import React from 'react';
import moment from 'moment';

type Props = {
  format: 'date' | 'dateTime';
  value: string;
};

export enum DateFormat {
  DateTime = 'DD.MM.YY HH:mm',
  Date = 'DD.MM.YY',
}

export const DateTimeCell = (props: Props) => {
  const { format = 'dateTime', value } = props;

  if (value == null) return null;

  const momentValue = moment(value);
  const formatString = format === 'dateTime' ? DateFormat.DateTime : DateFormat.Date;

  return <>{momentValue.format(formatString)}</>;
};
