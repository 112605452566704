import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';
import moment from 'moment';
import { TourIdentifier } from '@legacy-modules/tour/models/state/tourDetails/TourIdentifier';

export const selectTourDetails = (state: RootState) => state.tourDetails;
export const selectTourDetailsTourIdentifier = createSelector(selectTourDetails, (tourDetailsState) =>
  tourDetailsState.tourIdentifier ? new TourIdentifier(tourDetailsState.tourIdentifier) : null
);
export const selectTourDetailsTourDate = createSelector(selectTourDetailsTourIdentifier, (tourIdentifier) =>
  tourIdentifier.date ? moment(tourIdentifier.date) : null
);
export const selectTourDetailsContractorKey = createSelector(
  selectTourDetailsTourIdentifier,
  (tourIdentifier) => tourIdentifier?.contractorKey
);
export const selectTourDetailsSelectedStop = createSelector(
  selectTourDetails,
  (tourDetailsState) => tourDetailsState.selectedStop
);
export const selectTourDetailsRoutingParams = createSelector(
  selectTourDetailsTourIdentifier,
  selectTourDetailsSelectedStop,
  (tourIdentifier, selectedStop) => ({
    tourIdentifier,
    selectedStop,
  })
);
