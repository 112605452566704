import FinishedDelivery from '../models/entities/FinishedDelivery';
import CustomerTrackingData from '../models/types/CustomerTrackingData';
import { MarkerIcon } from '../models/enum/MarkerIcon';
import AuthGuard from '../../auth/components/AuthGuard';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import React, { useCallback } from 'react';
import Logger from '../../utils/logging/Logger';
import { DeliveryItemTrackingData } from '../models/types/DeliveryItemTrackingData';
import DeliveryModes from '../models/enumerations/DeliveryMode';

const logger = Logger.getInstance('TourDetailsDeliveryTable');

export const transformFinishedDeliveryTableData = (finishedDeliveries: FinishedDelivery[], anomaliesForStop) => {
  const getAnomalyForStopIndex = (index: number) => {
    if (typeof index !== 'number') return '-';

    return !anomaliesForStop.has(index) ? [] : anomaliesForStop.get(index);
  };

  return finishedDeliveries.map((fd) => {
    const { index, deliveryMode, displayableStopNumber, customerDeliveries, finishedAt, uuid } = fd;
    return {
      identifier: uuid,
      deliveryMode,
      stopp: displayableStopNumber,
      anomalies: getAnomalyForStopIndex(index) || [],
      type: fd,
      up: Object.values(customerDeliveries)
        .map((cd) => cd.deliveryItemCount || 0)
        .reduce((a, b) => a + b, 0),
      down: Object.values(customerDeliveries)
        .map((cd) => cd.returnDeliveryItemCount || 0)
        .reduce((a, b) => a + b, 0),
      time: finishedAt?.format('HH:mm') || '',
    };
  });
};

export const transformUnfinishedDeliveryTableData = (
  unfinishedCustomers,
  customers,
  deliveryItems,
  finishedDeliveries
) => {
  let lastDisplayableStopNumber = 0;
  finishedDeliveries.forEach((fd) => {
    if (fd.displayableStopNumber > lastDisplayableStopNumber) {
      lastDisplayableStopNumber = fd.displayableStopNumber;
    }
  });
  return unfinishedCustomers.map((ucus, index) => {
    const customerTrackingData: CustomerTrackingData = customers.get(ucus.customerRef);
    if (!customerTrackingData) {
      logger.warn('no customerTrackingData found for ' + ucus.customerRef);
      return null;
    }

    const getCustomerDeliveryItems = (customerRef: string): Array<DeliveryItemTrackingData> => {
      return deliveryItems.filter((di: DeliveryItemTrackingData) => di.customerRef === customerRef);
    };

    return {
      identifier: ucus.customerRef,
      deliveryMode: null,
      stopp: lastDisplayableStopNumber + index + 1,
      anomalies: [],
      type: null,
      up: getCustomerDeliveryItems(ucus.customerRef).length,
      down: '-',
      time: ucus.estimatedAt?.format('HH:mm') || '-',
    };
  });
};
