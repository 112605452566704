import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { TextInput } from '../../common/components/TextInput';
import { useToday } from '../hooks/useToday';

type DateInputProps = {
  disabled?: boolean;
  value: moment.Moment | string;
  format?: string;
  onChange?: (date: string) => void;
  className?: string;
  innerClassName?: string;
  onFocus?: () => void;
  customError?: string;
  onBlur?: (value: moment.Moment) => void;
  allowFuture?: boolean;
};

const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  (
    {
      disabled = false,
      value,
      format = 'DD.MM.YYYY',
      onChange,
      className,
      innerClassName,
      onFocus,
      customError,
      onBlur,
      allowFuture = false,
    },
    ref
  ) => {
    const formatValueToString = useCallback(
      (value: string | moment.Moment) => {
        if (!value) {
          return '';
        }
        if (typeof value === 'string') {
          return moment(value).format(format);
        }
        return value.format(format);
      },
      [format]
    );

    const [error, setError] = useState<string>(customError || '');
    const [input, setInput] = useState(formatValueToString(value));

    useEffect(() => {
      if (!customError?.length) {
        return;
      }
      setError(customError);
    }, [customError]);

    useEffect(() => {
      if (!value) {
        setInput('');
        setError('');
      } else {
        setInput(formatValueToString(value));
        setError('');
      }
    }, [format, formatValueToString, value]);

    const today = useToday();

    const onChangeCallback = useCallback(
      (e) => {
        const { value } = e.target;
        const date = moment(value, format);

        const isValidDate = date.isValid() && value.length === 10;
        const isFutureAllowedOrNotInFuture = isValidDate && (allowFuture || date.isSameOrBefore(today));

        if (isValidDate && isFutureAllowedOrNotInFuture) {
          setError('');
          setInput(value);
          onChange && onChange(value);
        } else {
          const error = !isValidDate
            ? 'Bitte geben Sie ein gültiges Datum ein'
            : 'Datum darf nicht in der Zukunft liegen';
          setError(error);
          setInput(value);
        }
      },
      [allowFuture, format, onChange, today]
    );

    const onBlurCallback = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        const { value: eventValue } = e.target;
        const date = moment(eventValue, format);

        if (date.isValid() && eventValue.length === 10) {
          onBlur && onBlur(moment(eventValue, format));
        } else {
          onBlur && onBlur(typeof value === 'string' ? moment(value) : value);
          setInput(formatValueToString(value));
          setError('');
        }
      },
      [format, formatValueToString, onBlur, value]
    );

    return (
      <div className={className}>
        <TextInput
          ref={ref}
          type='text'
          disabled={disabled}
          className={innerClassName}
          onFocus={onFocus}
          onBlur={onBlurCallback}
          onChange={onChangeCallback} // FIXME
          value={input}
          invalid={!!error}
          invalidReason={error}
        />
      </div>
    );
  }
);

DateInput.displayName = 'DateInput';

export { DateInput };
