import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import anfahrtszeit from './anfahrtszeit';
import touren from './touren';

const expression = new QuotientValueExpression(
  anfahrtszeit,
  touren,
  'Ø Anfahrtszeit',
  false,
  '-#.###.##0,0 h',
  undefined,
  getDefinitionByName('Anfahrtszeit')
);

expression.setIdentifier('anfahrtszeit/touren');

export default expression;
