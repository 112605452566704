import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useApiContext } from '@contexts/api-context';
import { KnownWebsocketEvent } from '@contexts/api-context/request.types';
import { OrgKey } from '@data-table/data-table.types';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { injectContractorViewMode } from '@legacy-modules/dashboard/utils/OrgKeyUtils';
import { ContractorViewMode } from '@legacy-modules/navigation/constants/ContractorViewMode';
import { TourUtils } from '@legacy-modules/utils/tours/TourUtils';
import { DurationUtils } from '@utils/duration-utils';
import {
  TourListResponse,
  TourIdentifierWithOrgKeys,
  TourNumber,
  TourListData,
  TourDate,
  TourDTO,
  MetricDTO,
  TourListDTO,
} from './types';

type UseTourListOutput = {
  data: TourListResponse;
  uniqueTourIdentifiers: TourIdentifierWithOrgKeys[];
};

const useTourList = (
  orgKey: OrgKey,
  duration: Duration,
  isComparison: boolean = false,
  enabled: boolean = true
): UseTourListOutput => {
  const apiCtx = useApiContext();

  const primaryRange = isComparison
    ? DurationUtils.getComparisonDateRange(duration)
    : DurationUtils.getDateRange(duration);

  const { from, to } = {
    from: primaryRange?.from?.format('YYYY-MM-DD'),
    to: primaryRange?.to?.format('YYYY-MM-DD'),
  };

  const queryKey = primaryRange?.from?.isSame(primaryRange?.to, 'day') ? from : `${from}-${to}`;

  const { data } = useQuery<TourListResponse>({
    queryKey: [KnownWebsocketEvent.METRICS_LOAD_EVENT, KnownWebsocketEvent.TOUR_LIST_LOAD_EVENT, orgKey, queryKey],
    enabled: !!orgKey && !!from && !!to && enabled,
    queryFn: () =>
      apiCtx.wsFetch<TourListResponse>(KnownWebsocketEvent.METRICS_LOAD_EVENT, {
        type: KnownWebsocketEvent.TOUR_LIST_LOAD_EVENT,
        orgKey,
        dateFilter: {
          range: {
            from,
            until: to,
          },
        },
        contractorKey: TourUtils.isTourKey(orgKey) ? injectContractorViewMode(orgKey, ContractorViewMode.All) : null,
      }),
    placeholderData: { metrics: [] },
  });

  const uniqueTourIdentifiers = useMemo<TourIdentifierWithOrgKeys[]>(() => {
    const tourListMap = parseTourListResponse(data);
    return Array.from(
      new Map<TourNumber, TourIdentifierWithOrgKeys[]>(
        Array.from(tourListMap?.values() || []).flatMap((tourMap) => {
          return Array.from(tourMap.entries()).map(([tourOrgIdentifier, tours]) => [
            tourOrgIdentifier,
            tours.map((tour) => ({
              ...tour.identifier,
              standortOrgKey: `oz:${tour.identifier.organizationId}`,
              tourOrgKey: `oz_t:${tour.identifier.organizationId}_${tour.identifier.number}`,
              contractorKey: tour.contractorKey,
            })),
          ]);
        })
      ).values()
    ).flat();
  }, [data]);

  return { uniqueTourIdentifiers, data };
};

export default useTourList;

const parseTourListResponse = (data: TourListResponse): TourListData => {
  if (!isTourListResponse(data)) return null;
  return new Map<TourDate, Map<TourNumber, TourDTO[]>>(
    data?.metrics?.filter(isMetric).map((metric) => {
      const parsedValue = JSON.parse(metric.value);
      if (!isTourList(parsedValue)) {
        return [metric.date, new Map<TourNumber, TourDTO[]>([])];
      }
      const tourMap = new Map<TourNumber, TourDTO[]>();
      parsedValue.tours.filter(isTour).forEach((tour) => {
        const combinedTourNumberOrgKeyIdentifier = `${tour.identifier.organizationId}:${tour.identifier.number}`;
        if (tourMap.has(combinedTourNumberOrgKeyIdentifier)) {
          tourMap.get(combinedTourNumberOrgKeyIdentifier).push(tour);
        } else {
          tourMap.set(combinedTourNumberOrgKeyIdentifier, [tour]);
        }
      });
      return [metric.date, new Map<TourNumber, TourDTO[]>(tourMap)];
    }) || []
  );
};

export const isTourListResponse = (data: unknown): data is TourListResponse =>
  data && typeof data === 'object' && 'metrics' in data && Array.isArray(data.metrics);

export const isMetric = (data: unknown): data is MetricDTO => {
  return data && typeof data === 'object' && 'type' in data && 'value' in data && typeof data.value === 'string';
};

export const isTourList = (data: unknown): data is TourListDTO =>
  data && typeof data === 'object' && 'tours' in data && Array.isArray(data.tours);

export const isTour = (data: unknown): data is TourDTO =>
  data && typeof data === 'object' && 'identifier' in data && 'deliveryItems' in data;
