import React, { ReactElement } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

export type ChartProps = Pick<Bar['props'], 'options'> &
  Pick<ValueExpression, 'chartType'> & {
    data: Bar['props']['data'] | Line['props']['data'];
  };

export const Chart: React.FC<ChartProps> = ({ data, options, chartType }: ChartProps): ReactElement => {
  const ChartComponent = chartType === ChartType.bar ? Bar : Line;
  return (
    <div className='primaryTimelineChart'>
      <ChartComponent data={data} options={options} />
    </div>
  );
};

export default Chart;
