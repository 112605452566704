import Styles from './InputCheckbox.module.scss';
import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';

type InputCheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  indeterminate?: boolean;
  containerClassName?: string;
  'data-testid'?: string;
};
export const InputCheckbox = (props: InputCheckboxProps) => {
  const {
    'data-testid': dataTestId,
    className,
    containerClassName,
    children,
    style,
    indeterminate = false,
    checked,
    ...otherProps
  } = props;

  return (
    <label
      data-testid={dataTestId}
      data-checked={checked}
      data-indeterminate={!checked && indeterminate}
      className={classNames(Styles.Container, containerClassName)}
      style={style}
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <input checked={checked} {...otherProps} type='checkbox' />
      <span
        className={classNames(className, Styles.Checkmark, {
          [Styles.indeterminate]: indeterminate,
        })}
      />
      {children}
    </label>
  );
};
