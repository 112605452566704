export class CountryUtils {
  static PREFIX = 'country';

  static isCountry(orgKey: string): boolean {
    if (!orgKey) {
      return false;
    }
    return orgKey.startsWith(CountryUtils.PREFIX);
  }
}
