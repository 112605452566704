import MetricsDataProvider from '@legacy-modules/metrics2/services/MetricsDataProvider';
import React, { createContext, PropsWithChildren, ReactElement, useContext } from 'react';

export type MetricsDataContextType = MetricsDataProvider;

const defaultValue: MetricsDataContextType = null;
export const MetricsDataContext = createContext(defaultValue);

export const useMetricsDataContext = (): MetricsDataContextType => {
  return useContext<MetricsDataContextType>(MetricsDataContext);
};

type MetricsDataContextProviderProps = Required<
  PropsWithChildren<{
    metricsDataProvider: MetricsDataProvider;
  }>
>;

export default function MetricsDataContextProvider({
  children,
  metricsDataProvider,
}: MetricsDataContextProviderProps): ReactElement {
  return <MetricsDataContext.Provider value={metricsDataProvider}>{children}</MetricsDataContext.Provider>;
}
