import { useApiContext } from '@contexts/api-context';
import { KnownWebsocketEvent } from '@contexts/api-context/request.types';
import { OrgKey } from '@data-table/data-table.types';
import { ConfigType } from '@legacy-modules/dashboard/constants/ConfigType';
import { CustomLimit, DefaultLimit, LimitClassTypes, TargetArea } from '@legacy-modules/dashboard/types/AbstractLimit';
import { LimitType } from '@legacy-modules/dashboard/websocket/DashboardLoadLimitResponsePayload';
import { defaultLimitsMap } from '@utils/limits-utils';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

type OrgConfigLoadRequestPayload = {
  configType: string;
  orgKeys: string[];
  configKeys: string[];
};
type OrgConfig = { value: LimitClassTypes; orgKey: string; configKey: string };
type OrgConfigLoadResponse = {
  values: OrgConfig[];
};

type UseOrgConfigOutput = { data: OrgConfigLoadResponse; isLoading: boolean; isError: boolean };

// primarily used for fetching kpi goals
export default function useOrgConfig(
  orgKey: OrgKey,
  valueExpressionIdentifier: string,
  enabled: boolean = true
): UseOrgConfigOutput {
  const defaultLimitMapEntry = defaultLimitsMap.get(valueExpressionIdentifier);
  const apiCtx = useApiContext();
  const { data, isLoading, isError } = useQuery<OrgConfigLoadResponse>({
    queryKey: [ConfigType.ValueExpressionGoal, orgKey, valueExpressionIdentifier],
    queryFn: () =>
      apiCtx.wsFetch(KnownWebsocketEvent.ORG_UNIT_CONFIG_LOAD_EVENT, {
        configType: ConfigType.ValueExpressionGoal,
        configKeys: [valueExpressionIdentifier],
        orgKeys: [orgKey],
      } as OrgConfigLoadRequestPayload),
    enabled: enabled && !!orgKey && !!valueExpressionIdentifier,
    initialData: defaultLimitMapEntry && {
      values: [
        { value: { ...defaultLimitMapEntry, type: LimitType.LIMIT }, configKey: valueExpressionIdentifier, orgKey },
      ],
    },
  });

  return { data, isLoading, isError };
}

export const reviveGoalClass = (v: { type: string }) => {
  if (!v?.type) return null;

  switch (v.type) {
    case LimitType.LIMIT:
      return new DefaultLimit((v as DefaultLimit).positive, (v as DefaultLimit).negative);
    case LimitType.TARGET_AREA:
      return new TargetArea((v as TargetArea).targetAreas);
    case LimitType.CUSTOM_DIRECTION:
      return new CustomLimit((v as CustomLimit).direction, (v as CustomLimit).limit);
  }
};
