import React, { useCallback, useMemo } from 'react';
import { SearchResultGroup } from '../services/OrgSearchService';
import Styles from './OrgSearchResultList.module.scss';
import classNames from 'classnames';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import { CopyButton } from './CopyButton';
import AuthGuard from '../../auth/components/AuthGuard';
import { formatOrgUnitLabel, isOrgUnitExpired } from './OrganizationMultiSelection';
import { SearchResultList } from './SearchResultList';
import OrgUnitNode from '../../metrics2/models/websocket/org/OrgUnitNode';

type Props = {
  results?: Array<SearchResultGroup>;
  onResultSelect: (unit: OrgUnitNode) => void;
  searchValue?: string;
};

export const OrgSearchResultList: React.FC<Props> = ({ results = [], onResultSelect, searchValue = '' }) => {
  const getNameForNode = useCallback((orgUnit: OrgUnitNode) => {
    let nextElement = orgUnit;
    const nameParts = [];
    while (nextElement != null) {
      if (nextElement) {
        nameParts.push(formatOrgUnitLabel(nextElement.orgUnit));
      }
      nextElement = nextElement.parent;
    }
    return nameParts.reverse();
  }, []);

  const searchResultsMapped = useMemo(
    () =>
      results.map((result) => ({
        group: result.treeType,
        results: result.results,
      })),
    [results]
  );

  const getCustomElement = useCallback(
    (node: OrgUnitNode) => {
      return <SearchResultEntry node={node} getNameForNode={getNameForNode} searchValue={searchValue} />;
    },
    [getNameForNode, searchValue]
  );

  return (
    <SearchResultList
      results={searchResultsMapped}
      onResultSelect={onResultSelect}
      getNameForNode={getNameForNode}
      searchValue={searchValue}
      customElement={getCustomElement}
    />
  );
};

type SearchResultEntryProps = {
  node: OrgUnitNode;
  searchValue: string;
  getNameForNode: (node: OrgUnitNode) => string[];
};
const SearchResultEntry = ({ node, searchValue, getNameForNode }: SearchResultEntryProps) => {
  const searchResultParts = useMemo(
    () =>
      getNameForNode(node)
        .map((part, index, array) => {
          const isLastPart = index === array.length - 1;
          const startIndex = part?.toLowerCase().indexOf(searchValue?.toLowerCase());
          if (startIndex === -1) {
            return isLastPart ? part : `${part} / `;
          }
          const searchRegexp = new RegExp(searchValue, 'i');
          const valueToReplace = part?.substring(startIndex, startIndex + searchValue.length);
          return part?.split(searchRegexp).map((substring, index, partArray) => {
            if (index === 0) {
              return substring;
            }
            if (isLastPart || index < partArray.length - 1) {
              return (
                <React.Fragment>
                  <span className={Styles.Mark}>{valueToReplace}</span>
                  {substring}
                </React.Fragment>
              );
            }

            return (
              <React.Fragment>
                <span className={Styles.Mark}>{valueToReplace}</span>
                {substring + ' / '}
              </React.Fragment>
            );
          });
        })
        .flat(),
    [getNameForNode, searchValue, node]
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}>
      <span
        style={{
          flex: 1,
        }}
        className={classNames(Styles.Name, {
          [Styles.expired]: isOrgUnitExpired(node.orgUnit),
        })}>
        {searchResultParts}
      </span>
      <AuthGuard requiredPermissions={[WellKnownPermission.AdminCopyOrgId]}>
        <span className={Styles.CopyButtonWrapper}>
          <CopyButton textToCopy={node.orgUnit.orgKey} />
        </span>
      </AuthGuard>
    </div>
  );
};
