import moment from 'moment-timezone';
import BasePayload from '../../common/models/websocket/payloads/BasePayload';
import SystemErrorPayload from '../../common/models/websocket/payloads/system/SystemErrorPayload';
import Logger from '../../utils/logging/Logger';
import CacheEntity from '../models/entities/CacheEntity';
import { CacheKeyInterface } from '../models/entities/CacheKeyInterface';
import { QueryPriority } from '../models/enumerations/QueryPriority';
import { QueryPriorities } from '../models/enumerations/QueryPriority';
import { QueryInterface } from '../models/queries/QueryInterface';
import RequestScheduler from '../services/RequestScheduler';
import CacheDataConnection from './CacheDataConnection';
import { EntityPartsInterface } from '../models/entities/EntityPartsInterface';

export type CachedQueryData<EK, V> = {
  data: Map<EK, V>;
  allLoading: boolean;
  anyLoading: boolean;
  completed: boolean;
  lastUpdateAt: moment.Moment | null | undefined;
  completedKeys: Array<EK>;
  partsToRetrieve: Set<string>;
};

/**
 * This class:
 *  - Manages caches and its max caching times
 *  - Aggregation of data
 *  - Manages data watches
 *  - Requests data
 *  - Knows which cache entries are listened to by @class CacheDataConnections
 *  - Notifies CacheDataConnections about changes
 */
export default abstract class AbstractCacheDataProvider<EK extends CacheKeyInterface, V> {
  /*
  TODO
  _watches: Map<string, ActiveWatchRequest>;
  _queryDirectCacheEntityKeys: Map<MetricsQuery, Array<MetricsEntityKey>>;
  */
  _cacheEntityKeys: Map<string, CacheKeyInterface>;
  _cache: Map<CacheKeyInterface, CacheEntity<EK, V>>;
  _requestScheduler: RequestScheduler;
  _cleanupTimeout: number | null | undefined = null;
  _partitionedEntity: boolean = false;
  _logger: Logger = Logger.getInstance('AbstractCacheDataProvider');
  cleanupDelayTime: number = 4000;
  cacheTimeoutSeconds: number = 600;
  _returnEmptyValues: boolean = false;
  _cleanupInterval: number | null | undefined = null;

  constructor(requestScheduler: RequestScheduler) {
    this._requestScheduler = requestScheduler;
    this._cacheEntityKeys = new Map();
    this._cache = new Map();
    /*
    TODO
    this._queryDirectCacheEntityKeys = new Map();
    this._watches = new Map();
    */
  }

  init() {
    this._cleanupTimeout = window.setTimeout(this.cleanupCache.bind(this), this.cleanupDelayTime);
  }

  destruct() {
    if (this._cleanupTimeout) {
      window.clearTimeout(this._cleanupTimeout);
    }
  }

  /**
   * Request a DataConnection
   * @param query The query to execute
   * @param priority The priority of this DataConnection
   * @returns {CacheDataConnection}
   */
  requestConnection(
    query: QueryInterface<any>,
    priority: QueryPriority = QueryPriorities.normal
  ): CacheDataConnection<EK, V> {
    const dataConnection = this._newDataConnection(query);
    const cachedQueryData = this.getCachedQueryData(query);
    const cacheEntityKeys: Array<CacheKeyInterface> = Array.from(cachedQueryData?.data?.keys() || []);
    cacheEntityKeys.forEach((ek: CacheKeyInterface) => {
      const cacheEntry = this._cache.get(ek);
      if (cacheEntry) {
        cacheEntry.registerCacheDataConnection(dataConnection);
      }
    });
    if (cachedQueryData.allLoading) {
      dataConnection.setLoading(true);
    } else if (cachedQueryData.completed) {
      dataConnection.updateData(cachedQueryData.data, true, cachedQueryData.anyLoading);
    } else {
      dataConnection.setLoading(true);
      this.executeQuery(query, priority, cachedQueryData);
    }
    return dataConnection;
  }

  async query(
    query: QueryInterface,
    priority: QueryPriority = QueryPriorities.normal
  ): Promise<Map<CacheKeyInterface, V> | null | undefined> {
    const cachedQueryData = this.getCachedQueryData(query);
    if (cachedQueryData.completed) {
      return cachedQueryData.data;
    } else if (cachedQueryData.allLoading) {
      await this._requestScheduler.awaitScheduledRequests(priority);
      // wait for response to be processed
      await new Promise((resolve) => setTimeout(resolve, 500));
      const cachedQueryData2 = this.getCachedQueryData(query);
      if (cachedQueryData2.completed) {
        return cachedQueryData2.data;
      } else {
        return this.executeQuery(query, priority);
      }
    } else {
      query = this.filterQuery(query, cachedQueryData);

      const resultMap = await this.executeQuery(query, priority);
      cachedQueryData.completedKeys.forEach((ck) => {
        if (cachedQueryData.data.has(ck)) {
          resultMap.set(ck, cachedQueryData.data.get(ck));
        }
      });
      return resultMap;
    }
  }

  async singleQuery(query: QueryInterface<any>, priority: QueryPriority = QueryPriorities.normal): Promise<V | null> {
    const data = await this.query(query, priority);

    if (!data) {
      return null;
    }

    const result: Array<V> = Array.from(data.values());
    // FIXME
    // @ts-ignore
    return result.length > 0 ? result[0] : null;
  }

  private buildCacheEntitiesFromCache(
    cachedQueryData: CachedQueryData<EK, V> | null | undefined = null,
    query: QueryInterface
  ) {
    const cacheEntityKeys = cachedQueryData
      ? Array.from(cachedQueryData.data.keys())
      : this.getEntityKeysForQuery(query);

    cacheEntityKeys.forEach((key) => {
      let cacheEntity: CacheEntity<EK, V> = this._cache.get(key);
      if (!cacheEntity) {
        cacheEntity = new CacheEntity(this._partitionedEntity);
        this._cache.set(key, cacheEntity);
      }
      cacheEntity.requestPending = true;

      if (this._partitionedEntity && query.parts) {
        query.parts.forEach((p) => cacheEntity.requestedParts.add(p));
      }
    });

    return cacheEntityKeys;
  }

  async executeQuery(
    query: QueryInterface,
    priority: QueryPriority = QueryPriorities.normal,
    cachedQueryData: CachedQueryData<EK, V> | null | undefined = null
  ): Promise<Map<CacheKeyInterface, V>> {
    const cacheEntityKeys = this.buildCacheEntitiesFromCache(cachedQueryData, query);
    try {
      const updatedConnections: Set<CacheDataConnection<EK, V>> = new Set();
      const queryResponseEntities: Map<EK, V> = await this.executeQueryWithoutCaching(query, priority);
      const responseEntities: Map<CacheKeyInterface, V> = new Map();
      const remainingCacheEntityKeys = new Set(cacheEntityKeys);
      queryResponseEntities.forEach((value: V, cacheEntityKey: CacheKeyInterface) => {
        const cacheEntityKeyUnique = this.getUniqueCacheEntityKey(cacheEntityKey);

        let cacheEntity: CacheEntity<EK, V> = this._cache.get(cacheEntityKeyUnique);

        if (!cacheEntity) {
          cacheEntity = new CacheEntity(this._partitionedEntity);
          // TODO: is this really the correct key or should it be 'cacheEntityKeyUnique'?
          this._cache.set(cacheEntityKey, cacheEntity);
        }
        cacheEntity.setValue(value, this._partitionedEntity ? query.parts : new Set<string>());
        cacheEntity.cacheDataConnections.forEach((conn) => {
          updatedConnections.add(conn);
        });
        if (
          // TODO: Check if this call had a reason, I removed in, because it resulted in wrong/missing data
          // remainingCacheEntityKeys.has(cacheEntityKeyUnique) &&
          !this.isHiddenResultEntity(query, cacheEntityKeyUnique, value)
        ) {
          responseEntities.set(cacheEntityKeyUnique, cacheEntity.value);
        }
        remainingCacheEntityKeys.delete(cacheEntityKeyUnique);
        // TODO: Maybe this should be deleted as well?
        // remainingCacheEntityKeys.delete(cacheEntityKey);
      });
      remainingCacheEntityKeys.forEach((uniqueKey: EK) => {
        const cacheEntity: CacheEntity<EK, V> = this._cache.get(uniqueKey);

        if (this._returnEmptyValues) {
          // TODO: What is this good for?
          cacheEntity.setValue(this.zeroValue());
          if (!this.isHiddenResultEntity(query, uniqueKey, cacheEntity.value)) {
            responseEntities.set(uniqueKey, cacheEntity.value);
          }
        } else {
          cacheEntity.setValue(null);
        }
        cacheEntity.cacheDataConnections.forEach((conn) => {
          updatedConnections.add(conn);
        });
      });
      updatedConnections.forEach((conn: CacheDataConnection<EK, V>) => {
        const cqd = this.getCachedQueryData(conn.query);
        conn.updateData(cqd.data, cqd.completed, cqd.anyLoading);
      });
      return responseEntities;
    } catch (e) {
      this._logger.error('Request error while executing query.', e);
      throw e;
    }
  }

  async executeQueryWithoutCaching(
    query: QueryInterface,
    priority: QueryPriority = QueryPriorities.normal,
    repeat: boolean = true
  ): Promise<Map<EK, V>> {
    const response = await this._requestScheduler.request(query.toRequestType(), query.toRequestPayload(), priority)
      .promise;
    const responsePayload = response.payload;
    if (responsePayload instanceof SystemErrorPayload) {
      // ideally we'd like to check AuthService for current status but it's difficult with current design
      // sometimes backend is also timing first few queires after the restart
      const shouldRepeat = responsePayload.error === 401 || responsePayload.error === 504;
      if (shouldRepeat && repeat) {
        return new Promise((resolve) =>
          setTimeout(() => resolve(this.executeQueryWithoutCaching(query, priority, false)), this.cleanupDelayTime)
        );
      } else {
        this._logger.error(
          `Received system error payload. Error: "${responsePayload.error}" Message: "${
            responsePayload.message
          }". Query: ${JSON.stringify(query)}`
        );
        throw responsePayload;
      }
    }
    return this._parseResponse(query, responsePayload);
  }

  getCachedQueryData(query: QueryInterface): CachedQueryData<EK, V> {
    const cacheEntityKeys = this.getEntityKeysForQuery(query);
    const data: Map<CacheKeyInterface, V> = new Map();
    let completed = true;
    let allLoading = true;
    let anyLoading = false;
    const completedKeys = [];
    let lastUpdateAt: moment.Moment | null | undefined = moment();
    const partsToRetrieve: Set<string> = new Set();
    const queryParts = query.parts ? [...query.parts] : [];
    cacheEntityKeys.forEach((key) => {
      let cacheEntity = this._cache.get(key);
      if (!cacheEntity) {
        cacheEntity = new CacheEntity(this._partitionedEntity);
        this._cache.set(key, cacheEntity);
      }
      const partsComplete =
        !this._partitionedEntity || queryParts.length === 0 || queryParts.every((p) => cacheEntity.cachedParts.has(p));
      const partsLoading =
        !this._partitionedEntity ||
        queryParts.length === 0 ||
        queryParts.every((p) => cacheEntity.requestedParts.has(p));

      if (this._partitionedEntity && queryParts.length > 0) {
        const availableParts: Set<string> = cacheEntity.value
          ? (cacheEntity.value as unknown as EntityPartsInterface).getAvailableParts()
          : cacheEntity.cachedParts;
        const missingParts = queryParts.filter((p) => !availableParts.has(p));
        const allPartsAvailable = missingParts.length === 0;
        if (!allPartsAvailable && partsToRetrieve.size < query.parts.size) {
          missingParts.forEach((p) => partsToRetrieve.add(p));
        }
      }

      allLoading = allLoading && cacheEntity.requestPending && (partsComplete || partsLoading);
      anyLoading = anyLoading || (cacheEntity.requestPending && (partsComplete || partsLoading));
      let singleCompleted = !!cacheEntity.lastUpdatedAt && partsComplete;
      if (singleCompleted && this._partitionedEntity && queryParts.length > 0 && cacheEntity.value) {
        const availableParts: Set<string> = (cacheEntity.value as unknown as EntityPartsInterface).getAvailableParts();
        const missingParts = queryParts.filter((p) => !availableParts.has(p));
        const allPartsAvailable = missingParts.length === 0;
        singleCompleted = singleCompleted && allPartsAvailable;
      }
      if (lastUpdateAt && (!cacheEntity.lastUpdatedAt || cacheEntity.lastUpdatedAt.isBefore(lastUpdateAt))) {
        lastUpdateAt = cacheEntity.lastUpdatedAt;
      }
      if (!this.isHiddenResultEntity(query, key, cacheEntity.value)) {
        data.set(key, cacheEntity.value);
      }
      completed = completed && singleCompleted;
      if (singleCompleted) {
        completedKeys.push(key);
      }
    });
    return {
      data,
      allLoading,
      anyLoading,
      completed,
      lastUpdateAt,
      completedKeys,
      partsToRetrieve,
    } as CachedQueryData<EK, V>;
  }

  filterQuery(query: QueryInterface, cachedQueryData: CachedQueryData<EK, V>): QueryInterface {
    return query;
  }

  isHiddenResultEntity(query: QueryInterface, key: EK | CacheKeyInterface, value: V) {
    return false;
  }

  getEntityKeysForQuery(query: QueryInterface): Set<CacheKeyInterface> {
    return new Set(
      this.getDirectCacheEntityKeys(query).map((ce: CacheKeyInterface) => this.getUniqueCacheEntityKey(ce))
    );
  }

  getUniqueCacheEntityKey(ek: CacheKeyInterface): CacheKeyInterface {
    if (this._cacheEntityKeys.has(ek.identifier)) {
      return this._cacheEntityKeys.get(ek.identifier);
    } else {
      this._cacheEntityKeys.set(ek.identifier, ek);
      return ek;
    }
  }

  zeroValue(): any {
    return null;
  }

  _newDataConnection(query: QueryInterface): CacheDataConnection<EK, V> {
    return new CacheDataConnection(query);
  }

  getDirectCacheEntityKeys(query: QueryInterface): Array<CacheKeyInterface> {
    return [];
  }

  abstract _parseResponse(query: QueryInterface, response: Partial<BasePayload<any>>): Map<EK, V>;

  cleanupCache() {
    let countExpiredEntities = 0;
    const now = moment();

    // identify cache entities that have no CacheDataConnections and have not been updated for long enough
    this._cache.forEach((entity: CacheEntity<any, any>, key: CacheKeyInterface) => {
      if (
        entity.cacheDataConnections.size === 0 &&
        !entity.requestPending &&
        (!entity.lastUpdatedAt || entity.lastUpdatedAt.clone().add(this.cacheTimeoutSeconds, 's').isBefore(now))
      ) {
        this._cache.delete(key);
        countExpiredEntities++;
      }
    });
    if (countExpiredEntities > 0) {
      this._logger.info(`Cleaned ${countExpiredEntities} entries from cache.`);
    }
    this._cleanupTimeout = window.setTimeout(this.cleanupCache.bind(this), this.cleanupDelayTime);
  }
}
