import { useDataTableContext } from '@data-table/contexts/data-table-context';
import { DataTableDataType, DataTableMeta } from '@data-table/data-table.types';
import { DndContextProps, DragOverEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { Header } from '@tanstack/react-table';
import React, { ComponentType, Ref, useCallback, useRef, MutableRefObject } from 'react';

type ValueExpressionTableHeaderProps = {
  title: string;
  duration: Duration;
  valueExpressionKeys: string[];
  availableKpiSelectorKeys?: string[];
  disabled?: boolean;
  loading?: boolean;
  onDurationChange: (duration: Duration) => void;
  onColumnRemove: (columnId: string) => void;
  onColumnAdd: (columnId: string) => void;
};
export type ValueExpressionTableHeaderComponentProps = Pick<
  ValueExpressionTableHeaderProps,
  | 'title'
  | 'duration'
  | 'disabled'
  | 'loading'
  | 'onDurationChange'
  | 'valueExpressionKeys'
  | 'availableKpiSelectorKeys'
  | 'onColumnRemove'
  | 'onColumnAdd'
> &
  Pick<DndContextProps, 'onDragEnd' | 'onDragOver'> & {
    headers: Header<DataTableDataType, unknown>[];
  } & {
    scrollContainerRef: Ref<HTMLDivElement>;
    isFullscreen: boolean;
  };

const scrollToBottom = (scrollContainerRef: MutableRefObject<HTMLDivElement>) => {
  if (!scrollContainerRef.current) {
    return;
  }
  // timeout is needed to wait for the table to update
  setTimeout(() => {
    const scrollHeight = scrollContainerRef.current.scrollHeight;
    const height = scrollContainerRef.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    scrollContainerRef.current.scrollTo({
      top: maxScrollTop > 0 ? maxScrollTop : 0,
      behavior: 'smooth',
    });
  }, 200);
};

const withValueExpressionTableHeader =
  (
    Component: ComponentType<ValueExpressionTableHeaderComponentProps>
  ): ComponentType<ValueExpressionTableHeaderProps> =>
  ({ onColumnRemove, onColumnAdd, ...props }) => {
    const { table, tableWrapperRef } = useDataTableContext();

    const isFullscreen = tableWrapperRef.current ? document.fullscreenElement === tableWrapperRef?.current : false;

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const headers = table.getHeaderGroups()?.[0]?.headers;

    const onDragOver = useCallback(
      (e: DragOverEvent) => {
        const currentOrder: string[] = e?.active?.data?.current?.sortable?.items || [];
        const dragIndex = currentOrder.indexOf(e.active?.id as string);
        const dropIndex = currentOrder.indexOf(e.over?.id as string);
        if (dragIndex == -1 || dropIndex == -1) {
          return;
        }
        table.setColumnOrder(arrayMove(currentOrder, dragIndex, dropIndex));
      },
      [table]
    );

    const onDragEnd = useCallback(() => {
      (table.options.meta as DataTableMeta).saveColumnOrder();
    }, [table.options.meta]);

    const onColumnAddCallback = useCallback(
      (key: string) => {
        scrollToBottom(scrollContainerRef);
        onColumnAdd(key);
      },
      [onColumnAdd]
    );

    const onColumnRemoveCallback = useCallback(
      (key: string) => {
        scrollToBottom(scrollContainerRef);
        onColumnRemove(key);
      },
      [onColumnRemove]
    );

    return (
      <Component
        {...props}
        headers={headers}
        scrollContainerRef={scrollContainerRef}
        isFullscreen={isFullscreen}
        onDragOver={onDragOver}
        onDragEnd={onDragEnd}
        onColumnRemove={onColumnRemoveCallback}
        onColumnAdd={onColumnAddCallback}
      />
    );
  };

export default withValueExpressionTableHeader;
