import { GeoFeature } from './GeoFeature';
import { OrgUnitPart } from '../../queries/OrganizationQuery';
import { EntityPartsInterface } from '../../../../datamanager/models/entities/EntityPartsInterface';
import OrgUnitProperties from '../../websocket/org/OrgUnitProperties';
import { OrgUnitResult } from '../../websocket/org/OrgUnitResult';
import { TreeType } from '../../enumerations/TreeType';
import { Bounds } from '../../../../utils/map/MapUtils';

export default class OrgUnit implements EntityPartsInterface {
  // default part
  orgKey: string;
  name: string;
  orgType: string;
  treeType: TreeType | null | undefined;
  bbox: [number, number, number, number];
  // part: properties
  properties: OrgUnitProperties | null | undefined;
  // part: geometry
  type?: string;
  geometry?: GeoFeature;
  // part: children
  children?: Array<string>;
  // part: treeTypes
  treeTypes?: Array<TreeType>;
  // part: breadcrumb
  breadcrumb?: Array<string>;
  parent: OrgUnit;
  validTill: string;

  constructor(orgUnitResult: OrgUnitResult | null | undefined = null) {
    if (orgUnitResult) {
      this.orgKey = orgUnitResult.orgKey;
      this.name = orgUnitResult.name;
      this.orgType = orgUnitResult.orgType;
      this.treeType = orgUnitResult.treeType;
      this.bbox = orgUnitResult.bbox;
      // part: properties
      this.properties = orgUnitResult.properties && new OrgUnitProperties(orgUnitResult.properties);
      // part: geometry
      this.type = orgUnitResult.type;
      this.geometry = orgUnitResult.geometry;
      // part: children
      this.children = orgUnitResult.children;
      // part: treeTypes
      // @ts-ignore
      this.treeTypes = orgUnitResult.treeTypes;
      // part: breadcrumb
      this.breadcrumb = orgUnitResult.breadcrumb;
    }
  }

  enrichFromUnit(other: OrgUnit) {
    if (other == null) return;
    if (!this.properties && other.properties) {
      this.properties = new OrgUnitProperties(other.properties);
    }
    if (!this.geometry && other.geometry) {
      this.type = other.type;
      this.geometry = { ...other.geometry };
    }
    if (!this.children && other.children) {
      this.children = [...other.children];
    }
    if (!this.breadcrumb && other.breadcrumb) {
      this.breadcrumb = [...other.breadcrumb];
    }
    if (!this.treeTypes && other.treeTypes) {
      this.treeTypes = [...other.treeTypes];
    }
  }

  getAvailableParts(): Set<OrgUnitPart> {
    const parts = new Set<OrgUnitPart>();
    if (this.properties) {
      parts.add('properties');
    }
    if (this.type && this.geometry && this.bbox) {
      parts.add('geometry');
    }
    if (this.children) {
      parts.add('children');
    }
    if (this.breadcrumb) {
      parts.add('breadcrumb');
    }
    if (this.treeTypes) {
      parts.add('treeTypes');
    }
    return parts;
  }

  get parentOrgKey(): string | null | undefined {
    if (!this.breadcrumb || this.breadcrumb.length <= 1) {
      return null;
    }
    return this.breadcrumb[this.breadcrumb.length - 2];
  }

  get mapboxBoundingBox(): Bounds {
    return [
      [this.bbox[0], this.bbox[1]],
      [this.bbox[2], this.bbox[3]],
    ];
  }

  static getPath(node: OrgUnit): OrgUnit[] {
    if (!node.parent) {
      return [node];
    }
    return [node, ...OrgUnit.getPath(node.parent)];
  }
}
