import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import psMitnahmen from './ps-mitnahmen';
import touren from './touren';

const expression = new QuotientValueExpression(
  psMitnahmen,
  touren,
  'Ø PS Mitnahmen',
  false,
  undefined,
  undefined,
  getDefinitionByName('PS Mitnahmen')
);

expression.setIdentifier('psmitnahmen/touren');

export default expression;
