import React, { useCallback, useEffect, useRef, useState } from 'react';
import Styles from './Tooltip.module.scss';
import { usePopper } from 'react-popper';
import * as PopperJS from '@popperjs/core';
import classNames from 'classnames';
import Timeout = NodeJS.Timeout;
import Portal from './Portal';

type Props = {
  children?: React.ReactNode;
  anchorElement: Element;
  onClose?: () => void;
  placement?: PopperJS.Placement;
  visible?: boolean;
  offset?: [number, number];
  connectorElement?: {
    width: string;
  };
  className?: string;
  size?: 'small' | 'large';
  enterDelay?: number;
  onVisibilityChange?: (visible: boolean) => void;
};

export const Tooltip: React.FC<Props> = ({
  children,
  anchorElement,
  placement = 'auto',
  visible,
  offset = [0, 10],
  connectorElement = null,
  className,
  size = 'small',
  enterDelay,
  onVisibilityChange = () => undefined,
}: Props) => {
  const [popperRef, setPopperRef] = useState(null);
  const popOverRef = useRef<HTMLDivElement>(null);
  const [arrowRef, setArrowRef] = useState(null);

  const enterTimer = useRef<Timeout | number | null>(null);

  const { styles, attributes } = usePopper(anchorElement, popperRef, {
    placement,
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef?.current,
        },
      },
      {
        name: 'offset',
        enabled: true,
        options: {
          offset,
        },
      },
    ],
  });

  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    onVisibilityChange(isVisible);
  }, [isVisible, onVisibilityChange]);

  const showTooltip = useCallback(() => {
    if (typeof enterDelay === 'number') {
      enterTimer.current = setTimeout(() => setIsVisible(true), enterDelay);
    } else {
      setIsVisible(true);
    }
  }, [enterDelay]);

  const hideTooltip = useCallback(() => {
    if (enterTimer.current) {
      clearTimeout(enterTimer.current as NodeJS.Timeout);
      enterTimer.current = null;
    }
    setIsVisible(false);
  }, []);

  useEffect(() => {
    if (typeof visible !== 'undefined') {
      return;
    }
    if (!anchorElement) {
      return;
    }
    anchorElement.addEventListener('mouseover', showTooltip);
    anchorElement.addEventListener('mouseout', hideTooltip);

    return () => {
      anchorElement.removeEventListener('mouseover', showTooltip);
      anchorElement.removeEventListener('mouseout', hideTooltip);
    };
  }, [anchorElement, hideTooltip, showTooltip, visible]);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  if (!isVisible) {
    return null;
  }

  return (
    <Portal>
      <div
        ref={setPopperRef}
        style={{ ...styles.popper, zIndex: 2000 }}
        {...attributes.popper}
        className={classNames(Styles.Popper, attributes?.popper?.className)}>
        <div className={Styles.Wrapper}>
          <div
            className={Styles.Arrow}
            data-popper-arrow={'data-popper-arrow'}
            ref={setArrowRef}
            style={styles.arrow}
          />
          <div className={classNames(Styles.Inner, Styles[size])}>
            <div
              className={classNames(
                Styles.PopOver,
                {
                  hasConnectorElement: !!connectorElement,
                },
                className
              )}
              ref={popOverRef}>
              {connectorElement && (
                <span
                  className={Styles.ConnectorElement}
                  style={{
                    width: connectorElement.width,
                  }}
                />
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};
