export enum ProofOfDelivery {
  none = 'none',
  imageSignature = 'imageSignature',
  signature = 'signature',
  image = 'image',
  // Fallback values caused by the change of the delta api
  IMAGE_SIGNATURE = 'IMAGE_SIGNATURE',
  SIGNATURE = 'SIGNATURE',
  IMAGE = 'IMAGE',
  NONE = 'NONE',
}

// Helper function needed to always get the correct type of ProofOfDelivery
// It's mostly useful for the 20th and 21st of May 2022. When this data is no
// more available, this helper is safe to be removed
export const getProofOfDeliveryType = (pod: ProofOfDelivery) => {
  switch (pod) {
    case ProofOfDelivery.image:
    case ProofOfDelivery.IMAGE:
      return ProofOfDelivery.image;
    case ProofOfDelivery.IMAGE_SIGNATURE:
    case ProofOfDelivery.imageSignature:
      return ProofOfDelivery.imageSignature;
    case ProofOfDelivery.NONE:
    case ProofOfDelivery.none:
      return ProofOfDelivery.none;
    case ProofOfDelivery.SIGNATURE:
    case ProofOfDelivery.signature:
      return ProofOfDelivery.signature;
    default:
      return pod;
  }
};
