import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';

import Styles from './DeliveryItemShipmentSearch.module.scss';

import AuthGuard from '../../auth/components/AuthGuard';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import ShipmentHistorySearch from '../../dashboard/containers/ShipmentHistorySearch';

type Props = {
  barcode: string;
};

const DeliveryItemShipmentSearch: React.FC<Props> = ({ barcode }) => {
  const [historyVisible, setHistoryVisible] = useState(false);
  const onTooltipClick = useCallback((e) => {
    e.stopPropagation();
    setHistoryVisible((v) => !v);
  }, []);
  const closeCallback = useCallback(() => setHistoryVisible(false), []);
  const buttonRef = useRef(null);
  const wrapperRef = useRef(null);

  return (
    <AuthGuard requiredPermissions={[WellKnownPermission.ShipmentHistory]}>
      <div ref={wrapperRef}>
        <button ref={buttonRef} onClick={onTooltipClick} className={Styles.shipmentSearchIcon}>
          <MdInfoOutline />
        </button>
        <ShipmentHistorySearch
          barcode={barcode}
          target={buttonRef?.current}
          onClose={closeCallback}
          position={'right-start'}
          visible={historyVisible}
        />
      </div>
    </AuthGuard>
  );
};

export default DeliveryItemShipmentSearch;
