import moment from 'moment-timezone';

import OrgUnit from '../../../metrics2/models/entities/organization/OrgUnit';

export type ShipmentSearchEntryApiModel = [
  Partial<{
    tourNummer: string;
    orgId: number;
    ort: string;
    sendungsBarcode: string;
    status: string;
    tourDatum: string;
    zeitstempel: string;
  }>,
  { description: string; abbr: string },
  OrgUnit,
  {
    contractorKey?: string;
    orgUnitKey?: string;
  }
];

export default class ShipmentSearchEntry {
  orgId: number;
  place: string;
  shipmentId: string;
  status: string;
  tourDate?: string;
  tourNumber?: number;
  timestamp: moment.Moment;
  description: string;
  shortDesc: string;
  orgUnit?: OrgUnit;
  visibility: {
    contractorKey?: string;
    orgUnitKey?: string;
  };

  static fromJson(apiModel: ShipmentSearchEntryApiModel): ShipmentSearchEntry {
    const [org, translation, orgUnit, visibility] = apiModel;
    const tourNumber = Number(org.tourNummer) === 0 ? null : Number(org.tourNummer);
    const result = new ShipmentSearchEntry();
    result.orgId = org.orgId;
    result.place = org.ort;
    result.shipmentId = org.sendungsBarcode;
    result.status = org.status;
    result.tourDate = org.tourDatum;
    result.tourNumber = tourNumber;
    result.timestamp = moment(org.zeitstempel);
    result.description = translation.description;
    result.shortDesc = translation.abbr;
    result.orgUnit = orgUnit;
    result.visibility = visibility;
    return result;
  }

  date(): string {
    return this.timestamp.format('DD.MM.YYYY');
  }

  time(): string {
    return this.timestamp.format('HH:mm');
  }

  tourDatestamp(): moment.Moment {
    return this.tourDate ? moment(this.tourDate) : null;
  }

  key(): string {
    return this.timestamp.format() + '_' + this.status;
  }
}
