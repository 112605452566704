import { useApiContext } from '@contexts/api-context';
import { KnownWebsocketEvent } from '@contexts/api-context/request.types';
import { ConfigType } from '@legacy-modules/dashboard/constants/ConfigType';
import { LimitClassTypes } from '@legacy-modules/dashboard/types/AbstractLimit';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

type OrgConfig = { value: LimitClassTypes; orgKey: string; valueExpressionIdentifier: string };
type UseSaveOrgConfigOutput = UseMutationResult<OrgConfig, unknown, OrgConfig>;

export default function useSaveOrgConfig(): UseSaveOrgConfigOutput {
  const apiCtx = useApiContext();
  const queryClient = useQueryClient();

  const saveOrgConfig = useMutation<OrgConfig, unknown, OrgConfig>(
    ({ valueExpressionIdentifier, orgKey, value }) => {
      return apiCtx.wsFetch<OrgConfig>(KnownWebsocketEvent.ORG_UNIT_CONFIG_SAVE_EVENT, {
        configType: ConfigType.ValueExpressionGoal,
        configKey: valueExpressionIdentifier,
        orgKey,
        value,
      });
    },
    {
      onSuccess(data, variables) {
        queryClient.invalidateQueries({
          queryKey: [ConfigType.ValueExpressionGoal, variables.orgKey, variables.valueExpressionIdentifier],
        });
      },
    }
  );

  return saveOrgConfig;
}
