import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import { MetricTypeKey } from './metric-type-keys';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';
import StaticValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/StaticValueExpression';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';

const expression = new SumValueExpression(
  new SingleValueExpression(
    new MetricType({
      key: MetricTypeKey.TARelevantSum,
      aggregation: AggregationType.sum,
      hidden: true,
      positiveDirection: PositiveDirection.custom,
      label: 'Lademenge',
    })
  ),
  new StaticValueExpression(0), // TODO: check why this is required
  'IST-Lademenge',
  'Anzahl der auf Tour geladenen Sendungen mit mind. einem Stopp.',
  '-#.###.##0',
  getDefinitionByName('IST-Lademenge')
);

expression.chartType = ChartType.bar;
expression.setIdentifier(MetricTypeKey.TARelevantSum);

export default expression;
