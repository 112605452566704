import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import anzahlTagZsbPlus0Successful from './anzahl-tag-zsb-plus-0-successful';
import anzahlTagZsbPlus0 from './anzahl-tag-zsb-plus-0';

const expression = new QuotientValueExpression(
  anzahlTagZsbPlus0Successful,
  anzahlTagZsbPlus0,
  'ZSB+0 Standard',
  true,
  undefined,
  undefined,
  getDefinitionByName('ZSB+0 Standard')
);

expression.chartRenderOptions.startYAxisOnZero = false;
expression.setIdentifier('zsb_plus_0_standard');

export default expression;
