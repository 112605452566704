import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';
import valueExpressionMap from '../common/contexts/value-expression-context/value-expressions';

export const selectDashboard = (state: RootState) => state.dashboard;
export const selectDashboardOrgKey = createSelector(selectDashboard, (dashboardState) => dashboardState.orgKey);
export const selectDashboardContractorViewMode = createSelector(
  selectDashboard,
  (dashboardState) => dashboardState.contractorViewMode
);
export const selectActiveDashboardView = createSelector(
  selectDashboard,
  (dashboardState) => dashboardState.activeDashboardView
);
export const selectBarcode = createSelector(selectDashboard, (dashboardState) => dashboardState.barcode);
export const selectDashboardRoutingParams = createSelector(selectDashboard, (dashboardState) => {
  const { orgKey, contractorViewMode, activeDashboardView } = dashboardState;
  return {
    orgKey,
    contractorViewMode,
    activeDashboardView,
  };
});
export const selectDashboardTrendValueExpression = createSelector(selectDashboard, (dashboardState) => {
  return valueExpressionMap.get(dashboardState.dashboardTrendValueExpressionIdentifier);
});

export const selectLocalComponentOptions = (componentId: string, optionId: string) =>
  createSelector(selectDashboard, (dashboardState) => {
    return dashboardState.localComponentOptions?.[componentId]?.[optionId];
  });
