import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.ComplaintsHappenedCountWithTours,
  label: 'SB (Tour)',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: 'down',
  category: 'complaints',
  description: '<p>Anzahl aller zustelltourbezogenen Servicebeanstandungen</p>\n',
});

const expression = new SingleValueExpression(metric);

expression.setIdentifier('sb_tour');

export default expression;
