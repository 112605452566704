import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';
import StaticValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/StaticValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.TourStreckeAnfahrt,
  label: 'Anfahrtsstrecke',
  valueFormat: '#.###.###.##0 m',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.down,
  category: 'tours',
  description: '',
  hidden: true,
});

const expression = new QuotientValueExpression(
  new SingleValueExpression(metric),
  new StaticValueExpression(1000),
  'Anfahrtsstrecke',
  false,
  '-#.###.##0,0 km',
  undefined,
  getDefinitionByName('Anfahrtsstrecke')
);

expression.setIdentifier('anfahrtsstrecke');

export default expression;
