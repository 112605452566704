import Styles from './AppMainPage.module.scss';
import NavigationBar from '../../navigation/components/NavigationBar';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import SideNavigation from '../../navigation/components/SideNavigation';
import ContentWrapper from '../components/ContentWrapper';
import NetworkCheck from '../../dashboard/components/NetworkCheck';
import { localStorageService } from '@legacy-modules/common/services/LocalStorageService';

export const AppMainPage = () => {
  const [sidebarSmall, setSidebarSmall] = useState(() => localStorageService.getRaw('sideNavigationOpen') === '1');

  useEffect(() => {
    const checkSideBarOpen = () => {
      setSidebarSmall(localStorageService.getRaw('sideNavigationOpen') === '1');
    };
    window.addEventListener('storage', checkSideBarOpen);
    return () => {
      window.removeEventListener('storage', checkSideBarOpen);
    };
  }, []);

  useEffect(() => {
    if (sidebarSmall) {
      window.document.body.classList.add('smallNav');
    } else {
      window.document.body.classList.remove('smallNav');
    }
  }, [sidebarSmall]);

  return (
    <div
      className={classNames(Styles.Wrapper, {
        [Styles.smallSideNav]: sidebarSmall,
      })}>
      <header className={Styles.Header}>
        <NavigationBar />
        <NetworkCheck />
      </header>
      <nav className={Styles.Nav}>
        <SideNavigation />
      </nav>
      <main className={Styles.Main}>
        <ContentWrapper />
      </main>
    </div>
  );
};
