import React, { useCallback, useRef, useState } from 'react';
import { Row, Col, Button, Input, FormGroup, Label } from 'reactstrap';
import { MdFileDownload } from 'react-icons/md';
import { PopOver } from '../../common/components/PopOver';
import Styles from './ExportButton.module.scss';
import { ExportFormat, ExportType } from '@other-components/use-overview-export-hook/use-overview-export.hook';

type Props = {
  onExport: (type: ExportType, format: ExportFormat) => void;
};

const emptyFn = () => {
  /* ignore */
};

const ExportButton = (props: Props) => {
  const { onExport = emptyFn } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<ExportType>('time');

  const closeWindowIfOpen = useCallback(() => {
    if (!open) return;
    setOpen(false);
  }, [open]);

  const ref = useRef();

  const _toggleOpen = useCallback(() => {
    setOpen((v) => !v);
  }, []);

  const _onExportClick = useCallback(
    (format: ExportFormat) => {
      onExport(type, format);
      setOpen(false);
    },
    [onExport, type]
  );

  return (
    <div className={Styles.ExportButton} ref={ref}>
      <div ref={ref}>
        <button className='btn btn-sm btn-white' onClick={_toggleOpen}>
          Exportieren <MdFileDownload />
        </button>
      </div>
      <PopOver
        anchorElement={ref?.current}
        placement={'bottom-start'}
        visible={open}
        onClose={closeWindowIfOpen}
        closeOnClickOutside
        closeOnEscape>
        <div>
          <div className={Styles.Popper}>
            <span className={Styles.Title}>Aufschlüsselung</span>
            <FormGroup check className={Styles.FormCheck}>
              <Label check>
                <Input type='radio' name='export-btn-type' checked={type === 'time'} onChange={() => setType('time')} />
                nach Zeit
              </Label>
            </FormGroup>
            <FormGroup check className={Styles.FormCheck}>
              <Label check>
                <Input
                  type='radio'
                  name='export-btn-type'
                  checked={type === 'organization'}
                  onChange={() => setType('organization')}
                />
                nach Organisation
              </Label>
            </FormGroup>
            <Row className={Styles.Row}>
              <Col xs={6} className={Styles.Col}>
                <Button size='sm' block outline color='primary' onClick={() => _onExportClick('xlsx')}>
                  Excel
                </Button>
              </Col>
              <Col xs={6} className={Styles.Col}>
                <Button size='sm' block outline color='primary' onClick={() => _onExportClick('csv')}>
                  CSV
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </PopOver>
    </div>
  );
};

export default ExportButton;
