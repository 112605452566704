import { MetricsQueryResponsePayloadValue } from './MetricsQueryResponsePayloadValue';
import BasePayload from '../../../../common/models/websocket/payloads/BasePayload';
import { DefaultType, MemberType } from '../../../../utils/Model';
import { PlainObjectOf } from '../org/OrgUnitTreeRequestPayload';

export default class MetricsOverviewQueryResponsePayload extends BasePayload<MetricsOverviewQueryResponsePayload> {
  values: Array<MetricsQueryResponsePayloadValue>;

  constructor(source: PlainObjectOf<MetricsOverviewQueryResponsePayload>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<MetricsOverviewQueryResponsePayload> {
    return undefined;
  }

  getMembers(): MemberType<MetricsOverviewQueryResponsePayload> {
    return undefined;
  }
}
