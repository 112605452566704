import React, { createContext, PropsWithChildren, ReactElement, useContext } from 'react';
import valueExpressionMap from './value-expressions';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { useAuthContext } from '@contexts/auth-context';
import { WellKnownPermission } from '@legacy-modules/auth/constants/WellKnownPermission';
import nettoZustellzeitAlt from '../value-expression-context/value-expressions/netto-zustellzeit-alt';
import nettoZustellzeitAltAvg from '../value-expression-context/value-expressions/netto-zustellzeit-alt-avg';
import produktivitaetAlt from '../value-expression-context/value-expressions/produktivitaet-alt';
import servicebeanstandungenMeldungVerlustrelevant from '../value-expression-context/value-expressions/servicebeanstandungen-meldung-verlustrelevant';
import servicebeanstandungenMeldungGrund from '../value-expression-context/value-expressions/servicebeanstandungen-meldung-grund';
import servicebeanstandungenMeldung from '../value-expression-context/value-expressions/servicebeanstandungen-meldung';
import servicebeanstandungenZustelltour from '../value-expression-context/value-expressions/servicebeanstandungen-zustelltour';
import servicebeanstandungenAvg from '../value-expression-context/value-expressions/servicebeanstandungen-avg';
import servicebeanstandungenQuote from '../value-expression-context/value-expressions/servicebeanstandungen-quote';
import servicebeanstandungenGruende from '../value-expression-context/value-expressions/servicebeanstandungen-gruende';
import anfahrtsstreckeAvgV2 from './value-expressions/anfahrtsstrecke-avg-v2';
import tourenV2 from './value-expressions/touren-v2';

export type ValueExpressionContextType = Map<string, ValueExpression>;

const defaultValue: ValueExpressionContextType = new Map();
export const ValueExpressionContext = createContext(defaultValue);

export const useValueExpressionContext = (): ValueExpressionContextType => {
  return useContext<ValueExpressionContextType>(ValueExpressionContext);
};

type ValueExpressionContextProviderProps = Required<PropsWithChildren<{}>>;

export default function ValueExpressionContextProvider({
  children,
}: ValueExpressionContextProviderProps): ReactElement {
  const authService = useAuthContext();
  if (!authService || authService.cannot(WellKnownPermission.ShowOldProduktivitaet)) {
    valueExpressionMap.delete(nettoZustellzeitAlt.identifier);
    valueExpressionMap.delete(nettoZustellzeitAltAvg.identifier);
    valueExpressionMap.delete(produktivitaetAlt.identifier);
  }
  if (!authService || authService.cannot('complaints')) {
    valueExpressionMap.delete(servicebeanstandungenMeldungVerlustrelevant.identifier);
    valueExpressionMap.delete(servicebeanstandungenMeldungGrund.identifier);
    valueExpressionMap.delete(servicebeanstandungenMeldung.identifier);
    valueExpressionMap.delete(servicebeanstandungenZustelltour.identifier);
    valueExpressionMap.delete(servicebeanstandungenAvg.identifier);
    valueExpressionMap.delete(servicebeanstandungenQuote.identifier);

    servicebeanstandungenGruende.forEach((valueExpression) => {
      valueExpressionMap.delete(valueExpression.identifier);
    });
  }
  if (!authService || authService.cannot(WellKnownPermission.ShowPrototypeGeneral)) {
    valueExpressionMap.delete(anfahrtsstreckeAvgV2.identifier);
    valueExpressionMap.delete(tourenV2.identifier);
  }
  return <ValueExpressionContext.Provider value={valueExpressionMap}>{children}</ValueExpressionContext.Provider>;
}
