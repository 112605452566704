import React from 'react';
import Styles from './FilterGroup.module.scss';

type Props = {
  children: React.ReactNode;
};

export const FilterGroup = React.forwardRef<HTMLDivElement, Props>(({ children }: Props, ref) => {
  return (
    <div className={Styles.FilterGroup} ref={ref}>
      {children}
    </div>
  );
});
