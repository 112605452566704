import { FilterButton } from '../components/FilterButton';
import { AnomalyWarnIcon } from '../components/AnomalyList';
import React, { useMemo } from 'react';
import { FilterGroup } from '../components/FilterGroup';
import { useTourDetailsContext } from '@other-components/tour-details-context';

type Props = {};

const AnomalyFilterGroup: React.FC<Props> = () => {
  const { allAnomalies, showOnlyWithAnomalies, toggleShowOnlyWithAnomalies } = useTourDetailsContext();

  const [stopCount, shipmentCount] = useMemo(() => {
    const counter = allAnomalies?.reduce(
      (acc, anomaly) => {
        acc.stops += anomaly?.tourStopRefs?.length || 0;
        acc.shipments +=
          anomaly?.tourStopRefs?.map((ref) => ref?.shipmentRefs?.length || 0)?.reduce((a, b) => a + b, 0) || 0;
        return acc;
      },
      { stops: 0, shipments: 0 }
    );
    return [counter.stops, counter.shipments];
  }, [allAnomalies]);

  return (
    <FilterGroup>
      <FilterButton
        onSelect={toggleShowOnlyWithAnomalies}
        tooltipText={
          <>
            Hinweise <br />
            Stopps: {stopCount} <br />
            Hinweise: {shipmentCount}
          </>
        }
        active={showOnlyWithAnomalies}
        count={stopCount}
        disabled={!stopCount}
        customIcon={<AnomalyWarnIcon />}
      />
    </FilterGroup>
  );
};

export { AnomalyFilterGroup };
