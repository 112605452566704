import { DraggableList } from '@components/draggable-list';
import { useDataTableContext } from '@data-table/contexts/data-table-context';
import { DataTableColumnMeta } from '@data-table/data-table.types';
import React, { ComponentType, HTMLAttributes, ReactElement, useCallback, useState } from 'react';
import { useLayer, UseLayerProps } from 'react-laag';
import style from './data-table-settings.module.scss';

type DataTableSettingsProps = HTMLAttributes<HTMLDivElement> & {
  header?: ReactElement;
  disabled?: boolean;
};
export type DataTableSettingsComponentProps = HTMLAttributes<HTMLDivElement> & {
  settingsProps: UseLayerProps;
  showSettings: boolean;
  header: ReactElement;
  disabled?: boolean;
  toggleSettings: () => void;
};
const DefaultHeader = () => {
  return <div className={style.configurationTitle}>Tabelle konfigurieren</div>;
};
const DefaultContent = () => {
  const { table } = useDataTableContext();

  const headers = table.getHeaderGroups()?.[0]?.headers;

  return (
    <DraggableList.List
      direction='vertical'
      sortableContextProps={{
        items: headers,
      }}>
      {headers.map((header) => (
        <DraggableList.Item
          key={header.id}
          id={header.id}
          pinningEnabled={false}
          sortableArguments={{
            id: header.id,
            disabled: !header.column.getIsPinned(),
          }}>
          {(header.column.columnDef.meta as DataTableColumnMeta).exportValue}
        </DraggableList.Item>
      ))}
    </DraggableList.List>
  );
};

export const withDataTableSettings =
  (Component: ComponentType<DataTableSettingsComponentProps>) =>
  ({ children = <DefaultContent />, header = <DefaultHeader />, ...props }: DataTableSettingsProps): ReactElement => {
    const [showSettings, setShowSettings] = useState(false);

    const { table, columnOrder, tableWrapperRef } = useDataTableContext();

    const toggleSettings = useCallback(() => {
      setShowSettings((showSettings) => {
        if (showSettings) {
          // Ensure column order is updated when settings get closed
          table.setColumnOrder(columnOrder);
        }
        return !showSettings;
      });
    }, [columnOrder, table]);

    const onClose = useCallback(() => {
      // Ensure column order is updated when settings get closed
      table.setColumnOrder(columnOrder);
      setShowSettings(false);
    }, [columnOrder, table]);

    const settingsProps = useLayer({
      isOpen: showSettings,
      onOutsideClick: onClose, // close the menu when the user clicks outside
      onParentClose: onClose, // close the menu when the parent component gets unmounted
      onDisappear: onClose, // close the menu when the menu gets scrolled out of sight
      overflowContainer: true, // keep the menu positioned inside the container
      auto: true, // automatically find the best placement
      placement: 'left-start', // we prefer to place the menu "top-end"
      triggerOffset: 20, // keep some distance to the trigger
      preferX: 'left', // we prefer to align the menu to the left
      preferY: 'bottom', // we prefer to align the menu to the bottom
      container: tableWrapperRef?.current,
    });

    return (
      <Component
        {...props}
        settingsProps={settingsProps}
        showSettings={showSettings}
        toggleSettings={toggleSettings}
        header={header}>
        {children}
      </Component>
    );
  };
