import moment from 'moment-timezone';
import MetricsBaseQuery from '../../../metrics2/models/queries/MetricsBaseQuery';
import { WeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';

export default class ValueExpressionBaseQuery {
  dateFrom: moment.Moment;
  dateUntil: moment.Moment;
  valueKey: string | null | undefined;

  constructor(
    dateFrom: moment.Moment,
    dateUntil: moment.Moment,
    public weekdayFilter: WeekdaysFilter,
    valueKey: string | null | undefined = null
  ) {
    this.dateFrom = dateFrom;
    this.dateUntil = dateUntil;
    this.valueKey = valueKey;
  }

  getMetricQueries(): Array<MetricsBaseQuery> {
    return [];
  }
}
