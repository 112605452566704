import { $Keys } from 'utility-types';

const ReturnTypes = Object.freeze({
  ReturnToSender: 'ReturnToSender',
  NewParcel: 'NewParcel',
  DBCourier: 'DBCourier',
  Undeliverable: 'Undeliverable',
});

export type ReturnType = $Keys<typeof ReturnTypes>;

export default ReturnTypes;
