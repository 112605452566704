import React from 'react';

type Props = {
  children: string;
};

export const NewLineText = (props: Props) => {
  const text = props.children;
  return (
    <>
      {text.split('\\n').map((text, i) => (
        <React.Fragment key={`${text}-${i}`}>
          {text}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};
