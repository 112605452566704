import React, { HTMLAttributes, ReactElement } from 'react';
import style from './data-table-footer.module.scss';
import { flexRender } from '@tanstack/react-table';
import { useDataTableContext } from '@data-table/contexts/data-table-context';
import { DataTableColumnMeta } from '@data-table/data-table.types';

export type DataTableFooterProps = HTMLAttributes<HTMLDivElement>;
const DataTableFooter = (props: DataTableFooterProps): ReactElement => {
  const { table } = useDataTableContext();

  const headers = table.getFooterGroups()?.[0]?.headers || [];

  return (
    <div
      data-testid='data-table-footer'
      className={style.footerRow}
      style={{
        gridTemplateColumns: headers
          .map((header) => {
            return (header.column.columnDef?.meta as DataTableColumnMeta)?.columnStyle?.gridColumn;
          })
          .join(' '),
      }}
      {...props}>
      {headers.map((header) =>
        flexRender(header.column.columnDef.footer, {
          ...header.getContext(),
          key: header.id,
        })
      )}
    </div>
  );
};

export default DataTableFooter;
