import React, { HTMLAttributes, ReactElement, useCallback } from 'react';
import style from './fallback-component.module.scss';
import classNames from 'classnames';
import Lottie, { LottieProps } from 'react-lottie-player';
import { Button } from 'reactstrap';
import CloudSad from '../../../assets/lottie/cloud/cloud_sad.json';

const defaultLottieProps: LottieProps = {
  className: style.cloud,
  loop: true,
  play: true,
  animationData: CloudSad,
  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
};

export type FallbackComponentProps = HTMLAttributes<HTMLDivElement> & {
  title: string;
  body?: string;
  lottieProps?: LottieProps;
  returnActionTitle?: string;
  onReturnClick?: () => void;
};

export function FallbackComponent({
  title = 'Unerwarteter Fehler',
  body,
  returnActionTitle = 'Zurück',
  lottieProps,
  onReturnClick,
  ...divProps
}: FallbackComponentProps): ReactElement {
  const goBack = useCallback(() => {
    history.back();
  }, []);

  return (
    <div className={classNames(style.fallbackComponent)} {...divProps}>
      <div className={style?.title}>{title}</div>
      {body && <div className={style?.body}>{body}</div>}
      <Lottie {...defaultLottieProps} {...lottieProps} />
      <Button outline color='secondary' onClick={onReturnClick ?? goBack}>
        {returnActionTitle}
      </Button>
    </div>
  );
}
export default FallbackComponent;
