import moment from 'moment-timezone';
import { PlainObjectOf } from '../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export enum DurationModes {
  Today = 'today',
  Current = 'current',
  Yesterday = 'yesterday',
  Last = 'last',
  LastMonth = 'last_month',
  Custom = 'custom',
  Forecast = 'forecast',
}

export type Weekday = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
export type WeekdaysFilter = {
  [key in Weekday]: boolean;
};
export const defaultWeekdaysFilter: WeekdaysFilter = {
  mon: true,
  tue: true,
  wed: true,
  thu: true,
  fri: true,
  sat: true,
  sun: true,
};

export type Duration = {
  key: DurationKey;
  mode: DurationModes;
  label: string;
  offset: number;
  duration: number;
  weekdayFilter?: WeekdaysFilter;
  range: moment.unitOfTime.DurationConstructor;
  isVisibleInForecast: boolean;
  from: moment.Moment;
  to: moment.Moment;
  amountOfWorkDays?: number;
};

export const createDurationFromPlainObject = (plain: PlainObjectOf<Duration> | Duration | null): Duration => {
  if (!plain) return null;

  if ((plain.from && typeof plain.from !== 'string') || (plain.to && typeof plain.to !== 'string')) {
    return plain as Duration;
  }

  return {
    ...plain,
    from: plain.from ? moment(plain.from) : null,
    to: plain.to ? moment(plain.to) : null,
  } as Duration;
};

export enum DurationKey {
  TODAY = 'today',
  // If current day is monday -> use saturday
  YESTERDAY_OR_SATURDAY = 'yesterday',
  MONTH = 'month',
  LAST_MONTH = 'last_month',
  LAST_7_DAYS = 'last_7',
  LAST_28_DAYS = 'last_28',
  LAST_91_DAYS = 'last_90',
  NEXT_28 = 'next_28',
  NEXT_56 = 'next_56',
  CUSTOM = 'custom',
}

export type DurationsType = Omit<Duration, 'from' | 'to'>;

const isMonday = () => {
  return moment().isoWeekday() === 1;
};

/**
 * Make sure that the given date displays "Vorgestern" if the current day is
 * a monday as there aren't any delivery tours on a sunday.
 * @param duration
 */
export const calculateYesterday = <T extends { mode: DurationModes; label: string; offset: number }>(duration: T) => {
  if (!duration) return null;
  if (duration.mode !== DurationModes.Yesterday) return duration;
  if (!isMonday()) return duration;
  return {
    ...duration,
    label: 'Vorgestern',
    offset: -2,
  };
};

export const Durations: {
  [key in DurationKey]: DurationsType;
} = {
  [DurationKey.TODAY]: {
    key: DurationKey.TODAY,
    mode: DurationModes.Today,
    offset: 0,
    duration: 1,
    range: 'days',
    label: 'Heute',
    isVisibleInForecast: false,
  },
  [DurationKey.YESTERDAY_OR_SATURDAY]: calculateYesterday({
    key: DurationKey.YESTERDAY_OR_SATURDAY,
    mode: DurationModes.Yesterday,
    offset: -1,
    duration: 1,
    range: 'days',
    label: 'Gestern',
    isVisibleInForecast: false,
  }),
  [DurationKey.MONTH]: {
    key: DurationKey.MONTH,
    mode: DurationModes.Current,
    offset: 0,
    duration: 1,
    range: 'month',
    label: 'Aktueller Monat',
    isVisibleInForecast: false,
  },
  [DurationKey.LAST_MONTH]: {
    key: DurationKey.LAST_MONTH,
    mode: DurationModes.LastMonth,
    offset: -1,
    duration: 1,
    range: 'month',
    label: 'Letzter Monat',
    isVisibleInForecast: false,
  },
  [DurationKey.LAST_7_DAYS]: {
    key: DurationKey.LAST_7_DAYS,
    mode: DurationModes.Last,
    offset: -7,
    duration: 7,
    range: 'days',
    label: 'Letzte 7 Tage',
    isVisibleInForecast: true,
  },
  [DurationKey.LAST_28_DAYS]: {
    key: DurationKey.LAST_28_DAYS,
    mode: DurationModes.Last,
    offset: -28,
    duration: 28,
    range: 'days',
    label: 'Letzte 28 Tage',
    isVisibleInForecast: false,
  },
  [DurationKey.LAST_91_DAYS]: {
    key: DurationKey.LAST_91_DAYS,
    mode: DurationModes.Last,
    offset: -91,
    duration: 91,
    range: 'days',
    label: 'Letztes Vierteljahr',
    isVisibleInForecast: false,
  },
  [DurationKey.NEXT_28]: {
    key: DurationKey.NEXT_28,
    mode: DurationModes.Forecast,
    offset: 0,
    duration: 28,
    range: 'days',
    label: '4-Wochen-Prognose',
    isVisibleInForecast: true,
  },
  [DurationKey.NEXT_56]: {
    key: DurationKey.NEXT_56,
    mode: DurationModes.Forecast,
    offset: 0,
    duration: 56,
    range: 'days',
    label: 'Aktuelle Prognose',
    isVisibleInForecast: true,
  },
  [DurationKey.CUSTOM]: {
    key: DurationKey.CUSTOM,
    mode: DurationModes.Custom,
    label: 'Benutzerdefiniert',
    isVisibleInForecast: false,
    offset: NaN,
    duration: NaN,
    range: 'days',
  },
};
