/* eslint-disable brace-style */
/* eslint-disable max-len */
import moment from 'moment-timezone';
import ExtendedEventEmitter from '../../events/ExtendedEventEmitter';
import { DataConnectionMetaData } from '../models/types/DataConnectionMetaData';
import { DataConnectionInterface } from './DataConnectionInterface';
import { PublisherInterface } from '../../events/PublisherInterface';
import * as events from 'events';
import { QueryInterface } from '../../datamanager/models/queries/QueryInterface';

export default class DataConnection<EK = QueryInterface, V = any>
  implements DataConnectionInterface<V>, PublisherInterface
{
  static EVENT_LOADING = 'loading';
  static EVENT_DATA_CHANGED = 'data';
  static EVENT_TERMINATED = 'terminated';
  _eventEmitter: ExtendedEventEmitter;

  constructor() {
    this._eventEmitter = new ExtendedEventEmitter();
    this._eventEmitter.registerListenerInterface(this);
    this._triggerDataChanged = this._triggerDataChanged.bind(this);
    this._triggerLoading = this._triggerLoading.bind(this);
  }

  terminate() {
    this._eventEmitter.emit(DataConnection.EVENT_TERMINATED);
  }

  _triggerDataChanged() {
    this._eventEmitter.emit(DataConnection.EVENT_DATA_CHANGED, this.data, this.meta);
  }

  _triggerLoading() {
    this._eventEmitter.emit(DataConnection.EVENT_LOADING);
  }

  get meta(): DataConnectionMetaData {
    return {
      completed: this.isComplete,
      loading: this.isLoading,
      lastUpdatedAt: this.lastUpdatedAt,
    };
  }

  get isComplete(): boolean {
    return false;
  }

  get isLoading(): boolean {
    return false;
  }

  get lastUpdatedAt(): moment.Moment {
    return moment();
  }

  get data(): any {
    return null;
  }

  get rawData(): Map<EK, V> {
    return new Map<EK, V>();
  }

  addListener(eventName: string, listener: Function): events.EventEmitter {
    return undefined;
  }

  on(eventName: string, listener: Function): events.EventEmitter {
    return undefined;
  }

  once(eventName: string, listener: Function): events.EventEmitter {
    return undefined;
  }

  removeListener(eventName: string, listener: Function): events.EventEmitter {
    return undefined;
  }
}
