import { raaReasons, raaReasonsMap } from '@legacy-modules/metrics2/models/entities/MetricType';
import ruecklaufAbholkartenGrund from './ruecklauf-abholkarten-grund';
import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import { UndeliverableReasonAbaShortLabels } from '@legacy-modules/metrics2/models/enumerations/UndeliverableReason';

export default raaReasons.flatMap((key) => {
  const expression = new MapDetailValueExpression(
    ruecklaufAbholkartenGrund.metricType,
    key,
    raaReasonsMap[key] ?? `unbekannt (${key})`
  );
  expression.shortLabel = UndeliverableReasonAbaShortLabels[key];
  expression.setIdentifier(`aba_ruecklauefer_gruende.${key}`); // TODO: fix typo ruecklaufer
  return [expression, ...(expression.getChildren() || [])];
});
