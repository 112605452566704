import React, { ReactElement } from 'react';
import style from './kpi-slider.module.scss';
import { motion, AnimatePresence } from 'framer-motion';
import TourKpiSelectorRow from '@legacy-modules/tour/components/TourKpiSelectorRow';
import { RadioButton } from '@components/radio-button';
import { WithKpiSliderOutputProps, withKpiSlider } from './with-kpi-slider-hoc';

export function KpiSlider({
  kpiPageState,
  firstPageKennzahlen,
  secondPageKennzahlen,
  filteredKennzahlen,
  // this prop (allKennzahlen) is not used in this component, but is needed for tests.
  // It is passed down from the withKpiSlider HOC
  // if you remove this prop, it will still be passed to DOM, this is not intended
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  allKennzahlen,
  onKennzahlChange,
  ...htmlProps
}: WithKpiSliderOutputProps): ReactElement {
  const [kpiPage] = kpiPageState;

  return (
    <div {...htmlProps} className={style.kpiCarousel}>
      <AnimatePresence initial={false}>
        {kpiPage === '0' && (
          <motion.div
            key='kpi-slider-page-1'
            data-testid='kpi-slider-page-1'
            data-active={kpiPage === '0'}
            className={style.kpiCarouselPage}
            animate={{ x: 0, opacity: 1, position: 'absolute', zIndex: 1 }}
            initial={{ x: '-100%', opacity: 0, position: 'absolute', zIndex: 0 }}
            transition={{ type: 'spring', bounce: 0.15 }}>
            {firstPageKennzahlen.map((valueExpressionKey, index) => (
              <TourKpiSelectorRow
                valueExpressionKey={valueExpressionKey}
                selectedValueExpressionKeys={filteredKennzahlen}
                key={valueExpressionKey ?? index}
                onKennzahlChange={onKennzahlChange(index)}
              />
            ))}
          </motion.div>
        )}
        {kpiPage === '1' && (
          <motion.div
            key='kpi-slider-page-2'
            data-testid='kpi-slider-page-2'
            data-active={kpiPage === '1'}
            className={style.kpiCarouselPage}
            initial={{ x: '100%', opacity: 0, position: 'absolute', zIndex: 0 }}
            animate={{ x: 0, opacity: 1, position: 'absolute', zIndex: 1 }}
            transition={{ type: 'spring', bounce: 0.15 }}>
            {secondPageKennzahlen.map((valueExpressionKey, index) => (
              <TourKpiSelectorRow
                valueExpressionKey={valueExpressionKey}
                selectedValueExpressionKeys={filteredKennzahlen}
                key={valueExpressionKey ?? index}
                onKennzahlChange={onKennzahlChange(index + firstPageKennzahlen.length)}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      <div className={style.kpiCarouselControls}>
        <RadioButton
          id='kpi-slider-page-1-toggle'
          data-testid='kpi-slider-page-1-toggle'
          aria-label='Kennzahlen Seite 1'
          name='kpi-pagination'
          value='0'
          state={kpiPageState}
          pixelSize={16}
        />
        <RadioButton
          id='kpi-slider-page-2-toggle'
          data-testid='kpi-slider-page-2-toggle'
          aria-label='Kennzahlen Seite 2'
          name='kpi-pagination'
          value='1'
          state={kpiPageState}
          pixelSize={16}
        />
      </div>
    </div>
  );
}
export default withKpiSlider(KpiSlider);
