import React from 'react';
import DataTable from '@data-table';
import Styles from './TourTablePage.module.scss';

const defaultSorting = [
  { id: 'tourIdentifier', desc: true },
  { id: 'orgName', desc: true },
];
export const TourTablePage = () => {
  return (
    <div className={Styles.TourTablePage}>
      <DataTable.TourenDataTable componentId='touren.table' defaultSorting={defaultSorting} />
    </div>
  );
};
