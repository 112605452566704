import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { StopDetails } from '../../../refactoring/other/stop-details';
import { TourDetailListContextProvider } from '../../../refactoring/other/tour-detail-list-context';
import { TextLoader } from '../../common/components/TextLoader';
import FinishedDeliveryStopDetail from '../components/FinishedDeliveryStopDetail';
import { TourDetailsDeliveryTable } from '../components/TourDetailsDeliveryTable';
import TourFilterPanel from '../components/TourFilterPanel';
import { StopTypeType } from '../models/state/TourDetailsState';
import { buildShipmentIdToCustomerIdMap } from '../utils/ShipmentUtils';
import { useAuthContext } from '@contexts/auth-context';
import {
  selectTourDetailsTourIdentifier,
  selectTourDetailsContractorKey,
  selectTourDetailsSelectedStop,
} from '@redux/tour-details.selectors';
import { selectTokenId } from '@redux/auth.selectors';
import { useTourDetailsContext } from '@other-components/tour-details-context';

import '../assets/tourDetailsStatsContainerStyle.scss';

const TourDetailsStatsContainer: React.FC = () => {
  const tourIdentifier = useSelector(selectTourDetailsTourIdentifier);
  const contractorKey = useSelector(selectTourDetailsContractorKey);
  const tokenId = useSelector(selectTokenId);
  const selectedStop = useSelector(selectTourDetailsSelectedStop);

  const {
    tourDetails,
    tourDetailsInTime,
    filteredFinishedDeliveries: filteredFDList,
    tourDetailsLoading,
  } = useTourDetailsContext();

  const authService = useAuthContext();

  const deliveryItems = useMemo(() => {
    if (!tourDetails?.deliveryItems) return [];
    return Array.from(tourDetails.deliveryItems.values());
  }, [tourDetails?.deliveryItems]);
  const allFinishedDeliveries = useMemo(
    () => Object.values(tourDetails?.finishedDeliveries || {}),
    [tourDetails?.finishedDeliveries]
  );
  const unfinishedCustomers = useMemo(() => get(tourDetailsInTime, 'unfinishedCustomers', []), [tourDetailsInTime]);

  const _renderStopDetail = useCallback(() => {
    if (!selectedStop) {
      return <div />;
    }

    if (selectedStop.type === StopTypeType.Processed) {
      if (!tourDetails) return <div />;

      const { finishedDeliveries = {} } = tourDetails;

      const finishedDeliveriesForStop = Array.from(Object.values(finishedDeliveries)).filter(
        (f) => f.displayableStopNumber === selectedStop.stopNumber
      );

      if (!finishedDeliveriesForStop.length) return null;

      const finishedDelivery = finishedDeliveriesForStop[0];

      const url = finishedDelivery.signatureURL(tourIdentifier, tokenId);
      const cd = Object.values(finishedDelivery.customerDeliveries);

      if (cd.length >= 1) {
        const customers = cd.map((c) => tourDetails.customers.get(c.customerRef));
        const { deliveryItems, returnDeliveryItems } = finishedDelivery;

        return (
          <FinishedDeliveryStopDetail
            url={url}
            finishedDelivery={finishedDelivery}
            customers={customers}
            deliveryItems={deliveryItems}
            returnDeliveryItems={returnDeliveryItems}
            shipmentIdToCustomerMap={buildShipmentIdToCustomerIdMap(
              tourDetails.deliveryItems,
              Object.values(tourDetails.finishedDeliveries)
            )}
          />
        );
      }
    } else if (selectedStop.type === StopTypeType.Open || selectedStop.type === StopTypeType.Planned) {
      const customer = tourDetails?.customers?.get(selectedStop.customerRef);
      if (!customer) return null;

      const deliveryItems = Array.from(tourDetails?.deliveryItems?.values()).filter(
        (item) => item.customerRef === customer.customerRef
      );
      const tourPlanStop =
        selectedStop.type === StopTypeType.Planned
          ? tourDetails.plannedStops.find(
              (s) => s.shipments?.includes(deliveryItems[0].id) || s.collectionOrders?.includes(deliveryItems[0].id)
            )
          : null;
      return <StopDetails customer={customer} deliveryItems={deliveryItems} tourPlanStop={tourPlanStop} />;
    }
  }, [selectedStop, tokenId, tourDetails, tourIdentifier]);

  const isAllowedToSeeHermesTours = authService.can('ui.tour.employment-types.all');
  const isDriverOfGeneralContractor = tourDetails?.driver?.employmentType !== 'internal';
  const isAllowedToSeeTourDetails = authService.can('ui.tour.see-details');

  if (selectedStop) {
    return _renderStopDetail();
  }

  if (tourDetailsLoading && !tourDetails) {
    return (
      <TextLoader
        style={{
          fontSize: '1.2rem',
          textAlign: 'center',
          color: 'var(--black2)',
          opacity: '0.7',
        }}
        text={'Daten werden geladen...'}
      />
    );
  } else if (!tourDetails) {
    return <div />;
  }

  return (
    <div className='flex-full tour-stats-container' style={{ flexDirection: 'column' }}>
      {isAllowedToSeeTourDetails && (isDriverOfGeneralContractor || isAllowedToSeeHermesTours) && (
        <TourDetailListContextProvider>
          <div className='tour-detail'>
            <TourFilterPanel tourDetails={tourDetails} />

            <TourDetailsDeliveryTable
              deliveryItems={deliveryItems}
              allFinishedDeliveries={allFinishedDeliveries}
              customers={tourDetails.customers}
              appInfo={tourDetails?.appInfo}
              anomalies={tourDetails?.anomalies}
              unfinishedCustomers={unfinishedCustomers}
              tourIdentifier={tourIdentifier}
              contractorKey={contractorKey}
              filteredFDList={filteredFDList}
            />
          </div>
        </TourDetailListContextProvider>
      )}
    </div>
  );
};

export default TourDetailsStatsContainer;
