import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import nettoZustellzeit from './netto-zustellzeit';
import touren from './touren';

const expression = new QuotientValueExpression(
  nettoZustellzeit,
  touren,
  'Ø Netto-Zustellzeit',
  false,
  '-#.###.##0,0 h',
  'Zeit vom ersten bis zum letzten Stopp bereinigt um Pausenzeiten.',
  getDefinitionByName('Netto-Zustellzeit')
);

expression.setIdentifier('netto-zustellzeit/touren');

export default expression;
