import { shipmentTypeMap, shipmentTypes } from '@legacy-modules/metrics2/models/entities/MetricType';
import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import lademengeSendungsart from './lademenge-sendungsart';

export default shipmentTypes.flatMap((key) => {
  const expression = new MapDetailValueExpression(
    lademengeSendungsart.metricType,
    key,
    shipmentTypeMap[key] ?? `unbekannt (${key})`
  );
  expression.setIdentifier(`lademenge_sendungsarten.${key}`); // TODO: fix typo ruecklaufer
  return [expression, ...(expression.getChildren() || [])];
});
