import { useMemo } from 'react';
import { OrgKey } from '@data-table/data-table.types';
import { getTableHeadlineForOrgKey, isTourOrgKey, isToday as getIsToday } from '@data-table/utils';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { ContractorViewMode } from '@legacy-modules/navigation/constants/ContractorViewMode';
import { ContractorUtils } from '@legacy-modules/utils/tours/ContractorUtils';
import { useSelector } from 'react-redux';
import { selectDashboardContractorViewMode } from '@redux/dashboard.selectors';

type UseDataTableCustomInfoOutput = {
  isTour: boolean;
  isToday: boolean;
  title: string;
  showStandort: boolean;
  showFortschritt: boolean;
};

const useDataTableCustomInfo = (orgKey: OrgKey, duration: Duration): UseDataTableCustomInfoOutput => {
  const vertragsPartnerViewMode = useSelector(selectDashboardContractorViewMode);

  const [isTour, title, showStandort] = useMemo(() => {
    const isTour = isTourOrgKey(orgKey);

    const title = getTableHeadlineForOrgKey(orgKey);

    const showStandort =
      isTour &&
      ContractorUtils.isContractor(orgKey) &&
      (vertragsPartnerViewMode === ContractorViewMode.All || vertragsPartnerViewMode === ContractorViewMode.TaskForce);

    return [isTour, title, showStandort];
  }, [orgKey, vertragsPartnerViewMode]);

  const isToday = useMemo(() => getIsToday(duration), [duration]);

  const showFortschritt = useMemo(() => {
    return isTour && isToday;
  }, [isTour, isToday]);

  return {
    isTour,
    isToday,
    title,
    showStandort,
    showFortschritt,
  };
};

export default useDataTableCustomInfo;
