import React from 'react';
import Styles from './IconButton.module.scss';
import classNames from 'classnames';

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  style?: React.CSSProperties;
  icon: React.ReactChild;
  children?: React.ReactNode;
};

const IconButton = ({ onClick, className, style = {}, icon, children }: Props) => {
  return (
    <button style={style} onClick={(e) => onClick(e)} className={classNames(Styles.IconButton, className)}>
      {icon}
      {children}
    </button>
  );
};

export default IconButton;
