import { DataTableComponentId, DataTableUserConfig } from '@data-table/data-table.types';
import { UpdateUserConfigFn, useUserConfig } from '@hooks/use-user-config-hook';
import { useCallback, useMemo } from 'react';

const defaultColoringToggle = true;

type UseDataTableCellColoringOutput = [boolean, UpdateUserConfigFn<boolean>];

const useDataTableCellColoring = (
  componentId: DataTableComponentId,
  defaultState: boolean = defaultColoringToggle
): UseDataTableCellColoringOutput => {
  const defaultValue = useMemo(() => ({ hasCellColoring: defaultState }), [defaultState]);

  const [dataTableUserConfig, updateDataTableUserConfig] = useUserConfig<DataTableUserConfig>(
    componentId,
    defaultValue
  );

  const hasCellColoring = dataTableUserConfig?.hasCellColoring || defaultState;

  const updateCellColoring = useCallback(
    (hasCellColoring) => {
      updateDataTableUserConfig({
        ...dataTableUserConfig,
        hasCellColoring,
      });
    },
    [dataTableUserConfig, updateDataTableUserConfig]
  );

  return [hasCellColoring, updateCellColoring];
};

export default useDataTableCellColoring;
