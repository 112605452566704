import SystemAlertsPayload from '../models/websocket/payloads/system/SystemAlertsPayload';
import RequestScheduler from '../../datamanager/services/RequestScheduler';
import AuthService, { AuthEvent } from '../../common/services/AuthService';
import { localStorageService } from '../../common/services/LocalStorageService';
import { QueryPriorities } from '../../datamanager/models/enumerations/QueryPriority';
import Alert from '../models/Alert';
import Logger from '../../utils/logging/Logger';
import { lmaReduxStore } from '@redux/store';
import { appSlice } from '@redux/app.slice';

export default class AlertsService {
  static queryInterval = 60000;
  static backoffInterval = AlertsService.queryInterval * 60;
  requestScheduler: RequestScheduler;
  authService: AuthService;
  logger: Logger;
  running: boolean;

  constructor(requestScheduler: RequestScheduler, authService: AuthService) {
    this.requestScheduler = requestScheduler;
    this.authService = authService;
    this.logger = Logger.getInstance('AlertsService');
    this.running = false;

    this.requestScheduler.websocketApiService.socketEventFormatter.registerEventType(
      'system.alerts',
      SystemAlertsPayload
    );
    this.authService.eventEmitter.on(AuthEvent.EVENT_LOGGED_IN, this._onLoggedIn.bind(this));
  }

  destruct() {
    this.running = false;
    this.authService.eventEmitter.removeListener(AuthEvent.EVENT_LOGGED_IN, this._onLoggedIn.bind(this));
  }

  async _onLoggedIn() {
    if (!this.running) {
      this._queryAlerts();
      this.running = true;
    }
  }

  _queryAlerts() {
    const alertsPromise = this.fetchAlerts();
    alertsPromise
      .then((alerts) => {
        const alertSeenTimestamp = Number(localStorageService.getRaw('alertSeenTimestamp', '0'));
        lmaReduxStore.dispatch(appSlice.actions.setAlerts(JSON.parse(JSON.stringify(alerts))));
        lmaReduxStore.dispatch(appSlice.actions.setAlertSeenTime(alertSeenTimestamp));
        if (this.running) {
          setTimeout(() => this._queryAlerts(), AlertsService.queryInterval);
        }
      })
      .catch(() => {
        if (this.running) {
          setTimeout(() => this._queryAlerts(), AlertsService.backoffInterval);
        }
      });
    return alertsPromise;
  }

  acknowledgeAlerts(): void {
    const alertSeenTime = new Date().getTime() * 1000;
    lmaReduxStore.dispatch(appSlice.actions.setAlertSeenTime(alertSeenTime));
    localStorageService.setRaw('alertSeenTimestamp', '' + alertSeenTime);
  }

  async fetchAlerts(): Promise<Array<Alert>> {
    const response = await this.requestScheduler.request('system.alerts', null, QueryPriorities.high).promise;
    if (response?.payload?.alerts) {
      return response.payload.alerts;
    }
    return [];
  }
}
