import { useApiContext } from '@contexts/api-context';
import { KnownWebsocketEvent } from '@contexts/api-context/request.types';
import { OrgKey } from '@data-table/data-table.types';
import MetricsFilter from '@legacy-modules/metrics2/models/filter/MetricsFilter';
import { OrgUnitPart } from '@legacy-modules/metrics2/models/queries/OrganizationQuery';
import OrgUnitMultiResponsePayload from '@legacy-modules/metrics2/models/websocket/org/OrgUnitMultiResponsePayload';
import { OrgUnitResult } from '@legacy-modules/metrics2/models/websocket/org/OrgUnitResult';
import moment from 'moment';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

type UseOrganizationDataOutput = [Map<OrgKey, OrgUnitResult>, boolean, boolean];

export default function useOrganizationData(
  orgKeys: OrgKey[],
  parts: OrgUnitPart[] = [],
  orgType?: string,
  _from?: moment.Moment,
  _to?: moment.Moment,
  enabled: boolean = true
): UseOrganizationDataOutput {
  const apiCtx = useApiContext();

  const [from, to] = useMemo(() => {
    const filter = new MetricsFilter();
    return [_from ?? filter.from.toISOString(), _to ?? filter.to.toISOString()];
  }, [_from, _to]);

  const { data, isLoading, isError } = useQuery<OrgUnitMultiResponsePayload, undefined, Map<string, OrgUnitResult>>({
    queryKey: [KnownWebsocketEvent.MULTI_ORG_TREE_LOAD_EVENT, orgKeys.join(','), parts.join(','), orgType, from, to],
    enabled,
    queryFn: () =>
      apiCtx.wsFetch(KnownWebsocketEvent.MULTI_ORG_TREE_LOAD_EVENT, {
        orgKeys,
        parts: Array.from(new Set(parts)),
        orgType,
        from,
        to,
      }),
    select: (data) =>
      data.units?.reduce((acc, unit) => acc.set(unit.orgKey, unit), new Map<string, OrgUnitResult>()) ||
      new Map<string, OrgUnitResult>(),
    keepPreviousData: true,
  });

  return [data, isLoading, isError];
}
