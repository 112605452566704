import React from 'react';
import Styles from './TableFooter.module.scss';

type TableFooterProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>;

export const TableFooter: React.FC<TableFooterProps> = ({ children, ...props }: TableFooterProps) => {
  return (
    <tfoot className={Styles.Footer} {...props}>
      {children}
    </tfoot>
  );
};
