import React, { ReactElement } from 'react';
import style from './data-table-settings.module.scss';
import classNames from 'classnames';
import { AiOutlineSetting } from 'react-icons/ai';
import { DataTableSettingsComponentProps, withDataTableSettings } from './with-data-table-settings.hoc';
import { motion } from 'framer-motion';
import { Tooltip } from '@other-components/tooltip';

const DataTableSettings = ({
  className,
  header,
  settingsProps,
  showSettings,
  toggleSettings,
  disabled = false,
  children,
  ...htmlProps
}: DataTableSettingsComponentProps): ReactElement => {
  return (
    <div {...htmlProps} className={classNames(style.dataTableSettings)}>
      <Tooltip data-testid='data-table-settings-action-tooltip' tooltipText='Tabelle Konfigurieren'>
        <div>
          <div
            data-testid='data-table-settings-trigger'
            data-disabled={disabled}
            className={classNames(style.trigger, {
              [style.disabled]: disabled,
            })}
            {...settingsProps.triggerProps}
            onClick={disabled ? null : toggleSettings}>
            <AiOutlineSetting />
          </div>
        </div>
      </Tooltip>
      {showSettings && (
        <motion.section
          data-testid='data-table-settings'
          {...settingsProps.layerProps}
          key='modal'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={style.configurationPopup}>
          {header}
          <div className={classNames(style.configurationContainer, className)}>{children}</div>
        </motion.section>
      )}
    </div>
  );
};

export default withDataTableSettings(DataTableSettings);
