import { useApiContext } from '@contexts/api-context';
import { KnownWebsocketEvent } from '@contexts/api-context/request.types';
import { useMutation, useQuery, useQueryClient } from 'react-query';

type Setter<T> = T | ((value: T) => void);
export type UpdateUserConfigFn<T> = (setter: Setter<T | Partial<T>>) => void;
type UseUserConfigOutput<T = unknown> = [T | Partial<T>, UpdateUserConfigFn<T>, boolean];

const useUserConfig = <T = unknown,>(configKey: string, defaultState?: T | Partial<T>): UseUserConfigOutput<T> => {
  const apiCtx = useApiContext();

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery<T>({
    queryKey: [KnownWebsocketEvent.USER_CONFIG_LOAD_EVENT, configKey],
    queryFn: () =>
      apiCtx.wsFetch(KnownWebsocketEvent.USER_CONFIG_LOAD_EVENT, {
        configKey,
      }),
    optimisticResults: true,
  });

  const { mutate } = useMutation<unknown, unknown, Setter<T>>({
    mutationFn: (state) =>
      apiCtx.wsFetch(KnownWebsocketEvent.USER_CONFIG_SAVE_EVENT, {
        configKey,
        value: state instanceof Function ? state(data) : state,
      }),
    onMutate: (state: T) => {
      queryClient.cancelQueries({ queryKey: [KnownWebsocketEvent.USER_CONFIG_LOAD_EVENT, configKey] });
      const previous = queryClient.getQueryData([KnownWebsocketEvent.USER_CONFIG_LOAD_EVENT, configKey]);
      queryClient.setQueryData([KnownWebsocketEvent.USER_CONFIG_LOAD_EVENT, configKey], state);
      return previous;
    },
    onError: (error, state, previous) => {
      queryClient.setQueryData([KnownWebsocketEvent.USER_CONFIG_LOAD_EVENT, configKey], previous);
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: [KnownWebsocketEvent.USER_CONFIG_LOAD_EVENT, configKey] }),
  });

  if (!data) {
    return [defaultState, mutate, isLoading];
  }

  return [data, mutate, isLoading];
};

export default useUserConfig;
