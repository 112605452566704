import { useEffect, useRef } from 'react';

export default function useComponentWillMount(callback: Function): void {
  const willMount = useRef(true);

  useEffect(() => {
    willMount.current = false;
  }, []);

  if (willMount.current) {
    callback();
  }
}
