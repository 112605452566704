export default class Rounding {
  /**
   * Round up a number nicely to the next 10, 100, 1000, ...
   * E.g. for 1234 it would be 2000, for 43,235.00 it would be 50,000.00
   * @param number
   * @returns {number}
   */
  static niceUp(number: number): number {
    if (Number.isNaN(number)) {
      return 0;
    }
    const size = Math.floor(Math.log(Math.abs(number)) / Math.LN10);
    const magnitude = Math.pow(10, size);
    const max = Math.ceil(number / magnitude) * magnitude;
    return isFinite(max) ? max : 0;
  }

  /**
   * Round down a number nicely to the next 10, 100, 1000, ...
   * E.g. for 1234 it would be 1000, for 43,235.00 it would be 40,000.00
   * @param number
   * @returns {number}
   */
  static niceDown(number: number): number {
    if (Number.isNaN(number)) {
      return 0;
    }
    const size = Math.floor(Math.log(Math.abs(number)) / Math.LN10);
    const magnitude = Math.pow(10, size);
    const max = Math.floor(number / magnitude) * magnitude;
    return isFinite(max) ? max : 0;
  }

  static _calculateMinimalMagnitude(values: Array<number>, includeNull: boolean = true): number {
    if (values.length === 0) {
      return 0;
    }
    const min = Math.min.apply(null, values);
    const max = Math.max.apply(null, values);
    const diff = max - min;
    let magnitudes = values.map(Rounding._calculateMagnitude);
    if (diff !== 0) {
      magnitudes.push(Rounding._calculateMagnitude(diff));
    }
    magnitudes = includeNull && min === 0 ? magnitudes : magnitudes.filter((v) => v !== 0);
    return Math.min.apply(null, magnitudes);
  }

  static _calculateMagnitude(value: number): number {
    const size = Math.floor(Math.log(Math.abs(value)) / Math.LN10);
    return Math.pow(10, size);
  }

  static roundedMin(values: Array<number>): number {
    values = values.filter((v) => v !== null);
    const min = Math.min.apply(null, values);
    if (min === 0) {
      return 0;
    }
    const magnitude = Rounding._calculateMinimalMagnitude(values);
    const roundedMin = Math.floor(min / magnitude) * magnitude;
    return isFinite(roundedMin) ? roundedMin : 0;
  }

  static roundedMax(values: Array<number>): number {
    values = values.filter((v) => v !== null);
    const magnitude = Rounding._calculateMinimalMagnitude(values, false);
    const max = Math.max.apply(null, values);
    const roundedMax = Math.ceil(max / magnitude) * magnitude;
    return isFinite(roundedMax) ? roundedMax : 0;
  }
}
