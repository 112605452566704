/* eslint-disable max-len */
import ValueExpression from './ValueExpression';
import { ValueType } from '../../../metrics2/models/enumerations/ValueType';
import MetricType from '../../../metrics2/models/entities/MetricType';
import ValueExpressionEntityKey from '../entities/ValueExpressionEntityKey';
import MetricsEntityKey from '../../../metrics2/models/entities/MetricsEntityKey';
import { ValueExpressionRoutingParams } from '../routing/ValueExpressionRoutingParams';
import { DateRangeGrouping } from '../../../metrics2/models/enumerations/DateRangeGrouping';
import { ChartType } from '../../../metrics2/models/enumerations/ChartType';
import { PositiveDirections } from '../../../metrics2/models/entities/PositiveDirection';
import MetricDataUtils from '../../../metrics2/converters/MetricDataUtils';
import { ValueFormatter } from '../types/ValueFormatter';
import { AggregationType } from '../../../metrics2/models/enumerations/AggregationType';
import { KpiDefinitionWithoutName } from '@custom-types/kpi';

export type MathOperator = '+' | '-' | '*' | '/';

const MathOperatorLabels = {
  '+': 'Summe',
  '-': 'Differenz',
  '*': 'Produkt',
  '/': 'Quotient',
};

const MathOperatorKeys = {
  '+': 'plus',
  '-': 'minus',
  '*': 'multiply',
  '/': 'divide',
};

export default class MathValueExpression extends ValueExpression {
  static TAG = 'MathValueExpression';
  tag = MathValueExpression.TAG;

  _chartType: ChartType;
  first: ValueExpression;
  firstMapKey: string | null | undefined = null;
  second: ValueExpression;
  secondMapKey: string | null | undefined = null;
  operator: MathOperator;
  // @TODO: implement mapFromFirst in order to allow creating map values (only if numerator is a mapvalue and numeratormapkey===null)
  // mapFromFirst: boolean = true;
  label: string | null | undefined;
  description: string | null | undefined;
  percent: boolean;
  valueFormat: string | null | undefined;

  constructor(
    first: ValueExpression,
    second: ValueExpression,
    operator: MathOperator,
    label: string = null,
    description: string = null,
    valueFormat: string = null,
    definition?: KpiDefinitionWithoutName
  ) {
    super();
    this.first = first;
    this.second = second;
    this.operator = operator;
    this.label = label;
    this.description = description;
    this.valueFormat = valueFormat;
    this.definition = definition;
  }

  clone(): MathValueExpression {
    return new MathValueExpression(
      this.first,
      this.second,
      this.operator,
      this.label,
      this.description,
      this.valueFormat,
      this.definition
    );
  }

  get key(): string {
    return `mve:${MathOperatorKeys[this.operator]}:${this.first.key}:${this.second.key}`;
  }

  get valueType(): ValueType {
    return ValueType.single;
  }

  get mapKeys(): Array<string> | null | undefined {
    return null;
  }

  getRequiredMetricTypes(id: string = null): Array<{ type: MetricType; valueKey?: string; id: string }> {
    return [...this.first.getRequiredMetricTypes(id ? `${id}.first` : 'first')].concat(
      this.second.getRequiredMetricTypes(id ? `${id}.second` : 'second')
    );
  }

  set chartType(chartType: ChartType) {
    this._chartType = chartType;
  }

  get chartType(): ChartType {
    if (this._chartType) return this._chartType;
    return ChartType.line;
  }

  get positiveDirection(): PositiveDirections {
    return this.first.positiveDirection;
  }

  get aggregation(): AggregationType {
    return AggregationType.sum;
  }

  getLabel(mapKey = null, language = null): string {
    if (this.label) {
      return this.label;
    }
    return `${this.first.getLabel()} ${this.operator} ${this.second.getLabel()}`;
  }

  getDescription(mapKey = null, language = null): string | null | undefined {
    if (this.description) {
      return this.description;
    }
    return `${
      MathOperatorLabels[this.operator]
    } aus den Kennzahlen <em>${this.first.getLabel()}</em> und <em>${this.second.getLabel()}</em>`;
  }

  getSumLabel(language = null): string {
    return this.getLabel(null, language);
  }

  getValueFormat(): string {
    if (this.valueFormat) {
      return this.valueFormat;
    }
    return '-#.###.##0,0';
  }

  getValueFormatter(language = null): ValueFormatter {
    const formatter = super.getValueFormatter(language);
    return (value: number) => {
      // at this point we should not have infinities, only NaN
      if (isNaN(value)) {
        return '-';
      }
      if (this.valueFormat && this.valueFormat.includes('%')) {
        const safeValue = value || 0;
        return formatter(safeValue * 100);
      }
      return formatter(value);
    };
  }

  processValues(
    metrics: Map<MetricsEntityKey, number>,
    forceNoneGrouping: boolean = false
  ): Map<ValueExpressionEntityKey, number> {
    const firstResult = this.first.processValues(metrics, forceNoneGrouping);

    const secondResult = this.second.processValues(metrics, forceNoneGrouping);
    const key = this.key;

    const veEntityKeys: {
      [key: string]: ValueExpressionEntityKey;
    } = {};
    let minDate = null;
    let maxDate = null;
    const firstAggregationFn = MetricDataUtils.aggregationFunction(this.first.aggregation);
    const secondAggregationFn = MetricDataUtils.aggregationFunction(this.second.aggregation);
    if (forceNoneGrouping) {
      metrics.forEach((value: number, mek: MetricsEntityKey) => {
        if (!minDate || minDate.isAfter(mek.dateFrom)) {
          minDate = mek.dateFrom;
        }
        if (!maxDate || maxDate.isBefore(mek.dateUntil)) {
          maxDate = mek.dateUntil;
        }
      });
    }

    const resultMap: Map<ValueExpressionEntityKey, { aggregatedFirst: number; aggregatedSecond: number }> = new Map();

    this._sortInValues(
      firstResult,
      key,
      forceNoneGrouping,
      minDate,
      maxDate,
      veEntityKeys,
      resultMap,
      true,
      firstAggregationFn,
      secondAggregationFn
    );
    this._sortInValues(
      secondResult,
      key,
      forceNoneGrouping,
      minDate,
      maxDate,
      veEntityKeys,
      resultMap,
      false,
      firstAggregationFn,
      secondAggregationFn
    );

    const aggregateFn = this._aggregateMap.bind(this);
    const aggResult: Map<ValueExpressionEntityKey, number> = new Map();
    resultMap.forEach(aggregateFn(aggResult));

    return aggResult;
  }

  _aggregateMap(aggResult: Map<ValueExpressionEntityKey, number>) {
    const combineFunction = this._combineValueFunction();
    return function (sums: { aggregatedFirst: number; aggregatedSecond: number }, veek: ValueExpressionEntityKey) {
      if (sums.aggregatedSecond >= 0) {
        const result = combineFunction(sums.aggregatedFirst, sums.aggregatedSecond);
        if (Number.isFinite(result)) {
          aggResult.set(veek, result);
        }
      }
      return aggResult;
    };
  }

  _sortInValues(
    results,
    key,
    forceNoneGrouping,
    minDate,
    maxDate,
    veEntityKeys,
    resultMap,
    isFirstValue,
    firstAggregationFn,
    secondAggregationFn
  ) {
    new Map([...results].filter(([k, v]) => k)).forEach((value: number, mek: ValueExpressionEntityKey) => {
      let veek: ValueExpressionEntityKey = new ValueExpressionEntityKey(
        key,
        forceNoneGrouping ? '' : mek.orgKey,
        forceNoneGrouping ? minDate : mek.dateFrom,
        forceNoneGrouping ? maxDate : mek.dateUntil,
        forceNoneGrouping ? DateRangeGrouping.none : mek.grouping,
        null
      );
      if (veEntityKeys[veek.identifier]) {
        veek = veEntityKeys[veek.identifier];
      } else {
        veEntityKeys[veek.identifier] = veek;
      }
      const oldValue = resultMap.get(veek) || {
        aggregatedFirst: null,
        aggregatedSecond: null,
      };

      resultMap.set(veek, {
        aggregatedFirst: isFirstValue ? firstAggregationFn(value, oldValue.aggregatedFirst) : oldValue.aggregatedFirst,
        aggregatedSecond: !isFirstValue
          ? secondAggregationFn(value, oldValue.aggregatedSecond)
          : oldValue.aggregatedSecond,
      });
    });
  }

  _combineValueFunction(): (a: number, b: number) => number {
    switch (this.operator) {
      case '+':
        return (a, b) => a + b;
      case '-':
        return (a, b) => a - b;
      case '*':
        return (a, b) => a * b;
      case '/':
        return (a: number, b: number) => {
          if (!Number.isFinite(a) || !Number.isFinite(b) || b === 0) {
            return null;
          }
          return a / b;
        };
      default:
        return () => 0;
    }
  }

  getRoutingParams(): ValueExpressionRoutingParams {
    return {
      valueExpressionClass: MathValueExpression.TAG,
      first: this.first.getRoutingParams(),
      firstMapKey: this.firstMapKey,
      second: this.second.getRoutingParams(),
      secondMapKey: this.secondMapKey,
      operator: this.operator,
      label: this.label,
      valueFormat: this.valueFormat,
      description: this.description,
    };
  }
}
