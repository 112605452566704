import React, { useRef, useState } from 'react';
import { Tooltip } from '../../common/components/Tooltip';

type Props = {
  icon: React.ReactElement;
  title: string;
};

const DeliveryItemServiceIcon: React.FC<Props> = ({ icon, title }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const ref = useRef();

  return (
    <>
      <div
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
        className='icon-direct-import-parent'
        ref={ref}>
        {icon}
      </div>
      <Tooltip className='tooltip-badge' placement='bottom' anchorElement={ref?.current} visible={tooltipOpen}>
        {title}
      </Tooltip>
    </>
  );
};
export default DeliveryItemServiceIcon;
