import BasePayload from '../BasePayload';
import { DefaultType, MemberType } from '../../../../../utils/Model';
import { PlainObjectOf } from '../../../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export default class StateStoreRequestPayload extends BasePayload<StateStoreRequestPayload> {
  constructor(source: PlainObjectOf<StateStoreRequestPayload>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }
  getDefaults(): DefaultType<StateStoreRequestPayload> {
    return undefined;
  }

  getMembers(): MemberType<StateStoreRequestPayload> {
    return undefined;
  }
  content: string;

  static buildFromObject(obj: Object): StateStoreRequestPayload {
    return new StateStoreRequestPayload({ content: JSON.stringify(obj) });
  }
}
