import React from 'react';

type TableHeadProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>;

export const TableHead: React.FC<TableHeadProps> = ({ children, style, ...props }: TableHeadProps) => {
  return (
    <thead style={style} {...props}>
      {children}
    </thead>
  );
};
