import WebsocketEvent from '../../../common/models/websocket/WebsocketEvent';
import { QueryInterface } from './QueryInterface';
import { QueryPriority } from '../enumerations/QueryPriority';

export default class ScheduledQuery {
  request: WebsocketEvent;
  promise: Promise<{
    payload: any;
  }>;
  resolvePromise: Function;
  rejectPromise: Function;
  priority: QueryPriority;
  _aborted: boolean;
  _running: boolean;
  _finished: boolean;

  constructor(request: WebsocketEvent, priority: QueryPriority) {
    this.request = request;
    this.priority = priority;
    this._aborted = false;
    this._running = false;
    this._finished = false;
    this.promise = new Promise((resolve, reject) => {
      this.resolvePromise = resolve;
      this.rejectPromise = reject;
    });
  }

  /**
   * Tries to abort the query, this cannot happen if the query is already running or finished.
   * @returns {boolean} whether the query was aborted
   */
  abort(): boolean {
    if (!this._finished && !this._running) {
      this._aborted = true;
      this.rejectPromise(new Error('This query was aborted by the application.'));
    }
    return this._aborted;
  }

  isAborted(): boolean {
    return this._aborted;
  }

  isRunning(): boolean {
    return this._running && !this._finished;
  }

  setRunning(running: boolean): void {
    this._running = running;
  }

  isFinished(): boolean {
    return this._finished;
  }

  finish(): void {
    this._finished = true;
    this._running = false;
  }

  resolve(result: Object): void {
    this.finish();
    this.resolvePromise(result);
  }

  reject(error: Error): void {
    this.finish();
    this.rejectPromise(error);
  }
}
