import React from 'react';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import QuotientValueExpression from '../../valueexpressions/models/valueexpressions/QuotientValueExpression';
import PercentageInput from './PercentageInput';
import FloatNumberInput from './FloatNumberInput';

type Props<T extends ValueExpression> = {
  valueExpression: T;
  onValueChange: (value: number) => void;
  className?: string;
  value?: number;
  onBlur?: () => void;
  name?: string;
  tabIndex?: number;
};

const ValueExpressionInput = React.forwardRef(<T extends ValueExpression>(props: Props<T>, ref) => {
  const { valueExpression, onValueChange, name, tabIndex, ...otherProps } = props;

  if (valueExpression instanceof QuotientValueExpression && valueExpression.percent) {
    return (
      <PercentageInput
        {...otherProps}
        onValueChange={onValueChange}
        allowMoreThan100Percent={false}
        decimalPlaces={valueExpression.decimalPlaces}
        ref={ref}
        name={name}
        tabIndex={tabIndex}
      />
    );
  }
  return <FloatNumberInput {...otherProps} ref={ref} onValueChange={onValueChange} name={name} tabIndex={tabIndex} />;
});

ValueExpressionInput.displayName = 'ValueExpressionInput';
export { ValueExpressionInput };
