import { UndeliverableReason } from '../../../metrics2/models/enumerations/UndeliverableReason';
import { DeliveryStatus } from '../../constants/DeliveryStatus';
import { badgeDictionary } from '@components/delivery-badge/types';

export const recipientPersonMapping = {
  atDoor: badgeDictionary.IW,
  intendedReceiver: badgeDictionary.OE,
  nextDoor: badgeDictionary.NB,
  intendedReceiverAndNextDoor: badgeDictionary['OE+'],
  reception: badgeDictionary.REZ,
  staff: badgeDictionary.MA,
};

export const DeliveryStatusToUndeliveredReasonMap: Partial<Record<DeliveryStatus, UndeliverableReason>> = {
  [DeliveryStatus.RL_A]: 'DeliveryRefused',
  [DeliveryStatus.RL_F]: 'WrongAddress',
  [DeliveryStatus.RL_N1]: 'NotMet1',
  [DeliveryStatus.RL_N2]: 'NotMet2',
  [DeliveryStatus.RL_N3]: 'NotMet3',
  [DeliveryStatus.RL_N4]: 'NotMet4',
  [DeliveryStatus.RL_U]: 'MisroutedDeliveryItem',
  [DeliveryStatus.RL_S]: 'PackagingDamaged',
  [DeliveryStatus.RL_Z]: 'TooLate',
  [DeliveryStatus.RL_KG]: 'NoMoney',
  [DeliveryStatus.RL_IL]: 'PictureVerificationFailed',
  [DeliveryStatus.RL_IN]: 'IdentName',
  [DeliveryStatus.RL_IG]: 'IdentBirthday',
  [DeliveryStatus.RL_ID]: 'IdentDocument',
  [DeliveryStatus.RL_IA]: 'MinAgeVerificationFailed',
  [DeliveryStatus.RL_oGGS]: 'RLoGGS',
  [DeliveryStatus.RL_Storno]: 'Cancelled',
  [DeliveryStatus.Ret_Abh]: 'RLoGGS',
  [DeliveryStatus.Ret_Mit]: 'RLoGGS',
  [DeliveryStatus.SEZ_Medea]: 'RLoGGS',
  [DeliveryStatus.SEZ_BK]: 'RLoGGS',
};
