import { useEffect, useState } from 'react';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import ValueExpressionDataProvider from '../../valueexpressions/services/ValueExpressionDataProvider';
import { DateRange } from '../../utils/dates/DateRange';
import DataConnectionsService from '../../dataconnection/services/DataConnectionsService';
import ValueExpressionQuery from '../../valueexpressions/models/queries/ValueExpressionQuery';
import { DateRangeGrouping } from '../../metrics2/models/enumerations/DateRangeGrouping';
import { MetricDataConnectionOutputFormats } from '../../metrics2/models/enumerations/MetricDataConnectionOutputFormat';
import DataConnection from '../../dataconnection/connections/DataConnection';
import { DataConnectionMetaData } from '../../dataconnection/models/types/DataConnectionMetaData';
import ValueExpressionDataConnection from '../../valueexpressions/models/dataconnection/ValueExpressionDataConnection';
import { ContractorUtils } from '../../utils/tours/ContractorUtils';
import { WeekdaysFilter, defaultWeekdaysFilter } from '../models/enums/Duration';
import { WeekdayInput, DateRangeGrouping as DateRangeGroupingV2 } from '@graphql-client/graphql';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import { KpiFilter, KpiMapper } from '@hooks/use-kpi-query-hook/use-kpi-query.hook';

export const useValueExpression = (
  ve: ValueExpression,
  orgKey: string,
  dateRange: DateRange,
  valueExpressionDataProvider: ValueExpressionDataProvider,
  dataConnectionsService: DataConnectionsService,
  weekdayFilter: WeekdaysFilter = defaultWeekdaysFilter
): { loading: boolean; data: any } => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkMeta = (meta: DataConnectionMetaData) => {
    if (meta?.completed) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  const setDataIfConnectionIsPrefilled = (dataConnection: ValueExpressionDataConnection) => {
    if (dataConnection.meta?.completed) {
      setLoading(false);
      setData(dataConnection.data);
    } else {
      setLoading(true);
    }
  };

  const { from, to } = {
    from: dateRange?.from?.format('YYYY-MM-DD'),
    to: dateRange?.to?.format('YYYY-MM-DD'),
  };

  const { data: gqlData, isLoading: gqlLoading } = useKpiQuery(
    {
      orgKeys: [orgKey],
      dateFilter: {
        range: {
          from,
          until: to,
        },
        weekdays: Object.entries(weekdayFilter || {}).map(([day, active]) => ({
          weekday: day.toUpperCase() as WeekdayInput,
          active,
        })),
      },
      kpiIds: ve.getRequiredMetricTypes().map((metricType) => ({ id: metricType.type.key })),
      dateRangeGrouping: DateRangeGroupingV2.Single,
    },
    {
      enabled: ve.apiVersion === 'graphql' && !!orgKey && !!from && !!to,
      select: (data) => {
        const filteredGroups = data.kpis.groups.map((group) => ({
          ...group,
          kpiValues: group.kpiValues.filter(KpiFilter).map(KpiMapper),
        }));
        const filteredSummaryKpiValues = data.kpis.summary.kpiValues.filter(KpiFilter).map(KpiMapper);
        return { kpis: { ...data.kpis, groups: filteredGroups, summary: { kpiValues: filteredSummaryKpiValues } } };
      },
    }
  );

  useEffect(() => {
    if (ve.apiVersion !== 'graphql' || !gqlData?.kpis || gqlLoading) {
      return;
    }

    setData(gqlData.kpis.summary.kpiValues[0]?.value);
  }, [gqlData?.kpis, gqlLoading, ve.apiVersion]);

  useEffect(() => {
    if (!dataConnectionsService || !valueExpressionDataProvider || !ve || ve.apiVersion === 'graphql') {
      return;
    }
    let isMounted = true;
    setLoading(true);

    const primary: ValueExpressionQuery = new ValueExpressionQuery(
      ve,
      orgKey,
      dateRange.from,
      dateRange.to,
      weekdayFilter,
      DateRangeGrouping.none,
      null,
      ContractorUtils.isContractor(orgKey) ? orgKey : null
    );

    const primaryConnection = valueExpressionDataProvider.requestConnection(primary);
    primaryConnection.setOutputFormat(MetricDataConnectionOutputFormats.single);

    primaryConnection.addListener(DataConnection.EVENT_DATA_CHANGED, () => {
      if (!isMounted) {
        return;
      }
      setData(primaryConnection.data);
      checkMeta(primaryConnection.meta);
    });

    primaryConnection.addListener(DataConnection.EVENT_LOADING, () => {
      if (!isMounted) {
        return;
      }
      checkMeta(primaryConnection.meta);
    });

    primaryConnection.addListener(DataConnection.EVENT_TERMINATED, () => {
      if (!isMounted) {
        return;
      }
      checkMeta(primaryConnection.meta);
    });

    const key = dataConnectionsService.registerDataConnection(primaryConnection);
    // If data is cached the connection already contains the data
    // without emitting an event
    setDataIfConnectionIsPrefilled(primaryConnection);

    return () => {
      isMounted = false;
      dataConnectionsService.onTerminatedConnection(key);
    };
  }, [dataConnectionsService, valueExpressionDataProvider, orgKey, dateRange, ve, weekdayFilter]);

  return { loading: loading || gqlLoading, data };
};
