import Model, { MemberType } from '../../../utils/Model';
import FinishedDelivery from './FinishedDelivery';
import GeoCoordinate from '../../../geo/models/GeoCoordinate';
import GeoLocation from '../../../geo/models/GeoLocation';
import TourPlan from './TourPlan';
import moment from 'moment-timezone';
import UnfinishedCustomerPlanDetails from './UnfinishedCustomerPlanDetails';

export type TourDetailsInTimeStats = {
  itemsDone: number;
  itemsDelivered: number;
  itemsCollected: number;
  itemsUndeliverable: number;
  itemsTotal: number;
  distanceDriven: number;
  distanceRemaining: number;
  timeElapsedMs: number;
  timeRemainingMs: number;
  productivityTimeElapsedMs: number;
};

export default class TourDetailsInTime extends Model<TourDetailsInTime> {
  unfinishedCustomers: Array<UnfinishedCustomerPlanDetails>;
  filteredFinishedDeliveries: Array<FinishedDelivery>;
  tourPlan: TourPlan | null | undefined;
  currentLocation: GeoLocation | null | undefined;
  time: moment.Moment | null | undefined;
  finalDestination: GeoCoordinate | null | undefined;
  stats: TourDetailsInTimeStats;

  constructor(
    source: any, // FIXME: set proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults() {
    return {
      unfinishedCustomers: () => [],
      filteredFinishedDeliveries: () => [],
      tourPlan: () => null,
      stats: () => ({
        itemsDone: 22,
        itemsDelivered: 19,
        itemsCollected: 0,
        itemsUndeliverable: 3,
        itemsTotal: 101,
        distanceDriven: 12412,
        distanceRemaining: 19232,
        timeElapsedMs: 14000000,
        timeRemainingMs: 21000000,
        productivityTimeElapsedMs: 14000000,
      }),
    };
  }
  getMembers(): MemberType<TourDetailsInTime> {
    return {
      tourPlan: (src) => (src ? new TourPlan(src) : null),
      unfinishedCustomers: (src) => (src ? src.map((u) => new UnfinishedCustomerPlanDetails(u)) : []),
      filteredFinishedDeliveries: (src) => (src ? src.map((fd) => new FinishedDelivery(fd)) : []),
      time: (src) => moment(src),
      currentLocation: (src) => (src ? new GeoLocation(src) : null),
      finalDestination: (src) => (src ? new GeoCoordinate(src) : null),
    };
  }

  isFinished(): boolean {
    return this.unfinishedCustomers.length === 0;
  }
}
