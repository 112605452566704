import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Styles from './ComplaintsValueRows.module.scss';
import { DateRangeGrouping } from '../../../../metrics2/models/enumerations/DateRangeGrouping';
import { chain } from 'lodash';
import { ValuableComplaintReasons } from '../../../models/enums/ValuableComplaintsReason';
import { Duration } from '../../../../dashboard/models/enums/Duration';
import { ValueType } from '../../../../metrics2/models/enumerations/ValueType';
import { Table, TableColumn, TableRow } from '../../../../common/components/table/Table';
import { selectDashboardOrgKey } from '@redux/dashboard.selectors';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { selectDataconnectionState } from '@redux/dataconnection.selector';
import ChartUtils from '@legacy-modules/dashboard/utils/ChartUtils';
import ValueExpressionQuery from '@legacy-modules/valueexpressions/models/queries/ValueExpressionQuery';
import GroupedDataConnection from '@legacy-modules/dataconnection/connections/GroupedDataConnection';
import { MetricDataConnectionOutputFormats } from '@legacy-modules/metrics2/models/enumerations/MetricDataConnectionOutputFormat';
import { QueryPriorities } from '@legacy-modules/datamanager/models/enumerations/QueryPriority';
import { useValueExpressionDataContext } from '@contexts/value-expression-data-context';
import { useDataConnectionContext } from '@contexts/data-connection-context';

type Props = {
  duration: Duration;
  onlyValuable?: boolean;
  valueExpressionKey: string;
  style?: React.CSSProperties;
  headline?: string;
};

const ComplaintsValueRows: React.FC<Props> = ({ duration, onlyValuable, valueExpressionKey, style, headline }) => {
  const orgKey = useSelector(selectDashboardOrgKey);
  const dataConnectionState = useSelector(selectDataconnectionState);

  const valueExpressionDataContext = useValueExpressionDataContext();
  const dataConnectionContext = useDataConnectionContext();

  const valueExpressionMap = useValueExpressionContext();
  const valueExpression = valueExpressionMap.get(valueExpressionKey);

  const dataConnectionKeyRef = useRef<string>(null);

  const setupDataConnections = useCallback(() => {
    const { from, to } = ChartUtils.getDateRange(duration);
    if (valueExpression == null) return;
    const primaryQuery: ValueExpressionQuery = new ValueExpressionQuery(
      valueExpression,
      orgKey,
      from,
      to,
      duration.weekdayFilter,
      DateRangeGrouping.none,
      null
    );
    const primary = valueExpressionDataContext.requestConnection(primaryQuery, QueryPriorities.high);
    primary.setOutputFormat(MetricDataConnectionOutputFormats.single);
    const dataConnection = new GroupedDataConnection({ primary });
    const dataConnectionKey = dataConnectionContext.registerDataConnection(dataConnection);
    dataConnectionKeyRef.current = dataConnectionKey;
  }, [dataConnectionContext, duration, orgKey, valueExpression, valueExpressionDataContext]);

  useEffect(() => {
    setupDataConnections();
  }, [setupDataConnections]);

  const renderSingle = (data) => {
    const valueExpression = valueExpressionMap.get(valueExpressionKey);
    const value = valueExpression.getValueFormatter()(data.data?.primary);
    return <span>{value}</span>;
  };

  const renderMap = (data) => {
    const dataMap = data.data?.primary;
    let valuePairs = chain(dataMap).toPairs().orderBy([1, 0], ['desc', 'asc']).value();
    if (onlyValuable) {
      valuePairs = valuePairs.filter((p) => (Object.values(ValuableComplaintReasons) as string[]).includes(p[0]));
    }

    return valuePairs.slice(0, 5).map((p, i) => {
      // Do not render rows that don't have a complaint
      if (p[1] <= 0) {
        return null;
      }

      return (
        <TableRow key={`sb-value-row-${i}`} className={Styles.Row}>
          <TableColumn variant={'small'}>{p[0]}</TableColumn>
          <TableColumn variant={'small'} isNumber>
            {p[1]}
          </TableColumn>
        </TableRow>
      );
    });
  };

  const data = dataConnectionState[dataConnectionKeyRef.current];

  if (!data) {
    return null;
  }

  if (valueExpression && valueExpression.valueType === ValueType.map) {
    return (
      <>
        <div className={Styles.Headline}>{headline}</div>
        <div style={{ minHeight: '170px' }}>
          <Table style={style} className={Styles.ComplaintsValueRows} body={renderMap(data)} />
        </div>
      </>
    );
  }
  return renderSingle(data);
};

export default ComplaintsValueRows;
