import React from 'react';
import classNames from 'classnames';
import Styles from './Code.module.scss';

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

export const CodeListing = ({ children, style, className }: Props) => {
  return (
    <pre style={style} className={classNames(Styles.Code, className)}>
      {children}
    </pre>
  );
};
