import Model, { DefaultType, MemberType } from '../../../utils/Model';
import { DeliveryItemTrackingData } from '../../../tour/models/types/DeliveryItemTrackingData';
import { TourIdentifier } from '../state/tourDetails/TourIdentifier';
import GeoLocation from '../../../geo/models/GeoLocation';
import CustomerTrackingData from '../../../tour/models/types/CustomerTrackingData';
import createMapMap, { createObjectForEntities } from '../../../utils/createMapMap';
import FinishedDelivery from './FinishedDelivery';
import moment from 'moment-timezone';
import { DriverInformation } from './DriverInformation';
import { AppInfoTrackingData } from '../types/AppInfoTrackingData';
import { IRawAnomalyResponse } from '../../../dashboard/anomalies/interfaces/IRawAnomalyResponse';
import TourPlanStop from './TourPlanStop';
import TourPlan from './TourPlan';

export type CustomerMap = Map<string, CustomerTrackingData>;

export default class TourDetails extends Model<TourDetails> {
  tourIdentifier: TourIdentifier;
  customers: CustomerMap;
  deliveryItems: Map<string, DeliveryItemTrackingData>;
  finishedDeliveries: {
    [k: string]: FinishedDelivery;
  };
  driver: DriverInformation;
  startedAt: moment.Moment;
  startLocation: GeoLocation | null | undefined;
  realPath: Array<GeoLocation>;
  currentDriverLocation: GeoLocation | null | undefined;
  finishAt: moment.Moment | null | undefined;
  softFinishAt: moment.Moment | null | undefined;
  estimatedFinalAt: moment.Moment | null | undefined;
  currentLastDeliveryId: string | null | undefined;
  currentNextCustomerRef: string | null | undefined;
  plannedStops: TourPlanStop[];
  appInfo: AppInfoTrackingData;
  anomalies: Array<IRawAnomalyResponse>;
  latestTourPlan: TourPlan | null | undefined;

  constructor(
    source: any, // FIXME: set proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  get identifier() {
    return this.tourIdentifier;
  }

  getCustomerDeliveryItems(customerRef: string): Array<DeliveryItemTrackingData> {
    return Array.from(this.deliveryItems.values()).filter(
      (di: DeliveryItemTrackingData) => di.customerRef === customerRef
    );
  }

  getDefaults(): DefaultType<TourDetails> {
    return {
      customers: () => new Map(),
      deliveryItems: () => new Map(),
      finishedDeliveries: () => new Map(),
      tourIdentifier: () => new TourIdentifier(),
      estimatedFinalAt: () => null,
      finishAt: () => null,
      softFinishAt: () => null,
      startLocation: () => null,
      realPath: () => [],
    };
  }

  getMembers(): MemberType<TourDetails> {
    // @ts-ignore
    return {
      tourIdentifier: (src) => new TourIdentifier(src),
      deliveryItems: (src) => (src ? src : new Map()),
      finishedDeliveries: (src) => (src ? createObjectForEntities(src, FinishedDelivery) : {}),
      startedAt: (src) => (src ? moment(src) : moment()),
      estimatedFinalAt: (src) => (src ? moment(src) : null),
      finishAt: (src) => (src ? moment(src) : null),
      softFinishAt: (src) => (src ? moment(src) : null),
      startLocation: (src) => (src ? new GeoLocation(src) : null),
      currentDriverLocation: (src) => (src ? new GeoLocation(src) : null),
      realPath: (src) => (src ? src.map((l) => new GeoLocation(l)) : []),
      customers: (src) =>
        // @ts-ignore
        // FIXME
        src ? createMapMap(src, CustomerTrackingData) : new Map(),
    };
  }
}
