import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import style from './text-filter-content.module.scss';
import { ColumnFilterContentProps } from '@data-table/data-table.types';
import { getDataTableColumnFilterTitle } from '@data-table/utils';
import { FilterControls } from '../filter-controls';
import { DebounceInput } from '@components/debounce-input';

const TextFilterContent = <T, V>({ table, column, onCancel, onConfirm }: ColumnFilterContentProps<T, V>) => {
  const filterValue = column.getFilterValue() as V;

  const [isValid, setIsValid] = useState(true);

  const label = getDataTableColumnFilterTitle(column.id);

  const pattern = getFilterPatternByColumnId(column.id);

  const onValueChange = useCallback(
    (value: string) => {
      const valid = pattern.test(value);
      setIsValid(valid);
      if (value?.length === 0 || valid) {
        column.setFilterValue(value);
        table.resetPagination();
      }
    },
    [table, column, pattern]
  );
  return (
    <div className={classNames(style.filterContent)}>
      <FilterControls
        className={style.textFilterContent}
        cancelCallback={onCancel}
        confirmCallback={isValid ? () => onConfirm(filterValue) : undefined}>
        <div className={style.filterContentHeadline}>{label}</div>
        <DebounceInput isValid={isValid} onValueChange={onValueChange} />
      </FilterControls>
    </div>
  );
};
export default TextFilterContent;

const getFilterPatternByColumnId = (columnId: string): RegExp => {
  if (columnId === 'sendungsId') {
    return /^([hH])?(\d+)?$/;
  }
  return /^[a-zA-Z0-9äöüÄÖÜß]+$/;
};
