import ValueExpression from './ValueExpression';
import { ValueType } from '../../../metrics2/models/enumerations/ValueType';
import MetricType from '../../../metrics2/models/entities/MetricType';
import ValueExpressionEntityKey from '../entities/ValueExpressionEntityKey';
import MetricsEntityKey from '../../../metrics2/models/entities/MetricsEntityKey';
import { ChartType } from '../../../metrics2/models/enumerations/ChartType';
import { PositiveDirection, PositiveDirections } from '../../../metrics2/models/entities/PositiveDirection';
import { AggregationType } from '../../../metrics2/models/enumerations/AggregationType';

export default class StaticValueExpression extends ValueExpression {
  static TAG = 'StaticValueExpression';
  tag = StaticValueExpression.TAG;

  constructor(private readonly value: number) {
    super();
  }

  clone(): StaticValueExpression {
    return new StaticValueExpression(this.value);
  }

  get key(): string {
    return `static:${this.value}`;
  }

  get valueType(): ValueType {
    return ValueType.single;
  }

  get mapKeys(): Array<string> | null | undefined {
    return null;
  }

  get aggregation(): AggregationType {
    return AggregationType.none;
  }

  getLabel(mapKey = null, language = null): string {
    return String(this.value);
  }

  get chartType(): ChartType {
    return ChartType.line;
  }

  getRequiredMetricTypes(id?: string): Array<{ type: MetricType; valueKey?: string; id: string }> {
    return [];
  }

  getValueFormat(): string {
    return '';
  }

  get positiveDirection(): PositiveDirections {
    return PositiveDirection.custom;
  }

  processValues(
    metrics: Map<MetricsEntityKey, number>,
    forceNoneGrouping: boolean
  ): Map<ValueExpressionEntityKey, number> {
    const results = new Map();
    [...metrics.keys()].forEach((k) => {
      results.set(k, this.value);
    });
    return results;
  }
}
