import React, { ReactElement, useMemo } from 'react';
import classNames from 'classnames';
import { CellContext } from '@tanstack/react-table';
import { useDispatch } from 'react-redux';
import { Progress } from 'reactstrap';
import { MdMonetizationOn } from 'react-icons/md';
import moment from 'moment';
import style from './data-table-column-cell.module.scss';
import {
  DashboardDataTableDataType,
  DataTableColumnMeta,
  TourIdentifier,
  ValueExpressionDataTableColumnMeta,
} from '@data-table/data-table.types';
import { TourNumberCell } from '@legacy-modules/dashboard/components/table/TourNumberCell';
import AuthGuard from '@legacy-modules/auth/components/AuthGuard';
import { WellKnownPermission } from '@legacy-modules/auth/constants/WellKnownPermission';
import { Link } from '@legacy-modules/tour/components/Link';
import { TruncatedText } from '@components/truncated-text';
import { formatValueExpressionValue } from '@legacy-modules/dashboard/utils/FormatValueExpression';
import { Tooltip } from '@other-components/tooltip';
import { CenterAlignedCell as CenterAlignedText } from '@legacy-modules/dashboard/components/table/CenterAlignedCell';
import DeliveryItemShipmentSearch from '@legacy-modules/tour/components/DeliveryItemShipmentSearch';
import { Dot } from '@legacy-modules/common/components/Dot';
import { useKpiIndicator } from '@legacy-modules/dashboard/hooks/useKpiIndicator';
import { useOrgConfig } from '@hooks/use-org-config-hook';
import { TourUtils } from '@legacy-modules/utils/tours/TourUtils';
import { dashboardSlice } from '@redux/dashboard.slice';
import { reviveGoalClass } from '@hooks/use-org-config-hook/use-org-config.hook';

type ContextType<T, V> = CellContext<T, V>;
type DataTableColumnCellProps<T, V> = {
  ctx: ContextType<T, V>;
  children: ReactElement;
};
const DataTableColumnCell = <T, V>({ ctx, children }: DataTableColumnCellProps<T, V>): ReactElement => {
  const isSorted = ctx.cell.column.getIsSorted();
  const bodyCellClassName = (ctx.cell.column.columnDef.meta as DataTableColumnMeta)?.bodyCellClassName || '';
  const pinnedLeftOffset = (ctx.cell.column.columnDef.meta as DataTableColumnMeta)?.columnStyle?.left || 0;
  return (
    <div
      data-testid='data-table-body-cell'
      key={ctx.cell.id}
      className={classNames(
        style.bodyCell,
        {
          [style.sorted]: isSorted,
          [style.pinned]: ctx.cell.column.getIsPinned(),
        },
        style[bodyCellClassName]
      )}
      style={{ left: pinnedLeftOffset }}>
      {children}
    </div>
  );
};

const TourNumberColumnCell = <T,>(ctx: ContextType<T, TourIdentifier>): ReactElement => {
  const tourIdentifier = ctx.getValue<TourIdentifier>();
  return (
    <DataTableColumnCell ctx={ctx}>
      <TourNumberCell
        value={tourIdentifier?.tourNumber ?? '\u2060'} // zero width space
        orgKey={tourIdentifier?.orgId}
        tourDate={tourIdentifier?.tourDate}
        openable={!!tourIdentifier?.tourNumber && !!tourIdentifier?.orgId && !!tourIdentifier?.tourDate}
        contractorKey={tourIdentifier?.contractorKey}
      />
    </DataTableColumnCell>
  );
};

const OrgNameColumnCell = <T,>(ctx: ContextType<T & { orgKey: string }, string>): ReactElement => {
  const dispatch = useDispatch();
  const { row, getValue } = ctx;
  const value = getValue<string>();
  const onOrgKeyChange = () => {
    dispatch(dashboardSlice.actions.setOrgKey(row.original.orgKey));
  };

  return (
    <DataTableColumnCell ctx={ctx}>
      <AuthGuard requiredPermissions={[WellKnownPermission.TourSeeDetails]} notAllowedComponent={<span>{value}</span>}>
        <Link onClick={onOrgKeyChange} className={style.orgKeyLink}>
          <TruncatedText showTooltipIfCutOff={true}>{value}</TruncatedText>
        </Link>
      </AuthGuard>
    </DataTableColumnCell>
  );
};

const ValueExpressionColumnCell = (ctx: ContextType<DashboardDataTableDataType, string>): ReactElement => {
  const { column, getValue, row } = ctx;
  const value = getValue<string>();
  const {
    valueExpression,
    orgKey: rootOrgKey,
    hasCellColoring,
    duration,
  } = (column.columnDef.meta as ValueExpressionDataTableColumnMeta) || {};
  const rowOrgKey = row.getValue<string>('orgKey');
  // We want to load zielwert for the root orgKey if the row is a tour row
  // and we want to load zielwert for the row orgKey otherwise
  const orgKey = TourUtils.isTourKey(rowOrgKey) ? rootOrgKey : rowOrgKey;

  const { data: orgConfig, isLoading: zielwertIsLoading } = useOrgConfig(orgKey, valueExpression.identifier);

  const zielwert = useMemo(() => {
    return reviveGoalClass(
      orgConfig?.values?.find((config) => config.orgKey === orgKey && config.configKey === valueExpression?.identifier)
        ?.value
    );
  }, [orgConfig, valueExpression, orgKey]);

  const formattedValue = formatValueExpressionValue(valueExpression, value)?.toString();

  const { color } = useKpiIndicator(
    zielwert,
    valueExpression,
    zielwertIsLoading,
    Number(value),
    duration?.amountOfWorkDays
  );

  const showKpiColorDot = hasCellColoring && !!color;

  return (
    <DataTableColumnCell ctx={ctx}>
      {valueExpression.getLabel() === 'Fortschritt' ? (
        <Tooltip tooltipText={formattedValue} triggerStyle={{ display: 'block' }}>
          <Progress value={formattedValue ? parseFloat(formattedValue?.replace(',', '.')) ?? 0 : 0} />
        </Tooltip>
      ) : (
        <CenterAlignedText>
          {showKpiColorDot && <Dot color={color} style={{ marginRight: '8px' }} small />}
          {formattedValue}
        </CenterAlignedText>
      )}
    </DataTableColumnCell>
  );
};

const TruncatedColumnCell = <T,>(ctx: ContextType<T, string>): ReactElement => {
  const { getValue } = ctx;
  const value = getValue<string>();
  return (
    <DataTableColumnCell ctx={ctx}>
      <TruncatedText showTooltipIfCutOff={true}>{value}</TruncatedText>
    </DataTableColumnCell>
  );
};

const ShipmentCell = <T,>(ctx: ContextType<T, string>): ReactElement => {
  const { getValue } = ctx;
  const value = getValue<string>();
  return (
    <DataTableColumnCell ctx={ctx}>
      <div className={style.shipmentCell}>
        <span>{value}</span>
        <DeliveryItemShipmentSearch barcode={value} />
      </div>
    </DataTableColumnCell>
  );
};

const CenterAlignedCell = <T,>(ctx: ContextType<T, string>): ReactElement => {
  const { getValue } = ctx;
  const value = getValue<string>();
  return (
    <DataTableColumnCell ctx={ctx}>
      <CenterAlignedText>{value}</CenterAlignedText>
    </DataTableColumnCell>
  );
};

const WertCell = <T,>(ctx: ContextType<T, boolean>): ReactElement => {
  const { getValue } = ctx;
  const value = getValue<boolean>();
  return (
    <DataTableColumnCell ctx={ctx}>
      <CenterAlignedText>{value === true ? <MdMonetizationOn size={18} title='Wertsendung' /> : '-'}</CenterAlignedText>
    </DataTableColumnCell>
  );
};

const DateCell = <T,>(ctx: ContextType<T, string>): ReactElement => {
  const { getValue } = ctx;
  const value = getValue<string>();
  const format = (ctx.cell.column.columnDef.meta as DataTableColumnMeta)?.dateCellFormat;
  const momentValue = moment(value)?.format(format);
  return (
    <DataTableColumnCell ctx={ctx}>
      <>{momentValue ?? '-'}</>
    </DataTableColumnCell>
  );
};

export default {
  TourNumberColumnCell,
  OrgNameColumnCell,
  ValueExpressionColumnCell,
  TruncatedColumnCell,
  ShipmentCell,
  CenterAlignedCell,
  WertCell,
  DateCell,
};
