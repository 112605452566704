import React from 'react';
import Styles from './AppFullPage.module.scss';

type Props = {
  children: React.ReactElement;
};

export const AppFullPage: React.FC<Props> = ({ children }: Props): React.ReactElement => {
  return <div className={Styles.AppFullPage}>{children}</div>;
};
