import { ColumnFilterContentProps } from '@data-table/data-table.types';
import { getHinweisArts } from '@data-table/utils';
import React, { ReactElement } from 'react';
import { MultiSelectFilterContent } from '../multi-select-filter-content';

export const OrgNameFilterContent = <T, V>({
  table,
  column,
  onConfirm,
  onCancel,
}: ColumnFilterContentProps<T, V>): ReactElement => {
  const rows = column.getFacetedRowModel().rows;

  const allValues = rows.map((row) => row.getValue<string>(column.id));

  return (
    <MultiSelectFilterContent<T, V>
      allValues={allValues}
      table={table}
      column={column}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};
export const HinweisartFilterContent = <T, V>({
  table,
  column,
  onConfirm,
  onCancel,
}: ColumnFilterContentProps<T, V>): ReactElement => {
  const [activeValues, inactiveValues] = getHinweisArts<T, V>(column);

  return (
    <MultiSelectFilterContent<T, V>
      allValues={activeValues}
      inactiveValues={inactiveValues}
      table={table}
      column={column}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};
