import React, { ReactElement } from 'react';
import style from './data-table-column-headers.module.scss';
import {
  default as withDataTableColumnHeaders,
  DataTableColumnHeadersComponentProps,
} from './with-data-table-column-headers.hoc';
import { flexRender, Header } from '@tanstack/react-table';
import { DraggableList } from '@components/draggable-list';
import { DataTableDataType } from '@data-table/data-table.types';

export function DataTableColumnHeaders({
  sortableContextProps,
  dndContextProps,
  direction,
  columnConfig,
  ...htmlProps
}: DataTableColumnHeadersComponentProps): ReactElement {
  return (
    <div {...htmlProps} className={style.dataTableColumnHeaders}>
      <DraggableList.List
        data-testid='data-table-column-headers'
        direction={direction}
        dndContextProps={dndContextProps}
        sortableContextProps={sortableContextProps}
        className={style.list}
        style={columnConfig}>
        {sortableContextProps?.items?.map((header: Header<DataTableDataType, string>) =>
          flexRender(header.column.columnDef.header, {
            ...header.getContext(),
            key: header.id,
          })
        )}
      </DraggableList.List>
    </div>
  );
}
export default withDataTableColumnHeaders(DataTableColumnHeaders);
