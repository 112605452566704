import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.TourLoadedCount,
  label: 'Einsätze',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'tours',
  description: '<p>Anzahl aller geladenen Touren gezählt nach dem ersten abgeschlossenen Zustellvorgang</p>\n',
  definition: getDefinitionByName('Einsätze (Touren)'),
  apiVersion: 'ws',
});

export default new SingleValueExpression(metric);
