const data: any[] = [
  {
    label: 'Touren',
    showInSubmenu: true,
    tabMeta: {
      title: 'Touren',
      valueExpressionKey: 'tour_loaded.count',
    },
  },

  {
    label: 'Rücklauf',
    showInSubmenu: true,
    showInOverview: true,
    tabMeta: {
      title: 'RL-Quote',
      valueExpressionKey: 'ruecklaufquote',
    },
    components: [
      {
        component: 'DetailedChart',
        props: {
          uID: 'ruecklaufChartRuecklaufquote',
          valueExpressionKey: 'ruecklaufquote',
          showChartModeSelector: false,
          showKpiAvg: true,
          showDateSelector: true,
          defaultChartType: 'line',
          title: 'Rücklaufquote',
        },
      },
      {
        component: 'DetailedChart',
        props: {
          uID: 'ruecklaufChartRuecklaufgruende',
          showChartModeSelector: true,
          showDateSelector: true,
          defaultChartType: 'lineStacked',
          showToggle: true,
        },
      },
      {
        component: 'RuecklaufDataTable',
        props: {
          componentId: 'ruecklauf.table',
          availableValueExpressionKeys: [
            'tour_loaded.count',
            'ist_lademenge/touren',
            'soll_lademenge/touren',
            'zustellungen/touren',
            'ruecklaufquote',
            'ruecklaeufer/touren',
            'ruecklaeufer',
            'ruecklauefer_gruende.DeliveryRefused',
            'ruecklauefer_gruende.NoMoney',
            'ruecklauefer_gruende.WrongAddress',
            'ruecklauefer_gruende.MisroutedDeliveryItem',
            'ruecklauefer_gruende.IdentDocument',
            'ruecklauefer_gruende.IdentName',
            'ruecklauefer_gruende.IdentPicture',
            'ruecklauefer_gruende.IdentPin',
            'ruecklauefer_gruende.NotMet',
            'ruecklauefer_gruende.NotMet1',
            'ruecklauefer_gruende.NotMet2',
            'ruecklauefer_gruende.NotMet3',
            'ruecklauefer_gruende.NotMet4',
            'ruecklauefer_gruende.RLoGGS',
            'ruecklauefer_gruende.DeliveryItemDamaged',
            'ruecklauefer_gruende.TooLate',
            'ruecklauefer_eil_gruende.Cancelled',
            'ruecklauefer_eil_gruende.IdentAge',
            'ruecklauefer_eil_gruende.DeliveryItemDamaged',
            'ruecklauefer_eil_gruende.DeliveryRefused',
            'ruecklauefer_eil_gruende.IdentDocument',
            'ruecklauefer_eil_gruende.IdentName',
            'ruecklauefer_eil_gruende.IdentPicture',
            'ruecklauefer_eil_gruende.MisroutedDeliveryItem',
            'ruecklauefer_eil_gruende.NoMoney',
            'ruecklauefer_eil_gruende.NotMet1',
            'ruecklauefer_eil_gruende.NotMet2',
            'ruecklauefer_eil_gruende.NotMet3',
            'ruecklauefer_eil_gruende.NotMet4',
            'ruecklauefer_eil_gruende.RLoGGS',
            'ruecklauefer_eil_gruende.TooLate',
            'ruecklauefer_eil_gruende.WrongAddress',
          ],
          defaultSorting: [
            {
              id: 'rl-quote',
              desc: true,
            },
          ],
          defaultValueExpressionKeys: [
            'tour_loaded.count',
            'ist_lademenge/touren',
            'soll_lademenge/touren',
            'zustellungen/touren',
            'ruecklaeufer/touren',
            'ruecklaeufer',
            'ruecklaufquote',
          ],
        },
      },
    ],
  },
  {
    label: 'Beanstandungen',
    showInSubmenu: true,
    showInOverview: true,
    permission: 'complaints',
    hideForOrgKeyPattern: [/^ov/],
    tabMeta: {
      title: 'Servicebeanstandungen',
      valueExpressionKey: 'sb_all',
    },
    components: [
      {
        component: 'ComplaintsChart',
        props: {
          uID: 'SbTopChart',
          title: 'Servicebeanstandungen nach Meldedatum',
          hideTodayInSelection: true,
        },
      },
      {
        component: 'DetailedChart',
        props: {
          uID: 'SbDetailsChart',
          valueExpressionKey: 'complaints.reported.count_by_type',
          title: 'Servicebeanstandungsgründe nach Meldedatum',
          showChartModeSelector: true,
          threshold: 2,
          showDateSelector: true,
          hideTodayInSelection: true,
        },
      },
      {
        component: 'BeanstandungenDataTable',
        props: {
          componentId: 'beanstandungen.table',
          defaultSorting: [{ id: 'tourIdentifier', desc: true }],
        },
      },
    ],
  },
  {
    label: 'Hinweise',
    showInSubmenu: true,
    showInOverview: false,
    tabMeta: {
      title: 'Hinweise',
      valueExpressionKey: 'notification',
    },
    components: [],
  },
  {
    label: 'Prognose',
    showInSubmenu: true,
    showInOverview: false,
    tabMeta: {
      title: 'Mengenprognose',
      valueExpressionKey: 'mengenprognose',
    },
    hideForOrgKeyPattern: [/^ov/],
    components: [
      {
        component: 'DetailedChart',
        props: {
          uID: 'mengenprognoseChartPrognose',
          valueExpressionKey: 'mengenprognose',
          showChartModeSelector: false,
          showMetricValues: true,
          showKpiAvg: false,
          showDateSelector: true,
          defaultChartType: 'bar',
        },
      },
      {
        component: 'CalendarTable',
        props: {
          uID: 'mengenprognoseCalendarPrognose',
          valueExpressionKey: 'mengenprognose',
          fixedValueExpressionKey: 'fixed_mengenprognose',
          showTotals: true,
          showFixed: false,
        },
      },
    ],
  },
  {
    label: 'Kennzahlen',
    showInSubmenu: true,
    showInOverview: false,
    tabMeta: {
      title: 'Kennzahlen',
      valueExpressionKey: 'Kennzahlen',
    },
    components: [
      {
        component: 'KpiCompare',
        props: {
          uID: 'kpiCompare',
        },
      },
      {
        component: 'KennzahlenDataTable',
        props: {
          componentId: 'kennzahlen.table',
          defaultSorting: [{ id: 'tour_loaded.count', desc: true }],
          defaultValueExpressionKeys: ['tour_loaded.count'],
        },
      },
    ],
  },
  {
    label: 'Tourproduktivität',
    showInSingleTourZeitverlauf: true,
    tabMeta: {
      title: 'HT-Produktivität',
      valueExpressionKey: 'ht-produktivitaet',
    },
  },
  {
    label: 'Lademenge',
    showInSingleTourZeitverlauf: true,
    tabMeta: {
      title: 'Lademenge',
      valueExpressionKey: 'ist_lademenge/touren',
    },
  },
  {
    label: 'Zustellungen',
    showInSingleTourZeitverlauf: true,
    tabMeta: {
      title: 'Zustellungen',
      valueExpressionKey: 'zustellungen/touren',
    },
  },
  {
    label: 'Rücklauf',
    showInSingleTourZeitverlauf: true,
    tabMeta: {
      title: 'RL-Quote',
      valueExpressionKey: 'ruecklaufquote',
    },
  },
  {
    label: 'Zustellzeit',
    showInSingleTourZeitverlauf: true,
    tabMeta: {
      title: 'Brutto-Zustellzeit',
      valueExpressionKey: 'bruttozustellzeit/touren',
    },
  },
];

export default data;
