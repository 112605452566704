import Styles from './ComponentHeader.module.scss';
import React from 'react';
import classNames from 'classnames';

type Props = {
  children?: React.ReactNode;
  headline: string;
  className?: string;
  right?: React.ReactNode;
};

export const ComponentHeader: React.FC<Props> = ({ children, headline, className, right }: Props) => {
  return (
    <div className={classNames(Styles.ComponentHeader, className)}>
      <h2 className={Styles.Headline}>{headline}</h2>
      {children}
      {right && <div className={Styles.RightHandSide}>{right}</div>}
    </div>
  );
};
