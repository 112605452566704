import React from 'react';
import { AiFillExclamationCircle } from 'react-icons/ai';
import Styles from './AnomalyList.module.scss';
import { NO_OP } from '../../dashboard/components/FilterBubble';
import classNames from 'classnames';
import { AnomalyReason } from '../../dashboard/anomalies/utils/AnomalyUtils';
import { useTourDetailListContext } from '../../../refactoring/other/tour-detail-list-context';

type AnomalyType = {
  name: string;
  count?: number;
};

type Props = {
  anomalyNameFilter?: string[];
  onAnomalyClick: (anomalyName: string) => void;
  anomalies?: AnomalyType[];
};

export const AnomalyWarnIcon = () => (
  <span className={Styles.IconWrapper}>
    <AiFillExclamationCircle />
  </span>
);

const AnomalyList: React.FC<Props> = ({ anomalies = [], anomalyNameFilter = [], onAnomalyClick: _onAnomalyClick }) => {
  // const anomalyNameFilter = []; // selectAnomalyNameFilter(state);
  const tourDetailsListContext = useTourDetailListContext();

  const onAnomalyClick = (anomalyName: string) => {
    // toggle anomaly filter or
    // reset all other anomalies filters if
    // anomalyName === "usk.ruecklauf-am-tourende"
    _onAnomalyClick(anomalyName);
    if (anomalyName === AnomalyReason['usk.ruecklauf-am-tourende']) {
      setTimeout(() => {
        // it takes  some time to reset all anomalies filters
        // so we have to wait a bit before we can scroll
        tourDetailsListContext.scrollToRef({ behavior: 'smooth' });
      }, 20);
    }
  };

  return (
    <ul className={Styles.AnomalyList}>
      {anomalies.map((h, i) => (
        <AnomalyListItem
          active={anomalyNameFilter.includes(h.name)}
          onClick={() => onAnomalyClick(h.name)}
          key={i}
          hint={h}
        />
      ))}
    </ul>
  );
};

type ItemListProps = {
  hint: AnomalyType;
  onClick?: () => void;
  active?: boolean;
};

export const AnomalyListItem = (props: ItemListProps) => {
  const {
    hint: { name, count },
    onClick = NO_OP,
    active = false,
  } = props;

  return (
    <li className={classNames(Styles.ListItem, { [Styles.active]: active })} onClick={onClick}>
      <AnomalyWarnIcon />
      <span>{name}</span>
      <span className={Styles.Count}>{count}</span>
    </li>
  );
};

export { AnomalyList };
