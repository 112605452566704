import React from 'react';
import Styles from './TargetArea.module.scss';
import ThresholdValueSelector, { ThresholdValueColorVariant } from '../ThresholdValueSelector';
import { FieldErrors, SetValueConfig } from 'react-hook-form';
import ValueExpression from '../../../valueexpressions/models/valueexpressions/ValueExpression';

type Props = {
  valueExpression: ValueExpression;
  watch: <T>(id: string) => T;
  setValue(name: string, value: number, config?: SetValueConfig): void;
  errors: FieldErrors<any>;
  trigger;
};

export const TargetArea = ({ watch, setValue, valueExpression, errors, trigger }: Props) => {
  return (
    <div className={Styles.TaWrapper}>
      <div className={Styles.TargetArea}>
        <div>
          <strong>grün</strong> zwischen
        </div>
        <div className={Styles.CorridorWrapper}>
          <div className={Styles.SelectorWrapper}>
            <ThresholdValueSelector
              valueExpression={valueExpression}
              colorVariant={ThresholdValueColorVariant.Positive}
              indicatorPosition={'left'}
              value={watch('targetAreaFirst')}
              onValueChange={(v) => {
                if (typeof v !== 'number' || isNaN(v)) {
                  setValue('targetAreaFirst', null, {
                    shouldValidate: true,
                  });
                } else {
                  setValue('targetAreaFirst', v, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }
                trigger(['targetAreaSecond', 'targetAreaFirst']);
              }}
            />
          </div>
          <div className={Styles.SelectorWrapper}>
            <ThresholdValueSelector
              valueExpression={valueExpression}
              colorVariant={ThresholdValueColorVariant.Positive}
              value={watch('targetAreaSecond')}
              onValueChange={(v) => {
                if (typeof v !== 'number' || isNaN(v)) {
                  setValue('targetAreaSecond', null, {
                    shouldValidate: true,
                  });
                } else {
                  setValue('targetAreaSecond', v, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }
                trigger(['targetAreaSecond', 'targetAreaFirst']);
              }}
            />
          </div>
        </div>
      </div>
      {(!!errors?.targetAreaSecond?.type || !!errors?.targetAreaFirst?.type) && (
        <div className={Styles.Error}>
          {errors?.targetAreaSecond?.type === 'required' || errors?.targetAreaFirst?.type === 'required'
            ? 'Es müssen beide oder kein Wert ausgefüllt sein'
            : errors?.targetAreaSecond?.message}
        </div>
      )}
    </div>
  );
};
