import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

const metric = new MetricType({
  key: MetricTypeKey.FinishedDeliveriesStopJourneyDurationSameDayNet,
  label: 'Netto-Zustellzeit alt',
  aggregation: AggregationType.sum,
  chart: ChartType.line,
  positiveDirection: PositiveDirection.custom,
  valueFormat: '#.###.##0,0 h',
  category: 'tours',
  description:
    '<p>Summe der Netto-Zeit im Tourgebiet von erstem bis letztem abgeschlossenen Zustellvorgang am gleichen Tag</p>\n',
  definition: getDefinitionByName('Netto-Zustellzeit alt'),
});

const expression = new SingleValueExpression(metric);

expression.setIdentifier('nettozustellzeit');

export default expression;
