import BasePayload from '../BasePayload';
import { DefaultType, MemberType } from '../../../../../utils/Model';
import { PlainObjectOf } from '../../../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export default class AuthLoginPayload extends BasePayload<AuthLoginPayload> {
  username: string | null | undefined;
  password: string | null | undefined;
  token: string | null | undefined;

  constructor(source: PlainObjectOf<AuthLoginPayload>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<AuthLoginPayload> {
    return undefined;
  }

  getMembers(): MemberType<AuthLoginPayload> {
    return undefined;
  }
}
