import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ValueType } from '@legacy-modules/metrics2/models/enumerations/ValueType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.ZUbyDeliveryType,
  label: 'Zustellungen nach Art',
  sumLabel: 'Zustellungen',
  aggregation: AggregationType.sum,
  hidden: true,
  positiveDirection: PositiveDirection.custom,
  valueType: ValueType.map,
});

export default new SingleValueExpression(metric);
