import { MultiSelectFilterContent } from '../multi-select-filter-content';
import { ColumnFilterContentProps } from '@data-table/data-table.types';
import { getBeanstandungGrunds, getVerlustrelevantBeanstandungGrunds } from '@data-table/utils';
import React, { MouseEventHandler, useCallback, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import style from './beanstandung-grund-filter-content.module.scss';

function BeanstandungGrundFilterContent<T, V>({ table, column, onCancel, onConfirm }: ColumnFilterContentProps<T, V>) {
  const [activeTab, setActiveTab] = useState<'all' | 'lossRelevant'>('all');

  // this filter stores the values of the all tab while user switches to lossRelevant tab
  const [allFilter, setAllFilter] = useState([]);

  const filterValue = column.getFilterValue() as string[];

  const [allValues, inactiveValues] = getBeanstandungGrunds(column);
  const [verlustRelevanteValues, inactiveVerlustRelevanteValues] = getVerlustrelevantBeanstandungGrunds(allValues);

  const onTabChange = useCallback(
    (value: 'all' | 'lossRelevant'): MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        e.preventDefault();
        setActiveTab(value);
        if (value === 'all') {
          const withoutVerlustrelevant = allFilter?.filter((value) => !verlustRelevanteValues.includes(value));
          column.setFilterValue(
            filterValue ? Array.from(new Set([...withoutVerlustrelevant, ...filterValue])) : withoutVerlustrelevant
          );
          table.resetPagination();
        } else {
          setAllFilter(filterValue);
          column.setFilterValue(filterValue?.filter((val) => verlustRelevanteValues.includes(val)));
          table.resetPagination();
        }
      },
    [table, column, filterValue, allFilter, verlustRelevanteValues]
  );

  return (
    <MultiSelectFilterContent<T, V>
      allValues={activeTab === 'all' ? allValues : verlustRelevanteValues}
      inactiveValues={activeTab === 'all' ? inactiveValues : inactiveVerlustRelevanteValues}
      column={column}
      table={table}
      onConfirm={onConfirm}
      onCancel={onCancel}
      headerControls={
        <div className={style.multiSelectFilterHeaderControls}>
          <ButtonGroup size='md' className={style.multiSelectFilterButtonGroup}>
            <Button
              color={activeTab === 'all' ? 'primary' : 'secondary'}
              outline={activeTab === 'lossRelevant'}
              value='all'
              onClick={onTabChange('all')}>
              Freie Auswahl
            </Button>
            <Button
              color={activeTab === 'lossRelevant' ? 'primary' : 'secondary'}
              outline={activeTab === 'all'}
              value='lossRelevant'
              onClick={onTabChange('lossRelevant')}>
              Verlustrelevant
            </Button>
          </ButtonGroup>
        </div>
      }
    />
  );
}

export default BeanstandungGrundFilterContent;
