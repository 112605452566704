import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button, ButtonGroup, Input } from 'reactstrap';
import { ColumnFilterContentProps, ValueExpressionDataTableColumnMeta } from '@data-table/data-table.types';
import { FilterControls } from '../../filter-controls';
import sharedStyle from '../value-expression-filter-content.module.scss';

// TODO: refactor this component to make it more readable and maintainable
// check if all default hooks are indeed needed
// check if you can simplify the code using input ref
const TimeBasedValueExpressionFilterContent = <T, V>({
  column,
  onCancel,
  onConfirm,
}: ColumnFilterContentProps<T, V>) => {
  const filterValue = column.getFilterValue() as V;

  const [value, setValue] = useState(() => {
    const _filterValue = filterValue?.toString()?.replace(/>|</g, '');
    const filterNumberValue = Number(_filterValue);
    if (isNaN(filterNumberValue)) {
      return '';
    }
    const hours = filterNumberValue - (filterNumberValue % 1);
    const minutes = Math.round((filterNumberValue % 1) * 60);
    if (minutes === 0) {
      return `${hours}`;
    }
    return `${hours}:${minutes}`;
  });

  const meta = column?.columnDef?.meta as ValueExpressionDataTableColumnMeta;

  const [toggleState, setToggleState] = useState<'greater' | 'less'>(
    filterValue?.toString()?.startsWith('<') ? 'less' : 'greater'
  );

  const onConfirmCallback = useCallback(() => {
    onConfirm(filterValue);
  }, [filterValue, onConfirm]);

  const onCancelCallback = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const title = useMemo(() => {
    return `${meta?.valueExpression?.getLabel() ?? 'Zeit'} filtern`;
  }, [meta?.valueExpression]);

  const onMaskedValueChange = useCallback(
    (e) => {
      const inputValue: string = e.target.value;
      // This regular expression matches a number that can have up to 10 digits,
      // optionally followed by a comma and another digit.
      // The entire number is optional, meaning it can also match an empty string or undefined
      const isValid = /^\d{0,7}(:[0-5]\d?)?$/.test(inputValue);
      if (!isValid) {
        return;
      }
      const splitValue = inputValue.split(':');
      const hoursValue = splitValue[0];
      const minutesValue = splitValue[1];
      if (minutesValue) {
        setValue(`${hoursValue?.length > 0 ? hoursValue : ''}:${minutesValue}`);
      } else {
        setValue(inputValue);
      }
      const hours = isNaN(Number(hoursValue)) ? 0 : Number(hoursValue);
      // 0:5 -> 0:50
      // 0:50 -> 0:50
      // 0:05 -> 0:05
      const minutesNumberValue = Number(minutesValue?.concat('00')?.slice(0, 2));
      // 60min - 1
      // 59min = 0.98
      const decimalMinutes = isNaN(minutesNumberValue) ? 0 : minutesNumberValue / 60;
      const newFilterValue = hours + decimalMinutes;
      if (newFilterValue > 0) {
        column.setFilterValue(`${toggleState === 'less' ? '<' : '>'}${newFilterValue}`);
      } else {
        column.setFilterValue(undefined);
      }
    },
    [column, toggleState]
  );

  const onFilterToggleChange = useCallback(
    (toggleValue) => () => {
      setToggleState(toggleValue);
      const _filterValue = filterValue?.toString()?.replace(/>|</g, '');
      if (_filterValue) {
        column.setFilterValue(`${toggleValue === 'less' ? '<' : '>'}${_filterValue}`);
      }
    },
    [filterValue, column]
  );

  return (
    <div className={classNames(sharedStyle.filterContent)}>
      <FilterControls
        className={sharedStyle.valueExpressionFilterContent}
        cancelCallback={onCancelCallback}
        confirmCallback={value ? onConfirmCallback : undefined}>
        <div className={sharedStyle.filterContentHeadline}>{title}</div>
        <div className={sharedStyle.numberFilterContentContainer}>
          <div className={sharedStyle.valueExpressionFilterInput}>
            Nur Werte
            <ButtonGroup size='sm' className={sharedStyle.valueExpressionFilterButtonGroup}>
              <Button
                color={toggleState === 'greater' ? 'primary' : 'secondary'}
                outline={toggleState === 'greater' ? false : true}
                size='sm'
                onClick={onFilterToggleChange('greater')}>
                größer
              </Button>
              <Button
                color={toggleState === 'less' ? 'primary' : 'secondary'}
                outline={toggleState === 'less' ? false : true}
                size='sm'
                onClick={onFilterToggleChange('less')}>
                kleiner
              </Button>
            </ButtonGroup>
            <Input value={value} onChange={onMaskedValueChange} placeholder='h:min' />
          </div>
        </div>
      </FilterControls>
    </div>
  );
};

export default TimeBasedValueExpressionFilterContent;
