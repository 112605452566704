import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../dashboard/cssModules/kpiDetailsContainerStyles.scss';
import Styles from './DashboardBreadcrumb.module.scss';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { useViewMode } from '@hooks/use-view-mode-hook';
import {
  dashboardSlice,
  selectActiveDashboardView,
  selectTourDetailsSelectedStop,
  selectTourDetailsTourIdentifier,
  tourDetailsSlice,
} from '@redux';

type Props = {};
const DashboardBreadcrumb: React.FC<Props> = () => {
  const activeDashboard = useSelector(selectActiveDashboardView);
  const tourIdentifier = useSelector(selectTourDetailsTourIdentifier);
  const selectedStopp = useSelector(selectTourDetailsSelectedStop);
  const selectedCustomerRef = selectedStopp?.customerRef;

  const dispatch = useDispatch();

  const valueExpressionMap = useValueExpressionContext();

  const [viewMode, setViewMode] = useViewMode();

  const goBack = useCallback(() => {
    dispatch(tourDetailsSlice.actions.setSelectedStop(null));
    setViewMode('dashboard');
  }, [setViewMode, dispatch]);

  const onDashboardClick = useCallback(() => {
    dispatch(dashboardSlice.actions.setActiveDashboardView(null));
    setViewMode('dashboard');
  }, [setViewMode, dispatch]);

  const onTourClick = useCallback(() => dispatch(tourDetailsSlice.actions.setSelectedStop(null)), [dispatch]);

  const valueExpression = valueExpressionMap.get(activeDashboard) || null;

  const title = (() => {
    switch (activeDashboard) {
      case 'mengenprognose':
        return 'Prognose';
      case 'ruecklaufquote':
        return 'Rücklauf';
      case 'sb_all':
        return 'Servicebeanstandungen';
      case 'tour_loaded.list':
      case 'tour_loaded.count':
        return 'Touren';
      case 'notification':
        return 'Hinweise';
    }
    return valueExpression?.getLabel();
  })();

  return (
    <nav>
      <ol className={Styles.DashboardBreadcrumb}>
        <li className={Styles.Item}>
          <span onClick={onDashboardClick}>Dashboard</span>
        </li>
        {activeDashboard === 'Kennzahlen' && (
          <li className={Styles.Item}>
            <span onClick={goBack}>Kennzahlen</span>
          </li>
        )}
        {title && (
          <li className={Styles.Item}>
            <span onClick={goBack}>{title}</span>
          </li>
        )}
        {tourIdentifier && viewMode !== 'dashboard' && (
          <li className={Styles.Item}>
            <span onClick={onTourClick}>Tour {tourIdentifier.number}</span>
          </li>
        )}
        {selectedCustomerRef && tourIdentifier && viewMode !== 'dashboard' && (
          <li className={Styles.Item}>
            <span>{selectedCustomerRef ? 'Stopp' : 'Offener Stopp'}</span>
          </li>
        )}
      </ol>
    </nav>
  );
};

export default DashboardBreadcrumb;
