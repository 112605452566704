import FinishedDelivery from '../models/entities/FinishedDelivery';
import TourDetails from '../models/entities/TourDetails';
import TourDetailsInTime, { TourDetailsInTimeStats } from '../models/entities/TourDetailsInTime';
import moment from 'moment-timezone';
import UnfinishedCustomerPlanDetails from '../models/entities/UnfinishedCustomerPlanDetails';
import DeliveryModes from '../models/enumerations/DeliveryMode';

export default class TourDetailsInTimeGenerator {
  convert(tourDetails: TourDetails, chosenTime: moment.Moment | null = null): TourDetailsInTime {
    if (!tourDetails) {
      return null;
    }

    const filteredFinishedDeliveries: Array<FinishedDelivery> = Array.from(
      Object.values(tourDetails.finishedDeliveries)
    )
      .filter((fd) => !chosenTime || fd.finishedAt.isBefore(chosenTime))
      .sort((fd1, fd2) => fd1.finishedAt.valueOf() - fd2.finishedAt.valueOf());

    const finishedCustomerRefs = new Set(
      filteredFinishedDeliveries.map((fd) => Object.keys(fd.customerDeliveries)).reduce((a, b) => a.concat(b), [])
    );

    const allItemsDelivered = (customerRef: string): boolean => {
      const customerItemIds = tourDetails.getCustomerDeliveryItems(customerRef).map((x) => x.deliveryItemId);

      const customDeliveredItemIds = filteredFinishedDeliveries
        .flatMap((fd) =>
          fd.deliveryItems.filter((item) => {
            return item.customerRef !== customerRef;
          })
        )
        .map((item) => item.deliveryItemId);

      return customerItemIds.every((v) => customDeliveredItemIds.includes(v));
    };

    const unfinishedCustomers: Array<Partial<UnfinishedCustomerPlanDetails>> = Array.from(tourDetails.customers.keys())
      .filter((k) => !finishedCustomerRefs.has(k))
      .filter((k) => !allItemsDelivered(k))
      .map((customerRef) => {
        return {
          customerRef,
        };
      });
    let lastEstimatedAt = null;
    unfinishedCustomers.forEach((uc) => {
      if (uc.estimatedAt && (!lastEstimatedAt || uc.estimatedAt.isAfter(lastEstimatedAt))) {
        lastEstimatedAt = uc.estimatedAt;
      }
    });

    let distanceDriven = 0;

    const time: moment.Moment = chosenTime || tourDetails.softFinishAt || tourDetails.finishAt || moment();
    let currentLocation = tourDetails.currentDriverLocation;
    let lastLocation = tourDetails.realPath[0];

    if (lastLocation && time.isAfter(lastLocation.date)) {
      tourDetails.realPath.some((location) => {
        if (location.date.isAfter(time)) {
          const timeDiffTotal = location.date.diff(lastLocation.date);
          const timeDiff = time.diff(lastLocation.date);
          distanceDriven += (location.metricDistanceTo(lastLocation) * timeDiff) / timeDiffTotal;
          currentLocation = lastLocation.interpolateTo(location, timeDiff / timeDiffTotal);
          return true;
        } else {
          distanceDriven += location.metricDistanceTo(lastLocation);
          lastLocation = location;
          return false;
        }
      });
    } else {
      // for H@V there was always zeros, didn't want to change live segments though
      filteredFinishedDeliveries.forEach((finishedDelivery) => {
        if (finishedDelivery?.location?.isValid() && finishedDelivery?.routeStop?.realDistanceFromLast < 100000) {
          distanceDriven += finishedDelivery.routeStop.realDistanceFromLast;
        }
      });

      currentLocation = lastLocation;
    }
    distanceDriven = distanceDriven > 0 ? distanceDriven / 1000 : distanceDriven;

    let distanceRemaining = 0;
    unfinishedCustomers.forEach((ucus: UnfinishedCustomerPlanDetails, inx) => {
      ucus.location = tourDetails.customers.get(ucus.customerRef).location;
      const lastFinishedDelivery = Array.from(filteredFinishedDeliveries)
        .reverse()
        .find((fd) => {
          return fd.location && fd.location.isValid();
        });
      const lastFinishedDeliveryLocation = lastFinishedDelivery ? lastFinishedDelivery.location : null;
      if (inx === 0 && lastLocation && lastFinishedDeliveryLocation) {
        distanceRemaining += lastFinishedDeliveryLocation.metricDistanceTo(lastLocation);
      }
      if (ucus.location?.isValid()) {
        if (lastLocation) {
          const distanceFromLast = ucus.location.metricDistanceTo(lastLocation);
          if (distanceFromLast < 100000) {
            distanceRemaining += distanceFromLast;
          }
        }
        lastLocation = ucus.location;
      }
    });
    distanceRemaining = distanceRemaining > 0 ? distanceRemaining / 1000 : distanceRemaining;

    // Calculate times for tour duration (this is the actual working time of the driver)
    let finalTime;
    const startedAt = tourDetails.startedAt;
    if (!chosenTime && (tourDetails.softFinishAt || tourDetails.finishAt)) {
      finalTime = tourDetails.softFinishAt || tourDetails.finishAt;
    } else if (lastEstimatedAt) {
      finalTime = lastEstimatedAt.clone().add(15, 'm');
    } else {
      // start + elaspsedTime * customers.size / finishedCustomers.size
      finalTime = startedAt
        .clone()
        .add(
          (time.diff(startedAt) * tourDetails.customers.size) /
            (tourDetails.customers.size - unfinishedCustomers.length),
          'ms'
        )
        .add(15, 'm');
    }

    // Calculate times for tour productivity
    const productivityStart =
      filteredFinishedDeliveries.length > 0 ? filteredFinishedDeliveries[0].finishedAt : tourDetails.startedAt;

    const filteredDeliveryEnds = filteredFinishedDeliveries
      .map((fd) => fd.finishedAt)
      .filter((f) => f.isSame(productivityStart, 'day'));
    const productivityEnd =
      (tourDetails.softFinishAt || tourDetails.finishAt || !moment().isSame(productivityStart, 'day')) &&
      filteredFinishedDeliveries.length > 0
        ? filteredDeliveryEnds[filteredDeliveryEnds.length - 1]
        : moment();

    const tourStats: TourDetailsInTimeStats = {
      itemsDone: 0,
      itemsUndeliverable: 0,
      itemsDelivered: 0,
      itemsCollected: 0,
      itemsTotal: tourDetails.deliveryItems.size,
      distanceDriven, // TODO: Remove
      distanceRemaining, // TODO: Remove
      timeElapsedMs: time.diff(startedAt), // TODO: Remove?
      timeRemainingMs: finalTime.diff(time), // TODO: Remove?
      productivityTimeElapsedMs: productivityEnd.diff(productivityStart), // TODO: Remove
    };

    const itemsDone = new Set();

    const countedCrefs = new Set();
    [...filteredFinishedDeliveries].reverse().forEach((fd) => {
      // item counts
      fd.deliveryItems.forEach((item) => {
        itemsDone.add(item.deliveryItemId);
      });
      if (fd.deliveryMode === DeliveryModes.undeliverable) {
        tourStats.itemsUndeliverable += fd.deliveryItems.length;
      } else {
        tourStats.itemsDelivered += fd.deliveryItems.length;
        tourStats.itemsCollected += fd.returnDeliveryItems.length;
      }
      Object.keys(fd.customerDeliveries).forEach((cref) => {
        countedCrefs.add(cref);
      });
      // distances
    });

    tourStats.itemsDone = itemsDone.size;

    const tourDetailsIT = {
      filteredFinishedDeliveries,
      unfinishedCustomers,
      currentLocation,
      time,
      stats: tourStats,
      finalDestination: null,
    };

    return new TourDetailsInTime(tourDetailsIT);
  }
}
