import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import { AbstractLimit, CustomLimit, TargetArea } from '../types/AbstractLimit';

export const getDirectionFromGoal = (valueExpression: ValueExpression, goal: AbstractLimit) => {
  if (['up', 'down'].includes(valueExpression.positiveDirection)) {
    return valueExpression.positiveDirection;
  }
  if (valueExpression.positiveDirection === 'custom' && goal instanceof CustomLimit) {
    return goal.direction;
  }
  return null;
};

export const isInGoalArea = (goal: TargetArea, data: number) => {
  if (goal instanceof TargetArea) {
    const sortedAreas = [...goal.targetAreas].sort((a, b) => a - b);
    const isWithinArea = sortedAreas[0] <= data && data <= sortedAreas[1];
    return isWithinArea;
  }
  return false;
};
