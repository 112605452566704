import { Loader } from '../../common/components/Loader';
import React from 'react';
import { AppFullPage } from './AppFullPage';

type Props = {
  loadingText: string;
};

export const AppInitializing: React.FC<Props> = ({ loadingText }) => {
  return (
    <AppFullPage>
      <div
        style={{
          marginTop: '-10%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <Loader />
        <br />
        <h2>{loadingText}</h2>
      </div>
    </AppFullPage>
  );
};
