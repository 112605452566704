import DataConnection from '@legacy-modules/dataconnection/connections/DataConnection';
import { DataConnectionMetaData } from '@legacy-modules/dataconnection/models/types/DataConnectionMetaData';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export type DataConnectionState = {
  [key: string]: {
    initial: boolean;
    data: any;
    meta: DataConnectionMetaData;
  };
};

const initialState: DataConnectionState = {};

/**
 * This state is used to store legacy data connection information.
 * Consider removing this state after old fetch client is removed.
 */
export const dataconnectionSlice = createSlice({
  name: 'dataconnection',
  initialState,
  reducers: {
    createDataConnection: (state, action: PayloadAction<string>) => {
      if (state[action.payload]) return;
      state[action.payload] = {
        initial: true,
        data: null,
        meta: {
          loading: true,
          completed: false,
          lastUpdatedAt: moment(),
        },
      };
    },
    updateFromDataConnection: (
      state,
      action: PayloadAction<{ connectionId: string; dataConnection: DataConnection }>
    ) => {
      const { connectionId, dataConnection } = action.payload;
      state[connectionId] = {
        initial: false,
        data: dataConnection.data,
        meta: {
          loading: dataConnection.isLoading,
          completed: dataConnection.isComplete,
          lastUpdatedAt: dataConnection.lastUpdatedAt,
        },
      };
    },
  },
});
