import React, { HTMLAttributes, ReactElement } from 'react';
import style from './mapbox-style-control.module.scss';
import classNames from 'classnames';
import MapStyleSatImage from '../../../../../assets/images/map_style_sattelite.png';
import { useMapStyleContext } from '../map-style-context';
import { MapboxControl } from '../mapbox-control';

export type MapboxStyleControlProps = HTMLAttributes<HTMLDivElement> & {};

function MapboxStyleControlBase({ ...htmlProps }: MapboxStyleControlProps): ReactElement {
  const styleCtx = useMapStyleContext();
  return (
    <div className={classNames(style.mapboxStyleControl)} onClick={styleCtx?.toggleMapStyle} {...htmlProps}>
      <img src={styleCtx?.mapConfig?.toggleImgSrc ?? MapStyleSatImage} alt='Toggle map style' />
    </div>
  );
}

export const MapboxStyleControl = MapboxControl<MapboxStyleControlProps>(MapboxStyleControlBase);
export default MapboxStyleControl;
