import { DataTableColumnCell } from '@data-table/components/data-table-column-cell';
import { DataTableColumnHeader } from '@data-table/components/data-table-column-header';
import { DataTableColumnMeta, HinweisData } from '@data-table/data-table.types';
import { filterTourIdentifierRows, sortTourIdentifierRows } from '@data-table/utils';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<HinweisData>();

const useHinweiseDataTableColumns = () => {
  return [
    columnHelper.accessor('tourIdentifier', {
      id: 'tourIdentifier',
      header: DataTableColumnHeader.CenterAlignedColumnHeader,
      cell: DataTableColumnCell.TourNumberColumnCell,
      meta: {
        exportValue: 'Tour',
        columnStyle: {
          gridColumn: '100px',
        },
        orgKey: '',
      } as DataTableColumnMeta,
      sortingFn: sortTourIdentifierRows,
      filterFn: filterTourIdentifierRows,
      sortUndefined: 1,
    }),
    columnHelper.accessor('hinweisart', {
      id: 'hinweisart',
      header: DataTableColumnHeader.TruncatedColumnHeader,
      cell: DataTableColumnCell.TruncatedColumnCell,
      meta: {
        exportValue: 'Hinweisart',
        columnCellClassName: 'leftAlignedCell',
        bodyCellClassName: 'leftAlignedCell',
        columnStyle: {
          gridColumn: 'minmax(150px, 1fr)',
        },
      } as DataTableColumnMeta,
      filterFn: 'arrIncludesSome',
      invertSorting: true,
      sortUndefined: 1,
    }),
    columnHelper.accessor('details', {
      id: 'details',
      header: DataTableColumnHeader.TruncatedColumnHeader,
      cell: DataTableColumnCell.TruncatedColumnCell,
      meta: {
        exportValue: 'Details',
        columnCellClassName: 'leftAlignedCell',
        bodyCellClassName: 'leftAlignedCell',
        columnStyle: {
          gridColumn: 'minmax(150px, 1fr)',
        },
      } as DataTableColumnMeta,
      filterFn: 'includesString',
      sortUndefined: 1,
    }),
    columnHelper.accessor('tourdatum', {
      id: 'tourdatum',
      header: DataTableColumnHeader.TruncatedColumnHeader,
      cell: DataTableColumnCell.TruncatedColumnCell,
      meta: {
        exportValue: 'Tourdatum',
        columnCellClassName: 'leftAlignedCell',
        bodyCellClassName: 'leftAlignedCell',
        columnStyle: {
          gridColumn: '150px',
        },
      } as DataTableColumnMeta,
      enableColumnFilter: false,
      sortUndefined: 1,
    }),
  ];
};

export default useHinweiseDataTableColumns;
