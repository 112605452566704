import React, { FC, HTMLAttributes, useCallback } from 'react';
import { KpiTab } from '@legacy-modules/dashboard/websocket/ConfigPayload';
import { DraggableList } from '@components/draggable-list';
import { DragEndEvent, DragOverEvent } from '@dnd-kit/core';
import { motion } from 'framer-motion';
import style from './kpi-overview-settings.module.scss';
import { Tooltip } from '@other-components/tooltip';
import classNames from 'classnames';
import { useLayer } from 'react-laag';
import { useToggleState } from '@hooks/use-toggle-state-hook';
import { AiOutlineSetting } from 'react-icons/ai';
import { MdDragHandle } from 'react-icons/md';
import { EditableText } from '@components/editable-text';
import TextButton from '@legacy-modules/common/components/TextButton';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';

export type KpiOverviewSettingsProps = HTMLAttributes<HTMLDivElement> & {
  tabItems: KpiTab[];
  canAddMoreTabs: boolean;
  onDragOver: (e: DragOverEvent) => void;
  onDragEnd: (e: DragEndEvent) => void;
  onTabRemove: (index: number) => void;
  onTabRename: (index: number, newName: string) => void;
  onTabAdd: () => void;
};

const KpiOverviewSettings: FC<KpiOverviewSettingsProps> = ({
  tabItems,
  canAddMoreTabs,
  onDragOver,
  onDragEnd,
  onTabRemove,
  onTabRename,
  onTabAdd,
}) => {
  const [showSettings, toggleSettings] = useToggleState(false);
  const settingsModal = useLayer({
    isOpen: showSettings,
    onOutsideClick: toggleSettings,
    onParentClose: toggleSettings,
    onDisappear: () => toggleSettings(),
    overflowContainer: true,
    auto: true,
    placement: 'left-start',
    triggerOffset: 20,
    preferX: 'left',
    preferY: 'bottom',
    container: 'layers',
  });

  return (
    <div>
      <Tooltip tooltipText='Tabs Konfigurieren'>
        <div
          className={classNames(style.trigger)}
          data-testid='settings-click'
          {...settingsModal.triggerProps}
          onClick={() => toggleSettings()}>
          <AiOutlineSetting />
        </div>
      </Tooltip>
      {showSettings && (
        <motion.section
          {...settingsModal.layerProps}
          key='modal'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={style.configurationPopup}>
          <div className={style.configurationHeader}>Tabs konfigurieren</div>
          <div className={classNames(style.configurationContainer)}>
            <DraggableList.List
              className={style.listSettings}
              dndContextProps={{ onDragOver, onDragEnd, modifiers: [restrictToVerticalAxis, restrictToParentElement] }}
              sortableContextProps={{ items: tabItems.map((tab) => tab.name) }}>
              {tabItems.map((tab, idx) => (
                <TabSortEntry
                  key={tab.name}
                  index={idx}
                  tabName={tab.name}
                  canBeRemoved={tabItems.length > 1}
                  onTabRemove={onTabRemove}
                  onTabRename={onTabRename}
                />
              ))}
            </DraggableList.List>
          </div>
          <div className={style.addKpi}>
            <TextButton
              disabled={!canAddMoreTabs}
              className={style.addKpiText}
              text='Hinzufügen'
              onClick={() => onTabAdd()}
            />
          </div>
          <div className={style.hint}>
            <p className={style.hintHeading}>Hinweis</p>
            <p>
              Hier können Tabs hinzugefügt oder gelöscht werden. Gleichzeitig kann die Bezeichnung durch Doppelklick
              angepasst werden. Es können insgesamt 5 Tabs angelegt werden.
            </p>
          </div>
        </motion.section>
      )}
    </div>
  );
};

type TabSortEntryProps = {
  index: number;
  tabName: string;
  canBeRemoved: boolean;
  onTabRename: (idx: number, content: string) => void;
  onTabRemove: (idx: number) => void;
};

export const TabSortEntry: FC<TabSortEntryProps> = ({ index, tabName, canBeRemoved, onTabRename, onTabRemove }) => {
  const onRemoveIconClick = useCallback(() => {
    if (!canBeRemoved) {
      return;
    }
    onTabRemove(index);
  }, [canBeRemoved, index, onTabRemove]);

  return (
    <DraggableList.Item sortableArguments={{ id: tabName }}>
      <div className={style.sortEntryContent}>
        <MdDragHandle className={classNames(style.icon, style.dragIcon)} />
        <EditableText
          className={style.tabEntryText}
          initialContent={tabName}
          maxLength={15}
          onEdit={(content: string) => onTabRename(index, content)}
        />
        <IoMdCloseCircleOutline
          aria-label='Tab entfernen'
          className={classNames(style.icon, style.removeIcon, {
            [style.disabled]: !canBeRemoved,
          })}
          onClick={onRemoveIconClick}
        />
      </div>
    </DraggableList.Item>
  );
};

export default KpiOverviewSettings;
