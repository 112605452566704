import OrgUnitNode from '../../metrics2/models/websocket/org/OrgUnitNode';
import OrgTree from '../../metrics2/models/websocket/org/OrgTree';

export class OrgTreeUtils {
  /**
   * Pre-order traversal of orgTree
   * @param orgTree
   * @param orgUnitKey
   * @param orgUnitValue
   * @returns {null|OrgUnitNode|undefined}
   */
  static findNodeInTree(orgTree: OrgUnitNode, orgUnitKey: string, orgUnitValue: string): OrgUnitNode {
    return new OrgTree(orgTree).findOrgUnitValueByFunction((val) => val?.orgUnit?.[orgUnitKey] === orgUnitValue);
  }
}
