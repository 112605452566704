import { PlainObjectOf } from '../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';
import Model, { DefaultType, MemberType } from '../../utils/Model';

export default class Alert extends Model<Alert> {
  level: 'info' | 'warning' | 'danger';
  message: string;
  timestamp: number;

  constructor(source: PlainObjectOf<Alert>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<Alert> {
    return undefined;
  }

  getMembers(): MemberType<Alert> {
    return undefined;
  }
}
