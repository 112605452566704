import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import abholungen from './abholungen';
import mitnahmen from './mitnahmen';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';

const expression = new SumValueExpression(
  abholungen,
  mitnahmen,
  'Abholungen',
  'Abholungen und Mitnahmen',
  '-#.###.##0',
  getDefinitionByName('Abholungen')
);

expression.setIdentifier(MetricTypeKey.ABHMITsum);

export default expression;
