import React, { HTMLAttributes, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import style from './truncated-text.module.scss';
import classNames from 'classnames';
import { Tooltip, TooltipProps } from '../../../other/tooltip';

export type TruncatedTextProps = HTMLAttributes<HTMLDivElement> & {
  children: string;
  showTooltipIfCutOff?: boolean;
  tooltipProps?: TooltipProps;
};

export function TruncatedText({
  showTooltipIfCutOff = true,
  tooltipProps,
  className,
  children,
  ...htmlProps
}: TruncatedTextProps): ReactElement {
  const textRef = useRef<HTMLDivElement>();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (!showTooltipIfCutOff) {
      setVisible(false);
    }
  }, [showTooltipIfCutOff]);
  const open = useCallback(() => {
    const ellipsisActive = textRef?.current?.offsetWidth < textRef?.current?.scrollWidth;
    if (!showTooltipIfCutOff || !ellipsisActive) {
      return;
    }
    setVisible(true);
  }, [showTooltipIfCutOff]);
  const close = useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <Tooltip
      {...tooltipProps}
      tooltipText={children}
      disableTooltip={!visible}
      layerStyle={{ zIndex: 10 }}
      style={{ overflow: 'hidden' }}>
      <div
        {...htmlProps}
        className={classNames(style.truncatedText, className)}
        data-testid='truncated-text'
        ref={textRef}
        onMouseOver={open}
        onMouseOut={close}>
        {children}
      </div>
    </Tooltip>
  );
}
export default TruncatedText;
