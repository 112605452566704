import React, { HTMLAttributes, ReactElement } from 'react';
import style from './data-table-header.module.scss';
import classNames from 'classnames';
import { CsvExportAction } from './csv-export-action';
import { DataTableSettings } from './data-table-settings';
import { FullscreenAction } from './fullscreen-action';
import DateSelect, { DateSelectProps } from '@legacy-modules/common/components/DateSelect';

type HeaderProps = HTMLAttributes<HTMLDivElement>;
const Header = ({ className, children, ...htmlProps }: HeaderProps): ReactElement => {
  return (
    <section data-testid='data-table-header' {...htmlProps} className={classNames(style.tableHeader, className)}>
      {children}
    </section>
  );
};

type TitleSlotProps = HTMLAttributes<HTMLDivElement>;
const TitleSlot = ({ children, className, ...htmlProps }: TitleSlotProps): ReactElement => {
  return (
    <div {...htmlProps} className={classNames(style.title, className)}>
      {children}
    </div>
  );
};

type ActionsSlotProps = HTMLAttributes<HTMLDivElement>;
const ActionsSlot = ({ children, className, ...htmlProps }: ActionsSlotProps): ReactElement => {
  return (
    <div {...htmlProps} className={classNames(style.actions, className)}>
      {children}
    </div>
  );
};

type DateSelectorProps = Omit<HTMLAttributes<HTMLDivElement> & DateSelectProps, 'children'>;
const DateSelector = ({ className, ...props }: DateSelectorProps): ReactElement => {
  return (
    <DateSelect data-testid='data-table-date-select' {...props} className={classNames(style.dateSelect, className)} />
  );
};

export default {
  Header,
  TitleSlot,
  ActionsSlot,
  DateSelector,
  CsvExportAction,
  SettingsAction: DataTableSettings,
  FullscreenAction,
};
