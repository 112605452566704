import React from 'react';
import '../../dashboard/cssModules/kpiDetailsContainerStyles.scss';
import KpiCompareChart from './KpiCompareChart';
import { FloatingComponent } from '../../common/components/FloatingComponent';

type Props = {
  uID: string | null | undefined;
};

const KpiCompare: React.FC<Props> = () => {
  return (
    <FloatingComponent>
      <div className='KpiCompare'>
        <KpiCompareChart uID='kpiCompareChart' showDateSelector />
      </div>
    </FloatingComponent>
  );
};

export default KpiCompare;
