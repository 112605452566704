import React, { HTMLAttributes, PropsWithChildren, ReactElement, useMemo, useState } from 'react';
import classNames from 'classnames';
import { MdArrowBack, MdPlace } from 'react-icons/md';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import style from './stop-details.module.scss';

import { MapboxMap } from '../../common/components/mapbox-map';
import CustomerTrackingData from '../../../modules/tour/models/types/CustomerTrackingData';
import { mapStyleConfig } from '../../common/components/mapbox-map/map-style-context/map-style.context';
import { MapboxMarker } from '../../common/components/mapbox-map/mapbox-marker';
import { MarkerType } from '../../../modules/tour/models/enumerations/MarkerType';
import { DeliveryItemTrackingData } from '../../../modules/tour/models/types/DeliveryItemTrackingData';
import { Badge } from '../../../modules/tour/components/DeliveryBadge';
import { Tooltip } from '../tooltip';
import { AttributeChoiceLabels } from '../../../modules/shipments/models/enums/AttributeLabels';
import DeliveryItemShipmentSearch from '../../../modules/tour/components/DeliveryItemShipmentSearch';
import DeliveryItemServiceIcon from '../../../modules/tour/components/DeliveryItemServiceIcon';
import {
  Bulky,
  Eil,
  IdentService,
  Luggage,
  Mehrcollig,
  Nachnahme,
  Valuable,
  VerbotNachbarschaftsAbgabe,
  WunschTag,
  WunschzeitFenster,
} from '../../../modules/tour/utils/ServiceIcons';
import TourPlanStop from '../../../modules/tour/models/entities/TourPlanStop';
import { MapboxPopup } from '../../common/components/mapbox-map/mapbox-popup';
import AuthGuard from '../../../modules/auth/components/AuthGuard';
import { WellKnownPermission } from '../../../modules/auth/constants/WellKnownPermission';
import { Address } from './address';
import { tourDetailsSlice } from '@redux/tour-details.slice';

export type StopDetailsProps = HTMLAttributes<HTMLDivElement> & {
  customer: CustomerTrackingData;
  deliveryItems: DeliveryItemTrackingData[];
  tourPlanStop: TourPlanStop;
};

export function StopDetails({ customer, deliveryItems, tourPlanStop, ...htmlProps }: StopDetailsProps): ReactElement {
  const validLocation = customer?.location?.isValid();

  return (
    <div className={classNames(style.stopDetails)} {...htmlProps}>
      <Header isTourPlanStop={!!tourPlanStop} />
      <section className={classNames(style.stopDetailsContent)}>
        <Address {...customer} />
        {validLocation && (
          <div className={style.mt50}>
            <SectionTitle>Position</SectionTitle>
            <Minimap customer={customer} />
          </div>
        )}
        <div className={style.mt50}>
          <SectionTitle>Sendungen</SectionTitle>
          <div className={style.deliveryItemsList}>
            {deliveryItems.map((deliveryItem) => (
              <DeliveryItem deliveryItem={deliveryItem} customer={customer} key={deliveryItem.id} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
export default StopDetails;

function SectionTitle({ children }: PropsWithChildren<{}>): ReactElement {
  return <title className={style.stopDetailsHeaderTitle}>{children}</title>;
}

function Header({ isTourPlanStop }: { isTourPlanStop: boolean }): ReactElement {
  const dispatch = useDispatch();
  const goBack = () => {
    dispatch(tourDetailsSlice.actions.setSelectedStop(null));
  };
  return (
    <div className={classNames(style.stopDetailsHeader)}>
      <nav className={style.stopDetailsArrowContainer}>
        <MdArrowBack role='button' size={26} onClick={goBack} className={style.stopDetailsArrow} />
      </nav>
      <SectionTitle>{isTourPlanStop ? 'Geplanter Stopp' : 'Offener Stopp'}</SectionTitle>
    </div>
  );
}

const Minimap = ({ customer }: Pick<StopDetailsProps, 'customer'>): ReactElement => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onMouseEnter = () => {
    setShowTooltip(true);
  };
  const onMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className={style.mapContainer}>
      <MapboxMap
        initialViewState={{
          zoom: 17,
        }}
        latitude={customer.location.lat}
        longitude={customer.location.lng}
        minZoom={6}
        maxZoom={20}
        maxBounds={[
          [5.85, 47.25],
          [15.1, 55.1],
        ]}
        mapStyle={mapStyleConfig.satellite.mapStyleURL}>
        <MapboxMarker
          markerType={MarkerType.planned}
          content={<MdPlace color='white' size={18} />}
          location={customer.location}
          active={true}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
        <MapboxPopup
          shown={showTooltip}
          longitude={customer.location?.lng}
          latitude={customer.location?.lat}
          offset={{ top: [0, 10], bottom: [0, -45] }}
          closeButton={false}
          closeOnClick={true}
          closeOnMove={true}
          focusAfterOpen={false}>
          <div style={{ textTransform: 'capitalize' }}>
            {customer.street?.toLowerCase()} {customer?.streetNr}
          </div>
        </MapboxPopup>
      </MapboxMap>
    </div>
  );
};

type DeliveryItemProps = {
  deliveryItem: DeliveryItemTrackingData;
  customer: CustomerTrackingData;
};
function DeliveryItem({ deliveryItem, customer }: DeliveryItemProps): ReactElement {
  const [open, setOpen] = useState(false);
  const { colli } = deliveryItem?.itemInfo || {};
  const { wunschzeitFenster, nachnahme } = deliveryItem?.service || {};
  const isWunschtag = wunschzeitFenster && Object.keys(wunschzeitFenster).length === 1;
  const wunschzeitDate = useMemo(() => {
    if (isWunschtag) {
      return moment(wunschzeitFenster.date, 'YYYY-MM-DD').format('DD.MM.YYYY');
    }
    return '';
  }, [isWunschtag, wunschzeitFenster]);

  const toggleIsOpen = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  return (
    <div className={style.deliveryItemWrapper}>
      <div className={style.detailsArrow} onClick={toggleIsOpen}>
        {open ? <FaAngleDown fontSize={16} /> : <FaAngleRight fontSize={16} />}
      </div>
      <details open={open} className={style.deliveryItemDetails}>
        <summary className={style.deliveryItemSummary} onClick={toggleIsOpen}>
          <span className={style.deliveryItem}>
            <Badge shortName='?' longName='Geplanter Stopp' color='#c4c4c4' />
            <Tooltip
              className={style.ml10}
              tooltipText={AttributeChoiceLabels['shipment.itemCategory'][deliveryItem.itemInfo.itemCategory]}>
              {deliveryItem.itemInfo.itemCategory}
            </Tooltip>
            <span className={style.mx10}>{deliveryItem?.deliveryItemId}</span>
            <DeliveryItemShipmentSearch barcode={deliveryItem?.deliveryItemId} />
            <DeliveryItemNotificationIcons deliveryItem={deliveryItem} />
          </span>
        </summary>
        <div className={style.deliveryItemContent}>
          <div className={style.deliveryItemMainInfo}>
            <section>
              <span>Empfänger</span>
              <Address {...customer} />
            </section>
            <section>
              <span>Auftraggeber</span>
              <div>{deliveryItem?.itemInfo?.client}</div>
            </section>
          </div>
          {(colli || !!nachnahme || isWunschtag) && (
            <div className={style.deliveryItemAdditionalInfo}>
              {colli && <section>Mehrcollig</section>}
              {!!nachnahme && <section>{`Nachnahme: ${convertNachnahme(nachnahme)} €`}</section>}
              {isWunschtag && <section>{`Wunschtag: ${wunschzeitDate}`}</section>}
            </div>
          )}
        </div>
      </details>
    </div>
  );
}

function DeliveryItemNotificationIcons({ deliveryItem }: Pick<DeliveryItemProps, 'deliveryItem'>): ReactElement {
  const { colli, valuable, luggage, bulky } = deliveryItem?.itemInfo || {};
  const { wunschzeitFenster, eilservice, identservice, nachnahme, verbotNachbarschaftsabgabe } =
    deliveryItem?.service || {};

  const isWunschzeitFenster = wunschzeitFenster?.from && wunschzeitFenster?.to;
  const isWunschtag = wunschzeitFenster && Object.keys(wunschzeitFenster).length === 1;
  const wunschzeitDate = useMemo(() => {
    if (isWunschtag) {
      return moment(wunschzeitFenster.date, 'YYYY-MM-DD').format('DD.MM.YYYY');
    }
    return '';
  }, [isWunschtag, wunschzeitFenster]);
  const nachnahmeConverted = useMemo(() => {
    return convertNachnahme(nachnahme);
  }, [nachnahme]);

  return (
    <span className={style.deliveryItemIconRow}>
      {eilservice && <DeliveryItemServiceIcon title='Eilservice' icon={<Eil />} />}
      {colli && <DeliveryItemServiceIcon title='Mehrcollig' icon={<Mehrcollig />} />}
      {isWunschtag && (
        <DeliveryItemServiceIcon title={`Wunschtag ${isWunschtag && wunschzeitDate}`} icon={<WunschTag />} />
      )}
      {isWunschzeitFenster && (
        <DeliveryItemServiceIcon
          title={`Wunschzeitfenster: ${wunschzeitDate}
              \n${isWunschzeitFenster && moment(wunschzeitFenster.from, 'HH:mm:ss').format('HH:mm')}
               Uhr bis ${isWunschzeitFenster && moment(wunschzeitFenster.to, 'HH:mm:ss').format('HH:mm')} Uhr`}
          icon={<WunschzeitFenster />}
        />
      )}
      {identservice && <DeliveryItemServiceIcon title={'Identservice'} icon={<IdentService />} />}
      {luggage && <DeliveryItemServiceIcon title={'DB Gepäck'} icon={<Luggage />} />}
      {!!nachnahme && <DeliveryItemServiceIcon title={`Nachnahme: ${nachnahmeConverted}€`} icon={<Nachnahme />} />}
      {valuable && <DeliveryItemServiceIcon title={`Wertsendung`} icon={<Valuable />} />}
      {verbotNachbarschaftsabgabe && (
        <DeliveryItemServiceIcon title={'Nachbarschaftsabgabeverbot'} icon={<VerbotNachbarschaftsAbgabe />} />
      )}
      {bulky && <DeliveryItemServiceIcon title='Bulky' icon={<Bulky />} />}
    </span>
  );
}

const convertNachnahme = (nachnahme: number) => {
  if (nachnahme) {
    const val = nachnahme / 100;
    return val.toFixed(2).toString().replace('.', ',');
  }
};
