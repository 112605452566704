import { InputProps, Input } from 'reactstrap';
import React from 'react';
import classNames from 'classnames';
import Styles from './TextInput.module.scss';
import { ErrorMessage } from './ErrorMessage';

interface Props extends InputProps {
  invalidReason: string;
}

const TextInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { invalidReason, invalid, className, ...otherProps } = props;

  return (
    <>
      <Input
        onChange={props.onChange}
        {...otherProps}
        innerRef={ref}
        invalid={invalid}
        className={classNames(Styles.TextInput, className, {
          [Styles.isInvalid]: invalid,
        })}
      />
      {invalid && invalidReason && <ErrorMessage className={Styles.Error}>{invalidReason}</ErrorMessage>}
    </>
  );
});

TextInput.displayName = 'TextInput';

export { TextInput };
