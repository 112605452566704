import React from 'react';
import { Range } from './Range';

type Props = {
  galleryImageSize: number;
  onSizeChangeCallback: (size: number) => void;
};

const GalleryImageSizeSelector: React.FC<Props> = ({ galleryImageSize, onSizeChangeCallback }) => {
  return <Range min={175} max={450} step={25} onChange={onSizeChangeCallback} value={galleryImageSize} />;
};

export { GalleryImageSizeSelector };
