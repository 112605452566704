export class ContractorUtils {
  static readonly CONTRACTOR_PREFIX = 'ov';

  static isContractor(orgKeyOrId: string | number): boolean {
    if (!orgKeyOrId || typeof orgKeyOrId !== 'string') {
      return false;
    }
    return orgKeyOrId.startsWith(ContractorUtils.CONTRACTOR_PREFIX);
  }

  static isHomeTour(orgKey: string): boolean {
    if (!orgKey) return false;
    if (typeof orgKey !== 'string') return false;
    return orgKey.startsWith(`${ContractorUtils.CONTRACTOR_PREFIX}_h`) && orgKey.endsWith('_1');
  }
}
