/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables>(endpoint: string, requestInit: RequestInit, query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(endpoint, {
      method: 'POST',
      ...requestInit,
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  };
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  OrgKey: { input: any; output: any };
};

export type AggregationDateFilter = {
  __typename?: 'AggregationDateFilter';
  range: DateRange;
  weekdays?: Maybe<Array<WeekdayFilter>>;
};

export type AggregationDateFilterInput = {
  range: DateRangeInput;
  weekdays?: InputMaybe<Array<WeekdayFilterInput>>;
};

export type AggregationGroupResult = {
  __typename?: 'AggregationGroupResult';
  dateRange: DateRange;
  group: Scalars['String']['output'];
  kpiValues: Array<AggregationValue>;
  orgKey: Scalars['OrgKey']['output'];
};

export type AggregationRequest = {
  __typename?: 'AggregationRequest';
  dateFilter: AggregationDateFilter;
  dateRangeAggregation: DateRangeAggregationInput;
  dateRangeGrouping: DateRangeGrouping;
  id: Scalars['String']['output'];
  kpiIds: Array<KpiId>;
  orgKeys: Array<Scalars['OrgKey']['output']>;
};

export type AggregationResponse = {
  __typename?: 'AggregationResponse';
  groups: Array<AggregationGroupResult>;
  request: AggregationRequest;
  summary: AggregationSummary;
};

export type AggregationSummary = {
  __typename?: 'AggregationSummary';
  kpiValues: Array<AggregationValue>;
};

export type AggregationValue = {
  __typename?: 'AggregationValue';
  kpiId: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DateRange = {
  __typename?: 'DateRange';
  from: Scalars['Date']['output'];
  until: Scalars['Date']['output'];
};

/** computing aggregations like sum, min and max */
export enum DateRangeAggregationInput {
  /** aggregate result by COUNT */
  Count = 'COUNT',
  /** aggregate result by MAX */
  Max = 'MAX',
  /** aggregate result by MIN */
  Min = 'MIN',
  /** aggregate result by SUM */
  Sum = 'SUM',
}

/** Automatically group according to the selected grouping method */
export enum DateRangeGrouping {
  /** group result by day */
  Day = 'DAY',
  /** group result by month */
  Month = 'MONTH',
  /** group all results into single group */
  Single = 'SINGLE',
  /** group result by week */
  WeekMonday = 'WEEK_MONDAY',
  /** group result by week */
  WeekSunday = 'WEEK_SUNDAY',
  /** group result by year */
  Year = 'YEAR',
}

export type DateRangeInput = {
  from: Scalars['Date']['input'];
  until: Scalars['Date']['input'];
};

export type KpiId = {
  __typename?: 'KpiId';
  id: Scalars['String']['output'];
};

export type KpiIdInput = {
  id: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  kpis: AggregationResponse;
};

export type QueryKpisArgs = {
  dateFilter: AggregationDateFilterInput;
  dateRangeAggregation?: InputMaybe<DateRangeAggregationInput>;
  dateRangeGrouping?: InputMaybe<DateRangeGrouping>;
  kpiIds: Array<KpiIdInput>;
  orgKeys: Array<Scalars['OrgKey']['input']>;
  requestId: Scalars['ID']['input'];
};

export type WeekdayFilter = {
  __typename?: 'WeekdayFilter';
  active: Scalars['Boolean']['output'];
  weekday: WeekdayInput;
};

export type WeekdayFilterInput = {
  active: Scalars['Boolean']['input'];
  weekday: WeekdayInput;
};

export enum WeekdayInput {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED',
}

export type KpisQueryVariables = Exact<{
  requestId: Scalars['ID']['input'];
  kpiIds: Array<KpiIdInput> | KpiIdInput;
  orgKeys: Array<Scalars['OrgKey']['input']> | Scalars['OrgKey']['input'];
  dateFilter: AggregationDateFilterInput;
  dateRangeGrouping?: InputMaybe<DateRangeGrouping>;
  dateRangeAggregation?: InputMaybe<DateRangeAggregationInput>;
}>;

export type KpisQuery = {
  __typename?: 'Query';
  kpis: {
    __typename?: 'AggregationResponse';
    groups: Array<{
      __typename?: 'AggregationGroupResult';
      group: string;
      orgKey: any;
      dateRange: { __typename?: 'DateRange'; from: any; until: any };
      kpiValues: Array<{ __typename?: 'AggregationValue'; kpiId: string; value: string }>;
    }>;
    summary: {
      __typename?: 'AggregationSummary';
      kpiValues: Array<{ __typename?: 'AggregationValue'; kpiId: string; value: string }>;
    };
  };
};

export const KpisDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Kpis' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kpiIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'KpiIdInput' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrgKey' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateFilter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AggregationDateFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeGrouping' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRangeGrouping' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeAggregation' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRangeAggregationInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpis' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'kpiIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'kpiIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orgKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orgKeys' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateFilter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateFilter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateRangeGrouping' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeGrouping' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateRangeAggregation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeAggregation' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'until' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpiValues' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kpiId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'orgKey' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpiValues' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kpiId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KpisQuery, KpisQueryVariables>;

export const QueryKpisDocument = `
    query Kpis($requestId: ID!, $kpiIds: [KpiIdInput!]!, $orgKeys: [OrgKey!]!, $dateFilter: AggregationDateFilterInput!, $dateRangeGrouping: DateRangeGrouping, $dateRangeAggregation: DateRangeAggregationInput) {
  kpis(
    requestId: $requestId
    kpiIds: $kpiIds
    orgKeys: $orgKeys
    dateFilter: $dateFilter
    dateRangeGrouping: $dateRangeGrouping
    dateRangeAggregation: $dateRangeAggregation
  ) {
    groups {
      dateRange {
        from
        until
      }
      group
      kpiValues {
        kpiId
        value
      }
      orgKey
    }
    summary {
      kpiValues {
        kpiId
        value
      }
    }
  }
}
    `;
export const useKpisQuery = <TData = KpisQuery, TError = unknown>(
  dataSource: { endpoint: string; fetchParams?: RequestInit },
  variables: KpisQueryVariables,
  options?: UseQueryOptions<KpisQuery, TError, TData>
) =>
  useQuery<KpisQuery, TError, TData>(
    ['Kpis', variables],
    fetcher<KpisQuery, KpisQueryVariables>(
      dataSource.endpoint,
      dataSource.fetchParams || {},
      QueryKpisDocument,
      variables
    ),
    options
  );
