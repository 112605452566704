import React, { useCallback, useState } from 'react';
import Styles from './DashboardComponentSortPopover.module.scss';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdDragHandle, MdLock } from 'react-icons/md';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

type BoxProps = {
  isDisabled?: boolean;
  onCheck?: () => void;
  checked?: boolean;
  name: string;
  onActivate: (key: string, activate: boolean) => void;
  identifier: string;
};

const Box = SortableElement((props: BoxProps) => {
  const { name, isDisabled = false, checked = false, onActivate, identifier } = props;

  return (
    <div
      className={classNames(Styles.Box, {
        [Styles.disabled]: isDisabled,
      })}>
      <label className={Styles.Label}>
        <input
          className={Styles.CheckboxInput}
          type={'checkbox'}
          disabled={isDisabled}
          onChange={(e) => {
            e.persist();
            onActivate(identifier, e.target.checked);
          }}
          checked={checked}
        />
        <span className={Styles.Checkbox}>
          {(checked || isDisabled) && <MdCheckBox />}
          {!checked && !isDisabled && <MdCheckBoxOutlineBlank />}
        </span>
        {name}
      </label>
      <span className={Styles.DragHandle}>{isDisabled ? <MdLock /> : <MdDragHandle />}</span>
    </div>
  );
});

const SortableList = SortableContainer(({ items, onActivate }) => {
  return (
    <div>
      {items?.map((value, index) => (
        <Box
          key={`item-${value.key}`}
          index={index}
          name={value.name}
          isDisabled={value.disabled}
          // disabled is used by the framework
          disabled={value.disabled}
          onActivate={onActivate}
          identifier={value.key}
          checked={value.active}
        />
      ))}
    </div>
  );
});

type Props = {
  onConfirm?: (items: ComponentSortItem[]) => void;
  items: ComponentSortItem[];
};

export type ComponentSortItem = {
  key: string;
  name: string;
  disabled: boolean;
  active: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DashboardComponentSortPopover = ({ onConfirm = () => undefined, items: itemProps = [] }: Props) => {
  const [items, setItems] = useState<ComponentSortItem[]>(itemProps);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setItems((items) => {
      const firstEnabledAfterIdx = items.slice(newIndex).findIndex((v) => !v.disabled);

      if (firstEnabledAfterIdx < 0) {
        // all disabled
        return items;
      }

      return arrayMove(items, oldIndex, firstEnabledAfterIdx + newIndex);
    });
  }, []);

  const onActivateCallback = (key: string, active: boolean) => {
    setItems((items) => {
      return items.map((k) => {
        if (k.key === key) {
          return {
            ...k,
            active,
          };
        }
        return k;
      });
    });
  };

  return (
    <div className={Styles.Wrapper}>
      <div className={Styles.Main}>
        <h2 className={Styles.Headline}>Komponentenreihenfolge ändern</h2>
        <SortableList
          helperClass={Styles.SortableList}
          distance={5}
          lockAxis={'y'}
          items={items}
          onSortEnd={onSortEnd}
          onActivate={onActivateCallback}
        />
      </div>
      <div className={Styles.NoteSection}>
        <h2 className={Styles.Headline}>Hinweise</h2>
        <p>
          Die Interfaceelemente können hier nicht nur an eine andere Position verschoben, sondern auch per Checkbox an
          und ausgeschaltet werden.
        </p>
      </div>
      <div className={Styles.SubmitFooter}>
        <Button onClick={() => onConfirm(items)} disabled={false} color='primary'>
          Übernehmen
        </Button>
      </div>
    </div>
  );
};
