import moment from 'moment';
import { Duration, DurationModes } from '../../../modules/dashboard/models/enums/Duration';
import { DateRange } from '../../../modules/utils/dates/DateRange';

export class DurationUtils {
  public static getDateRange(duration: Duration): DateRange {
    if (!duration) return;
    if (duration.mode === DurationModes.Custom) {
      const from = moment(duration.from).startOf(duration.range);
      const to = moment(duration.to).endOf(duration.range);
      return { from, to };
    } else {
      const from = moment().add(duration.offset, duration.range).startOf(duration.range);
      const to = moment(from)
        .add(duration.duration - 1, duration.range)
        .endOf(duration.range);
      return { from, to };
    }
  }
  public static getComparisonDateRange(duration: Duration): DateRange {
    if (!duration) return;
    const dateRange = this.getDateRange(duration);
    const startEndDiff = moment(dateRange.to).diff(dateRange.from, 'days') + 1;
    const numberOfWeeks = Math.ceil(startEndDiff / 7);
    const from = moment(dateRange.from).subtract(numberOfWeeks, 'weeks');
    const to = moment(dateRange.to).subtract(numberOfWeeks, 'weeks');
    return { from, to };
  }
}
