import React, { useRef, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ReactPlaceholder from 'react-placeholder';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useLayer } from 'react-laag';
import classNames from 'classnames';

import Styles from './TourKpiSelectorRow.module.scss';

import { DateRangeGrouping } from '../../metrics2/models/enumerations/DateRangeGrouping';
import { useToggleState } from '@hooks/use-toggle-state-hook';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { useAuthContext } from '@contexts/auth-context';
import { getKpiTree } from '@legacy-modules/dashboard/utils/KpiTree';
import KpiSelectorOverlay from '@legacy-modules/dashboard/kpiCompare/KpiSelectorOverlay';
import { useMetricQuery } from '@hooks/use-metric-query-hook';
import {
  DurationKey,
  DurationModes,
  createDurationFromPlainObject,
} from '@legacy-modules/dashboard/models/enums/Duration';
import {
  selectTourDetailsContractorKey,
  selectTourDetailsTourDate,
  selectTourDetailsTourIdentifier,
} from '@redux/tour-details.selectors';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import { KpiFilter } from '@hooks/use-kpi-query-hook/use-kpi-query.hook';
import { formatValueExpressionValue } from '@legacy-modules/dashboard/utils/FormatValueExpression';

type OwnProps = {
  valueExpressionKey: string;
  selectedValueExpressionKeys: string[];
  onKennzahlChange: (kennzahl: string) => void;
};

const TourKpiSelectorRow: React.FC<OwnProps> = ({
  valueExpressionKey,
  selectedValueExpressionKeys,
  onKennzahlChange,
}) => {
  const contractorKey = useSelector(selectTourDetailsContractorKey);
  const { orgId, number } = useSelector(selectTourDetailsTourIdentifier);
  const date = useSelector(selectTourDetailsTourDate);
  const valueExpressionMap = useValueExpressionContext();
  const valueExpression = valueExpressionMap.get(valueExpressionKey);
  const orgKey = `oz_t:${orgId}_${number}`;
  const duration = createDurationFromPlainObject({
    key: DurationKey.CUSTOM,
    mode: DurationModes.Custom,
    label: 'Benutzerdefiniert',
    isVisibleInForecast: false,
    offset: NaN,
    duration: NaN,
    from: date,
    to: date,
    range: DateRangeGrouping.day,
  });

  const isGqlValueExpression = valueExpression?.apiVersion === 'graphql';

  const metricQueryResult = useMetricQuery(
    [orgKey],
    valueExpression,
    duration,
    contractorKey,
    DateRangeGrouping.none,
    false,
    !!valueExpression && !!orgId && !!number && !isGqlValueExpression
  );

  const gqlQueryResult = useKpiQuery(
    {
      orgKeys: [orgKey],
      dateFilter: {
        range: { from: date?.format('YYYY-MM-DD'), until: date?.format('YYYY-MM-DD') },
      },
      kpiIds: valueExpression?.getRequiredMetricTypes().map((metricType) => ({ id: metricType.type.key })),
    },
    {
      enabled: !!valueExpression && !!orgId && !!number && isGqlValueExpression,
      select: (data) => {
        const filteredSummaryKpiValues = data.kpis.summary.kpiValues.filter(KpiFilter);
        return { kpis: { ...data.kpis, summary: { kpiValues: filteredSummaryKpiValues } } };
      },
    }
  );

  const getByOrgKey = metricQueryResult[3];

  const gqlValue = gqlQueryResult?.data?.kpis?.summary?.kpiValues[0]?.value;
  const wsValue = getByOrgKey(orgKey) ?? null;
  const value = isGqlValueExpression ? gqlValue : wsValue;
  const formattedValue = valueExpression ? formatValueExpressionValue(valueExpression, value) : '-';

  const loading = metricQueryResult[1] || gqlQueryResult.isLoading;

  const [settingsOpen, toggleSettings] = useToggleState(false);
  const [kennzahlenSelectorOpen, toggleKennzahlenSelector] = useToggleState(false);

  const showMoreButtonCallback = () => {
    if (kennzahlenSelectorOpen) {
      toggleKennzahlenSelector();
    }
    toggleSettings();
  };

  const wrapperRef = useRef(null);

  const menuLayer = useLayer({
    isOpen: settingsOpen,
    onOutsideClick: toggleSettings, // close the menu when the user clicks outside
    onParentClose: toggleSettings, // close the menu when the parent component gets unmounted
    onDisappear: () => toggleSettings(), // close the menu when the menu gets scrolled out of sight
    overflowContainer: true, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'right-start', // we prefer to place the menu "right-start"
    triggerOffset: 0, // keep some distance to the trigger
    preferX: 'right', // we prefer to align the menu to the right
    preferY: 'bottom', // we prefer to align the menu to the bottom
    container: 'layers',
  });

  const kennzahlenSelectorLayer = useLayer({
    isOpen: kennzahlenSelectorOpen,
    onOutsideClick: toggleKennzahlenSelector, // close the menu when the user clicks outside
    onParentClose: toggleKennzahlenSelector, // close the menu when the parent component gets unmounted
    onDisappear: () => toggleKennzahlenSelector(), // close the menu when the menu gets scrolled out of sight
    overflowContainer: true, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'right-start', // we prefer to place the menu "right-start"
    triggerOffset: 0, // keep some distance to the trigger
    preferX: 'right', // we prefer to align the menu to the right
    preferY: 'bottom', // we prefer to align the menu to the bottom
    container: 'layers',
  });
  const authService = useAuthContext();
  const tree = useMemo(() => {
    return getKpiTree(authService, valueExpressionMap, orgKey);
  }, [valueExpressionMap, authService, orgKey]);

  const addKennzahl = useCallback(
    (kennzahl: string) => {
      onKennzahlChange(kennzahl);
      toggleKennzahlenSelector();
    },
    [onKennzahlChange, toggleKennzahlenSelector]
  );

  const removeKennzahl = () => {
    onKennzahlChange(null);
    toggleSettings();
  };

  const openKennzahlSelector = () => {
    toggleSettings();
    toggleKennzahlenSelector();
  };

  return (
    <div
      className={Styles.KpiRow}
      ref={wrapperRef}
      data-testid='kpi-cell'
      data-kpi={valueExpression?.identifier || 'empty'}
      data-value={value}>
      {valueExpression && (
        <>
          <span className={Styles.value}>
            <ReactPlaceholder showLoadingAnimation rows={1} type={'text'} ready={!loading}>
              {formattedValue}
            </ReactPlaceholder>
          </span>
          <span className={Styles.label}>{valueExpression.getLabel()}</span>
        </>
      )}
      <div
        onClick={showMoreButtonCallback}
        {...menuLayer.triggerProps}
        className={Styles.moreIcon}
        data-testid='kpi-menu-trigger'>
        <div {...kennzahlenSelectorLayer.triggerProps}>
          <BsThreeDotsVertical />
        </div>
      </div>
      {settingsOpen &&
        menuLayer.renderLayer(
          <div {...menuLayer.layerProps}>
            <div className={Styles.kpiRowSettings} data-testid='kpi-menu'>
              <div className={Styles.kpiRowSettingsButton} onClick={openKennzahlSelector}>
                {valueExpression ? 'Kennzahl ändern' : 'Kennzahl hinzufügen'}
              </div>
              <div
                data-disabled={!valueExpression}
                className={classNames(Styles.kpiRowSettingsButton, {
                  [Styles.disabled]: !valueExpression,
                })}
                onClick={removeKennzahl}>
                Kennzahl entfernen
              </div>
            </div>
          </div>
        )}
      {kennzahlenSelectorOpen &&
        kennzahlenSelectorLayer.renderLayer(
          <div {...kennzahlenSelectorLayer.layerProps}>
            <div className={Styles.kennzahlenSelectorContainer}>
              <KpiSelectorOverlay tree={tree} selected={selectedValueExpressionKeys} onSelect={addKennzahl} />
            </div>
          </div>
        )}
    </div>
  );
};

export default TourKpiSelectorRow;
