import React, { ReactElement, useMemo } from 'react';
import style from './mapbox-control.module.scss';
import classNames from 'classnames';

export type MapboxControlProps<T = {}> = T & {
  /**
   * Offset of the control [x, y]
   * @default [0,0]
   */
  offset?: [number, number];
  /**
   * Position of the control
   * @default top-right
   */
  position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
};

export function MapboxControl<T>(Component) {
  return function ({ position = 'top-right', offset = [0, 0], ...props }: MapboxControlProps<T>): ReactElement {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const containerStyle = useMemo(
      () => ({
        top: position.startsWith('top') ? offset[1] : 'auto',
        right: position.endsWith('right') ? offset[0] : 'auto',
        bottom: position.startsWith('bottom') ? offset[1] : 'auto',
        left: position.endsWith('left') ? offset[0] : 'auto',
      }),
      [position, offset]
    );
    return (
      <div className={classNames('mapboxgl-ctrl', style.mapboxControl)} style={containerStyle}>
        <Component {...props} />
      </div>
    );
  };
}
export default MapboxControl;
