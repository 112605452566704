import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import moment from 'moment-timezone';
import { Durations } from '../models/enums/Duration';
import ChartUtils from '../utils/ChartUtils';
import { ValueFormatter } from '../../valueexpressions/models/types/ValueFormatter';
import Styles from './CalendarTableStyles.module.scss';
import { extendMoment } from 'moment-range';
import { FloatingComponent } from '../../common/components/FloatingComponent';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { selectDashboardOrgKey, selectLocalComponentOptions } from '@redux/dashboard.selectors';
import { selectDataconnectionState } from '@redux/dataconnection.selector';
import { useDataConnectionContext } from '@contexts/data-connection-context';
import { useValueExpressionDataContext } from '@contexts/value-expression-data-context';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { range } = extendMoment(moment as any);

type Week = { label: string; week: number; year: number };

type Calendar = {
  weeks: Array<Week>;
  from: moment.Moment;
  to: moment.Moment;
};

type Props = {
  uID: string;
  valueExpressionKey: string;
  fixedValueExpressionKey: string;
  showSunday: boolean;
  showTotals: boolean;
  showFixed: Boolean;
};

const DAYS_OF_WEEK: Array<number> = [1, 2, 3, 4, 5, 6];

const toDay = (week: Week, dayNum: number): moment.Moment =>
  moment().weekYear(week.year).isoWeek(week.week).isoWeekday(dayNum);

const metricByDay = (data: Object | null | undefined, day: moment.Moment): number => {
  const key = day.format('YYYY-MM-DD') + '_day';
  return data ? data[key] : NaN;
};

const CalendarTable = (props: Props) => {
  const { showTotals, showFixed, valueExpressionKey, fixedValueExpressionKey } = props;

  const valueExpressionDataContext = useValueExpressionDataContext();
  const dataConnectionContext = useDataConnectionContext();

  const reduxComponentDuration = useSelector(selectLocalComponentOptions('mengenprognoseChartPrognose', 'duration'));
  const orgKey = useSelector(selectDashboardOrgKey);
  const duration = reduxComponentDuration ?? Durations.next_28;
  const dataFromProps = useSelector(selectDataconnectionState);

  const valueExpressionMap = useValueExpressionContext();

  const calendar = useRef<Calendar>({
    weeks: [],
    from: moment(),
    to: moment(),
  });

  useEffect(() => {
    const registerDataConnection = (key: string, sparse: boolean) => {
      const { from, to } = calendar.current;
      const ve = valueExpressionMap.get(key);

      if (ve) {
        const dataConnection = ChartUtils.buildConnection(
          orgKey,
          from.clone().startOf('week'),
          to.clone().endOf('week'),
          ve,
          valueExpressionDataContext,
          duration,
          sparse
        );

        return dataConnectionContext.registerDataConnection(dataConnection);
      }
    };

    const setupCalendar = (): void => {
      const { from, to } = ChartUtils.getDateRange(duration);
      const r = range(from.clone().startOf('week'), to.clone().endOf('week')).by('week');
      const weeks = [];
      for (const week of r) {
        weeks.push({
          week: week.isoWeek(),
          year: week.weekYear(),
          label: week.format('YYYYww'),
        });
      }
      calendar.current = { weeks, from, to };
    };
    const setupDataConnection = (): void => {
      const dataConnectionKey = registerDataConnection(valueExpressionKey, false);
      setDataConnectionKey(dataConnectionKey);
      if (showFixed) {
        const fixedDataConnectionKey = registerDataConnection(fixedValueExpressionKey, true);
        setFixedDataConnectionKey(fixedDataConnectionKey);
      }
    };
    setupCalendar();
    setupDataConnection();
  }, [
    dataConnectionContext,
    valueExpressionDataContext,
    duration,
    fixedValueExpressionKey,
    orgKey,
    showFixed,
    valueExpressionKey,
    valueExpressionMap,
  ]);

  const [dataConnectionKey, setDataConnectionKey] = useState<string | null>(null);
  const [fixedDataConnectionKey, setFixedDataConnectionKey] = useState<string | null>(null);

  const getData = (connectionKey?: string): Object | null | undefined => {
    if (!connectionKey) return undefined;
    const _data = dataFromProps?.[connectionKey];
    if (_data && _data?.meta?.completed) {
      return _data.data;
    } else {
      return undefined;
    }
  };

  const valueFormatter = (): ValueFormatter => {
    const ve = valueExpressionMap.get(valueExpressionKey);
    return ve ? ve.getValueFormatter() : (n: number) => '' + n;
  };

  const weekTotal = (connectionKey: string | null | undefined, absentIsInvalid: boolean) => {
    return function (week: Week): string {
      if (connectionKey == null) return '';
      const data = getData(connectionKey);

      const areAreMetricsValid = (dayNum: number) => {
        const day = toDay(week, dayNum);
        const metric = metricByDay(data, day);
        return !isNaN(metric);
      };

      const allValid = DAYS_OF_WEEK.every(areAreMetricsValid);
      if (!allValid && absentIsInvalid) return '';

      return DAYS_OF_WEEK.filter(areAreMetricsValid)
        .reduce((sum, dayNum) => {
          const day = toDay(week, dayNum);
          const metric = metricByDay(data, day);
          return sum + metric;
        }, 0)
        .toString();
    };
  };

  const weekTotalStr = (week: Week): [string, string] => weekSummaryStr(week, weekTotal(dataConnectionKey, false));

  const weekFixedStr = (week): [string, string] => {
    return fixedDataConnectionKey != null && props.showFixed
      ? weekSummaryStr(week, weekTotal(fixedDataConnectionKey, true))
      : ['', '0'];
  };

  const weekSummaryStr = (week: Week, totalFn: (week: Week) => string): [string, string] => {
    const fmt = valueFormatter();
    const total = totalFn(week);
    const formatted = total === '' ? '' : fmt(total);
    return [formatted, total];
  };

  const data = getData(dataConnectionKey);
  if (data === undefined) return null;
  const fmt = valueFormatter();

  const cellBuilder = (week: Week) => (dayNum: number) => {
    const day = toDay(week, dayNum);
    const metric = metricByDay(data, day);
    const isToday = day.isSame(moment(), 'day');
    const todayClass = isToday ? Styles.today : '';
    const countClass = todayClass === '' ? Styles.count : todayClass;
    const showDay = day.isBetween(calendar.current.from, calendar.current.to, 'second', '[]');
    const pastClass = showDay || isToday ? '' : Styles.past;
    return (
      <React.Fragment key={`${week.label}_${dayNum}`}>
        <td className={`${todayClass} ${pastClass}`}>{day.format('DD.MM')}</td>
        <td className={`${countClass} ${pastClass}`}>{fmt(metric)}</td>
      </React.Fragment>
    );
  };

  const rows = calendar.current.weeks.map((week) => {
    const [totalStr, total] = weekTotalStr(week);
    const fixedStr = weekFixedStr(week)[0];
    return (
      Number(total) > 0 && (
        <tr key={`${week.label}_row`}>
          <td>{week.week}</td>
          {DAYS_OF_WEEK.map(cellBuilder(week))}
          {showTotals && <td className={Styles.total}>{totalStr}</td>}
          {showFixed && <td className={Styles.fixed}>{fixedStr}</td>}
        </tr>
      )
    );
  });

  const headCells = DAYS_OF_WEEK.map((day) => {
    const name = moment().day(day).format('dddd');
    return (
      <React.Fragment key={day}>
        <th colSpan={2}>{name}</th>
      </React.Fragment>
    );
  });

  const totals = showTotals && <th className={Styles.total}>∑</th>;
  const fixed = showFixed && <th className={Styles.fixed}>𝔼</th>;
  const header = (
    <tr>
      <th>KW</th>
      {headCells}
      {totals}
      {fixed}
    </tr>
  );

  if (!data) return null;

  return (
    <FloatingComponent className={Styles.CalendarTable}>
      <div className={Styles.shadow} />
      <div>
        * Eine Aktualisierung der Prognose findet im Vier-Wochen-Zyklus statt. Bitte achtet auf das angegebene Datum der
        Aktualisierung. Die tatsächlich angelieferten Sendungsmengen können von den prognostizierten Mengen abweichen.
        Bei Fragen zur Prognose wenden Sie sich an&nbsp;
        <a href='mailto:MP-Coreteam@hermesworld.com'>MP-Coreteam@hermesworld.com</a>.
      </div>
      <div className={Styles.shadow} />
      <div className={Styles.scroll}>
        <Table>
          <thead>{header}</thead>
          <tbody>{rows}</tbody>
          <tfoot />
        </Table>
      </div>
    </FloatingComponent>
  );
};

export default CalendarTable;
