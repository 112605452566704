import classNames from 'classnames';
import React, { useState, useCallback, useMemo } from 'react';
import { AnomalyReason, countAnomaliesTypes } from '../../dashboard/anomalies/utils/AnomalyUtils';
import TourDetails from '../models/entities/TourDetails';
import { KpiSlider } from '@components/kpi-slider';
import { knownAnomalyFilter } from '../utils/KnownAnomalyFilter';
import { AnomalyList, AnomalyWarnIcon } from './AnomalyList';
import FilterBadgePanel from './FilterBadgePanel';
import style from './TourFilterPanel.module.scss';
import { useTourDetailsContext } from '@other-components/tour-details-context';

type Props = {
  tourDetails: TourDetails;
};

type TabProps = {
  children: React.ReactChildren | React.ReactChild;
  hasNewItems?: boolean;
  active: boolean;
  onTabSelection: (idx: number) => void;
  idx: number;
  disabled: boolean;
};

const Tab = ({ hasNewItems = false, active, idx, onTabSelection, children, disabled }: TabProps) => {
  return (
    <span
      className={classNames(style.tabItem, {
        [style.active]: active,
        [style.hasNewElements]: hasNewItems,
        [style.disabled]: disabled,
      })}
      onClick={() => onTabSelection(idx)}>
      {children}
    </span>
  );
};

const TourFilterPanel: React.FC<Props> = ({ tourDetails }) => {
  const {
    anomalies,
    identifier: { date },
  } = tourDetails;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const {
    anomalyFilterState: [, setAnomalyFilter],
    zustellartFilterState: [, setZustellartFilter],
  } = useTourDetailsContext();

  const anomaliesWithCount = useMemo(() => {
    const known = anomalies.filter(knownAnomalyFilter);
    const counted = countAnomaliesTypes(known);
    return counted;
  }, [anomalies]);

  const hasAnomalies = useMemo(() => anomaliesWithCount.length > 0, [anomaliesWithCount.length]);

  const onAnomalyClick = useCallback(
    (anomalyName: string) => {
      if (anomalyName === AnomalyReason['usk.ruecklauf-am-tourende']) {
        // reset all other active anomalies filter before scrolling to the bottom of the page (AUP-497)
        setAnomalyFilter([]);
        return;
      }
      setAnomalyFilter((state) =>
        state.includes(anomalyName) ? state.filter((o) => o !== anomalyName) : [...state, anomalyName]
      );
    },
    [setAnomalyFilter]
  );

  const tabContent = (
    <>
      {selectedTabIndex === 0 && (
        <div className={style.content}>
          <KpiSlider tourDate={date} />
        </div>
      )}
      {selectedTabIndex === 1 && (
        <div className={style.content}>
          <div className={style.kpiSelectorIndicesWrapper}>
            <div className={style.flexWrapper}>
              <FilterBadgePanel />
            </div>
          </div>
        </div>
      )}
      {selectedTabIndex === 2 &&
        (hasAnomalies ? (
          <div className={classNames(style.content, style.noPadding, style.anomalies)}>
            <div className={classNames(style.kpiSelectorIndicesWrapper, style.alignTop, style.noPadding)}>
              <div className={style.flexWrapper}>
                <AnomalyList anomalies={anomaliesWithCount} onAnomalyClick={onAnomalyClick} />
              </div>
            </div>
          </div>
        ) : (
          <div className={classNames(style.content, style.noPadding)}>
            <div className={classNames(style.kpiSelectorIndicesWrapper, style.alignCenter, style.noPadding)}>
              <span>Es liegen keine Hinweise vor.</span>
            </div>
          </div>
        ))}
    </>
  );

  const handleTabSelection = useCallback(
    (index) => {
      setAnomalyFilter([]);
      setZustellartFilter([]);
      setSelectedTabIndex(index);
    },
    [setAnomalyFilter, setSelectedTabIndex, setZustellartFilter]
  );

  const createTab = (content: React.ReactChild | React.ReactChildren, tabIdx: number, disabled: boolean = false) => (
    <Tab idx={tabIdx} active={selectedTabIndex === tabIdx} onTabSelection={handleTabSelection} disabled={disabled}>
      {content}
    </Tab>
  );

  return (
    <div className={style.tourFilterPanel}>
      <div className={style.tabs}>
        {createTab('Kennzahlen', 0)}
        {createTab('Zustellart', 1)}
        {createTab(
          <div
            className={classNames(style.anomalyTab, {
              [style.disabled]: !hasAnomalies,
            })}>
            <span>Hinweise</span>
            {hasAnomalies && (
              <span className={style.icon}>
                <AnomalyWarnIcon />
              </span>
            )}
          </div>,
          2,
          !hasAnomalies
        )}
      </div>
      {tabContent}
    </div>
  );
};

export default TourFilterPanel;
