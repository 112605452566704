import React, { MutableRefObject, useCallback } from 'react';
import Styles from './SearchField.module.scss';
import { MdClose, MdSearch } from 'react-icons/md';

type Props = {
  onChange: (text: string) => void;
  value?: string;
};

const SearchField = React.forwardRef(({ onChange, value = '' }: Props, ref: MutableRefObject<HTMLInputElement>) => {
  const onChangeCallBack = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [onChange]
  );

  const onRemoveCallback = useCallback(() => onChange(''), [onChange]);

  return (
    <div className={Styles.SearchField}>
      <div className={Styles.Icon}>
        <MdSearch />
      </div>
      <input
        ref={ref}
        tabIndex={1}
        autoFocus
        type='text'
        className={Styles.Input}
        placeholder='Durchsuchen'
        value={value}
        onChange={onChangeCallBack}
      />
      {value.length ? (
        <div className={Styles.RemoveButton} onClick={onRemoveCallback}>
          <MdClose />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
});

SearchField.displayName = 'SearchField';

export { SearchField };
