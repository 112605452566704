import { Input, InputProps } from 'reactstrap';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { MdClose, MdSearch } from 'react-icons/md';
import classNames from 'classnames';

import { useKeyboardShortcut } from '../../navigation/hooks/useKeyboardShortcut';

import Styles from '../containers/NavigationBarDashboardShipmentSearch.module.scss';

type SearchInputProps = {
  onResetClick: () => void;
} & InputProps;

const SearchInput: React.FC<SearchInputProps> = React.forwardRef(
  ({ onResetClick, className, placeholder = 'Suche...', value, ...otherProps }, ref: React.Ref<HTMLInputElement>) => {
    const [focused, setFocused] = useState(false);
    const closeOnEscapeIfFocused = useCallback(
      (event: KeyboardEvent) => {
        if (!focused) return;
        onResetClick();
        event.stopImmediatePropagation();
      },
      [focused, onResetClick]
    );

    useKeyboardShortcut(
      {
        code: 'Escape',
      },
      closeOnEscapeIfFocused
    );

    return (
      <div className={Styles.SearchInputWrapper}>
        <div className={Styles.SearchIcon}>
          <MdSearch />
        </div>
        <Input
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          innerRef={ref}
          value={value}
          className={classNames(Styles.SearchInput, className)}
          placeholder={placeholder}
          {...otherProps}
        />
        {value && `${value}`.length > 0 && (
          <div className={Styles.RemoveIcon} onClick={onResetClick}>
            <MdClose />
          </div>
        )}
      </div>
    );
  }
);

export { SearchInput };
