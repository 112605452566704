import React, { ReactElement, useCallback } from 'react';
import style from './value-expression-table-header.module.scss';
import DataTableHeader from '../data-table-header';
import { Header } from '@tanstack/react-table';
import { MdDragHandle, MdLock } from 'react-icons/md';
import {
  ValueExpressionTableHeaderComponentProps,
  default as withValueExpressionTableHeader,
} from './with-value-expression-table-header.hoc';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import classNames from 'classnames';
import { useDndContext } from '@dnd-kit/core';
import ValueExpressionTableSettings from '../data-table-settings/value-expression-table-settings/value-expression-table-settings';
import { DraggableList } from '@components/draggable-list';
import { DataTableDataType, ValueExpressionDataTableColumnMeta } from '@data-table/data-table.types';
import { TruncatedText } from '@components/truncated-text';

const ValueExpressionTableHeader = ({
  title,
  duration,
  headers,
  valueExpressionKeys,
  availableKpiSelectorKeys,
  scrollContainerRef,
  isFullscreen,
  disabled = false,
  loading = false,
  onColumnRemove,
  onColumnAdd,
  onDurationChange,
  onDragEnd,
  onDragOver,
}: ValueExpressionTableHeaderComponentProps): ReactElement => {
  return (
    <DataTableHeader.Header>
      <DataTableHeader.TitleSlot>{title}</DataTableHeader.TitleSlot>
      <DataTableHeader.ActionsSlot>
        <DataTableHeader.FullscreenAction disabled={disabled || loading} />
        <DataTableHeader.CsvExportAction disabled={disabled || loading} />
        <DataTableHeader.SettingsAction className={style.settingsContainer} disabled={disabled || loading}>
          <ValueExpressionTableSettings
            valueExpressionKeys={valueExpressionKeys}
            availableKpiSelectorKeys={availableKpiSelectorKeys}
            addValueExpressionKey={onColumnAdd}
            removeValueExpressionKey={onColumnRemove}
          />
          <DraggableList.List
            direction='vertical'
            sortableContextProps={{
              items: headers,
            }}
            dndContextProps={{
              onDragOver,
              onDragEnd,
            }}
            scrollContainerRef={scrollContainerRef}
            className={style.headerSettings}>
            {headers?.map((header) => (
              <ValueExpressionTableSortEntry header={header} key={header.id} onColumnRemove={onColumnRemove} />
            ))}
          </DraggableList.List>
        </DataTableHeader.SettingsAction>
      </DataTableHeader.ActionsSlot>
      <DataTableHeader.DateSelector
        container={isFullscreen ? undefined : 'body'}
        onDurationSelected={onDurationChange}
        selectedDuration={duration}
        disabled={loading}
      />
    </DataTableHeader.Header>
  );
};

type ValueExpressionTableSortEntryProps = {
  header: Header<DataTableDataType, any>;
  onColumnRemove: (key: string) => void;
};
function ValueExpressionTableSortEntry({ header, onColumnRemove }: ValueExpressionTableSortEntryProps) {
  const isPinned = !!header.column.getIsPinned();
  const label = (header.column.columnDef?.meta as ValueExpressionDataTableColumnMeta).exportValue;

  const onColumnRemoveCallback = useCallback(() => {
    onColumnRemove((header.column.columnDef?.meta as ValueExpressionDataTableColumnMeta)?.valueExpression?.identifier);
  }, [header.column.columnDef.meta, onColumnRemove]);

  const dndCtx = useDndContext();
  const isDragged = dndCtx.active?.id === header?.id;
  return (
    <DraggableList.Item
      id={header.id}
      sortableArguments={{
        id: header.id,
        disabled: isPinned,
      }}
      pinned={isPinned}
      pinningEnabled={false}>
      <div className={style.sortEntryContent}>
        {isPinned ? <MdLock className={style.icon} /> : <MdDragHandle className={style.icon} />}
        <TruncatedText showTooltipIfCutOff={!isDragged}>{label}</TruncatedText>
        {isPinned ? (
          <div />
        ) : (
          <IoMdCloseCircleOutline
            className={classNames(style.icon, style.closeIcon)}
            onClick={onColumnRemoveCallback}
          />
        )}
      </div>
    </DraggableList.Item>
  );
}

export default withValueExpressionTableHeader(ValueExpressionTableHeader);
