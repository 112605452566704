import { RefObject, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useResizeListener = (target: React.RefObject<any>, onResize: (ref: RefObject<any>) => void): void => {
  useEffect(() => {
    const currTarget = target.current;

    if (!currTarget) return;

    if (!ResizeObserver || !currTarget || !onResize) {
      return;
    }

    // Resize observer code is wrapped to prevent errors: https://stackoverflow.com/a/58701523
    const resizeObserver = new ResizeObserver(() => window.requestAnimationFrame(() => onResize(target)));

    resizeObserver.observe(currTarget);

    return () => {
      if (!ResizeObserver || !currTarget || !onResize) {
        return;
      }

      resizeObserver.unobserve(currTarget);
    };
  }, [onResize, target]);
};
