import Model, { DefaultType, MemberType } from '../../utils/Model';

type TrackEvent = {
  event: {
    type?: string;
    [key: string]: string;
  };
};
export default class TrackRequest extends Model<TrackEvent> {
  event: TrackEvent;

  constructor(source: TrackEvent, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<TrackRequest> {
    return undefined;
  }

  getMembers(): MemberType<TrackRequest> {
    return undefined;
  }
}
