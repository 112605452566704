import BasePayload from '../../../../common/models/websocket/payloads/BasePayload';
import { DateRangeGrouping } from '../../enumerations/DateRangeGrouping';
import { DefaultType, MemberType } from '../../../../utils/Model';
import { AggregationType } from '../../enumerations/AggregationType';
import { WeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';

export default class MetricsQueryRequestPayload extends BasePayload<MetricsQueryRequestPayload> {
  type: string;
  orgKey: string;
  dateFilter: {
    range: {
      from: string;
      until: string;
    };
    weekdays: WeekdaysFilter;
  };
  grouping?: DateRangeGrouping;
  valueKey?: string;
  aggregation?: AggregationType;
  contractorKey?: string;

  constructor(
    source: Pick<
      MetricsQueryRequestPayload,
      'type' | 'orgKey' | 'dateFilter' | 'grouping' | 'valueKey' | 'aggregation' | 'contractorKey'
    >,
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<MetricsQueryRequestPayload> {
    return undefined;
  }

  getMembers(): MemberType<MetricsQueryRequestPayload> {
    return undefined;
  }
}
