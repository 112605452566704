import React, { useCallback, useMemo } from 'react';
import Styles from './KpiCustomThresholdSelection.module.scss';
import { FieldErrors, SetValueConfig } from 'react-hook-form';
import { KpiThresholdBounds } from '../KpiOverview';
import { ILimitBounds } from '../../websocket/DashboardLoadLimitResponsePayload';
import ValueExpression from '../../../valueexpressions/models/valueexpressions/ValueExpression';
import { ToggleButtonGroup } from '../../../common/components/ButtonGroup';
import { ToggleButton } from '../../../common/components/ToggleButton';
import ThresholdValueSelector, { ThresholdValueColorVariant } from '../ThresholdValueSelector';
import { PositiveDirection } from '../../../metrics2/models/entities/PositiveDirection';

export const getBounds = (
  forWhich: 'positive' | 'negative',
  bounds: KpiThresholdBounds,
  defaultBounds: ILimitBounds
) => {
  if (bounds && typeof bounds[forWhich] === 'number') {
    return bounds[forWhich];
  } else if (defaultBounds && typeof defaultBounds[forWhich] === 'number') {
    return defaultBounds[forWhich];
  } else {
    return NaN;
  }
};

type Props = {
  valueExpression: ValueExpression;
  getValues: (id: string) => string;
  watch: <T>(id: string) => T;
  setValue<T>(name: string, value: T, config?: SetValueConfig): void;
  errors: FieldErrors<any>;
  reset: (val: string | string[]) => void;
  trigger: (field: string | string[]) => void;
};

type LimitModeType = 'up' | 'down';

export const useGoalInput = (
  reset: (val: string | string[]) => void,
  getValues: (id: string) => string,
  setValue: <T>(name: string, value: T, config?: SetValueConfig) => void,
  trigger: (val: string | string[]) => void,
  defaultDirection: PositiveDirection
) => {
  const setGoal = useCallback(
    (formValueField: 'positiveVal' | 'negativeVal') => (v) => {
      if (defaultDirection) {
        setValue('direction', defaultDirection);
      }

      if (typeof v !== 'number' || isNaN(v)) {
        setValue(formValueField, null, {
          shouldValidate: true,
        });
        trigger(['positiveVal', 'negativeVal']);
        return;
      }

      setValue(formValueField, v, {
        shouldValidate: true,
      });
      trigger(['positiveVal', 'negativeVal']);
    },
    [setValue, trigger, defaultDirection]
  );
  const setPositiveGoal = useMemo(() => setGoal('positiveVal'), [setGoal]);
  const setNegativeGoal = useMemo(() => setGoal('negativeVal'), [setGoal]);

  return {
    setPositiveGoal,
    setNegativeGoal,
  };
};

export const CustomGoal = ({ getValues, watch, setValue, valueExpression, errors, reset, trigger }: Props) => {
  const onPositiveValueFocusOut = useCallback(() => {
    const positiveVal = getValues('positiveVal');
    const negativeVal = getValues('negativeVal');

    if (negativeVal == null) {
      setValue('negativeVal', positiveVal, {
        shouldValidate: true,
      });
      trigger(['negativeVal', 'positiveVal']);
    }
  }, [getValues, setValue, trigger]);

  const onToggleChange = useCallback(
    (mode: LimitModeType | null) => {
      if (!mode) {
        return;
      }
      setValue('direction', mode, {
        shouldValidate: true,
        shouldDirty: true,
      });
      trigger(['positiveVal', 'negativeVal']);
    },
    [setValue, trigger]
  );

  const { setPositiveGoal, setNegativeGoal } = useGoalInput(reset, getValues, setValue, trigger, null);

  const positiveSelector = (
    <>
      <span className={Styles.Name}>
        <strong>grün</strong> wenn
      </span>
      <div className={Styles.ToggleWrapper}>
        <ToggleButtonGroup onChange={(e, value) => onToggleChange(value as LimitModeType)} value={watch('direction')}>
          <ToggleButton value={'up'}>größer</ToggleButton>
          <ToggleButton value={'down'}>kleiner</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={Styles.ThresholdWrapper}>
        <ThresholdValueSelector
          onValueChange={setPositiveGoal}
          tabIndex={1}
          value={watch('positiveVal')}
          valueExpression={valueExpression}
          colorVariant={ThresholdValueColorVariant.Positive}
          onBlur={onPositiveValueFocusOut}
        />
      </div>

      {!!errors?.positiveVal && (
        <div className={Styles.Error}>
          <>{(errors?.positiveVal.message as string)?.replace('positiveVal', 'Wert')}</>
        </div>
      )}
    </>
  );

  const negativeSelector = (
    <>
      <span className={Styles.Name}>
        <strong>rot</strong> wenn
      </span>
      <div className={Styles.ToggleWrapper}>
        <ToggleButtonGroup
          onChange={(e, value) => onToggleChange(value as LimitModeType)}
          value={watch('direction')}
          exclusive>
          {/* notice: This is reversed here, to always be the opposite of the green value */}
          <ToggleButton value={'down'}>größer</ToggleButton>
          <ToggleButton value={'up'}>kleiner</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={Styles.ThresholdWrapper}>
        <ThresholdValueSelector
          onValueChange={setNegativeGoal}
          tabIndex={2}
          valueExpression={valueExpression}
          colorVariant={ThresholdValueColorVariant.Negative}
          value={watch('negativeVal')}
        />
      </div>
      {!!errors?.negativeVal && (
        <div className={Styles.Error}>
          <>{(errors?.negativeVal.message as string)?.replace('negativeVal', 'Wert')}</>
        </div>
      )}
    </>
  );

  return (
    <>
      <div className={Styles.Selector}>
        <div className={Styles.LimitWrapper}>{positiveSelector}</div>
      </div>
      <div className={Styles.Selector}>
        <div className={Styles.LimitWrapper}>{negativeSelector}</div>
      </div>
    </>
  );
};
