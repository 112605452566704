import React, { HTMLAttributes, ReactElement, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Styles from './DateSelect.module.scss';
import { DateRange } from '../../utils/dates/DateRange';
import moment from 'moment';
import { MdDateRange } from 'react-icons/md';
import { getHumanReadableDateText } from '../../navigation/utils/DateRangeTextUtils';
import {
  Duration,
  DurationModes,
  Durations,
  DurationsType,
  WeekdaysFilter,
  defaultWeekdaysFilter,
} from '../../dashboard/models/enums/Duration';
import { useRanges } from '../../dashboard/hooks/useRanges';
import DateRangeSelectorPopup, {
  weekdayLabels as allWeekdayLabels,
} from '../../navigation/components/DateRangeSelectorPopup';
import classNames from 'classnames';

export const ForecastConfig = Object.values(Durations).filter((d) => d.isVisibleInForecast);

export const DateSelectionDefaultConfig = Object.values(Durations).filter(
  (d) => d.mode !== DurationModes.Today && d.mode !== DurationModes.Forecast
);

export const DateSelectIncludingTodayConfig = [Durations.today, ...DateSelectionDefaultConfig];

type CustomItemProps = {
  label: string;
  active: boolean;
  onSelect: () => void;
};

const CustomItem: React.FC<CustomItemProps> = ({ label, active, onSelect }: CustomItemProps): ReactElement => {
  return (
    <React.Fragment key='customdurationmode'>
      <DropdownItem divider />
      <DropdownItem active={active} onClick={onSelect} style={{ minWidth: '150px' }}>
        {label}...
      </DropdownItem>
    </React.Fragment>
  );
};

export type DateSelectProps = HTMLAttributes<HTMLDivElement> & {
  selectedDuration: Duration;
  onDurationSelected: (duration: Partial<Duration>) => void;
  config?: DurationsType[];
  displayCompare?: boolean;
  disabled?: boolean;
  container?: string | RefObject<HTMLDivElement>;
  'data-testid'?: string;
  showWeekdaysFilter?: boolean;
};

const DateSelect: React.FC<DateSelectProps> = ({
  selectedDuration,
  onDurationSelected,
  disabled = false,
  config = DateSelectIncludingTodayConfig,
  className,
  container,
  showWeekdaysFilter = true,
  'data-testid': testId = 'date-select',
  ...htmlProps
}): ReactElement => {
  const popupWrapperRef = useRef<HTMLDivElement>();

  const [customOpen, setCustomOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleCustom = useCallback(() => {
    setCustomOpen((customOpen) => !customOpen);
  }, [setCustomOpen]);

  const toggle = useCallback(() => {
    setDropdownOpen(!dropdownOpen);
  }, [setDropdownOpen, dropdownOpen]);

  const _onDurationSelected = useCallback(
    (duration) => {
      if (duration?.mode === DurationModes.Custom) {
        toggleCustom();
      } else {
        onDurationSelected(duration);
      }
    },
    [onDurationSelected, toggleCustom]
  );

  const onCustomSelectionSubmit = useCallback(
    (primaryRange: DateRange, _compareRange: DateRange, weekdayFilter: WeekdaysFilter) => {
      onDurationSelected({
        ...Durations.custom,
        ...primaryRange,
        weekdayFilter,
      });
      setCustomOpen(false);
    },
    [setCustomOpen, onDurationSelected]
  );
  const getText = useCallback(
    (from: moment.Moment, to: moment.Moment) => {
      const weekdayFilter = selectedDuration.weekdayFilter || defaultWeekdaysFilter;
      const weekdayLabels = Object.keys(weekdayFilter)
        .filter((weekday) => weekdayFilter[weekday])
        .map((weekday) => allWeekdayLabels[weekday]);

      const isWeekdaySpan =
        weekdayLabels.length > 1 && Object.values(allWeekdayLabels).join(',').includes(weekdayLabels.join(','));

      const showSuffixLabel =
        showWeekdaysFilter &&
        !moment(from)?.isSame(moment(to), 'day') &&
        weekdayLabels.length > 0 &&
        weekdayLabels.length < 7;

      const suffixLabel = isWeekdaySpan
        ? ` (${weekdayLabels[0]} - ${weekdayLabels[weekdayLabels.length - 1]})`
        : ` (${weekdayLabels.join(', ')})`;

      return getHumanReadableDateText(from, to).text + (showSuffixLabel ? suffixLabel : '');
    },
    [selectedDuration, showWeekdaysFilter]
  );

  const { primaryRange } = useRanges(selectedDuration);

  const closeOverlay = useCallback(() => {
    setCustomOpen(false);
  }, []);

  const wrapperRef = React.useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(() => setDropdownOpen(false), {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    });
    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }
    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef?.current);
      }
    };
  }, []);

  return (
    <div {...htmlProps} ref={wrapperRef} className={classNames(Styles.DateSelect, className)}>
      <Dropdown
        data-testid={`${testId}-dropdown`}
        disabled={disabled}
        className={Styles.Dropdown}
        isOpen={dropdownOpen}
        toggle={toggle}>
        <DropdownToggle
          color='primary'
          outline
          disabled={disabled}
          data-testid={`${testId}-dropdown-toggle`}
          className={Styles.DropdownButton}>
          {selectedDuration.mode === DurationModes.Custom ? (
            <div className={Styles.CustomDateRangeWrapper}>
              <MdDateRange size={16} className={Styles.MdDateRangeIcon} />
              <span className={Styles.DaterangeTextLabel}>{getText(selectedDuration.from, selectedDuration.to)}</span>
            </div>
          ) : (
            selectedDuration.label
          )}
        </DropdownToggle>
        <DropdownMenu end container={container}>
          {config.map((option) => {
            if (option.mode === DurationModes.Custom) {
              return (
                <CustomItem
                  key={`duration-opt-${option.key}`}
                  label={option.label}
                  active={selectedDuration.key === option.key}
                  onSelect={() => _onDurationSelected(option)}
                />
              );
            }
            return (
              <DropdownItem
                active={selectedDuration.key === option.key}
                key={`duration-opt-${option.key}`}
                onClick={() => _onDurationSelected(option)}
                style={{ minWidth: '150px' }}>
                {option.label}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
      <div className='mt-4' ref={popupWrapperRef}>
        {customOpen && (
          <DateRangeSelectorPopup
            initialPrimaryRange={primaryRange}
            initialWeekdaysFilter={selectedDuration?.weekdayFilter}
            hidePreset={true}
            futureEnabled={false}
            open={customOpen}
            onDurationSelected={onCustomSelectionSubmit}
            target={popupWrapperRef}
            showWeekdaysFilter={showWeekdaysFilter}
            onCancel={closeOverlay}
          />
        )}
      </div>
    </div>
  );
};
export default DateSelect;
