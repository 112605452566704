import ValueExpressionDataProvider from '@legacy-modules/valueexpressions/services/ValueExpressionDataProvider';
import React, { createContext, PropsWithChildren, ReactElement, useContext } from 'react';

export type ValueExpressionDataContextType = ValueExpressionDataProvider;

const defaultValue: ValueExpressionDataContextType = null;
export const ValueExpressionDataContext = createContext(defaultValue);

export const useValueExpressionDataContext = (): ValueExpressionDataContextType => {
  return useContext<ValueExpressionDataContextType>(ValueExpressionDataContext);
};

type ValueExpressionDataContextProviderProps = Required<
  PropsWithChildren<{
    valueExpressionDataProvider: ValueExpressionDataProvider;
  }>
>;

export default function ValueExpressionDataContextProvider({
  children,
  valueExpressionDataProvider,
}: ValueExpressionDataContextProviderProps): ReactElement {
  return (
    <ValueExpressionDataContext.Provider value={valueExpressionDataProvider}>
      {children}
    </ValueExpressionDataContext.Provider>
  );
}
