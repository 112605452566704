import Styles from './SignatureImageContainer.module.scss';
import SignatureImage, { SignatureRotation } from '../components/SignatureImage';
import React, { useCallback, useRef, useState } from 'react';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import { SIGNATURE_HOVER_WIDTH, SignatureHover } from './SignatureHover';
import SignatureFlaggingService from '../services/SignatureFlaggingService';
import { useIsMounted } from '../../dashboard/hooks/useIsMounted';
import { PopOver } from '../../common/components/PopOver';
import ScannerSignature from '../components/ScannerSignature';
import { ImageError } from '../components/ImageError';
import { useResizeListener } from '../../common/hooks/useResizeListener';
import { Placement } from '@popperjs/core';
import { getProofOfDeliveryType, ProofOfDelivery } from '../models/enumerations/ProofOfDelivery';
import { useSelector } from 'react-redux';
import { selectTokenId } from '@redux/auth.selectors';

type Props = {
  finishedDelivery: FinishedDelivery;
  url: string;
  size?: number;
  lazyloadRoot?: Element | null;
};

const signatureFlaggingService = new SignatureFlaggingService();
const HOVER_IMAGE_OFFSET = 50;

const SIGNATURE_IMAGE_RATIO = 0.75;

const SignatureImageContainer: React.FC<Props> = ({ finishedDelivery, url, size = 200, lazyloadRoot }) => {
  const tokenId = useSelector(selectTokenId);
  const [hovering, setHovering] = useState(false);
  const [rotateDeg, setRotateDeg] = useState<SignatureRotation>(
    getProofOfDeliveryType(finishedDelivery.proofOfDelivery) === 'imageSignature' ? 90 : 0
  );
  const [isImageReported, setIsImageReported] = useState<boolean>(false);
  const mounted = useIsMounted();

  const handleImageFlagged = useCallback(
    (imageId: string) => {
      const flagImage = async () => {
        if (!imageId) {
          // TODO: Use user notification once implemented to display error
          // eslint-disable-next-line no-console
          console.error('ImageId could not be parsed');
          return;
        }
        await signatureFlaggingService.flagImage(imageId, tokenId);
      };
      flagImage()
        .then(() => {
          if (!mounted.current) {
            return;
          }
          setIsImageReported(true);
        })
        .catch(() => {
          if (!mounted.current) {
            return;
          }
          setIsImageReported(false);
        });
    },
    [mounted, tokenId]
  );

  const handleImageRotate = useCallback(() => setRotateDeg((v) => ((v + 90) % 360) as SignatureRotation), []);
  const handleMouseEnter = useCallback(() => setHovering(true), []);
  const handleMouseLeave = useCallback(() => setHovering(false), []);

  const signatureRef = useRef<HTMLDivElement>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const [hoverPosition, setHoverPosition] = useState<Placement>('right');
  const recalculateHoverPosition = useCallback(() => {
    if (!signatureRef.current) {
      return;
    }
    // We calculate if the popup should appear on left or right hand sight of the signature
    // which is hovered. Usually the is calculated by the popper framework whereas the popover
    // should always be in the view scope of the user. However, as we rotate the image its size
    // changes which can cause the hover to bounce from left to right side. This happens because
    // the vertical image fits in the right side of the signature, however the horizontal image does
    // not making it appear on the left side. Here we assure that the image is always on the side where
    // it fits both alignments.
    const rect = signatureRef.current?.getBoundingClientRect();
    if (rect.x + rect.width + HOVER_IMAGE_OFFSET + SIGNATURE_HOVER_WIDTH > window.innerWidth) {
      setHoverPosition('left');
    } else {
      setHoverPosition('right');
    }
  }, []);

  useResizeListener(signatureRef, recalculateHoverPosition);

  const getRotationBySignatureType = useCallback((deliveryType: ProofOfDelivery): SignatureRotation => {
    switch (deliveryType) {
      case 'imageSignature':
        return 90;
      case 'image':
      case 'signature':
      default:
        return 0;
    }
  }, []);

  if (isImageReported) {
    return (
      <div
        className={Styles.ErrorWrapper}
        style={{
          width: size,
        }}>
        <div className={Styles.Error}>Foto wurde wegen Datenschutzverstoß gemeldet</div>
      </div>
    );
  }

  if (finishedDelivery.isRenderingImage()) {
    return (
      <>
        <div ref={signatureRef}>
          {getProofOfDeliveryType(finishedDelivery.proofOfDelivery) === 'imageSignature' ? (
            <SignatureImage
              lazyLoadRoot={lazyloadRoot}
              imageWidth={size}
              key={finishedDelivery.uuid}
              imageType={getProofOfDeliveryType(finishedDelivery.proofOfDelivery)}
              handleImageRotate={handleImageRotate}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              handleImageFlagged={handleImageFlagged}
              url={url}
              imageRatio={
                getProofOfDeliveryType(finishedDelivery.proofOfDelivery) === 'imageSignature' ? 1.333333 : 3.44
              }
              rotation={getRotationBySignatureType(getProofOfDeliveryType(finishedDelivery.proofOfDelivery))}
              flaggingEnabled
              rotationEnabled
              onImageLoaded={setImageSrc}
            />
          ) : (
            <ScannerSignature
              url={url}
              width={size}
              height={size * SIGNATURE_IMAGE_RATIO}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              onImageLoaded={setImageSrc}
            />
          )}
        </div>
        <PopOver
          anchorElement={signatureRef?.current}
          visible={hovering}
          offset={[HOVER_IMAGE_OFFSET, HOVER_IMAGE_OFFSET]}
          placement={hoverPosition}>
          <SignatureHover finishedDelivery={finishedDelivery} rotateDeg={rotateDeg} url={imageSrc} />
        </PopOver>
      </>
    );
  }

  return (
    <div
      style={{
        width: size,
        height: size * SIGNATURE_IMAGE_RATIO,
      }}>
      <ImageError
        error={finishedDelivery.getImageErrorReasonByType(getProofOfDeliveryType(finishedDelivery.proofOfDelivery))}
        retryable={false}
      />
    </div>
  );
};

export { SignatureImageContainer };
