import React, { HTMLAttributes, ReactElement } from 'react';
import style from './loading-indicator.module.scss';
import classNames from 'classnames';
import Cloud from '../../../assets/lottie/cloud/cloud.json';
import Lottie, { LottieProps } from 'react-lottie-player';

const defaultLottieProps: LottieProps = {
  className: style.cloud,
  loop: true,
  play: true,
  animationData: Cloud,
  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
};
export type LoadingIndicatorProps = HTMLAttributes<HTMLDivElement> & {
  label?: string;
  lottieProps?: LottieProps;
};

export function LoadingIndicator({
  label = 'Lade Daten aus der Cloud...',
  lottieProps = {},
  ...divProps
}: LoadingIndicatorProps): ReactElement {
  return (
    <div className={classNames(style.loadingIndicator)} {...divProps}>
      <span>{label}</span>
      <Lottie {...defaultLottieProps} {...lottieProps} />
    </div>
  );
}
export default LoadingIndicator;
