import WebsocketApiService from '../../common/services/WebsocketApiService';
import moment from 'moment';
import { v1 as uuid } from 'uuid';
import { EventDataProvider } from '../interfaces/EventDataProvider';
import Logger from '../../utils/logging/Logger';
import TrackRequest from '../models/TrackRequest';
import WebsocketEvent from '../../common/models/websocket/WebsocketEvent';
import uaParser from 'ua-parser-js';
import { AppRoutingParams } from '../../routing/models/AppRoutingParams';

type ViewEvent = {
  viewData: AppRoutingParams;
};
type LoginEvent = {
  loginInfo: {
    success: boolean;
    error: string | null;
  };
};
type TrackingEvent = ViewEvent | LoginEvent | null;

export default class TrackingService {
  websocketApiService: WebsocketApiService;
  _logger: Logger = Logger.getInstance('TrackingService');

  eventDataProviders: {
    [key: string]: EventDataProvider;
  } = {
    timestamp: async () => moment().toISOString(),
    source: async () => 'lma-ui',
    uuid: async () => uuid(),
    browserInfo: async () => {
      const info = uaParser(window.navigator.userAgent);
      return {
        ...info,
        language: window.navigator.language || window.navigator['userLanguage'],
      };
    },
    resolution: async () => ({
      width: window.screen.width,
      height: window.screen.height,
    }),
  };

  constructor(websocketApiService: WebsocketApiService) {
    this.websocketApiService = websocketApiService;
  }

  async _buildEvent(
    type: string,
    data: Object | null | undefined = null,
    additionalDataProvider?: {
      [key: string]: EventDataProvider;
    }
  ) {
    let event = { type: type };
    // get additional data from data providers
    let providers: {
      [key: string]: EventDataProvider;
    } = this.eventDataProviders;
    if (additionalDataProvider) {
      providers = { ...providers, ...additionalDataProvider };
    }
    const promises: Array<Promise<void>> = Object.entries(providers).map(async (kv) => {
      const key: string = kv[0];
      const eventDataProvider: EventDataProvider = kv[1];
      let eventData;
      try {
        eventData = await eventDataProvider();
      } catch (e) {
        eventData = {
          error: true,
          message: 'getting data from eventDataProvider ' + key + ' failed',
          exception: e.toString(),
        };
      }
      if (eventData) {
        event[key] = eventData;
      }
    });
    await Promise.all(promises);
    if (data) {
      event = { ...event, ...data };
    }
    return event;
  }

  registerEventDataProvider(key: string, eventDataProvider: EventDataProvider) {
    this.eventDataProviders[key] = eventDataProvider;
  }

  unregisterEventDataProvider(key: string) {
    delete this.eventDataProviders[key];
  }

  async sendEvent(
    type: string,
    data: TrackingEvent = null,
    additionalDataProvider?: {
      [key: string]: EventDataProvider;
    }
  ) {
    try {
      const event = await this._buildEvent(type, data, additionalDataProvider);
      return this.websocketApiService.emit(
        new WebsocketEvent({
          name: 'tracking.event',
          payload: new TrackRequest({ event }),
        })
      );
    } catch (e) {
      this._logger.error('Sending analytics event failed', type, e);
    }
  }
}
