import React, { ComponentType, MouseEvent, PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import { Column } from '@tanstack/react-table';
import { useLayer, UseLayerProps } from 'react-laag';
import { useDataTableContext } from '@data-table/contexts/data-table-context';
import { ColumnFilterContentProps, DataTableColumnMeta } from '@data-table/data-table.types';
import {
  BeanstandungGrundFilterContent,
  OrgNameFilterContent,
  HinweisartFilterContent,
  TextFilterContent,
  TourNumberFilterContent,
  ValueExpressionFilterContent,
} from './filter-content';

type DataTableColumnFilterProps<T, V> = {
  column: Column<T, V>;
  filterIsOpen: boolean;
  toggleFilter: () => void;
};
export type DataTableColumnFilterComponentProps = Required<
  PropsWithChildren<{
    showFilter: boolean;
    filtered: boolean;
    filterProps: UseLayerProps;
    toggleFilter: (e: MouseEvent<HTMLDivElement>) => void;
  }>
>;

export function withDataTableColumnFilter(Component: ComponentType<DataTableColumnFilterComponentProps>) {
  return function <T, V>({ column, filterIsOpen, toggleFilter }: DataTableColumnFilterProps<T, V>): ReactElement {
    const { table, tableWrapperRef } = useDataTableContext();
    const isFullscreen = tableWrapperRef.current ? document.fullscreenElement === tableWrapperRef.current : false;
    const filtered = column.getIsFiltered();
    const filterValue = column.getFilterValue();
    const defaultFilterValue = (column.columnDef.meta as DataTableColumnMeta)?.defaultFilterValue;
    const [initialValue, setInitialValue] = useState(filterValue);

    const onFilterDismiss = useCallback(() => {
      column.setFilterValue(initialValue);
      toggleFilter();
    }, [initialValue, toggleFilter, column]);
    const onCancel = useCallback(() => {
      setInitialValue(defaultFilterValue);
      column.setFilterValue(defaultFilterValue);
      toggleFilter();
    }, [column, toggleFilter, defaultFilterValue]);
    const onConfirm = useCallback(
      (value: unknown) => {
        setInitialValue(value);
        toggleFilter();
      },
      [toggleFilter]
    );

    const filterProps = useLayer({
      isOpen: filterIsOpen,
      onOutsideClick: onFilterDismiss, // close the menu when the user clicks outside
      onDisappear: isFullscreen ? () => null : onFilterDismiss, // close the menu when gets scrolled out of sight
      overflowContainer: true, // keep the menu positioned inside the container
      auto: true, // automatically find the best placement
      placement: 'right-start', // we prefer to place the menu "top-end"
      triggerOffset: 20, // keep some distance to the trigger
      preferX: 'right', // we prefer to align the menu to the left
      preferY: 'bottom', // we prefer to align the menu to the bottom
      container: tableWrapperRef.current ?? 'layers',
    });

    const preventEventPropagationOnClick = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
    };

    const FilterContent = getFilterContentByColumnId<T, V>(column.id);

    return (
      <Component showFilter={filterIsOpen} toggleFilter={toggleFilter} filterProps={filterProps} filtered={filtered}>
        <div data-testid='data-table-filter-content' onClick={preventEventPropagationOnClick}>
          <FilterContent table={table} column={column} onCancel={onCancel} onConfirm={onConfirm} />
        </div>
      </Component>
    );
  };
}

const getFilterContentByColumnId = <T, V>(
  columnId: string
): ((props: ColumnFilterContentProps<T, V>) => ReactElement) => {
  switch (columnId) {
    case 'tourIdentifier':
      return TourNumberFilterContent;
    case 'orgName':
    case 'standort':
    case 'auftraggeber':
      return OrgNameFilterContent;
    case 'hinweisart':
      return HinweisartFilterContent;
    case 'details':
    case 'sendungsId':
      return TextFilterContent;
    case 'beanstandungGrund':
      return BeanstandungGrundFilterContent;
    default:
      return ValueExpressionFilterContent;
  }
};
