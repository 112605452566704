import React, { ReactElement } from 'react';
import { useDataTableContext } from '@data-table/contexts/data-table-context';
import { TablePagination } from '@legacy-modules/dashboard/components/table/TablePagination';

const DataTablePagination = (): ReactElement => {
  const { table } = useDataTableContext();
  const pagination = table.getState().pagination;
  const totalResultCount = table.getPrePaginationRowModel().rows.length;

  return (
    <TablePagination
      canNextPage={table.getCanNextPage()}
      canPreviousPage={table.getCanPreviousPage()}
      gotoPage={(pageNr) => table.setPageIndex(pageNr)}
      nextPage={() => table.nextPage()}
      pageCount={table.getPageCount()}
      previousPage={() => table.previousPage()}
      pageIndex={pagination.pageIndex}
      totalResultCount={totalResultCount}
      pageSize={pagination.pageSize}
      setPageSize={(pageSize) => table.setPageSize(pageSize)}
    />
  );
};

export default DataTablePagination;
