import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import hinweisHtWzfZustellung from './hinweis-ht-wzf-zustellung';
import hinweisHtWzfAbholung from './hinweis-ht-wzf-abholung';

const expression = new SumValueExpression(
  hinweisHtWzfZustellung,
  hinweisHtWzfAbholung,
  'Außerhalb WZF',
  null,
  '-#.###.##'
);

expression.shortLabel = 'Außerhalb WZF';
expression.setIdentifier('anomalies/wzf-ht');

export default expression;
