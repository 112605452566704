import React from 'react';
import Styles from './LeftRight.module.scss';
import { MdChevronLeft, MdChevronRight, MdFullscreen } from 'react-icons/md';
import classNames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { appSlice, selectSidebarSize } from '@redux';

type Props = {
  left: React.ReactChild;
  right: React.ReactChild;
  onSectionToggled: () => void;
};

const LeftRight = (props: Props) => {
  const { onSectionToggled, left = null, right = null } = props;
  const dispatch = useDispatch();
  const sidebarSize = useSelector(selectSidebarSize);

  const toggleFullScreen = () => {
    if (sidebarSize === 'extended') {
      dispatch(appSlice.actions.setSidebarSize('default'));
    } else {
      dispatch(appSlice.actions.setSidebarSize('extended'));
    }
    onSectionToggled();
  };

  const toggleLeftSideVisibility = () => {
    if (sidebarSize === 'hidden') {
      dispatch(appSlice.actions.setSidebarSize('default'));
    } else {
      dispatch(appSlice.actions.setSidebarSize('hidden'));
    }
    onSectionToggled();
  };

  const getFullScreenIcon = (text) => (
    <React.Fragment>
      <MdFullscreen size={24} />
      <UncontrolledTooltip placement='right' target='fullscreen-chevron'>
        {text}
      </UncontrolledTooltip>
    </React.Fragment>
  );

  return (
    <div
      className={classNames(Styles.LeftRight, {
        [Styles.Expanded]: sidebarSize === 'extended',
        [Styles.Hidden]: sidebarSize === 'hidden',
      })}>
      <div className={Styles.Left}>{left}</div>
      <div
        className={classNames(Styles.Chevron, {
          [Styles.LeftHidden]: sidebarSize === 'hidden',
        })}>
        <div className={Styles.FullScreen} onClick={toggleFullScreen} id='fullscreen-chevron'>
          {sidebarSize !== 'extended' && getFullScreenIcon('Linke Ansicht vergrößern')}
          {sidebarSize === 'extended' && getFullScreenIcon('Linke Ansicht verkleinern')}
        </div>
        <div className={Styles.VisibilityChevron} onClick={toggleLeftSideVisibility}>
          {sidebarSize !== 'hidden' ? <MdChevronLeft size={24} /> : <MdChevronRight size={24} />}
        </div>
      </div>
      <div className={Styles.Right}>{right}</div>
    </div>
  );
};

export { LeftRight };
