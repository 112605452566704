import React, { createContext, PropsWithChildren, ReactElement, useContext, useMemo } from 'react';
import { Table } from '@tanstack/react-table';

// TODO: get rid of any
export type DataTableContextType<T = any> = {
  table: Table<T>;
  comparisonTable: Table<T>;
  columnOrder: string[];
  tableWrapperRef: React.RefObject<HTMLDivElement>;
};

const defaultValue: DataTableContextType = null;
export const DataTableContext = createContext(defaultValue);

export const useDataTableContext = (): DataTableContextType => {
  return useContext<DataTableContextType>(DataTableContext);
};

// TODO: get rid of any
type DataTableContextProviderProps<T = any> = Required<
  PropsWithChildren<{
    table: Table<T>;
    comparisonTable: Table<T>;
    columnOrder: string[];
    tableWrapperRef: React.RefObject<HTMLDivElement>;
  }>
>;

export default function DataTableContextProvider({
  children,
  table,
  comparisonTable,
  columnOrder,
  tableWrapperRef,
}: DataTableContextProviderProps): ReactElement {
  const context: DataTableContextType = useMemo(
    () => ({
      table,
      comparisonTable,
      columnOrder,
      tableWrapperRef,
    }),
    [table, comparisonTable, columnOrder, tableWrapperRef]
  );

  return <DataTableContext.Provider value={context}>{children}</DataTableContext.Provider>;
}
