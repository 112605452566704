import ValueExpression from './ValueExpression';
import { ValueType } from '../../../metrics2/models/enumerations/ValueType';
import MetricType, { mapKeyLabels, mapKeys } from '../../../metrics2/models/entities/MetricType';
import MetricsEntityKey from '../../../metrics2/models/entities/MetricsEntityKey';
import ValueExpressionEntityKey from '../entities/ValueExpressionEntityKey';
import { ChartType } from '../../../metrics2/models/enumerations/ChartType';
import MapDetailValueExpression from './MapDetailValueExpression';
import { PositiveDirections } from '../../../metrics2/models/entities/PositiveDirection';
import { AggregationType } from '../../../metrics2/models/enumerations/AggregationType';
import MetricDataUtils from '../../../metrics2/converters/MetricDataUtils';
import { ValueExpressionRoutingParams } from '../routing/ValueExpressionRoutingParams';

export default class SingleValueExpression extends ValueExpression {
  static TAG = 'SingleValueExpression';
  tag = SingleValueExpression.TAG;
  metricType: MetricType;
  label?: string;

  constructor(metricType: MetricType, label: string | null | undefined = null) {
    super();
    this.metricType = metricType;
    this.definition = metricType?.definition;
    this.label = label;
    this.identifier = metricType?.key;
    this.apiVersion = metricType?.apiVersion || 'ws';
  }

  clone(): SingleValueExpression {
    return new SingleValueExpression(this.metricType);
  }

  get key(): string {
    return `sve:${this.metricType?.key}`;
  }

  get valueType(): ValueType {
    if (this.metricType.valueType === ValueType.map) {
      return ValueType.map;
    }
    return ValueType.single;
  }

  get mapKeys(): Array<string> | null | undefined {
    if (this.valueType === ValueType.single) {
      return null;
    } else {
      const metricType = new MetricType();
      metricType.hydrateFrom(this.metricType);
      return metricType.mapKeys;
    }
  }

  getRequiredMetricTypes(id: string = null): Array<{ type: MetricType; valueKey?: string; id: string }> {
    return [{ type: this.metricType, id: id ? id : 'default' }];
  }

  get category(): string | null | undefined {
    return this.metricType.category;
  }

  get chartType(): ChartType {
    return ChartType.line;
  }

  get positiveDirection(): PositiveDirections {
    return this.metricType.positiveDirection;
  }

  get aggregation(): AggregationType {
    return this.metricType.aggregation;
  }

  getLabel(mapKey: string | null = null): string {
    if (this.label) {
      return this.label;
    }
    if (mapKey) {
      const mapKeyLabels_ = mapKeyLabels[this.metricType.key];
      if (mapKeyLabels_ && mapKeyLabels_[mapKey]) {
        return mapKeyLabels_[mapKey];
      }
      return mapKey;
    }
    return this.metricType.label;
  }

  getDescription(mapKey = null, language = null): string | null | undefined {
    if (mapKey) {
      const mapKeyLabels = this.metricType.mapKeyLabels;
      if (mapKeyLabels && mapKeyLabels[mapKey]) {
        return `Detail-Wert ${mapKeyLabels[mapKey]} von ${this.getLabel(null)}.`;
      }
    }
    return this.metricType.description;
  }

  getSumLabel(): string {
    return this.metricType.sumLabel || this.metricType.label;
  }

  getValueFormat(): string {
    return this.metricType.valueFormat;
  }

  processValues(metrics: Map<MetricsEntityKey, number>): Map<ValueExpressionEntityKey, number> {
    const key = this.key;
    const aggregationFn = MetricDataUtils.aggregationFunction(this.aggregation);
    const veEntityKeys: {
      [key: string]: ValueExpressionEntityKey;
    } = {};
    const mek2veek: Map<MetricsEntityKey, string> = new Map();
    Array.from(metrics.keys())
      .filter((mek: MetricsEntityKey) => mek.type === this.metricType.key)
      .map((mek: MetricsEntityKey) => {
        return {
          mek,
          veek: new ValueExpressionEntityKey(key, mek.orgKey, mek.dateFrom, mek.dateUntil, mek.grouping, mek.valueKey),
        };
      })
      .forEach(({ mek, veek }: { mek: MetricsEntityKey; veek: ValueExpressionEntityKey }) => {
        mek2veek.set(mek, veek.identifier);
        veEntityKeys[veek.identifier] = veek;
      });
    const resultMap = new Map();
    metrics.forEach((value, mek) => {
      const veek: ValueExpressionEntityKey = veEntityKeys[mek2veek.get(mek)];
      const oldValue = resultMap.get(veek) || 0.0;
      resultMap.set(veek, aggregationFn(value, oldValue));
    });
    return resultMap;
  }

  getRoutingParams(): ValueExpressionRoutingParams {
    return {
      valueExpressionClass: SingleValueExpression.TAG,
      metricType: this.metricType.key,
    };
  }

  getChildren(): Array<ValueExpression> {
    const _mapKeys = [
      ...(mapKeys[this.key] || []),
      ...(mapKeys[this.metricType?.key] || []),
      ...(mapKeys[this.identifier] || []),
    ];

    return _mapKeys.map((k) => new MapDetailValueExpression(this.metricType, k));
  }
}
