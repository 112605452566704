import { ContractorViewMode } from '../../navigation/constants/ContractorViewMode';
import { ContractorUtils } from '../../utils/tours/ContractorUtils';

export const injectContractorViewMode = (orgKey: string, mode?: ContractorViewMode): string => {
  if (!ContractorUtils.isContractor(orgKey) || !mode) {
    return orgKey;
  }
  if (!validateOrgKeyWithViewMode(orgKey)) {
    throw new Error('Invalid orgKey: ' + orgKey);
  }
  const orgKeyNumber = /:(\d+)($|_)/.exec(orgKey)[1];
  const { prefix, suffix } = getValueForKey(mode);

  return `${prefix}:${orgKeyNumber}${suffix}`;
};

const ovRegexWithModifier = /^ov_h:\d+_[01]$/;
const ovRegexWithoutModifier = /^ov:\d+$/;

export const validateOrgKeyWithViewMode = (orgKey: string): boolean => {
  if (!ContractorUtils.isContractor(orgKey)) {
    return true;
  }
  return ovRegexWithModifier.test(orgKey) || ovRegexWithoutModifier.test(orgKey);
};

const getValueForKey = (mode: ContractorViewMode) => {
  switch (mode) {
    case ContractorViewMode.All:
      return {
        prefix: 'ov',
        suffix: '',
      };
    case ContractorViewMode.Home:
      return {
        prefix: 'ov_h',
        suffix: '_1',
      };
    case ContractorViewMode.TaskForce:
      return {
        prefix: 'ov_h',
        suffix: '_0',
      };
    default:
      throw new Error('Unknown contractor view mode: ' + mode);
  }
};
