export class TourUtils {
  static TOUR_PREFIX = 'oz_t';

  /**
   * Returns org key for given orgId and tourNumber
   * Example: (92647, 498) -> oz_t:92647_496
   * @param orgId
   * @param tourNumber
   * @returns {string}
   */
  static getTourOrgKey(orgId: string, tourNumber: string) {
    return `${TourUtils.TOUR_PREFIX}:${orgId}_${tourNumber}`;
  }

  /**
   * Returns tour number for tour org key
   * Example: oz_t:92647_496 -> 496
   * @param orgKey
   * @returns {string|null}
   */
  static getTourNumberByOrgKey(orgKey: string) {
    if (!orgKey?.length) {
      return null;
    }
    return orgKey.substr(orgKey.lastIndexOf('_') + 1);
  }

  static isTourKey(orgKey: string): boolean {
    if (!orgKey) {
      return false;
    }
    return orgKey.startsWith(TourUtils.TOUR_PREFIX);
  }
}
