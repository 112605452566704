import { useCallback, useMemo } from 'react';
import { useQueries } from 'react-query';
import { useApiContext } from '@contexts/api-context';
import { KnownWebsocketEvent } from '@contexts/api-context/request.types';
import { LoadingState, OrgKey, ValueExpressionIdentifier } from '@data-table/data-table.types';
import { Duration, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';
import { injectContractorViewMode } from '@legacy-modules/dashboard/utils/OrgKeyUtils';
import MetricDataUtils from '@legacy-modules/metrics2/converters/MetricDataUtils';
import MetricsEntityKey from '@legacy-modules/metrics2/models/entities/MetricsEntityKey';
import { DateRangeGrouping } from '@legacy-modules/metrics2/models/enumerations/DateRangeGrouping';
import { ContractorViewMode } from '@legacy-modules/navigation/constants/ContractorViewMode';
import { ContractorUtils } from '@legacy-modules/utils/tours/ContractorUtils';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { DurationUtils } from '@utils/duration-utils';
import MetricsOverviewQueryResponsePayload from '@legacy-modules/metrics2/models/websocket/metrics/MetricsOverviewQueryResponsePayload';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import { WeekdayInput, DateRangeGrouping as DateRangeGroupingV2 } from '@graphql-client/graphql';
import { KpiFilter, KpiMapper } from '@hooks/use-kpi-query-hook/use-kpi-query.hook';

type UseDataTableValueExpressionDataOutput = [
  Map<ValueExpressionIdentifier, Map<OrgKey, number>>,
  (valueExpressionIdentifier: ValueExpressionIdentifier, filter: OrgKey[]) => number,
  (valueExpressionIdentifier: ValueExpressionIdentifier) => number,
  LoadingState
];
const useDataTableValueExpressionData = (
  currentOrgKey: OrgKey,
  orgKeys: OrgKey[],
  valueExpressions: ValueExpression[],
  duration: Duration,
  isComparison: boolean = false,
  enabled: boolean = true
): UseDataTableValueExpressionDataOutput => {
  const [weekdayFilter, weekdayFilterCashId] = useMemo(() => {
    const filterObject = duration.weekdayFilter || defaultWeekdaysFilter;
    return [
      filterObject,
      Object.keys(filterObject)
        .filter((key) => filterObject[key])
        .join(','),
    ];
  }, [duration.weekdayFilter]);
  const apiCtx = useApiContext();

  const primaryRange = isComparison
    ? DurationUtils.getComparisonDateRange(duration)
    : DurationUtils.getDateRange(duration);

  const { from, to } = {
    from: primaryRange?.from?.format('YYYY-MM-DD'),
    to: primaryRange?.to?.format('YYYY-MM-DD'),
  };

  const dateQueryKey = primaryRange.from.isSame(primaryRange.to, 'day') ? from : `${from}-${to}`;

  const wsValueExpressions = valueExpressions.filter((valueExpression) => valueExpression.apiVersion === 'ws');
  const gqlValueExpressions = valueExpressions.filter((valueExpression) => valueExpression.apiVersion === 'graphql');
  const gqlRequiredMetricTypeKeys = gqlValueExpressions.flatMap((valueExpression) =>
    valueExpression.getRequiredMetricTypes().map((metricType) => ({ id: metricType.type.key }))
  );

  const replaceMetricTypeKeyWithValueExpressionIdentifier = useCallback(
    (kpiValue: { kpiId: string; value: number }) => ({
      ...kpiValue,
      kpiId: gqlValueExpressions.find((ve) =>
        ve.getRequiredMetricTypes().some((type) => type.type.key === kpiValue.kpiId)
      ).identifier,
    }),
    [gqlValueExpressions]
  );

  const graphqlResults = useKpiQuery(
    {
      orgKeys: [currentOrgKey, ...orgKeys],
      dateFilter: {
        range: { from, until: to },
        weekdays: Object.entries(weekdayFilter || {}).map(([day, active]) => ({
          weekday: day.toUpperCase() as WeekdayInput,
          active,
        })),
      },
      kpiIds: gqlRequiredMetricTypeKeys,
      dateRangeGrouping: DateRangeGroupingV2.Single,
    },
    {
      enabled: orgKeys?.length > 0 && gqlValueExpressions?.length > 0 && !!from && !!to && enabled,
      select: (data) => {
        const filteredGroups = data.kpis.groups.map((group) => ({
          ...group,
          kpiValues: group.kpiValues
            .filter(KpiFilter)
            .map(KpiMapper)
            .map(replaceMetricTypeKeyWithValueExpressionIdentifier),
        }));
        const filteredSummaryKpiValues = data.kpis.summary.kpiValues
          .filter(KpiFilter)
          .map(KpiMapper)
          .map(replaceMetricTypeKeyWithValueExpressionIdentifier);
        return { kpis: { ...data.kpis, groups: filteredGroups, summary: { kpiValues: filteredSummaryKpiValues } } };
      },
    }
  );

  const results = useQueries(
    wsValueExpressions.flatMap((valueExpression) =>
      valueExpression.getRequiredMetricTypes().map(({ type, valueKey }) => {
        return {
          queryKey: [
            KnownWebsocketEvent.METRICS_OVERVIEW_LOAD_EVENT,
            type?.key,
            valueKey,
            currentOrgKey,
            dateQueryKey,
            { orgKeys },
            weekdayFilterCashId,
          ],
          enabled: !!currentOrgKey && orgKeys?.length > 0 && !!from && !!to && enabled,
          queryFn: (): Promise<[ValueExpression, Map<MetricsEntityKey, number>]> =>
            apiCtx.wsFetch<[ValueExpression, Map<MetricsEntityKey, number>], MetricsOverviewQueryResponsePayload>(
              KnownWebsocketEvent.METRICS_OVERVIEW_LOAD_EVENT,
              {
                types: [type?.key],
                orgKeys,
                dateFilter: {
                  range: {
                    from,
                    until: to,
                  },
                  weekdays: weekdayFilter,
                },
                valueKey,
                aggregations: [type?.aggregation],
                contractorKey: ContractorUtils.isContractor(currentOrgKey)
                  ? injectContractorViewMode(currentOrgKey, ContractorViewMode.All)
                  : null,
              }
            ),
          select: (data) => {
            return [
              valueExpression,
              new Map<MetricsEntityKey, number>(
                data?.values?.map((value) => [
                  new MetricsEntityKey(
                    value.type,
                    value.group,
                    primaryRange.from,
                    primaryRange.to,
                    DateRangeGrouping.none,
                    valueKey,
                    duration.weekdayFilter
                  ),
                  value.value,
                ]) || []
              ),
            ] as [ValueExpression, Map<MetricsEntityKey, number>];
          },
        };
      })
    )
  );

  // When the table is NOT filtered, we have to load the summary data for root orgKey
  // to take account for not started tours. If table is filtered, we can safely use
  // exact orgKeys of already started tours. This is an important condition for some
  // value expressions like Lademenge (incl. nicht gestartet) and all derived
  // value expressions of it like Rücklaufquote or Fortschritt. As side-effect of that
  // there can be some situations in wich summary value of an unfiltered table column
  // doesn't match the sum of single row values.
  const rootOrgResults = useQueries(
    valueExpressions.flatMap((valueExpression) =>
      valueExpression.getRequiredMetricTypes().map(({ type, valueKey }) => ({
        queryKey: [
          KnownWebsocketEvent.METRICS_OVERVIEW_LOAD_EVENT,
          type?.key,
          valueKey,
          currentOrgKey,
          dateQueryKey,
          { orgKeys: [currentOrgKey] },
          weekdayFilterCashId,
        ],
        enabled: !!currentOrgKey && !!from && !!to && enabled,
        queryFn: () =>
          apiCtx.wsFetch<unknown>(KnownWebsocketEvent.METRICS_OVERVIEW_LOAD_EVENT, {
            types: [type?.key],
            orgKeys: [currentOrgKey],
            dateFilter: {
              range: {
                from,
                until: to,
              },
              weekdays: weekdayFilter,
            },
            valueKey: valueKey,
            aggregations: [type?.aggregation],
            contractorKey: null,
          }),
        select: (data) => {
          return [
            valueExpression,
            [
              new MetricsEntityKey(
                data.values?.[0]?.type,
                data.values?.[0]?.group,
                primaryRange.from,
                primaryRange.to,
                DateRangeGrouping.none,
                valueKey,
                duration.weekdayFilter
              ),
              data.values?.[0]?.value,
            ],
          ] as [ValueExpression, [MetricsEntityKey, number]];
        },
      }))
    )
  );

  const orgKeyMap = useMemo(() => {
    const metricsMap: Map<ValueExpression, Map<MetricsEntityKey, number>> = results
      // filter out errors
      .filter((result) => !result.isError && result.data)
      // combine value maps for each value expression
      .reduce((acc, result) => {
        const [valueExpression, valueMap] = result.data;
        if (acc.has(valueExpression)) {
          const existing = acc.get(valueExpression);
          return acc.set(valueExpression, new Map([...existing, ...valueMap]));
        } else {
          return acc.set(valueExpression, valueMap);
        }
      }, new Map());

    const wsResultsMap = new Map<ValueExpressionIdentifier, Map<OrgKey, number>>(
      Array.from(metricsMap.entries()).map((metricEntry) => {
        const [valueExpression, valueMap] = metricEntry;
        // process values for each value expression
        const metricEntityValuesMap: Map<MetricsEntityKey, number> = valueExpression.processValues(valueMap, false);
        // transform metrics entity keys to org keys
        const orgKeyValuesMap = new Map(
          Array.from(metricEntityValuesMap.entries()).map(([key, value]) => [key.orgKey, value]) || []
        );
        // return entry for each value expression
        return [valueExpression.identifier, orgKeyValuesMap];
      })
    );
    graphqlResults.data?.kpis?.groups?.forEach((result) => {
      result.kpiValues.forEach((kpiValue) => {
        if (wsResultsMap.has(kpiValue.kpiId)) {
          const outerMap = wsResultsMap.get(kpiValue.kpiId);
          outerMap.set(result.orgKey, kpiValue.value);
        } else {
          wsResultsMap.set(kpiValue.kpiId, new Map([[result.orgKey, kpiValue.value]]));
        }
      });
    });
    return wsResultsMap;
  }, [results, graphqlResults.data?.kpis?.groups]);

  const getFilteredSumm = useCallback(
    (valueExpressionIdentifier: ValueExpressionIdentifier, filter: OrgKey[]) => {
      if (!filter || filter.length === 0) {
        return 0;
      } else {
        const metricsMap: Map<ValueExpressionIdentifier, number> = new Map();
        results
          .filter((result) => !result.isError && result.data?.[0]?.identifier === valueExpressionIdentifier)
          // combine value maps for each value expression
          .reduce((acc, result) => {
            const [valueExpression, _valueMap] = result.data;
            const valueMap = new Map(
              Array.from(_valueMap.entries()).filter(([key]) => {
                return filter.includes(key.orgKey);
              }) || []
            );
            if (acc.has(valueExpression)) {
              const existing = acc.get(valueExpression);
              return acc.set(valueExpression, new Map([...existing, ...valueMap]));
            } else {
              return acc.set(valueExpression, valueMap);
            }
          }, new Map())
          .forEach((valueMap, valueExpression) => {
            metricsMap.set(
              valueExpression.identifier,
              MetricDataUtils.metricData2Single(
                valueExpression.processValues(valueMap, true),
                valueExpression.aggregation
              ) as number
            );
          });
        return metricsMap.get(valueExpressionIdentifier) || 0;
      }
    },
    [results]
  );

  const getRootOrgSumm = useCallback(
    (valueExpressionIdentifier: ValueExpressionIdentifier): number => {
      const valueExpression = valueExpressions.find(
        (valueExpression) => valueExpression.identifier === valueExpressionIdentifier
      );
      if (!valueExpression) {
        return 0;
      }
      if (valueExpression.apiVersion === 'graphql') {
        const value = graphqlResults.data?.kpis?.groups
          ?.find((result) => result.orgKey === currentOrgKey)
          ?.kpiValues?.find((kpiValue) => {
            return kpiValue.kpiId === valueExpressionIdentifier;
          })?.value;
        return value || 0;
      }
      const results = rootOrgResults
        .filter((result) => !result.isError && result.data?.[0]?.identifier === valueExpressionIdentifier)
        .map((result) => result.data);
      const metricKeyMap = new Map(results?.map((result) => result?.[1]).filter((result) => !!result?.[1]));
      return valueExpression?.processValues(new Map(metricKeyMap), false)?.values()?.next()?.value;
    },
    [rootOrgResults, graphqlResults.data?.kpis?.groups, currentOrgKey, valueExpressions]
  );
  return [
    orgKeyMap,
    getFilteredSumm,
    getRootOrgSumm,
    results.some((result) => result.isLoading) || rootOrgResults.some((result) => result.isLoading),
  ];
};

export default useDataTableValueExpressionData;
