import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Input } from 'reactstrap';
import { FaqData, faqData } from '../../utils/faq/faq';
import QuestionAnswerCollapse from './questionAnswerCollapse';
import { filter } from 'lodash';
import Styles from './FaqModal.module.scss';
import classNames from 'classnames';

type Props = {
  onClose: () => void;
};

const cleanKey = (key: string) => key.replace(/\s/g, '');

const FaqModal = (props: Props) => {
  const { onClose } = props;
  const refs = useRef<{ [k: string]: HTMLDivElement }>({});

  const [searchValue, setSearchValue] = useState('');

  const filteredQuestions: FaqData = useMemo(() => {
    const search = searchValue.toLowerCase();
    if (search !== '') {
      return faqData.map((cat) => {
        const filteredQs = filter(cat[Object.keys(cat)[0]], (q) => q.question.toLowerCase().includes(search));
        const newObj = {};
        newObj[Object.keys(cat)[0]] = filteredQs;
        return newObj;
      });
    } else {
      return faqData;
    }
  }, [searchValue]);

  const handleClickTopic = useCallback((category: Object): void => {
    const str = Object.keys(category)[0];
    const ref = cleanKey(str);
    refs.current[ref].scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Modal
        centered={true}
        isOpen={true}
        autoFocus={true}
        style={{ zIndex: 9999 }}
        toggle={onClose}
        className={Styles.Modal}>
        <ModalHeader className={Styles.Header} toggle={onClose}>
          Häufig gestellte Fragen
          <Input
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            type='text'
            className={Styles.Input}
            placeholder='Nach Thema suchen'
          />
        </ModalHeader>
        <ModalBody className={Styles.Body}>
          <div className='container'>
            <div className='row'>
              <div className='col-3'>
                <div className={classNames(Styles.Topics, 'mt-5', 'ml-4')}>
                  <p className={classNames(Styles.Title, 'mb-4')}>Themengebiete</p>
                  {faqData.map((category, i) => (
                    <div
                      key={Object.keys(category)[0] + i}
                      onClick={() => handleClickTopic(category)}
                      className={Styles.Single}>
                      {Object.keys(category)}
                    </div>
                  ))}
                </div>
              </div>
              <div className={classNames('col-9')}>
                {filteredQuestions.map((category, i) => (
                  <div key={Object.keys(category)[0] + i}>
                    <div
                      ref={(ref) => {
                        const name = `${cleanKey(Object.keys(category)[0])}`;
                        refs.current[name] = ref;
                      }}
                      className={classNames('mb-1', 'mt-5', 'pl-3', Styles.Category)}>
                      {Object.keys(category)}
                    </div>
                    {Object.values(category)[0].map((q, id) => (
                      <QuestionAnswerCollapse
                        key={`keynr-${id}`}
                        question={q.question}
                        answer={q.answer}
                        search={searchValue}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default FaqModal;
