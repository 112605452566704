import React, { useCallback, useEffect, useRef, useState } from 'react';
import Styles from './SignatureImage.module.scss';
import { MdFlag, MdRotateRight } from 'react-icons/md';
import TextButton, { TextButtonVariant } from '../../common/components/TextButton';
import { Loader } from '../../common/components/Loader';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import { useOnClickOutside } from '../../common/hooks/useOnClickOutside';
import AuthGuard from '../../auth/components/AuthGuard';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import classNames from 'classnames';
import { useKeyboardShortcut } from '../../navigation/hooks/useKeyboardShortcut';
import { useResizeListener } from '../../common/hooks/useResizeListener';
import { useApiImageFetch } from '../hook/useApiImageFetch';
import { ImageError } from './ImageError';
import { isEqual } from 'lodash';
import { LazyImage } from '../../common/components/LazyImage';
import { getImageErrorMessageByError } from '../helpers/GetImageErrorMessageByError';
import { getProofOfDeliveryType, ProofOfDelivery } from '../models/enumerations/ProofOfDelivery';
import { NO_OP } from '../../dashboard/components/FilterBubble';

// Ratio from width to height for a signature
// e.g.
// height = 200
// width = 266.66
// ratio = width / height = 266.66 / 200 = 1.3333
const DEFAULT_SIGNATURE_IMAGE_RATIO = 1.3333333;

export type SignatureRotation = 0 | 90 | 180 | 270;

type Props = {
  flaggingEnabled?: boolean;
  rotationEnabled?: boolean;
  handleImageRotate: () => void;
  handleMouseEnter: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleMouseLeave: (e?: React.MouseEvent<HTMLDivElement>) => void;
  handleImageError?: () => void;
  url: string;
  handleMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleMouseUp?: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleImageFlagged?: (arg0: string) => void;
  imageType: ProofOfDelivery;
  rotation?: SignatureRotation;
  imageSize?: {
    width: string | number;
    height: string | number;
  };
  styles?: {
    image?: Object;
    overlay?: Object;
    loader?: Object;
    error?: Object;
  };
  lazyLoadRoot?: Element;
  finishedDelivery?: FinishedDelivery;
  imageRatio?: number;
  imageWidth: number;
  offsetTop?: number;
  onImageLoaded?: (src: string) => void;
  src?: string;
};

const imageStyles = {
  0: {},
  90: {
    transform: `rotate(90deg) translateY(-100%)`,
    transformOrigin: 'top left',
  },
  180: {
    transform: `rotate(180deg) translate(-100%, -100%)`,
    transformOrigin: 'top left',
  },
  270: {
    transform: `rotate(270deg) translateX(-100%)`,
    transformOrigin: 'top left',
  },
};

const SignatureImage: React.FC<Props> = ({
  finishedDelivery,
  handleImageRotate,
  handleMouseEnter,
  handleMouseLeave,
  handleImageError,
  url,
  handleMouseDown,
  handleMouseUp,
  handleImageFlagged,
  imageWidth,
  imageRatio = DEFAULT_SIGNATURE_IMAGE_RATIO,
  rotation = 90,
  flaggingEnabled = true,
  rotationEnabled = true,
  lazyLoadRoot = undefined,
  onImageLoaded = NO_OP,
}: Props) => {
  const isSignature = finishedDelivery && getProofOfDeliveryType(finishedDelivery.proofOfDelivery) === 'signature';
  const [overlayOpen, setOverlayOpen] = useState(false);
  // "active" describes the state during a click, if you hold your button down,
  // the component stays "active" unless released
  const [flagConfirmationVisible, setFlagConfirmationVisible] = useState(false);
  const confirmationRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const closeConfirmation = useCallback(() => {
    if (flagConfirmationVisible) {
      setFlagConfirmationVisible(false);
    }
  }, [flagConfirmationVisible]);

  useEffect(() => {
    if (!flagConfirmationVisible) {
      return;
    }
    handleMouseLeave && handleMouseLeave();
  }, [handleMouseLeave, flagConfirmationVisible]);

  useOnClickOutside(confirmationRef, closeConfirmation);
  useKeyboardShortcut({ code: 'Escape' }, closeConfirmation);

  const { error, imgId, loadFn, resetError } = useApiImageFetch(url, handleImageError);

  const onLoadCompletedCallback = useCallback(
    (src) => {
      setLoaded(true);
      onImageLoaded(src);
    },
    [onImageLoaded]
  );

  const onMouseEnterCallback = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (flagConfirmationVisible || error || !loaded) {
        return;
      }
      setOverlayOpen(true);
      handleMouseEnter && handleMouseEnter(e);
    },
    [flagConfirmationVisible, error, loaded, handleMouseEnter]
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [imageStyle, setImageStyle] = useState({});

  const imageRef = useRef<HTMLImageElement>(null);

  const recalculateImageStyle = useCallback(() => {
    if (!wrapperRef) {
      setImageStyle({});
      return;
    }

    // Depending on the rotation we translate the origin of the image
    // We cannot transform from center because this can cause a breakout
    // of the image container
    const transformationStyle = (() => {
      return imageStyles[`${rotation}`];
    })();

    // If we rotate 90 or 270 degree, we swap the width and height
    // to make the image fit it's container.
    const widthHeightStyle = (() => {
      if (!wrapperRef.current) {
        return {};
      }

      if ([270, 90].includes(rotation)) {
        return {
          maxWidth: wrapperRef.current.getBoundingClientRect().height,
          maxHeight: wrapperRef.current.getBoundingClientRect().width,
        };
      } else {
        return {
          maxWidth: wrapperRef.current.getBoundingClientRect().width,
          maxHeight: wrapperRef.current.getBoundingClientRect().height,
        };
      }
    })();

    const newStyle = {
      ...transformationStyle,
      ...widthHeightStyle,
    };
    setImageStyle((v) => {
      if (isEqual(v, newStyle)) {
        return v;
      }
      return newStyle;
    });
  }, [rotation, wrapperRef]);

  useResizeListener(wrapperRef, recalculateImageStyle);

  useEffect(() => {
    recalculateImageStyle();
  }, [recalculateImageStyle]);

  const overlayPosition = {};

  const openOverlay = useCallback(() => setOverlayOpen(true), []);
  const closeOverlay = useCallback(() => setOverlayOpen(false), []);

  const onFlagClick = useCallback(() => {
    setOverlayOpen(false);
    setFlagConfirmationVisible(true);
  }, []);

  const onImageFlagCallback = useCallback(
    (e) => {
      if (error) {
        return;
      }
      e.preventDefault();
      handleImageFlagged && handleImageFlagged(imgId);
      setFlagConfirmationVisible(false);
    },
    [error, handleImageFlagged, imgId]
  );

  const confirmationOverlay = (
    <div
      style={overlayPosition}
      ref={confirmationRef}
      className={classNames(Styles.ConfirmationOverlay, {
        visible: true,
      })}>
      <div className={Styles.ConfirmationOverlayInner}>
        <p>Soll das Foto überprüft werden?</p>
        <TextButton
          onClick={onImageFlagCallback}
          variant={TextButtonVariant.White}
          text='OK'
          style={{ float: 'right', fontWeight: 500, marginRight: 20 }}
        />
      </div>
    </div>
  );

  const loaderOverlay = (
    <div className={Styles.LoaderOverlay}>
      <Loader />
    </div>
  );

  const rotateOverlay = (
    <div
      className={classNames(Styles.RotateOverlay, {
        [Styles.iconOverlaySignatureHandwritten]: isSignature,
        [Styles.iconOverlay]: !isSignature,
      })}
      style={overlayPosition}
      onMouseDown={handleMouseDown}
      onMouseEnter={openOverlay}
      onMouseLeave={closeOverlay}
      onMouseUp={handleMouseUp}
      onClick={handleImageRotate}>
      {flaggingEnabled && (
        <AuthGuard absencePermissions={[WellKnownPermission.HidePhotoReport]}>
          {!flagConfirmationVisible && (
            <button title={'Foto melden'} className={Styles.flagBtn} onClick={onFlagClick}>
              <MdFlag className={Styles.flagIcon} />
            </button>
          )}
        </AuthGuard>
      )}
      {rotationEnabled && (
        <div className={Styles.RotationWrapper}>
          <MdRotateRight className={Styles.iconRotateSignature} />
          <span className={Styles.iconOverlayText}>Bild drehen</span>
        </div>
      )}
    </div>
  );

  const imageInPortrait = [0, 180].includes(rotation);
  const imageHeight = imageWidth / imageRatio;

  const overlayEnabled = flaggingEnabled || rotationEnabled;

  const onWrapperMouseLeaveCallback = useCallback(
    (e) => {
      setOverlayOpen(false);
      handleMouseLeave && handleMouseLeave(e);
    },
    [handleMouseLeave]
  );

  const signatureImageStyle = {
    width: !imageInPortrait ? imageWidth : imageHeight,
    height: !imageInPortrait ? imageHeight : imageWidth,
  };

  if (error) {
    const { retryable, message } = getImageErrorMessageByError(error, ProofOfDelivery.imageSignature);

    return (
      <div style={signatureImageStyle}>
        <ImageError retryable={retryable} error={message} onRetry={resetError} />
      </div>
    );
  }

  return (
    <div
      className={Styles.SignatureImage}
      style={signatureImageStyle}
      ref={wrapperRef}
      onMouseOver={onMouseEnterCallback}
      onMouseLeave={onWrapperMouseLeaveCallback}>
      <figure onMouseEnter={openOverlay} onMouseLeave={closeOverlay}>
        <LazyImage
          style={imageStyle}
          onLoadCompleted={onLoadCompletedCallback}
          className={Styles.imgSignature}
          loadFn={loadFn}
          lazyLoadRoot={lazyLoadRoot}
          alt=''
          ref={imageRef}
        />
      </figure>
      {!loaded && loaderOverlay}
      {loaded && overlayEnabled && overlayOpen && rotateOverlay}
      {loaded && flagConfirmationVisible && confirmationOverlay}
    </div>
  );
};

export default SignatureImage;
