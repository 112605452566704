import Model, { DefaultType, MemberType } from '../../../utils/Model';
import { PositiveDirections } from './PositiveDirection';
import { PositiveDirection } from './PositiveDirection';
import { UndeliverableReasonLabels } from '../enumerations/UndeliverableReason';
import { ValueType } from '../enumerations/ValueType';
import { AggregationType } from '../enumerations/AggregationType';
import { MetricTypeKey } from '@contexts/value-expression-context/value-expressions/metric-type-keys';
import { ChartType } from '../enumerations/ChartType';
import { KpiDefinitionWithoutName } from '@custom-types/kpi';

const dayHours = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const complaintReasons = [
  'BNK - Keine',
  'Verspätung',
  'F-Adresse ist richtig',
  'unklarer Sendungsverbleib',
  'unklarer Abliefernachweis',
  'Fehlerhafte BNK',
  'Umverfügung nicht eingehalten',
  'fehlerhafte Scannung',
  'fehlerhafte A-Scannung',
  'fehlerhafte N-Scannung',
  'Fehler bei der Abholung',
  'Fehler bei der Zustellung',
  'Unerwünschte Zustellung am PaketShop (PS)',
  'Kontaktlose Zustellung nicht erfolgt',
  'Abholung nicht erfolgt',
  'keine Quittung',
  'Paketankündigung nicht eingehalten',
  'Schaden gemeldet',
  'Inhalt fehlt (Teilverlust)',
  'Haftpflichtschaden',
  'Korrekturbeleg eingetroffen',
  'Widerspruch nach OPS Klärung',
  'Widerspruch zur Haftungsentscheidung',
  'Rückzahlung von KD/ATG',
  'Zahlungsrückläufer',
  'Unfreundlicher Mitarbeiter',
  'Prävention',
  'Ungepflegtes Erscheinungsbild',
  'Sendung verschmutzt/riecht nach Rauch',
  'Fehlerhafte Datenänderung',
  'sprachliche Probleme / Verständigungsprobleme',
  'unzureichende / falsche Beratung',
  'Ermittlung durch Polizei/Staatsanwaltschaft',
  'Bearbeitungszeit überschritten',
  'Reklamation unvollständig abgeschlossen',
  'PaketShop existiert nicht mehr/ falsche Adresse',
  'Öffnungszeiten des Paketshops wurden nicht eingehalten',
  'Hermes App - allgemeine Probleme',
  'Hermes App - Verbindungsabbrüche',
  'Hermes App - Sendungsverfolgung fehlerhaft',
];

export const deliveryTypes = [
  'props',
  'paketShop',
  'putDownOutside:oneTimeAuthorization',
  'putDownOutside:permanentAuthorization',
  'putDownOutside:bnkAuthorization',
  'putDownOutside:unknownAuthorization',
  'intendedReceiverAndNextDoor',
  'person',
  'person:atDoor',
  'person:intendedReceiver',
  'person:nextDoor',
  'person:reception',
  'person:staff',
  'putDownOutside:Mailbox',
  'putDownOutside:Bag',
  'undeliverable:AuthorityNotFound',
  'undeliverable:AuthorityNotPossible',
  'undeliverable:Canceled',
  'undeliverable:DeliveryItemDamaged',
  'undeliverable:DeliveryRefused',
  'undeliverable:Ident',
  'undeliverable:IdentAge',
  'undeliverable:IdentBirthday',
  'undeliverable:IdentDocument',
  'undeliverable:IdentName',
  'undeliverable:IdentPicture',
  'undeliverable:IdentPin',
  'undeliverable:IdVerificationFailed',
  'undeliverable:Loss',
  'undeliverable:MinAgeVerificationFailed',
  'undeliverable:MisroutedDeliveryItem',
  'undeliverable:NameVerificationFailed',
  'undeliverable:NoMoney',
  'undeliverable:NotMet',
  'undeliverable:NotMet1',
  'undeliverable:NotMet2',
  'undeliverable:NotMet3',
  'undeliverable:NotMet4',
  'undeliverable:NotPickedUp',
  'undeliverable:PackagingDamaged',
  'undeliverable:PictureVerificationFailed',
  'undeliverable:RLoGGS',
  'undeliverable:SingleAuthorityNotPossible',
  'undeliverable:TooLate',
  'undeliverable:WrongAddress',
  // backward compatibility
  'putDownOutside',
  'oneTimeAuthorizationPutDown',
];

export const undeliverableReasons = [
  'AuthorityNotFound',
  'AuthorityNotPossible',
  'Canceled',
  'DeliveryItemDamaged',
  'DeliveryRefused',
  'Ident',
  'IdentAge',
  'IdentBirthday',
  'IdentDocument',
  'IdentName',
  'IdentPicture',
  'IdentPin',
  'IdVerificationFailed',
  'Loss',
  'MinAgeVerificationFailed',
  'MisroutedDeliveryItem',
  'NameVerificationFailed',
  'NoMoney',
  'NotMet',
  'NotMet1',
  'NotMet2',
  'NotMet3',
  'NotMet4',
  'NotPickedUp',
  'PackagingDamaged',
  'PictureVerificationFailed',
  'RLoGGS',
  'SingleAuthorityNotPossible',
  'TooLate',
  'WrongAddress',
  'UNB',
];

export const undeliverableReasonsShipmentBased = [
  'Cancelled',
  'IdentAge',
  'DeliveryItemDamaged',
  'DeliveryRefused',
  'IdentDocument',
  'IdentName',
  'IdentPicture',
  'MisroutedDeliveryItem',
  'NoMoney',
  'NotMet1',
  'NotMet2',
  'NotMet3',
  'NotMet4',
  'RLoGGS',
  'TooLate',
  'WrongAddress',
];

export const deliveryTypesShipmentBased = [
  'person',
  'person:atDoor',
  'person:intendedReceiver',
  'person:nextDoor',
  'person:reception',
  'person:staff',
  'putDownOutside:Bag',
  'putDownOutside:bnkAuthorization',
  'putDownOutside:Mailbox',
  'putDownOutside:oneTimeAuthorization',
  'putDownOutside:permanentAuthorization',
  'putDownOutside:unknownAuthorization',
];

export const shipmentTypes = [
  'ABA',
  'BRFBU',
  'BRFST',
  'FAH',
  'GEPSO',
  'GEPST',
  'GRST',
  'HERVP',
  'HPGE',
  'HPST',
  'KATST',
  'KATUN',
  'KFS',
  'KRTS',
  'KWS',
  'PKT',
  'SG',
  'SKI',
  'TUE',
  'LTUE',
  'UNB',
];

export const shipmentTypeMap = {
  ABA: 'Abholauftrag',
  BRFBU: 'Businessbrief',
  BRFST: 'Brief Standard',
  FAH: 'Fahrrad',
  GEPSO: 'Sondergepäck',
  GEPST: 'Gepäck Standard',
  GRST: 'Großstück',
  HERVP: 'Herstellerverpackung',
  HPGE: 'Hängepaket gebündelt',
  HPST: 'Hängepaket Standard',
  KATST: 'Katalog Standard',
  KATUN: 'Katalog unfoliert',
  KFS: 'Krankenfahrstuhl',
  KRTS: 'Kartusche',
  KWS: 'Kleinwarensendung',
  PKT: 'Paket',
  SG: 'Servicegebühr',
  SKI: 'Ski',
  TUE: 'Tüte',
  LTUE: 'Leichttüte',
  UNB: 'Unbekannt',
};

export const byDeliveryDetailTypeMap = {
  props: 'ProPS',
  paketShop: 'PaketShop',
  intendedReceiverAndNextDoor: 'Originalempfänger und Nachbar',
  person: 'unbekannt',
  'person:atDoor': 'In Wohnung',
  'person:intendedReceiver': 'Originalempfänger',
  'person:nextDoor': 'Nachbar',
  'person:reception': 'Rezeption',
  'person:staff': 'Mitarbeiter',
  'putDownOutside:Mailbox': 'Briefkasten',
  'putDownOutside:Bag': 'Zustelltüte',
  'putDownOutside:oneTimeAuthorization': 'Einmalvollmacht',
  'putDownOutside:permanentAuthorization': 'Dauervollmacht',
  'putDownOutside:bnkAuthorization': 'BNK-Vollmacht',
  'putDownOutside:unknownAuthorization': 'Vollmacht',
  'undeliverable:UNB': 'Rückläufer: Sonstiger Grund',
  'undeliverable:AuthorityNotFound': 'Rückläufer: Ablageort nicht gefunden',
  'undeliverable:AuthorityNotPossible': 'Rückläufer: Ablage nicht möglich',
  'undeliverable:Canceled': 'Rückläufer: Storno',
  'undeliverable:DeliveryItemDamaged': 'Rückläufer: Schadenfall',
  'undeliverable:DeliveryRefused': 'Rückläufer: Annahmeverweigerung',
  'undeliverable:Ident': 'Rückläufer: Identservice',
  'undeliverable:IdentAge': 'Rückläufer: Altersverifizierung nicht möglich',
  'undeliverable:IdentBirthday': 'Rückläufer: Identservice Geburtsdatum',
  'undeliverable:IdentDocument': 'Rückläufer: Identservice Dokument',
  'undeliverable:IdentName': 'Rückläufer: Identservice Name',
  'undeliverable:IdentPicture': 'Rückläufer: Identservice Lichtbild',
  'undeliverable:IdentPin': 'Rückläufer: Identservice PIN-Code',
  'undeliverable:IdVerificationFailed': 'Rückläufer: Identservice Dokument',
  'undeliverable:Loss': 'Rückläufer: Verlust',
  'undeliverable:MinAgeVerificationFailed': 'Rückläufer: Identservice Geburtsdatum',
  'undeliverable:MisroutedDeliveryItem': 'Rückläufer: Irrläufer',
  'undeliverable:NameVerificationFailed': 'Rückläufer: Identservice Name',
  'undeliverable:NoMoney': 'Rückläufer: Empfänger hat kein Bargeld',
  'undeliverable:NotMet': 'Rückläufer: Nicht angetroffen',
  'undeliverable:NotMet1': 'Rückläufer: Nicht angetroffen - N1',
  'undeliverable:NotMet2': 'Rückläufer: Nicht angetroffen - N2',
  'undeliverable:NotMet3': 'Rückläufer: Nicht angetroffen - N3',
  'undeliverable:NotMet4': 'Rückläufer: Nicht angetroffen - N4',
  'undeliverable:NotPickedUp': 'Rückläufer: Nicht am Paketshop abgeholt',
  'undeliverable:PackagingDamaged': 'Rückläufer: Verpackung ausgebessert',
  'undeliverable:PictureVerificationFailed': 'Rückläufer: Identservice Lichtbild',
  'undeliverable:RLoGGS': 'Rückläufer: ohne Gegenscannung',
  'undeliverable:SingleAuthorityNotPossible': 'Rückläufer: Einmalvollmacht nicht möglich',
  'undeliverable:TooLate': 'Rückläufer: Zeitmangel',
  'undeliverable:WrongAddress': 'Rückläufer: Falsche Anschrift',
  // backward compatibility
  oneTimeAuthorizationPutDown: 'Einmalvollmacht',
  putDownOutside: 'Dauervollmacht',
};

const sourceMap = {
  ProPS: 'ProPS',
  PaketShop: 'Paketshop',
  Tour: 'Tour',
};
export const abhMitReturnTypes = ['Gepäck', 'PriPS', 'Retoure'];
export const abhMitReturnTypeMap = {
  Gepäck: 'Gepäck',
  PriPS: 'PriPS',
  Retoure: 'Retoure',
};

const deliverySources = ['PaketShop', 'Tour', 'ProPS'];

const deliveryModes = [
  'putDownOutside:oneTimeAuthorization',
  'putDownOutside:permanentAuthorization',
  'putDownOutside:bnkAuthorization',
  'putDownOutside:unknownAuthorization',
  'person',
  'undeliverable',
  'paketShop',
  'props',
  // backward compatibility
  'putDownOutside',
  'oneTimeAuthorizationPutDown',
];

const byItemCount = ['1', '2', '3', '4', '5-10', '11-20', '21-100', '>100'];

export const raaReasons = [
  'Canceled',
  'DeliveryRefused',
  'NoMoney',
  'NotMet1',
  'NotMet2',
  'NotMet3',
  'NotMet4',
  'WrongAddress',
];

export const raaReasonsMap = UndeliverableReasonLabels;

export const notificationTypes = ['card', 'email'];

export const notificationTypeLabels = {
  card: 'BNK',
  email: 'Email',
};

const mapReducer = (acc, val) => {
  acc[val] = val;
  return acc;
};
const complaintReasonsMap = complaintReasons.reduce(mapReducer, {});

export type ChartRenderOptions = {
  // If true, the chart will always start on 0 on the y-axis. This can result in a lot of white spaces if all
  // values are close to each other and far away from zero, e.g. high percentage numbers (98% - 100%).
  startYAxisOnZero?: boolean;
};

export const DefaultChartRenderOptions = {
  startYAxisOnZero: true,
};

export const mapKeys = {
  'finished_deliveries.stop_journey.location.accuracy': ['0-5', '6-10', '11-20', '21-50', '51-100', '101-200', '>200'],
  'finished_deliveries.stop_journey.location.time_lag': [
    '0-5',
    '6-10',
    '11-15',
    '16-20',
    '21-30',
    '31-60',
    '61-120',
    '121-300',
    '>300',
  ],
  'returns.finished_deliveries.by_hour': dayHours,
  'returns.finished_deliveries.by_source': deliverySources,
  'returns.finished_deliveries.by_item_count': byItemCount,
  'returns.finished_deliveries.items.by_hour': dayHours,
  'returns.finished_deliveries.items.by_source': deliverySources,
  'returns.finished_deliveries.items.by_type': [
    'ReturnToSender',
    'NewParcel',
    'PriPS',
    'ProPS',
    'DBCourier',
    'Undeliverable',
  ],
  'finished_deliveries.by_customer_count': ['1', '2', '3', '4', '5-10', '>10'],
  'finished_deliveries.by_item_count': byItemCount,
  'finished_deliveries.items.by_delivery_detail_type': deliveryTypes,
  'finished_deliveries.items.by_delivery_mode': deliveryModes,
  'finished_deliveries.items.delivered.by_hour': dayHours,
  'finished_deliveries.items.delivered.by_recipient_person': [
    'atDoor',
    'intendedReceiver',
    'nextDoor',
    'reception',
    'staff',
    'paketShop',
    'props',
  ],
  'finished_deliveries.items.undeliverable.by_hour': dayHours,
  'finished_deliveries.items.undeliverable.by_reason': undeliverableReasons,
  'finished_deliveries.notifications.by_type': notificationTypes,
  'finished_deliveries.proof_of_delivery.by_type': ['none', 'image', 'signature', 'imageSignature'],
  'finished_deliveries.items.delivered.by_type': shipmentTypes,
  'tour_loaded.deliveryitems.types': shipmentTypes,
  'complaints.reported.count_by_type': complaintReasons,
  'erp.costs.tours.amount_by_article': [
    '200000000',
    '200000100',
    '200000101',
    '200000102',
    '200000103',
    '200000107',
    '200000109',
    '200000110',
    '200000111',
    '200000113',
    '200000114',
    '200000116',
    '200000118',
    '200000119',
    '200000120',
    '200000121',
    '200000122',
    '200000123',
    '200000124',
    '200000125',
    '200000126',
    '200000128',
    '200000130',
    '200000160',
    '200000162',
    '200000163',
    '200000164',
    '200000165',
    '200000166',
    '200000167',
    '200000168',
    '200000170',
    '200000171',
    '200000172',
    '200000184',
    '200000185',
    '200000186',
    '200000187',
    '200000188',
    '200000189',
    '200000190',
    '200000191',
    '200000192',
    '200000193',
    '200000210',
    '200000230',
    '200000400',
    '201000001',
  ],
  'erp.costs.tours.amount_by_type': ['110', '111', '112'],
  'lma-shipmentbased.ZU.all.count.by_delivery_type': deliveryTypes,
  'lma-shipmentbased.TA.relevant.count.by_shipment_type': shipmentTypes,
  'lma-shipmentbased.RL.all.count.by_undeliverable_reason': undeliverableReasons,
  'lma-shipmentbased.ABH.all.count.by_return_type': abhMitReturnTypes,
  'lma-shipmentbased.MIT.all.count.by_return_type': abhMitReturnTypes,
  'lma-shipmentbased.PSE.all.count.by_return_type': abhMitReturnTypes,
  'lma-shipmentbased.RAA.all.count.by_undeliverable_reason': raaReasons,
  'lma-shipmentbased.RL.express.count.by_undeliverable_reason': undeliverableReasons,
};

export const mapKeyLabels = {
  'returns.finished_deliveries.by_source': sourceMap,
  'returns.finished_deliveries.items.by_source': sourceMap,
  'returns.finished_deliveries.items.by_type': {
    ReturnToSender: 'Retoure',
    NewParcel: 'PriPS/ProPS',
    PriPS: 'PriPS',
    ProPS: 'ProPS',
    DBCourier: 'DB Kuriergepäck',
    Undeliverable: 'Paketshop Rücklauf',
  },
  'finished_deliveries.items.by_delivery_detail_type': byDeliveryDetailTypeMap,
  'finished_deliveries.items.by_delivery_mode': {
    props: 'ProPS',
    paketShop: 'PaketShop',
    'putDownOutside:oneTimeAuthorization': 'Einmalvollmacht',
    'putDownOutside:permanentAuthorization': 'Dauervollmacht',
    'putDownOutside:bnkAuthorization': 'BNK-Vollmacht',
    'putDownOutside:unknownAuthorization': 'Vollmacht',
    person: 'Zugestellt',
    undeliverable: 'Rückläufer',
    // backward compatibility
    putDownOutside: 'Dauervollmacht',
    oneTimeAuthorizationPutDown: 'Einmalvollmacht',
  },
  'finished_deliveries.items.delivered.by_recipient_person': {
    atDoor: 'In Wohnung',
    intendedReceiver: 'Originalempfänger',
    nextDoor: 'Nachbar',
    reception: 'Rezeption',
    staff: 'Mitarbeiter',
  },
  'finished_deliveries.items.undeliverable.by_reason': UndeliverableReasonLabels,
  'finished_deliveries.notifications.by_type': notificationTypeLabels,
  'finished_deliveries.proof_of_delivery.by_type': {
    none: 'Ohne',
    image: 'Foto',
    signature: 'Unterschrift',
    imageSignature: 'Foto-Unterschrift',
  },
  'finished_deliveries.items.delivered.by_type': shipmentTypeMap,
  'tour_loaded.deliveryitems.types': shipmentTypeMap,
  'complaints.reported.count_by_type': complaintReasonsMap,
  'erp.costs.tours.amount_by_type': {
    110: '(GU) Generalunternehmer-Abrechnung',
    111: '(GU) Generalunternehmer-Korrektur',
    112: '(GU) Generalunternehmer-Pauschalen/Zuschlag',
  },
  'erp.costs.tours.amount_by_article': {
    200000000: 'Kl. 000 Sendung ohne Avis',
    200000100: 'Kl. 100 Standardleistung',
    200000101: 'Kl. 101 Eilsendung Standard',
    200000102: 'Kl. 102 Kataloge mit Quittung',
    200000103: 'Kl. 103 Wunschtermin Standard',
    200000107: 'Kl. 107 Premiumservice',
    200000108: 'Kl. 108 Feierabendservice',
    200000109: 'Kl. 109 Ehemals Premiumservice',
    200000110: 'Kl. 110 Eilsendung Samstag',
    200000111: 'Kl. 111 Wunschtermin Samstag',
    200000113: 'Kl. 113 Identservice',
    200000114: 'Kl. 114 Mitnahmeretouren',
    200000116: 'Kl. 116 Abholretouren',
    200000118: 'Kl. 118 Eilsendung Montag',
    200000119: 'Kl. 119 Wunschtermin Montag',
    200000120: 'Kl. 120 Kataloge/Briefe bis 200 g',
    200000121: 'Kl. 121 Kataloge/Briefe bis 400 g',
    200000122: 'Kl. 122 Kataloge/Briefe bis 600 g',
    200000123: 'Kl. 123 Kataloge/Briefe bis 800 g',
    200000124: 'Kl. 124 Kataloge/Briefe bis 1.200 g',
    200000125: 'Kl. 125 Kataloge/Briefe bis 1.500 g',
    200000126: 'Kl. 126 Kataloge/Briefe bis 2.000 g',
    200000128: 'Kl. 128 Kataloge/Briefe bis 3.000 g',
    200000130: 'Kl. 130 Kataloge/Briefe über 3.000 g',
    200000160: 'Kl. 160 Privatservice Mitnahme am Paketshop',
    200000162: 'Kl. 162 Paketshop - Stopps',
    200000163: 'Kl. 163 Paketshop - Sendungen bis 5',
    200000164: 'Kl. 164 Paketshop - Sendungen ab 5',
    200000165: 'Kl. 165 Prop - Stopps',
    200000166: 'Kl. 166 Prop - Sendungen bis 5',
    200000167: 'Kl. 167 Prop - Sendungen ab 5',
    200000168: 'Kl. 168 Paketshop Sperrgut',
    200000170: 'Kl. 170 DB-Kuriergepäck Standard',
    200000171: 'Kl. 171 DB-Kuriergepäck Samstag',
    200000172: 'Kl. 172 DB-Kuriergepäck Montag',
    200000184: 'Kl. 184 Paketshop XS',
    200000185: 'Kl. 185 Paketshop Hermes Päckchen',
    200000186: 'Kl. 186 Paketshop Hermes Päckchen Sperrgut',
    200000187: 'Kl. 187 Paketshop S',
    200000188: 'Kl. 188 Paketshop S-Sperrgut',
    200000189: 'Kl. 189 PaketShop M',
    200000190: 'Kl. 190 PaketShop M-Sperrgut',
    200000191: 'Kl. 191 PaketShop L',
    200000192: 'Kl. 192 PaketShop L-Sperrgut',
    200000193: 'Kl. 193 PaketShop Gepäck',
    200000200: 'Kl. 200 DKS-Sendungen',
    200000210: 'Kl. 210 Pauschaltour',
    200000230: 'Kl. 230 Flyer/Wurfsendungen',
    200000400: 'Kl. 400 Rückläufer',
    201000001: 'Abwicklungspauschale',
    201000002: 'Sperrgutpauschale',
    202000001: 'Dieselzuschlag',
  },
  'lma-shipmentbased.ZU.all.count.by_delivery_type': byDeliveryDetailTypeMap,
  'lma-shipmentbased.TA.relevant.count.by_shipment_type': shipmentTypeMap,
  'lma-shipmentbased.ABH.all.count.by_return_type': abhMitReturnTypeMap,
  'lma-shipmentbased.MIT.all.count.by_return_type': abhMitReturnTypeMap,
  'lma-shipmentbased.PSE.all.count.by_return_type': abhMitReturnTypeMap,
  'lma-shipmentbased.RAA.all.count.by_undeliverable_reason': raaReasonsMap,
  'lma-shipmentbased.RL.express.count.by_undeliverable_reason': UndeliverableReasonLabels,
  'lma-shipmentbased.RL.all.count.by_undeliverable_reason': UndeliverableReasonLabels,
};

export default class MetricType extends Model<MetricType> {
  label: string;
  key: MetricTypeKey | string;
  aggregation: AggregationType;
  valueType: ValueType;
  positiveDirection: PositiveDirections;
  category: string | null | undefined;
  description: string | null | undefined;
  valueFormat: string;
  sumLabel: string | null | undefined;
  hidden: boolean | null | undefined;
  definition?: KpiDefinitionWithoutName;
  chartRenderOptions: ChartRenderOptions = DefaultChartRenderOptions;
  chart: ChartType;
  apiVersion: 'ws' | 'graphql' = 'ws';

  constructor(source: Partial<MetricType> = null, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  isPositiveChange(change: number): boolean {
    if (this.positiveDirection === PositiveDirection.up) {
      return change > 0;
    } else {
      return change < 0;
    }
  }

  static mapKeys = mapKeys;
  static mapKeyLabels = mapKeyLabels;

  get mapKeys(): Array<string> {
    if (MetricType.mapKeys[this.key]) {
      return MetricType.mapKeys[this.key];
    }
    return [];
  }

  get mapKeyLabels(): {
    [key: string]: string;
  } {
    if (MetricType.mapKeyLabels[this.key]) {
      return MetricType.mapKeyLabels[this.key];
    }
    return {};
  }

  getDefaults(): DefaultType<MetricType> {
    return {
      valueFormat: () => '-#.###.##0',
      hidden: () => false,
    };
  }

  getMembers(): MemberType<MetricType> {
    return undefined;
  }
}
