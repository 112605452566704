import React, { ReactElement } from 'react';

import { Layer, LayerProps } from 'react-map-gl';

export type MapboxLayerProps = LayerProps & {};

export function MapboxLayer(props: MapboxLayerProps): ReactElement {
  return <Layer {...props} />;
}
export default MapboxLayer;
