import { OrgSearchService } from '../services/OrgSearchService';
import { useEffect, useState } from 'react';
import OrgUnitNode from '../../metrics2/models/websocket/org/OrgUnitNode';

const orgSearchService = new OrgSearchService();

export const useTreeSearch = (tree: Array<OrgUnitNode>, searchValue: string, minSearchLength: number = 2) => {
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    if (!searchValue || searchValue.length < minSearchLength) {
      setSearchResults(null);
      return;
    }
    setSearchResults(orgSearchService.filterOrgUnits(tree, searchValue));
  }, [minSearchLength, searchValue, tree]);

  return { searchResults };
};
