import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import rueckfahrtstrecke from './rueckfahrtstrecke';
import touren from './touren';

const expression = new QuotientValueExpression(
  rueckfahrtstrecke,
  touren,
  'Ø Rückfahrtstrecke',
  false,
  '-#.###.##0,0 km',
  undefined,
  getDefinitionByName('Rückfahrtstrecke')
);

expression.setIdentifier('rueckfahrtstrecke/touren');

export default expression;
