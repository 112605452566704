import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import anzahlTagExpressEPlus0 from './anzahl-tag-express-e-plus-0';
import anzahlTagExpress from './anzahl-tag-express';

const expression = new QuotientValueExpression(
  anzahlTagExpressEPlus0,
  anzahlTagExpress,
  'E+0 Eil',
  true,
  undefined,
  undefined,
  getDefinitionByName('E+0 Eil')
);

expression.chartRenderOptions.startYAxisOnZero = false;
expression.setIdentifier('same_day.e_plus_0_express');

export default expression;
