import TourDetails from '../models/entities/TourDetails';
import moment from 'moment-timezone';
import GeoCoordinate from '../../geo/models/GeoCoordinate';
import GeoLocation from '../../geo/models/GeoLocation';
import Logger from '../../utils/logging/Logger';
import { LMAnalyticsInputFinishedDelivery, LMAnalyticsInputTourModel } from '../models/types/TourInputModels';
import CustomerTrackingData from '../models/types/CustomerTrackingData';
import TourPlanStop from '../models/entities/TourPlanStop';

export default class TourDetailsGenerator {
  static _LOCATION_ACCURACY_TOLERANCE = 50;
  static _LOCATION_ACCURACY_INTOLERANCE = 100;

  logger: Logger = Logger.getInstance('TourDetailsGenerator');

  _fixFinishedAt(
    sortedFinishedDeliveries: Array<LMAnalyticsInputFinishedDelivery>,
    finishedDelivery: LMAnalyticsInputFinishedDelivery
  ) {
    const sortedFinishedDelivery = sortedFinishedDeliveries[0];
    let startAt = null;
    if (sortedFinishedDelivery) {
      startAt = moment(sortedFinishedDelivery.finishedAt);
    }
    if (!finishedDelivery.finishedAt) {
      this.logger.error('finished delivery should not have an empty finished At', finishedDelivery);
      if (finishedDelivery.location) {
        return moment(finishedDelivery.location.date);
      } else {
        return moment(startAt);
      }
    }
    const finAt = moment(finishedDelivery.finishedAt);
    if (startAt && finAt.isBefore(startAt)) {
      return startAt.clone().subtract(5, 'm');
    } else {
      return finAt;
    }
  }

  _locationIsNotTrustworthy(location: GeoLocation, knownAddress: boolean) {
    if (typeof location?.accuracy !== 'number') return false;

    const tolerance = knownAddress
      ? TourDetailsGenerator._LOCATION_ACCURACY_TOLERANCE
      : TourDetailsGenerator._LOCATION_ACCURACY_INTOLERANCE;
    return location.accuracy > tolerance;
  }

  _customerLocationRef(
    finishedDelivery: LMAnalyticsInputFinishedDelivery,
    customers: Map<string, CustomerTrackingData>
  ): string | null | undefined {
    const customerRefs = Object.keys(finishedDelivery.customerDeliveries);
    const customerRef = customerRefs.find((cRef) => customers.has(cRef) && customers.get(cRef).location);
    return customerRef;
  }

  _guessDeliveryLocation(
    finishedDelivery: LMAnalyticsInputFinishedDelivery,
    customers: Map<string, CustomerTrackingData>
  ): GeoLocation | null | undefined {
    const customerRef = this._customerLocationRef(finishedDelivery, customers);
    if (customerRef) {
      return new GeoLocation({
        ...customers.get(customerRef).location,
        date: moment(finishedDelivery.finishedAt),
      });
    }
    return null;
  }

  convert(tourDetailsRaw: LMAnalyticsInputTourModel): TourDetails {
    const { anomalies = [] } = tourDetailsRaw;

    const customers = new Map(
      (tourDetailsRaw.tourDetails.customers || []).map((customer) => [customer.customerRef, customer])
    );
    let deliveryItems = new Map();
    if (tourDetailsRaw.tourDetails.deliveryItems) {
      deliveryItems = new Map(
        tourDetailsRaw.tourDetails.deliveryItems.map((deliveryItem) => [deliveryItem.deliveryItemId, deliveryItem])
      );
    }
    tourDetailsRaw.finishedDeliveries.forEach((fd, idx) => {
      const knownAddress = !!this._customerLocationRef(fd, customers);
      if (this._locationIsNotTrustworthy(fd.location, knownAddress)) {
        delete fd.location;
      }
    });

    const finishedDeliveries = tourDetailsRaw.finishedDeliveries.map((fd) => {
      if (!fd.deliveryItems || !fd.returnDeliveryItems) {
        const crefs = Object.keys(fd.customerDeliveries);
        const items = tourDetailsRaw.tourDetails.deliveryItems.filter((i) => crefs.includes(i.customerRef));
        if (!fd.deliveryItems) {
          fd.deliveryItems = items.filter((i) => i.deliveryItemClass !== 'ReturnParcel');
        }
        if (!fd.returnDeliveryItems) {
          fd.returnDeliveryItems = items.filter((i) => i.deliveryItemClass === 'ReturnParcel');
        }
      }
      return fd;
    });
    const sortedFinishedDeliveries = finishedDeliveries.sort((fd1, fd2) =>
      moment(fd1.finishedAt).diff(moment(fd2.finishedAt))
    );
    const unfinishedCustomers: Set<string> = new Set(tourDetailsRaw.tourDetails.customers.map((c) => c.customerRef));
    sortedFinishedDeliveries.forEach((fd, i) => {
      fd.finishedAt = this._fixFinishedAt(sortedFinishedDeliveries, fd);
      fd.displayableStopNumber = i + 1;
      if (!fd.location) {
        fd.location = this._guessDeliveryLocation(fd, customers);
      }
      Object.keys(fd.customerDeliveries).forEach((cusRef) => {
        unfinishedCustomers.delete(cusRef);
      });
    });
    const lastFinishedDelivery = sortedFinishedDeliveries[sortedFinishedDeliveries.length - 1];
    const startedAt = moment(tourDetailsRaw?.tour?.info?.startedAt || tourDetailsRaw?.tour?.info?.loadedAt);
    const lastFinishedDeliveryAt = lastFinishedDelivery ? lastFinishedDelivery.finishedAt : startedAt;
    let finishAt = null;
    let softFinishAt = null;
    let estimatedFinalAt = null;
    if (unfinishedCustomers.size > 0) {
      // start + elaspsedTime * customers.size / finishedCustomers.size
      estimatedFinalAt = startedAt
        .clone()
        .add(
          (lastFinishedDeliveryAt.diff(startedAt) * customers.size) / (customers.size - unfinishedCustomers.size),
          'ms'
        );
    } else {
      // last finished delivery plus 15 min
      finishAt = lastFinishedDeliveryAt.clone().add(15, 'm');
      softFinishAt = lastFinishedDeliveryAt.clone().add(15, 'm');
    }

    const realPath = this.buildPaths(sortedFinishedDeliveries);

    const tourDetails: Partial<TourDetails> = {
      startedAt,
      currentDriverLocation: tourDetailsRaw.geo as any,
      driver: tourDetailsRaw?.tour?.driver,
      tourIdentifier: {
        ...tourDetailsRaw?.tour?.identifier,
        orgId: tourDetailsRaw?.tour?.identifier?.organizationId,
      } as any,
      softFinishAt: tourDetailsRaw?.tour?.info?.softFinishAt || softFinishAt,
      finishAt: tourDetailsRaw?.tour?.info?.finishedAt || finishAt,
      estimatedFinalAt: estimatedFinalAt,
      appInfo: tourDetailsRaw.appInfo,
      startLocation: realPath[0] as any, // tourDetailsRaw.tour.info.loadedLocation,
      customers,
      deliveryItems,
      realPath: realPath as any,
      anomalies,
      finishedDeliveries: new Map(
        finishedDeliveries.map((fd: LMAnalyticsInputFinishedDelivery) => [fd.uuid, fd])
      ) as any,
      plannedStops: tourDetailsRaw?.plannedStops?.map((stop) => new TourPlanStop(stop)),
    };
    return new TourDetails(tourDetails);
  }

  buildPaths(sortedFinishedDeliveries: LMAnalyticsInputFinishedDelivery[]): Array<LMAnalyticsInputFinishedDelivery> {
    let realPath = [];
    let lastLocation = null;
    let distance = 0;
    sortedFinishedDeliveries
      .filter((fd) => fd.routeStop)
      .forEach((fd) => {
        const routeFromLast = fd.routeStop.routeFromLast || [];
        const fdLocations = [
          ...routeFromLast.map((l) => ({ ...l, date: moment(l.date) })).filter((l) => l.date.isBefore(fd.finishedAt)),
          { ...fd.location, date: moment(fd.finishedAt) },
        ];
        realPath = realPath.concat(fdLocations);
        distance = 0;
        fdLocations.forEach((l) => {
          if (l) {
            const loc = new GeoCoordinate(l);
            if (lastLocation) {
              distance += loc.metricDistanceTo(lastLocation);
            }
            lastLocation = loc;
          }
        });
        fd.routeStop.realDistanceFromLast = distance;
      });
    realPath = realPath.filter((l) => !!l).sort((l1, l2) => moment(l1.date).diff(moment(l2.date)));

    return realPath;
  }
}
