import { useAuthContext } from '@contexts/auth-context';
import { DataTableComponentId } from '@data-table/data-table.types';
import { WellKnownPermission } from '@legacy-modules/auth/constants/WellKnownPermission';
import { SortingState } from '@tanstack/react-table';
import { useState } from 'react';

export type TabConfig = {
  componentId: DataTableComponentId;
  title: string;
  valueExpressionKeys: string[];
  sorting: SortingState;
  valueExpressionKey?: string;
  subTitle?: string;
};
type TabsConfig = {
  [uID: string]: TabConfig;
};
const tabsConfig: TabsConfig = {
  'touren.table.allgemein': {
    componentId: 'touren.table.allgemein',
    title: 'Übersicht',
    subTitle: 'Allgemein',
    sorting: [{ id: 'tourIdentifier', desc: true }],
    valueExpressionKeys: [
      'tour_loaded.count',
      'ist_lademenge/touren',
      'ht-produktivitaet',
      'ruecklaufquote',
      'netto-zustellzeit/touren',
      'bruttozustellzeit/touren',
    ],
  },
  'touren.table.htproduktivitaet': {
    componentId: 'touren.table.htproduktivitaet',
    title: 'HT-Produktivität',
    sorting: [{ id: 'ht-produktivität', desc: true }],
    valueExpressionKey: 'ht-produktivitaet',
    valueExpressionKeys: [
      'tour_loaded.count',
      'zustellungen/touren',
      'abholungen/touren',
      'abholungenProPS/touren',
      'psabgaben/touren',
      'psmitnahmen/touren',
      'netto-ht-zeit/touren',
      'ht-produktivitaet',
    ],
  },
  'touren.table.psproduktivitaet': {
    componentId: 'touren.table.psproduktivitaet',
    title: 'PS-Produktivität',
    sorting: [
      {
        id: 'ps-produktivität',
        desc: true,
      },
    ],
    valueExpressionKey: 'ps-produktivitaet',
    valueExpressionKeys: [
      'tour_loaded.count',
      'zustellungen/touren',
      'abholungen/touren',
      'abholungenProPS/touren',
      'psabgaben/touren',
      'psmitnahmen/touren',
      'netto-ps-zeit/touren',
      'ps-produktivitaet',
    ],
  },
  'touren.table.atgproduktivitaet': {
    componentId: 'touren.table.atgproduktivitaet',
    title: 'ATG-Produktivität',
    sorting: [
      {
        id: 'atg-produktivität',
        desc: true,
      },
    ],
    valueExpressionKey: 'atg-produktivitaet',
    valueExpressionKeys: [
      'tour_loaded.count',
      'zustellungen/touren',
      'abholungen/touren',
      'abholungenProPS/touren',
      'psabgaben/touren',
      'psmitnahmen/touren',
      'netto-atg-zeit/touren',
      'atg-produktivitaet',
    ],
  },
  'touren.table.ruecklaufquote': {
    componentId: 'touren.table.ruecklaufquote',
    title: 'Rücklauf',
    sorting: [
      {
        id: 'rl-quote',
        desc: true,
      },
    ],
    valueExpressionKey: 'ruecklaufquote',
    valueExpressionKeys: [
      'tour_loaded.count',
      'ist_lademenge/touren',
      'zustellungen/touren',
      'ruecklaeufer/touren',
      'ruecklaeufer',
      'ruecklaufquote',
    ],
  },
};
const oldTabsConfig: TabsConfig = {
  'touren.table.allgemein': {
    componentId: 'touren.table.allgemein',
    title: 'Übersicht',
    subTitle: 'Allgemein',
    sorting: [{ id: 'tourIdentifier', desc: true }],
    valueExpressionKeys: [
      'tour_loaded.count',
      'ist_lademenge/touren',
      'ht-produktivitaet',
      'ruecklaufquote',
      'netto-zustellzeit/touren',
      'bruttozustellzeit/touren',
    ],
  },
  'touren.table.htproduktivitaet': {
    componentId: 'touren.table.htproduktivitaet',
    title: 'HT-Produktivität',
    sorting: [{ id: 'ht-produktivität', desc: true }],
    valueExpressionKey: 'ht-produktivitaet',
    valueExpressionKeys: [
      'tour_loaded.count',
      'zustellungen/touren',
      'abholungen/touren',
      'abholungenProPS/touren',
      'psabgaben/touren',
      'psmitnahmen/touren',
      'netto-ht-zeit/touren',
      'ht-produktivitaet',
    ],
  },
  'touren.table.psproduktivitaet': {
    componentId: 'touren.table.psproduktivitaet',
    title: 'PS-Produktivität',
    sorting: [
      {
        id: 'ps-produktivität',
        desc: true,
      },
    ],
    valueExpressionKey: 'ps-produktivitaet',
    valueExpressionKeys: [
      'tour_loaded.count',
      'zustellungen/touren',
      'abholungen/touren',
      'abholungenProPS/touren',
      'psabgaben/touren',
      'psmitnahmen/touren',
      'netto-ps-zeit/touren',
      'ps-produktivitaet',
    ],
  },
  'touren.table.atgproduktivitaet': {
    componentId: 'touren.table.atgproduktivitaet',
    title: 'ATG-Produktivität',
    sorting: [
      {
        id: 'atg-produktivität',
        desc: true,
      },
    ],
    valueExpressionKey: 'atg-produktivitaet',
    valueExpressionKeys: [
      'tour_loaded.count',
      'zustellungen/touren',
      'abholungen/touren',
      'abholungenProPS/touren',
      'psabgaben/touren',
      'psmitnahmen/touren',
      'netto-atg-zeit/touren',
      'atg-produktivitaet',
    ],
  },
  'touren.table.produktivitaet': {
    componentId: 'touren.table.produktivitaet',
    title: 'Produktivität alt',
    sorting: [
      {
        id: 'produktivität',
        desc: true,
      },
    ],
    valueExpressionKey: 'produktivitaet',
    valueExpressionKeys: [
      'tour_loaded.count',
      'zustellungen/touren',
      'abholungen/touren',
      'abholungenProPS/touren',
      'psabgaben/touren',
      'psmitnahmen/touren',
      'nettozustellzeit/touren',
      'produktivitaet',
    ],
  },
  'touren.table.ruecklaufquote': {
    componentId: 'touren.table.ruecklaufquote',
    title: 'Rücklauf',
    sorting: [
      {
        id: 'rl-quote',
        desc: true,
      },
    ],
    valueExpressionKey: 'ruecklaufquote',
    valueExpressionKeys: [
      'tour_loaded.count',
      'ist_lademenge/touren',
      'zustellungen/touren',
      'ruecklaeufer/touren',
      'ruecklaeufer',
      'ruecklaufquote',
    ],
  },
};

export type UseTourenTableTabsOutput = {
  currentTab: DataTableComponentId;
  currentConfig: TabConfig;
  tabsConfig: TabsConfig;
  setCurrentTab: (tabID: DataTableComponentId) => void;
};

const useTourenTableTabs = (): UseTourenTableTabsOutput => {
  const [currentTab, setCurrentTab] = useState<DataTableComponentId>('touren.table.allgemein');
  const authService = useAuthContext();
  const filteredTabsConfig = authService.can(WellKnownPermission.ShowOldProduktivitaet) ? oldTabsConfig : tabsConfig;

  return {
    currentTab: currentTab,
    currentConfig: filteredTabsConfig[currentTab],
    tabsConfig: filteredTabsConfig,
    setCurrentTab,
  };
};

export default useTourenTableTabs;
