import moment from 'moment-timezone';

export type LoggerConfigType = {
  source: string;
  level: number;
};

export default class Logger {
  static LEVELS = {
    DEBUG: 1,
    INFO: 2,
    WARN: 3,
    ERROR: 4,
  };

  static DEFAULT_CONFIG: LoggerConfigType = {
    source: 'UNKNOWN',
    level: Logger.LEVELS.INFO,
  };
  config: LoggerConfigType;

  static getInstance(source: string, config: LoggerConfigType | null | undefined = null): Logger {
    // @ts-ignore
    // FIXME
    return new Logger({
      source,
      ...(config ? config : {}),
    });
  }

  constructor(config: LoggerConfigType | null | undefined = null) {
    this.config = { ...Logger.DEFAULT_CONFIG };
    if (config) {
      this.config = { ...this.config, ...config };
    }
  }

  debug(message: string, ...parameters: Array<any>) {
    this.log(Logger.LEVELS.DEBUG, message, parameters);
  }

  info(message: string, ...parameters: Array<any>) {
    this.log(Logger.LEVELS.INFO, message, parameters);
  }

  warn(message: string, ...parameters: Array<any>) {
    this.log(Logger.LEVELS.WARN, message, parameters);
  }

  error(message: string, ...parameters: Array<any>) {
    this.log(Logger.LEVELS.ERROR, message, parameters);
  }

  log(level: number, message: string, parameters: Array<any> = []) {
    if (level >= this.config.level) {
      let loggingFunction: (...messages: Array<string>) => void;
      let levelName: string;
      switch (level) {
        case Logger.LEVELS.ERROR:
          // eslint-disable-next-line no-console
          loggingFunction = console.error.bind(console);
          levelName = 'ERROR';
          break;
        case Logger.LEVELS.WARN:
          // eslint-disable-next-line no-console
          loggingFunction = console.warn.bind(console);
          levelName = 'WARN';
          break;
        case Logger.LEVELS.INFO:
          levelName = 'INFO';
          // eslint-disable-next-line no-console
          loggingFunction = console.info.bind(console);
          break;
        case Logger.LEVELS.DEBUG:
          levelName = 'DEBUG';
          // eslint-disable-next-line no-console
          loggingFunction = console.info.bind(console);
          break;
        default:
          levelName = 'UNKNOWN';
          // eslint-disable-next-line no-console
          loggingFunction = console.info.bind(console);
          break;
      }

      const prefix = `[${levelName} ${this.config.source}] - ${moment().toISOString()}:`;
      loggingFunction(`${prefix} ${message}`);
      parameters.forEach((p) => loggingFunction(`${prefix} ${JSON.stringify(p)}`));
    }
  }
}
