import GeoCoordinate from './GeoCoordinate';

export default class GeoCoordinateBounds {
  latitudeNorth: number;
  latitudeSouth: number;
  longitudeEast: number;
  longitudeWest: number;
  coordinatesCount: number;

  constructor(
    latitudeNorth: number,
    longitudeEast: number,
    latitudeSouth: number,
    longitudeWest: number,
    coordinatesCount: number = 0
  ) {
    this.latitudeNorth = latitudeNorth;
    this.longitudeEast = longitudeEast;
    this.latitudeSouth = latitudeSouth;
    this.longitudeWest = longitudeWest;
    this.coordinatesCount = coordinatesCount;
  }

  getCenter(): GeoCoordinate {
    return new GeoCoordinate({
      lat: (this.latitudeNorth + this.latitudeSouth) / 2,
      lng: (this.longitudeEast + this.longitudeWest) / 2,
    });
  }

  includes(coordinate: GeoCoordinate): boolean {
    return (
      coordinate.lat <= this.latitudeNorth &&
      coordinate.lat >= this.latitudeSouth &&
      coordinate.lng >= this.longitudeWest &&
      coordinate.lng <= this.longitudeEast
    );
  }

  static buildFromCoordinateList(coordinateList: Array<GeoCoordinate>): GeoCoordinateBounds {
    let minLat = 90;
    let minLng = 180;
    let maxLat = -90;
    let maxLng = -180;
    for (const coordinate of coordinateList) {
      minLat = Math.min(minLat, coordinate.lat);
      minLng = Math.min(minLng, coordinate.lng);
      maxLat = Math.max(maxLat, coordinate.lat);
      maxLng = Math.max(maxLng, coordinate.lng);
    }
    return new GeoCoordinateBounds(maxLat, maxLng, minLat, minLng, coordinateList.length);
  }

  getAsLngLatLikeArray(): [[number, number], [number, number]] {
    return [
      [this.longitudeWest, this.latitudeSouth],
      [this.longitudeEast, this.latitudeNorth],
    ];
  }
}
