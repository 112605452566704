import { KpiColorVariation } from '../hooks/useKpiIndicator';

export const compareValueExpressionWithBounds = (
  positiveDirection: 'up' | 'down',
  veData: number,
  bounds: { positive: number; negative: number }
): KpiColorVariation => {
  if (isNaN(veData)) {
    return KpiColorVariation.Warning;
  }

  switch (positiveDirection) {
    case 'down':
      if (veData <= bounds.positive) {
        return KpiColorVariation.Positive;
      } else {
        if (veData >= bounds.negative) {
          return KpiColorVariation.Negative;
        }
        return KpiColorVariation.Warning;
      }
    case 'up':
      if (veData >= bounds.positive) {
        return KpiColorVariation.Positive;
      } else {
        if (veData <= bounds.negative) {
          return KpiColorVariation.Negative;
        }
        return KpiColorVariation.Warning;
      }
  }
};
