import { useCallback, useEffect, useMemo, useState } from 'react';
import { OnChangeFn, ColumnOrderState } from '@tanstack/react-table';
import { DataTableComponentId, DataTableUserConfig } from '@data-table/data-table.types';
import useUserConfig from '@hooks/use-user-config-hook/use-user-config.hook';

const defaultOrder = [];

type UseDataTableColumnOrderStateOutput = [ColumnOrderState, OnChangeFn<ColumnOrderState>, () => void];

const useDataTableColumnOrderState = (
  componentId: DataTableComponentId,
  defaultState: ColumnOrderState = defaultOrder
): UseDataTableColumnOrderStateOutput => {
  const [columnOrder, setColumnOrder] = useState(defaultState);

  const defaultValue = useMemo(
    () => ({
      columnOrder: defaultState,
    }),
    [defaultState]
  );
  const [dataTableUserConfig, updateDataTableUserConfig] = useUserConfig<DataTableUserConfig>(
    componentId,
    defaultValue
  );

  useEffect(() => {
    if (!dataTableUserConfig?.columnOrder) return;
    setColumnOrder(dataTableUserConfig?.columnOrder);
  }, [dataTableUserConfig?.columnOrder]);

  const saveColumnOrder = useCallback(() => {
    updateDataTableUserConfig({
      ...dataTableUserConfig,
      columnOrder,
    });
  }, [dataTableUserConfig, columnOrder, updateDataTableUserConfig]);

  return [columnOrder, setColumnOrder, saveColumnOrder];
};

export default useDataTableColumnOrderState;
