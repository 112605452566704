import React, { FormEvent, HTMLAttributes, KeyboardEvent, useCallback } from 'react';
import style from './editable-text.module.scss';
import classNames from 'classnames';
import { TruncatedText } from '@components/truncated-text';

export type EditableTextProps = HTMLAttributes<HTMLSpanElement> & {
  initialContent: string;
  maxLength?: number;
  emptyContentAllowed?: boolean;
  contentEditable?: 'true' | 'false';
  onEdit: (content: string) => void;
};

export const EditableText = ({
  initialContent,
  maxLength,
  onEdit,
  className,
  contentEditable = 'true',
  ...htmlProps
}: EditableTextProps) => {
  const handleBlur = useCallback(
    (e: FormEvent<HTMLDivElement>) => {
      // characters only (no line breaks or other special characters including german characters)
      // and not empty string trimmed to max length
      const plaintext =
        new RegExp(/^[\w\-äöüÄÖÜß\s]+$/).test(e.currentTarget.textContent) &&
        e.currentTarget.textContent.trim().length > 0
          ? e.currentTarget.textContent
              .replace(/(\r\n|\n|\r)/gm, '')
              .trim()
              .slice(0, maxLength)
          : initialContent;
      e.currentTarget.textContent = plaintext;
      e.currentTarget.contentEditable = 'false';
      onEdit(plaintext);
    },
    [onEdit, maxLength, initialContent]
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' || e.key === 'Escape') {
        e.currentTarget.blur();
        e.preventDefault();
        e.stopPropagation();
      }
      const currentSelection = document.getSelection();
      const isTextContentSelected =
        currentSelection?.type === 'Range' && currentSelection?.focusNode === e.currentTarget;
      if (isTextContentSelected) {
        return;
      }
      if (maxLength && e.currentTarget.textContent.length >= maxLength) {
        if (
          e.key !== 'Backspace' &&
          e.key !== 'Delete' &&
          e.key !== 'ArrowLeft' &&
          e.key !== 'ArrowRight' &&
          e.ctrlKey !== true
        ) {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    },
    [maxLength]
  );

  const doubleClickHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (contentEditable === 'false') {
        return;
      }
      e.currentTarget.contentEditable = contentEditable;
      document.getSelection()?.selectAllChildren(e.currentTarget);
    },
    [contentEditable]
  );

  return (
    <TruncatedText
      className={classNames(style.editableText, className)}
      onDoubleClick={doubleClickHandler}
      suppressContentEditableWarning={true}
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
      spellCheck={false}
      {...htmlProps}>
      {initialContent}
    </TruncatedText>
  );
};
export default EditableText;
