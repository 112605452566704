import { DeliveryStatus } from '../constants/DeliveryStatus';
import { UndeliverableReasons } from '../../metrics2/models/enumerations/UndeliverableReason';
import { DeliveryStatusToUndeliveredReasonMap } from './getDeliveryBadge/GetDeliveryBadgeMappings';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import { personDeliveryRule, putDownOutside } from './getDeliveryBadge/GetDeliveryBadge';
import CustomerTrackingData from '../models/types/CustomerTrackingData';
import { BadgeAbbreviation, badgeDictionary } from '@components/delivery-badge/types';

export const mapItemStatus = (
  s: DeliveryStatus,
  finishedDelivery: FinishedDelivery,
  customer?: CustomerTrackingData
) => {
  if ((s as unknown as BadgeAbbreviation) === 'OE' || (s as unknown as BadgeAbbreviation) === 'NB') {
    return badgeDictionary[s];
  }

  if (personDeliveryRule.shouldApply(finishedDelivery)) {
    return personDeliveryRule.getValue(finishedDelivery, customer);
  } else if (putDownOutside.shouldApply(finishedDelivery)) {
    return putDownOutside.getValue(finishedDelivery);
  }
  switch (s) {
    case DeliveryStatus.Abgabe_PS:
      return badgeDictionary.PS;
    case DeliveryStatus.Ret_Mit:
      return badgeDictionary.RET;
    case DeliveryStatus.RL_N1:
      return badgeDictionary.N1;
    case DeliveryStatus.RL_N2:
      return badgeDictionary.N2;
    case DeliveryStatus.RL_N3:
      return badgeDictionary.N3;
    case DeliveryStatus.RL_N4:
      return badgeDictionary.N4;
    case DeliveryStatus.SEZ_Medea:
      return badgeDictionary.SEZ;
    case DeliveryStatus.RL_S:
      return badgeDictionary.S;
    case DeliveryStatus.RL_Storno:
      return {
        ...badgeDictionary.RL,
        description: 'Rücklauf',
        color: 'red',
      };
    case DeliveryStatus.RL_U:
      return badgeDictionary.U;
    case DeliveryStatus.RL_F:
      return badgeDictionary.F;
  }
  return UndeliverableReasons[DeliveryStatusToUndeliveredReasonMap[s]];
};
