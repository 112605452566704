import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import touren from './touren';
import sollLademenge from './lademenge-soll';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const expression = new QuotientValueExpression(
  sollLademenge,
  touren,
  'ø SOLL-Lademenge',
  null,
  null,
  null,
  getDefinitionByName('ø SOLL-Lademenge')
);

expression.setIdentifier('soll_lademenge/touren');

export default expression;
