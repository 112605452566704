import React, { Ref, useCallback, useEffect, useRef, useState } from 'react';

import AuthGuard from '../../auth/components/AuthGuard';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import { SearchInput } from '../components/SearchInput';
import ShipmentHistorySearch from './ShipmentHistorySearch';
import { isValidCode } from '../utils/ShipmentHistoryUtils';

import Styles from './NavigationBarDashboardShipmentSearch.module.scss';

const NavigationBarDashboardShipmentSearch: React.FC = () => {
  const [barcode, setBarcode] = useState('');
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);

  const ref: Ref<HTMLDivElement> = useRef(null);
  const searchInputRef: Ref<HTMLInputElement> = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('barcode')) {
      setBarcode(urlParams.get('barcode'));
      searchInputRef?.current.focus();
    }
  }, []);

  useEffect(() => {
    setIsCodeValid(isValidCode(barcode.trim()));
  }, [barcode]);

  useEffect(() => {
    setPopOverOpen(isCodeValid);
  }, [isCodeValid]);

  const closePopOver = useCallback((e?) => {
    // Do not close shipment window when clicking on the search input
    if (searchInputRef?.current?.id === e?.target?.id) {
      return;
    }
    setPopOverOpen(false);
  }, []);

  const openHistoryPopup = useCallback(() => {
    if (!isCodeValid) {
      return;
    }
    setPopOverOpen(true);
  }, [isCodeValid]);

  const onResetCallback = useCallback(() => {
    setBarcode('');
    searchInputRef.current?.focus();
    closePopOver();
  }, [closePopOver]);

  const onInputChangeCallback = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value);
  }, []);

  return (
    <AuthGuard requiredPermissions={[WellKnownPermission.ShipmentHistory]}>
      <div className={Styles.ShipmentSearchWrapper}>
        <div ref={ref}>
          <SearchInput
            ref={searchInputRef}
            id='ShipmentIdSearchDashboard'
            placeholder='Sendung suchen'
            value={barcode}
            onChange={onInputChangeCallback}
            onFocus={openHistoryPopup}
            onResetClick={onResetCallback}
          />
        </div>
        <ShipmentHistorySearch
          barcode={barcode}
          onClose={closePopOver}
          position='bottom-end'
          visible={popOverOpen}
          target={ref?.current}
        />
      </div>
    </AuthGuard>
  );
};

export default NavigationBarDashboardShipmentSearch;
