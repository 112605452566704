import BasePayload from './payloads/BasePayload';
import { DefaultType, MemberType } from '../../../utils/Model';
import { PlainObjectOf } from '../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export default class WebsocketEvent extends BasePayload<WebsocketEvent> {
  id: string | null | undefined;
  replyTo: string | null | undefined;
  name: string;
  payload: any | null | undefined;
  version: number | null | undefined;

  constructor(source: PlainObjectOf<WebsocketEvent>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<WebsocketEvent> {
    return undefined;
  }

  getMembers(): MemberType<WebsocketEvent> {
    return undefined;
  }
}
