import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.ZABulkyZugestellt,
  label: 'Bulky zugestellt',
  aggregation: AggregationType.sum,
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('Bulky zugestellt'),
});

export default new SingleValueExpression(metric);
