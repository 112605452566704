import React, { useCallback, useMemo } from 'react';
import { MdTimeline, MdArrowDropDown, MdArrowDropUp, MdHistory } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import ValueExpressionSelector from '../../valueexpressions/components/ValueExpressionSelector';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import PrimaryTimelineChart from '../components/charts/PrimaryTimelineChart';
import { PositiveDirection } from '../../metrics2/models/entities/PositiveDirection';
import formatNumber from 'format-number-with-string';
import { TreeTypeLabels, TreeTypes, TreeType } from '../../metrics2/models/enumerations/TreeType';
import { SidebarViewModes } from '../../app/models/enumerations/SidebarViewMode';
import ExportButton from '../components/ExportButton';
import TinyTimelineChart from '../components/charts/TinyTimelineChart';
import { LocationDetails } from '../components/LocationDetails';
import { DurationModes, Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { useOrganizationData } from '@hooks/use-organization-data-hook';
import { useToggleState } from '@hooks/use-toggle-state-hook';
import { OrgUnitPart } from '@legacy-modules/metrics2/models/queries/OrganizationQuery';
import { useMetricQuery } from '@hooks/use-metric-query-hook';
import { useOverviewExport } from '@other-components/use-overview-export-hook';
import OrgUnit from '@legacy-modules/metrics2/models/entities/organization/OrgUnit';
import {
  selectCompareText,
  selectComparisonMode,
  selectPrimaryText,
  selectSidebarSize,
  selectOverviewValueExpression,
  selectPrimaryFilter,
  selectCompareFilter,
  selectValueExpressionHistory,
  selectOverviewWeekdayFilter,
  selectOverviewOrgKey,
  overviewSlice,
} from '@redux';
import { useOverviewContext } from '@contexts/overview-context';

function OverviewStatsContainer() {
  const dispatch = useDispatch();
  const sidebarSize = useSelector(selectSidebarSize);
  const overviewRootOrgKey = useSelector(selectOverviewOrgKey);
  const primaryText = useSelector(selectPrimaryText);
  const compareText = useSelector(selectCompareText);
  const comparisonMode = useSelector(selectComparisonMode);
  const primaryValueExpression = useSelector(selectOverviewValueExpression);
  const primaryFilter = useSelector(selectPrimaryFilter);
  const compareFilter = useSelector(selectCompareFilter);
  const valueExpressionsHistory = useSelector(selectValueExpressionHistory);
  const weekdayFilter = useSelector(selectOverviewWeekdayFilter);
  const { hoverOrgKeyState } = useOverviewContext();
  const [hoverOrgKey] = hoverOrgKeyState;

  const primaryFormatter = primaryValueExpression?.getValueFormatter();

  const [tinyChartsVisible, toggleTinyChartsVisible] = useToggleState(true);

  const primaryOrgKeys = useMemo(() => {
    if (hoverOrgKey) {
      return [hoverOrgKey];
    } else if (primaryFilter?.orgKeys?.length > 0) {
      return primaryFilter.orgKeys;
    }
    return ['country:de'];
  }, [hoverOrgKey, primaryFilter]);

  const properties = useMemo<OrgUnitPart[]>(() => ['properties', 'children'], []);
  const [orgData] = useOrganizationData(primaryOrgKeys, properties, undefined, primaryFilter.from, primaryFilter.to);
  const rootOrgUnits = useMemo(
    () => primaryOrgKeys.map((orgKey) => orgData?.get(orgKey))?.filter((ou) => !!ou),
    [primaryOrgKeys, orgData]
  );
  const orgUnit = rootOrgUnits?.[0];
  const exportData = useOverviewExport(rootOrgUnits);
  const primaryDuration = useMemo(
    () =>
      ({
        from: primaryFilter.from,
        to: primaryFilter.to,
        weekdayFilter,
        mode: DurationModes.Custom,
      } as Duration),
    [primaryFilter, weekdayFilter]
  );
  const [_primaryData, _primaryDataLoading, _primaryDataError, getPrimaryDataByGroupingKey] = useMetricQuery(
    primaryOrgKeys,
    primaryValueExpression,
    primaryDuration
  );

  const [_compareData, _compareDataLoading, _compareDataError, getCompareDataByGroupingKey] = useMetricQuery(
    primaryOrgKeys,
    primaryValueExpression,
    primaryDuration,
    undefined,
    undefined,
    true,
    !!compareFilter
  );

  const [primaryValue, compareValue, diffClass, formattedDiffValue, formattedDiffPercentage] = useMemo(() => {
    const primaryValue = primaryOrgKeys.reduce((acc, orgKey) => {
      acc +=
        getPrimaryDataByGroupingKey(
          orgKey,
          `${primaryFilter.from.format('YYYY-MM-DD')}_${primaryFilter.to.format('YYYY-MM-DD')}`
        ) || 0;
      return acc;
    }, 0);
    const compareValue = compareFilter
      ? primaryOrgKeys.reduce((acc, orgKey) => {
          acc +=
            getCompareDataByGroupingKey(
              orgKey,
              `${compareFilter.from.format('YYYY-MM-DD')}_${compareFilter.to.format('YYYY-MM-DD')}`
            ) || 0;
          return acc;
        }, 0)
      : 0;
    const diffValue = primaryValue - compareValue;
    const diffClass =
      diffValue > 0 && primaryValueExpression.positiveDirection === PositiveDirection.up
        ? 'positive'
        : diffValue < 0 && primaryValueExpression.positiveDirection === PositiveDirection.down
        ? 'positive'
        : 'negative';
    let formattedDiffValue = primaryFormatter ? primaryFormatter(diffValue) : '';
    if (!formattedDiffValue.startsWith('-')) {
      formattedDiffValue = `+${formattedDiffValue}`;
    }
    let formattedDiffPercentage = '-';
    if (compareValue > 0) {
      const diffPercentage = (diffValue / compareValue) * 100.0;
      formattedDiffPercentage = formatNumber(diffPercentage || 0, '-#.###.##0,#0%');
      if (!formattedDiffPercentage.startsWith('-')) {
        formattedDiffPercentage = `+${formattedDiffPercentage}`;
      }
    }

    return [primaryValue, compareValue, diffClass, formattedDiffValue, formattedDiffPercentage];
  }, [
    primaryOrgKeys,
    primaryFilter,
    compareFilter,
    getPrimaryDataByGroupingKey,
    getCompareDataByGroupingKey,
    primaryValueExpression,
    primaryFormatter,
  ]);

  const multiOrgUnit = !hoverOrgKey && primaryOrgKeys?.length > 1;
  const showParentOrgUnitBtn = multiOrgUnit
    ? false
    : orgUnit?.['orgKey'] === primaryFilter.orgKey &&
      !!orgUnit?.['properties']?.['parentOrgKey'] &&
      orgUnit?.['orgKey'] !== overviewRootOrgKey;

  const orgUnitLabel = multiOrgUnit
    ? 'Mehrere Gebiete'
    : orgUnit
    ? `${getTreeTypeLabel(orgUnit.treeType)} ${(orgUnit.properties as OrgUnit['properties']).name}`
    : 'Deutschland';

  const onValueExpressionSelect = useCallback(
    (valueExpression: ValueExpression) => {
      dispatch(overviewSlice.actions.setPrimaryValueExpression(valueExpression));
    },
    [dispatch]
  );

  return (
    <div className='overview-stats-container' style={{ flexDirection: 'column' }}>
      <div className='head-section'>
        <div className='left'>
          <LocationDetails
            showParentOrgUnitBtn={showParentOrgUnitBtn}
            onUpdateOrgKey={() =>
              dispatch(
                overviewSlice.actions.updateOrgKey({
                  primaryOrgKey: orgUnit?.['properties']?.['parentOrgKey'],
                  compareOrgKey: null,
                  comparisonMode,
                })
              )
            }
            orgUnitLabel={orgUnitLabel}
          />
          <ValueExpressionSelector onValueSelect={onValueExpressionSelect} />
        </div>
        <div className='right'>
          {primaryValueExpression && !compareFilter && (
            <div className='head-values'>
              <div className='value-row primary-value'>
                <span className='value-label'>{primaryText}:</span>
                <span className='value-text'>{primaryFormatter(primaryValue)}</span>
              </div>
            </div>
          )}

          {primaryValueExpression && compareFilter && (
            <div className='head-values'>
              <div className='value-row primary-value'>
                <span className='value-label'>{primaryText}</span>
                <span className='value-text'>{primaryFormatter(primaryValue)}</span>
              </div>
              <div className='value-row compare-value'>
                <span className='value-label'>{compareText}</span>
                <span className='value-text'>{primaryFormatter(compareValue)}</span>
              </div>
              <div className='value-row diff-value'>
                <span className='value-label'>Differenz</span>
                <span className={`value-text ${diffClass}`}>{formattedDiffValue}</span>
              </div>
              <div className='value-row diff-value'>
                <span className='value-label'>&nbsp;</span>
                <span className={`value-text ${diffClass}`}>({formattedDiffPercentage})</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <ExportButton onExport={exportData} />
      <div className='chart-section' id='primary-chart'>
        <h3>
          <span className='section-icon'>
            <MdTimeline />
          </span>
          Zeitlicher Verlauf
        </h3>
        {primaryValueExpression && (
          <div className='primary-chart'>
            <PrimaryTimelineChart />
          </div>
        )}
        {!primaryValueExpression && <div className='no-selection'>Bitte Wert auswählen</div>}
      </div>
      <div className='chart-section' id='tiny-chart-list'>
        <h3>
          <span className='section-icon'>
            <MdHistory />
          </span>
          Zuletzt angesehene Werte
          <span className='toggle' onClick={() => toggleTinyChartsVisible()}>
            {tinyChartsVisible && (
              <span>
                ausblenden <MdArrowDropUp size='26' />
              </span>
            )}
            {!tinyChartsVisible && (
              <span>
                einblenden <MdArrowDropDown size='26' />
              </span>
            )}
          </span>
        </h3>

        <div className={`tiny-chart-list ${!tinyChartsVisible ? 'closed' : ''}`}>
          {valueExpressionsHistory.map((ve: ValueExpression) => {
            return (
              <TinyTimelineChart
                key={`history-tiny-timeline-${ve.identifier}`}
                className='tiny-chart'
                valueExpression={ve}
                tooltips={sidebarSize === SidebarViewModes.extended}
                onClick={() => onValueExpressionSelect(ve)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default OverviewStatsContainer;

function getTreeTypeLabel(treeType: TreeType): string {
  if (TreeTypeLabels[treeType] && treeType !== TreeTypes.hermes_org) {
    return TreeTypeLabels[treeType];
  }
  return '';
}
