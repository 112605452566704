import { $Keys } from 'utility-types';

/* eslint-disable camelcase */
export const TreeTypes = Object.freeze({
  hermes_org: 'hermes_org',
  rsteze: 'rsteze',
  zip: 'zip',
  'hermes_org/rsteze': 'hermes_org/rsteze',
  'hermes_org/zip': 'hermes_org/zip',
});

export enum TreeType {
  hermes_org = 'hermes_org',
  rsteze = 'rsteze',
  zip = 'zip',
  'hermes_org/rsteze' = 'hermes_org/rsteze',
  'hermes_org/zip' = 'hermes_org/zip',
}

export const TreeTypeLabels = {
  hermes_org: 'Area',
  rsteze: 'Richtungsschlüssel',
  zip: 'Postleitzahl',
  'hermes_org/rsteze': 'Tourenelement',
  'hermes_org/zip': 'Postleitzahl',
};

export const TreeTypeLabelDetails = {
  hermes_org: 'Karte nach Außendienst-Gebiets-Struktur bei Hermes',
  rsteze: 'Karte nach Transport-Logik von Sendungen',
  zip: 'Karte nach Postleitzahl-Gebieten',
  'hermes_org/rsteze': 'Tourenelement',
  'hermes_org/zip': 'Postleitzahl',
};
/* eslint-enable camelcase */
