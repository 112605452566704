import React from 'react';
import { Marker } from 'react-mapbox-gl';
import { Anchor } from 'react-mapbox-gl/lib/util/types';

type Props = {
  icon: string;
  iconSize: number[];
  hoverIcon?: string;
  coordinates: number[];
  anchor?: Anchor;
  offset?: number[];
  onClick?: () => void;
  onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
};

const ImageMarker: React.FC<Props> = ({
  icon,
  iconSize,
  hoverIcon,
  coordinates,
  anchor = 'bottom',
  offset = [0, 0],
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const _onMouseEnter: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (hoverIcon) {
      (e.target as HTMLInputElement).src = hoverIcon;
    }
    if (onMouseEnter) {
      onMouseEnter(e);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (hoverIcon) {
      (e.target as HTMLInputElement).src = icon;
    }
    if (onMouseLeave) {
      onMouseLeave(e);
    }
  };

  return (
    <Marker
      coordinates={coordinates}
      anchor={anchor}
      offset={offset}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}>
      <div className='image-marker' draggable={false}>
        <img
          src={icon}
          draggable={false}
          onClick={
            onClick
              ? onClick
              : () => {
                  /* ignore */
                }
          }
          style={{
            width: iconSize[0],
            height: iconSize[1],
          }}
          alt=''
        />
      </div>
    </Marker>
  );
};

export default ImageMarker;
