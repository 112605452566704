export class OHUtils {
  static PREFIX = 'oh';

  static isOH(orgKey: string): boolean {
    if (!orgKey) {
      return false;
    }
    return orgKey.startsWith(OHUtils.PREFIX);
  }
}
