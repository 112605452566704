import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavItem } from 'reactstrap';
import { ComparisonMode } from '../models/enumerations/ComparisonMode';
import OrgUnitNode from '../../metrics2/models/websocket/org/OrgUnitNode';
import { DateCompareSelector } from '../../navigation/components/DateCompareSelector';
import { DateRange } from '../../utils/dates/DateRange';
import { OrgMultiSelect } from '../../navigation/components/OrganizationMultiSelect';

import { WeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';
import {
  overviewSlice,
  selectCompareFilter,
  selectComparisonMode,
  selectOverviewWeekdayFilter,
  selectPrimaryFilter,
} from '@redux';

function NavigationBarOverviewContent() {
  const dispatch = useDispatch();
  const comparisonMode = useSelector(selectComparisonMode);
  const primaryFilter = useSelector(selectPrimaryFilter);
  const compareFilter = useSelector(selectCompareFilter);
  const weekdayFilter = useSelector(selectOverviewWeekdayFilter);

  const onOrganizationSelected = useCallback(
    (orgs: Array<OrgUnitNode>) => {
      dispatch(overviewSlice.actions.updateOrgKeys(orgs.map((o) => o.data.getIdentifier())));
    },
    [dispatch]
  );

  const onRangeSelected = useCallback(
    (primaryRange: DateRange, comparisonRange?: DateRange) => {
      dispatch(
        overviewSlice.actions.updateFilters({
          primaryFrom: primaryRange?.from?.toISOString(),
          primaryTo: primaryRange?.to?.toISOString(),
          compareFrom: comparisonRange?.from?.toISOString(),
          compareTo: comparisonRange?.to?.toISOString(),
          comparisonMode,
        })
      );
    },
    [dispatch, comparisonMode]
  );

  const onWeekdayFilterChange = useCallback(
    (weekdayFilter: WeekdaysFilter) => {
      dispatch(overviewSlice.actions.setWeekdayFilter(weekdayFilter));
    },
    [dispatch]
  );

  const primaryRange = {
    from: primaryFilter?.from,
    to: primaryFilter?.to,
  };

  const comparisonRange =
    compareFilter && comparisonMode === ComparisonMode.daterange
      ? {
          from: compareFilter?.from,
          to: compareFilter?.to,
        }
      : null;

  return (
    <>
      <NavItem key='org-select'>
        <OrgMultiSelect
          selectedOrgKeys={primaryFilter.orgKeys}
          onOrganizationSelect={onOrganizationSelected}
          hideMultiSelect={false}
        />
      </NavItem>
      <NavItem key='daterange-select'>
        <DateCompareSelector
          dayOnly={false}
          comparisonEnabled
          onSelectionChanged={onRangeSelected}
          primaryRange={primaryRange}
          compareRange={comparisonRange}
          onWeekdayFilterChange={onWeekdayFilterChange}
          weekdayFilter={weekdayFilter}
        />
      </NavItem>
    </>
  );
}

export default NavigationBarOverviewContent;
