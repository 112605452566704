import React, { ReactElement } from 'react';
import classNames from 'classnames';
import style from './data-table-column-footer.module.scss';
import { HeaderContext } from '@tanstack/react-table';
import { DataTableColumnMeta, DataTableDataType, TourIdentifier } from '@data-table/data-table.types';
import { ValueExpressionColumnFooterProps, withDataTableColumnFooter } from './with-data-table-column-footer-hoc';
import { Tooltip } from '@other-components/tooltip';

const ColumnFooter = <T,>({ column }: HeaderContext<DataTableDataType<T>, string | TourIdentifier>): ReactElement => {
  const isSorted = column.getIsSorted();
  const isPinned = column.getIsPinned();
  const meta = column.columnDef.meta as DataTableColumnMeta;
  return (
    <div
      data-testid='data-table-footer-cell'
      key={column.id}
      className={classNames(style.footerCell, {
        [style.sorted]: isSorted,
        [style.pinned]: isPinned,
      })}
      style={{ left: meta?.columnStyle?.left || 0 }}
    />
  );
};

const ValueExpressionColumnFooter = ({
  ctx,
  text,
  comparisonText,
  tooltipText,
  comparisonClassName,
}: ValueExpressionColumnFooterProps): ReactElement => {
  const { column } = ctx;
  const isSorted = column.getIsSorted();
  const isPinned = column.getIsPinned();
  const meta = column.columnDef.meta as DataTableColumnMeta;
  return (
    <div
      data-testid='data-table-footer-cell'
      key={column.id}
      className={classNames(style.footerCell, {
        [style.sorted]: isSorted,
        [style.pinned]: isPinned,
      })}
      style={{ left: meta?.columnStyle?.left || 0 }}>
      {meta.hideFooter ? null : (
        <div className={classNames(style.dataTableFooterCell)}>
          <div data-testid='data-table-footer-cell-value-text'>{text}</div>
          <div className={classNames(style.comparisonValue, comparisonClassName)}>
            <Tooltip tooltipText={tooltipText}>
              <div data-testid='data-table-footer-cell-comparison-text'>{comparisonText}</div>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default {
  ColumnFooter,
  ValueExpressionColumnFooter: withDataTableColumnFooter(ValueExpressionColumnFooter),
};
