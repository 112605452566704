import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import beanstandungenQuote from './beanstandungen-quote';
import abwicklungsmengeZsb from './abwicklungsmenge-zsb';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const expression = new QuotientValueExpression(
  beanstandungenQuote,
  abwicklungsmengeZsb,
  'SB-Quote (ZSB/VP+)',
  true,
  null,
  null,
  getDefinitionByName('SB-Quote (ZSB/VP+)')
);

expression.decimalPlaces = 3;
expression.chartRenderOptions.startYAxisOnZero = false;
expression.valueFormat = '-#.###.##0,##0%';
expression.setIdentifier('complaints.quote.count/complaints.quote.base');

export default expression;
