import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { MetricTypeKey } from '@contexts/value-expression-context/value-expressions/metric-type-keys';
import { OrgKey } from '@data-table/data-table.types';
import { WeekdaysFilter, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';
import MetricsFilter from '@legacy-modules/metrics2/models/filter/MetricsFilter';
import { ComparisonMode } from '@legacy-modules/overview/models/enumerations/ComparisonMode';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { updateFromRoutingParams } from './store';
import { AppRoutingParams } from '@legacy-modules/routing/models/AppRoutingParams';
import valueExpressionMap from '../common/contexts/value-expression-context/value-expressions';

const MAX_HISTORY_ENTRIES = 6;

export type OverviewState = {
  rootOrgKey: OrgKey;
  primaryFilter: MetricsFilter;
  compareFilter: MetricsFilter;
  weekdayFilter: WeekdaysFilter;
  valueExpressionIdentifier: ValueExpression;
  valueExpressionHistory: Array<ValueExpression>;
};

const initialState: OverviewState = {
  rootOrgKey: null,
  primaryFilter: new MetricsFilter().toObject() as unknown as MetricsFilter,
  compareFilter: null,
  weekdayFilter: defaultWeekdaysFilter,
  valueExpressionIdentifier: valueExpressionMap.get(MetricTypeKey.TARelevantSum),
  valueExpressionHistory: [],
};

export const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    setRootOrgKey: (state, action: PayloadAction<OrgKey>) => {
      state.rootOrgKey = action.payload;
    },
    updateOrgKeys: (state, action: PayloadAction<OrgKey[]>) => {
      state.primaryFilter.orgKeys = action.payload?.length > 0 ? action.payload : [state.rootOrgKey];
    },
    setWeekdayFilter: (state, action: PayloadAction<WeekdaysFilter>) => {
      state.weekdayFilter = action.payload;
    },
    updateFilters: (
      state,
      action: PayloadAction<{
        primaryFrom: string;
        primaryTo: string;
        compareFrom: string;
        compareTo: string;
        comparisonMode: ComparisonMode;
      }>
    ) => {
      const primaryFilter = {
        ...getMetricFilterDefaults(),
        ...state.primaryFilter,
        from: action.payload.primaryFrom,
        to: action.payload.primaryTo,
      };
      let compareFilter = {
        ...getMetricFilterDefaults(),
        ...state.compareFilter,
        from: action.payload.compareFrom,
        to: action.payload.compareTo,
      };
      if (
        action.payload.comparisonMode === ComparisonMode.daterange &&
        (!action.payload.compareFrom || !action.payload.compareTo)
      ) {
        compareFilter = null;
      } else if (action.payload.compareFrom && action.payload.compareTo) {
        compareFilter = {
          ...primaryFilter,
          from: action.payload.compareFrom,
          to: action.payload.compareTo,
        };
      } else if (action.payload.comparisonMode !== ComparisonMode.none) {
        compareFilter.from = action.payload.primaryFrom;
        compareFilter.to = action.payload.primaryTo;
      } else {
        compareFilter = null;
      }
      state.primaryFilter = new MetricsFilter(primaryFilter).toObject() as unknown as MetricsFilter;
      state.compareFilter = compareFilter
        ? (new MetricsFilter(compareFilter).toObject() as unknown as MetricsFilter)
        : null;
    },
    setPrimaryValueExpression: (state, action: PayloadAction<ValueExpression>) => {
      const valueExpression = action.payload;
      state.valueExpressionHistory = Array.from(
        new Set<ValueExpression>([state.valueExpressionIdentifier, ...state.valueExpressionHistory])
      ).slice(0, MAX_HISTORY_ENTRIES);
      state.valueExpressionIdentifier = valueExpression;
    },
    updateOrgKey: (
      state,
      action: PayloadAction<{ primaryOrgKey: OrgKey; compareOrgKey: OrgKey; comparisonMode: ComparisonMode }>
    ) => {
      const { primaryOrgKey: currentOrgKey, compareOrgKey, comparisonMode: filterMode } = action.payload;

      const primaryFilter = {
        ...getMetricFilterDefaults(),
        ...state.primaryFilter,
      };
      let compareFilter = {
        ...getMetricFilterDefaults(),
        ...state.compareFilter,
      };
      primaryFilter.orgKey = currentOrgKey;
      if (filterMode === ComparisonMode.organization && !compareOrgKey) {
        compareFilter = null;
      } else if (compareOrgKey) {
        compareFilter = { ...primaryFilter };
        compareFilter.orgKey = compareOrgKey;
      } else if (filterMode !== ComparisonMode.none) {
        compareFilter.orgKey = currentOrgKey;
      } else {
        compareFilter = null;
      }

      state.primaryFilter = new MetricsFilter(primaryFilter).toObject() as unknown as MetricsFilter;
      state.compareFilter = compareFilter
        ? (new MetricsFilter(compareFilter).toObject() as unknown as MetricsFilter)
        : null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFromRoutingParams, (state, action: PayloadAction<AppRoutingParams>) => {
      state.primaryFilter = action.payload.overview.primaryFilter;
      state.compareFilter = action.payload.overview.compareFilter;
      state.weekdayFilter = action.payload.overview.weekdayFilter;
    });
  },
});
const getMetricFilterDefaults = () =>
  Object.entries(new MetricsFilter().getDefaults()).reduce((c, [k, v]) => {
    return {
      ...c,
      [k]: v(),
    };
  }, {});
