import DataConnection from '../connections/DataConnection';
import { v4 as uuidv4 } from 'uuid';
import { lmaReduxStore } from '@redux/store';
import { dataconnectionSlice, selectDataConnections } from '@redux';
import DataConnectionsState from '../models/state/DataConnectionsState';

type DataConnectionsStateProvider = () => DataConnectionsState;

export default class DataConnectionsService {
  _registeredConnections: Map<string, DataConnection>;
  _connectionListerers: Map<string, Function>;
  dataConnectionsStateProvider: DataConnectionsStateProvider;

  constructor() {
    this.dataConnectionsStateProvider = () => selectDataConnections(lmaReduxStore.getState());
    this._registeredConnections = new Map();
    this._connectionListerers = new Map();
  }

  registerDataConnection(dataConnection: DataConnection<any>): string {
    const connectionId = uuidv4();

    if (!dataConnection) {
      return null;
    }

    lmaReduxStore.dispatch(dataconnectionSlice.actions.updateFromDataConnection({ connectionId, dataConnection }));

    this._registeredConnections.set(connectionId, dataConnection);
    const connectionListener = () => {
      lmaReduxStore.dispatch(dataconnectionSlice.actions.updateFromDataConnection({ connectionId, dataConnection }));
    };
    this._connectionListerers.set(connectionId, connectionListener);
    dataConnection.on(DataConnection.EVENT_DATA_CHANGED, connectionListener);
    dataConnection.on(DataConnection.EVENT_LOADING, connectionListener);
    dataConnection.once(DataConnection.EVENT_TERMINATED, this.onTerminatedConnection.bind(this, connectionId));
    return connectionId;
  }

  onTerminatedConnection(connectionId: string) {
    const dataConnection = this._registeredConnections.get(connectionId);
    const connectionListener = this._connectionListerers.get(connectionId);
    if (dataConnection) {
      dataConnection.removeListener(DataConnection.EVENT_DATA_CHANGED, connectionListener);
      dataConnection.removeListener(DataConnection.EVENT_LOADING, connectionListener);
      this._registeredConnections.delete(connectionId);
      this._connectionListerers.delete(connectionId);
    }
  }
}
