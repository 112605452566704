import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import zustellungen from './zustellungen';
import abholungen from './abholungen';
import atgAbholungen from './atg-abholungen';
import psMitnahmen from './ps-mitnahmen';
import psAbgaben from './ps-abgaben';
import nettoZustellzeitAlt from './netto-zustellzeit-alt';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const expression = new QuotientValueExpression(
  new SumValueExpression(
    zustellungen,
    new SumValueExpression(
      abholungen,
      new SumValueExpression(atgAbholungen, new SumValueExpression(psMitnahmen, psAbgaben))
    )
  ),
  nettoZustellzeitAlt,
  'Produktivität alt',
  false,
  null,
  null,
  getDefinitionByName('Produktivität alt')
);

expression.setIdentifier('produktivitaet');

export default expression;
