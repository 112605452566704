import { $Keys } from 'utility-types';

const DeliveryModes = Object.freeze({
  person: 'person',
  putDownOutside: 'putDownOutside',
  undeliverable: 'undeliverable',
  paketBox: 'paketBox',
  props: 'props',
  paketShop: 'paketShop',
  // obsolete
  oneTimeAuthorizationPutDown: 'oneTimeAuthorizationPutDown',
});

export type DeliveryModeType = $Keys<typeof DeliveryModes>;

export default DeliveryModes;
