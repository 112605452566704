import { PlainObjectOf } from '../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';
import Model, { DefaultType, MemberType } from '../../../utils/Model';

export default class PermissionGroup extends Model<PermissionGroup> {
  positivePermissions: Array<string>;
  negativePermissions: Array<string>;

  constructor(source: PlainObjectOf<PermissionGroup> = null, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  can(permission: string): number {
    const hasNegativeMatch = this.negativePermissions.map((r) => new RegExp(r)).some((r) => r.test(permission));
    const hasPositiveMatch = this.positivePermissions
      .map((r) => new RegExp(r))
      .some((r) => r.test(permission) || r.test(`+${permission}`));

    if (hasNegativeMatch) {
      return -1;
    } else if (hasPositiveMatch) {
      return 1;
    } else {
      return 0;
    }
  }

  getDefaults(): DefaultType<PermissionGroup> {
    return {
      positivePermissions: () => [],
      negativePermissions: () => [],
    };
  }

  getMembers(): MemberType<PermissionGroup> {
    return undefined;
  }
}
