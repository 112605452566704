import Model, { DefaultType, MemberType } from '../../../utils/Model';
import { TourDetailsMapOptions } from './tourDetails/TourDetailsMapOptions';
import { TourIdentifier } from './tourDetails/TourIdentifier';
import TimeRange from '../../../utils/dates/TimeRange';
import moment from 'moment-timezone';
import { TourDetailsRoutingParams } from '../../../routing/models/TourDetailsRoutingParams';
import { PlainObjectOf } from '../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export enum StopTypeType {
  Processed = 'processed',
  Open = 'open',
  Planned = 'planned',
}

export type StopType = {
  type: StopTypeType.Processed | StopTypeType.Open | StopTypeType.Planned;
  customerRef: string;
  stopNumber: number;
};

export default class TourDetailsState extends Model<TourDetailsState> {
  tourIdentifier: TourIdentifier;
  mapOptions: TourDetailsMapOptions;
  timeTravelTime: moment.Moment | null | undefined;
  timeHighlightRange: TimeRange | null | undefined;
  hoverMarkerRef: string | null | undefined;
  breadcrumb: Array<string>;
  listShowFinishedDeliveries: boolean;
  hoverOrgKey: string;
  contractorKey?: string;
  selectedStop?: StopType;

  constructor(source: PlainObjectOf<TourDetailsState>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getRoutingParams(): Partial<TourDetailsRoutingParams> {
    return {
      tourIdentifier: this.tourIdentifier.toObject(),
      mapOptions: this.mapOptions.toObject(),
      timeTravelTime: this.timeTravelTime && this.timeTravelTime.toISOString(),
      timeHighlightRange: this.timeHighlightRange && this.timeHighlightRange.toObject(),
      selectedStop: this.selectedStop,
      listShowFinishedDeliveries: this.listShowFinishedDeliveries,
      contractorKey: this.contractorKey,
    };
  }

  getDefaults(): DefaultType<TourDetailsState> {
    return {
      tourIdentifier: () => new TourIdentifier(),
      mapOptions: () => new TourDetailsMapOptions(),
      timeHighlightRange: () => null,
      timeTravelTime: () => null,
      selectedStop: () => null,
      hoverMarkerRef: () => null,
      listShowFinishedDeliveries: () => true,
      breadcrumb: () => [],
    };
  }

  getMembers(): MemberType<TourDetailsState> {
    return {
      tourIdentifier: (src) => new TourIdentifier(src),
      mapOptions: (src) => new TourDetailsMapOptions(src),
      timeTravelTime: (src) => (src ? moment(src) : null),
      timeHighlightRange: (src) => (src ? new TimeRange(src) : null),
    };
  }
}
