import Model, { DefaultType, MemberType } from '../../../utils/Model';
import createObjectMap from '../../../utils/createObjectMap';
import DataConnectionState from './DataConnectionState';
import { PlainObjectOf } from '../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export default class DataConnectionsState extends Model<DataConnectionsState> {
  dataConnections: {
    [key: string]: DataConnectionState<Object>;
  };

  constructor(source: PlainObjectOf<DataConnectionsState>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<DataConnectionsState> {
    return {
      dataConnections: () => ({}),
    };
  }

  getMembers(): MemberType<DataConnectionsState> {
    return {
      dataConnections: (src) =>
        // @ts-ignore
        // FIXME
        createObjectMap(src || {}, this.getClass().constructor),
    };
  }
}
