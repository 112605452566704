import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { MdSearch, MdInfoOutline, MdLayers, MdArrowDropDown } from 'react-icons/md';
import ValueExpression from '../models/valueexpressions/ValueExpression';
import MetricCategory from '../../metrics2/models/entities/MetricCategory';
import { sortBy } from 'lodash';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { Tooltip } from '../../common/components/Tooltip';
import { PopOver } from '../../common/components/PopOver';
import classNames from 'classnames';
import { useOnClickOutside } from '../../common/hooks/useOnClickOutside';
import { useKeyboardShortcut } from '../../navigation/hooks/useKeyboardShortcut';
import Styles from './ValueExpressionSelector.module.scss';
import { AvailableMetricCategories } from '@legacy-modules/metrics2/constants/metricCategories';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import SingleValueExpression from '../models/valueexpressions/SingleValueExpression';
import { MetricTypeKey } from '@contexts/value-expression-context/value-expressions/metric-type-keys';
import { useSelector } from 'react-redux';
import { selectOverviewValueExpression } from '@redux/overview.selectors';

type Props = {
  onValueSelect: (arg0: ValueExpression) => void;
};

const labels = {
  CATEGORY_OTHER_KEY: 'other',
  CATEGORY_OTHER_LABEL: 'Andere',
  CATEGORY_FAVORITES_KEY: 'favorites',
  CATEGORY_FAVORITES_LABEL: 'Favoriten',
  CATEGORY_FAVORITES_DESCRIPTION: 'Hier werden oft verwendete oder vorgeschlagene Werte angezeigt.',
};

type ValueExpressionListItemProps = {
  category: MetricCategory;
  valueExpression: ValueExpression;
  onOptionClick: (ve: ValueExpression) => void;
  onOptionHover: (ve: ValueExpression) => void;
  selected: boolean;
  level?: number;
  onOptionExpand: (ve: ValueExpression, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  expanded: boolean;
};

const ValueExpressionListItem = (props: ValueExpressionListItemProps) => {
  const {
    category,
    valueExpression,
    onOptionClick,
    selected,
    onOptionHover,
    level = 0,
    onOptionExpand,
    expanded,
  } = props;

  const description = valueExpression.getDescription();
  const children = valueExpression.getChildren() || [];

  const expandRef = useRef();
  const helpRef = useRef();

  return (
    <React.Fragment>
      <li
        key={`${category.key}:${valueExpression.key}`}
        onClick={() => onOptionClick(valueExpression)}
        className={classNames(Styles.ValueItem, Styles[`Level${level}`], {
          [Styles.Selected]: selected,
        })}
        onMouseEnter={() => onOptionHover(valueExpression)}>
        <div>{valueExpression.getLabel()}</div>
        <span className={Styles.ExpressionInfo}>
          {children.length > 0 && (
            <React.Fragment>
              <a href='#' onClick={(e) => onOptionExpand(valueExpression, e)} ref={expandRef}>
                <MdLayers />
              </a>
              <Tooltip placement='bottom' anchorElement={expandRef?.current} enterDelay={500}>
                Detailauswahl
              </Tooltip>
            </React.Fragment>
          )}
          {description && (
            <a href='#' ref={helpRef} onClick={(e) => e.preventDefault()}>
              <MdInfoOutline />
            </a>
          )}
        </span>
        {description && (
          <Tooltip placement='bottom' anchorElement={helpRef?.current}>
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Tooltip>
        )}
      </li>
      {expanded &&
        children.map((childValueExpression, i) => (
          <ValueExpressionListItem key={i} {...props} valueExpression={childValueExpression} level={level + 1} />
        ))}
    </React.Fragment>
  );
};

export type ExtendedModeType = 'quotient' | 'subtype' | 'simple';

const ValueExpressionSelector = (props: Props) => {
  const { onValueSelect } = props;
  const availableTypes = useValueExpressionContext();
  const currentValueExpression = useSelector(selectOverviewValueExpression);

  const [searchFilterText, setSearchFilterText] = useState('');
  const [open, setOpen] = useState(false);
  const [extendedMode, setExtendedMode] = useState<ExtendedModeType | null>(null);
  const searchFilter = useRef<HTMLInputElement | null>(null);
  const [selectedValueExpression, setSelectedValueExpression] = useState(null);
  const [expandedValueExpression, setExpandedValueExpression] = useState(null);
  const [selectedFirstValueExpression, setSelectedFirstValueExpression] = useState(null);

  const _onOptionExpand = useCallback(
    (value: ValueExpression, event) => {
      event.stopPropagation();
      event.preventDefault();
      const newValue = expandedValueExpression === value.key ? null : value.key;
      setExpandedValueExpression(newValue);
    },
    [expandedValueExpression]
  );

  const onSubmit = useCallback(() => {
    if (selectedFirstValueExpression) {
      onValueSelect(selectedFirstValueExpression);
      setOpen(false);
    } else {
      // @Todo: no value selected
    }
  }, [onValueSelect, selectedFirstValueExpression]);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const categories = useMemo(() => {
    const categories: Array<MetricCategory> = AvailableMetricCategories;
    return [
      new MetricCategory({
        key: labels.CATEGORY_FAVORITES_KEY,
        label: labels.CATEGORY_FAVORITES_LABEL,
        description: labels.CATEGORY_FAVORITES_DESCRIPTION,
      }),
      ...categories,
      new MetricCategory({
        key: labels.CATEGORY_OTHER_KEY,
        label: labels.CATEGORY_OTHER_LABEL,
      }),
    ];
  }, []);

  const categorizedValueExpressions = useMemo(() => {
    const filterValueExpressions = (input: Array<ValueExpression>): Array<ValueExpression> => {
      const text = searchFilterText.toLowerCase();
      return input
        ?.filter(
          (valueExpression) => valueExpression instanceof SingleValueExpression && !valueExpression?.metricType?.hidden
        )
        ?.filter((e) => {
          return e?.getLabel()?.toLowerCase()?.includes(text);
        });
    };

    const getFilteredFavoriteValueExpressions = (): Array<ValueExpression> => {
      const text = searchFilterText.toLowerCase();
      return [
        availableTypes.get(MetricTypeKey.TARelevantSum),
        availableTypes.get(MetricTypeKey.TAsum),
        availableTypes.get('soll_lademenge/touren'),
        availableTypes.get('ist_lademenge/touren'),
        availableTypes.get(MetricTypeKey.TourLoadedCount),
        availableTypes.get('touren'),
        availableTypes.get('zustellungen'),
        availableTypes.get('ruecklaeufer'),
        availableTypes.get('ruecklaufquote'),
        availableTypes.get('dvm_quote'),
        availableTypes.get('dbn_quote'),
        availableTypes.get('imageSignature_quote'),
        availableTypes.get('tourfahrtzeit'),
        availableTypes.get('tourfahrtzeit/touren'),
        availableTypes.get('tourstrecke'),
        availableTypes.get('tourstrecke/touren'),
        availableTypes.get('anfahrtszeit'),
        availableTypes.get('anfahrtszeit/touren'),
        availableTypes.get('anfahrtsstrecke'),
        availableTypes.get('anfahrtsstrecke/touren'),
        availableTypes.get('rueckfahrtzeit'),
        availableTypes.get('rueckfahrtzeit/touren'),
        availableTypes.get('rueckfahrtstrecke'),
        availableTypes.get('rueckfahrtstrecke/touren'),
        availableTypes.get('netto-ht-zeit'),
        availableTypes.get('netto-ht-zeit/touren'),
        availableTypes.get('netto-ps-zeit'),
        availableTypes.get('netto-ps-zeit/touren'),
        availableTypes.get('netto-atg-zeit'),
        availableTypes.get('netto-atg-zeit/touren'),
        availableTypes.get('netto-zustellzeit'),
        availableTypes.get('netto-zustellzeit/touren'),
        availableTypes.get('ht-produktivitaet'),
        availableTypes.get('ps-produktivitaet'),
        availableTypes.get('atg-produktivitaet'),
        availableTypes.get('tourfreigabe_zeitpunkt/touren'),
        availableTypes.get('zustellungen/einwohner'),
        availableTypes.get('zustellungen/stunde'),
        availableTypes.get('zustellungen/km'),
        availableTypes.get('einwohner/km'),
        availableTypes.get('lademenge/km'),
        availableTypes.get('lademenge/einwohner'),
        availableTypes.get('sb_verlust'),
      ].filter((e) => {
        return e?.getLabel()?.toLowerCase()?.includes(text);
      });
    };

    const getTypesAsValueExpressions = (): Array<ValueExpression> => {
      return Array.from(availableTypes.values());
    };

    const getFilteredTypeValueExpression = (): Array<ValueExpression> => {
      return filterValueExpressions(getTypesAsValueExpressions());
    };

    const categorizedMap: Map<string, Array<ValueExpression>> = new Map();
    categorizedMap.set(labels.CATEGORY_FAVORITES_KEY, getFilteredFavoriteValueExpressions());
    getFilteredTypeValueExpression().forEach((v) => {
      const category = v.category || labels.CATEGORY_OTHER_KEY;
      const categoryValues = categorizedMap.has(category) ? categorizedMap.get(category) : [];
      categoryValues.push(v);
      categorizedMap.set(category, categoryValues);
    });
    return categorizedMap;
  }, [availableTypes, searchFilterText]);

  const _onOptionHover = useCallback(
    (value: ValueExpression) => {
      if (!extendedMode) {
        setSelectedValueExpression(value.key);
      }
    },
    [extendedMode]
  );

  const _onExtendedModeClick = (mode: ExtendedModeType) => {
    setExtendedMode(mode);
  };

  const _onOptionClick = useCallback(
    (value: ValueExpression) => {
      if (extendedMode) {
        setSelectedFirstValueExpression(value);
      } else {
        setOpen(false);
        onValueSelect(value);
      }
    },
    [extendedMode, onValueSelect]
  );

  const renderOptions = useCallback(() => {
    return (
      <ul>
        {categories
          .filter((c) => categorizedValueExpressions.has(c.key) && categorizedValueExpressions.get(c.key).length > 0)
          .map((c, i) => {
            let valueExpressions = categorizedValueExpressions.get(c.key);
            // sort value-expressions except favorites
            if (c.key !== 'favorites') {
              valueExpressions = sortBy(valueExpressions, ['metricType.label']);
            }

            const categoryDescription = c.description;
            const categoryTooltipKey = 'category-selector-tooltip__' + c.key;
            return (
              <>
                <li className={classNames(Styles.Headline, Styles.Favorite)} key={'category-headline-' + c.key + i}>
                  {c.label}
                  {categoryDescription && (
                    <React.Fragment>
                      <span className={Styles.ExpressionInfo}>
                        <a href='#' id={categoryTooltipKey} onClick={(e) => e.preventDefault()}>
                          <MdInfoOutline />
                        </a>
                      </span>
                      <Tooltip placement='bottom' anchorElement={document.querySelector(`#${categoryTooltipKey}`)}>
                        <span
                          style={{ fontWeight: 'normal' }}
                          dangerouslySetInnerHTML={{
                            __html: categoryDescription,
                          }}
                        />
                      </Tooltip>
                    </React.Fragment>
                  )}
                </li>
                {valueExpressions.map((ve, i) => (
                  <ValueExpressionListItem
                    key={'first-level' + i}
                    onOptionExpand={_onOptionExpand}
                    expanded={ve.key === expandedValueExpression}
                    category={c}
                    valueExpression={ve}
                    onOptionClick={_onOptionClick}
                    onOptionHover={_onOptionHover}
                    selected={ve.key === selectedValueExpression}
                  />
                ))}
              </>
            );
          })}
      </ul>
    );
  }, [
    _onOptionClick,
    _onOptionExpand,
    _onOptionHover,
    categories,
    categorizedValueExpressions,
    expandedValueExpression,
    selectedValueExpression,
  ]);

  const emptySearchFilter = useCallback(() => {
    setSearchFilterText('');
  }, []);

  const toggleSelect = useCallback(() => {
    setOpen((v) => !v);
    setSearchFilterText('');
    setSelectedValueExpression(null);
    setExpandedValueExpression(null);
  }, []);

  const searchFilterChanged = useCallback((event) => {
    setSearchFilterText(event.target.value);
  }, []);

  useEffect(() => {
    if (open) {
      searchFilter.current?.focus();
    }
  }, [open]);

  const [ref, setRef] = useState(null);

  const closeWindowIfOpen = useCallback(() => setOpen(false), []);
  useOnClickOutside(ref, closeWindowIfOpen);
  useKeyboardShortcut({ code: 'Escape' }, closeWindowIfOpen);

  const getButtonForType = (type: ExtendedModeType, label: string) => (
    <Button
      size='sm'
      className={classNames(Styles.BtnMode, {
        [Styles.Selected]: extendedMode === type,
      })}
      onClick={() => _onExtendedModeClick(type)}>
      {label}
    </Button>
  );

  return (
    <div className={Styles.ValueExpressionSelector}>
      <div ref={setRef}>
        <div
          className={classNames('btn', Styles.BtnPrimary, 'btn-block', {
            [Styles.Empty]: !currentValueExpression,
          })}
          onClick={toggleSelect}>
          <div className={Styles.ValueExpressionLabel}>
            {currentValueExpression && (
              <span id='value-expression-infotag'>
                <MdInfoOutline size='18' />
                <Tooltip placement='bottom' anchorElement={document.querySelector('.value-expression-infotag')}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentValueExpression.getDescription(),
                    }}
                  />
                </Tooltip>
              </span>
            )}
            <span className={Styles.Label}>{currentValueExpression?.getLabel() || 'Bitte Wert auswählen...'}</span>
          </div>
          <MdArrowDropDown size={18} />
        </div>
      </div>
      {open && (
        <PopOver anchorElement={ref} visible={open} placement={'bottom-start'} onClose={closeWindowIfOpen}>
          <div
            className={classNames(Styles.SelectPopper, {
              'extended-mode': extendedMode,
            })}
            style={{
              display: 'flex',
              zIndex: 5,
            }}>
            <div className={Styles.SearchInput}>
              <div className={Styles.Icon}>
                <MdSearch />
              </div>
              <input
                ref={searchFilter}
                placeholder='Durchsuchen'
                value={searchFilterText}
                onChange={searchFilterChanged}
              />
              <div className={Styles.RemoveButton} onClick={emptySearchFilter}>
                <IoIosCloseCircleOutline />
              </div>
            </div>
            <div className={Styles.List}>{renderOptions()}</div>
            {extendedMode && (
              <div className={Styles.ExtendedTabs}>
                <ButtonGroup className={Styles.BtnGroup}>
                  {getButtonForType('simple', 'Einfach')}
                  {getButtonForType('quotient', 'Geteilt durch')}
                  {getButtonForType('subtype', 'Unterwert')}
                </ButtonGroup>
                {extendedMode === 'quotient' && renderOptions()}
                {extendedMode === 'subtype' && <div>Bitte Anzeigeschlüssel wählen</div>}
                <div>
                  <Button onClick={onSubmit}>OK</Button>
                  <Button onClick={onCancel}>ABBRECHEN</Button>
                </div>
              </div>
            )}
          </div>
        </PopOver>
      )}
    </div>
  );
};
export default ValueExpressionSelector;
