import classNames from 'classnames';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Marker, MarkerProps, useMap } from 'react-map-gl';
import MarkerDetails from '../../../../../modules/tour/models/entities/MarkerDetails';
import { ActiveMarkerIcon } from './active-marker-icon';
import style from './mapbox-marker.module.scss';

type MarkerSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
const getSizeByZoom = (zoom: number): MarkerSize => {
  if (!zoom) {
    return 'xs';
  }
  if (zoom < 10) {
    return 'xs';
  } else if (zoom < 12) {
    return 'sm';
  } else {
    return 'lg';
  }
};
export type MapboxMarkerBaseProps = MarkerProps &
  Pick<MarkerDetails, 'content'> & {
    icon?:
      | 'delivered_on'
      | 'delivered_off'
      | 'delivered_hover'
      | 'deliveries_on'
      | 'deliveries_off'
      | 'deliveries_hover'
      | 'driver_on'
      | 'driver_off'
      | 'driver_hover'
      | 'undeliverable_on'
      | 'undeliverable_off'
      | 'undeliverable_hover';
    size?: MarkerSize;
    active?: boolean;
    scaleSizeByZoom?: boolean;
    onMouseMove?: () => void;
    onMouseLeave?: () => void;
    onMouseEnter?: () => void;
  };

export function MapboxMarkerBase({
  icon,
  content,
  children,
  anchor = 'center',
  size: sizeProp,
  scaleSizeByZoom: scaleSize = false,
  color = 'black',
  active = false,
  onMouseMove,
  onMouseLeave,
  onMouseEnter,
  ...rest
}: MapboxMarkerBaseProps): ReactElement {
  const map = useMap();
  const [size, setSize] = useState(sizeProp);

  const onZoom = useCallback((e) => {
    setSize(getSizeByZoom(e.target?.getZoom()));
  }, []);

  useEffect(() => {
    if (!map) {
      return;
    }
    if (!scaleSize) {
      map.current?.off('zoom', onZoom);
      setSize(sizeProp ?? 'xs');
      return;
    }
    setSize(getSizeByZoom(map.current?.getZoom()));
    map.current?.on('zoom', onZoom);
    const _map = map.current;
    return () => {
      _map?.off('zoom', onZoom);
    };
  }, [map, size, scaleSize, onZoom, sizeProp]);

  return (
    <Marker anchor={anchor} {...rest}>
      <div
        className={classNames({
          [style.mapboxMarker]: !active,
          [style[size]]: !active,
          [style.disabled]: active,
        })}
        style={{ color }}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}>
        <>
          {active ? (
            <ActiveMarkerIcon
              text={typeof content === 'string' ? content : undefined}
              innerIcon={typeof content !== 'string' ? content : undefined}
              color={color}
            />
          ) : size === 'xl' || size === 'lg' ? (
            <>
              {icon && <img src={`/assets/markers/${icon}.png`} />}
              {children}
            </>
          ) : null}
        </>
      </div>
    </Marker>
  );
}

export default MapboxMarkerBase;
