import Model, { DefaultType, MemberType } from '../../../utils/Model';
import GeoLocation from '../../../geo/models/GeoLocation';
import moment from 'moment-timezone';
import { DeliveryItemTrackingData } from '../types/DeliveryItemTrackingData';
import { TourIdentifier } from '../state/tourDetails/TourIdentifier';
import { getProofOfDeliveryType, ProofOfDelivery } from '../enumerations/ProofOfDelivery';
import { ConfigKey, EnvironmentConfigurationService } from '../../../common/services/EnvironmentConfigurationService';
import { PlainObjectOf } from '../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';
import { DeliveryModeType } from '../enumerations/DeliveryMode';

export enum PutDownLocation {
  Mailbox = 'Mailbox',
  Bag = 'Bag',
  PermanentAuth = 'permanentAuthorization',
  OneTimeAuth = 'oneTimeAuthorization',
  BnkAuthorization = 'bnkAuthorization',
  UnknownAuthorization = 'unknownAuthorization',
}

const signatureUrl = EnvironmentConfigurationService.get(ConfigKey.HTTP_API_URL);

export default class FinishedDelivery extends Model<FinishedDelivery> {
  uuid: string;
  finishedAt: moment.Moment;
  deliveryMode: DeliveryModeType;
  undeliverableReason: string;
  putDownLocation: string;
  index?: number;
  displayableStopNumber?: number;
  location?: GeoLocation;
  routeStop: {
    lastLocationAt: moment.Moment;
    realDistanceFromLast: number;
    beelineDistanceFromLast: number;
  };
  customerDeliveries: {
    [key: string]: {
      customerRef: string;
      recipientPerson: string;
      notificationInfo: string | null | undefined;
      bnkNumber?: number;
      notifyCustomer: boolean;
      customerInfo: {
        rsteze: string;
        zipcode: string;
      };
      deliveryItemCount: number;
      returnDeliveryItemCount: number;
    };
  };
  deliveryItems: Array<DeliveryItemTrackingData>;
  returnDeliveryItems: Array<DeliveryItemTrackingData>;
  private _proofOfDelivery: ProofOfDelivery | null;
  entitled: string;
  differingAddress: {
    street: string;
    streetNr: string;
    zipcode: string;
    city: string;
  };

  constructor(
    source: any, // FIXME: make this a proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  get proofOfDelivery(): ProofOfDelivery {
    if (this._proofOfDelivery == null) {
      return ProofOfDelivery.imageSignature;
    }
    return this._proofOfDelivery;
  }

  set proofOfDelivery(proofOfDelivery: ProofOfDelivery) {
    this._proofOfDelivery = proofOfDelivery;
  }

  getTotalDeliveredItems(): number {
    return Object.values(this.customerDeliveries)
      .map((cd) => cd.deliveryItemCount)
      .reduce((a, b) => a + b, 0);
  }

  signatureURL(ti: TourIdentifier, tokenId: string): string {
    return `${signatureUrl}/signatures/${ti.orgId}/${ti.date.replace(/-/g, '')}/${ti.number}/${
      this.index
    }/signature?token=${tokenId}&type=${getProofOfDeliveryType(this.proofOfDelivery)}`;
  }

  isRenderingImage(): boolean {
    return getProofOfDeliveryType(this.proofOfDelivery) !== ProofOfDelivery.none;
  }

  getImageErrorReasonByType(proofOfDelivery: ProofOfDelivery): string {
    switch (proofOfDelivery) {
      case ProofOfDelivery.image:
        return 'Foto (Ablageort) kann noch nicht angezeigt werden';
      case ProofOfDelivery.none:
        return 'Keine Unterschrift notwendig';
      default:
        return 'Keine Unterschrift vorhanden';
    }
  }

  getDefaults(): DefaultType<FinishedDelivery> {
    return undefined;
  }

  getMembers(): MemberType<FinishedDelivery> {
    return {
      finishedAt: (src) => (src ? moment(src) : null),
      location: (src) => (src ? new GeoLocation(src) : null),
    };
  }
}
