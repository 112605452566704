import Model, { DefaultType, MemberType } from '../../../../utils/Model';
import OrgUnit from '../../entities/organization/OrgUnit';
import { NodeData } from '../../../../navigation/TreeMenu';
import { formatOrgUnitLabel } from '../../../../navigation/components/OrganizationMultiSelection';

export default class OrgUnitNode extends Model<OrgUnitNode> {
  public orgUnit: OrgUnit;
  children: Array<OrgUnitNode>;
  parent?: OrgUnitNode;

  constructor(
    source: any = null, // FIXME: set proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  get data(): NodeData {
    return {
      toString: () => formatOrgUnitLabel(this.orgUnit),
      getIdentifier: () => this.orgUnit.orgKey,
    };
  }

  getDefaults(): DefaultType<OrgUnitNode> {
    return undefined;
  }

  getMembers(): MemberType<OrgUnitNode> {
    return undefined;
  }
}
