import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import icon from '../img/signal_wifi_off-24px 1.svg';
import WebsocketApiService from '../../common/services/WebsocketApiService';
import Styles from './NetworkCheck.module.scss';
import { useApiContext } from '@contexts/api-context';

const errorText = 'Derzeit ist keine Verbindung zum Server vorhanden. Die angezeigten Daten sind daher nicht aktuell.';

const NetworkCheck = () => {
  const [show, setShow] = useState(false);
  const wsContext = useApiContext();

  useEffect(() => {
    const onSocketDisconnected = () => setShow(true);
    const onSocketConnected = () => setShow(false);

    wsContext.legacyService.eventEmitter.on(WebsocketApiService.EVENT_SOCKET_OPENED, onSocketConnected);
    wsContext.legacyService.eventEmitter.on(WebsocketApiService.EVENT_SOCKET_ERROR, onSocketDisconnected);
  }, [wsContext.legacyService.eventEmitter]);

  if (!show) return null;

  return (
    <Alert color='danger' className={Styles.Alert}>
      <img src={icon} alt='Disconnected' className={Styles.Icon} />
      <span className={Styles.Text}>{errorText}</span>
    </Alert>
  );
};

export default NetworkCheck;
