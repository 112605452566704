import numeral from 'numeral';
import 'numeral/locales/de';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import React, { useRef, useState } from 'react';
import { default as clsNames } from 'classnames';
import { Tooltip } from '../../common/components/Tooltip';
import Styles from './KpiCompareValue.module.scss';
import { formatValueExpressionValue } from '../utils/FormatValueExpression';
import { compareKpiValue } from '../../../refactoring/common/utils/compare-kpi-value';
import { LimitClassTypes } from '../types/AbstractLimit';

type Props = {
  primaryVE: ValueExpression;
  primaryData: string | number;
  compareData: string | number;
  zielwert?: LimitClassTypes;
  classNames?: {
    default?: string;
    positive?: string;
    negative?: string;
  };
  hoverText?: string;
};

numeral.locale('de');

const KpiCompareValue: React.FC<Props> = ({
  primaryVE,
  primaryData,
  compareData,
  classNames = {},
  hoverText,
  zielwert,
}: Props) => {
  const { positive = 'positive', negative = 'negative' } = classNames;
  const diffValue = numeral(primaryData).value() - numeral(compareData).value();
  // If there is no primary data, we don't want a compare value to be displayed
  const diffFormattedValue = formatValueExpressionValue(primaryVE, primaryData === null ? null : diffValue);

  const signedDiffFormattedValue = (() => {
    const formattedValueAsString = `${diffFormattedValue}`;

    if (
      formattedValueAsString.startsWith('+') ||
      formattedValueAsString.startsWith('-') ||
      formattedValueAsString === '0'
    ) {
      return diffFormattedValue;
    }
    return diffValue > 0 ? `+${formattedValueAsString}` : `-${formattedValueAsString}`;
  })();

  const comparisonResult = compareKpiValue(primaryVE, diffValue, zielwert);
  const diffClass: string = (() => {
    if (!primaryData) {
      return '';
    }

    if (comparisonResult === 'positive') {
      return positive;
    } else if (comparisonResult === 'negative') {
      return negative;
    }
    return '';
  })();

  const [hovering, setHovering] = useState(false);
  const ref = useRef();

  return (
    <>
      <span
        onMouseOver={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
        className={clsNames(classNames.default, diffClass)}
        ref={ref}>
        {signedDiffFormattedValue}
      </span>
      {primaryData !== null && (
        <Tooltip visible={hoverText && hovering} anchorElement={ref?.current} placement={'bottom'} size={'large'}>
          <div className={Styles.Tooltip}>{hoverText}</div>
        </Tooltip>
      )}
    </>
  );
};

export { KpiCompareValue };
