import { DraggableListProps } from '@components/draggable-list';
import { useDataTableContext } from '@data-table/contexts/data-table-context';
import { DataTableColumnMeta, DataTableMeta } from '@data-table/data-table.types';
import { DragOverEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import React, { ComponentType, CSSProperties, HTMLAttributes, useCallback } from 'react';

export type DataTableColumnHeadersProps = HTMLAttributes<HTMLDivElement>;
export type DataTableColumnHeadersComponentProps = HTMLAttributes<HTMLDivElement> &
  DraggableListProps & {
    columnConfig: CSSProperties;
  };

const withDataTableColumnHeaders =
  (Component: ComponentType<DataTableColumnHeadersComponentProps>) =>
  ({ ...props }: DataTableColumnHeadersProps) => {
    const { table } = useDataTableContext();
    const headers = table.getHeaderGroups()[0].headers;
    const onDragOver = useCallback(
      (e: DragOverEvent) => {
        const currentOrder: string[] = e.active.data.current?.sortable?.items;
        const dragIndex = currentOrder.indexOf(e.active?.id as string);
        const dropIndex = currentOrder.indexOf(e.over?.id as string);
        if (dragIndex === -1 || dropIndex === -1) {
          return;
        }
        table.setColumnOrder(arrayMove(currentOrder, dragIndex, dropIndex));
      },
      [table]
    );

    const onDragEnd = useCallback(() => {
      (table.options.meta as DataTableMeta).saveColumnOrder();
    }, [table.options.meta]);

    const columnConfig: CSSProperties = {
      gridTemplateColumns: headers
        .map((header) => {
          return (header.column.columnDef?.meta as DataTableColumnMeta)?.columnStyle?.gridColumn;
        })
        .join(' '),
    };

    return (
      <Component
        {...props}
        dndContextProps={{ onDragOver, onDragEnd }}
        direction='horizontal'
        sortableContextProps={{ items: headers }}
        columnConfig={columnConfig}
      />
    );
  };

export default withDataTableColumnHeaders;
