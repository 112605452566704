import React from 'react';
import { CustomGoal } from './CustomGoal';
import { TargetArea } from './TargetArea';
import ValueExpression from '../../../valueexpressions/models/valueexpressions/ValueExpression';
import { FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { DefaultGoal } from './DefaultGoal';

type Props = {
  valueExpression: ValueExpression;
  getValues: (id: string) => string;
  watch: <T>(id: string) => T;
  setValue: UseFormSetValue<any>;
  errors: FieldErrors<any>;
  trigger: UseFormTrigger<any>;
  reset;
};

export const KpiCustomThresholdSelectionFormBody = ({
  watch,
  valueExpression,
  getValues,
  setValue,
  errors,
  trigger,
  reset,
}: Props) => {
  const activeMode = watch('activeViewMode');

  if (valueExpression.positiveDirection !== 'custom') {
    return (
      <DefaultGoal
        watch={watch}
        valueExpression={valueExpression}
        getValues={getValues}
        setValue={setValue}
        errors={errors}
        trigger={trigger}
        reset={reset}
      />
    );
  }

  if (activeMode === 'limit') {
    return (
      <CustomGoal
        watch={watch}
        valueExpression={valueExpression}
        getValues={getValues}
        setValue={setValue}
        errors={errors}
        reset={reset}
        trigger={trigger}
      />
    );
  }

  return (
    <TargetArea watch={watch} valueExpression={valueExpression} setValue={setValue} errors={errors} trigger={trigger} />
  );
};
