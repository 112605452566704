import moment from 'moment';

const NON_KW_DURATIONS = [6, 27];

export function isThisYear(from: moment.Moment, to: moment.Moment) {
  if (!from) {
    throw new Error("You have to set a 'from' date");
  }
  if (!to) {
    throw new Error("You have to set a 'to' date");
  }
  const now = moment();
  return from.isSame(now, 'year') && to.isSame(now, 'year');
}
export function getPlainDateText(from: moment.Moment, to: moment.Moment, format: string = 'D. MMM YYYY') {
  if (!from) {
    throw new Error("You have to set a 'from' date");
  }
  if (!to) {
    throw new Error("You have to set a 'to' date");
  }

  if (from.isSame(to, 'date')) {
    return moment(from).format(format);
  } else {
    return `${moment(from).format(format)} - ${moment(to).format(format)}`;
  }
}
export function getHumanReadableDateText(from: moment.Moment, to: moment.Moment) {
  if (!from) {
    throw new Error("You have to set a 'from' date");
  }
  if (!to) {
    throw new Error("You have to set a 'to' date");
  }

  const getFromDate = () => moment(from).clone();
  const getToDate = () => moment(to).clone();
  const getDiffDays = () => Math.abs(getFromDate().diff(getToDate(), 'days'));

  const now = moment();
  const nowMinusOneDay = now.clone().subtract(1, 'day');

  // Ganzer Monat
  if (
    getFromDate().isSame(getToDate(), 'month') &&
    getFromDate().startOf('month').isSame(getFromDate(), 'day') &&
    getToDate().clone().endOf('month').isSame(getToDate(), 'day')
  ) {
    if (getFromDate().isSame(now, 'year')) {
      return { mode: 'month_this_year', text: moment(from).format('MMMM') };
    } else {
      return { mode: 'month_any_year', text: moment(from).format('MMMM YYYY') };
    }
  }
  // Letzte Woche
  if (
    now.clone().subtract(1, 'week').isSame(moment(from), 'week') &&
    now.clone().subtract(1, 'week').isSame(moment(to), 'week') &&
    moment(from).clone().startOf('isoWeek').isSame(moment(from), 'day') &&
    moment(to).clone().endOf('isoWeek').isSame(moment(to), 'day')
  ) {
    return { mode: 'last_week', text: 'Letzte Woche' };
  }

  // Ganze Woche
  if (
    getFromDate().isSame(getToDate(), 'week') &&
    getFromDate().startOf('isoWeek').isSame(getFromDate(), 'day') &&
    getToDate().endOf('isoWeek').isSame(getToDate(), 'day')
  ) {
    if (moment(from).isSame(now, 'year') || moment(to).isSame(now, 'year')) {
      return {
        mode: 'week_this_year',
        text: 'KW ' + moment(from).format('WW'),
      };
    } else {
      return {
        mode: 'week_any_year',
        text: 'KW ' + moment(from).format('WW YYYY'),
      };
    }
  }
  // Ganzes Quartal
  if (
    moment(from).isSame(moment(to), 'quarter') &&
    moment(from).clone().startOf('quarter').isSame(moment(from), 'day') &&
    moment(to).clone().endOf('quarter').isSame(moment(to), 'day')
  ) {
    return { mode: 'quarter', text: 'Q' + moment(from).format('Q YYYY') };
  }
  // Ganzes Jahr
  if (
    moment(from).isSame(moment(to), 'year') &&
    moment(from).clone().startOf('year').isSame(moment(from), 'day') &&
    moment(to).clone().endOf('year').isSame(moment(to), 'day')
  ) {
    return { mode: 'year', text: moment(from).format('YYYY') };
  }
  // Heute
  if (moment(from).isSame(now, 'day') && moment(to).isSame(now, 'day')) {
    return { mode: 'today', text: 'Heute' };
  }
  // Gestern
  if (
    moment(from).isSame(now.clone().subtract(1, 'days'), 'day') &&
    moment(to).isSame(now.clone().subtract(1, 'days'), 'day')
  ) {
    return { mode: 'yesterday', text: 'Gestern' };
  }
  // Diese Woche
  if (
    now.isSame(moment(from), 'week') &&
    now.isSame(moment(to), 'week') &&
    moment(from).clone().startOf('isoWeek').isSame(moment(to), 'day') &&
    moment(to).clone().endOf('isoWeek').isSame(moment(to), 'day')
  ) {
    return { mode: 'this_week', text: 'Diese Woche' };
  }
  // Letzte X Tage
  if (nowMinusOneDay.isSame(moment(to), 'day')) {
    if (now.clone().subtract(1, 'year').isSame(moment(from), 'week')) {
      return { mode: 'one_year', text: '1 Jahr' };
    }
    return {
      mode: 'last_days',
      text: 'Letzte ' + (moment(to).diff(moment(from), 'days') + 1) + ' Tage',
    };
  }
  // nur ein Tag in diesem Jahr
  if (moment(from).isSame(moment(to), 'day') && moment(from).isSame(now, 'year')) {
    return { mode: 'one_day', text: moment(from).format('D. MMM') };
  }
  // nur ein Tag
  if (moment(from).isSame(moment(to), 'day')) {
    return { mode: 'one_day', text: moment(from).format('D. MMM YYYY') };
  }
  // im diesem Jahr
  if (moment(from).isSame(moment(to), 'year') && now.isSame(moment(to), 'year') && moment(from).isSame(now, 'year')) {
    return {
      mode: 'this_year',
      text: moment(from).format('D. MMM') + ' - ' + moment(to).format('D. MMM'),
    };
  }
  // im gleichen Jahr
  if (moment(from).isSame(moment(to), 'year')) {
    return {
      mode: 'same_year',
      text: moment(from).format('D. MMM') + ' - ' + moment(to).format('D. MMM YYYY'),
    };
  }
  // Sonstiges
  return {
    mode: 'anything',
    text: moment(from).format('D. MMM YYYY') + ' - ' + moment(to).format('D. MMM YYYY'),
  };
}
