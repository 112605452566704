import toObject, { ToObjectConfiguration, ToObjectInterface } from './toObject/toObject';
import genericHydrateFrom from './hydrate/genericHydrateFrom';
import { CloneInterface } from './CloneInterface';

export type DefaultType<T> = {
  [P in keyof T]?: () => any;
};

export type MemberType<T> = {
  [P in keyof T]?: (...args: any[]) => any;
};

export default abstract class Model<T> implements ToObjectInterface, CloneInterface {
  abstract getDefaults(): DefaultType<T>;
  abstract getMembers(): MemberType<T>;

  clone() {
    // @ts-ignore
    return new this.constructor(this);
  }

  hydrateFrom(
    source: unknown | null | undefined = null,
    shallow: boolean = false,
    defaults: DefaultType<T> = null,
    members: MemberType<T> = null
  ) {
    genericHydrateFrom(
      this,
      source,
      members ? members : this.getMembers(),
      defaults ? defaults : this.getDefaults(),
      shallow
    );
  }

  getClass(): typeof Model {
    return this.constructor as typeof Model;
  }

  toObject(toObjectConfiguration: ToObjectConfiguration | null | undefined = null): any {
    return toObject(this, toObjectConfiguration);
  }

  shallowCopy(): Model<T> {
    // @ts-ignore
    return new (this.getClass())(this, true);
  }

  /**
   *
   * @param keys list of keys to shallow-copy, deep-path keys with '.' are allowed
   */
  shallowUpdateChildren(...keys: Array<string>) {
    keys.forEach((key: string) => {
      const pathInfo = this.getByPath(key);
      if (pathInfo && pathInfo.object instanceof Object) {
        const { object, container, containerKey } = pathInfo;
        if (object instanceof Model) {
          container[containerKey] = object.shallowCopy();
        } else if (object instanceof Array) {
          container[containerKey] = [...object];
        } else {
          container[containerKey] = { ...object };
        }
      }
    });
  }

  getByPath(key: string) {
    let object: Object | null | undefined = this;
    let container: Object = this;
    let containerKey: string = '';
    key.split('.').forEach((k: string) => {
      if (!object || !(object instanceof Object)) {
        object = undefined;
        return false;
      }
      container = object;
      object = object[k];
      containerKey = k;
    });
    if (object === undefined) {
      return undefined;
    }
    return { object, container, containerKey };
  }

  apply(args: Object | null | undefined = null): Model<T> {
    args = args || {};
    // @ts-ignore
    return new (this.getClass())({ ...this, ...args }, true);
  }
}
