export enum ICON_TITLES {
  eilservice = 'Eilservice',
  nachnahme = 'Nachnahme',
  colli = 'Mehrcollig',
  valuable = 'Wertsendung',
  wunschTag = 'Wunschtag',
  wunschzeitFenster = 'Wunschzeitfenster',
  luggage = 'DB Gepäck',
  identservice = 'Identservice',
  email = 'BNK: Email',
  number = 'BNK',
  verbotNachbarschaftsabgabe = 'Nachbarschaftsabgabeverbot',
  bulky = 'Bulky',
}
