import Model, { DefaultType, MemberType } from '../../../utils/Model';
import TourListEntry from './TourListEntry';

export default class TourList extends Model<TourList> {
  tours: Array<TourListEntry>;

  constructor(
    source: any = null, // FIXME: set proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  get distinctTours() {
    const tourListMap: Map<string, TourListEntry> = new Map(
      this.tours.filter((t) => t && t.identifier).map((t) => [`${t.identifier.orgId}:${t.identifier.number}`, t])
    );
    return [...tourListMap.values()];
  }

  getDefaults(): DefaultType<TourList> {
    return {
      tours: () => [],
    };
  }

  getMembers(): MemberType<TourList> {
    return {
      tours: (src) => (src ? src.map((e) => new TourListEntry(e)) : []),
    };
  }
}
