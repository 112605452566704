import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import { ValueType } from '@legacy-modules/metrics2/models/enumerations/ValueType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.ComplaintsReportedCountByType,
  label: 'SB nach Grund (Meldung)',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: 'down',
  category: 'complaints',
  valueType: ValueType.map,
  description:
    '<p>Kundenkontakte im Customer Service, bei denen es sich um Servicebeanstandungen handelt nach Grund</p>\n',
  definition: getDefinitionByName('SB (Meldung)'),
});

const expression = new SingleValueExpression(metric);

export default expression;
