import Model, { DefaultType, MemberType } from '../../../utils/Model';
import MetricType from '../entities/MetricType';
import createMapMap from '../../../utils/createMapMap';
import MetricCategory from '../entities/MetricCategory';
import { PlainObjectOf } from '../websocket/org/OrgUnitTreeRequestPayload';

export default class TypesState extends Model<TypesState> {
  availableTypes: Map<string, MetricType>;
  availableCategories: Map<string, MetricCategory>;

  constructor(source: PlainObjectOf<TypesState>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  static getMapKeysByType(type: string): Array<string | null | undefined> {
    if (!MetricType.mapKeys[type]) {
      return [null];
    }
    return MetricType.mapKeys[type];
  }

  static getMapKeyLabel(type: string, valueKey: string) {
    return MetricType.mapKeyLabels[type][valueKey];
  }

  static getMapKeyLabelMapByType(type: string) {
    return MetricType.mapKeyLabels[type];
  }

  getDefaults(): DefaultType<TypesState> {
    return {
      availableTypes: () => new Map(),
      availableCategories: () => new Map(),
    };
  }

  getMembers(): MemberType<TypesState> {
    return {
      availableTypes: (src) => createMapMap(src, MetricType),
      availableCategories: (src) => createMapMap(src, MetricCategory),
    };
  }
}
