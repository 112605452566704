import { combineReducers, configureStore, createAction } from '@reduxjs/toolkit';
import { appSlice } from './app.slice';
import { authSlice } from './auth.slice';
import { overviewSlice } from './overview.slice';
import { dashboardSlice } from './dashboard.slice';
import { dataconnectionSlice } from './dataconnection.slice';
import { tourDetailsSlice } from './tour-details.slice';
import { AppRoutingParams } from '@legacy-modules/routing/models/AppRoutingParams';

export const updateFromRoutingParams = createAction<AppRoutingParams>('updateFromRoutingParams');

const rootReducer = combineReducers({
  app: appSlice.reducer,
  auth: authSlice.reducer,
  overview: overviewSlice.reducer,
  dashboard: dashboardSlice.reducer,
  dataconnection: dataconnectionSlice.reducer,
  tourDetails: tourDetailsSlice.reducer,
});

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
}

export const lmaReduxStore = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
