import React, { useCallback, useMemo, useRef, useState } from 'react';
import Styles from './ContractorBadge.module.scss';
import { Tooltip } from '../../common/components/Tooltip';
import OrgNameDataProvider from '../../shipments/services/OrgNameDataProvider';
import ReactPlaceholder from 'react-placeholder';
import { get } from 'lodash';
import { useIsMounted } from '../hooks/useIsMounted';
import { injectContractorViewMode } from '../utils/OrgKeyUtils';
import { ContractorViewMode } from '../../navigation/constants/ContractorViewMode';

type Props = {
  children?: React.ReactNode;
  orgKey: string;
};

const orgNameDataProvider = new OrgNameDataProvider();

const ContractorBadge = ({ children, orgKey }: Props) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const lastFetchedOrgKey = useRef<string | null>(null);
  const [fetchedName, setFetchedName] = useState(null);
  const [loading, setLoading] = useState(false);

  const mounted = useIsMounted();

  const fetchOrgKey = useMemo(() => injectContractorViewMode(orgKey, ContractorViewMode.All), [orgKey]);

  const fetchName = useCallback(
    (visible) => {
      if (!visible) return;
      if (loading) return;
      // Cache old results if the orgKey itself is unchanged
      if (lastFetchedOrgKey.current === fetchOrgKey) return;
      setLoading(true);
      orgNameDataProvider
        .query([fetchOrgKey])
        .then((r) => {
          if (!mounted) return;
          setFetchedName(get(r, fetchOrgKey, null));
          lastFetchedOrgKey.current = fetchOrgKey;
        })
        .catch(() => {
          setFetchedName('Fehler beim Auflösen');
        })
        .finally(() => {
          if (!mounted) return;
          setLoading(false);
        });
    },
    [loading, mounted, fetchOrgKey]
  );

  return (
    <div className={Styles.ContractorBadge} ref={setRef}>
      <Tooltip onVisibilityChange={fetchName} placement={'top'} anchorElement={ref}>
        <ReactPlaceholder rows={1} type={'textRow'} ready={!loading}>
          {fetchedName ?? `Org-Name für ${fetchOrgKey} kann nicht aufgelöst werden.`}
        </ReactPlaceholder>
      </Tooltip>
      <span className={Styles.Text}>{children}</span>
    </div>
  );
};

export { ContractorBadge };
