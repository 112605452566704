import { $Keys } from 'utility-types';

export enum QueryPriorities {
  // primary data within the viewport
  high = 'high',
  // secondary data within the viewport
  normal = 'normal',
  // data that is necessary for this view, but not immediately above the fold or only visible in a hover state
  lazy = 'lazy',
  // data that is needed for a different view (preloading)
  low = 'low',
}

export const PriorityValues = {
  high: 4,
  normal: 3,
  lazy: 2,
  low: 1,
};

export function compareQueryPriorities(prio1: QueryPriority, prio2: QueryPriority) {
  return PriorityValues[prio1] - PriorityValues[prio2];
}

export type QueryPriority = $Keys<typeof QueryPriorities>;
