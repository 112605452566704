import { SharedTooltipSettingsContextProvider } from '@other-components/tooltip/shared-tooltip-settings-context';
import { DataTableContextProvider } from '@data-table/contexts/data-table-context';
import { DataTableComponentProps } from '@data-table/data-table.types';
import { css } from '@emotion/css';
import classNames from 'classnames';
import React, { useRef } from 'react';
import style from './data-table-base.module.scss';

const DataTableBase = <T = unknown,>({
  table,
  comparisonTable,
  columnOrder,
  header,
  tabs,
  body,
  pagination,
  style: styleProp,
}: DataTableComponentProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const tableWrapperRowLayoutStyle = css`
    grid-template-rows: ${!!header && '74px'} ${!!tabs && '120px'} 1fr ${!!pagination && '70px'};
  `;
  return (
    <SharedTooltipSettingsContextProvider
      layerOptions={{ container: containerRef?.current }}
      layerStyle={{ zIndex: 10 }}>
      <DataTableContextProvider
        table={table}
        comparisonTable={comparisonTable}
        columnOrder={columnOrder}
        tableWrapperRef={containerRef}>
        <div
          ref={containerRef}
          data-testid='data-table-base'
          className={classNames(style.tableWrapper, tableWrapperRowLayoutStyle, {
            [style.flexHeight]: pagination,
          })}
          style={styleProp}>
          {header}
          {tabs}
          {body}
          {pagination}
        </div>
      </DataTableContextProvider>
    </SharedTooltipSettingsContextProvider>
  );
};

export default DataTableBase;
