import * as React from 'react';
import MarkerDetails from '../models/entities/MarkerDetails';
import CustomerTrackingData from '../../tour/models/types/CustomerTrackingData';
import { MarkerType } from '../models/enumerations/MarkerType';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import DeliveryModes from '../../tour/models/enumerations/DeliveryMode';
import Color from 'color';
import { MdCheck, MdRefresh } from 'react-icons/md';
import { MarkerColor } from '../models/enumerations/MarkerColors';
import { map, capitalize } from 'lodash';

export const buildCustomerMarker = (
  customer: CustomerTrackingData,
  finishedDelivery: FinishedDelivery,
  itemCount: number
) => {
  const color = MarkerColor.byKindAndModeWithDefault(customer.location.provider, finishedDelivery.deliveryMode);

  return new MarkerDetails({
    markerRef: `customerLocation_${customer.customerRef}`,
    markerType: MarkerType.finishedDelivery,
    stopNumber: finishedDelivery.displayableStopNumber,
    color: new Color(color.color),
    location: customer.location,
    isClustered: itemCount > 1,
    content:
      itemCount > 1 ? (
        itemCount
      ) : finishedDelivery.deliveryMode === DeliveryModes.undeliverable ? (
        <MdRefresh color='white' size={24} />
      ) : (
        <MdCheck color='white' size={24} />
      ),
    tooltipCallback: () => (
      <span style={{ textTransform: 'capitalize', fontWeight: 400 }}>{`${
        customer.street ? formatAddress(customer.street) : 'Keine Adresse'
      } ${formatAddress(customer.streetNr)}`}</span>
    ),
  });
};

export const formatAddress = (...parts: string[]) => {
  return parts
    .filter((v) => v != null)
    .map((str) => map(str.split(' '), capitalize).join(' '))
    .join(' ')
    .trim();
};
