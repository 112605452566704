import React, { ReactElement, useCallback } from 'react';
import style from './hinweise-table-header.module.scss';
import DataTableHeader from '../data-table-header';
import { DragOverEvent, useDndContext } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Header } from '@tanstack/react-table';
import { MdDragHandle, MdLock } from 'react-icons/md';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { useDataTableContext } from '@data-table/contexts/data-table-context';
import { DraggableList } from '@components/draggable-list';
import { DataTableColumnMeta, DataTableDataType, DataTableMeta } from '@data-table/data-table.types';
import { TruncatedText } from '@components/truncated-text';

type HinweiseTableHeaderProps = {
  title: string;
  duration: Duration;
  disabled?: boolean;
  showWeekdaysFilter?: boolean;
  onDurationChange: (duration: Duration) => void;
};

const HinweiseTableHeader = ({
  title,
  duration,
  disabled = false,
  showWeekdaysFilter,
  onDurationChange,
}: HinweiseTableHeaderProps): ReactElement => {
  const { table, tableWrapperRef } = useDataTableContext();

  const isFullscreen = tableWrapperRef.current ? document.fullscreenElement === tableWrapperRef?.current : false;

  const headers = table.getHeaderGroups()?.[0]?.headers;

  const onDragOver = useCallback(
    (e: DragOverEvent) => {
      const currentOrder: string[] = e?.active?.data?.current?.sortable?.items || [];
      const dragIndex = currentOrder.indexOf(e.active?.id as string);
      const dropIndex = currentOrder.indexOf(e.over?.id as string);
      if (dragIndex === -1 || dropIndex === -1) {
        return;
      }
      table.setColumnOrder(arrayMove(currentOrder, dragIndex, dropIndex));
    },
    [table]
  );

  const onDragEnd = useCallback(() => {
    (table.options.meta as DataTableMeta).saveColumnOrder();
  }, [table.options.meta]);

  return (
    <DataTableHeader.Header>
      <DataTableHeader.TitleSlot>{title}</DataTableHeader.TitleSlot>
      <DataTableHeader.ActionsSlot>
        <DataTableHeader.FullscreenAction disabled={disabled} />
        <DataTableHeader.CsvExportAction disabled={disabled} />
        <DataTableHeader.SettingsAction
          disabled={disabled}
          className={style.settingsContainer}
          header={<div className={style.configurationHeader}>Tabelle konfigurieren</div>}>
          <DraggableList.List
            direction='vertical'
            sortableContextProps={{
              items: headers,
            }}
            dndContextProps={{
              onDragOver,
              onDragEnd,
            }}
            className={style.headerSettings}>
            {headers?.map((header) => (
              <HinweiseTableSortEntry header={header} key={header.id} />
            ))}
          </DraggableList.List>
        </DataTableHeader.SettingsAction>
      </DataTableHeader.ActionsSlot>
      <DataTableHeader.DateSelector
        container={isFullscreen ? undefined : 'body'}
        onDurationSelected={onDurationChange}
        selectedDuration={duration}
        showWeekdaysFilter={showWeekdaysFilter}
      />
    </DataTableHeader.Header>
  );
};

type HinweiseTableSortEntryProps = {
  header: Header<DataTableDataType, unknown>;
};
const HinweiseTableSortEntry = ({ header }: HinweiseTableSortEntryProps) => {
  const isPinned = !!header.column.getIsPinned();

  const label = (header.column.columnDef.meta as DataTableColumnMeta).exportValue;

  const dndCtx = useDndContext();

  const isDragged = dndCtx.active?.id === header?.id;

  return (
    <DraggableList.Item
      id={header.id}
      sortableArguments={{
        id: header.id,
        disabled: isPinned,
      }}
      pinned={isPinned}
      pinningEnabled={false}>
      <div className={style.sortEntryContent}>
        {isPinned ? <MdLock className={style.icon} /> : <MdDragHandle className={style.icon} />}
        <TruncatedText showTooltipIfCutOff={!isDragged}>{label}</TruncatedText>
      </div>
    </DraggableList.Item>
  );
};

export default HinweiseTableHeader;
