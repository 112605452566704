import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import atgAbholungen from './atg-abholungen';
import touren from './touren';

const expression = new QuotientValueExpression(
  atgAbholungen,
  touren,
  'Ø ATG Abholungen',
  false,
  undefined,
  undefined,
  getDefinitionByName('ATG Abholungen')
);

expression.setIdentifier('abholungenProPS/touren');

export default expression;
