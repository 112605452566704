import { DeliveryBadge } from '@legacy-modules/tour/utils/getDeliveryBadge/GetDeliveryBadge';

export type BadgeAbbreviation =
  | 'DB'
  | 'OE'
  | 'OE+'
  | 'NB'
  | 'IW'
  | 'MA'
  | 'REZ'
  | 'TU'
  | 'BV'
  | 'EV'
  | 'BK'
  | 'DV'
  | 'VM'
  | 'SEZ'
  | 'N'
  | 'N1'
  | 'N2'
  | 'N3'
  | 'N4'
  | 'NP'
  | 'A'
  | 'U'
  | 'S'
  | 'I'
  | 'IA'
  | 'ID'
  | 'IL'
  | 'IP'
  | 'V'
  | 'IN'
  | 'KG'
  | 'oGS'
  | 'Z'
  | 'F'
  | 'RL'
  | 'RET'
  | 'PS'
  | 'PRO'
  | 'PRI'
  | 'UNB';

// TODO: The badge entries derived from the UndeliverableReasons map are still missing here - this has yet to be unified,
// for that another key type than BadgeAbbreviation must be used, since that map has multiple entries using the same abbreviation as value.
export const badgeDictionary: Record<BadgeAbbreviation | 'default', DeliveryBadge> = {
  OE: {
    value: 'OE',
    description: 'Originalempfänger',
    color: '#13DA1C',
    type: 'normal',
    index: 0,
  },
  'OE+': {
    value: 'OE+',
    description: 'Originalempfänger mit Nachbarschaftsabgabe',
    color: '#13DA1C',
    type: 'normal',
    index: 1,
  },
  NB: {
    value: 'NB',
    description: 'Nachbar',
    color: '#13DA1C',
    type: 'normal',
    index: 2,
  },
  IW: {
    value: 'IW',
    description: 'in Wohnung',
    color: '#13DA1C',
    type: 'normal',
    index: 3,
  },
  MA: {
    value: 'MA',
    description: 'Mitarbeiter',
    color: '#13DA1C',
    type: 'normal',
    index: 4,
  },
  REZ: {
    value: 'REZ',
    description: 'Rezeption',
    color: '#13DA1C',
    type: 'normal',
    index: 5,
  },
  DV: {
    value: 'DV',
    description: 'Dauervollmacht',
    color: '#13DA1C',
    type: 'normal',
    index: 6,
  },
  TU: {
    value: 'TU',
    description: 'Quittungslose Sendung: Tüte',
    color: '#13DA1C',
    type: 'normal',
  },
  BV: {
    value: 'BV',
    description: 'BNK-Vollmacht',
    color: '#13DA1C',
    type: 'normal',
  },
  EV: {
    value: 'EV',
    description: 'Einmalvollmacht',
    color: '#13DA1C',
    type: 'normal',
    index: 8,
  },
  BK: {
    value: 'BK',
    description: 'Quittungslose Sendung: Briefkasten',
    color: '#13DA1C',
    type: 'normal',
  },
  VM: {
    value: 'VM',
    description: 'Vollmacht',
    color: '#13DA1C',
    type: 'normal',
  },
  SEZ: {
    value: 'SEZ',
    description: 'Sendungszustellung',
    color: '#13DA1C',
    type: 'normal',
  },
  N: {
    value: 'N',
    description: 'Nicht angetroffen',
    color: 'red',
    type: 'normal',
    index: 17,
  },
  N1: {
    value: 'N1',
    description: 'Nicht angetroffen - N1',
    color: 'red',
    type: 'normal',
    index: 17.1, // in some cases '17'
  },
  N2: {
    value: 'N2',
    description: 'Nicht angetroffen - N2',
    color: 'red',
    type: 'normal',
    index: 17.2, // in some cases '17.2'
  },
  N3: {
    value: 'N3',
    description: 'Nicht angetroffen - N3',
    color: 'red',
    type: 'normal',
    index: 17.3, // in some cases '17.2'
  },
  N4: {
    value: 'N4',
    description: 'Nicht angetroffen - N4',
    color: 'red',
    type: 'normal',
  },
  NP: {
    value: 'NP',
    description: 'Nicht am Paketshop abgeholt',
    color: '#CCC',
    type: 'normal',
  },
  A: {
    value: 'A',
    description: 'Annahmeverweigerung - A',
    color: 'var(--black0)',
    type: 'normal',
  },
  U: {
    value: 'U',
    description: 'Rücklauf - Irrläufer',
    color: 'red',
    type: 'normal',
  },
  S: {
    value: 'S',
    description: 'Rücklauf - Schadensfall',
    color: 'red',
    type: 'normal',
  },
  I: {
    value: 'I',
    description: 'Identservice',
    color: '#6DD9FF',
    type: 'normal',
  },
  IA: {
    value: 'IA',
    description: 'Identservice Alter - IA',
    color: '#Identservice Dokument - ID',
    type: 'normal',
  },
  ID: {
    value: 'ID',
    description: 'Identservice Dokument - ID',
    color: '#6DD9FF',
    type: 'normal',
  },
  IL: {
    value: 'IL',
    description: 'Identservice Lichtbild - IL',
    color: '#6DD9FF',
    type: 'normal',
  },
  IP: {
    value: 'IP',
    description: 'Identservice PIN-Code',
    color: '#6DD9FF',
    type: 'normal',
  },
  V: {
    value: 'V',
    description: 'Verlust',
    color: '#C00000',
    type: 'normal',
  },
  IN: {
    value: 'IN',
    description: 'Identservice Name',
    color: '#CCC',
    type: 'normal',
  },
  KG: {
    value: 'KG',
    description: 'Empfänger hat kein Bargeld - KG',
    color: '#FF7503',
    type: 'normal',
  },
  oGS: {
    value: 'oGS',
    description: 'Rücklauf ohne Gegenscannung - oGGS',
    color: 'red',
    type: 'normal',
  },
  Z: {
    value: 'Z',
    description: 'Zeitmangel - Z',
    color: 'red',
    type: 'normal',
  },
  F: {
    value: 'F',
    description: 'Rücklauf Falsche Adresse',
    color: 'red',
    type: 'normal',
  },
  RL: {
    value: 'RL',
    description: 'Paketshop Rücklauf', // in some cases 'Rücklauf'
    color: '#FFA500', // in some cases 'red'
    type: 'normal',
    index: 18,
  },
  RET: {
    value: 'RET',
    description: 'Retoure',
    color: '#228B22',
    type: 'normal',
    index: 21,
  },
  PS: {
    value: 'PS',
    description: 'PaketShop',
    color: '#79B0FF',
    type: 'normal',
    index: 20,
  },
  PRO: {
    value: 'PRO',
    description: 'ProPS',
    color: 'var(--black0)',
    type: 'normal',
    index: 19,
  },
  PRI: {
    value: 'PRI',
    description: 'PriPS',
    color: '#79B0FF',
    type: 'normal',
    index: 20,
  },
  DB: {
    value: 'DB',
    description: 'DB Kuriergepäck',
    color: '#FFF',
    type: 'normal',
    index: 22,
  },
  UNB: {
    value: 'UNB',
    description: '',
    color: '#CCC',
    type: 'normal',
  },
  default: {
    value: '-',
    color: '#CCC',
    description: 'nicht definiert',
    type: 'normal',
  },
};
