export default class PromisableEvent {
  listenerPromises: Promise<any>[] = [];
  rejected: boolean = false;
  rejectedArgs: Array<string> | null | undefined = null;

  createEventPromise(): Promise<any> {
    if (this.rejected) {
      return Promise.reject(new Error(this.rejectedArgs.join(', ')));
    }
    return Promise.all(this.listenerPromises);
  }

  reject(...rejectedArgs: any[]) {
    this.rejected = true;
    this.rejectedArgs = rejectedArgs;
  }

  addListenerPromise(promise: Promise<any>) {
    this.listenerPromises.push(promise);
  }
}
