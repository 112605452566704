import React, { useCallback, useMemo } from 'react';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import CustomerTrackingData from '../models/types/CustomerTrackingData';
import '../assets/signaturesDeliveryItem.scss';
import { MdRoom } from 'react-icons/md';
import '../assets/stopDetail.scss';
import DeliveryItemsList from './DeliveryItemsList';
import { SignatureImageContainer } from '../containers/SignatureImageContainer';
import { Warning } from './FinishedDeliveryStopDetail';
import classNames from 'classnames';
import { IRawAnomalyResponse } from '../../dashboard/anomalies/interfaces/IRawAnomalyResponse';
import { AnomalyReason } from '../../dashboard/anomalies/utils/AnomalyUtils';
import { knownAnomalyFilter } from '../utils/KnownAnomalyFilter';
import { countBy } from 'lodash';
import { Address } from '@other-components/stop-details/address';
import { tourDetailsSlice } from '@redux/tour-details.slice';
import { useDispatch } from 'react-redux';

type Props = {
  finishedDelivery: FinishedDelivery;
  url: string | null;
  customers: Array<CustomerTrackingData>;
  shipmentIdToCustomerMap: Map<string, string>;
  anomalies: IRawAnomalyResponse[];
};

const SignaturesCustomerRow: React.FC<Props> = (props) => {
  const { finishedDelivery, customers, url, shipmentIdToCustomerMap, anomalies } = props;

  const dispatch = useDispatch();

  const _onStopClick = useCallback(
    (stopNo: number) => {
      dispatch(tourDetailsSlice.actions.setSelectedStopNumber(stopNo));
    },
    [dispatch]
  );

  const transformedAnomalies = useMemo(() => {
    if (!anomalies) return [];

    const stopRefs = anomalies.filter((a) => a.tourStopRefs.flatMap((ref) => ref.stopNumber));

    const knownAnomalies = stopRefs.filter(knownAnomalyFilter);

    const groupedByType = countBy(knownAnomalies, 'anomalyType');

    return Object.entries(groupedByType).map(([name, count]) => ({
      name,
      count,
    }));
  }, [anomalies]);

  if (customers.length === 0) {
    return null;
  }

  const { street, zipcode, streetNr, lastName } = customers[0];

  const isNeighbor: boolean = Object.values(finishedDelivery.customerDeliveries).some(
    (cd) => cd.recipientPerson === 'nextDoor'
  );
  const noAddressAvailable = !finishedDelivery.differingAddress && !street && !streetNr && !lastName;
  const displayedAddressForDifferingAddress = isNeighbor ? finishedDelivery.differingAddress : customers[0];

  return (
    <React.Fragment key={`${zipcode}-key`}>
      <tr
        className={classNames('imatr', {
          hasWarnings: !!transformedAnomalies?.length,
        })}>
        <td style={{ width: '50px', position: 'unset' }} className={'td-stop td-signature bold-font'} valign='top'>
          <span onClick={() => _onStopClick(finishedDelivery.displayableStopNumber)}>
            {finishedDelivery.displayableStopNumber}
          </span>
          {transformedAnomalies?.length > 0 && (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: '82px',
                right: '10px',
                display: 'flex',
              }}>
              {transformedAnomalies.map((a, i) => (
                <div style={{ paddingRight: 10 }} key={`warning-${i}`}>
                  <Warning>
                    {AnomalyReason[a.name]} {a.count > 1 && <>({a.count})</>}
                  </Warning>
                </div>
              ))}
            </div>
          )}
        </td>
        <td className={'td-time td-signature bold-font'} valign='top'>
          {finishedDelivery.finishedAt?.format('HH:mm')}
        </td>
        <td className={'td-gps td-signature'} valign='top'>
          <div>
            {finishedDelivery.location && finishedDelivery.location.provider.toLowerCase() === 'gps' && (
              <MdRoom className='icon' />
            )}
          </div>
        </td>
        <td valign='top' className={'td-customer td-signature'}>
          {noAddressAvailable && <div>Keine Adresse vorhanden</div>}
          {finishedDelivery.differingAddress ? (
            <Address
              lastName={finishedDelivery.entitled?.substring(finishedDelivery.entitled?.indexOf(' ') + 1)}
              {...displayedAddressForDifferingAddress}
            />
          ) : (
            <Address {...customers[0]} />
          )}
        </td>
        <td valign='top' className={'td-signature '}>
          <SignatureImageContainer
            lazyloadRoot={document.querySelector('.signatures-main-container')}
            finishedDelivery={finishedDelivery}
            url={url}
          />
        </td>
        <td valign='top' className={'td-signature '}>
          <span className='entitled-signatures'>
            {finishedDelivery.entitled
              ? finishedDelivery.entitled.substring(finishedDelivery.entitled.indexOf(' ') + 1).toLowerCase()
              : ''}
          </span>
        </td>
        <td valign='top' className={'td-signature'}>
          <DeliveryItemsList
            finishedDelivery={finishedDelivery}
            customers={customers}
            shipmentIdToCustomerMap={shipmentIdToCustomerMap}
            anomalies={anomalies}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

export default SignaturesCustomerRow;
