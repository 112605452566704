import React from 'react';
import Styles from './ThresholdValueSelector.module.scss';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { ValueExpressionInput } from '../../common/components/ValueExpressionInput';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';

export enum ThresholdValueColorVariant {
  Positive = 'positive',
  Negative = 'negative',
}

type Props = {
  colorVariant: ThresholdValueColorVariant;
  onValueChange?: (value: number) => void;
  value?: number;
  valueExpression: ValueExpression;
  minValue?: number;
  maxValue?: number;
  onBlur?: () => void;
  indicatorPosition?: 'left' | 'right';
  name?: string;
  tabIndex?: number;
  disabled?: boolean;
};

const ThresholdValueSelector = React.forwardRef<NumberFormat<Props>, Props>((props, ref) => {
  const {
    colorVariant,
    onValueChange,
    value,
    valueExpression,
    indicatorPosition = 'left',
    name,
    tabIndex,
    ...other
  } = props;

  return (
    <div className={Styles.ThresholdValueSelector}>
      {indicatorPosition === 'left' && <div className={classNames(Styles.Indicator, Styles[colorVariant])} />}
      <ValueExpressionInput
        value={value}
        className={Styles.Input}
        valueExpression={valueExpression}
        onValueChange={onValueChange}
        {...other}
        ref={ref}
        name={name}
        tabIndex={tabIndex}
      />
      {indicatorPosition === 'right' && <div className={classNames(Styles.Indicator, Styles[colorVariant])} />}
    </div>
  );
});
ThresholdValueSelector.displayName = 'ThresholdValueSelector';
export default ThresholdValueSelector;
