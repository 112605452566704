import { ViewMode as NewViewMod } from '@redux/app.slice';
export enum ViewMode {
  loading = 'loading',
  overview = 'overview',
  shipment = 'shipment',
  tour = 'tour',
  tourDetails = 'tourDetails',
  dashboard = 'dashboard',
}

export type ViewModeKey = 'dashboard' | 'overview';

export const ViewModeCategory: ViewModeCategoryType = {
  overview: {
    label: 'Übersicht',
    details: 'Informationen in Diagrammen und geographische Verteilung auf der Karte',
    key: 'overview',
  },
  dashboard: {
    label: 'Dashboard',
    details: 'Kennzahlen und deren Entwicklung auf ZSB-Ebene',
    key: 'dashboard',
  },
};

type ViewModeCategoryType = {
  [k in ViewModeKey]: {
    label: string;
    details: string;
    key: k;
  };
};

export const getMappedViewMode = (viewMode: NewViewMod) => {
  if (viewMode === 'overview') {
    return ViewModeCategory.overview;
  } else {
    return ViewModeCategory.dashboard;
  }
};
