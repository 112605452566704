import React, { useState } from 'react';
import Styles from './Profile.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Tooltip } from './Tooltip';
import { selectLoggedIn, selectUserDetails } from '@redux/auth.selectors';

type Props = {};

const Profile: React.FC<Props> = () => {
  const { username, displayName } = useSelector(selectUserDetails);
  const loggedIn = useSelector(selectLoggedIn);
  const status = loggedIn ? 'online' : 'offline';

  const [firstName, lastName] = displayName
    .split(', ')
    .map((s) => s.toUpperCase())
    .reverse();

  const [ref, setRef] = useState<HTMLDivElement>();

  return (
    <div className={Styles.Profile}>
      <div className={Styles.Circle} ref={setRef}>
        <span className={Styles.Initials}>{`${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`}</span>
      </div>
      <div className={classNames(Styles.Status, Styles[status])} />
      <Tooltip anchorElement={ref} size={'large'}>
        <div className={Styles.Tooltip}>
          Eingeloggt als {displayName} ({username})
        </div>
      </Tooltip>
    </div>
  );
};

export { Profile };
