import React from 'react';
import { ImagePlaceholder } from './ImagePlaceholder';
import Styles from './ImageError.module.scss';
import TextButton from '../../common/components/TextButton';

type Props = {
  retryable?: boolean;
  error: string;
  onRetry?: () => void;
};

export const ImageError: React.FC<Props> = ({ error, onRetry, retryable = false }) => {
  return (
    <ImagePlaceholder>
      <div className={Styles.ImageError}>
        <div className={Styles.Inner}>
          <span className={Styles.ErrorMsg}>{error}</span>
          {retryable && (
            <TextButton className={Styles.RetryButton} text='erneut versuchen' onClick={() => onRetry && onRetry()} />
          )}
        </div>
      </div>
    </ImagePlaceholder>
  );
};
