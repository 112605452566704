import React from 'react';
import Styles from './VersionBadge.module.scss';
import { Tooltip } from '@other-components/tooltip';

type Props = {
  version: string;
};

export const VersionBadge: React.FC<Props> = ({ version }) => {
  return (
    <Tooltip
      layerOptions={{ placement: 'top-start' }}
      tooltipText='Delta-Version auf dem Scanner, der auf der Tour verwendet wurde.'>
      <div className={Styles.VersionBadge}>{version}</div>
    </Tooltip>
  );
};
