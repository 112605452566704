import React from 'react';

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    style={{ verticalAlign: 'middle', width: 24, height: 24 }}>
    <defs>
      <style>{`.a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}`}</style>
    </defs>
    <line className='a' x1='0.75' y1='23.25' x2='23.25' y2='23.25' />
    <path className='a' d='M6,17.25H3a.75.75,0,0,0-.75.75v5.25h4.5V18A.75.75,0,0,0,6,17.25Z' />
    <path className='a' d='M13.5,9.75h-3a.75.75,0,0,0-.75.75V23.25h4.5V10.5A.75.75,0,0,0,13.5,9.75Z' />
    <path className='a' d='M21,12.75H18a.75.75,0,0,0-.75.75v9.75h4.5V13.5A.75.75,0,0,0,21,12.75Z' />
    <polyline className='a' points='23.25 5.25 23.25 0.75 18.75 0.75' />
    <path
      className='a'
      d='M23.25.75,18.2,5.8a2.249,2.249,0,0,1-2.237.564L10.254,4.838a2.251,2.251,0,0,0-2.172.583L2.25,11.253'
    />
  </svg>
);
