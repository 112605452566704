import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import zustellungenArt from './zustellungen-art';
import zustellungen from './zustellungen';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const zustellungenNachArt = new MapDetailValueExpression(
  zustellungenArt.metricType,
  'putDownOutside:permanentAuthorization'
);

const expression = new QuotientValueExpression(
  zustellungenNachArt, // TODO: check this is correct. Why not sendungenDauervollmacht?
  zustellungen,
  'Dauervollmachtquote',
  true,
  null,
  null,
  getDefinitionByName('Dauervollmachtquote')
);

expression.setIdentifier('dvm_quote');

export default expression;
