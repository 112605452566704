import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import rueckfahrtzeit from './rueckfahrtzeit';
import touren from './touren';

const expression = new QuotientValueExpression(
  rueckfahrtzeit,
  touren,
  'Ø Rückfahrtzeit',
  false,
  '-#.###.##0,0 h',
  undefined,
  getDefinitionByName('Rückfahrtzeit')
);

expression.setIdentifier('rueckfahrtzeit/touren');

export default expression;
