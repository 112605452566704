import Model, { DefaultType, MemberType } from '../../../../utils/Model';
import Contact from './Contact';
import { PlainObjectOf } from './OrgUnitTreeRequestPayload';

export default class OrgUnitProperties extends Model<OrgUnitProperties> {
  orgKey: string;
  name: string;
  area: number | null | undefined;
  parentOrgKey: string | null | undefined;
  zipX: string | null | undefined;
  rstezeX: string | null | undefined;
  hermesLevel: string | null | undefined;
  orgId: number | null | undefined;
  contact: Contact | null | undefined;
  orgType: string;
  population: number | null | undefined;

  constructor(
    source: any, // FIXME: set proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<OrgUnitProperties> {
    return undefined;
  }

  getMembers(): MemberType<OrgUnitProperties> {
    return {
      contact: (src) => (src ? new Contact(src) : null),
    };
  }
}
