import React, { createContext, MutableRefObject, PropsWithChildren, ReactElement, useContext, useRef } from 'react';

export type TourDetailListContextType = {
  listElRef: MutableRefObject<HTMLTableRowElement>;
  listContainerRef: MutableRefObject<HTMLTableSectionElement>;
  scrollToRef: (args?: true | ScrollIntoViewOptions) => void;
};

const defaultValue: TourDetailListContextType = {
  listElRef: null,
  listContainerRef: null,
  scrollToRef: () => null,
};
export const TourDetailListContext = createContext(defaultValue);

export const useTourDetailListContext = (): TourDetailListContextType => {
  return useContext<TourDetailListContextType>(TourDetailListContext);
};

type TourDetailListContextProviderProps = Required<PropsWithChildren<{}>>;

export default function TourDetailListContextProvider({ children }: TourDetailListContextProviderProps): ReactElement {
  const listElRef = useRef<HTMLTableRowElement>(null);
  const listContainerRef = useRef<HTMLTableSectionElement>(null);
  const scrollToRef = (args?: true | ScrollIntoViewOptions): void => {
    listElRef.current?.scrollIntoView(args);
    listContainerRef.current?.classList?.add('scroll-into-view');
    setTimeout(() => {
      listContainerRef.current?.classList?.remove('scroll-into-view');
    }, 1000); // 1000ms is animation duration
  };

  const context: TourDetailListContextType = {
    listElRef,
    listContainerRef,
    scrollToRef,
  };

  return <TourDetailListContext.Provider value={context}>{children}</TourDetailListContext.Provider>;
}
