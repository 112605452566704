import React from 'react';
import Styles from './ErrorMessage.module.scss';
import classNames from 'classnames';

type Props = {
  children: string;
  className?: string;
};

export const ErrorMessage: React.FC<Props> = ({ children, className }) => {
  return <div className={classNames(Styles.ErrorMessage, className)}>{children}</div>;
};
