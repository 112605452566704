import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import moment from 'moment';
import { Button } from 'reactstrap';
import { DateRange } from '../../utils/dates/DateRange';
import { PopOver } from '../../common/components/PopOver';
import classNames from 'classnames';
import { DateInput } from './DateInput';
import { CalendarMonth } from './CalendarMonth';
import styles from './DateRangeSelectorPopup.module.scss';
import { useToday } from '../hooks/useToday';
import ChartUtils from '../../dashboard/utils/ChartUtils';
import { InputCheckbox } from '@legacy-modules/dashboard/components/InputCheckbox';
import { Weekday, WeekdaysFilter, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';

enum PresetLabel {
  today = 'Heute',
  '7days' = '7 Tage',
  '30days' = '30 Tage',
  '90days' = '90 Tage',
  '180days' = '180 Tage',
  '1year' = '1 Jahr',
}

export const weekdayLabels: { [key in Weekday]: string } = {
  mon: 'Mo',
  tue: 'Di',
  wed: 'Mi',
  thu: 'Do',
  fri: 'Fr',
  sat: 'Sa',
  sun: 'So',
};

const format = 'DD.MM.YYYY';

const getPresetRange = (preset: PresetLabel): DateRange | null => {
  switch (PresetLabel[preset]) {
    case PresetLabel.today:
      return {
        from: moment(),
        to: moment(),
      };
    case PresetLabel['7days']:
      return {
        from: moment().subtract(7, 'days'),
        to: moment().subtract(1, 'day'),
      };
    case PresetLabel['30days']:
      return {
        from: moment().subtract(30, 'days'),
        to: moment().subtract(1, 'day'),
      };
    case PresetLabel['90days']:
      return {
        from: moment().subtract(90, 'days'),
        to: moment().subtract(1, 'day'),
      };
    case PresetLabel['180days']:
      return {
        from: moment().subtract(180, 'days'),
        to: moment().subtract(1, 'day'),
      };
    case PresetLabel['1year']:
      return {
        from: moment().subtract(1, 'year'),
        to: moment().subtract(1, 'day'),
      };
    default: {
      return null;
    }
  }
};

type Props = {
  onSelectionChanged?: (primary: DateRange, compare?: DateRange, filter?: WeekdaysFilter) => void;
  initialPrimaryRange: DateRange;
  initialComparisonRange?: DateRange;
  initialWeekdaysFilter?: WeekdaysFilter;
  comparisonEnabled?: boolean;
  comparisonActive?: boolean;
  earliestDate?: moment.Moment;
  futureEnabled?: boolean;
  dayOnly?: boolean;
  disabled?: boolean;
  onDurationSelected?: (primary: DateRange, compare?: DateRange, filter?: WeekdaysFilter) => void;
  showWeekdaysFilter?: boolean;
  hidePreset?: boolean;
  // flag if "benutzerdefiniertes datum" is shown
  isCustomOpened?: boolean;
  onCancel?: () => void;
  target: React.RefObject<any>;
  open: boolean;
  // array of month to render: 2,1,0 renders the last 2 month, last month and the current month
  months?: number[];
  onCompareActivation?: (active: boolean) => void;
};

enum SelectionName {
  'primary' = 'primary',
  'compare' = 'compare',
}
type SelectionData = { from: moment.Moment; to: moment.Moment };
type SelectionType = {
  [key in SelectionName]: SelectionData;
};
type SelectionKey = 'from' | 'to';
type SelectedSelection = {
  selectionName: SelectionName;
  key: SelectionKey;
};

const getOppositeKey = (key: SelectionKey) => {
  switch (key) {
    case 'from':
      return 'to';
    case 'to':
      return 'from';
    default:
      throw new Error('Unknown key: ' + key);
  }
};

const DateRangePicker: React.FC<Props> = (props) => {
  const {
    initialComparisonRange,
    initialPrimaryRange,
    initialWeekdaysFilter = defaultWeekdaysFilter,
    dayOnly,
    onSelectionChanged,
    onDurationSelected,
    earliestDate,
    futureEnabled,
    comparisonEnabled,
    hidePreset,
    showWeekdaysFilter = true,
    target,
    open,
    months = [2, 1, 0],
    onCancel,
    comparisonActive: propComparisonActive,
  } = props;

  const [weekdayFilter, setWeekdayFilter] = useState<WeekdaysFilter>(initialWeekdaysFilter);

  const anyWeekdaySelected = Object.values(weekdayFilter).some((value) => value);

  const filteredWeekdays = useMemo(() => {
    return Object.keys(weekdayFilter).filter((weekday) => (showWeekdaysFilter ? weekdayFilter[weekday] : true));
  }, [weekdayFilter, showWeekdaysFilter]);

  const onWeekdayChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setWeekdayFilter((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  }, []);

  const [lastChangeIsManual, setLastChangeIsManual] = useState(false);
  const today = useToday();
  const [comparisonActive, setComparisonActive] = useState(false);

  const [calenderLastMonth, setCalenderLastMonth] = useState<moment.Moment>(moment());

  const [sentUpdate, setSentUpdate] = useState<boolean>(false);

  const [selectedSelection, setSelectedSelection] = useState<SelectedSelection>({
    selectionName: SelectionName.primary,
    key: 'from',
  });
  const [selections, setSelections] = useState<SelectionType>({
    [SelectionName.primary]: {
      from: initialPrimaryRange?.from ? moment(initialPrimaryRange?.from) : today,
      to: initialPrimaryRange?.to ? moment(initialPrimaryRange?.to) : today,
    },
    [SelectionName.compare]: {
      from: initialComparisonRange?.from ? moment(initialComparisonRange?.from) : today,
      to: initialComparisonRange?.to ? moment(initialComparisonRange?.to) : today,
    },
  });

  useEffect(() => {
    setComparisonActive(!!propComparisonActive);
  }, [propComparisonActive]);

  const getNextSelection = useCallback((): SelectedSelection => {
    const getNextSelectionName = (selectionName: SelectionName) => {
      if (!comparisonActive) {
        return SelectionName.primary;
      }
      switch (selectionName) {
        case SelectionName.primary:
          return SelectionName.compare;
        case SelectionName.compare:
          return SelectionName.primary;
        default:
          throw new Error('Unknown selection name: ' + selectionName);
      }
    };
    const { selectionName, key } = selectedSelection;
    if (key === 'from') {
      return {
        selectionName,
        key: getOppositeKey(key),
      };
    } else {
      return {
        selectionName: getNextSelectionName(selectionName),
        key: 'from',
      };
    }
  }, [comparisonActive, selectedSelection]);

  const updateSelectedValue = useCallback(
    (selection: SelectionName, key: SelectionKey, value: moment.Moment) => {
      // If current value is a "to" selection, we switch the values. In this case
      // we don't change the selection
      if (key === 'to' && value.isBefore(selections[selection].from)) {
        setSelections((s) => ({
          ...s,
          [selection]: {
            from: value,
            to: s[selection].from,
          },
        }));
      } else if (key === 'from' && value.isAfter(selections[selection].to)) {
        // If the current value is a "from" value, we set both to the same value
        setSelections((s) => ({
          ...s,
          [selection]: {
            from: value,
            to: value,
          },
        }));
        setSelectedSelection(getNextSelection);
      } else {
        // Default behaviour: Set the given value and select the next element
        setSelections((s) => ({
          ...s,
          [selection]: {
            ...s[selection],
            [key]: value,
          },
        }));
        setSelectedSelection(getNextSelection);
      }
      setSentUpdate(true);
    },
    [getNextSelection, selections]
  );

  const getComparePresetRange = useCallback((preset: string, primary: DateRange): DateRange => {
    if (!primary) {
      return;
    }
    const compare = ChartUtils.calculateComparisonDateRangeFromDateRange(primary);
    return {
      from: compare.from,
      to: compare.to,
    };
  }, []);

  const onDaySelect = useCallback(
    (date: moment.Moment) => {
      setLastChangeIsManual(false);
      if (!futureEnabled && date.isAfter(today)) {
        // ignore selection in the future if disabled
      }

      const { selectionName, key } = selectedSelection;
      if (dayOnly) {
        // always set opposite from / to value in day only mode
        updateSelectedValue(selectionName, 'from', date);
        updateSelectedValue(selectionName, 'to', date);
        setSelectedSelection({
          selectionName,
          key: 'from',
        });
      } else {
        updateSelectedValue(selectionName, key, date);
      }
    },
    [dayOnly, futureEnabled, selectedSelection, today, updateSelectedValue]
  );

  useEffect(() => {
    if (!onSelectionChanged) {
      return;
    }
    if (!sentUpdate) {
      return;
    }

    setSentUpdate(false);
    onSelectionChanged(selections.primary, selections.compare, weekdayFilter);
  }, [onSelectionChanged, selections, sentUpdate, weekdayFilter]);

  const _onSubmit = useCallback(() => {
    if (onDurationSelected) {
      onDurationSelected(selections.primary, selections.compare, weekdayFilter);
    }
  }, [onDurationSelected, selections.compare, selections.primary, weekdayFilter]);

  const _onCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onCalenderUnitSelect = useCallback(
    (date: moment.Moment, endOf: moment.Moment, unit: moment.unitOfTime.StartOf) => {
      if (dayOnly) {
        // do not allow selection of weeks or months in day only view
        return;
      }

      setLastChangeIsManual(false);

      if (futureEnabled && date.isAfter(today)) {
        return;
      }
      const from = date.clone().startOf(unit);
      const to = (() => {
        const endOfUnit = date.clone().endOf(unit);

        if (date.clone().endOf(unit).isAfter(today)) {
          return today;
        }
        return endOfUnit;
      })();

      // Set directly to avoid swapping which can occur if setting values
      // individually
      setSelections((s) => ({
        ...s,
        [selectedSelection.selectionName]: {
          to,
          from,
        },
      }));
      setSentUpdate(true);
    },
    [dayOnly, futureEnabled, selectedSelection.selectionName, today]
  );

  const [activePreset, setActivePreset] = useState<PresetLabel | ''>(PresetLabel.today);

  useEffect(() => {
    if (!comparisonActive) {
      return;
    }

    const compareRange = getComparePresetRange(activePreset, selections.primary);
    setSelections((s) => ({
      ...s,
      [SelectionName.compare]: compareRange,
    }));
  }, [activePreset, comparisonActive, getComparePresetRange, selections.primary]);

  const selectPreset = useCallback(
    (preset: PresetLabel) => () => {
      setCalenderLastMonth(today);
      setActivePreset(preset);
      const primaryRange = getPresetRange(preset);

      setSelections((s) => ({
        ...s,
        [SelectionName.primary]: primaryRange,
      }));
      setSentUpdate(true);
    },
    [today]
  );

  const getAvailablePresets = useCallback(() => {
    return Object.keys(PresetLabel)
      .map((preset: PresetLabel) => {
        const { from, to } = getPresetRange(preset);
        return {
          key: preset,
          label: PresetLabel[preset],
          selected: from.isSame(selections?.primary?.from, 'day') && to.isSame(selections?.primary?.to, 'day'),
          from,
          to: to,
        };
      })
      .filter((preset) => {
        if (!earliestDate) {
          return true;
        }
        return preset.from.isSameOrAfter(earliestDate);
      });
  }, [earliestDate, selections?.primary?.from, selections?.primary?.to]);

  const calenderShowPreviousMonth = useCallback(() => {
    setCalenderLastMonth(calenderLastMonth.clone().subtract(1, 'month'));
  }, [calenderLastMonth]);

  const calenderShowNextMonth = useCallback(() => {
    setCalenderLastMonth(calenderLastMonth.clone().add(1, 'month'));
  }, [calenderLastMonth]);

  const dateFocusCallback = useCallback((section: SelectionName, key: SelectionKey) => {
    return () => {
      setSelectedSelection({
        selectionName: section,
        key,
      });
    };
  }, []);

  const focusRef = useRef(null);

  useEffect(() => {
    focusRef?.current?.focus();
  }, [selectedSelection.key]);

  const presets = useMemo(getAvailablePresets, [getAvailablePresets]);

  useEffect(() => {
    const selected = presets.find((p) => p.selected);
    if (selected) {
      setActivePreset(selected.key);
    } else {
      setActivePreset('');
    }
  }, [presets]);

  const { primFromFocus, primToFocus, compareFromFocus, compareToFocus } = useMemo(
    () => ({
      primFromFocus: dateFocusCallback(SelectionName.primary, 'from'),
      primToFocus: dateFocusCallback(SelectionName.primary, 'to'),
      compareFromFocus: dateFocusCallback(SelectionName.compare, 'from'),
      compareToFocus: dateFocusCallback(SelectionName.compare, 'to'),
    }),
    [dateFocusCallback]
  );

  const onBlurCallbackFactory = useCallback(
    (selection: SelectionName, key: SelectionKey) => {
      return (val: moment.Moment) => {
        if (!lastChangeIsManual) {
          return;
        }

        const newInput = (() => {
          if (!val) {
            return val;
          }

          if (!futureEnabled && val.isAfter(today)) {
            return today;
          }
          return val;
        })();
        updateSelectedValue(selection, key, moment(newInput, format));
      };
    },
    [futureEnabled, lastChangeIsManual, today, updateSelectedValue]
  );

  const { pF, pT, cF, cT } = useMemo(() => {
    return {
      pF: onBlurCallbackFactory(SelectionName.primary, 'from'),
      pT: onBlurCallbackFactory(SelectionName.primary, 'to'),
      cF: onBlurCallbackFactory(SelectionName.compare, 'from'),
      cT: onBlurCallbackFactory(SelectionName.compare, 'to'),
    };
  }, [onBlurCallbackFactory]);

  const getCurrentSelectionDate = useCallback(() => {
    return selections[selectedSelection.selectionName][getOppositeKey(selectedSelection.key)];
  }, [selectedSelection.key, selectedSelection.selectionName, selections]);

  const disableManualSelection = !!earliestDate;
  const disableHeaderSelection = disableManualSelection;

  // FIXME: 'data-range-picker' class is used as identifier for data range selector element
  // FIXME: surely there is a better way to do this way of outside click detection
  return (
    <div>
      <PopOver
        anchorElement={target?.current}
        placement='auto-start'
        visible={open}
        className={classNames(styles.customPopper, 'data-range-picker')}
        onClose={_onCancel}
        closeOnClickOutside={true}
        offset={[5, 0]}>
        <div className={styles.dateRangeContainer}>
          {!dayOnly && (
            <header
              className={classNames(styles.header, {
                [styles.comparison]:
                  comparisonEnabled && comparisonActive && selections?.compare?.from && selections?.compare?.to,
              })}>
              <h1 className={styles.title}>
                {comparisonEnabled ? 'Zeitraum auswählen' : 'Benutzerdefinierte Zeitauswahl'}
              </h1>
              {!hidePreset && (
                <section className={styles.datePresets}>
                  {presets.map((preset) => (
                    <Button
                      outline
                      key={preset.key}
                      size='sm'
                      onClick={selectPreset(preset.key)}
                      className={classNames({
                        [styles.selected]: activePreset === preset.key,
                      })}>
                      {preset.label}
                    </Button>
                  ))}
                </section>
              )}
              <div className={styles.primaryRangeForm}>
                <DateInput
                  ref={
                    selectedSelection.selectionName === 'primary' && selectedSelection.key === 'from'
                      ? focusRef
                      : undefined
                  }
                  onBlur={pF}
                  onFocus={primFromFocus}
                  disabled={disableManualSelection}
                  className={styles.dateInput}
                  innerClassName={classNames(styles.primary, {
                    [styles.active]: selectedSelection.selectionName === 'primary' && selectedSelection.key === 'from',
                  })}
                  onChange={() => setLastChangeIsManual(true)}
                  value={selections?.primary?.from}
                />
                <div className={styles.dateSpacer}>-</div>
                <DateInput
                  onBlur={pT}
                  ref={
                    selectedSelection.selectionName === 'primary' && selectedSelection.key === 'to'
                      ? focusRef
                      : undefined
                  }
                  onFocus={primToFocus}
                  onChange={() => setLastChangeIsManual(true)}
                  disabled={disableManualSelection}
                  className={styles.dateInput}
                  innerClassName={classNames(styles.primary, {
                    [styles.active]: selectedSelection.selectionName === 'primary' && selectedSelection.key === 'to',
                  })}
                  value={selections?.primary?.to}
                />
              </div>
              {comparisonEnabled && comparisonActive && selections?.compare?.from && selections?.compare?.to && (
                <div className={styles.comparisonSection}>
                  <DateInput
                    ref={
                      selectedSelection.selectionName === 'compare' && selectedSelection.key === 'from'
                        ? focusRef
                        : undefined
                    }
                    onBlur={cF}
                    disabled={true}
                    onFocus={compareFromFocus}
                    className={styles.dateInput}
                    innerClassName={classNames(styles.compare, {
                      [styles.active]:
                        selectedSelection.selectionName === 'compare' && selectedSelection.key === 'from',
                    })}
                    onChange={() => setLastChangeIsManual(true)}
                    value={comparisonActive ? selections?.compare?.from : null}
                  />
                  <div className={styles.dateSpacer}>-</div>
                  <DateInput
                    ref={
                      selectedSelection.selectionName === 'compare' && selectedSelection.key === 'to'
                        ? focusRef
                        : undefined
                    }
                    onBlur={cT}
                    onFocus={compareToFocus}
                    disabled={true}
                    className={styles.dateInput}
                    innerClassName={classNames(styles.compare, {
                      [styles.active]: selectedSelection.selectionName === 'compare' && selectedSelection.key === 'to',
                    })}
                    onChange={() => setLastChangeIsManual(true)}
                    value={comparisonActive ? selections?.compare?.to : null}
                  />
                </div>
              )}
              {showWeekdaysFilter &&
                selections?.primary?.from &&
                selections?.primary?.to &&
                selections?.primary?.from?.isBefore(selections?.primary?.to) && (
                  <div className={styles.weekdayFilter}>
                    {Object.keys(weekdayFilter).map((weekday) => (
                      <InputCheckbox
                        containerClassName={styles.weekdayCheckbox}
                        className={styles.weekdayCheckmark}
                        key={weekday}
                        name={weekday}
                        checked={weekdayFilter[weekday]}
                        onChange={onWeekdayChange}>
                        <span className={styles.weekdayLabel}>{weekdayLabels[weekday]}</span>
                      </InputCheckbox>
                    ))}
                  </div>
                )}
            </header>
          )}

          <div
            className={classNames(styles.body, {
              'comparison-enabled': comparisonActive,
            })}>
            {selections.primary &&
              months.map((monthValue) => {
                const month = calenderLastMonth.clone().subtract(monthValue, 'month');
                return (
                  <CalendarMonth
                    activeDay={getCurrentSelectionDate()}
                    key={month.format('MM')}
                    month={month}
                    filteredWeekdays={filteredWeekdays}
                    primaryRange={selections.primary}
                    disableHeaderSelection={disableHeaderSelection}
                    onCalenderUnitSelect={onCalenderUnitSelect}
                    futureEnabled={futureEnabled}
                    earliestDate={earliestDate}
                    onDaySelect={onDaySelect}
                    onSubmit={_onSubmit}
                    compareRange={comparisonActive ? selections.compare : null}
                  />
                );
              })}
            <div className={classNames(styles.calendarPagination, styles.previous)} onClick={calenderShowPreviousMonth}>
              <MdChevronLeft />
            </div>
            <div className={classNames(styles.calendarPagination, styles.next)} onClick={calenderShowNextMonth}>
              <MdChevronRight />
            </div>
          </div>
          <div className={styles.footer}>
            <Button className={styles.btn} disabled={!anyWeekdaySelected} onClick={_onSubmit} color='primary'>
              Übernehmen
            </Button>
          </div>
        </div>
      </PopOver>
    </div>
  );
};

export default DateRangePicker;
