import React, { HTMLAttributes, ReactElement, useRef } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { MdArrowDropDown } from 'react-icons/md';
import classNames from 'classnames';
import { TruncatedText } from '../../common/components/TruncatedText';
import { Tooltip } from '../../common/components/Tooltip';

type Props = HTMLAttributes<HTMLElement> & {
  icon: ReactElement;
  text: string;
  onSelectClick?: () => void;
  showPrependButton?: boolean;
  showAppendButton?: boolean;
  onPrependButtonClick?: () => void;
  onAppendButtonClick?: () => void;
  prependButtonLabel?: string;
  prependButtonIcon?: React.ReactElement;
  appendButtonLabel?: string;
  appendButtonIcon?: React.ReactElement;
  prependButtonDisabled?: boolean;
  appendButtonDisabled?: boolean;
  prependButtonColor?: string;
  appendButtonColor?: string;
  prependButtonOutline?: boolean;
  appendButtonOutline?: boolean;
  infoLabel?: string;
  tooltipOnTruncate?: boolean;
  disabled?: boolean;
  'data-testid'?: string;
};

type UtilityButtonProps = HTMLAttributes<HTMLElement> & {
  icon: React.ReactElement;
  disabled: boolean;
  label: string | null | undefined;
  outline: boolean;
  color: string;
};

const UtilityButton = (props: UtilityButtonProps) => {
  const { icon, label, onClick, disabled, outline, color } = props;

  const ref = useRef(null);

  return (
    <>
      <Button
        innerRef={ref}
        onClick={onClick}
        outline={outline}
        color={color}
        className={`utility-button`}
        disabled={disabled}>
        {icon}
      </Button>
      {label && (
        <Tooltip placement='bottom' anchorElement={ref?.current} enterDelay={500}>
          {label}
        </Tooltip>
      )}
    </>
  );
};

const BaseSelect: React.FC<Props> = ({
  icon,
  text,
  onSelectClick,
  showPrependButton,
  showAppendButton,
  onPrependButtonClick,
  onAppendButtonClick,
  prependButtonLabel,
  prependButtonIcon,
  appendButtonLabel,
  appendButtonIcon,
  prependButtonDisabled,
  appendButtonDisabled,
  prependButtonColor = 'primary',
  appendButtonColor = 'primary',
  prependButtonOutline = true,
  appendButtonOutline = true,
  disabled,
  infoLabel,
  tooltipOnTruncate,
  className = 'base-select',
  ...rest
}) => {
  const spanRef = useRef<HTMLSpanElement>();

  return (
    <ButtonGroup className={className} {...rest}>
      {showPrependButton && (
        <UtilityButton
          icon={prependButtonIcon}
          label={prependButtonLabel}
          onClick={onPrependButtonClick}
          disabled={prependButtonDisabled}
          outline={prependButtonOutline}
          color={prependButtonColor}
        />
      )}
      <Button disabled={disabled} onClick={onSelectClick} outline color='secondary' className='main-select-button'>
        {icon && (
          <>
            <span className='main-select-icon' ref={spanRef}>
              {icon}
            </span>
            <Tooltip placement='bottom' anchorElement={spanRef?.current} enterDelay={500}>
              {infoLabel}
            </Tooltip>
          </>
        )}
        <span
          className={classNames('main-select-label', {
            icon: icon,
          })}>
          <TruncatedText showTooltipIfCutOff={tooltipOnTruncate}>{text}</TruncatedText>
        </span>
        <span className='main-select-caret'>
          <MdArrowDropDown size={18} />
        </span>
      </Button>
      {showAppendButton && (
        <UtilityButton
          icon={appendButtonIcon}
          label={appendButtonLabel}
          onClick={onAppendButtonClick}
          disabled={appendButtonDisabled}
          outline={appendButtonOutline}
          color={appendButtonColor}
        />
      )}
    </ButtonGroup>
  );
};

export default BaseSelect;
