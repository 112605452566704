import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

type Props = {
  onDismiss: () => void;
  onLogout: () => void;
};

export const LogoutModal = ({ onDismiss, onLogout }: Props) => {
  return (
    <div>
      <Modal isOpen autoFocus={false}>
        <ModalBody>Möchten Sie sich wirklich abmelden?</ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={onLogout}>
            Abmelden
          </Button>{' '}
          <Button color='white' onClick={onDismiss}>
            Abbrechen
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
