import classNames from 'classnames';
import React, { HTMLAttributes, useRef, useState } from 'react';
import { MdClose, MdSearch } from 'react-icons/md';
import { Button, FormFeedback, Input, InputProps } from 'reactstrap';
import style from './debounce-input.module.scss';

type DebounceInputProps = {
  className?: HTMLAttributes<HTMLDivElement>['className'];
  isValid?: boolean;
  debounceTimeout?: number;
  showAddon?: boolean;
  onValueChange: (value: string) => void;
  inputProps?: InputProps;
};
const DebounceInput = ({
  debounceTimeout = 500,
  onValueChange,
  isValid = true,
  showAddon = true,
  className,
  inputProps,
}: DebounceInputProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const timeoutId = useRef(null);

  const [value, setValue] = useState<string>(inputProps?.defaultValue?.toString() || '');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (timeoutId?.current) {
      clearTimeout(timeoutId.current);
    }
    event.persist();
    timeoutId.current = setTimeout(() => {
      onValueChange(event.target.value);
    }, debounceTimeout);
  };

  const onInputClear = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      onValueChange('');
    }
  };

  return (
    <div className={classNames(style.debounceInputContainer, className)}>
      {showAddon && (
        <div className={classNames(style.debounceInputAddon, style.debounceInputSearchAddon)}>
          <MdSearch data-testid='debounce-input-left-addon' />
        </div>
      )}
      <Input
        {...inputProps}
        data-testid='debounce-input'
        innerRef={inputRef}
        valid={value?.length > 0 && isValid}
        invalid={value?.length > 0 && !isValid}
        autoFocus={true}
        type='text'
        onChange={onChange}
        className={classNames(
          style.debounceInput,
          {
            [style.addonLeft]: showAddon,
          },
          inputProps?.className
        )}
      />
      <FormFeedback tooltip={true} data-testid='debounce-input-error'>
        Überprüfen Sie Ihre Eingabe!
      </FormFeedback>
      {value?.length > 0 && (
        <Button
          data-testid='debounce-input-clear-button'
          className={classNames(style.debounceInputAddon, style.debounceInputButtonAddon)}
          type='button'
          onClick={onInputClear}>
          <MdClose />
        </Button>
      )}
    </div>
  );
};

export default DebounceInput;
