import Model, { DefaultType, MemberType } from '../../../../utils/Model';
import { CacheKeyInterface } from '../../../../datamanager/models/entities/CacheKeyInterface';

export class TourIdentifier extends Model<TourIdentifier> implements CacheKeyInterface {
  orgId: string;
  date: string;
  number: string;
  contractorKey?: string;

  constructor(
    source: any = null, // FIXME: set proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  get identifier(): string {
    return [this.orgId, this.date, this.number].join(':');
  }

  getDefaults(): DefaultType<TourIdentifier> {
    return undefined;
  }

  getMembers(): MemberType<TourIdentifier> {
    return undefined;
  }
}
