import { complaintReasons } from '@legacy-modules/metrics2/models/entities/MetricType';
import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import servicebeanstandungenMeldungGrund from './servicebeanstandungen-meldung-grund';
import { UndeliverableReasonShortLabels } from '@legacy-modules/metrics2/models/enumerations/UndeliverableReason';

const byType = complaintReasons.map((reason) => {
  const expression = new MapDetailValueExpression(
    servicebeanstandungenMeldungGrund.metricType,
    reason,
    UndeliverableReasonShortLabels[reason]
  );
  expression.setIdentifier(`complaints.reported.count_by_type.${reason}`);
  return expression;
});

const byGrund = complaintReasons.map((reason) => {
  const expression = new MapDetailValueExpression(servicebeanstandungenMeldungGrund.metricType, reason, reason);
  expression.setIdentifier(`complaints.reported.${reason}`);
  return expression;
});

export default [...byType, ...byGrund];
