import Model, { DefaultType, MemberType } from '../../../utils/Model';
import { TourIdentifier } from '../state/tourDetails/TourIdentifier';
import moment from 'moment-timezone';
import GeoLocation from '../../../geo/models/GeoLocation';
import { PlainObjectOf } from '../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export default class TourListEntry extends Model<TourListEntry> {
  identifier: TourIdentifier;
  lastPosition: GeoLocation | null | undefined;
  updateAt: moment.Moment;
  driver: { id: string; name: string };
  deliveryItems: { total: number };
  contractorKey?: string;

  constructor(source: PlainObjectOf<TourListEntry>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<TourListEntry> {
    return {
      updateAt: () => moment(),
    };
  }

  getMembers(): MemberType<TourListEntry> {
    return {
      identifier: (src) => new TourIdentifier({ ...src, orgId: src.orgId || src.organizationId }),
      lastPosition: (src) => (src ? new GeoLocation(src) : null),
      updateAt: (src) => moment(src),
    };
  }
}
