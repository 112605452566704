import React, { ReactElement } from 'react';
import style from './mapbox-stop-select.module.scss';
import classNames from 'classnames';
import { MapboxControl } from '../mapbox-control';
import { ButtonGroup, Button, ButtonGroupProps } from 'reactstrap';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

export type MapboxStopSelectProps = ButtonGroupProps & {
  label: string;
  onPrevClick?: () => void;
  onNextClick?: () => void;
};

function MapboxStopSelectBase({
  label = 'Unbekannter Stop',
  onPrevClick,
  onNextClick,
  ...buttonGroupProps
}: MapboxStopSelectProps): ReactElement {
  return (
    <ButtonGroup size='md' className={classNames(style.mapboxStopSelect)} {...buttonGroupProps}>
      <Button color='light' onClick={onPrevClick} disabled={!onPrevClick}>
        <MdChevronLeft size={20} />
      </Button>
      <span>{label}</span>
      <Button color='light' onClick={onNextClick} disabled={!onNextClick}>
        <MdChevronRight size={20} />
      </Button>
    </ButtonGroup>
  );
}
export const MapboxStopSelect = MapboxControl<MapboxStopSelectProps>(MapboxStopSelectBase);
export default MapboxStopSelect;
