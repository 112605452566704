import React, { ComponentType, HTMLAttributes, ReactElement, Ref, useMemo } from 'react';
import { DndContext, DndContextProps, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, SortableContextProps, useSortable, UseSortableArguments } from '@dnd-kit/sortable';

export type DraggableListProps = HTMLAttributes<HTMLDivElement> & {
  sortableContextProps: Omit<SortableContextProps, 'children'>;
  dndContextProps?: DndContextProps;
  scrollContainerRef?: Ref<HTMLDivElement>;
  direction?: 'vertical' | 'horizontal';
};
export type DraggableListComponentProps = HTMLAttributes<HTMLDivElement> & {
  scrollContainerRef?: Ref<HTMLDivElement>;
};

export function withDraggableList(Component: ComponentType<DraggableListComponentProps>) {
  return function DraggableList({
    direction,
    dndContextProps,
    sortableContextProps,
    ...props
  }: DraggableListProps): ReactElement {
    const modifiers = useMemo(() => {
      if (!direction) {
        return dndContextProps?.modifiers || [];
      } else if (direction === 'vertical') {
        return [restrictToVerticalAxis];
      } else {
        return [restrictToHorizontalAxis];
      }
    }, [direction, dndContextProps?.modifiers]);
    const mouseSensor = useSensor(MouseSensor, {
      activationConstraint: { distance: 5 },
    });
    const touchSensor = useSensor(TouchSensor, {
      activationConstraint: { distance: 5 },
    });
    const sensors = useSensors(touchSensor, mouseSensor);
    return (
      <DndContext {...dndContextProps} modifiers={modifiers} sensors={sensors}>
        <SortableContext {...sortableContextProps}>
          <Component {...props} />
        </SortableContext>
      </DndContext>
    );
  };
}

export type DraggableListItemProps = HTMLAttributes<HTMLDivElement> & {
  sortableArguments: UseSortableArguments;
  pinned?: boolean;
  pinningEnabled?: boolean;
};
export type DraggableListItemComponentProps = HTMLAttributes<HTMLDivElement> & {
  sortableItemProps: ReturnType<typeof useSortable>;
  pinned?: boolean;
  pinningEnabled?: boolean;
};

export function withDraggableListItem(Component: ComponentType<DraggableListItemComponentProps>) {
  return function DraggableListItem({
    sortableArguments,
    className,
    pinned,
    ...props
  }: DraggableListItemProps): ReactElement {
    const sortableItemProps = useSortable({
      disabled: pinned,
      ...sortableArguments,
    });

    return <Component {...props} pinned={pinned} sortableItemProps={sortableItemProps} className={className} />;
  };
}
