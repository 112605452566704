import { MetricsValue } from '../models/entities/MetricsValue';
import MetricsEntityKey from '../models/entities/MetricsEntityKey';
import { AggregationType } from '../models/enumerations/AggregationType';

export default class MetricDataUtils {
  static aggregationFunction(aggregation: AggregationType): (arg0: number, arg1: number) => number {
    switch (aggregation) {
      case AggregationType.sum:
        return (a, b) => a + b;
      default:
        return (a, b) => a;
    }
  }

  static calcDataStats(data: Map<MetricsEntityKey, number>):
    | {
        valueKeys: Set<string>;
        dates: Array<string>;
        dateIndex: {
          [key: string]: number;
        };
        isMapValue: boolean;
      }
    | null
    | undefined {
    if (!data) {
      return null;
    }
    const valueKeys = new Set(Array.from(data.keys()).map((me: MetricsEntityKey) => me.valueKey));
    const dates = Array.from(new Set(Array.from(data.keys()).map((me: MetricsEntityKey) => me.dateIdentifier))).sort();
    const dateIndex = {};
    dates.forEach((date, inx) => {
      dateIndex[date] = inx;
    });
    // Before typescript typing this line was:
    // this.sumValueKeys || valueKeys.size > 1;
    // which might indicate that the sumValueKeys value should be
    // used in any matter. However it wasn't ever defined
    const isMapValue = valueKeys.size > 1;
    return { valueKeys, dates, dateIndex, isMapValue };
  }

  static metricData2Single(
    data: Map<MetricsEntityKey, number>,
    aggregation: AggregationType = AggregationType.none
  ): MetricsValue | null | undefined {
    if (!data || data.size == 0) {
      return null;
    }
    const aggregationFn = MetricDataUtils.aggregationFunction(aggregation);
    const stats = MetricDataUtils.calcDataStats(data);
    let returnValue;
    if (stats.isMapValue) {
      returnValue = {};
      data.forEach((value: number, key: MetricsEntityKey) => {
        if (returnValue[key.valueKey] !== undefined) {
          returnValue[key.valueKey] = aggregationFn(value, returnValue[key.valueKey]);
        } else {
          returnValue[key.valueKey] = value;
        }
      });
      return returnValue;
    } else {
      returnValue = 0;
      data.forEach((value: number, key: MetricsEntityKey) => {
        returnValue = aggregationFn(value, returnValue);
      });
      return returnValue;
    }
  }

  static metricData2DateArray(
    data: Map<MetricsEntityKey, number>,
    aggregation: AggregationType = AggregationType.none
  ): Array<MetricsValue> | null | undefined {
    if (!data || data.size == 0) {
      return null;
    }
    const aggregationFn = MetricDataUtils.aggregationFunction(aggregation);
    const stats = MetricDataUtils.calcDataStats(data);
    let dateArray;
    if (stats.isMapValue) {
      dateArray = stats.dates.map((dt) => ({}));
      data.forEach((value: number, key: MetricsEntityKey) => {
        if (dateArray[stats.dateIndex[key.dateIdentifier]][key.valueKey] !== undefined) {
          dateArray[stats.dateIndex[key.dateIdentifier]][key.valueKey] = aggregationFn(
            value,
            dateArray[stats.dateIndex[key.dateIdentifier]][key.valueKey]
          );
        } else {
          dateArray[stats.dateIndex[key.dateIdentifier]][key.valueKey] = value;
        }
      });
      return dateArray;
    } else {
      dateArray = stats.dates.map((dt) => 0);
      data.forEach((value: number, key: MetricsEntityKey) => {
        dateArray[stats.dateIndex[key.dateIdentifier]] = aggregationFn(
          value,
          dateArray[stats.dateIndex[key.dateIdentifier]]
        );
      });
      return dateArray;
    }
  }

  static metricData2Object(
    data: Map<MetricsEntityKey, number>,
    keyFn: (arg0: MetricsEntityKey) => string,
    aggregation: AggregationType = AggregationType.none
  ): {
    [key: string]: MetricsValue;
  } {
    if (!data || data.size == 0) {
      return null;
    }
    const aggregationFn = MetricDataUtils.aggregationFunction(aggregation);
    const stats = MetricDataUtils.calcDataStats(data);
    const returnValue = {};
    if (stats.isMapValue) {
      data.forEach((value: number, key: MetricsEntityKey) => {
        const objKey = keyFn(key);
        if (!returnValue[objKey]) {
          returnValue[objKey] = {};
        }
        if (returnValue[objKey][key.valueKey] !== undefined) {
          returnValue[objKey][key.valueKey] = aggregationFn(value, returnValue[objKey][key.valueKey]);
        } else {
          returnValue[objKey][key.valueKey] = value;
        }
      });
    } else {
      data.forEach((value: number, key: MetricsEntityKey) => {
        const objKey = keyFn(key);
        if (!returnValue[objKey]) {
          returnValue[objKey] = 0;
        }
        returnValue[objKey] = aggregationFn(value, returnValue[objKey]);
      });
    }
    return returnValue;
  }

  static metricData2DateObject(data: Map<MetricsEntityKey, number>): {
    [key: string]: MetricsValue;
  } {
    return MetricDataUtils.metricData2Object(data, (k: MetricsEntityKey) => k.dateIdentifier);
  }

  static metricData2OrgKeyObject(data: Map<MetricsEntityKey, number>): {
    [key: string]: MetricsValue;
  } {
    return MetricDataUtils.metricData2Object(data, (k: MetricsEntityKey) => k.orgKey);
  }

  static metricData2MappedDateArrays(
    data: Map<MetricsEntityKey, number>,
    aggregation: AggregationType = AggregationType.none
  ):
    | {
        [key: string]: Array<number>;
      }
    | Array<number> {
    if (!data || data.size == 0) {
      return null;
    }
    const aggregationFn = MetricDataUtils.aggregationFunction(aggregation);
    const stats = MetricDataUtils.calcDataStats(data);
    const returnValue = {};
    if (stats.isMapValue) {
      data.forEach((value: number, key: MetricsEntityKey) => {
        if (!returnValue[key.valueKey]) {
          returnValue[key.valueKey] = stats.dates.map((dt) => 0);
        }
        returnValue[key.valueKey][stats.dateIndex[key.dateIdentifier]] = aggregationFn(
          value,
          returnValue[key.valueKey][stats.dateIndex[key.dateIdentifier]]
        );
      });
      return returnValue;
    } else {
      const dateArray = stats.dates.map((dt) => 0);
      data.forEach((value: number, key: MetricsEntityKey) => {
        dateArray[stats.dateIndex[key.dateIdentifier]] = aggregationFn(
          value,
          dateArray[stats.dateIndex[key.dateIdentifier]]
        );
      });
      return dateArray;
    }
  }
}
