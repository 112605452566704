import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import React, { useMemo } from 'react';
import MetricsFilter from '../../../metrics2/models/filter/MetricsFilter';
import getRandomHexColorByIndex from '../../../utils/color/getRandomHexColorByIndex';
import { DateRangeGrouping } from '../../../metrics2/models/enumerations/DateRangeGrouping';
import { DateRange } from '../../../utils/dates/DateRange';
import { useSelector } from 'react-redux';
import { ChartData } from '../../../dashboard/charts/DetailedChart';
import { Duration, DurationModes } from '@legacy-modules/dashboard/models/enums/Duration';
import { useMetricQuery } from '@hooks/use-metric-query-hook';
import { useOrganizationData } from '@hooks/use-organization-data-hook';
import { OrgUnitResult } from '@legacy-modules/metrics2/models/websocket/org/OrgUnitResult';
import { Chart } from '@components/chart';
import {
  selectCompareFilter,
  selectCompareText,
  selectOverviewValueExpression,
  selectPrimaryFilter,
  selectPrimaryText,
  selectOverviewWeekdayFilter,
} from '@redux/overview.selectors';
import { useOverviewContext } from '@contexts/overview-context';
const moment = extendMoment(Moment as any);

const thresholdDataBarsCount = 90;
const thresholdAxisBarsCount = 50;

export const getBarChartData = (
  primaryFilter: MetricsFilter,
  compareFilter: MetricsFilter,
  thresholdDataBarsCount: number,
  thresholdAxisBarsCount: number,
  orgUnits: OrgUnitResult[],
  primaryText: string,
  compareText: string,
  getPrimaryDataByGroupingKey: (orgKey: string, groupingKey: string) => number,
  getCompareDataByGroupingKey: (orgKey: string, groupingKey: string) => number
): ChartData => {
  function getMaxDaysCount(primaryFilter: MetricsFilter, compareFilter?: MetricsFilter): number {
    const days = primaryFilter.to.diff(primaryFilter.from, 'days');
    const comparisonDays = compareFilter ? compareFilter.to.diff(compareFilter.from, 'days') : 0;
    return Math.max(days, comparisonDays);
  }
  function getDateResolution(days: number, withCompare = false, maxBarsCount = 90): Moment.unitOfTime.Diff {
    const maxBars = withCompare ? maxBarsCount / 2 : maxBarsCount;
    if (days < maxBars) {
      return 'day';
    }
    const weeksCount = days / 7;
    if (weeksCount < maxBars) {
      return 'week';
    } else {
      return 'month';
    }
  }
  function getAxisLabels(days: number): (date: moment.Moment) => string {
    if (days > thresholdAxisBarsCount * 3) {
      return (date) => date.format('MMMM');
    } else if (days > thresholdAxisBarsCount) {
      return (date) => 'KW ' + date.format('W');
    } else {
      return (date) => date.format('DD');
    }
  }
  function getDataLabels(resolution: Moment.unitOfTime.Diff): (date: moment.Moment) => string {
    switch (resolution) {
      case 'week':
        return (d) => 'KW ' + d.format('W YYYY');
      case 'month':
        return (d) => d.format('MMMM YYYY');
      default:
        return (d) => d.format('DD.MM.YYYY');
    }
  }
  const days = getMaxDaysCount(primaryFilter, compareFilter);
  const chartResolution = getDateResolution(days, !!compareFilter, thresholdDataBarsCount);
  const primaryRange: DateRange = {
    from: primaryFilter.from.clone().startOf(chartResolution),
    to: primaryFilter.to.clone().endOf(chartResolution),
  };
  const compareRange: DateRange = compareFilter
    ? {
        from: compareFilter.from.clone().startOf(chartResolution),
        to: compareFilter.to.clone().endOf(chartResolution),
      }
    : null;
  const primaryDates: Array<moment.Moment> = Array.from(
    moment.range(primaryRange.from.clone(), primaryRange.to.clone()).by(chartResolution)
  );
  const compareDates: Array<moment.Moment> = compareRange
    ? Array.from(moment.range(compareRange.from.clone(), compareRange.to.clone()).by(chartResolution))
    : null;
  const primaryAxisLabels: Array<string> = primaryDates.map(getAxisLabels(days));
  const compareAxisLabels: Array<string> = compareDates ? compareDates.map(getAxisLabels(days)) : null;
  const primaryDataLabels: Array<string> = primaryDates.map(getDataLabels(chartResolution));
  const compareDataLabels: Array<string> = compareDates ? compareDates.map(getDataLabels(chartResolution)) : null;

  const datasets =
    orgUnits?.map((ou, i) => {
      const label = primaryFilter.orgKeys.length > 1 ? `${primaryText}: ${ou?.name}` : primaryText;
      const color = primaryFilter.orgKeys.length > 1 ? getRandomHexColorByIndex(i) : '#0091cd';
      return {
        label: label,
        spanGaps: true,
        borderColor: color,
        backgroundColor: color,
        fill: false,
        primary: true,
        stack: 'primary',
        data: primaryDates.map((date) => {
          return getPrimaryDataByGroupingKey(ou?.orgKey, date.format('YYYY-MM-DD') + '_' + chartResolution) || 0;
        }),
      };
    }) || [];

  const compareDatasets = compareFilter
    ? orgUnits.map((ou, i) => {
        const color = compareFilter.orgKeys.length > 1 ? getRandomHexColorByIndex(i, 1) : '#FC9800';
        return {
          label: compareFilter.orgKeys.length > 1 ? `${compareText}: ${ou?.name}` : compareText,
          spanGaps: true,
          borderColor: color,
          backgroundColor: color,
          fill: false,
          primary: false,
          stack: 'compare',
          data: compareDates.map((date) => {
            return getCompareDataByGroupingKey(ou?.orgKey, date.format('YYYY-MM-DD') + '_' + chartResolution) || 0;
          }),
        };
      })
    : [];

  return {
    labels: primaryAxisLabels,
    labelsCompare: compareAxisLabels,
    dataLabels: primaryDataLabels,
    compareDataLabels: compareDataLabels,
    labelValues: primaryDates,
    compareLabelValues: compareDates,
    datasets: [...datasets, ...compareDatasets],
  } as unknown as ChartData;
};

type Props = {};

export const PrimaryTimelineChart: React.FC<Props> = () => {
  const primaryFilter = useSelector(selectPrimaryFilter);
  const compareFilter = useSelector(selectCompareFilter);
  const primaryText = useSelector(selectPrimaryText);
  const compareText = useSelector(selectCompareText);
  const weekdayFilter = useSelector(selectOverviewWeekdayFilter);
  const primaryValueExpression = useSelector(selectOverviewValueExpression);

  const { hoverOrgKeyState } = useOverviewContext();
  const [hoverOrgKey] = hoverOrgKeyState;

  const orgKeys = useMemo(() => (hoverOrgKey ? [hoverOrgKey] : primaryFilter.orgKeys), [hoverOrgKey, primaryFilter]);

  const [orgData] = useOrganizationData(orgKeys, ['properties'], undefined, primaryFilter.from, primaryFilter.to);
  const orgUnits = useMemo(
    () => (hoverOrgKey ? [orgData?.get(hoverOrgKey)] : primaryFilter.orgKeys.map((key) => orgData?.get(key))),
    [hoverOrgKey, orgData, primaryFilter]
  );

  const primaryDuration = useMemo(
    () =>
      ({
        from: primaryFilter.from,
        to: primaryFilter.to,
        weekdayFilter,
        mode: DurationModes.Custom,
      } as Duration),
    [primaryFilter, weekdayFilter]
  );
  const [_primaryData, _primaryDataLoading, _primaryDataError, getPrimaryDataByGroupingKey] = useMetricQuery(
    orgKeys,
    primaryValueExpression,
    primaryDuration,
    undefined,
    DateRangeGrouping.day
  );
  const [_compareData, _compareDataLoading, _compareDataError, getCompareDataByGroupingKey] = useMetricQuery(
    orgKeys,
    primaryValueExpression,
    primaryDuration,
    undefined,
    DateRangeGrouping.day,
    true,
    !!compareFilter
  );

  const barChartData = useMemo(
    () =>
      getBarChartData(
        primaryFilter,
        compareFilter,
        thresholdDataBarsCount,
        thresholdAxisBarsCount,
        orgUnits,
        primaryText,
        compareText,
        getPrimaryDataByGroupingKey,
        getCompareDataByGroupingKey
      ),
    [
      orgUnits,
      compareFilter,
      primaryFilter,
      getPrimaryDataByGroupingKey,
      getCompareDataByGroupingKey,
      primaryText,
      compareText,
    ]
  );

  if (!barChartData) {
    return null;
  }
  const formatter = primaryValueExpression.getValueFormatter();

  return (
    <Chart
      chartType={primaryValueExpression?.chartType}
      data={barChartData}
      options={{
        animation: {
          duration: 0,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                autoSkip: true,
                autoSkipPadding: 10,
                maxRotation: 0,
                fontColor: barChartData.labelsCompare ? 'var(--primary)' : '#333333',
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                callback: (value) =>
                  Number(value).toLocaleString('de-DE', {
                    maximumFractionDigits: 20,
                  }),
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            title: (tooltipItems, data: any) => {
              const dataset = data?.datasets?.[tooltipItems?.[0]?.datasetIndex];
              const labels = dataset?.primary ? data?.dataLabels : data?.compareDataLabels;
              return labels?.[tooltipItems?.[0]?.index];
            },
            label: (tooltipItem, data) => {
              let label = data?.datasets?.[tooltipItem?.datasetIndex]?.label || '';
              if (label) {
                label += ': ';
              }
              label += formatter(Number(tooltipItem?.yLabel));
              return label;
            },
          },
        },
      }}
    />
  );
};

export default PrimaryTimelineChart;
