import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import istLademenge from './lademenge-ist';
import psAbgaben from './ps-abgaben';
import zustellungen from './zustellungen';

const expression = new QuotientValueExpression(
  new SumValueExpression(zustellungen, psAbgaben),
  istLademenge,
  'Fortschritt',
  true
);

export const fortschrittMetricKey = 'fortschritt';

expression.setIdentifier(fortschrittMetricKey);

export default expression;
