export const MarkerOffset = {
  selected: {
    'bottom-left': [12, -60],
    bottom: [0, -60],
    'bottom-right': [-12, -60],
  },
  unselected: {
    'bottom-left': [12, -16],
    bottom: [0, -16],
    'bottom-right': [-12, -16],
  },
};
