import React from 'react';
import Styles from './Dot.module.scss';
import classNames from 'classnames';

export enum DotColor {
  primary = 'primary',
  compare = 'compare',
  positive = 'positive',
  negative = 'negative',
  warning = 'warning',
  neutral = 'neutral',
}

type Props = {
  small?: boolean;
  color?: DotColor | string;
  style?: React.CSSProperties;
};

export const Dot: React.FC<Props> = ({ small = false, color = '', style = {} }) => {
  const customStyle = Object.values<string>(DotColor).includes(color) ? style : { ...style, backgroundColor: color };

  return <span style={customStyle} className={classNames(Styles.dot, Styles[color], { [Styles.small]: small })} />;
};
