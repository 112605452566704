import React, { MouseEventHandler, FC, ReactElement } from 'react';
import style from './tab.module.scss';
import classNames from 'classnames';

type Props = React.PropsWithChildren<{
  isActive?: boolean;
  onTabClick?: MouseEventHandler<HTMLElement>;
}>;

const Tab: FC<Props> = ({ isActive, onTabClick, children }): ReactElement => {
  return (
    <section
      className={classNames(style.tab, {
        [style.active]: isActive,
      })}
      onClick={onTabClick}>
      {children}
    </section>
  );
};
export default Tab;
