import React from 'react';
import OverviewStatsContainer from '../../overview/containers/OverviewStatsContainer';
import { useSelector } from 'react-redux';
import OverviewMapContainer from '../../overview/containers/OverviewMapContainer';
import DashboardContainer from '../../dashboard/components/DashboardContainer';
import Styles from './ContentWrapper.module.scss';
import { LeftRight } from './LeftRight';
import { TourTablePage } from './TourTablePage';
import { TourPage } from './TourPage';
import classNames from 'classnames';
import { selectActiveView, selectActiveDashboardView } from '@redux';
import { OverviewContextProvider } from '@contexts/overview-context';
import { TourDetailsContextProvider } from '@other-components/tour-details-context';

type Props = {};

const FullScreenComponents = {
  'tour_loaded.list': (
    <TourDetailsContextProvider>
      <TourPage />
    </TourDetailsContextProvider>
  ),
  'tour_loaded.count': <TourTablePage />,
};

const ContentWrapper: React.FC<Props> = () => {
  const viewMode = useSelector(selectActiveView);
  const activeDashboard = useSelector(selectActiveDashboardView);
  const isInSplitView = viewMode !== 'dashboard';
  const isInFullscreen =
    viewMode === 'dashboard' && ['tour_loaded.list', 'tour_loaded.count'].includes(activeDashboard);

  if (isInSplitView) {
    return (
      <div className={classNames(Styles.ContentWrapper, Styles.SplitView)}>
        <OverviewContextProvider>
          <LeftRight
            left={<OverviewStatsContainer />}
            right={<OverviewMapContainer />}
            onSectionToggled={() => window.dispatchEvent(new Event('resize'))}
          />
        </OverviewContextProvider>
      </div>
    );
  }

  if (isInFullscreen) {
    return (
      <div className={classNames(Styles.ContentWrapper, Styles.SplitView)}>{FullScreenComponents[activeDashboard]}</div>
    );
  }

  return (
    <div className={Styles.ContentWrapper}>
      <DashboardContainer />
    </div>
  );
};

export default ContentWrapper;
