import moment from 'moment-timezone';
import CacheDataConnection from '../../../datamanager/cache/CacheDataConnection';
import { MetricDataConnectionOutputFormats } from '../enumerations/MetricDataConnectionOutputFormat';
import { MetricsValue } from '../entities/MetricsValue';
import MetricsEntityKey from '../entities/MetricsEntityKey';
import { MetricsCacheEntity } from '../entities/MetricsCacheEntity';
import MetricDataUtils from '../../converters/MetricDataUtils';

export default class MetricDataConnection extends CacheDataConnection<any, any> {
  outputFormat: MetricDataConnectionOutputFormats = MetricDataConnectionOutputFormats.dateArray;
  sumValueKeys: boolean = false;
  _triggerLoading: () => void;

  setLoading(isLoading: boolean) {
    this._isLoading = isLoading;
    if (isLoading) {
      this._triggerLoading();
    }
  }

  updateData(data: Map<MetricsEntityKey, MetricsCacheEntity>, isComplete: boolean, isLoading: boolean) {
    this._lastUpdateAt = moment();
    this._data = data;
    this._isComplete = isComplete;
    this.setLoading(isLoading);
    this._triggerDataChanged();
  }

  setOutputFormat(format: MetricDataConnectionOutputFormats) {
    this.outputFormat = format;
  }

  setSumValueKeys(active: boolean = true) {
    this.sumValueKeys = active;
  }

  get dataSingle(): MetricsValue | null | undefined {
    return MetricDataUtils.metricData2Single(this._data);
  }

  get dataDateArray(): Array<MetricsValue> {
    return MetricDataUtils.metricData2DateArray(this._data);
  }

  get dataDateObject(): {
    [key: string]: MetricsValue;
  } {
    return MetricDataUtils.metricData2DateObject(this._data);
  }

  get dataMappedDateArrays():
    | {
        [key: string]: Array<number>;
      }
    | Array<number> {
    return MetricDataUtils.metricData2MappedDateArrays(this._data);
  }

  get dataEntities(): Map<MetricsEntityKey, number> {
    return this._data;
  }

  get data(): any {
    switch (this.outputFormat) {
      case MetricDataConnectionOutputFormats.single:
        return this.dataSingle;
      case MetricDataConnectionOutputFormats.dateObject:
        return this.dataDateObject;
      case MetricDataConnectionOutputFormats.mappedDateArrays:
        return this.dataMappedDateArrays;
      case MetricDataConnectionOutputFormats.entities:
        return this.dataEntities;
      case MetricDataConnectionOutputFormats.dateArray:
      default:
        return this.dataDateArray;
    }
  }
}
