import { useEffect } from 'react';

enum MouseButton {
  Left = 1,
  Middle = 2,
  Right = 3,
}

export const useOnClickOutside = (
  target: React.RefObject<any>,
  onClickOutside?: (e: MouseEvent) => void,
  // On which mouse button click the callback is called. Default is only the left mouse button
  filterMouseButtonType: MouseButton[] = [MouseButton.Left]
) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!target.current || target.current.contains(e.target)) {
        return;
      }
      if (filterMouseButtonType.includes(e.which)) {
        onClickOutside && onClickOutside(e);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [onClickOutside, target]);
};
