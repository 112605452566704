import { useAuthContext } from '@contexts/auth-context';
import { useOrgTreeData } from '@hooks/use-org-tree-data-hook';
import { AppInitializing } from '@legacy-modules/app/components/AppInitializing';
import { getMappedViewMode } from '@legacy-modules/app/models/enumerations/ViewMode';
import {
  dashboardSlice,
  selectActiveView,
  selectDashboardOrgKey,
  selectOverviewOrgKey,
  selectRootOrgKeys,
} from '@redux';
import { ViewMode } from '@redux/app.slice';
import { injectContractorViewMode } from '@legacy-modules/dashboard/utils/OrgKeyUtils';
import OrgTree from '@legacy-modules/metrics2/models/websocket/org/OrgTree';
import { ContractorViewMode } from '@legacy-modules/navigation/constants/ContractorViewMode';
import React, { createContext, PropsWithChildren, ReactElement, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type OrgTreeContextType = {
  orgTree: OrgTree;
  viewMode: ViewMode;
  orgKey: string;
} | null;

const defaultValue: OrgTreeContextType = null;
export const OrgTreeContext = createContext(defaultValue);

export const useOrgTreeContext = (): OrgTreeContextType => {
  return useContext<OrgTreeContextType>(OrgTreeContext);
};

type OrgTreeContextProviderProps = Required<PropsWithChildren<{}>>;

export default function OrgTreeContextProvider({ children }: OrgTreeContextProviderProps): ReactElement {
  const dispatch = useDispatch();
  const rootOrgKeys = useSelector(selectRootOrgKeys);
  const viewMode = useSelector(selectActiveView);
  const orgKey = useSelector<unknown, string>(viewMode === 'overview' ? selectOverviewOrgKey : selectDashboardOrgKey);
  const viewModeKey = useMemo(() => {
    return getMappedViewMode(viewMode ?? 'dashboard')?.key;
  }, [viewMode]);

  const orgTreeData = useOrgTreeData(viewModeKey, !!viewModeKey && viewMode !== 'loading');

  const orgTree = useMemo(() => {
    if (!orgTreeData?.node) {
      return null;
    }
    return OrgTree.createFromPlainData(orgTreeData.node);
  }, [orgTreeData]);

  useEffect(() => {
    if (!orgTree) {
      return;
    }
    const userCanSeeOrgUnit =
      orgKey &&
      !!orgTree.findOrgUnitValueByFunction(
        (v) => v?.orgUnit?.orgKey === injectContractorViewMode(orgKey, ContractorViewMode.All)
      );
    if (!userCanSeeOrgUnit) {
      const viewModeOrgKeys = rootOrgKeys?.[viewModeKey];
      const firstAllowedOrgKey = orgTree.findOrgUnitValueByFunction((v) => {
        return viewModeOrgKeys.includes(v?.orgUnit?.orgKey);
      });
      dispatch(dashboardSlice.actions.setOrgKey(firstAllowedOrgKey?.orgUnit?.orgKey ?? null));
    }
  }, [orgTree, dispatch, rootOrgKeys, viewModeKey, orgKey]);

  const context = useMemo(
    () => ({
      orgTree,
      viewMode,
      orgKey,
    }),
    [orgTree, viewMode, orgKey]
  );

  return orgTree && orgKey ? (
    <OrgTreeContext.Provider value={context}>{children}</OrgTreeContext.Provider>
  ) : (
    <AppInitializing loadingText={'Lade Organisationsbaum...'} />
  );
}
