import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import nettoHtZeit from './netto-ht-zeit';
import nettoAtgZeit from './netto-atg-zeit';
import nettoPsZeit from './netto-ps-zeit';

const expression = new SumValueExpression(
  nettoHtZeit,
  new SumValueExpression(nettoAtgZeit, nettoPsZeit),
  'Netto-Zustellzeit',
  'Zeit vom ersten bis zum letzten Stopp bereinigt um Pausenzeiten.',
  '-#.###.##0,0 h',
  getDefinitionByName('Netto-Zustellzeit')
);

expression.setIdentifier('netto-zustellzeit');

export default expression;
