export default function createMapMap<T>(source: Object, constructor: new (val: any) => T): Map<any, any> {
  if (source instanceof Map) {
    return new Map(Array.from(source).map(([key, val]) => [key, new constructor(val)]));
  }
  const items = new Map();
  Object.entries(source).forEach(([key, val]) => items.set(key, new constructor(val)));
  return items;
}

export function createObjectForEntities<T>(
  source: T | Map<string, T>,
  constructor: new (val: any) => T
): { [k: string]: T } {
  const entries = source instanceof Map ? source.entries() : Object.entries(source);

  const result = [...entries].reduce((acc, curr) => {
    const [key, val] = curr;
    return {
      ...acc,
      [key]: new constructor(val),
    };
  }, {});
  return result;
}
