import moment from 'moment-timezone';
import ValueExpression from '../valueexpressions/ValueExpression';
import ValueExpressionBaseQuery from './ValueExpressionBaseQuery';
import MetricsOverviewQuery from '../../../metrics2/models/queries/MetricsOverviewQuery';
import MetricType from '../../../metrics2/models/entities/MetricType';
import { WeekdaysFilter, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';
import { MetricTypeKey } from '@contexts/value-expression-context/value-expressions/metric-type-keys';

export default class ValueExpressionOverviewQuery extends ValueExpressionBaseQuery {
  valueExpression: ValueExpression;
  orgKeys: Array<string>;

  constructor(
    valueExpression: ValueExpression,
    orgKeys: Array<string>,
    dateFrom: moment.Moment,
    dateUntil: moment.Moment,
    valueKey: string | null = null,
    public readonly contractorKey: string | null = null,
    public readonly weekdayFilter: WeekdaysFilter = defaultWeekdaysFilter
  ) {
    super(dateFrom, dateUntil, weekdayFilter, valueKey);
    this.valueExpression = valueExpression;
    this.orgKeys = orgKeys;
  }

  getMetricQueries(): Array<MetricsOverviewQuery> {
    return this.valueExpression
      .getRequiredMetricTypes()
      .map(({ type, valueKey }: { type: MetricType; valueKey?: string }) => {
        return new MetricsOverviewQuery(
          [type.key as MetricTypeKey],
          this.orgKeys,
          this.dateFrom,
          this.dateUntil,
          this.weekdayFilter,
          valueKey,
          this.contractorKey,
          type.aggregation ? [type.aggregation] : []
        );
      });
  }
}
