import React, { ReactElement } from 'react';
import Logger from '../../utils/logging/Logger';
import { intersection } from 'lodash';
import { WellKnownPermission } from '../constants/WellKnownPermission';
import { useAuthContext } from '@contexts/auth-context';

const logger = Logger.getInstance('AuthGuard');

type Props = {
  requiredPermissions?: WellKnownPermission[];
  absencePermissions?: WellKnownPermission[];
  children?: React.ReactNode;
  notAllowedComponent?: ReactElement;
};

const AuthGuard: React.FC<Props> = ({
  requiredPermissions = [],
  absencePermissions = [],
  children,
  notAllowedComponent = null,
}: Props) => {
  const authService = useAuthContext();

  if (!authService) {
    throw new Error('Fatal error: AuthService must be set!');
  }

  const wrongPermissionConfiguration = intersection(requiredPermissions, absencePermissions);

  if (wrongPermissionConfiguration.length) {
    logger.error(`Wrong permission entries for: ${wrongPermissionConfiguration.join(',')}`);
    throw new Error('You cannot require and require absence of a permission');
  }

  const hasMissingPermission = requiredPermissions.some((req) => authService.cannot(req));

  const hasAbsencePermission = absencePermissions.some((abReq) => authService.can(abReq));

  if (hasMissingPermission || hasAbsencePermission) {
    return notAllowedComponent;
  }

  return <>{children}</>;
};

export default AuthGuard;
