/**
 * Coding for an atomic delivery area.
 *
 * Example:
 * - 43-43-12
 *
 * @see https://wiki.prd.otc.hlg.de/pages/viewpage.action?pageId=201121621
 */
export class RsTeZe {
  constructor(readonly rs: string, readonly te: string, readonly ze: string) {}

  static parseFromString(rstezeString: string): RsTeZe {
    if (!rstezeString) {
      throw new Error('No string provided');
    }
    if (typeof rstezeString !== 'string') {
      throw new Error('Invalid type for rsteze string');
    }

    const parts = rstezeString.split('-');
    if (parts.length !== 3) {
      throw new Error('Invalid format for rsteze. Format needs to be: RS-TE-ZE');
    }

    const [rs, te, ze] = parts;

    return new RsTeZe(rs, te, ze);
  }
}
