import React, { useCallback, useRef, useState } from 'react';
import OrgUnitNode from '../../metrics2/models/websocket/org/OrgUnitNode';
import { getTreeMenuDepthForNode, TreeMenu, TreeMenuNode } from '../TreeMenu';
import AuthGuard from '../../auth/components/AuthGuard';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import Styles from './OrganizationMultiSelection.module.scss';
import { useOrgTree } from '../hooks/useOrgTree';
import classNames from 'classnames';
import { CopyButton } from './CopyButton';
import moment from 'moment/moment';
import OrgUnit from '../../metrics2/models/entities/organization/OrgUnit';
import { useOrgTreeContext } from '@contexts/org-tree-context';

type Props = {
  multiSelectionEnabled?: boolean;
  onNodeSelection: (nodes: Array<TreeMenuNode<OrgUnitNode['data']>>) => void;
  selectedNodes: Array<TreeMenuNode<OrgUnitNode['data']>>;
  visible?: boolean;
};

type OrgItemProps = {
  orgKey: string;
  label: string;
  selected: boolean;
  hovering: boolean;
  isExpired?: boolean;
};

export const isOrgUnitExpired = (orgUnit: OrgUnit) => {
  if (!orgUnit?.validTill) {
    return false;
  }

  // moment.js does not support dates like: +999999999-12-31 and falls back to standard js date
  // and print a warning in console
  const cleanedValidTill = orgUnit.validTill.replace(/\+999999999/, '9999');

  const validTillMoment = moment(cleanedValidTill);
  // per definition all invalid dates are not expired
  const isExpiredUnit = validTillMoment.isValid() && moment(cleanedValidTill).isBefore(moment());
  return isExpiredUnit;
};

export const formatOrgUnitLabel = (orgUnit: OrgUnit) => {
  const isExpiredUnit = isOrgUnitExpired(orgUnit);

  const label = isExpiredUnit ? `${orgUnit?.name} - abgelaufen` : orgUnit?.name;
  return label;
};

const OrgItem: React.FC<OrgItemProps> = ({ orgKey, label, selected, hovering, isExpired = false }) => {
  return (
    <div
      className={classNames(Styles.OrgItem, {
        [Styles.selected]: selected,
        [Styles.expired]: isExpired,
      })}>
      <span
        className={classNames(Styles.Label, {
          [Styles.hovering]: hovering,
        })}>
        {label}
      </span>
      <AuthGuard requiredPermissions={[WellKnownPermission.AdminCopyOrgId]}>
        <span className={Styles.CopyButtonWrapper}>
          <CopyButton textToCopy={orgKey} />
        </span>
      </AuthGuard>
    </div>
  );
};

export const OrganizationMultiSelection: React.FC<Props> = ({
  multiSelectionEnabled = true,
  onNodeSelection,
  selectedNodes,
  visible = true,
}: Props) => {
  const { viewMode, orgTree } = useOrgTreeContext();

  const { rootNodes } = useOrgTree(orgTree, viewMode);

  const defaultOpened = useRef(rootNodes);

  const showCollapseIconCallback = useCallback(
    (node: TreeMenuNode<OrgUnitNode['data']>) => {
      return node.data.getIdentifier() !== rootNodes[0].data.getIdentifier();
    },
    [rootNodes]
  );

  const divRef = useRef<HTMLDivElement>(null);

  const [selectedLevel, setSelectedLevel] = useState<number | null>(null);

  const onNodeSelectionCallback = useCallback(
    (ids: Array<TreeMenuNode<OrgUnitNode['data']>>) => {
      if (ids?.length) {
        setSelectedLevel(getTreeMenuDepthForNode(ids[0]));
      } else {
        setSelectedLevel(null);
      }
      onNodeSelection(ids);
    },
    [onNodeSelection]
  );

  const renderFn = (
    node: TreeMenuNode<OrgUnitNode['data']> & OrgUnitNode,
    depth: number,
    selected: boolean,
    hovering: boolean
  ) => {
    const isExpiredUnit = isOrgUnitExpired(node?.orgUnit);

    return (
      <OrgItem
        isExpired={isExpiredUnit}
        orgKey={node.orgUnit.orgKey}
        label={node.data.toString()}
        selected={selected}
        hovering={hovering}
      />
    );
  };

  const isCheckableCallback = useCallback(
    (node, depth) => (selectedLevel ? selectedLevel === depth : depth > 1),
    [selectedLevel]
  );

  if (!visible) {
    return null;
  }

  return (
    <div ref={divRef}>
      <TreeMenu
        tree={rootNodes}
        onNodeSelection={onNodeSelectionCallback}
        customLeaf={renderFn}
        defaultOpenedNodes={defaultOpened.current}
        isCollapsable={showCollapseIconCallback}
        isCheckable={isCheckableCallback}
        multiSelection={multiSelectionEnabled}
        selectedNodes={
          multiSelectionEnabled ? selectedNodes.filter((s) => s.data.getIdentifier() !== 'country:de') : selectedNodes
        }
      />
    </div>
  );
};

export default OrganizationMultiSelection;
