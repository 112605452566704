import { abhMitReturnTypeMap, abhMitReturnTypes } from '@legacy-modules/metrics2/models/entities/MetricType';
import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import mitnahmenByReturnType from './mitnahmen-by-return-type';

export default abhMitReturnTypes.flatMap((key) => {
  const expression = new MapDetailValueExpression(
    mitnahmenByReturnType.metricType,
    key,
    abhMitReturnTypeMap[key] ?? `unbekannt (${key})`
  );

  expression.setIdentifier(`${mitnahmenByReturnType.identifier}.${key}`);
  return [expression, ...(expression.getChildren() || [])];
});
