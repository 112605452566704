import * as yup from 'yup';
import { lazy } from 'yup';
import { de } from 'yup-locales';
import { setLocale } from 'yup';

setLocale(de);

export interface ErrorMap {
  [key: string]: { message: string; type: string } | undefined;
}

const defaultGoalSchemaShape = {
  positiveVal: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .test('required', 'Es müssen beide oder kein Wert ausgefüllt sein', (value, context) => {
      const { parent } = context;
      if (typeof parent.negativeVal === 'number' && (value == null || isNaN(value))) {
        return false;
      }
      return true;
    })
    .test('goalDirection', 'Wert muss größer oder gleich dem rotem Wert sein', (value, context) => {
      const { parent } = context;
      const { direction, negativeVal } = parent;

      if (typeof value !== 'number') {
        return true;
      }

      if (direction === 'up') {
        return negativeVal <= value;
      }
      return true;
    })
    .test('goalDirection', 'Wert muss kleiner oder gleich dem rotem Wert sein', (value, context: any) => {
      const { parent } = context;
      const { direction, negativeVal } = parent;

      if (typeof value !== 'number' || typeof negativeVal !== 'number') {
        return true;
      }

      if (direction === 'down') {
        return negativeVal >= value;
      }
      return true;
    })
    .test('defaultLimits', 'Zielwert Hermes beachten', (value, context: any) => {
      const { parent } = context;
      const { direction, defaultLimits } = parent;

      if (!defaultLimits || typeof defaultLimits.positive !== 'number' || typeof value !== 'number') {
        return true;
      }

      if (direction === 'down') {
        return defaultLimits.positive >= value;
      } else if (direction === 'up') {
        return defaultLimits.positive <= value;
      }
      return true;
    }),
  negativeVal: yup
    .number()
    .test('required', 'Es müssen beide oder kein Wert ausgefüllt sein', (value, context) => {
      const { parent } = context;
      if (typeof parent.positiveVal === 'number' && (value == null || isNaN(value))) {
        return false;
      }
      return true;
    })
    .test('goalDirection', 'Wert muss kleiner oder gleich dem grünen Wert sein', (value, context) => {
      const { parent } = context;
      const { direction, positiveVal } = parent;

      if (typeof value !== 'number') {
        return true;
      }

      if (direction === 'up') {
        return positiveVal >= value;
      }
      return true;
    })
    .test('goalDirection', 'Wert muss größer oder gleich dem grünen Wert sein', (value, context: any) => {
      const { parent } = context;
      const { direction, positiveVal } = parent;

      if (typeof value !== 'number' || typeof positiveVal !== 'number') {
        return true;
      }

      if (direction === 'down') {
        return positiveVal <= value;
      }
      return true;
    })
    .test('defaultLimits', 'Zielwert Hermes beachten', (value, context: any) => {
      const { parent } = context;
      const { direction, defaultLimits } = parent;

      if (!defaultLimits || typeof defaultLimits.negative !== 'number' || typeof value !== 'number') {
        return true;
      }

      if (direction === 'down') {
        return defaultLimits.negative >= value;
      } else if (direction === 'up') {
        return defaultLimits.negative <= value;
      }
      return true;
    }),
};

const customSchema = yup.object().shape({
  ...defaultGoalSchemaShape,
  direction: yup.string().test('required', 'Richtung wird benötigt', (value, context: any) => {
    const { parent } = context;
    const { positiveVal, negativeVal } = parent;

    return (positiveVal == null && negativeVal == null) || (positiveVal != null && negativeVal != null && !!value);
  }),
});

const corridorSchema = yup.object().shape({
  targetAreaFirst: yup.number().test('required', 'Es müssen beide oder kein Wert ausgefüllt sein', (value, context) => {
    const { parent } = context;
    if (typeof parent.targetAreaSecond === 'number' && (value == null || isNaN(value))) {
      return false;
    }
    return true;
  }),
  targetAreaSecond: yup
    .number()
    .test('required', 'Es müssen beide oder kein Wert ausgefüllt sein', (value, context) => {
      const { parent } = context;
      if (typeof parent.targetAreaFirst === 'number' && (value == null || isNaN(value))) {
        return false;
      }
      return true;
    }),
});

export const limitValidationSchema = lazy((data) => {
  const { activeViewMode } = data;

  return activeViewMode === 'limit' || activeViewMode === null ? customSchema : corridorSchema;
});
