import { useState, useCallback } from 'react';

type UseToggleStateOutput = [value: boolean, callback: (e?: Event) => void];
function useToggleState(initialValue: boolean = false): UseToggleStateOutput {
  const [value, setValue] = useState(initialValue);
  const callback = useCallback((e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setValue((prev) => !prev);
  }, []);
  return [value, callback];
}

export default useToggleState;
