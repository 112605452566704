import moment from 'moment-timezone';
import Model, { DefaultType, MemberType } from '../../../utils/Model';
import GeoCoordinate from '../../../geo/models/GeoCoordinate';
import { PlainObjectOf } from '../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export default class UnfinishedCustomerPlanDetails extends Model<UnfinishedCustomerPlanDetails> {
  customerRef: string;
  estimatedAt: moment.Moment | null | undefined;
  location: GeoCoordinate | null | undefined;
  remainingDistance: number | null | undefined;

  constructor(source: PlainObjectOf<UnfinishedCustomerPlanDetails>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<UnfinishedCustomerPlanDetails> {
    return {
      estimatedAt: () => null,
      location: () => null,
    };
  }

  getMembers(): MemberType<UnfinishedCustomerPlanDetails> {
    return {
      location: (src) => (src ? new GeoCoordinate(src) : null),
    };
  }
}
