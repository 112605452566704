import Logger from '../../utils/logging/Logger';
import { ConfigKey, EnvironmentConfigurationService } from '../../common/services/EnvironmentConfigurationService';
import { selectTokenId } from '@redux/auth.selectors';
import { lmaReduxStore } from '@redux/store';

const apiUrl = EnvironmentConfigurationService.get(ConfigKey.HTTP_API_URL);

export default class OrgNameDataProvider {
  _logger: Logger = Logger.getInstance('OrgNameDataProvider');

  private static getUrl(token: string, orgIds: string[] = []): string {
    const uniqueOrgIds = new Set([...orgIds]);
    const urlEncodedIds = [...uniqueOrgIds].map((v) => `ids=${v}`).join('&');
    const urlParameter = urlEncodedIds.length ? `&${urlEncodedIds}` : '';

    return `${apiUrl}/orgunits/names?token=${token}${urlParameter}`;
  }

  async query(orgKeys: string[]): Promise<{ [key: string]: string }> | null {
    if (!orgKeys?.length) {
      return null;
    }
    const url = OrgNameDataProvider.getUrl(selectTokenId(lmaReduxStore.getState()), orgKeys);
    return fetch(url).then(this.onResponse.bind(this), this.onError.bind(this));
  }

  private onError(error) {
    this._logger.error('Org name query failed', error);
    return undefined;
  }

  private onResponse(res) {
    if (res && Math.floor(res.status / 100) === 2) {
      return res.json();
    }
    if (res && res.status === 404) {
      return null;
    } else {
      this._logger.error('Org name query failed', res);
      return undefined;
    }
  }
}
