/* eslint-disable max-len */
import React, { ReactElement, HTMLAttributes } from 'react';
import style from './active-marker-icon.module.scss';
import classNames from 'classnames';
import Color from 'color';

export type ActiveMarkerIconProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Text shown on the icon
   */
  text?: string;
  /**
   * Icon shown in center of the marker
   */
  innerIcon?: ReactElement;
  /**
   * @default 52
   */
  width?: number;
  /**
   * @default 60
   */
  height?: number;
  /**
   * @default "#008000"
   */
  color?: string;
};

export function ActiveMarkerIcon({
  text,
  innerIcon,
  color = '#008000',
  width = 40,
  height = 50,
  ...htmlProps
}: ActiveMarkerIconProps): ReactElement {
  const darkenColor = new Color(color).darken(50).toString() || '#000';
  return (
    <div className={classNames(style.activeMarkerIcon)} {...htmlProps}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='454 287 64 64'
        width={width}
        height={height}
        preserveAspectRatio='xMidYMid slice'>
        <defs>
          <style>
            {`.cls-1 {
              fill: ${color};
            }
              .cls-2 {
              fill: url(#linear-gradient);
            }
              .cls-3 {
              fill: none;
              stroke: #fff;
              stroke-width: 3px;
            }
              .cls-4 {
              fill: var(--white);
            }`}
          </style>
          <filter x='-58.9%' y='-32.8%' width='217.9%' height='203.1%' filterUnits='objectBoundingBox' id='dropwshadow'>
            <feOffset dx='0' dy='1' in='SourceAlpha' result='shadowOffsetOuter1' />
            <feGaussianBlur stdDeviation='2.5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
            <feColorMatrix
              values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0'
              type='matrix'
              in='shadowBlurOuter1'
              result='shadowMatrixOuter1'
            />
            <feOffset dx='0' dy='3' in='SourceAlpha' result='shadowOffsetOuter2' />
            <feGaussianBlur stdDeviation='2' in='shadowOffsetOuter2' result='shadowBlurOuter2' />
            <feColorMatrix
              values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.12 0'
              type='matrix'
              in='shadowBlurOuter2'
              result='shadowMatrixOuter2'
            />
            <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter3' />
            <feGaussianBlur stdDeviation='2' in='shadowOffsetOuter3' result='shadowBlurOuter3' />
            <feColorMatrix
              values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.14 0'
              type='matrix'
              in='shadowBlurOuter3'
              result='shadowMatrixOuter3'
            />
            <feMerge>
              <feMergeNode in='shadowMatrixOuter1' />
              <feMergeNode in='shadowMatrixOuter2' />
              <feMergeNode in='shadowMatrixOuter3' />
              <feMergeNode in='SourceGraphic' />
            </feMerge>
          </filter>
          <linearGradient id={`linear-gradient`} x1='0.5' x2='0.5' y2='0.986' gradientUnits='objectBoundingBox'>
            <stop offset='0' stopColor='#fff' stopOpacity='0.2' />
            <stop offset='1' stopColor={darkenColor} stopOpacity='0.2' />
          </linearGradient>
        </defs>
        <g
          id='_200_Archiv_Live---Marker'
          data-name='200_Archiv_Live---Marker'
          transform='translate(358 277)'
          opacity={1}>
          <g id='Molecule_-Marker_Sendungen_on' data-name='Molecule/-Marker_Sendungen_on' transform='translate(88)'>
            <g id='Sendungen_on' filter='url(#dropwshadow)' transform='translate(18 10)'>
              <g id='Shape'>
                <path
                  id='Pfad_8'
                  data-name='Pfad 8'
                  className={`cls-1`}
                  d='M22,0A22.186,22.186,0,0,0,0,22.4C0,39.2,22,64,22,64S44,39.2,44,22.4A22.186,22.186,0,0,0,22,0Z'
                />
                <path
                  id='Pfad_9'
                  data-name='Pfad 9'
                  className={`cls-2`}
                  d='M22,0A22.186,22.186,0,0,0,0,22.4C0,39.2,22,64,22,64S44,39.2,44,22.4A22.186,22.186,0,0,0,22,0Z'
                />
                <path
                  id='Pfad_10'
                  data-name='Pfad 10'
                  className={`cls-3`}
                  d='M22,61.7c.691-.821,1.451-1.748,2.265-2.768,2.552-3.2,5.1-6.664,7.485-10.256a89.633,89.633,0,0,0,7.586-13.623C41.4,30.279,42.5,26.013,42.5,22.4A20.687,20.687,0,0,0,22,1.5,20.687,20.687,0,0,0,1.5,22.4c0,3.613,1.1,7.879,3.164,12.649A89.633,89.633,0,0,0,12.25,48.671c2.38,3.592,4.933,7.054,7.485,10.256C20.549,59.948,21.309,60.875,22,61.7Z'
                />
              </g>
              {text && (
                <text x='22' y='32' textAnchor='middle' fontSize='20' fill='white' className={style?.iconText}>
                  {text}
                </text>
              )}
              {innerIcon && (
                <g id='ic_place_white' data-name='ic/place_white' transform={`translate(6 10) scale(1.8 1.8)`}>
                  {innerIcon}
                </g>
              )}
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
export default ActiveMarkerIcon;
