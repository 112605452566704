import { ILimitBounds } from '@legacy-modules/dashboard/websocket/DashboardLoadLimitResponsePayload';

export const defaultLimitsMap = new Map<string, ILimitBounds>([
  [
    'same_day.e_plus_0',
    {
      positive: 0.975,
      negative: 0.975,
    },
  ],
  [
    'same_day.e_plus_0_express',
    {
      positive: 0.985,
      negative: 0.985,
    },
  ],
]);
