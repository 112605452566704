import DeliveredOff from '/assets/markers/delivered_off.png';
import DeliveredHover from '/assets/markers/delivered_hover.png';
import DeliveredOn from '/assets/markers/delivered_on.png';
import DeliveredOnGrayscale from '/assets/markers/delivered_on_grayscale.png';
import DeliveriesHover from '/assets/markers/deliveries_hover.png';
import DeliveriesOff from '/assets/markers/deliveries_off.png';
import DeliveriesOn from '/assets/markers/deliveries_on.png';
import DriverHover from '/assets/markers/driver_hover.png';
import DriverOff from '/assets/markers/driver_off.png';
import DriverOn from '/assets/markers/driver_on.png';
import UndeliverableHover from '/assets/markers/undeliverable_hover.png';
import UndeliverableOff from '/assets/markers/undeliverable_off.png';
import UndeliverableOn from '/assets/markers/undeliverable_on.png';
import UndeliverableOnGrayscale from '/assets/markers/undeliverable_on_grayscale.png';

export const MarkerIcon = {
  DeliveredHover: DeliveredHover,
  DeliveredOff: DeliveredOff,
  DeliveredOn: DeliveredOn,
  DeliveredOnGrayscale: DeliveredOnGrayscale,
  DeliveriesHover: DeliveriesHover,
  DeliveriesOff: DeliveriesOff,
  DeliveriesOn: DeliveriesOn,
  DriverHover: DriverHover,
  DriverOff: DriverOff,
  DriverOn: DriverOn,
  UndeliverableHover: UndeliverableHover,
  UndeliverableOff: UndeliverableOff,
  UndeliverableOn: UndeliverableOn,
  UndeliverableOnGrayscale: UndeliverableOnGrayscale,
};

export const MarkerSize = {
  delivered: {
    on: {
      iconSize: [80, 80],
      iconAnchor: [40, 70],
      popupAnchor: [0, 0],
    },
    off: {
      iconSize: [48, 48],
      iconAnchor: [24, 24],
      popupAnchor: [0, 0],
    },
    hover: {
      iconSize: [48, 48],
      iconAnchor: [24, 24],
      popupAnchor: [0, 0],
    },
  },
  undeliverable: {
    on: {
      iconSize: [80, 80],
      iconAnchor: [40, 70],
      popupAnchor: [0, 0],
    },
    off: {
      iconSize: [48, 48],
      iconAnchor: [24, 24],
      popupAnchor: [0, 0],
    },
    hover: {
      iconSize: [48, 48],
      iconAnchor: [24, 24],
      popupAnchor: [0, 0],
    },
  },
  deliveries: {
    on: {
      iconSize: [80, 80],
      iconAnchor: [40, 70],
      popupAnchor: [0, 0],
    },
    off: {
      iconSize: [48, 48],
      iconAnchor: [24, 24],
      popupAnchor: [0, 0],
    },
    hover: {
      iconSize: [48, 48],
      iconAnchor: [24, 24],
      popupAnchor: [0, 0],
    },
  },
  driver: {
    on: {
      iconSize: [80, 80],
      iconAnchor: [40, 80],
      popupAnchor: [0, 0],
    },
    off: {
      iconSize: [48, 48],
      iconAnchor: [24, 48],
      popupAnchor: [0, 0],
    },
    hover: {
      iconSize: [48, 48],
      iconAnchor: [24, 48],
      popupAnchor: [0, 0],
    },
  },
};
