import { DataTableBase } from '@data-table/components/data-table-base';
import { BeanstandungenDataTableDataType, DashboardDataTableDataType, HinweisData } from '@data-table/data-table.types';
import { withDataTableBaseConfig } from '@data-table/hocs/with-data-table-base-config-hoc';
import { withBeanstandungenDataTableConfig } from './hocs/with-beanstandungen-data-table-config-hoc';
import { withDashboardDataTableConfig } from './hocs/with-dashboard-data-table-config-hoc';
import { withHinweiseDataTableConfig } from './hocs/with-hinweise-data-table-config-hoc';
import { withTourenDataTableConfig } from './hocs/with-touren-data-table-config-hoc';

export default {
  DashboardDataTable: withDashboardDataTableConfig(withDataTableBaseConfig<DashboardDataTableDataType>(DataTableBase)),
  KennzahlenDataTable: withDashboardDataTableConfig(withDataTableBaseConfig<DashboardDataTableDataType>(DataTableBase)),
  BeanstandungenDataTable: withBeanstandungenDataTableConfig(
    withDataTableBaseConfig<BeanstandungenDataTableDataType>(DataTableBase)
  ),
  HinweiseDataTable: withHinweiseDataTableConfig(withDataTableBaseConfig<HinweisData>(DataTableBase)),
  RuecklaufDataTable: withDashboardDataTableConfig(withDataTableBaseConfig<DashboardDataTableDataType>(DataTableBase)),
  TourenDataTable: withTourenDataTableConfig(withDataTableBaseConfig<DashboardDataTableDataType>(DataTableBase)),
};
