import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import servicebeanstandungenMeldungGrund from './servicebeanstandungen-meldung-grund';
import { ValuableComplaintReasons } from '@legacy-modules/complaints/models/enums/ValuableComplaintsReason';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const expression = new SumValueExpression(
  new SumValueExpression(
    new MapDetailValueExpression(
      servicebeanstandungenMeldungGrund.metricType,
      ValuableComplaintReasons.missing_contents
    ),
    new MapDetailValueExpression(servicebeanstandungenMeldungGrund.metricType, ValuableComplaintReasons.unsure_delivery)
  ),
  new MapDetailValueExpression(servicebeanstandungenMeldungGrund.metricType, ValuableComplaintReasons.unsure_proof),
  'vSB (Meldung)',
  '',
  '-#.###.##0',
  getDefinitionByName('vSB (Meldung)')
);

expression.setIdentifier('sb_verlust');

export default expression;
