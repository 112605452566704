export enum KnownWebsocketEvent {
  // --------------------------------------- Konfigurationen ---------------------------------------
  USER_CONFIG_LOAD_EVENT = 'userconfig.load',
  USER_CONFIG_SAVE_EVENT = 'userconfig.save',
  // --------------------------------------- Metriken ---------------------------------------
  // Wird zum Laden von KPIs verwendet mit mehreren OrgKeys (z.B. für alle Touren am bestimmten Tag)
  METRICS_OVERVIEW_LOAD_EVENT = 'metrics.overview',
  // Wird zum Laden von KPIs verwendet mit einem OrgKey (z.B. für eine bestimmte Tour am bestimmten Tag)
  METRICS_QUERY_LOAD_EVENT = 'metrics.query',
  // --------------------------------------- Beanstandungen ---------------------------------------
  BEANSTANDUNGEN_LOAD_EVENT = 'complaints.overview',
  // --------------------------------------- Organisation ---------------------------------------
  ORG_TREE_LOAD_EVENT = 'map.org-units.authorized-tree',
  MULTI_ORG_TREE_LOAD_EVENT = 'map.org-units.multi',
  // --------------------------------------- Zielwerte ---------------------------------------
  ORG_UNIT_CONFIG_LOAD_EVENT = 'orgunitconfig.load',
  ORG_UNIT_CONFIG_SAVE_EVENT = 'orgunitconfig.save',
  // --------------------------------------- Tour ---------------------------------------
  METRICS_LOAD_EVENT = 'metrics.request',
  TOUR_REPORT_LOAD_EVENT = 'live.tour-report',
  TOUR_LIST_LOAD_EVENT = 'live.tours',
  TOUR_DETAILS_LOAD_EVENT = 'live.tour-details',
}
