import { useApiContext } from '@contexts/api-context';
import { KnownWebsocketEvent } from '@contexts/api-context/request.types';
import { BeanstandungData, LoadingState, OrgKey, TourIdentifier } from '@data-table/data-table.types';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { DurationUtils } from '@utils/duration-utils';
import { useQuery } from 'react-query';

type UseBeanstandungenDataOutput = [BeanstandungData[], LoadingState];
export default function useBeanstandungenData(
  currentOrgKey: OrgKey,
  duration: Duration,
  enabled: boolean = false
): UseBeanstandungenDataOutput {
  const apiCtx = useApiContext();

  const primaryRange = DurationUtils.getDateRange(duration);

  const { from, to } = {
    from: primaryRange?.from?.format('YYYY-MM-DD'),
    to: primaryRange?.to?.format('YYYY-MM-DD'),
  };

  const dateQueryKey = primaryRange?.from?.isSame(primaryRange?.to, 'day') ? from : `${from}-${to}`;

  const { data, isFetching } = useQuery<BeanstandungenResponse, unknown, BeanstandungData[]>({
    queryKey: [KnownWebsocketEvent.BEANSTANDUNGEN_LOAD_EVENT, currentOrgKey, dateQueryKey],
    enabled: !!currentOrgKey && !!from && !!to && enabled,
    queryFn: () =>
      apiCtx.wsFetch<BeanstandungenResponse>(KnownWebsocketEvent.BEANSTANDUNGEN_LOAD_EVENT, {
        dateFrom: from,
        dateUntil: to,
        orgKey: currentOrgKey,
      }),
    select: (data) =>
      data?.complaints?.map((beanstandung) => ({
        tourIdentifier: {
          tourNumber: beanstandung?.tour?.number?.toString(),
          orgKey: beanstandung.shipment.orgKey,
          orgId: null,
          tourDate: null,
          contractorKey: null,
        } as TourIdentifier,
        sendungsId: beanstandung?.shipment?.id,
        wert: beanstandung?.shipment?.valuable,
        beanstandungGrund: beanstandung?.complaint?.text,
        status: beanstandung?.status?.shortDescription,
        auftraggeber: beanstandung?.shipment?.client,
        meldezeitpunkt: beanstandung?.createdAt,
      })),
    placeholderData: { complaints: [] },
  });

  return [data, isFetching];
}

type Sendung = {
  id: string;
  client: string;
  orgKey: OrgKey;
  scanAt: string;
  valuable: boolean;
};

type Tour = {
  number: number;
};

type BeanstandungGrund = {
  code: string;
  text: string;
};

type BeanstandungStatus = {
  stgNumber: number;
  sdsNumber: number;
  description: string;
  shortDescription: string;
};

type Beanstandung = {
  complaint: BeanstandungGrund;
  shipment: Sendung;
  tour?: Tour;
  status?: BeanstandungStatus;
  createdAt: string;
};

type BeanstandungenResponse = {
  complaints: Beanstandung[];
};
