import { useDataTableContext } from '@data-table/contexts/data-table-context';
import { DataTableColumnMeta, TourIdentifier, ValueExpressionDataTableColumnMeta } from '@data-table/data-table.types';
import moment from 'moment';
import React, { useCallback, HTMLAttributes } from 'react';
import * as XLSX from 'xlsx';
import * as fs from 'fs';
import { CellObject } from 'xlsx';

XLSX.set_fs(fs);

export type CsvExportActionProps = HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
};
export type CsvExportActionComponentProps = HTMLAttributes<HTMLDivElement> & {
  onExport: () => void;
  disabled?: boolean;
};

export const withCsvExport =
  (Component: React.ComponentType<CsvExportActionComponentProps>) =>
  ({ ...htmlProps }: CsvExportActionProps) => {
    const { table } = useDataTableContext();

    const onExport = useCallback(() => {
      if (!table) {
        return;
      }
      const headerMap = new Map(
        table
          .getHeaderGroups()?.[0]
          ?.headers?.map((c) => [c.id, (c.column.columnDef?.meta as DataTableColumnMeta)?.exportValue]) || []
      );

      const data = table.getPrePaginationRowModel().rows.map((row) => {
        const resultRow = {};
        headerMap.forEach((value, key) => {
          if (key === 'tourIdentifier') {
            resultRow[value] = row.getValue<TourIdentifier>(key)?.tourNumber;
          } else if (key === 'meldezeitpunkt') {
            resultRow[value] = moment(row.getValue(key)).format('DD.MM.YY HH:mm');
          } else {
            const columnMeta = table.getColumn(key).columnDef?.meta;
            if (Object.hasOwn(columnMeta, 'valueExpression')) {
              const valueExpression = (columnMeta as ValueExpressionDataTableColumnMeta).valueExpression;
              resultRow[value] = valueExpression?.getValueFormatter()(row.getValue(key));
            } else {
              resultRow[value] = row.getValue(key);
            }
          }
        });
        return resultRow;
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);

      const range = XLSX.utils.decode_range(worksheet['!ref']);
      const hasTourenSpalte = worksheet?.['A1']?.v === 'Tour';
      for (let row = range.s.r + 1; row <= range.e.r; ++row) {
        for (let column = range.s.c; column <= range.e.c; ++column) {
          // Don't try to format area names or other non-touren number fields as the first column
          if (!hasTourenSpalte && column === 0) {
            continue;
          }
          const headerCellAddress = XLSX.utils.encode_cell({ r: 0, c: column });
          const headerCell = worksheet[headerCellAddress];
          /**
           *  Skip 'Sendungs-ID' column since those can be pure numbers (33030131002503 e.g.)
           *  but we DON'T want formatting there
           */
          if (headerCell.v === 'Sendungs-ID') {
            continue;
          }
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: column });
          const cell: CellObject = worksheet[cellAddress];
          if (typeof cell.v === 'string') {
            constructNumerableCellValue(cell);
          }
        }
      }

      worksheet['!cols'] = new Array(headerMap.size).fill({
        wch: 20,
      });

      XLSX.utils.book_append_sheet(workbook, worksheet, 'LMA');

      XLSX.writeFile(workbook, 'LMA.xlsx', { bookType: 'xlsx' });
    }, [table]);

    return <Component {...htmlProps} onExport={onExport} />;
  };

function constructNumerableCellValue(cell: CellObject): void {
  const cellValue = cell.v as string;
  const formattedCellValue = cellValue.replace(/\./g, '').replace(',', '.');
  const isPlainNumber = !isNaN(Number(formattedCellValue));

  if (isPlainNumber) {
    // Needs no further formatting, e.g. regular integer kpi like 'Abwicklungsmenge'
    cell.v = formattedCellValue;
    cell.t = 'n';
    cell.z = formattedCellValue.includes('.') ? '#,##0.00' : '#,##0';
  } else if (formattedCellValue.includes('%')) {
    // Percentage based kpis like 'RL-Quote', let sheet js handle this via 'z' format property
    cell.v = parseFloat(formattedCellValue) / 100;
    cell.t = 'n';
    cell.z = '0.00%';
  } else if (formattedCellValue === '-') {
    // Some kpi have '-' as null value so for excel compatibility, set it to zero
    cell.v = 0;
    cell.t = 'n';
  } else if (/\d+\s(?:m|km)/.test(formattedCellValue)) {
    // Distance kpis such as 'Anfahrtsstrecke' get stripped off their unit
    cell.v = parseFloat(formattedCellValue);
    cell.t = 'n';
    cell.z = '#,##0.00';
  }
}
