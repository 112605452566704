import 'moment/dist/locale/de';
// wdyr throws an error if you save any component after the first page load with no changes
// import "./wdyr";
import React from 'react';
import ReactDOM from 'react-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.scss';
import './variables.scss';
import AppRoot from './modules/app/containers/AppRoot';
import { Provider } from 'react-redux';
import Logger from './modules/utils/logging/Logger';
import MapboxGL from 'mapbox-gl';
import moment from 'moment-timezone';
import 'moment/min/locales.min';
import * as Sentry from '@sentry/react';
import { lmaReduxStore } from '@redux';
import { ConfigKey, EnvironmentConfigurationService } from './modules/common/services/EnvironmentConfigurationService';

MapboxGL.accessToken = EnvironmentConfigurationService.get(
  process.env.NODE_ENV === 'development' ? ConfigKey.MAPBOX_DEV_TOKEN : ConfigKey.MAPBOX_TOKEN
);

moment.locale('de');
moment.updateLocale('de', null);

if (EnvironmentConfigurationService.isInDevelopmentEnvironment()) {
  Logger.DEFAULT_CONFIG.level = Logger.LEVELS.DEBUG;
  Logger.getInstance('Root').warn('We are on development - Setting log level to debug.');
} else {
  Sentry.init({
    dsn: EnvironmentConfigurationService.get(ConfigKey.SENTRY_DSN),
    release: EnvironmentConfigurationService.get(ConfigKey.APP_VERSION) || '0.0.0',
    environment: process.env.NODE_ENV || process.env.REACT_NODE_ENV || 'local',
  });
}

ReactDOM.render(
  <Provider store={lmaReduxStore}>
    <AppRoot />
  </Provider>,
  document.getElementById('root')
);
