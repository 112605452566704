import ValueExpression from './ValueExpression';
import { ValueType } from '../../../metrics2/models/enumerations/ValueType';
import MetricType from '../../../metrics2/models/entities/MetricType';
import ValueExpressionEntityKey from '../entities/ValueExpressionEntityKey';
import { ValueExpressionRoutingParams } from '../routing/ValueExpressionRoutingParams';
import { PositiveDirections } from '../../../metrics2/models/entities/PositiveDirection';
import { ChartType } from '../../../metrics2/models/enumerations/ChartType';
import { AggregationType } from '../../../metrics2/models/enumerations/AggregationType';
import MetricsEntityKey from '../../../metrics2/models/entities/MetricsEntityKey';
import { KpiDefinitionWithoutName } from '@custom-types/kpi';

export default class MapDetailValueExpression extends ValueExpression {
  static TAG = 'MapDetailValueExpression';
  tag = MapDetailValueExpression.TAG;
  metricType: MetricType;
  metricMapKey: string | null | undefined;
  label: string;

  constructor(
    metricType: MetricType,
    metricMapKey: string | null | undefined = null,
    label = null,
    definition?: KpiDefinitionWithoutName
  ) {
    super();
    this.metricType = metricType;
    this.metricMapKey = metricMapKey;
    this.definition = definition;
    if (label) {
      this.label = label;
    }
  }

  clone(): MapDetailValueExpression {
    return new MapDetailValueExpression(this.metricType, this.metricMapKey, this.label);
  }

  get key(): string {
    const safeMetricMapKey = this.metricMapKey
      .toLowerCase()
      .replace(/[^_a-z0-9]/g, '_')
      .replace(/_+/g, '_');
    return `mdve:${this.metricType.key}:${safeMetricMapKey}`;
  }

  get valueType(): ValueType {
    return ValueType.single;
  }

  get mapKeys(): Array<string> | null | undefined {
    return null;
  }

  getRequiredMetricTypes(id: string = null): Array<{ type: MetricType; valueKey?: string; id: string }> {
    return [
      {
        type: this.metricType,
        valueKey: this.metricMapKey,
        id: id ? id : 'default',
      },
    ];
  }

  get chartType(): ChartType {
    return ChartType.bar;
  }

  get positiveDirection(): PositiveDirections {
    return this.metricType.positiveDirection;
  }

  get aggregation(): AggregationType {
    return this.metricType.aggregation;
  }

  get category(): string | null | undefined {
    return this.metricType.category;
  }

  getMapKeyLabel(mapKey: string): string {
    const metricType = new MetricType();
    metricType.hydrateFrom(this.metricType);
    const mapKeyLabels = metricType.mapKeyLabels;
    if (mapKeyLabels && mapKeyLabels[mapKey]) {
      return mapKeyLabels[mapKey];
    }
    return mapKey;
  }

  getShortLabel(mapKey = null): string {
    if (this.shortLabel) {
      return this.shortLabel;
    }
    return this.getMapKeyLabel(this.metricMapKey);
  }

  getLabel(): string {
    if (this.label) {
      return this.label;
    }
    return this.metricType.label + ' (' + this.getMapKeyLabel(this.metricMapKey) + ')';
  }

  getDescription(mapKey = null, language = null): string | null | undefined {
    return `Detail-Wert '${this.getMapKeyLabel(this.metricMapKey)}' von ${this.metricType.label}.`;
  }

  getSumLabel(language = null): string {
    return this.metricType.sumLabel;
  }

  getValueFormat(): string {
    return this.metricType.valueFormat;
  }

  processValues(metrics: Map<MetricsEntityKey, number>): Map<ValueExpressionEntityKey, number> {
    const key = this.key;
    const veEntityKeys: {
      [key: string]: ValueExpressionEntityKey;
    } = {};
    const mek2veek: Map<MetricsEntityKey, string> = new Map();
    Array.from(metrics.keys())
      .filter((mek: MetricsEntityKey) => mek.type === this.metricType.key && mek.valueKey === this.metricMapKey)
      .map((mek: MetricsEntityKey) => {
        return {
          mek,
          veek: new ValueExpressionEntityKey(key, mek.orgKey, mek.dateFrom, mek.dateUntil, mek.grouping, mek.valueKey),
        };
      })
      .forEach(({ mek, veek }: { mek: MetricsEntityKey; veek: ValueExpressionEntityKey }) => {
        mek2veek.set(mek, veek.identifier);
        veEntityKeys[veek.identifier] = veek;
      });
    const resultMap = new Map();
    metrics.forEach((value, mek) => {
      const veek: ValueExpressionEntityKey = veEntityKeys[mek2veek.get(mek)];
      if (veek) {
        const oldValue = resultMap.get(veek) || 0.0;
        resultMap.set(veek, oldValue + value);
      }
    });
    return resultMap;
  }

  getRoutingParams(): ValueExpressionRoutingParams {
    return {
      valueExpressionClass: MapDetailValueExpression.TAG,
      metricType: this.metricType.key,
      label: this.label,
      metricMapKey: this.metricMapKey,
    };
  }
}
