import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { unitOfTime } from 'moment';

export const useTimeUnit = (granularity?: unitOfTime.StartOf, checkInterval: number = 60000): moment.Moment => {
  const [timeUnit, setTimeUnit] = useState<moment.Moment>(moment().endOf(granularity));

  useEffect(() => {
    const checkToday = () => {
      const now = moment().endOf(granularity);

      if (!timeUnit) {
        setTimeUnit(now);
        return;
      }

      if (!timeUnit.isSame(now, granularity)) {
        setTimeUnit(now);
      }
    };

    const timer = setInterval(checkToday, checkInterval);

    return () => {
      clearInterval(timer);
    };
  }, [checkInterval, granularity, timeUnit]);

  return timeUnit;
};

export const useToday = (): moment.Moment => useTimeUnit('day');
