// TODO: This shouldn't exist, shall be replaced by DeliveryBadge type
export type ReasonPayload = {
  // label will be used where is enough space (in the UI) and where the
  // context is clear. e.g. in the kpi selector, where the difference
  // between rücklauf and rücklauf eil is distinguishable because of
  // it's category
  label: string;
  // shortlabel will be used when there isn't enough space or/and when the
  // context is not clear. An example where not enough space is, is a table
  // header cell. An example for a missing context is a dashboard tile, because
  // the ruecklauf reason could be for ruecklauf or for ruecklauf eil
  shortLabel?: string;
  color?: string;
  // will be used in the badges which are used for filtering tours
  badgeAbbreviation?: string;
};

export type UndeliverableReason =
  | 'AuthorityNotFound'
  | 'AuthorityNotPossible'
  | 'Cancelled'
  | 'Canceled'
  | 'DeliveryItemDamaged'
  | 'DeliveryRefused'
  | 'Ident'
  | 'IdentAge'
  | 'IdentBirthday'
  | 'IdentDocument'
  | 'IdentName'
  | 'IdentPicture'
  | 'IdentPin'
  | 'IdVerificationFailed'
  | 'Loss'
  | 'MinAgeVerificationFailed'
  | 'MisroutedDeliveryItem'
  | 'NameVerificationFailed'
  | 'NoMoney'
  | 'NotMet'
  | 'NotMet1'
  | 'NotMet2'
  | 'NotMet3'
  | 'NotMet4'
  | 'NotPickedUp'
  | 'PackagingDamaged'
  | 'PictureVerificationFailed'
  | 'RLoGGS'
  | 'SingleAuthorityNotPossible'
  | 'TooLate'
  | 'WrongAddress'
  | 'UNB';

// TODO: Unify / replace ReasonPayload with DeliveryBadge type
// TODO: Check if 'shortLabel' is necessary, this does not exist on DeliveryBadge type, why is it needed here ?
export const UndeliverableReasons: Record<UndeliverableReason, ReasonPayload> = {
  AuthorityNotFound: {
    label: 'Ablageort nicht gefunden',
    badgeAbbreviation: 'VM',
  },
  AuthorityNotPossible: {
    label: 'Ablage nicht möglich',
    badgeAbbreviation: 'VM',
  },
  Cancelled: {
    label: 'Storno - Storno',
  },
  // yes, it exists in both, american and british english
  Canceled: {
    label: 'Storno - Storno',
  },
  DeliveryItemDamaged: {
    label: 'Schadenfall - S',
    shortLabel: 'RL S',
    badgeAbbreviation: 'S',
    color: 'var(--black0)',
  },
  DeliveryRefused: {
    label: 'Annahmeverweigerung - A',
    shortLabel: 'RL A',
    badgeAbbreviation: 'A',
    color: 'var(--black0)',
  },
  Ident: {
    label: 'Identservice',
    badgeAbbreviation: 'I',
    color: '#6dd9ff',
  },
  IdentAge: {
    label: 'Identservice Alter - IA',
    shortLabel: 'RL IA',
    badgeAbbreviation: 'IA',
    color: '#6dd9ff',
  },
  IdentBirthday: {
    label: 'Identservice Geburtsdatum - IG',
    shortLabel: 'RL IG',
    badgeAbbreviation: 'IG',
    color: '#6dd9ff',
  },
  IdentDocument: {
    label: 'Identservice Dokument - ID',
    shortLabel: 'RL ID',
    badgeAbbreviation: 'ID',
    color: '#6dd9ff',
  },
  IdentName: {
    label: 'Identservice Name - IN',
    shortLabel: 'RL IN',
    badgeAbbreviation: 'IN',
    color: '#6dd9ff',
  },
  IdentPicture: {
    label: 'Identservice Lichtbild - IL',
    shortLabel: 'RL IL',
    badgeAbbreviation: 'IL',
    color: '#6dd9ff',
  },
  IdentPin: {
    label: 'Identservice PIN-Code',
    badgeAbbreviation: 'IP',
    color: '#6dd9ff',
  },
  IdVerificationFailed: {
    label: 'Identservice Dokument',
    badgeAbbreviation: 'ID',
    color: '#6dd9ff',
  },
  Loss: {
    label: 'Verlust',
    badgeAbbreviation: 'V',
    color: '#c00000',
  },
  MinAgeVerificationFailed: {
    label: 'Identservice Geburtsdatum',
    badgeAbbreviation: 'IA',
    color: '#6dd9ff',
  },
  MisroutedDeliveryItem: {
    label: 'Irrläufer - U', // Kpi-Selector & Chart-Legende
    shortLabel: 'RL U', // RL U Eil <-- Tabellenkopf, Kacheln
    badgeAbbreviation: 'U', // <-- Badges
    color: '#c00000',
  },
  NameVerificationFailed: {
    label: 'Identservice Name',
    badgeAbbreviation: 'IN',
  },
  NoMoney: {
    label: 'Empfänger hat kein Bargeld - KG',
    shortLabel: 'RL KG',
    badgeAbbreviation: 'KG',
    color: '#ff7503',
  },
  NotMet: {
    label: 'Nicht angetroffen',
    badgeAbbreviation: 'N',
    color: '#f79645',
  },
  NotMet1: {
    label: 'Nicht angetroffen - N1',
    shortLabel: 'RL N1',
    badgeAbbreviation: 'N1',
    color: '#f79645',
  },
  NotMet2: {
    label: 'Nicht angetroffen - N2',
    shortLabel: 'RL N2',
    badgeAbbreviation: 'N2',
    color: '#f79645',
  },
  NotMet3: {
    label: 'Nicht angetroffen - N3',
    shortLabel: 'RL N3',
    badgeAbbreviation: 'N3',
    color: '#f79645',
  },
  NotMet4: {
    label: 'Nicht angetroffen - N4',
    shortLabel: 'RL N4',
    badgeAbbreviation: 'N4',
    color: '#f79645',
  },
  NotPickedUp: {
    label: 'Nicht am Paketshop abgeholt',
    badgeAbbreviation: 'NP',
  },
  PackagingDamaged: {
    label: 'Verpackung ausgebessert',
    badgeAbbreviation: 'S',
    color: 'var(--black0)',
  },
  PictureVerificationFailed: {
    label: 'Identservice Lichtbild',
    badgeAbbreviation: 'IL',
  },
  RLoGGS: {
    label: 'Rücklauf ohne Gegenscannung - oGGS',
    shortLabel: 'RL oGGS',
    badgeAbbreviation: 'oGS',
    color: 'red',
  },
  SingleAuthorityNotPossible: {
    label: 'Einmalvollmacht nicht möglich',
    badgeAbbreviation: 'VM',
  },
  TooLate: {
    label: 'Zeitmangel - Z',
    shortLabel: 'RL Z',
    badgeAbbreviation: 'Z',
    color: 'red',
  },
  WrongAddress: {
    label: 'Falsche Adresse - F',
    shortLabel: 'RL F',
    badgeAbbreviation: 'F',
    color: '#4298f8',
  },
  UNB: {
    label: 'Unbekannt',
    color: '#92e49e',
  },
};

// TODO: Remove all of these below
const getPartOfObjectByKey = (key: keyof ReasonPayload) => {
  return Object.entries(UndeliverableReasons).reduce((collector, currentVal) => {
    const [k, v] = currentVal;
    return {
      ...collector,
      [k]: v[key],
    };
  }, {});
};

export const UndeliverableReasonLabels = getPartOfObjectByKey('label');
export const UndeliverableReasonShortLabels = getPartOfObjectByKey('shortLabel');
export const UndeliverableReasonExpressShortLabels = Object.entries(getPartOfObjectByKey('shortLabel')).reduce(
  (collector, currentVal) => {
    const [k, v] = currentVal;
    if (!v) {
      return collector;
    }
    return {
      ...collector,
      [k]: `${v} Eil`,
    };
  },
  {}
);
export const UndeliverableReasonAbaShortLabels = Object.entries(getPartOfObjectByKey('shortLabel')).reduce(
  (collector, currentVal) => {
    const [k, v] = currentVal;
    if (!v) {
      return collector;
    }
    return {
      ...collector,
      [k]: `${v} ABA`,
    };
  },
  {}
);
export const UndeliverableReasonBadgeAbbreviation = getPartOfObjectByKey('badgeAbbreviation');
export const UndeliverableReasonColors = getPartOfObjectByKey('color');
