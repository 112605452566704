import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';

export const formatValueExpressionValue = (valueExpression: ValueExpression, veData?: number | null | string) => {
  if (!valueExpression) {
    throw new Error('No value expression given');
  }

  const formatter = valueExpression.getValueFormatter();

  if (veData === null || veData === undefined || (typeof veData === 'number' && isNaN(veData))) {
    return valueExpression.nullValue;
  }
  return formatter(veData);
};
