import React, { ReactElement } from 'react';
import style from './touren-table-tab.module.scss';
import classNames from 'classnames';
import { default as withTourenTableTab, TourenTableTabComponentProps } from './with-touren-table-tab.hoc';
import { Tooltip } from '@other-components/tooltip';

const TourenTableTab = ({
  isActive,
  title,
  subTitle,
  valueExpressionKey,
  text,
  comparisonText,
  comparisonClassName,
  tooltipText,
  onTabClick,
  ...htmlProps
}: TourenTableTabComponentProps): ReactElement => {
  return (
    <div
      className={classNames(style.tabItem, {
        [style.active]: isActive,
      })}
      onClick={onTabClick}
      {...htmlProps}>
      <div className={style.title}>{title}</div>
      {subTitle && <div className={style.subtitle}>{subTitle}</div>}
      {valueExpressionKey && (
        <div className={style.valueExpressionItem}>
          {text}
          <Tooltip tooltipText={tooltipText} className={classNames(style.comparisonValue, comparisonClassName)}>
            <div>{comparisonText}</div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default withTourenTableTab(TourenTableTab);
