import * as React from 'react';
import Styles from './EmptyKpiSelectionInfo.module.scss';

type Props = {
  arrowPosition: [number, number];
  text?: string;
  details?: string;
};

export const EmptyKpiSelectionInfo = ({
  arrowPosition,
  text = 'Bitte wähle zuerst eine Kennzahl aus.',
  details = null,
}: Props) => {
  const [x, y] = arrowPosition;
  return (
    <div
      className={Styles.textSelectKpi}
      style={{
        backgroundPosition: `${x}px ${y}px`,
      }}>
      <p>{text}</p>
      {details && <p style={{ fontSize: 16 }}>{details}</p>}
    </div>
  );
};
