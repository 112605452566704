import Alert from '@legacy-modules/app/models/Alert';
import { AppRoutingParams } from '@legacy-modules/routing/models/AppRoutingParams';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { updateFromRoutingParams } from './store';

export type ViewMode = 'loading' | 'overview' | 'dashboard' | 'tourDetails';
export type SidebarSize = 'default' | 'extended' | 'hidden';

export type AppState = {
  activeView: ViewMode;
  sidebarSize: SidebarSize;
  alerts: Array<Alert>;
  alertSeenTime: number;
};

const initialState: AppState = {
  activeView: 'dashboard',
  sidebarSize: 'default',
  alerts: [],
  alertSeenTime: 0,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setViewMode: (state, action: PayloadAction<ViewMode>) => {
      state.activeView = action.payload;
    },
    setSidebarSize: (state, action: PayloadAction<SidebarSize>) => {
      state.sidebarSize = action.payload;
    },
    setAlerts: (state, action: PayloadAction<Array<Alert>>) => {
      state.alerts = action.payload;
    },
    setAlertSeenTime: (state, action: PayloadAction<number>) => {
      state.alertSeenTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFromRoutingParams, (state, action: PayloadAction<AppRoutingParams>) => {
      state.activeView = action.payload.viewMode;
    });
  },
});
