import React, { useCallback } from 'react';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import Styles from './SignatureHover.module.scss';
import SignatureImage, { SignatureRotation } from '../components/SignatureImage';
import ScannerSignature from '../components/ScannerSignature';
import FinishedDeliveryBadge from '../components/FinishedDeliveryBadge';
import { getProofOfDeliveryType, ProofOfDelivery } from '../models/enumerations/ProofOfDelivery';

type Props = {
  finishedDelivery: FinishedDelivery;
  rotateDeg: SignatureRotation;
  url: string;
};

export const SIGNATURE_HOVER_WIDTH = 500;

const SignatureHover: React.FC<Props> = ({ finishedDelivery, rotateDeg, url }) => {
  const noop = useCallback(() => {
    /**/
  }, []);

  return (
    <div className={Styles.SignatureHover}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        {getProofOfDeliveryType(finishedDelivery.proofOfDelivery) === 'signature' ? (
          <ScannerSignature
            url={url}
            width={SIGNATURE_HOVER_WIDTH}
            height={200}
            handleMouseEnter={noop}
            handleMouseLeave={noop}
          />
        ) : (
          <SignatureImage
            handleImageRotate={noop}
            handleMouseEnter={noop}
            handleMouseLeave={noop}
            url={url}
            imageWidth={SIGNATURE_HOVER_WIDTH}
            imageType={ProofOfDelivery.image}
            rotation={rotateDeg}
          />
        )}
      </div>
      <div className={Styles.Info}>
        <span style={{ fontSize: '12px' }}>Stopp: {finishedDelivery.displayableStopNumber}</span>
        <span style={{ marginLeft: 20 }}>
          <FinishedDeliveryBadge finishedDelivery={finishedDelivery} />
        </span>
        <span style={{ marginLeft: '20px', fontSize: '12px' }}>
          Berechtigte(r): {finishedDelivery.entitled && finishedDelivery.entitled}
        </span>
      </div>
    </div>
  );
};

export { SignatureHover };
