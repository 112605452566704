import GeoCodedAddress from '../../../geo/models/GeoCodedAddress';
import { PlainObjectOf } from '../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';
import Model, { DefaultType, MemberType } from '../../../utils/Model';
import CustomerOptions from '../models/CustomerOptions';

export default class CustomerTrackingData extends Model<CustomerTrackingData> {
  customerRef: string;
  street: string;
  streetNr: string;
  city: string;
  rsteze: string;
  zipcode: string;
  salutation: string;
  addressSupplement?: string | null;
  firstName: string;
  lastName: string;
  paketShopId: string | null | undefined;
  authorizationInfo:
    | {
        authorizationId: string | null | undefined;
      }
    | null
    | undefined;
  location: GeoCodedAddress | null | undefined;
  options: CustomerOptions;
  tour: {
    // count of delivery items for this customer on this tour
    deliveryItemCount: number;
  };

  constructor(source: PlainObjectOf<CustomerTrackingData>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  get safeRsteze() {
    if (!this.rsteze || this.rsteze === 'undefined') {
      return '00-00-00';
    }
    return this.rsteze;
  }

  getDefaults(): DefaultType<CustomerTrackingData> {
    return {
      authorizationInfo: () => null,
      location: () => null,
    };
  }

  getMembers(): MemberType<CustomerTrackingData> {
    return {
      location: (src) => (src ? new GeoCodedAddress(src) : null),
    };
  }
}
