import { ColumnFilterContentProps, ValueExpressionDataTableColumnMeta } from '@data-table/data-table.types';
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonGroup, Input } from 'reactstrap';
import { FilterControls } from '../../filter-controls';
import sharedStyle from '../value-expression-filter-content.module.scss';

// TODO: refactor this component to make it more readable and maintainable
// check if all default hooks are indeed needed
// check if you can simplify the code using input ref
const NumberValueExpressionFilterContent = <T, V>({ column, onCancel, onConfirm }: ColumnFilterContentProps<T, V>) => {
  const filterValue = column.getFilterValue() as V;

  const meta = column?.columnDef?.meta as ValueExpressionDataTableColumnMeta;

  const [value, setValue] = useState<string>(filterValue?.toString()?.replace(/>|</g, '') ?? '');
  const [isValid, setIsValid] = useState<boolean>(true);

  const [toggleState, setToggleState] = useState<'greater' | 'less'>(
    filterValue?.toString()?.startsWith('<') ? 'less' : 'greater'
  );

  const title = useMemo(() => {
    const label = meta?.valueExpression?.getLabel() ?? 'Werte';
    return `${label} filtern`;
  }, [meta?.valueExpression]);

  const onConfirmCallback = useCallback(() => {
    onConfirm(filterValue);
  }, [filterValue, onConfirm]);

  const onCancelCallback = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onValueChange = useCallback(
    (e) => {
      const value = e.target.value;
      // That regular expression is checking if a value is a string of 0 to 10 digits.
      // So it would match strings like "123" or "9876543210", but not "1a3" or "12345678901".
      const isValid = /^\d{0,10}$/.test(value);
      setIsValid(isValid);
      setValue(value);
      if (isValid) {
        if (value === '') {
          column.setFilterValue(undefined);
          return;
        }
        if (isFinite(Number(value))) {
          column.setFilterValue(`${toggleState === 'less' ? '<' : '>'}${value}`);
        }
      }
    },
    [column, toggleState]
  );

  const onFilterToggleChange = useCallback(
    (toggleValue) => () => {
      setToggleState(toggleValue);
      if (!isValid) {
        return;
      }
      if (value === '') {
        column.setFilterValue(undefined);
        return;
      }
      if (isFinite(Number(value))) {
        column.setFilterValue(`${toggleValue === 'less' ? '<' : '>'}${value}`);
      }
    },
    [column, value, isValid]
  );

  return (
    <div className={classNames(sharedStyle.filterContent)}>
      <FilterControls
        className={sharedStyle.valueExpressionFilterContent}
        cancelCallback={onCancelCallback}
        confirmCallback={value?.length > 0 && isValid ? onConfirmCallback : undefined}>
        <div data-testid='data-table-filter-title' className={sharedStyle.filterContentHeadline}>
          {title}
        </div>
        <div className={sharedStyle.valueExpressionFilterInput}>
          Nur Werte
          <ButtonGroup
            data-testid='data-table-filter-toggle'
            data-value={toggleState}
            size='sm'
            className={sharedStyle.valueExpressionFilterButtonGroup}>
            <Button
              data-testid='data-table-filter-toggle-greater'
              color={toggleState === 'greater' ? 'primary' : 'secondary'}
              outline={toggleState === 'greater' ? false : true}
              size='sm'
              onClick={onFilterToggleChange('greater')}>
              größer
            </Button>
            <Button
              data-testid='data-table-filter-toggle-less'
              color={toggleState === 'less' ? 'primary' : 'secondary'}
              outline={toggleState === 'less' ? false : true}
              size='sm'
              onClick={onFilterToggleChange('less')}>
              kleiner
            </Button>
          </ButtonGroup>
          <Input
            data-testid='data-table-filter-input'
            value={value}
            valid={value?.length > 0 && isValid}
            invalid={value?.length > 0 && !isValid}
            onChange={onValueChange}
          />
        </div>
      </FilterControls>
    </div>
  );
};

export default NumberValueExpressionFilterContent;
