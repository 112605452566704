import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import DetailedChart from '../../dashboard/charts/DetailedChart';
import { Duration, Durations } from '../../dashboard/models/enums/Duration';
import DetailedChartKpiValue from '../../dashboard/charts/DetailedChartKpiValue';
import detailsMapping from '../../dashboard/utils/DashboardDetailsMapping';
import { ChartType } from '../../metrics2/models/enumerations/ChartType';
import DateSelect from '../../common/components/DateSelect';
import { selectTourDetailsTourDate, selectTourDetailsTourIdentifier } from '@redux/tour-details.selectors';
import { useTourDetailsContext } from '@other-components/tour-details-context';
import { TourUtils } from '@legacy-modules/utils/tours/TourUtils';

import '../../dashboard/cssModules/kpiContainerStyles.scss';

const SingleTourOverTime: React.FC = () => {
  const { number: tourNumber, orgId } = useSelector(selectTourDetailsTourIdentifier);
  const tourDetailsDate = useSelector(selectTourDetailsTourDate);
  const {
    tourInTimeState: [, setTourInTimeOpen],
  } = useTourDetailsContext();

  const [duration, setDuration] = useState<Partial<Duration>>(() => {
    const date = tourDetailsDate;
    const selectedDay = moment(date, 'YYYY-MM-DD');
    const today = moment();
    const difference = today.diff(selectedDay, 'days');
    if (difference > 28 && difference <= 90) {
      return Durations.last_90;
    }
    return Durations.last_28;
  });
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const onDurationSelected = (duration: Duration) => {
    setDuration(duration);
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        setTourInTimeOpen(false);
      }
    },
    [setTourInTimeOpen]
  );

  const handleClickOutside = useCallback(
    (event) => {
      let a = event.target;
      const classList = [];
      while (a) {
        classList.unshift(a.className);
        a = a.parentNode;
      }

      if (
        classList?.find(
          (c: string) =>
            c?.includes('wrapperDashboardKpi') ||
            c?.includes('subheader-wrapper') ||
            c?.includes('modal-dialog') ||
            c?.includes('data-range-picker')
        )
      ) {
        return;
      }

      setTourInTimeOpen(false);
    },
    [setTourInTimeOpen]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, handleKeyDown]);

  const tourOrgKey = TourUtils.getTourOrgKey(orgId, tourNumber);

  const mapping = detailsMapping.filter((dm) => dm.showInSingleTourZeitverlauf);

  return (
    <div className='wrapperDashboardKpi' style={{ marginTop: '0px' }}>
      <div className='topPanelDashboard'>
        <span className='allgemeinText'>Tour {tourNumber} im Zeitverlauf</span>
        <div style={{ marginLeft: 'auto' }}>
          <DateSelect
            onDurationSelected={onDurationSelected}
            // FIXME: It can be partial here, find out why and fix it :D
            selectedDuration={duration as Duration}
          />
        </div>
      </div>
      <div className='kpiContainer'>
        {mapping.map((dm, i) => {
          const selected = i === selectedTabIndex;
          return (
            <div
              key={`tab-${i}`}
              className={`singleKpiContainer ${selected ? 'selected gradient' : ''}`}
              onClick={() => setSelectedTabIndex(i)}>
              <span className='dashboardKpiLabel'>{dm.tabMeta.title}</span>
              <DetailedChartKpiValue
                orgKey={tourOrgKey}
                duration={duration as Duration}
                valueExpressionKey={dm.tabMeta.valueExpressionKey}
                showDetails={false}
                selected={selected}
              />
            </div>
          );
        })}
      </div>
      <div className='chart'>
        {mapping.map((entry, index) => {
          return (
            <React.Fragment key={`chart-${index}`}>
              {index === selectedTabIndex && (
                <DetailedChart
                  orgKey={tourOrgKey}
                  uID='tourDetails.chart'
                  valueExpressionKey={entry.tabMeta.valueExpressionKey}
                  defaultChartType={ChartType.line}
                  duration={duration as Duration}
                  style={{ boxShadow: 'none', margin: 0 }}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default SingleTourOverTime;
