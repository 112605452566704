import Model, { DefaultType, MemberType } from '../../../utils/Model';
import { PlainObjectOf } from '../websocket/org/OrgUnitTreeRequestPayload';

export default class MetricCategory extends Model<MetricCategory> {
  key: string;
  label: string;
  description: string | null | undefined;

  constructor(source: PlainObjectOf<MetricCategory>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<MetricCategory> {
    return undefined;
  }

  getMembers(): MemberType<MetricCategory> {
    return undefined;
  }
}
