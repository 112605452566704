import React from 'react';
import { HinweiseTableHeader } from '@data-table/components/data-table-header';
import { TourenTableTabs } from '@data-table/components/touren-table-tabs';
import { DashboardDataTableDataType, DataTableConfigProps } from '@data-table/data-table.types';
import { useDataTableCustomInfo } from '@data-table/hooks/use-data-table-custom-info-hook';
import { useDataTableDuration } from '@data-table/hooks/use-data-table-duration-hook';
import { useDataTableOrgKey } from '@data-table/hooks/use-data-table-org-key-hook';
import { useTourenTableTabs } from '@data-table/hooks/use-touren-table-tabs-hook';
import { DashboardDataTableConfigProps, withDashboardDataTableConfig } from '../with-dashboard-data-table-config-hoc';

const withTourenDataTableConfig = (WrappedComponent: React.FC<DataTableConfigProps<DashboardDataTableDataType>>) => {
  const Component = withDashboardDataTableConfig(WrappedComponent);

  return ({ componentId, defaultDuration, ...rest }: DashboardDataTableConfigProps) => {
    const { currentTab, currentConfig, tabsConfig, setCurrentTab } = useTourenTableTabs();

    const [durationIsLoading, duration, onDurationChange] = useDataTableDuration(componentId, defaultDuration);

    const [orgKey] = useDataTableOrgKey();

    const { title } = useDataTableCustomInfo(orgKey, duration);

    return (
      <Component
        {...rest}
        componentId={currentConfig.componentId}
        defaultDuration={duration}
        tabs={
          !durationIsLoading && (
            <TourenTableTabs
              duration={duration}
              currentOrgKey={orgKey}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              tabsConfig={tabsConfig}
            />
          )
        }
        header={
          <HinweiseTableHeader
            title={title}
            duration={duration}
            disabled={durationIsLoading}
            onDurationChange={onDurationChange}
          />
        }
        defaultValueExpressionKeys={currentConfig?.valueExpressionKeys}
        defaultSorting={currentConfig?.sorting}
        style={{ maxHeight: 'calc(100vh - 55px)' }}
      />
    );
  };
};

export default withTourenDataTableConfig;
