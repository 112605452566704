import React, { ReactElement } from 'react';

import { NavigationControl, NavigationControlProps } from 'react-map-gl';

export type MapboxNavigationControlProps = NavigationControlProps & {};

export function MapboxNavigationControl(props: MapboxNavigationControlProps): ReactElement {
  return <NavigationControl {...props} />;
}
export default MapboxNavigationControl;
