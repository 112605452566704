import OrgUnitNode from './OrgUnitNode';
import OrgUnit from '../../entities/organization/OrgUnit';
import { PlainObjectOf } from './OrgUnitTreeRequestPayload';
import GenericTree from './GenericTree';

export interface TreeNode {
  children?: Array<TreeNode>;
  parent?: TreeNode;
}

export default class OrgTree extends GenericTree<OrgUnit, OrgUnitNode> {
  constructor(readonly rootNode?: OrgUnitNode) {
    super(rootNode);
  }

  private static reviveNode(plainNode: PlainObjectOf<OrgUnitNode>, parent: OrgUnitNode | null): OrgUnitNode {
    if (!plainNode) {
      return null;
    }

    const orgUnitNode = new OrgUnitNode({
      ...plainNode,
    });

    orgUnitNode.parent = parent;

    if (plainNode.children) {
      orgUnitNode.children = plainNode.children.map((c) => OrgTree.reviveNode(c, orgUnitNode));
    }

    return orgUnitNode;
  }

  // FIXME: type: PlainObjectOf<OrgUnitNode>
  static createFromPlainData(plainData: any): OrgTree {
    const revivedData: OrgUnitNode = OrgTree.reviveNode(plainData, null);
    return new OrgTree(revivedData);
  }

  sortRecursively(sortFn: (aOrgUnit: OrgUnitNode, bOrgUnit: OrgUnitNode) => number): void {
    this.sortNode(this.rootNode, sortFn);
  }

  private sortNode(orgUnitNode: OrgUnitNode, sortFn) {
    if (orgUnitNode.children) {
      orgUnitNode.children = orgUnitNode.children.sort(sortFn);
      orgUnitNode.children.forEach((c) => {
        this.sortNode(c, sortFn);
      });
    }
  }

  removeNodes(removeFn: (node: OrgUnitNode) => boolean): void {
    this.removeNode(this.rootNode, removeFn);
  }

  private removeNode(node: OrgUnitNode, removeFn) {
    if (node.children) {
      node.children.forEach((c) => {
        this.removeNode(c, removeFn);
      });
      node.children = node.children.filter((c) => !removeFn(c));
    }
  }

  static getPrevNode(node: OrgUnitNode) {
    if (!node) {
      return null;
    }
    const parent = node.parent;
    if (!parent) {
      return null;
    }
    const siblings = parent.children;
    const myIdx = siblings.findIndex((s) => s.orgUnit.name === node.orgUnit.name);

    if (myIdx < 0) {
      return null;
    }

    const prevIdx = myIdx - 1;
    // no previous sibling, navigate on last on this level again
    if (prevIdx < 0) {
      return siblings[siblings.length - 1];
    }
    return siblings[prevIdx];
  }

  static getNextNode(node: OrgUnitNode) {
    if (!node) {
      return null;
    }
    const parent = node.parent;
    if (!parent) {
      return null;
    }
    const siblings = parent.children;

    const myIdx = siblings.findIndex((s) => s.orgUnit.name === node.orgUnit.name);

    if (myIdx < 0) {
      return null;
    }

    // no next sibling, navigate on first on this level again
    if (myIdx + 1 >= siblings.length) {
      return siblings[0];
    }
    return siblings[myIdx + 1];
  }
}
