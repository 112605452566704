import AuthService from '@legacy-modules/common/services/AuthService';
import React, { createContext, PropsWithChildren, ReactElement, useContext } from 'react';

export type AuthContextType = AuthService;

const defaultValue: AuthContextType = null;
export const AuthContext = createContext(defaultValue);

export const useAuthContext = (): AuthContextType => {
  return useContext<AuthContextType>(AuthContext);
};

type AuthContextProviderProps = Required<
  PropsWithChildren<{
    authService: AuthService;
  }>
>;

export default function AuthContextProvider({ children, authService }: AuthContextProviderProps): ReactElement {
  return <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>;
}
