import { Class } from 'utility-types';

import WebsocketEvent from '../../models/websocket/WebsocketEvent';
import BasePayload from '../../models/websocket/payloads/BasePayload';
import SystemErrorPayload from '../../models/websocket/payloads/system/SystemErrorPayload';
import AuthStatusPayload from '../../models/websocket/payloads/auth/AuthStatusPayload';
import AuthTokenPayload from '../../models/websocket/payloads/auth/AuthTokenPayload';
import CompressedMessageHandler from './CompressedMessageHandler';
import AuthLoginPayload from '../../models/websocket/payloads/auth/AuthLoginPayload';
import { KnownResponseKey } from '../../constants/KnownResponseKeys';

export default class EventMessageFormatter {
  types: {
    [key in KnownResponseKey]: Class<BasePayload<any>>;
  } = {
    [KnownResponseKey.SystemError]: SystemErrorPayload,
    [KnownResponseKey.AuthLogin]: AuthLoginPayload,
    [KnownResponseKey.AuthStatus]: AuthStatusPayload,
    [KnownResponseKey.AuthToken]: AuthTokenPayload,
  };
  pingMessage: WebsocketEvent;
  compressedMessageHandler: CompressedMessageHandler;
  _requestMap: Map<string, Function>;
  _nextId: number;

  constructor() {
    this.pingMessage = new WebsocketEvent({ name: 'system.ping' });
    this.compressedMessageHandler = new CompressedMessageHandler();
    this._requestMap = new Map();
    this._nextId = 0;
  }

  registerEventType(type: string, classConstructor: Class<BasePayload<any>>): void {
    this.types[type] = classConstructor;
  }

  toMessage(event: WebsocketEvent): string {
    return JSON.stringify(event.toObject());
  }

  fromMessage(message: string): WebsocketEvent {
    const object = JSON.parse(message);
    const payload = object.payload;
    if (!object.name) {
      throw Error('Could not find event type.');
    }
    if (object.name === 'system.compressed') {
      return this.fromMessage(this.compressedMessageHandler.handle(payload));
    }
    const constructor: Class<BasePayload<any>> = this.types[object.name];
    if (constructor && payload) {
      object.payload = new constructor(payload);
    }
    return new WebsocketEvent(object);
  }

  trackRequest(event: WebsocketEvent, handler: Function): WebsocketEvent {
    return event;
  }

  getPendingHandlerIds(): Iterator<string> {
    return this._requestMap.keys();
  }

  handlePong(event: WebsocketEvent): boolean {
    return event.name === 'system.pong';
  }

  handlePing(): boolean {
    return false;
  }
}
