import OrgUnitNode from '../../../../../../modules/metrics2/models/websocket/org/OrgUnitNode';
import { OrgKey } from '@data-table/data-table.types';
import { useOrgTreeContext } from '@contexts/org-tree-context';

const useDataTableOrgKey = (): [OrgKey, OrgUnitNode] => {
  const { orgKey, orgTree } = useOrgTreeContext();

  return [orgKey, orgTree.rootNode];
};

export default useDataTableOrgKey;
