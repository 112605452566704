/* eslint-disable max-len */
import MetricsDataProvider from '../../metrics2/services/MetricsDataProvider';
import ValueExpression from '../models/valueexpressions/ValueExpression';
import { QueryPriorities, QueryPriority } from '../../datamanager/models/enumerations/QueryPriority';
import MetricsBaseQuery from '../../metrics2/models/queries/MetricsBaseQuery';
import ValueExpressionDataConnection from '../models/dataconnection/ValueExpressionDataConnection';
import ValueExpressionQuery from '../models/queries/ValueExpressionQuery';
import ValueExpressionBaseQuery from '../models/queries/ValueExpressionBaseQuery';
import MetricDataConnection from '../../metrics2/models/dataconnection/MetricDataConnection';
import { MetricDataConnectionOutputFormats } from '../../metrics2/models/enumerations/MetricDataConnectionOutputFormat';
import ExtendedEventEmitter from '../../events/ExtendedEventEmitter';
import ValueExpressionOverviewQuery from '../models/queries/ValueExpressionOverviewQuery';
import AuthService from '../../common/services/AuthService';
import { PublisherInterface } from '../../events/PublisherInterface';
import * as events from 'events';

export default class ValueExpressionDataProvider implements PublisherInterface {
  static EVENT_DATA_LOADED = 'loaded';

  metricsDataProvider: MetricsDataProvider;
  sparseMetricsDataProvider: MetricsDataProvider;
  authService: AuthService;

  _eventEmitter: ExtendedEventEmitter;

  isLoaded: boolean;
  static valueExpressions: ValueExpression[];
  static favoriteKeys: string[];

  constructor(
    metricsDataProvider: MetricsDataProvider,
    sparseMetricsDataProvider: MetricsDataProvider,
    authService: AuthService
  ) {
    this.metricsDataProvider = metricsDataProvider;
    this.sparseMetricsDataProvider = sparseMetricsDataProvider;
    this._eventEmitter = new ExtendedEventEmitter();
    this._eventEmitter.registerListenerInterface(this);
    this.authService = authService;
  }

  requestConnection(
    query: ValueExpressionBaseQuery,
    priority: QueryPriority = QueryPriorities.normal
  ): ValueExpressionDataConnection {
    return this._requestConnection(query, this.metricsDataProvider, priority);
  }

  requestSparseConnection(
    query: ValueExpressionBaseQuery,
    priority: QueryPriority = QueryPriorities.normal
  ): ValueExpressionDataConnection {
    return this._requestConnection(query, this.sparseMetricsDataProvider, priority);
  }

  _requestConnection(
    query: ValueExpressionBaseQuery,
    dataProvider: MetricsDataProvider,
    priority: QueryPriority
  ): ValueExpressionDataConnection {
    const metricQueries: Array<MetricsBaseQuery> = query.getMetricQueries();

    if (query instanceof ValueExpressionQuery || query instanceof ValueExpressionOverviewQuery) {
      const connections: Array<MetricDataConnection> = metricQueries.map((mq: MetricsBaseQuery) =>
        dataProvider.requestConnection(mq, priority)
      );
      connections.forEach((c: MetricDataConnection) => {
        c.setOutputFormat(MetricDataConnectionOutputFormats.entities);
      });
      return new ValueExpressionDataConnection(query.valueExpression, connections);
    }
  }

  async registerFavoriteValueExpressions() {
    /**
     * NOTE registering the favorite value expressions has been moved to registerAll() in order to re-use
     * ValueExpressions already built for the "other" KPI tree
     */

    // This however remains because we don't know if the code breaks when removed...
    this._eventEmitter.emit(ValueExpressionDataProvider.EVENT_DATA_LOADED);
    this.isLoaded = true;
  }

  get eventEmitter() {
    return this._eventEmitter;
  }

  addListener(eventName: string, listener: Function): events.EventEmitter {
    return undefined;
  }

  on(eventName: string, listener: Function): events.EventEmitter {
    return undefined;
  }

  once(eventName: string, listener: Function): events.EventEmitter {
    return undefined;
  }

  removeListener(eventName: string, listener: Function): events.EventEmitter {
    return undefined;
  }
}
