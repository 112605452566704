import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import bruttoZustellzeit from './brutto-zustellzeit';
import touren from './touren';

const expression = new QuotientValueExpression(
  bruttoZustellzeit,
  touren,
  'Ø Brutto-Zustellzeit',
  false,
  '-#.###.##0,0 h',
  null,
  getDefinitionByName('Brutto-Zustellzeit')
);

expression.setIdentifier('bruttozustellzeit/touren');

export default expression;
