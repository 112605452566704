import { AnomalyReason } from '../../dashboard/anomalies/utils/AnomalyUtils';
import { IRawAnomalyResponse } from '../../dashboard/anomalies/interfaces/IRawAnomalyResponse';

/**
 * Filters out the unknown anomalies. A known anomaly is a anomaly that
 * has a AnomalyReason mapping
 * @see AnomalyReason
 */
export const knownAnomalyFilter = (ano: IRawAnomalyResponse) => {
  const knownAnomaly = AnomalyReason[ano.anomalyType] != null;
  if (!knownAnomaly) {
    // eslint-disable-next-line no-console
    console.info(`Skipped unknonwn anomaly with type: ${ano.anomalyType}`);
    return false;
  }
  return true;
};
