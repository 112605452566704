export enum DateRangeGrouping {
  day = 'day',
  // week starting on sunday
  week0 = 'week0',
  // week starting on monday
  week1 = 'week1',
  month = 'month',
  year = 'year',
  none = 'none',
  week = 'week',
}
