import MathValueExpression from './MathValueExpression';
import ValueExpression from './ValueExpression';
import { KpiDefinitionWithoutName } from '@custom-types/kpi';

export default class QuotientValueExpression extends MathValueExpression {
  percent: boolean = false;
  decimalPlaces: number = 2;
  constructor(
    numerator: ValueExpression,
    denominator: ValueExpression,
    label: string | null | undefined = null,
    percent: boolean | null | undefined = false,
    valueFormat: string | null | undefined = null,
    description: string | null | undefined = null,
    definition?: KpiDefinitionWithoutName
  ) {
    super(
      numerator,
      denominator,
      '/',
      label,
      description,
      valueFormat ? valueFormat : percent ? '-#.###.##0,#0%' : '-#.###.##0,0',
      definition
    );
    this.percent = percent;
  }
}
