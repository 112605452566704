import React, { useCallback, useState } from 'react';
import Styles from './ButtonGroup.module.scss';
import { ToggleButtonProps } from './ToggleButton';
import classNames from 'classnames';

type Props<T extends string> = {
  selectedIndex?: number;
  onChange: (e: Event, value: T) => void;
  value: string[] | string;
  exclusive?: boolean;
  children: React.ReactElement<ToggleButtonProps>[] | React.ReactElement<ToggleButtonProps>;
  disabled?: boolean;
};

const isValueSelected = (value, candidate) => {
  if (candidate === undefined || value === undefined) {
    return false;
  }

  if (Array.isArray(candidate)) {
    return candidate.indexOf(value) >= 0;
  }

  return value === candidate;
};

export const ToggleButtonGroup = <T extends string>(props: Props<T>) => {
  const { children, onChange, value, exclusive = true, disabled = false } = props;

  const handleChange = useCallback(
    (event, buttonValue) => {
      if (!onChange) {
        return;
      }

      const index = value && value.indexOf(buttonValue);
      let newValue;

      if (value && index >= 0) {
        newValue = value.slice();
        newValue.splice(index, 1);
      } else {
        newValue = value ? value.concat(buttonValue) : [buttonValue];
      }

      onChange(event, newValue);
    },
    [onChange, value]
  );

  const handleExclusiveChange = useCallback(
    (event, buttonValue) => {
      if (!onChange) {
        return;
      }

      onChange(event, value === buttonValue ? null : buttonValue);
    },
    [onChange, value]
  );

  return (
    <div className={classNames(Styles.ButtonGroup)} role={'group'}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return React.cloneElement(child, {
          className: classNames(child.props.className),
          onChange: exclusive ? handleExclusiveChange : handleChange,
          disabled: disabled,
          selected:
            child.props.selected === undefined ? isValueSelected(child.props.value, value) : child.props.selected,
        });
      })}
    </div>
  );
};
