import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { formatOrgUnitLabel, OrganizationMultiSelection } from './OrganizationMultiSelection';
import { PopOver } from '../../common/components/PopOver';
import { MdList, MdLocationOn, MdRemoveCircle } from 'react-icons/md';
import BaseSelect from './BaseSelect';
import { TreeMenuNode } from '../TreeMenu';
import OrgUnitNode from '../../metrics2/models/websocket/org/OrgUnitNode';
import Styles from './OrganizationMultiSelect.module.scss';
import { SearchField } from './SearchField';
import { useKeyboardShortcut } from '../hooks/useKeyboardShortcut';
import { useTreeSearch } from '../hooks/useTreeSearch';
import { OrgSearchResultList } from './OrgSearchResultList';
import TextButton, { TextButtonVariant } from '../../common/components/TextButton';
import { useOrgTree } from '../hooks/useOrgTree';
import OrgUnit from '../../metrics2/models/entities/organization/OrgUnit';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { injectContractorViewMode } from '../../dashboard/utils/OrgKeyUtils';
import { ContractorViewMode } from '../constants/ContractorViewMode';
import { ZSBUtils } from '../../utils/tours/ZSBUtils';
import { ContractorUtils } from '../../utils/tours/ContractorUtils';
import { TruckIcon } from '../../common/components/Icons';
import GenericTree from '../../metrics2/models/websocket/org/GenericTree';
import { useOrgTreeContext } from '@contexts/org-tree-context';

export const isHub = (orgUnit: OrgUnit): boolean => {
  return orgUnit.orgType === 'hermes_org_hub' || orgUnit.orgKey.startsWith('oh:');
};

type MultiSelectItemProps = {
  nodes: Array<TreeMenuNode<OrgUnitNode['data']>>;
  onRemoveNode: (item: TreeMenuNode<OrgUnitNode['data']>) => void;
};

const MultiSelectSelectedItems: React.FC<MultiSelectItemProps> = ({ nodes, onRemoveNode }) => {
  const onRemoveCallback = useCallback((node) => () => onRemoveNode(node), [onRemoveNode]);

  return (
    <ul className={Styles.MultiSelectSelectedItems}>
      {nodes.map((n) => (
        <li key={n.data.getIdentifier()} className={Styles.ListItem} onClick={onRemoveCallback(n)}>
          <span className={Styles.IconWrapper}>
            <MdRemoveCircle />
          </span>
          {n.data.toString()}
        </li>
      ))}
    </ul>
  );
};

type Props = {
  label?: string;
  hideMultiSelect?: boolean;
  onOrganizationSelect: (organizations: Array<TreeMenuNode<OrgUnitNode['data']>>) => void;
  selectedOrgKeys: string[];
  visible?: boolean;
};

export const OrgMultiSelect: React.FC<Props> = ({
  label,
  hideMultiSelect = true,
  onOrganizationSelect,
  selectedOrgKeys,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [multiSelectEnabled, setMultiSelectEnabled] = useState(selectedOrgKeys?.length > 1);
  const [searchText, setSearchText] = useState('');

  const { orgKey, orgTree, viewMode } = useOrgTreeContext();

  const { rootNodes, tree: createdTree } = useOrgTree(orgTree, viewMode);

  const flatOrgTree = useMemo(() => createdTree.flatten(), [createdTree]);

  const mapKeysToNodes = useCallback(
    (selectedOrgKeys: string[]): OrgUnitNode[] => {
      return selectedOrgKeys
        .map((selectedOrgKey) => {
          let foundNode = null;
          for (const rootNode of rootNodes) {
            foundNode = GenericTree.findNodeInTree(rootNode, (n) => {
              return n.orgUnit.orgKey === injectContractorViewMode(selectedOrgKey, ContractorViewMode.All);
            });
            if (foundNode) {
              break;
            }
          }
          return foundNode;
        })
        .filter((v) => v);
    },
    [rootNodes]
  );

  const [selectedOrgUnits, setSelectedOrgUnits] = useState<OrgUnitNode[]>([]);

  useEffect(() => {
    if (lastOrgKey.current == null) {
      setSelectedOrgUnits(mapKeysToNodes([orgKey]));
      lastOrgKey.current = orgKey;
    } else {
      setSelectedOrgUnits(mapKeysToNodes(selectedOrgKeys));
    }
  }, [mapKeysToNodes, orgKey, selectedOrgKeys]);

  const onRemoveCallback = useCallback(
    (removeNode) => {
      const newSelectedNodes = selectedOrgUnits.filter(
        (n) => n.data.getIdentifier() !== removeNode.data.getIdentifier()
      );
      onOrganizationSelect(newSelectedNodes);
    },
    [onOrganizationSelect, selectedOrgUnits]
  );

  const toggleSelect = useCallback(() => setOpen((v) => !v), []);
  const hidePopOver = useCallback(() => open && setOpen(false), [open]);
  const toggleMultiSelect = useCallback(() => {
    setMultiSelectEnabled((v) => !v);
    if (selectedOrgUnits.length > 1) {
      const newSelectedNodes = [selectedOrgUnits[0]];
      onOrganizationSelect(newSelectedNodes);
    }
  }, [onOrganizationSelect, selectedOrgUnits]);

  const onOrganizationSelectCallback = useCallback(
    (orgs) => {
      onOrganizationSelect(orgs);
      if (!multiSelectEnabled) {
        hidePopOver();
      }
    },
    [hidePopOver, multiSelectEnabled, onOrganizationSelect]
  );

  const lastOrgKey = useRef<string>(null);

  const labelToRender = useMemo(() => {
    if (label) {
      return label;
    }
    if (selectedOrgUnits.length > 0) {
      return selectedOrgUnits.map((ou) => formatOrgUnitLabel(ou?.orgUnit)).join(', ');
    }
    return 'Wird geladen...';
  }, [label, selectedOrgUnits]);

  const { searchResults } = useTreeSearch(flatOrgTree, searchText);

  const onSearchValueChange = useCallback((text: string) => {
    if (!/^(?:[\wäöüÄÖÜß&:\- ]+)?$/i.test(text)) {
      return;
    }
    setSearchText(text.trimLeft());
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  useKeyboardShortcut({ code: 'Space', ctrlKey: true }, toggleSelect);

  const onResultSelectCallback = useCallback(
    (orgUnitNode: OrgUnitNode) => {
      if (!orgUnitNode) return;
      const { orgUnit } = orgUnitNode;
      if (!orgUnit) return;
      setSearchText('');
      hidePopOver();
      onOrganizationSelect(mapKeysToNodes([orgUnit.orgKey]));
    },
    [hidePopOver, mapKeysToNodes, onOrganizationSelect]
  );

  const isInSearchMode = searchText?.length > 0;

  const searchField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!searchField.current) {
      return;
    }
    searchField.current.focus();
  });

  const hasSearchResults = useMemo(() => {
    if (!searchResults) {
      return false;
    }
    return searchResults.reduce((prev, curr) => prev + curr.results.length, 0) > 0;
  }, [searchResults]);

  const selectIcon = (() => {
    if (ZSBUtils.isZSB(orgKey) || selectedOrgKeys?.every((z) => ZSBUtils.isZSB(z))) {
      return <MdLocationOn size={18} />;
    }

    if (ContractorUtils.isContractor(orgKey)) {
      return (
        <span style={{ marginTop: 4, display: 'block' }}>
          <TruckIcon />
        </span>
      );
    }

    return (
      <span style={{ marginTop: -3, display: 'block', marginRight: 4 }}>
        {/* because the icon is larger than the other one, we need to offset it a little */}
        <FaMapMarkedAlt size={18} />
      </span>
    );
  })();

  return (
    <div ref={ref}>
      <BaseSelect
        tooltipOnTruncate={!open}
        onSelectClick={toggleSelect}
        icon={selectIcon}
        text={labelToRender}
        style={{ width: 250, height: 40 }}
        infoLabel='Hier Location wählen'
        showAppendButton={!hideMultiSelect}
        appendButtonOutline={!multiSelectEnabled}
        appendButtonColor={multiSelectEnabled ? 'primary' : 'secondary'}
        appendButtonLabel='Hier klicken für Mehrfachauswahl'
        appendButtonIcon={<MdList size={24} color={multiSelectEnabled ? 'white' : 'var(--gray17)'} />}
        onAppendButtonClick={toggleMultiSelect}
        data-testid='org-select'
      />
      <PopOver
        anchorElement={ref?.current}
        visible={open}
        placement={'bottom-start'}
        closeOnEscape
        onClose={hidePopOver}
        closeOnClickOutside
        offset={[0, 6]}>
        <div className={Styles.PopOverContent}>
          <div>
            <SearchField ref={searchField} value={searchText} onChange={onSearchValueChange} />
          </div>
          {isInSearchMode ? (
            <>
              {searchText.length < 2 ? (
                <div className={Styles.Inner}>
                  <span className={Styles.NotEnoughChars}>Bitte gib min. 2 Zeichen ein</span>
                </div>
              ) : (
                <>
                  {hasSearchResults ? (
                    <div className={Styles.Inner}>
                      <OrgSearchResultList
                        onResultSelect={onResultSelectCallback}
                        results={searchResults}
                        searchValue={searchText}
                      />
                    </div>
                  ) : (
                    <div className={Styles.Inner}>
                      <span className={Styles.NotEnoughChars}>
                        Keine Suchergebnisse für <strong>{searchText}</strong>.
                      </span>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <div className={Styles.Inner} ref={inputRef}>
                <OrganizationMultiSelection
                  multiSelectionEnabled={multiSelectEnabled}
                  onNodeSelection={onOrganizationSelectCallback}
                  selectedNodes={selectedOrgUnits}
                  visible={open}
                  key={`${viewMode}orgSelect`}
                />
                {multiSelectEnabled && (
                  <div className={Styles.MultiSelectionList}>
                    {selectedOrgUnits.length < 2 && (
                      <p className='multi-select-empty'>
                        Zur Mehrfachauswahl auf der linken Seite Organisations-Einheiten auswählen. Du kannst nur
                        Organisations-Einheiten der selben Ebene auswählen.
                      </p>
                    )}
                    {selectedOrgUnits?.length >= 2 && (
                      <MultiSelectSelectedItems nodes={selectedOrgUnits} onRemoveNode={onRemoveCallback} />
                    )}
                  </div>
                )}
              </div>
              {multiSelectEnabled && (
                <div className={Styles.Footer}>
                  <TextButton onClick={hidePopOver} text={'OK'} variant={TextButtonVariant.Primary} />
                </div>
              )}
            </>
          )}
        </div>
      </PopOver>
    </div>
  );
};

export default OrgMultiSelect;
