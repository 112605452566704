import { NetworkError } from '../errors/NetworkError';
import { NotFoundError } from '../errors/NotFoundError';
import { DataProtectError } from '../errors/DataProtectionError';
import { ProofOfDelivery } from '../models/enumerations/ProofOfDelivery';

type ReturnType = {
  message: string;
  retryable: boolean;
};

export const getImageErrorMessageByError = (error: Error, signatureType: ProofOfDelivery): ReturnType | null => {
  if (!error) {
    return null;
  }

  const signatureName = (() => {
    switch (signatureType) {
      case 'imageSignature':
        return 'Unterschrift (Foto)';
      case 'signature':
        return 'Unterschrift (Scanner)';
      case 'image':
        return 'Unterschrift';
    }
  })();

  if (error instanceof NetworkError) {
    return {
      message: `${signatureName} konnte nicht geladen werden`,
      retryable: true,
    };
  }
  if (error instanceof NotFoundError) {
    return {
      message: `${signatureName} konnte nicht gefunden werden`,
      retryable: false,
    };
  }
  if (error instanceof DataProtectError) {
    return {
      message: `${signatureName} wurde wegen Datenschutzverstoß gemeldet`,
      retryable: false,
    };
  }
  return {
    message: `${signatureName} konnte nicht geladen werden: unerwarteter Fehler`,
    retryable: false,
  };
};
