import React from 'react';
import Styles from './FilterBubble.module.scss';
import { IoMdCloseCircle } from 'react-icons/io';

type Props = {
  children: React.ReactChild;
  onClose?: () => void;
};

export const NO_OP = () => {
  /**/
};

export const FilterBubble = (props: Props) => {
  const { children, onClose = NO_OP } = props;
  return (
    <span className={Styles.FilterBubble}>
      {children}
      <span className={Styles.Icon}>
        <IoMdCloseCircle />
      </span>
    </span>
  );
};

type ResetBubbleProps = {
  children?: React.ReactChild;
  onClick?: () => void;
};

export const FilterResetBubble = ({ children = 'Filter löschen', onClick }: ResetBubbleProps) => {
  return (
    <span onClick={onClick} className={Styles.ResetBubble}>
      {children}
    </span>
  );
};
