import React, { useCallback } from 'react';
import Styles from './SignatureGalleryElement.module.scss';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import { SignatureImageContainer } from './SignatureImageContainer';
import { Link } from '../components/Link';
import { useDispatch } from 'react-redux';
import { tourDetailsSlice } from '@redux/tour-details.slice';

type Props = {
  url: string;
  finishedDelivery: FinishedDelivery;
  size: number;
};

const SignatureGalleryElement: React.FC<Props> = ({ url, finishedDelivery, size }) => {
  const dispatch = useDispatch();

  const onStopClickCallback = useCallback(() => {
    dispatch(tourDetailsSlice.actions.setSelectedStopNumber(finishedDelivery.displayableStopNumber));
  }, [dispatch, finishedDelivery.displayableStopNumber]);

  return (
    <div className={Styles.SignatureGalleryElement}>
      <div className={Styles.LinkWrapper}>
        <Link onClick={onStopClickCallback}>{finishedDelivery.displayableStopNumber}</Link>
      </div>
      <div
        style={{
          maxHeight: size / 1.33333,
          maxWidth: size,
        }}>
        <SignatureImageContainer
          size={size}
          finishedDelivery={finishedDelivery}
          url={url}
          lazyloadRoot={document.querySelector('.signatures-main-container')}
        />
      </div>
    </div>
  );
};

export { SignatureGalleryElement };
