import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import stoppsStrecke from './stopps-strecke';
import anfahrtsstrecke from './anfahrtsstrecke';
import rueckfahrtstrecke from './rueckfahrtstrecke';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const expression = new SumValueExpression(
  stoppsStrecke,
  new SumValueExpression(anfahrtsstrecke, rueckfahrtstrecke),
  'Tourstrecke',
  undefined,
  '-#.###.##0,0 km',
  getDefinitionByName('Tourstrecke')
);
expression.setIdentifier('tourstrecke');

export default expression;
