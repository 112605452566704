import React, { useCallback, useRef, useState } from 'react';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import { MdInfoOutline } from 'react-icons/md';
import Styles from './ValueExpressionName.module.scss';
import classNames from 'classnames';
import { TruncatedText } from './TruncatedText';
import { Tooltip } from './Tooltip';
import { Placement } from '@popperjs/core';
import { getDefinitionByName } from '../util/kpiDefinitions';
import { KpiName } from '@custom-types/kpi';

export enum ValueExpressionHelpMode {
  ShowHelpOnIconHover,
  ShowHelpOnNameHover,
}

type Props = {
  valueExpression: ValueExpression;
  showHelpTooltip?: boolean;
  className?: string;
  mode?: ValueExpressionHelpMode;
  helpDetails?: 'short' | 'long';
  helpToolTipPlacement?: Placement;
  // defines how the valueExpression label is rendered
  labelName?: 'long' | 'short';
};

const ValueExpressionName: React.FC<Props> = ({
  valueExpression,
  showHelpTooltip = true,
  className,
  mode = ValueExpressionHelpMode.ShowHelpOnNameHover,
  helpDetails = 'short',
  helpToolTipPlacement = 'auto',
  labelName = 'long',
}) => {
  const [hovering, setHovering] = useState(false);
  const [iconHovering, setIconHovering] = useState(false);
  const popOverAnchor = useRef<HTMLDivElement | HTMLSpanElement>(null);
  const definition = valueExpression.definition ?? getDefinitionByName(valueExpression.getLabel() as KpiName);
  const hasDefinition = !!definition;
  const displayHelpIcon = hasDefinition && hovering && showHelpTooltip;

  const label = labelName === 'long' ? valueExpression.getLabel() : valueExpression.getShortLabel();

  const wrapperHoveringCallback = useCallback(() => {
    if (mode === ValueExpressionHelpMode.ShowHelpOnIconHover) {
      setHovering(true);
    } else {
      setIconHovering(true);
    }
  }, [mode]);

  const wrapperHoverOutCallback = useCallback(() => {
    if (mode === ValueExpressionHelpMode.ShowHelpOnIconHover) {
      setHovering(false);
    } else {
      setIconHovering(false);
    }
  }, [mode]);

  const helpContent = (
    <div className={Styles.DefinitionContent}>
      <h1 className={classNames(Styles.Headline, Styles.KpiName)}>{label}</h1>
      {helpDetails === 'long' && <h2 className={Styles.Headline}>Definition</h2>}
      <p data-testid='value-expression-definition' className={Styles.Text}>
        {definition?.definition}
      </p>
      {helpDetails === 'long' && (
        <>
          <h2 className={Styles.Headline}>Erklärung</h2>
          <p className={Styles.Text}>
            {definition?.explanation?.split('\n')?.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  {item}
                  <br />
                </React.Fragment>
              );
            })}
          </p>
        </>
      )}
    </div>
  );

  return (
    <div
      data-testid='value-expression-name-text'
      className={classNames(Styles.ValueExpressionName, className, {
        [Styles.hasIcon]: showHelpTooltip && hasDefinition,
      })}
      onMouseOver={wrapperHoveringCallback}
      onMouseLeave={wrapperHoverOutCallback}>
      <TruncatedText
        className={Styles.Name}
        showTooltipIfCutOff={mode === ValueExpressionHelpMode.ShowHelpOnIconHover || !hasDefinition}
        innerRef={(ref) => {
          if (mode === ValueExpressionHelpMode.ShowHelpOnNameHover) {
            popOverAnchor.current = ref;
          }
        }}>
        {label}
      </TruncatedText>
      {hasDefinition && (
        <>
          {mode === ValueExpressionHelpMode.ShowHelpOnIconHover && (
            <span
              data-testid='value-expression-tooltip-trigger'
              className={classNames(Styles.Icon, {
                [Styles.visible]: displayHelpIcon,
              })}
              onClick={(e) => {
                e.preventDefault();
                setIconHovering(true);
              }}
              onMouseOver={() => setIconHovering(true)}
              onMouseLeave={() => setIconHovering(false)}
              ref={(ref) => {
                if (mode === ValueExpressionHelpMode.ShowHelpOnIconHover) {
                  popOverAnchor.current = ref;
                }
              }}>
              <MdInfoOutline />
            </span>
          )}

          <Tooltip visible={iconHovering} anchorElement={popOverAnchor?.current} placement={helpToolTipPlacement}>
            {helpContent}
          </Tooltip>
        </>
      )}
    </div>
  );
};

export { ValueExpressionName };
