import React, { ReactElement } from 'react';
import style from './mapbox-breadcrumb.module.scss';
import classNames from 'classnames';
import { MapboxControl } from '../mapbox-control';
import OrgUnit from '../../../../../modules/metrics2/models/entities/organization/OrgUnit';
import { TreeType, TreeTypes } from '../../../../../modules/metrics2/models/enumerations/TreeType';
import { MdChevronRight } from 'react-icons/md';

type BreadcrumbItem = Partial<OrgUnit>;
export type MapboxBreadcrumbProps = React.HTMLAttributes<HTMLDivElement> & {
  items: BreadcrumbItem[];
  onClickItem?: (item: BreadcrumbItem) => void;
};

function MapboxBreadcrumbBase({ items, onClickItem, ...htmlProps }: MapboxBreadcrumbProps): ReactElement {
  return (
    <div className={classNames(style.mapboxBreadcrumb)} {...htmlProps}>
      <ul>
        {items.map((item, itemIndex, itemArray) => {
          const nextItem = itemArray?.[itemIndex + 1];
          return (
            <React.Fragment key={item?.name}>
              <li
                role='button'
                onClick={item?.orgKey ? () => onClickItem(item) : () => null}
                className={classNames({
                  disabled: !item?.orgKey,
                  multi: nextItem && item?.orgType === nextItem?.orgType, // multiple selected OrgUnits
                })}>
                {itemIndex === 1
                  ? `${getTreeTypeLabel(item?.treeType)} ${item?.name}` // Special label for the second item
                  : item?.name}
              </li>
              {itemIndex !== itemArray.length - 1 && (
                <li className={style.breadcrumbArrow}>
                  {item?.orgType === nextItem?.orgType ? (
                    <em>,&nbsp;</em> // Comma separated OrgUnits when multiple OrgUnits of the same type are selected
                  ) : (
                    <MdChevronRight size={18} /> // Arrow icon otherwise
                  )}
                </li>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
}
const getTreeTypeLabel = (treeType: TreeType): string => {
  switch (treeType) {
    case TreeTypes.rsteze:
      return 'RS';
    case TreeTypes.zip:
      return 'PLZ';
    default:
      return '';
  }
};
const MapboxBreadcrumb = MapboxControl<MapboxBreadcrumbProps>(MapboxBreadcrumbBase);
export default MapboxBreadcrumb;
