import moment from 'moment-timezone';
import DataConnection from './DataConnection';

export default class GroupedDataConnection<EK, V> extends DataConnection<EK, V> {
  _dataConnections: {
    [key: string]: DataConnection<EK, V>;
  };

  constructor(dataConnections: { [key: string]: DataConnection<EK, V> }) {
    super();
    this._dataConnections = dataConnections;
    Object.values(this._dataConnections).forEach((dc: DataConnection<EK, V>) => {
      dc.on(DataConnection.EVENT_LOADING, this._triggerLoading.bind(this));
      dc.on(DataConnection.EVENT_DATA_CHANGED, this._triggerDataChanged.bind(this));
    });
  }

  terminate() {
    Object.values(this._dataConnections).forEach((dc: DataConnection<EK, V>) => {
      dc.terminate();
    });
    super.terminate();
  }

  get isComplete(): boolean {
    return Object.values(this._dataConnections).every((dc: DataConnection<EK, V>) => dc.isComplete);
  }

  get isLoading(): boolean {
    return Object.values(this._dataConnections).some((dc: DataConnection<EK, V>) => {
      return dc.isLoading;
    });
  }

  get lastUpdatedAt(): moment.Moment {
    const sortedUpdates = Object.values(this._dataConnections)
      .map((dc: DataConnection<EK, V>) => dc.lastUpdatedAt.valueOf())
      .sort();
    if (sortedUpdates.length > 0) {
      return moment(sortedUpdates[0]);
    }
    return moment();
  }

  get data(): Object {
    const data = {};
    Object.entries(this._dataConnections).forEach(([key, dc]) => {
      data[key] = dc.data;
    });
    return data;
  }
}
