import Model, { DefaultType, MemberType } from '../../utils/Model';

export default class GeoCoordinate extends Model<GeoCoordinate> {
  lat: number;
  lng: number;

  constructor(
    source: any, // FIXME: set proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  static _defaults: Object = {
    lat: () => 0.0,
    lng: () => 0.0,
  };

  toArray() {
    return [this.lat, this.lng];
  }

  toReversedArray() {
    return [this.lng, this.lat];
  }

  toCsvString() {
    return `${this.lng},${this.lat}`;
  }

  metricDistanceTo(coordinate: GeoCoordinate): number {
    // Metric earth
    const radius = 6371e3;
    const phi1 = this._toRadians(this.lat);
    const phi2 = this._toRadians(coordinate.lat);
    const lambda1 = this._toRadians(this.lng);
    const lambda2 = this._toRadians(coordinate.lng);
    const deltaPhi = phi2 - phi1;
    const deltaLambda = lambda2 - lambda1;
    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return radius * c;
  }

  _toRadians(number: number): number {
    return (number * Math.PI) / 180;
  }

  isValid() {
    return !!this.lat && !!this.lng;
  }

  interpolateTo(coordinate: GeoCoordinate, fraction: number): GeoCoordinate {
    return new GeoCoordinate({
      lat: this.lat + (coordinate.lat - this.lat) * fraction,
      lng: this.lng + (coordinate.lng - this.lng) * fraction,
    });
  }

  isSame(comparisonObj: GeoCoordinate): boolean {
    return this.lat === comparisonObj.lat && this.lng === comparisonObj.lng;
  }

  getDefaults(): DefaultType<GeoCoordinate> {
    return undefined;
  }

  getMembers(): MemberType<GeoCoordinate> {
    return undefined;
  }
}
