import DeliveryModes, { DeliveryModeType } from '../enumerations/DeliveryMode';

/* eslint-disable camelcase */
export const MarkerColors = Object.freeze({
  finished_gps: { label: 'Zugestellt - GPS', color: '#00A300' },
  finished_hadv: { label: 'Zugestellt - Adresse', color: '#96C679' },
  undeliverable_gps: { label: 'Rücklauf - GPS', color: '#FA0000' },
  undeliverable_hadv: { label: 'Rücklauf - Adresse', color: '#F4A7A3' },
  paketshop_gps: { label: 'Paketshop - GPS', color: '#4A7BC1' },
  paketshop_hadv: { label: 'Paketshop - Adresse', color: '#ABC6F0' },
  planned: { label: 'Geplant', color: '#BEBEBE' },
});

export type MarkerColorsType = { label: string; color: string };
type LocationProvider = 'hadv' | 'gps' | 'fused';

// Merge 'fused' and 'gps', since they both result in GPS markers
const streamlineProviderTypes = (provider?: LocationProvider): LocationProvider | undefined => {
  if (provider === 'fused') {
    return 'gps';
  }
  return provider;
};

export class MarkerColor {
  static byKindAndModeWithDefault(locationType?: string, deliveryMode?: DeliveryModeType): MarkerColorsType {
    const color = this.byKindAndMode(locationType, deliveryMode);

    if (!color) {
      // default color
      return MarkerColors.planned;
    }
    return color;
  }

  static byKindAndMode(locationType?: string, deliveryMode?: DeliveryModeType): MarkerColorsType | null | undefined {
    const unifiedType = streamlineProviderTypes(locationType?.toLowerCase() as LocationProvider);
    switch (deliveryMode) {
      case DeliveryModes.undeliverable:
        return MarkerColors['undeliverable_' + unifiedType];
      case DeliveryModes.paketShop:
        return MarkerColors['paketshop_' + unifiedType];
      default:
        return MarkerColors['finished_' + unifiedType];
    }
  }
}
