import React, { HTMLAttributes, ReactElement, useMemo } from 'react';
import style from './touren-table-tabs.module.scss';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { TourenTableTab } from './touren-table-tab';
import { UseTourenTableTabsOutput } from '@data-table/hooks/use-touren-table-tabs-hook';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';

export type TourenTableTabsProps = HTMLAttributes<HTMLDivElement> &
  Pick<UseTourenTableTabsOutput, 'tabsConfig' | 'currentTab' | 'setCurrentTab'> & {
    duration: Duration;
    currentOrgKey: string;
  };

const TourenTableTabs = ({
  tabsConfig,
  currentTab,
  duration,
  currentOrgKey,
  setCurrentTab,
  ...htmlProps
}: TourenTableTabsProps): ReactElement => {
  const tabsKeys = useMemo(() => Object.keys(tabsConfig), [tabsConfig]);

  const tabsContainerTemplateColumnsStyle = css`
    grid-template-columns: repeat(${tabsKeys.length}, minmax(150px, 250px));
  `;

  return (
    <div className={classNames(style.tourenTableTabs, tabsContainerTemplateColumnsStyle)} {...htmlProps}>
      {tabsKeys.map((uID) => {
        return (
          <TourenTableTab
            key={uID}
            tabConfig={tabsConfig[uID]}
            uID={uID}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            duration={duration}
            currentOrgKey={currentOrgKey}
          />
        );
      })}
    </div>
  );
};

export default TourenTableTabs;
