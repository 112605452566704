import { useCallback, useEffect, useState } from 'react';
import DataConnectionsService from '../../dataconnection/services/DataConnectionsService';
import DataConnection from '../../dataconnection/connections/DataConnection';
import { DataConnectionMetaData } from '../../dataconnection/models/types/DataConnectionMetaData';
import GroupedDataConnection from '../../dataconnection/connections/GroupedDataConnection';

export const useGroupedDataConnection = (
  dataConnection: GroupedDataConnection<any, any>,
  dataConnectionsService: DataConnectionsService,
  enabled: boolean = true
): { data: any; loading: boolean } => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(enabled);

  const checkMeta = (meta: DataConnectionMetaData) => {
    if (meta?.completed) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  const setDataIfConnectionIsPrefilled = useCallback((dataConnection: DataConnection<any, any>) => {
    if (dataConnection.meta?.completed) {
      setLoading(false);
      setData(dataConnection.data);
    } else {
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    let isMounted = true;

    // When dataConnection is not defined in first render, we
    // set loading to true, because it will be loaded in the next
    // render phase.
    if (typeof dataConnection === 'undefined') {
      setData(null);
      setLoading(true);
      return;
    }
    // If it's explicitly set to null, this is an indicator that the
    // data should not be loaded. So we also set the loading variable
    // to false
    if (dataConnection === null) {
      setData(null);
      setLoading(false);
      return;
    }

    dataConnection.addListener(DataConnection.EVENT_DATA_CHANGED, () => {
      if (!isMounted) {
        return;
      }
      setData(dataConnection.data);
      checkMeta(dataConnection.meta);
    });

    dataConnection.addListener(DataConnection.EVENT_LOADING, () => {
      if (!isMounted) {
        return;
      }
      checkMeta(dataConnection.meta);
    });

    dataConnection.addListener(DataConnection.EVENT_TERMINATED, () => {
      if (!isMounted) {
        return;
      }
      checkMeta(dataConnection.meta);
    });

    const key = dataConnectionsService.registerDataConnection(dataConnection);
    // If data is cached the connection already contains the data
    // without emitting an event
    setDataIfConnectionIsPrefilled(dataConnection);

    return () => {
      isMounted = false;
      dataConnectionsService.onTerminatedConnection(key);
    };
  }, [dataConnection, dataConnectionsService, enabled, setDataIfConnectionIsPrefilled]);

  return { loading, data };
};
