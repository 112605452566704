import React, { ReactElement } from 'react';
import style from './fullscreen-action.module.scss';
import classNames from 'classnames';
import { default as withFullscreen, FullscreenActionComponentProps } from './with-fullscreen-action.hoc';
import { BiExitFullscreen, BiFullscreen } from 'react-icons/bi';
import { Tooltip } from '@other-components/tooltip';

export function FullscreenAction({
  onClick,
  disabled = false,
  disableTooltip,
  className,
  isFullscreen,
  ...htmlProps
}: FullscreenActionComponentProps): ReactElement {
  return (
    <Tooltip
      data-testid='fullscreen-action-tooltip'
      tooltipText={isFullscreen ? 'Vollbildmodus verlassen' : 'Vollbildmodus'}
      disableTooltip={disableTooltip}>
      <div
        data-testid='fullscreen-action'
        data-disabled={disabled}
        {...htmlProps}
        className={classNames(style.fullscreenAction, className, {
          [style.disabled]: disabled,
        })}
        onClick={disabled ? null : onClick}>
        {isFullscreen ? <BiExitFullscreen /> : <BiFullscreen />}
      </div>
    </Tooltip>
  );
}
export default withFullscreen(FullscreenAction);
