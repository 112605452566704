import React, { ReactElement, useEffect, useState } from 'react';
import style from './mapbox-button-group.module.scss';
import classNames from 'classnames';
import { Button, ButtonGroup, ButtonGroupProps } from 'reactstrap';
import { MapboxControl } from '../mapbox-control';
import { Tooltip, TooltipProps } from '../../../../other/tooltip';

type IterableValue<T> = {
  label: string;
  tooltipProps?: Omit<TooltipProps, 'children'>;
  value?: T;
  disabled?: boolean;
};
export type MapboxButtonGroupProps<T = string> = ButtonGroupProps & {
  values: IterableValue<T>[];
  onValueChange: (value: T) => void;
  /**
   * Button group size
   * @default md
   */
  size?: 'sm' | 'md' | 'lg';
  vertical?: boolean;
  initialValueLabel?: string;
  disabled?: boolean;
};

function MapboxButtonGroupBase({
  values,
  vertical = false,
  initialValueLabel = values?.[0]?.label,
  size = 'md',
  disabled = false,
  onValueChange,
  ...buttonGroupProps
}: MapboxButtonGroupProps): ReactElement {
  const [currentLabel, setCurrentLabel] = useState<string>(initialValueLabel);

  useEffect(() => {
    const iterableValue = values?.find((value) => value.label === currentLabel);
    onValueChange(iterableValue?.value || iterableValue?.label);
  }, [currentLabel, values, onValueChange]);

  return (
    <ButtonGroup size={size} className={classNames(style.mapboxButtonGroup)} vertical={vertical} {...buttonGroupProps}>
      {values?.map((value) => {
        const buttonDisabled = disabled || value.disabled;
        return (
          <Button
            key={value?.label}
            onClick={() => setCurrentLabel(value?.label)}
            disabled={buttonDisabled}
            color={currentLabel === value?.label && !buttonDisabled ? 'primary' : 'light'}>
            <Tooltip {...value?.tooltipProps}>
              <div className={style.btnLabel}>{value.label}</div>
            </Tooltip>
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
export const MapboxButtonGroup = MapboxControl<MapboxButtonGroupProps>(MapboxButtonGroupBase);
export default MapboxButtonGroup;
