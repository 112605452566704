import { IRawAnomalyResponse } from '../interfaces/IRawAnomalyResponse';
import moment from 'moment';
import { DateFormat } from '../../components/table/DateTimeCell';
import { groupBy } from 'lodash';

export const AnomalyReason = {
  'wzf.ht-zustellung': 'Außerhalb Wunschzeitfenster',
  'wzf.ht-abholung': 'Außerhalb Wunschzeitfenster',
  'wzf.atg-abholung': 'Außerhalb ATG-Abholzeitfenster',
  's.colli-teillieferung': 'Teillieferung (mehrcollig)',
  'usk.ruecklauf-am-tourende': 'Rücklauf am Tourende',
  'hr.zustellzeit': 'Außerhalb Hermes Zustellzeit',
  'uv.annahmeverweigerung-wunschtag': 'Rücklauf A am Wunschtag',
  'hr.zustelltag': 'Tagversetzte Bearbeitung',
};

// Use this code to add anomalies using user permissions
// appEvents.on("INIT_DONE", () => {
//   const addMappings = () => {};
//   authServiceInstance.eventEmitter.on(AuthEvent.REQUEST_REFRESH, addMappings);
//   authServiceInstance.eventEmitter.on(AuthEvent.EVENT_LOGGED_IN, addMappings);
// });

export type AnomalyTableDisplayFormat = {
  tourIdentifier: {
    tourDate: string;
    tourNumber: number;
    zsbOrgId: number;
    contractorKey?: string;
  };
  location: string;
  reason: string;
  detail: string;
  tourDate: string;
  anomalyLevel: string;
  anomalyType?: string;
};

export const mapAnomalyEntryDataToTableData = (anomalyEntry: IRawAnomalyResponse): AnomalyTableDisplayFormat => {
  const getDifference = () => {
    if (!anomalyEntry.details) return '';
    return `Wunschzeitfenster`;
  };

  if (AnomalyReason[anomalyEntry.anomalyType] == null) {
    return null;
  }

  return {
    tourIdentifier: {
      ...anomalyEntry.tourIdentifier,
      contractorKey: anomalyEntry.contractorKey,
    },
    location: String(anomalyEntry.tourIdentifier.zsbOrgId),
    reason: AnomalyReason[anomalyEntry.anomalyType],
    detail: getDifference(),
    tourDate: moment(anomalyEntry.tourIdentifier?.tourDate).format(DateFormat.Date),
    anomalyType: anomalyEntry.anomalyType,
    anomalyLevel: anomalyEntry.anomalyLevel,
  };
};

export const groupAnomalies = (anomalyEntry: AnomalyTableDisplayFormat[]): AnomalyTableDisplayFormat[] => {
  if (!Array.isArray(anomalyEntry)) return [];

  const groupsOfAnomalies = groupBy(anomalyEntry, (item) => {
    return `${item.tourDate}|${item.tourIdentifier.tourNumber}|${item.reason}`;
  });
  const groupedWithCount = Object.entries(groupsOfAnomalies).map(([_, value]) => {
    if (value[0]?.anomalyLevel === 'tour') {
      return {
        ...value[0],
        detail: '1 Tourenbereich',
      };
    } else if (['wzf.ht-zustellung', 'wzf.ht-abholung', 'wzf.atg-abholung'].includes(value[0].anomalyType)) {
      return {
        ...value[0],
        detail: `${value.length} Zeitfenster`,
      };
    }
    const name = value.length <= 1 ? 'Sendung' : 'Sendungen';
    return {
      ...value[0],
      detail: `${value.length} ${name}`,
    };
  });

  return groupedWithCount;
};

export const countAnomaliesTypes = (anomalyData: IRawAnomalyResponse[]) => {
  const counter = new Map<keyof typeof AnomalyReason, number>([]);
  anomalyData.forEach((anomaly) => {
    if (anomaly.anomalyLevel === 'tour') {
      // Anomalies on tour level shouldn't have a counter (AUP-497)
      counter.set(AnomalyReason[anomaly.anomalyType], null);
      return;
    }
    counter.set(
      AnomalyReason[anomaly.anomalyType],
      (counter.has(AnomalyReason[anomaly.anomalyType]) ? counter.get(AnomalyReason[anomaly.anomalyType]) : 0) +
        anomaly.tourStopRefs.length
    );
  });
  return Array.from(counter.entries()).map(([key, value]) => {
    return {
      name: key,
      count: value,
    };
  });
};
