import { KpisQuery, KpisQueryVariables, useKpisQuery } from '@graphql-client/graphql';
import {
  ConfigKey,
  EnvironmentConfigurationService,
} from '@legacy-modules/common/services/EnvironmentConfigurationService';
import { selectToken } from '@redux/auth.selectors';
import { useMemo } from 'react';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

/** Iterate through kpi values of results to filter out 'na' flagged */
export const KpiFilter = (kpiValue: { kpiId: string; value: string }) => kpiValue.value !== 'NA';
/** Parse as float, since we use numbers throughout the app */
export const KpiMapper = (kpiValue: { kpiId: string; value: string }) => ({
  ...kpiValue,
  value: parseFloat(kpiValue.value),
});

export default function useKpiQuery<TData = KpisQuery, TError = unknown>(
  variables: Omit<KpisQueryVariables, 'requestId'>,
  options?: UseQueryOptions<KpisQuery, TError, TData>
): UseQueryResult<TData, TError> {
  const token = useSelector(selectToken);
  const dataSource = useMemo<{ endpoint: string; fetchParams?: RequestInit }>(
    () => ({
      endpoint: EnvironmentConfigurationService.get(ConfigKey.GQL_API_URL),
      fetchParams: {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      },
    }),
    [token]
  );
  const requestId = useMemo(() => crypto.randomUUID(), []);
  return useKpisQuery<TData, TError>(dataSource, { requestId, ...variables }, options);
}
