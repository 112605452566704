import React, { HTMLProps } from 'react';
import Styles from './Loader.module.scss';
import LoadingChart from '../../overview/assets/loading_chart.svg';
import classNames from 'classnames';

type Props = HTMLProps<HTMLDivElement>;

export const Loader: React.FC<Props> = ({ className, ...htmlProps }: Props) => (
  <div {...htmlProps} className={classNames(Styles.Loader, className)}>
    <div className={Styles.LoaderRing} />
  </div>
);

export const ChartLoader: React.FC = () => {
  return (
    <div className={Styles.loaderwrapper}>
      <div>
        <img src={LoadingChart} alt='' />
      </div>
      <div className={Styles.textLoading}>Daten werden geladen...</div>
    </div>
  );
};
