import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { MetricTypeKey } from './metric-type-keys';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.ABHsum,
  aggregation: AggregationType.sum,
  hidden: true,
  positiveDirection: PositiveDirection.custom,
  label: 'Abholungen ohne Mitnahmen',
  definition: getDefinitionByName('Abholungen ohne Mitnahmen'),
});

const expression = new SingleValueExpression(metric);
expression.setIdentifier('abholungen');

export default expression;
