import React from 'react';
import Styles from './ContextMenuItem.module.scss';

type Props = {
  onClick: () => void;
  children?: React.ReactNode;
};

export const ContextMenuItem: React.FC<Props> = ({ onClick, children }: Props) => {
  return (
    <li className={Styles.ContextMenuItem} onClick={onClick}>
      {children}
    </li>
  );
};
