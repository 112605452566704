import Model, { DefaultType, MemberType } from '../../../../utils/Model';
import { PlainObjectOf } from './OrgUnitTreeRequestPayload';

export default class Contact extends Model<Contact> {
  city: string;
  additional: string;
  telephone: string;
  zipcode: string;
  street: string;
  streetNr: string;

  constructor(source: PlainObjectOf<Contact>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<Contact> {
    return undefined;
  }

  getMembers(): MemberType<Contact> {
    return undefined;
  }
}
