import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import lagernd from './lagernd';
import bestand from './bestand';

const expression = new QuotientValueExpression(
  lagernd,
  bestand,
  'Lagernd-Quote',
  true,
  undefined,
  undefined,
  getDefinitionByName('Lagernd-Quote')
);

expression.setIdentifier('stock.stored_per_inventory');

export default expression;
