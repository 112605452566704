import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Styles from './Table.module.scss';
import classNames from 'classnames';
import { TableBody } from './TableBody';
import { TableHead } from './TableHead';
import { TableFooter } from './TableFooter';
import { useScrollbarWidth } from '../../hooks/useScrollbarWidth';
import { useWindowSize } from '../../hooks/useWindowResize';

type TableProps = React.TableHTMLAttributes<HTMLTableElement> & {
  alteringColors?: boolean;
  body: ReactElement<any> | ReactElement<any>[];
  header?: React.ReactNode;
  footer?: React.ReactNode;
  maxBodyHeight?: string | number;
  fullscreen?: boolean;
};

export const Table: React.FC<TableProps> = ({
  className,
  style,
  header,
  footer,
  body,
  maxBodyHeight,
  fullscreen,
  alteringColors = false,
  ...props
}: TableProps) => {
  const bodyRef = useRef(null);
  const [tableBodyHeight, setTableBodyHeight] = useState<number | string>(0);
  const [offsetStyle, setOffsetStyle] = useState({
    paddingRight: 0,
  });

  const { scrollbarWidth } = useScrollbarWidth(bodyRef);

  useEffect(() => {
    setOffsetStyle({
      paddingRight: scrollbarWidth,
    });
  }, [scrollbarWidth]);

  const size = useWindowSize();

  useEffect(() => {
    if (!fullscreen) {
      setTableBodyHeight(maxBodyHeight);
      return;
    }
    if (!bodyRef?.current) {
      return;
    }
    const { top } = bodyRef.current.getBoundingClientRect();
    const value = size.height - top;

    if (footer) {
      setTableBodyHeight(value - 90);
    } else {
      setTableBodyHeight(value);
    }
  }, [size, bodyRef, fullscreen, maxBodyHeight, footer]);

  return (
    <table
      className={classNames(Styles.Table, className, {
        [Styles.AlteringColors]: alteringColors,
      })}
      style={style}
      {...props}>
      {header && <TableHead style={offsetStyle}>{header}</TableHead>}
      <TableBody hasFooter={maxBodyHeight !== undefined} height={tableBodyHeight} ref={bodyRef}>
        {body}
      </TableBody>
      {footer && <TableFooter style={offsetStyle}>{footer}</TableFooter>}
    </table>
  );
};

type TableHeaderCellProps = {
  alignment?: 'left' | 'center';
  fixedWidth?: number | string;
} & React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement>;

export const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  children,
  className,
  alignment = 'center',
  fixedWidth,
  ...props
}) => {
  const { style = {}, ...otherProps } = props;
  const ourStyle = fixedWidth ? { maxWidth: fixedWidth, minWidth: fixedWidth } : {};

  return (
    <th
      className={classNames(Styles.TableHeadCell, className, {
        [Styles.center]: alignment === 'center',
        [Styles.left]: alignment === 'left',
      })}
      style={{ ...style, ...ourStyle }}
      {...otherProps}>
      {children}
    </th>
  );
};

type TableRow = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;

export const TableRow: React.FC<TableRow> = ({ children, ...props }: TableRow) => {
  return (
    <tr className={`${Styles.TableRow}`} {...props}>
      {children}
    </tr>
  );
};

type TableColumnProps = React.DetailedHTMLProps<
  React.TdHTMLAttributes<HTMLTableDataCellElement>,
  HTMLTableDataCellElement
> & {
  isNumber?: boolean;
  children?: React.ReactNode;
  variant?: 'normal' | 'small';
  fixedWidth?: number | string;
};

export const TableColumn: React.FC<TableColumnProps> = ({
  children,
  isNumber = false,
  className,
  variant = 'normal',
  fixedWidth,
  ...props
}: TableColumnProps) => {
  const { style = {}, ...otherProps } = props;
  const ourStyle = fixedWidth ? { maxWidth: fixedWidth, minWidth: fixedWidth } : {};
  return (
    <td
      className={classNames(
        Styles.TableColumn,
        {
          [Styles.IsNumber]: isNumber,
          [Styles.small]: variant === 'small',
        },
        className
      )}
      style={{ ...style, ...ourStyle }}
      {...otherProps}>
      {children}
    </td>
  );
};
