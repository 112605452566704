import * as React from 'react';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import CustomerTrackingData from '../models/types/CustomerTrackingData';
import '../assets/stopDetail.scss';
import '../assets/signaturesDeliveryItem.scss';
import DeliveryItem from './DeliveryItem';
import { MdExpandMore } from 'react-icons/md';
import DeliveryModes from '../models/enumerations/DeliveryMode';
import { DeliveryItemTrackingData } from '../models/types/DeliveryItemTrackingData';
import { useMemo, useState } from 'react';
import { IRawAnomalyResponse } from '../../dashboard/anomalies/interfaces/IRawAnomalyResponse';
import { AnomalyReason } from '../../dashboard/anomalies/utils/AnomalyUtils';
import { knownAnomalyFilter } from '../utils/KnownAnomalyFilter';

type Props = {
  finishedDelivery: FinishedDelivery;
  customers: Array<CustomerTrackingData>;
  shipmentIdToCustomerMap: Map<string, string>;
  anomalies?: IRawAnomalyResponse[];
};

const MAX_DISPLAY_DELIVERY_ITEM_COUNT = 5;

const DeliveryItemsList = (props: Props) => {
  const { customers, finishedDelivery, shipmentIdToCustomerMap, anomalies = [] } = props;

  const { deliveryItems, returnDeliveryItems } = finishedDelivery;
  const firstReturnElems = returnDeliveryItems.slice(0, MAX_DISPLAY_DELIVERY_ITEM_COUNT);

  const [showMore, setShowMore] = useState<boolean>(false);
  const [showMoreAb, setShowMoreAb] = useState<boolean>(false);

  const itemsToShow = !showMore ? deliveryItems.slice(0, MAX_DISPLAY_DELIVERY_ITEM_COUNT) : deliveryItems;
  const expandText = !showMore ? 'Alle anzeigen' : 'Weniger anzeigen';

  const toggleShowMore = () => setShowMore((v) => !v);
  const toggleShowMoreAbholungen = () => setShowMoreAb((v) => !v);

  const createShowMore = (
    deliveryItems: Array<DeliveryItemTrackingData>,
    id: number,
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    expandmsg: string,
    sendungtype: string
  ) => {
    const undeliverable = props.finishedDelivery.deliveryMode === DeliveryModes.undeliverable;
    return (
      <div className='showmore-btn-wrapper'>
        {undeliverable ? (
          <div className='number-sendungen'>{`${deliveryItems.length} Rückläufer`}</div>
        ) : (
          <div className='number-sendungen'>
            {deliveryItems.length > 1
              ? `${deliveryItems.length} ${sendungtype} Sendungen`
              : `${deliveryItems.length} ${sendungtype} Sendung`}
          </div>
        )}
        <div className={`${id !== 0 && deliveryItems.length > 5 && 'visible-showmore'} showmore-btn`} onClick={onClick}>
          <MdExpandMore className='expand-icon' />
          {expandmsg}
        </div>
      </div>
    );
  };

  const anomalyMap = useMemo(() => {
    const knownAnomalies = anomalies.filter(knownAnomalyFilter);
    return knownAnomalies.reduce((acc, curr) => {
      curr?.tourStopRefs?.forEach((tourStopRef) => {
        tourStopRef?.shipmentRefs?.forEach((shipmentRef) => {
          acc.set(shipmentRef, curr);
        });
      });
      return acc;
    }, new Map<string, IRawAnomalyResponse>());
  }, [anomalies]);

  return (
    <React.Fragment>
      {itemsToShow.map((item, id) => {
        return (
          <React.Fragment key={`key-${id}-deliveryitems`}>
            <DeliveryItem
              key={`key-${id}--deliv-item-package`}
              finishedDelivery={finishedDelivery}
              deliveryItems={deliveryItems}
              item={item}
              anomaly={anomalyMap.get(item.deliveryItemId)}
              customer={customers
                .filter((c) => c.customerRef === shipmentIdToCustomerMap.get(item.deliveryItemId))
                .pop()}
            />
            {id === itemsToShow.length - 1 &&
              createShowMore(deliveryItems, id, toggleShowMore, expandText, 'Zugestellte')}
          </React.Fragment>
        );
      })}
      {/* rendering part for return delivery items */}
      {!showMoreAb
        ? firstReturnElems.map((item, id) => {
            return (
              <React.Fragment key={`key-${id}-return-deliveryitems`}>
                <DeliveryItem
                  key={`key-${id}-return-deliv-item-package`}
                  finishedDelivery={finishedDelivery}
                  deliveryItems={deliveryItems}
                  item={item}
                  anomaly={anomalyMap.get(item.deliveryItemId)}
                  customer={customers.filter((c) => c.customerRef === item.customerRef).pop()}
                />
                {id === firstReturnElems.length - 1 &&
                  createShowMore(returnDeliveryItems, id, toggleShowMoreAbholungen, 'Alle anzeigen', 'Abgeholte')}
              </React.Fragment>
            );
          })
        : returnDeliveryItems &&
          returnDeliveryItems.map((item, id) => {
            return (
              <React.Fragment key={`key-${id}-return-deliveryitems-full`}>
                <DeliveryItem
                  key={`key-${id}-return-deliv-item-package`}
                  finishedDelivery={finishedDelivery}
                  deliveryItems={deliveryItems}
                  item={item}
                  anomaly={anomalyMap.get(item.deliveryItemId)}
                  customer={customers.filter((c) => c.customerRef === item.customerRef).pop()}
                />
                {id === returnDeliveryItems.length - 1 &&
                  createShowMore(returnDeliveryItems, id, toggleShowMoreAbholungen, 'Weniger anzeigen', 'Abgeholte')}
              </React.Fragment>
            );
          })}
    </React.Fragment>
  );
};

export default DeliveryItemsList;
