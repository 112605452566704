import React from 'react';
import Styles from './ContextGroup.module.scss';

type Props = {
  children?: React.ReactNode;
  name?: string;
};

export const ContextGroup: React.FC<Props> = ({ children, name }: Props) => {
  return (
    <div className={Styles.Wrapper}>
      <h2 className={Styles.Name}>{name}</h2>
      {children}
    </div>
  );
};
