/* eslint-disable brace-style */
/* eslint-disable max-len */
import { QueryInterface } from '../../../datamanager/models/queries/QueryInterface';
import moment from 'moment-timezone';
import MetricsOverviewQueryRequestPayload from '../websocket/metrics/MetricsOverviewQueryRequestPayload';
import MetricsBaseQuery from './MetricsBaseQuery';
import { MetricTypeKey } from '@contexts/value-expression-context/value-expressions/metric-type-keys';
import { AggregationType } from '../enumerations/AggregationType';
import { WeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';

export default class MetricsOverviewQuery
  extends MetricsBaseQuery
  implements QueryInterface<MetricsOverviewQueryRequestPayload>
{
  constructor(
    public readonly types: Array<MetricTypeKey>,
    public readonly orgKeys: Array<string>,
    dateFrom: moment.Moment,
    dateUntil: moment.Moment,
    weekdayFilter?: WeekdaysFilter,
    valueKey: string | null = null,
    public readonly contractorKey: string | null = null,
    private aggregations?: AggregationType[]
  ) {
    super(types[0], dateFrom, dateUntil, weekdayFilter, valueKey);
  }

  toRequestType() {
    return 'metrics.overview';
  }

  toRequestPayload(): MetricsOverviewQueryRequestPayload {
    return new MetricsOverviewQueryRequestPayload({
      types: this.types,
      orgKeys: this.orgKeys,
      dateFilter: {
        range: {
          from: moment(this.dateFrom).format('YYYY-MM-DD'),
          until: moment(this.dateUntil).format('YYYY-MM-DD'),
        },
        weekdays: this.weekdayFilter,
      },
      valueKey: this.valueKey,
      aggregations: this.aggregations,
      contractorKey: this.contractorKey,
    });
  }
}
