import { Duration } from '../models/enums/Duration';
import { useMemo } from 'react';
import ChartUtils, { FromTo } from '../utils/ChartUtils';

export const useRanges = (
  duration: Duration
): {
  primaryRange: FromTo;
  compareRange: FromTo;
} => {
  const { primaryRange, compareRange } = useMemo(() => {
    if (!duration) {
      return {};
    }
    return {
      primaryRange: ChartUtils.getDateRange(duration),
      compareRange: ChartUtils.getComparisonDateRange(duration),
    };
  }, [duration]);

  return {
    primaryRange,
    compareRange,
  };
};
