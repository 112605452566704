import { useMemo } from 'react';
import { OrgKey, OrgName } from '@data-table/data-table.types';
import { isTourOrgKey } from '@data-table/utils';
import { AreaUtils } from '@legacy-modules/utils/tours/AreaUtils';
import { CountryUtils } from '@legacy-modules/utils/tours/CountryUtils';
import OrgUnitNode from '@legacy-modules/metrics2/models/websocket/org/OrgUnitNode';
import { OrgTreeUtils } from '@legacy-modules/utils/orgtree/OrgTreeUtils';

type UseOrgNamesOutput = Map<OrgKey, OrgName>;
const useOrgNames = (rootOrgKey: OrgKey, orgUnitNode: OrgUnitNode): UseOrgNamesOutput => {
  const orgNameMap = useMemo<Map<OrgKey, OrgName>>(() => {
    if (!orgUnitNode || !rootOrgKey || isTourOrgKey(rootOrgKey)) {
      return new Map<OrgKey, OrgName>();
    }
    const _childNodes = OrgTreeUtils.findNodeInTree(orgUnitNode, 'orgKey', rootOrgKey);
    const _orgKeys = (_childNodes?.children ?? []).map((orgUnitNode) => {
      return {
        orgKey: orgUnitNode.orgUnit.orgKey,
        name: orgUnitNode.orgUnit.name,
      };
    });
    if (CountryUtils.isCountry(rootOrgKey)) {
      const filteredKeys = _orgKeys.filter((orgUnit) => AreaUtils.isArea(orgUnit.orgKey));
      return new Map<OrgKey, OrgName>(filteredKeys.map((orgUnit) => [orgUnit.orgKey, orgUnit.name]));
    } else {
      return new Map<OrgKey, OrgName>(_orgKeys.map((orgUnit) => [orgUnit.orgKey, orgUnit.name]));
    }
  }, [rootOrgKey, orgUnitNode]);
  return orgNameMap ?? new Map<OrgKey, OrgName>([]);
};

export default useOrgNames;
