import BasePayload from '../../../../../common/models/websocket/payloads/BasePayload';
import Alert from '../../../Alert';
import { DefaultType, MemberType } from '../../../../../utils/Model';
import { PlainObjectOf } from '../../../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';
import WebsocketEvent from '../../../../../common/models/websocket/WebsocketEvent';

export default class SystemAlertsPayload extends BasePayload<SystemAlertsPayload> {
  alerts: Array<Alert>;

  constructor(source: PlainObjectOf<SystemAlertsPayload>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<SystemAlertsPayload> {
    return undefined;
  }

  getMembers(): MemberType<SystemAlertsPayload> {
    return {
      alerts: (src) => (src ? src.map((a) => new Alert(a)) : []),
    };
  }
}
