import React, { ReactElement } from 'react';
import style from './draggable-list.module.scss';
import classNames from 'classnames';
import {
  withDraggableList,
  withDraggableListItem,
  DraggableListComponentProps,
  DraggableListItemComponentProps,
} from './with-draggable-list.hoc';

export function DraggableList({
  children,
  className,
  scrollContainerRef,
  ...htmlProps
}: DraggableListComponentProps): ReactElement {
  return (
    <div {...htmlProps} className={classNames(style.draggableList, className)} ref={scrollContainerRef}>
      {children}
    </div>
  );
}

export function DraggableListItem({
  children,
  className,
  sortableItemProps,
  pinned = false,
  pinningEnabled = true,
  ...htmlProps
}: DraggableListItemComponentProps): ReactElement {
  const { setNodeRef, attributes, listeners, isDragging } = sortableItemProps;
  return (
    <div
      {...htmlProps}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={classNames(
        style.draggableItem,
        {
          [style.pinned]: pinned,
          [style.pinningDisabled]: !pinningEnabled,
          [style.draggable]: !pinned,
          [style.dragged]: isDragging,
        },
        className
      )}>
      {children}
    </div>
  );
}
export default {
  List: withDraggableList(DraggableList),
  Item: withDraggableListItem(DraggableListItem),
};
