import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { TreeTypeLabels, TreeTypeLabelDetails, TreeType } from '../../../metrics2/models/enumerations/TreeType';
import { Tooltip } from '../../../common/components/Tooltip';

const buttonOrder = ['hermes_org', 'rsteze', 'zip'];

type Props = {
  orgType: string;
  treeType?: TreeType;
  treeTypes: Array<TreeType>;
  onSwitchTreeType: (arg0: string, arg1: string) => void;
};
const ModeSwitch: React.FC<Props> = ({ orgType, treeType, treeTypes, onSwitchTreeType }) => {
  const isActiveTreeType = (element: TreeType) => {
    if (treeType) {
      return treeType === element;
    } else if (treeTypes.length > 0) {
      return treeTypes[0] === element;
    } else {
      return false;
    }
  };

  // Buttons should keep same position independent of data order
  const sortedTreeTypes = [...(treeTypes || [])].sort(
    (typeA, typeB) => buttonOrder.indexOf(typeA) - buttonOrder.indexOf(typeB)
  );

  return (
    <ButtonGroup
      style={{
        height: 30,
        position: 'absolute',
        left: 15,
        top: 50,
        zIndex: 1000,
      }}
      className='tree-type-switch'>
      {sortedTreeTypes.map((treeType) => {
        return (
          <Button
            id={treeType.replace('/', '_')}
            key={treeType}
            style={{
              fontSize: 12,
              backgroundColor: isActiveTreeType(treeType) ? '#0076a7' : '#fff',
            }}
            outline={!isActiveTreeType(treeType)}
            color={isActiveTreeType(treeType) ? 'primary' : 'secondary'}
            onClick={() => !isActiveTreeType(treeType) && onSwitchTreeType(orgType, treeType)}>
            {TreeTypeLabels[treeType]}
            <Tooltip
              placement='bottom'
              anchorElement={document.getElementById(treeType.replace('/', '_'))}
              enterDelay={250}>
              {TreeTypeLabelDetails[treeType]}
            </Tooltip>
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default ModeSwitch;
