import IconButton from '../../common/components/IconButton';
import Styles from './OrganizationMultiSelection.module.scss';
import { MdContentCopy } from 'react-icons/md';
import React, { useCallback, useRef } from 'react';

type Props = {
  textToCopy: string;
};

export const CopyButton: React.FC<Props> = ({ textToCopy }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const copyText = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    inputRef.current.select();
    document.execCommand('copy');
  }, []);

  return (
    <React.Fragment>
      <input
        style={{
          opacity: 0,
          position: 'fixed',
          left: '-1000000px',
          zIndex: -10000,
        }}
        ref={inputRef}
        readOnly
        type='text'
        value={textToCopy}
      />
      <IconButton className={Styles.IconButton} onClick={copyText} icon={<MdContentCopy />} />
    </React.Fragment>
  );
};
