import * as React from 'react';
import { ButtonGroup, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import '../assets/tourQuickSelect.scss';
import orderBy from 'lodash/orderBy';
import { useCallback, useState } from 'react';

export type QuickSelectOption = {
  label: string;
  value: string;
};

type Props = {
  onPrevClick?: () => void;
  onNextClick?: () => void;
  onOptionClick?: (arg0: string) => void | null | undefined;
  options?: Array<QuickSelectOption> | null | undefined;
  selectionName?: string;
};

const TourQuickSelect = (props: Props) => {
  const { options: propOptions, onPrevClick, onOptionClick = () => undefined, onNextClick, selectionName } = props;

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = useCallback(() => setDropdownOpen((v) => !v), []);

  const options = orderBy(propOptions, (o) => {
    return Number(o.value);
  });

  const displayOptions = options && options.length > 0;

  return (
    <div className='TourQuickSelect' style={{ display: 'flex' }}>
      <ButtonGroup>
        {onPrevClick && (
          <Button
            color='white'
            // Remove focused state after click
            onMouseDown={(e) => e.preventDefault()}
            onClick={onPrevClick}>
            <MdChevronLeft size={20} />
          </Button>
        )}
        {!displayOptions && (
          <Button color='white' disabled className={'BtnDisabled'}>
            {selectionName}
          </Button>
        )}
        {displayOptions && (
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle color='white' caret>
              {selectionName}&nbsp;
            </DropdownToggle>
            <DropdownMenu className='dropdown-menu'>
              {options.map((o: QuickSelectOption, i) => (
                <DropdownItem key={`${o.value}${i}`} onClick={() => onOptionClick(o.value)}>
                  {o.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
        )}
        {onNextClick && (
          <Button
            color='white'
            onClick={onNextClick}
            // Remove focused state after click
            onMouseDown={(e) => e.preventDefault()}>
            <MdChevronRight size={20} />
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
};

export default TourQuickSelect;
