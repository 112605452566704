import { useCallback, useEffect, useMemo, useState } from 'react';
import { SortingState, OnChangeFn, Updater } from '@tanstack/react-table';
import { DataTableComponentId, DataTableUserConfig } from '@data-table/data-table.types';
import { useUserConfig } from '@hooks/use-user-config-hook';

type UseDataTableSortingStateOutput = [SortingState, OnChangeFn<SortingState>];

const useDataTableSortingState = (
  componentId: DataTableComponentId,
  defaultState: SortingState = []
): UseDataTableSortingStateOutput => {
  const [columnSorting, setColumnSorting] = useState<SortingState>(defaultState);

  const defaultValue = useMemo(
    () => ({
      columnSorting: defaultState,
    }),
    [defaultState]
  );

  const [dataTableUserConfig, updateDataTableUserConfig, isLoading] = useUserConfig<DataTableUserConfig>(
    componentId,
    defaultValue
  );

  useEffect(() => {
    if (isLoading || !dataTableUserConfig?.columnSorting?.length) {
      return;
    }
    setColumnSorting(dataTableUserConfig.columnSorting);
  }, [isLoading, dataTableUserConfig?.columnSorting]);

  const setSorting: OnChangeFn<SortingState> = useCallback(
    (state: SortingState | Updater<SortingState>) => {
      const newSorting = typeof state === 'function' ? state(columnSorting) : state;
      setColumnSorting(newSorting);
      updateDataTableUserConfig({
        ...dataTableUserConfig,
        columnSorting: newSorting,
      });
    },
    [columnSorting, updateDataTableUserConfig, dataTableUserConfig]
  );

  return [columnSorting, setSorting];
};

export default useDataTableSortingState;
