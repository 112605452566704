import React from 'react';
import { useSelector } from 'react-redux';
import { AppInitializing } from './AppInitializing';
import { AppErrorPage } from './AppErrorPage';
import { useAuthContext } from '@contexts/auth-context';
import { selectInitialized, selectLoggedIn } from '@redux/auth.selectors';

type Props = {
  children: React.ReactNode;
};

const RequireAuthentication: React.FC<Props> = ({ children }: Props) => {
  const loggedIn = useSelector(selectLoggedIn);
  const initialized = useSelector(selectInitialized);
  const authService = useAuthContext();
  if (!authService) {
    return <AppErrorPage error={new Error('Schwerwiegender Fehler, bitte kontaktiere den Support.')} />;
  }

  if (!loggedIn || !initialized) {
    return <AppInitializing loadingText={'Anwendung wird geladen...'} />;
  }

  return <>{children}</>;
};

export default RequireAuthentication;
