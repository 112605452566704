/* eslint-disable max-len */
export enum WellKnownPermission {
  DashboardHideBestaende = 'ui.dashboard.hide-bestaende',
  DashboardSetGoals = 'ui.dashboard.set-goals',
  AdminCopyOrgId = 'admin.ui.org-select.copy-org-key',
  ShipmentHistory = 'ui.dashboard.allow-shipment-history',
  TourSeeDetails = 'ui.tour.see-details',
  HidePhotoReport = 'ui.dashboard.hide.photo-report',
  TourReport = 'ui.tour.report',
  TourAllowDateSelection = 'ui.tour.allow-date-selection',
  TourEmploymentTypesAll = 'ui.tour.employment-types.all',
  ShipmentsAllowSearch = 'ui.shipments.allow-search',
  TourShowGpsRawData = 'ui.tour.show-gps-rawdata',
  UiGeneralViewModesDashboard = 'ui.general.view-modes.dashboard',
  // Currently not in usage, since these old Zeitfenster-KPIs were removed
  KpiShowPaket = 'kpi.show-paketankuendigung',
  ShowPrototypeHinweise = 'prototype.hinweise', // Schränkt die Sichtbarkeit von Hinweisen ein die in der Prototyp-Version enthalten sind
  ShowPrototypeZeitfenster = 'prototype.zeitfenster', // Schränkt die Sichtbarkeit von den Prototype-Zeitfenster in den Details einer geplanter Tour ein
  ShowPrototypeAbwicklungsMenge = 'prototype.abwicklungsmenge', // Schränkt die Sichtbarkeit von den Prototype-Abwicklungsmenge ein
  // Schränkt die Sichtbarkeit von alten Kennzahlen "Produktivität alt", "Netto-Zustellzeit alt", "Ø Netto-Zustellzeit alt" ein
  ShowOldProduktivitaet = 'feature.old.produktivitaet',
  ShowPrototypeGeneral = 'prototype.general',
  ShowTourenBerichtExportButton = 'signature.fraud.export-button', // Schränkt die Sichtbarkeit von den Export-Button in der Signatur-Touren-Bereich ein
}
