import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import React from 'react';
import { formatValueExpressionValue } from '../utils/FormatValueExpression';

type Props = {
  valueExpression: ValueExpression;
  veData: number | string | null;
  className?: string;
  // if true always prefixes with a sign character (+)
  enforceSign?: boolean;
};

const isPositiveNumber = (value: string | number) => {
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;
  return !isNaN(numberValue) && numberValue > 0;
};

const KpiValue: React.FC<Props> = ({ valueExpression, veData, className, enforceSign = false }: Props) => {
  const formattedValue = (() => {
    if (veData === null && valueExpression.nullValue) {
      return valueExpression.nullValue;
    }
    try {
      const val = formatValueExpressionValue(valueExpression, veData);
      if (enforceSign && veData != null && isPositiveNumber(veData)) {
        return `+${val}`;
      }
      return val;
    } catch (e) {
      return 'Fehlende ValueExpression';
    }
  })();

  return <span className={className}>{formattedValue}</span>;
};

export default KpiValue;
