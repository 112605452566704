import React, { HTMLAttributes } from 'react';
import { Button } from 'reactstrap';
import style from './filter-controls.module.scss';

type FilterControlsProps = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  confirmCallback?: () => void;
  cancelCallback?: () => void;
};

const FilterControls = ({ children, confirmCallback, cancelCallback, ...htmlProps }: FilterControlsProps) => {
  return (
    <>
      <div {...htmlProps}>{children}</div>
      <div className={style.footer}>
        <Button
          data-testid='data-table-filter-reset-button'
          color='white'
          disabled={!cancelCallback}
          onClick={cancelCallback}>
          Zurücksetzen
        </Button>
        <Button
          data-testid='data-table-filter-confirm-button'
          color='primary'
          type='submit'
          disabled={!confirmCallback}
          onClick={confirmCallback}>
          Anwenden
        </Button>
      </div>
    </>
  );
};

export default FilterControls;
