import { useCallback, useMemo } from 'react';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import ChartUtils from '../utils/ChartUtils';
import { ValueType } from '../../metrics2/models/enumerations/ValueType';
import { ValuableComplaintReasonsColors } from '../../complaints/models/enums/ValuableComplaintsReason';
import { UndeliverableReasonColors } from '../../metrics2/models/enumerations/UndeliverableReason';
import { shadeColor } from '../../utils/color/colorUtils';
import getRandomHexColorByIndex from '../../utils/color/getRandomHexColorByIndex';
import MetricsDataProvider from '../../metrics2/services/MetricsDataProvider';
import { get, orderBy, zip, has } from 'lodash';
import { _isStacked, DatasetType } from '../charts/DetailedChart';
import { v4 as uuidv4 } from 'uuid';
import { ChartType } from '../../metrics2/models/enumerations/ChartType';
import { Duration } from '../models/enums/Duration';
import { mapKeys } from '../../metrics2/models/entities/MetricType';
import SingleValueExpression from '../../valueexpressions/models/valueexpressions/SingleValueExpression';

export type ValueExpressionMap = Map<string, ValueExpression>;

export const useChartDataTransformation = (
  showBenchmark: boolean,
  showMetricValues: boolean,
  valueExpressions: ValueExpressionMap,
  activeDashboard: string,
  selectedChartType: ChartType,
  duration: Duration,
  data: { primary: any; compare: any },
  loading: boolean,
  valueExpressionKey: string,
  ve: ValueExpression,
  threshold: number
) => {
  const _mergeDatasets = (datasets: DatasetType[], label = 'Sonstige', color = '#c7c7c7') => {
    const ds: any = { ...datasets[0] };
    ds.label = label;
    ds.backgroundColor = color;
    ds.borderColor = shadeColor(color, -20);
    ds.details = orderBy(datasets, ['sum', (ds) => ds.label.toLowerCase()], ['desc', 'asc']);
    ds.data = zip(...datasets.map((ds) => ds.data)).map((ds) => {
      return ds.filter((v) => !isNaN(v)).reduce((a, b) => a + b, 0);
    });
    return ds;
  };

  const _groupDatasetByThreshold = useCallback(
    (datasets: DatasetType[]): DatasetType[] => {
      const total = datasets.map((d) => d.sum).reduce((a, b) => a + b, 0);
      const aboveThresholdDatasets = [];
      const underThresholdDatasets = [];
      datasets.forEach((ds) => {
        const percentage = (ds.sum / total) * 100;
        ds.percentage = percentage;
        if (percentage <= threshold) {
          underThresholdDatasets.push(ds);
        } else {
          aboveThresholdDatasets.push(ds);
        }
      });

      if (underThresholdDatasets.length > 0) {
        const groupedDataset = _mergeDatasets(underThresholdDatasets);
        return [...aboveThresholdDatasets, groupedDataset];
      }
      return aboveThresholdDatasets;
    },
    [threshold]
  );

  const chartData = useMemo(() => {
    const _cleanDatasetFromNullValues = (datasets: DatasetType[]) => {
      return datasets.map((ds) => {
        const data = ds.data.map((value, i) => {
          if (typeof value === 'number' && value > 1) {
            return value;
          }
          const filteredDatasets = datasets.filter((ds) => typeof ds.data[i] === 'number' && ds.data[i] > 0);
          if (filteredDatasets.length > 0) {
            return value;
          }
          return null;
        });
        return { ...ds, ...{ data } };
      });
    };

    const isMengenPrognose = activeDashboard === 'mengenprognose';
    const dateRange = ChartUtils.getDateRange(duration);
    const dateRanges = isMengenPrognose
      ? ChartUtils.getPrognoseDateRanges(dateRange.from, dateRange.to)
      : ChartUtils.getDateRanges(dateRange.from, dateRange.to);
    const comparisonRange = ChartUtils.getComparisonDateRange(duration);
    const compareRanges = ChartUtils.getDateRanges(comparisonRange.from, comparisonRange.to);
    let datasets: DatasetType[] = [];
    let target = 0;
    const stacked = _isStacked(selectedChartType);

    if (valueExpressionKey == null || loading || !data) {
      return null;
    }

    if (typeof valueExpressionKey !== 'string') {
      throw new Error(`Invalid key: ${valueExpressionKey}`);
    }

    switch (ve.valueType) {
      case ValueType.map: {
        const veMapKeys = mapKeys[(ve as SingleValueExpression).metricType.key] || [];

        datasets = veMapKeys
          .filter((mapKey) => data.primary != null && Object.values(data.primary).some((d) => d[mapKey]))
          .map((mapKey, i) => {
            const color =
              ValuableComplaintReasonsColors[mapKey] ||
              UndeliverableReasonColors[mapKey] ||
              shadeColor(getRandomHexColorByIndex(i), -20);
            return {
              primary: true,
              label: ve.getLabel(mapKey),
              lineTension: 0,
              spanGaps: true,
              pointRadius: dateRanges.resolution === 'isoWeek' ? 2 : 3,
              pointBorderWidth: 2,
              pointBackgroundColor: '#FFFFFF',
              fill: !!stacked,
              backgroundColor: color,
              borderColor: shadeColor(color, -20),
              //  map data values
              data: dateRanges.dates.map((dt) => {
                const dateKey = `${dt.format('YYYY-MM-DD')}_${MetricsDataProvider.translateMomentRangeToGrouping(
                  dateRanges.resolution
                )}`;
                if (has(data, `primary[${dateKey}]`)) {
                  return data.primary[dateKey][mapKey];
                }
                return 0;
              }),
            };
          }) // add sum to sort datasets afterwards
          // this is needed so the legend is ordered the same the chart is
          .map((ds) => {
            const sum = ds.data.filter((s) => !isNaN(s)).reduce((a, b) => a + b, 0);
            return { ...ds, ...{ sum } };
          });
        datasets = _groupDatasetByThreshold(datasets);
        datasets = _cleanDatasetFromNullValues(datasets);
        datasets = orderBy(datasets, ['sum', (ds: DatasetType) => ds.label.toLowerCase()], ['asc', 'asc']);
        break;
      }
      default: {
        datasets = [
          {
            primary: true,
            label: ve.getLabel(),
            lineTension: 0,
            pointRadius: 3,
            pointBorderWidth: 2,
            pointBackgroundColor: '#FFFFFF',
            spanGaps: true,
            fill: !!stacked,
            borderColor: '#0091cd',
            backgroundColor: '#0091cd',
            data: dateRanges.dates.map((dt) => {
              const dateKey =
                dt.format('YYYY-MM-DD') +
                '_' +
                MetricsDataProvider.translateMomentRangeToGrouping(dateRanges.resolution);
              return get(data, `primary[${dateKey}]`, null);
            }),
          },
        ].map((ds) => {
          const data: Array<number | null | undefined> = ds.data.map((value) =>
            typeof value === 'number' && value > 0 ? value : null
          );
          return { ...ds, ...{ data } };
        });

        if (!showMetricValues && !isMengenPrognose) {
          const compareDatasets = [
            {
              compare: true,
              label: `Vergleich ${ve.getLabel()}`,
              spanGaps: true,
              lineTension: 0,
              pointRadius: 2,
              pointHitRadius: 2,
              borderColor: 'rgba(252, 152, 0, 0.4)',
              backgroundColor: 'rgba(252, 152, 0, 0.4)',
              borderDash: [5, 2],
              fill: false,
              data: compareRanges.dates.map((dt) => {
                const dateKey =
                  dt.format('YYYY-MM-DD') +
                  '_' +
                  MetricsDataProvider.translateMomentRangeToGrouping(compareRanges.resolution);
                return get(data, `compare[${dateKey}]`);
              }),
            },
          ].map((ds) => {
            const data = ds.data.map((value) => (typeof value === 'number' && value > 0 ? value : null));
            return { ...ds, ...{ data } };
          });

          datasets = [...compareDatasets, ...datasets];

          // @Todo: this targetData is for demo purpose only right now
          // this has to bee configured somewhere else as soon we now how
          // define some benchmark / target values
          // for now it juts displayse the average value from the comparision daterange
          if (showBenchmark) {
            const targetData = compareDatasets[0].data.map((d) => (d ? d : 0));
            target = targetData.reduce((a, b) => a + b, 0) / targetData.filter((v) => v > 0).length;
            const data = new Array(Math.max(compareRanges.dates.length, dateRanges.dates.length) + 1).fill(null);
            data[0] = target;
            data[data.length - 1] = target;

            const targetDatasets = [
              {
                compare: true,
                label: 'Zielwert',
                spanGaps: true,
                lineTension: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                borderWidth: 2,
                pointBorderWidth: 2,
                tooltop: false,
                borderColor: '#f98dca',
                backgroundColor: '#f98dca',
                pointBackgroundColor: '#f31a95',
                fill: false,
                data,
              },
            ];
            datasets = [...datasets, ...targetDatasets];
          }
        }

        datasets = _cleanDatasetFromNullValues(datasets);
        break;
      }
    }

    return {
      labels: dateRanges.axisLabels,
      dataLabels: dateRanges.dataLabels,
      compareDataLabels: compareRanges.dataLabels,
      labelValues: dateRanges.dates,
      datasets,
      target,
      loaded: true,
      id: uuidv4(),
    };
  }, [
    duration,
    activeDashboard,
    selectedChartType,
    valueExpressionKey,
    data,
    loading,
    ve,
    showMetricValues,
    _groupDatasetByThreshold,
    showBenchmark,
  ]);

  return chartData;
};
