import React, { useCallback, useState } from 'react';
import { AppFullPage } from './AppFullPage';
import { Button } from 'reactstrap';
import Styles from './AppErrorPage.module.scss';
import classNames from 'classnames';

type Props = {
  error: Error;
  resetErrorBoundary?: () => void;
};

export const AppErrorPage: React.FC<Props> = ({ error, resetErrorBoundary }: Props) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = useCallback(() => {
    setShowDetails((d) => !d);
  }, []);

  return (
    <AppFullPage>
      <div className={Styles.AppErrorPage}>
        <div role='alert' className={Styles.Alert}>
          <p>Ein schwerwiegender Fehler ist aufgetreten:</p>
          <pre className={Styles.ErrorDetails}>
            {!showDetails && <>{error.message}</>}
            {showDetails && <>{error.stack}</>}
          </pre>
          <div
            className={classNames(Styles.ButtonGroup, {
              [Styles.spacing]: !showDetails,
            })}>
            <Button className={Styles.Btn} color={'secondary'} onClick={toggleDetails}>
              Details {!showDetails ? 'anzeigen' : 'ausblenden'}
            </Button>{' '}
            <Button color={'primary'} onClick={resetErrorBoundary}>
              Zurück zur Startseite
            </Button>
          </div>
        </div>
      </div>
    </AppFullPage>
  );
};
