import CacheDataConnection from '../../cache/CacheDataConnection';
import moment from 'moment-timezone';

export default class CacheEntity<EK, V> {
  private _requestPending: boolean;
  private _value: V | null | undefined;
  private _lastUpdatedAt: moment.Moment | null | undefined; // should be null if no request was made
  private readonly _cacheDataConnections: Set<CacheDataConnection<EK, V>>;
  private _cachedParts: Set<string> | null | undefined;
  private _requestedParts: Set<string> | null | undefined;

  constructor(multiPart = false) {
    this._requestPending = false;
    this._value = null;
    this._lastUpdatedAt = null;
    this._cacheDataConnections = new Set();
    if (multiPart) {
      this._cachedParts = new Set();
      this._requestedParts = new Set();
    }
  }

  set requestPending(requestPending: boolean) {
    this._requestPending = requestPending;
  }

  get requestPending() {
    return this._requestPending;
  }

  get cacheDataConnections() {
    return this._cacheDataConnections;
  }

  get lastUpdatedAt() {
    return this._lastUpdatedAt;
  }

  get requestedParts() {
    return this._requestedParts;
  }

  get cachedParts() {
    return this._cachedParts;
  }

  get value() {
    return this._value;
  }

  setValue(value: V, parts: Set<string> | null | undefined = null) {
    this._value = value;
    this._lastUpdatedAt = moment();
    this._requestPending = false;
    if (this._requestedParts) {
      this._requestedParts = new Set();
    }
    if (parts) {
      this._cachedParts = new Set([...(this._cachedParts || []), ...parts]);
    }
  }

  setRequestPending(value: boolean) {
    this._requestPending = value;
  }

  registerCacheDataConnection(cacheDataConnection: CacheDataConnection<EK, V>) {
    this._cacheDataConnections.add(cacheDataConnection);
  }

  unregisterCacheDataConnection(cacheDataConnection: CacheDataConnection<EK, V>) {
    this._cacheDataConnections.delete(cacheDataConnection);
  }
}
