import Model, { DefaultType, MemberType } from '../../../utils/Model';
import { PlainObjectOf } from '../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export default class TourPlanStop extends Model<TourPlanStop> {
  index?: number;
  lon?: number;
  lat?: number;
  collectionOrders?: string[];
  shipments: string[];

  constructor(source: PlainObjectOf<TourPlanStop>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<TourPlanStop> {
    return undefined;
  }

  getMembers(): MemberType<TourPlanStop> {
    return undefined;
  }
}
