import React from 'react';
import style from './address.module.scss';

type AddressProps = {
  salutation?: string;
  firstName?: string;
  lastName?: string;
  paketShopId?: string | null;
  street: string;
  streetNr: string;
  addressSupplement?: string | null;
  zipcode: string;
  city: string;
  rsteze?: string;
  entitled?: string;
};

export const Address: React.FC<AddressProps> = ({
  salutation,
  firstName,
  lastName,
  paketShopId,
  street,
  streetNr,
  addressSupplement,
  zipcode,
  city,
  rsteze,
  entitled,
}) => {
  // 'salutation' field can sometimes contain a number
  const isSalutationValid = salutation && isNaN(parseInt(salutation));
  return (
    <address>
      {lastName && (
        <span className={style.name}>
          {isSalutationValid && salutation.toLowerCase()} {firstName?.toLowerCase()} {lastName.toLowerCase()}
        </span>
      )}
      {paketShopId && <span>Paketshop {paketShopId}</span>}
      {street && (
        <span>
          {street.toLowerCase()} {streetNr}
        </span>
      )}
      {addressSupplement && <span>{addressSupplement.toLowerCase()}</span>}
      {(zipcode || city) && (
        <span>
          {zipcode ? zipcode : ''}
          {city && ` ${city?.toLowerCase()}`}
        </span>
      )}
      {rsteze && <span className={style.rsTeZe}>{rsteze}</span>}
      {entitled && <span className={style.entitled}>{entitled}</span>}
    </address>
  );
};
