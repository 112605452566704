import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { MdChevronRight, MdClose, MdSearch } from 'react-icons/md';
import { getKpiTree, KpiNode, ValueExpressionNode } from '../utils/KpiTree';
import { get, uniqWith } from 'lodash';
import Styles from './KpiSelectorOverlay.module.scss';
import classNames from 'classnames';
import { NodeData, ScrollBehaviour, SelectionBehaviour, TreeMenu, TreeMenuNode } from '../../navigation/TreeMenu';
import GenericTree from '../../metrics2/models/websocket/org/GenericTree';
import { SearchResultList } from '../../navigation/components/SearchResultList';
import { ValueExpressionHelpMode, ValueExpressionName } from '../../common/components/ValueExpressionName';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { useAuthContext } from '@contexts/auth-context';
import { useSelector } from 'react-redux';
import { selectDashboardOrgKey } from '@redux/dashboard.selectors';

type Props = {
  onSelect: (identifier: string, shouldClose: boolean) => void;
  selected: string[];
  className?: string;
  availableKpiSelectorKeys?: string[];
  categoriesSelectable?: boolean;
  tree?: TreeMenuNode<NodeData>[];
};

const KpiSelectorOverlay: React.FC<Props> = ({
  onSelect,
  selected,
  className,
  availableKpiSelectorKeys,
  categoriesSelectable = false,
  tree: treeFromProps,
}: Props) => {
  const authService = useAuthContext();
  const valueExpressionMap = useValueExpressionContext();
  const inputRef = React.createRef<HTMLInputElement>();
  const orgKey = useSelector(selectDashboardOrgKey);

  const tree = useMemo(() => {
    if (!treeFromProps) {
      return getKpiTree(authService, valueExpressionMap, orgKey);
    }
    return treeFromProps;
  }, [authService, treeFromProps, valueExpressionMap, orgKey]);

  const [inputText, setInputText] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const filteredTree = useMemo(() => {
    if (!availableKpiSelectorKeys) {
      return tree;
    }
    // TODO: build generic solution
    const kpiTree = tree
      .map((n) => {
        return [
          ...n.children.filter(
            (c) =>
              (c instanceof ValueExpressionNode && availableKpiSelectorKeys.includes(c.valueExpression.identifier)) ||
              (!(c instanceof ValueExpressionNode) &&
                c.children.some(
                  (c) =>
                    c instanceof ValueExpressionNode && availableKpiSelectorKeys.includes(c.valueExpression.identifier)
                ))
          ),
        ];
      })
      .flat(2);

    // get unique values with the identifier of valueExpression
    // categories don't have an identifier, therefore we thread
    // them all as unique
    const uniqTree = uniqWith(kpiTree, (a, b) => {
      if (get(a, 'identifier') || get(b, 'identifier')) {
        return get(a, 'identifier') === get(b, 'identifier');
      }
      return false;
    });

    return uniqTree;
  }, [availableKpiSelectorKeys, tree]);

  const [treeObject, flatTree] = useMemo(() => {
    const treeObj = new GenericTree<NodeData, TreeMenuNode<NodeData>>({
      data: null,
      children: filteredTree,
    });
    return [treeObj, treeObj.flatten()];
  }, [filteredTree]);

  const isNodeSelectable = useCallback(
    (node: KpiNode | ValueExpressionNode) => {
      if (node instanceof KpiNode && !categoriesSelectable) return false;
      if (node instanceof KpiNode && node.valueExpressionKey == null) {
        return false;
      }
      // remove selected nodes from search results
      if (node instanceof ValueExpressionNode && selected.indexOf(node?.valueExpression?.identifier) !== -1) {
        return false;
      }
      return true;
    },
    [categoriesSelectable, selected]
  );

  const search = useCallback(
    (text: string) => {
      const searchFilterValue = text.toLowerCase().trim();
      let searchResults = [];

      if (!searchFilterValue.length) {
        setSearchResults([]);
        return;
      }
      searchResults = flatTree.filter((node) => {
        const nodeText = node.data?.toString()?.toLocaleLowerCase();
        if (nodeText == null) return false;
        // If kpi node doesn't have a valueExpressionKey, it cannot be selected therefore we don't want the search
        // to find the selected entry
        if (!isNodeSelectable(node as KpiNode | ValueExpressionNode)) {
          return false;
        }
        return node.matchesSearchInput?.(searchFilterValue);
      });
      setSearchResults(searchResults);
    },
    [flatTree, isNodeSelectable]
  );

  const resetSearch = useCallback(() => {
    setInputText('');
    setSearchResults([]);
  }, []);

  const onSearchValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInputText(e.target.value);
      search(e.target.value);
    },
    [search]
  );

  useEffect(() => {
    inputRef?.current?.focus();
  });

  const isSearching = inputText.length > 0;
  const hasSearchResults = searchResults.length > 0;

  const selectItem = (identifier: string, shouldClose: boolean) => {
    resetSearch();
    onSelect(identifier, shouldClose);
  };

  const selectedNodes = selected.map((s) =>
    treeObject.findOrgUnitValueByFunction((node) => {
      if (!(node instanceof ValueExpressionNode)) return false;
      return node.valueExpression.identifier === s;
    })
  );

  const getSearchResultRow = (node) => {
    const renderedNode = <div className={Styles.SearchResultRow}>{renderValueExpression(node)}</div>;

    if (node?.parent?.data) {
      return (
        <div className={Styles.SearchResultRow}>
          <em className={Styles.Parent}>
            {node?.parent?.data?.toString()} <MdChevronRight size={14} />{' '}
          </em>
          {renderedNode}
        </div>
      );
    }
    return <div className={Styles.SearchResultRow}>{renderedNode}</div>;
  };

  const renderValueExpression = (node: TreeMenuNode<NodeData>) => {
    const isSBTour = node.data.getIdentifier() === 'complaints.happened.count_with_tours';

    const label = (() => {
      if (node instanceof KpiNode) {
        return <>{node.label}</>;
      }

      if (!(node instanceof ValueExpressionNode)) {
        return 'Unbekannt';
      }

      if (isSBTour) {
        return 'SB (Tour)';
      } else {
        return (
          <ValueExpressionName
            showHelpTooltip
            valueExpression={node.valueExpression}
            mode={ValueExpressionHelpMode.ShowHelpOnIconHover}
            helpDetails={'short'}
          />
        );
      }
    })();

    return <>{label}</>;
  };

  return (
    <div className={classNames(Styles.KpiSelectorOverlay, className)}>
      <div className={Styles.SearchWrapper}>
        <div className={Styles.SearchInner}>
          <div className={Styles.SearchIcon}>
            <MdSearch />
          </div>
          <input
            className={Styles.SearchInput}
            tabIndex={1}
            ref={inputRef}
            autoFocus
            type='text'
            placeholder='Durchsuchen'
            value={inputText}
            onChange={onSearchValueChange}
          />
          {isSearching && (
            <div className={Styles.RemoveButton} onClick={() => resetSearch()}>
              <MdClose />
            </div>
          )}
        </div>
      </div>
      {!isSearching && (
        <TreeMenu
          scrollBehaviour={ScrollBehaviour.DO_NOT_SCROLL}
          tree={filteredTree}
          customLeaf={(item, depth, selected, hovering) => (
            <div
              className={classNames(Styles.Item, {
                [Styles.Hover]: hovering,
              })}>
              {renderValueExpression(item)}
            </div>
          )}
          customExpandIcon={(item, depth, selected, hovering) => (
            <div
              className={classNames(Styles.Item, {
                [Styles.Hover]: hovering && !isNodeSelectable(item as KpiNode | ValueExpressionNode),
              })}>
              <MdChevronRight size={20} />
            </div>
          )}
          selectionBehaviour={SelectionBehaviour.DISABLE_RESELECT_SAME_VALUE}
          isSelectableCallback={(n) => {
            if (n instanceof ValueExpressionNode) return true;
            if (!categoriesSelectable) return false;
            return n instanceof KpiNode && n.valueExpressionKey != null;
          }}
          onNodeSelection={(nodes) => {
            if (!Array.isArray(nodes) || !nodes.length) return;
            const [firstNode] = nodes;

            if (firstNode instanceof ValueExpressionNode) {
              selectItem(firstNode.valueExpression.identifier, true);
            } else if (firstNode instanceof KpiNode && firstNode.valueExpressionKey != null) {
              selectItem(firstNode.valueExpressionKey, true);
            }
          }}
          selectedNodes={selectedNodes}
        />
      )}

      {isSearching && !hasSearchResults && <div className={Styles.NoResults}>Die Suche ergab kein Ergebnis.</div>}
      <SearchResultList
        searchValue={inputText}
        results={[
          {
            group: null,
            results: searchResults,
          },
        ]}
        onResultSelect={(node) => {
          if (node instanceof KpiNode && node.valueExpressionKey != null) {
            selectItem(node.valueExpressionKey, true);
          } else if (node instanceof ValueExpressionNode) {
            selectItem(node.valueExpression.identifier, true);
          }
        }}
        customElement={(node) => getSearchResultRow(node)}
      />
    </div>
  );
};

KpiSelectorOverlay.displayName = 'KpiSelectorOverlay';
export default KpiSelectorOverlay;
