import { useCallback, useEffect } from 'react';

type Key = {
  code: string;
  ctrlKey?: boolean;
};

export const useKeyboardShortcut = (key: Key, fn: (e: KeyboardEvent) => void): void => {
  const checkKeyCallback = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === key.code && (key.ctrlKey === undefined || key.ctrlKey === event.ctrlKey)) {
        fn(event);
      }
    },
    [fn, key.code, key.ctrlKey]
  );

  useEffect(() => {
    document.addEventListener('keydown', checkKeyCallback);

    return () => {
      document.removeEventListener('keydown', checkKeyCallback);
    };
  }, [key, fn, checkKeyCallback]);
};
