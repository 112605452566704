import MetricCategory from '../models/entities/MetricCategory';

export const AvailableMetricCategories: Array<MetricCategory> = [
  new MetricCategory({
    key: 'returns',
    label: 'Abholvorgänge',
    description: 'Abholvorgänge',
  }),
  new MetricCategory({
    key: 'return-items',
    label: 'Abgeholte Sendungen',
    description: 'Alle abgeholte Sendungen',
  }),
  new MetricCategory({
    key: 'delivery-items',
    label: 'Sendungen',
    description: 'Alle auf Tour geladenen Sendungen gezählt nach dem ersten abgeschlossenen Zustellvorgang',
  }),
  new MetricCategory({
    key: 'customers',
    label: 'Empfänger',
    description:
      'Alle auf der geladenen Tour geplante Empfänger gezählt nach dem ersten abgeschlossenen Zustellvorgang',
  }),
  new MetricCategory({
    key: 'deliveries',
    label: 'Zustellvorgänge',
    description:
      'Alle abgeschlossenen Zustellvorgänge, bei der ein Zustellvorgang die Bearbeitung eines oder mehrerer Pakete ' +
      'für ein oder mehrere Empfänger ist',
  }),
  new MetricCategory({
    key: 'tours',
    label: 'Touren',
    description: 'Alle geladenen Touren gezählt nach dem ersten abgeschlossenen Zustellvorgang',
  }),
  new MetricCategory({
    key: 'complaints',
    label: 'Servicebeanstandungen',
    description: 'Alle gemeldeten Servicebeanstandungen',
  }),
  new MetricCategory({
    key: 'sat',
    label: 'ZSB-Prozesse',
    description: 'An ZSBs auftretende Prozesse',
  }),
  new MetricCategory({
    key: 'costs',
    label: 'Kosten',
    description: 'Informationen zu Kosten in der letzten Meile',
  }),
  new MetricCategory({
    key: 'static',
    label: 'Andere',
    description: 'Werte ohne direkten Zustellbezug',
  }),
  new MetricCategory({
    key: 'sendungsbasiert',
    label: 'Sendungsbasiertes Zaehlen',
    description: 'Sendungsbasiertes Zaehlen',
  }),
  new MetricCategory({
    key: 'stock',
    label: 'Laufzeiten und Bestände',
    description: 'Informationen zu Laufzeiten und Beständen',
  }),
];
