import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import changelog, { ChangeType } from '../../../configuration/changelog';
import '../assets/changelogModal.scss';
import { gt } from 'semver';

type Props = {
  visible: boolean;
  onClose: () => void;
};

const versionKeys = Object.keys(changelog).sort((a, b) => (gt(a, b) ? -1 : 1));

type VersionProps = {
  version: string;
};

type changeCategory = keyof ChangeType;

const Version: React.FC<VersionProps> = ({ version }) => {
  const versionData = changelog[version];

  const renderChangeType = (categoryName: string, changeCategory: changeCategory) => {
    // hide category if no changes in there
    if (!Array.isArray(versionData.changes?.[changeCategory])) return null;
    return (
      <div>
        <h5 className='changelog-features'>{categoryName}</h5>
        <ul className='changelog-list'>
          {(versionData.changes?.[changeCategory] || []).map((f, idx) => (
            <li key={idx} dangerouslySetInnerHTML={{ __html: f }} />
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className='version' key={version}>
      <div className='title-date-wrapper'>
        <span className='release-date'>{versionData.releaseDate}</span>
        <h4 className='version-title'>Version {version}</h4>
      </div>
      <br />
      {renderChangeType('Neue Funktionen', 'features')}
      {renderChangeType('Verbesserungen', 'improvements')}
      {renderChangeType('Behobene Fehler', 'bugs')}
      {renderChangeType('Infos', 'info')}
    </div>
  );
};

const ChangelogModal: React.FC<Props> = ({ visible, onClose }) => {
  const title = 'Update Informationen';

  return (
    <div>
      <Modal isOpen={visible} autoFocus={false} style={{ zIndex: 9999 }} toggle={onClose} className='changelog-modal'>
        <ModalHeader style={{ paddingLeft: '14px' }} toggle={onClose}>
          {title}
        </ModalHeader>
        <ModalBody>
          {versionKeys.map((k) => (
            <Version key={k} version={k} />
          ))}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangelogModal;
