import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import Styles from './Link.module.scss';

type Props = HTMLAttributes<HTMLButtonElement> & {
  onClick: () => void;
};

const Link: React.FC<Props> = ({ onClick, children, className, ...rest }) => {
  return (
    <button {...rest} className={classNames(Styles.Link, className)} type={'button'} onClick={onClick}>
      {children}
    </button>
  );
};

export { Link };
