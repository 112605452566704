import React, { useCallback, useEffect, useState } from 'react';
import Styles from './DefaultGoal.module.scss';
import { FieldErrors, SetValueConfig } from 'react-hook-form';
import ValueExpression from '../../../valueexpressions/models/valueexpressions/ValueExpression';
import ThresholdValueSelector, { ThresholdValueColorVariant } from '../ThresholdValueSelector';
import { MdReport } from 'react-icons/md';
import { Tooltip } from '../../../common/components/Tooltip';
import KpiValue from '../KpiValue';
import classNames from 'classnames';
import { useGoalInput } from './CustomGoal';
import { PositiveDirection } from '../../../metrics2/models/entities/PositiveDirection';
import { defaultLimitsMap } from '@utils/limits-utils';

type Props = {
  valueExpression: ValueExpression;
  getValues: (id?: string) => string;
  watch: <T>(id: string) => T;
  setValue<T>(name: string, value: T, config?: SetValueConfig): void;
  errors: FieldErrors<any>;
  trigger: (field: string | string[]) => void;
  reset: (val: string | string[]) => void;
};

export const DefaultGoal = ({ getValues, watch, setValue, valueExpression, errors, trigger, reset }: Props) => {
  const defaultLimits = defaultLimitsMap.get(valueExpression.identifier) || null;
  const hasDefaultLimit = defaultLimits !== null;

  const onPositiveValueFocusOut = useCallback(() => {
    const positiveVal = getValues('positiveVal');
    const negativeVal = getValues('negativeVal');

    if (negativeVal == null) {
      setValue('negativeVal', positiveVal, {
        shouldValidate: true,
      });
      trigger('negativeVal');
    }
  }, [getValues, setValue, trigger]);

  const [posSelectorRef, setPosSelectorRef] = useState(null);
  const [negSelectorRef, setNegSelectorRef] = useState(null);

  const posError = (errors?.positiveVal?.message as string)?.replace('positiveVal', 'Wert');
  const negError = (errors?.positiveVal?.message as string)?.replace('negativeVal', 'Wert');

  useEffect(() => {
    setValue('direction', valueExpression.positiveDirection);
  }, [setValue, valueExpression.positiveDirection]);

  const greaterSmallerWording =
    valueExpression.positiveDirection === 'up'
      ? {
          positive: 'größer',
          negative: 'kleiner',
        }
      : {
          positive: 'kleiner',
          negative: 'größer',
        };

  const positiveVal = watch('positiveVal') as number;

  const { setPositiveGoal, setNegativeGoal } = useGoalInput(
    reset,
    getValues,
    setValue,
    trigger,
    valueExpression.positiveDirection as PositiveDirection
  );

  const positiveSelector = (
    <div className={classNames(Styles.Row)}>
      <span className={Styles.Name}>
        <strong>grün</strong> wenn {greaterSmallerWording.positive} als
      </span>
      {hasDefaultLimit && (
        <>
          <div
            className={classNames(Styles.WarnIcon, {
              [Styles.HasError]: !!posError,
            })}
            ref={setPosSelectorRef}>
            <MdReport />
          </div>
          <Tooltip anchorElement={posSelectorRef} placement={'bottom'}>
            Zielwert Hermes:{' '}
            <strong>
              <KpiValue valueExpression={valueExpression} veData={defaultLimits.positive} />
            </strong>
            .<br />
            Dieser Wert kann <strong>nicht überschrieben</strong> werden.
          </Tooltip>
        </>
      )}
      <ThresholdValueSelector
        valueExpression={valueExpression}
        colorVariant={ThresholdValueColorVariant.Positive}
        onValueChange={setPositiveGoal}
        value={positiveVal}
        onBlur={onPositiveValueFocusOut}
        disabled={hasDefaultLimit}
      />
      {posError && (
        <div className={Styles.ErrorRow}>
          <div className={Styles.InvalidInput}>{posError}</div>
        </div>
      )}
    </div>
  );

  const negativeSelector = (
    <div className={Styles.Row}>
      <span className={Styles.Name}>
        <strong>rot</strong> wenn {greaterSmallerWording.negative} als
      </span>
      {hasDefaultLimit && (
        <>
          <div
            className={classNames(Styles.WarnIcon, {
              [Styles.HasError]: !!negError,
            })}
            ref={setNegSelectorRef}>
            <MdReport />
          </div>
          <Tooltip anchorElement={negSelectorRef} placement={'bottom'}>
            Zielwert Hermes:{' '}
            <strong>
              <KpiValue valueExpression={valueExpression} veData={defaultLimits.negative} />
            </strong>
            .<br /> Dieser Wert kann <strong>nicht überschrieben</strong> werden.
          </Tooltip>
        </>
      )}
      <ThresholdValueSelector
        valueExpression={valueExpression}
        colorVariant={ThresholdValueColorVariant.Negative}
        onValueChange={setNegativeGoal}
        value={watch('negativeVal')}
        disabled={hasDefaultLimit}
      />
      {negError && (
        <div className={Styles.ErrorRow}>
          <div className={Styles.InvalidInput}>{negError}</div>
        </div>
      )}
    </div>
  );

  return (
    <div className={Styles.ThresholdWrapper}>
      {positiveSelector}
      {negativeSelector}
    </div>
  );
};
