import React, { useCallback, useRef, useState } from 'react';
import { Tooltip } from '../../common/components/Tooltip';
import classNames from 'classnames';
import Styles from './FilterButton.module.scss';

type Props = {
  active: boolean;
  count: number;
  disabled: boolean;
  tooltipText?: React.ReactNode;
  onSelect: () => void;
  iconSrc?: string;
  customIcon?: React.ReactNode;
};

export const FilterButton = (props: Props) => {
  const { active, disabled, tooltipText = null, onSelect, iconSrc, count, customIcon = null } = props;

  const [open, setOpen] = useState(false);
  const showTooltip = useCallback(() => setOpen(true), []);
  const hideTooltip = useCallback(() => setOpen(false), []);

  const iconRef = useRef();

  return (
    <>
      <button
        onClick={onSelect}
        className={classNames(Styles.FilterButton, {
          [Styles.active]: active,
          [Styles.disabled]: disabled,
        })}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        ref={iconRef}>
        {!customIcon ? (
          <img
            style={{
              margin: '0 auto',
              height: '20px',
              width: '20px',
              pointerEvents: 'none',
            }}
            className='icon-direct-import'
            src={iconSrc}
            alt={''}
          />
        ) : (
          customIcon
        )}
        <div className='counter'>{count}</div>
      </button>
      {tooltipText && (
        <Tooltip className='tooltip-badge' placement='bottom-end' visible={open} anchorElement={iconRef?.current}>
          {tooltipText}
        </Tooltip>
      )}
    </>
  );
};
