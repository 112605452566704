import moment from 'moment-timezone';
import DataConnection from '../../dataconnection/connections/DataConnection';
import { QueryInterface } from '../models/queries/QueryInterface';

export default class CacheDataConnection<EK, V> extends DataConnection<EK, V> {
  query: QueryInterface;
  _data: Map<EK, V> | null | undefined;
  _isComplete: boolean;
  _isLoading: boolean;
  _lastUpdateAt: moment.Moment;

  constructor(query: QueryInterface) {
    super();
    this.query = query;
    this._isComplete = false;
    this._isLoading = false;
    this._lastUpdateAt = moment();
    this._data = null;
  }

  get isComplete(): boolean {
    return this._isComplete;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get lastUpdatedAt(): moment.Moment {
    return this._lastUpdateAt;
  }

  get data(): Map<EK, V> | null | undefined {
    return this._data;
  }

  setLoading(isLoading: boolean) {
    this._isLoading = isLoading;
    if (isLoading) {
      this._triggerLoading();
    }
  }

  updateData(data: Map<EK, V>, isComplete: boolean, isLoading: boolean) {
    this._lastUpdateAt = moment();
    this._data = data;
    this._isComplete = isComplete;
    this.setLoading(isLoading);
    this._triggerDataChanged();
  }
}
