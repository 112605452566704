import { notificationTypeLabels, notificationTypes } from '@legacy-modules/metrics2/models/entities/MetricType';
import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import benachrichtigungenArt from './benachrichtigungen-art';

export default notificationTypes.flatMap((key) => {
  const expression = new MapDetailValueExpression(
    benachrichtigungenArt.metricType,
    key,
    notificationTypeLabels[key] ?? `unbekannt (${key})`
  );

  expression.setIdentifier(`finished_deliveries.notifications.${key}`);
  return [expression, ...(expression.getChildren() || [])];
});
