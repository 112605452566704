import Styles from './FloatingComponent.module.scss';
import React from 'react';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const FloatingComponent: React.FC<Props> = ({ children, style, className }) => {
  return (
    <div style={style} className={classNames(Styles.FloatingComponent, className)}>
      <div className={Styles.Inner}>{children}</div>
    </div>
  );
};
