import BasePayload from '../BasePayload';
import { DefaultType, MemberType } from '../../../../../utils/Model';
import { PlainObjectOf } from '../../../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export default class StateFetchRequestPayload extends BasePayload<StateFetchRequestPayload> {
  uuid: string;

  constructor(source: PlainObjectOf<StateFetchRequestPayload>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<StateFetchRequestPayload> {
    return undefined;
  }

  getMembers(): MemberType<StateFetchRequestPayload> {
    return undefined;
  }
}
