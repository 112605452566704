import React, { forwardRef } from 'react';
import Styles from './ToggleButton.module.scss';
import classNames from 'classnames';

export type ToggleButtonProps = {
  value: string;
  onChange?: (event: React.FormEvent<HTMLButtonElement>, value: string) => void;
  selected?: boolean;
  variant?: 'normal' | 'large';
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
};

export const ToggleButton = forwardRef((props: ToggleButtonProps, ref: React.RefObject<HTMLButtonElement>) => {
  const { value, onChange, children, selected, variant = 'normal', disabled = false } = props;

  return (
    <button
      ref={ref}
      className={classNames(
        Styles.ToggleButton,
        {
          [Styles.selected]: selected,
          disabled,
        },
        Styles[variant]
      )}
      type={'button'}
      color={selected ? 'primary' : 'white'}
      disabled={disabled}
      onClick={(e) => onChange(e, value)}>
      {children}
    </button>
  );
});
