import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';
import { MetricTypeKey } from './metric-type-keys';

const metric = new MetricType({
  key: MetricTypeKey.TAAsum,
  aggregation: AggregationType.sum,
  hidden: true,
  positiveDirection: PositiveDirection.custom,
  label: 'Abholaufträge inkl. nicht gestartet',
  definition: getDefinitionByName('Abholaufträge'),
});

export default new SingleValueExpression(metric);
