import { singleton } from '@utils/singleton';

export default class LocalStorageService {
  static STORAGE_PREFIX: string = 'last-mile-analytics.';
  storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  _receive(key: string): string | null | undefined {
    return this.storage.getItem(LocalStorageService.STORAGE_PREFIX + key);
  }

  set(key: string, data: Object): void {
    this.setRaw(key, JSON.stringify(data));
  }

  setRaw(key: string, data: string): void {
    this.storage.setItem(LocalStorageService.STORAGE_PREFIX + key, data);
  }

  remove(key: string): void {
    this.storage.removeItem(LocalStorageService.STORAGE_PREFIX + key);
  }

  get<T>(key: string, defaultValue?: T): T | null {
    const data: string | null | undefined = this._receive(key);
    if (data) {
      return JSON.parse(data);
    }
    if (defaultValue) {
      return defaultValue;
    }
    return null;
  }

  getRaw(key: string, defaultValue: string | null | undefined = null): string | undefined {
    let value = this._receive(key);
    if (value === null) {
      value = defaultValue;
    }
    return value;
  }
}

export const localStorageService = singleton('localStorageService', new LocalStorageService());
