import {
  byDeliveryDetailTypeMap,
  deliveryTypesShipmentBased,
} from '@legacy-modules/metrics2/models/entities/MetricType';
import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import zustellungenArt from './zustellungen-art';

const expressions = deliveryTypesShipmentBased.flatMap((key) => {
  const expression = new MapDetailValueExpression(
    zustellungenArt.metricType,
    key,
    byDeliveryDetailTypeMap[key] ?? `unbekannt (${key})`
  );

  expression.setIdentifier(`zustellungen_arten.${key}`); // TODO: fix typo ruecklaufer
  return [expression, ...(expression.getChildren() || [])];
});
export default expressions;
