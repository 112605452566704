import React, { createContext, PropsWithChildren, ReactElement, useContext, useMemo } from 'react';
import { TooltipProps } from '../tooltip';

export type SharedTooltipSettingsContextType = Pick<
  TooltipProps,
  'layerOptions' | 'layerStyle' | 'hoverOptions' | 'arrowOptions'
>;

const defaultValue: SharedTooltipSettingsContextType = {};
export const SharedTooltipSettingsContext = createContext(defaultValue);

export const useSharedTooltipSettingsContext = (): SharedTooltipSettingsContextType => {
  return useContext<SharedTooltipSettingsContextType>(SharedTooltipSettingsContext);
};

type SharedTooltipSettingsContextProviderProps = Required<PropsWithChildren<{}>> & SharedTooltipSettingsContextType;

export default function SharedTooltipSettingsContextProvider({
  children,
  ...rest
}: SharedTooltipSettingsContextProviderProps): ReactElement {
  const context: SharedTooltipSettingsContextType = useMemo(() => ({ ...rest }), [rest]);

  return <SharedTooltipSettingsContext.Provider value={context}>{children}</SharedTooltipSettingsContext.Provider>;
}
