import * as React from 'react';
import { useMemo } from 'react';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import CustomerTrackingData from '../models/types/CustomerTrackingData';
import buildDeliveryBadge from '../utils/getDeliveryBadge/GetDeliveryBadge';
import Styles from './FinishedDeliveryBadge.module.scss';
import { DeliveryStatus } from '../constants/DeliveryStatus';
import { mapItemStatus } from '../utils/mapItemStatus';
import { Badge } from './DeliveryBadge';

type Props = {
  finishedDelivery: FinishedDelivery;
  customer?: CustomerTrackingData;
};

const FinishedDeliveryBadge: React.FC<Props> = (props) => {
  const { finishedDelivery, customer } = props;
  const deliveryBadge = useMemo(() => buildDeliveryBadge(finishedDelivery, customer), [customer, finishedDelivery]);

  if (finishedDelivery == null) return null;

  if (deliveryBadge.type === 'mixed') {
    return (
      <Badge shortName={''} longName={''} color={''} isMixedBadge>
        {Object.entries(deliveryBadge.grouping).map(([key, count]) => {
          const badge = mapItemStatus(key as DeliveryStatus, finishedDelivery, customer);

          return (
            <div className={Styles.BadgeTooltipWrapper} key={finishedDelivery?.uuid}>
              <Badge shortName={badge?.value} color={badge?.color} /> {count}
            </div>
          );
        })}
      </Badge>
    );
  }

  if (deliveryBadge.type === 'grouped') {
    return (
      <Badge color={deliveryBadge.color} shortName={deliveryBadge.value} longName={deliveryBadge.description}>
        {Object.entries(deliveryBadge.grouping).map(([key, count]) => {
          const badge = mapItemStatus(key as DeliveryStatus, finishedDelivery, customer);

          return (
            <div className={Styles.BadgeTooltipWrapper} key={finishedDelivery?.uuid}>
              <Badge shortName={badge?.value} color={badge?.color} /> {count}
            </div>
          );
        })}
      </Badge>
    );
  }

  return <Badge shortName={deliveryBadge.value} longName={deliveryBadge.description} color={deliveryBadge.color} />;
};

export default FinishedDeliveryBadge;
