import React, { useCallback, useRef, useState } from 'react';
import Styles from './PopOver.module.scss';
import { usePopper } from 'react-popper';
import * as PopperJS from '@popperjs/core';
import classNames from 'classnames';
import { useResizeListener } from '../hooks/useResizeListener';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { useKeyboardShortcut } from '../../navigation/hooks/useKeyboardShortcut';
import Portal from './Portal';

type Props = {
  children?: React.ReactNode;
  closeOnEscape?: boolean;
  closeOnClickOutside?: boolean;
  anchorElement: Element | PopperJS.VirtualElement | null;
  onClose?: (event: KeyboardEvent | MouseEvent) => void;
  placement?: PopperJS.Placement;
  visible?: boolean;
  offset?: [number, number];
  connectorElement?: {
    width: string;
  };
  className?: string;
  prerender?: boolean;
  // If the content resizes, the popover is repositioned
  updatePositionOnContentResize?: boolean;
  usePortal?: boolean;
};

export const PopOver: React.FC<Props> = ({
  closeOnEscape = true,
  closeOnClickOutside = true,
  children,
  anchorElement,
  onClose = () => undefined,
  placement = 'auto',
  visible = true,
  offset = [0, 10],
  connectorElement = null,
  className,
  prerender,
  updatePositionOnContentResize = true,
  usePortal = true,
}: Props) => {
  /**
   * The popper element is the element that is positioned by the popper.js library.
   * Please note that useState is used here instead of useRef, because the popper element
   * is not available on the first render. Therefore, useRef would not work here.
   */
  const [popOverRef, setPopOverRef] = useState<HTMLDivElement>(null);
  /**
   * The container element is the element that is used to detect clicks outside the popover.
   */
  const containerRef = useRef<HTMLDivElement>(null);

  const { styles, attributes, forceUpdate } = usePopper(anchorElement, popOverRef, {
    placement,
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset,
        },
      },
    ],
  });

  const onEscapeClose = useCallback(
    (event) => {
      if (!closeOnEscape) {
        return;
      }
      if (!visible) {
        return;
      }
      onClose(event);
    },
    [closeOnEscape, onClose, visible]
  );

  const onOutsideClick = useCallback(
    (event) => {
      if (!visible) {
        return;
      }
      if (!closeOnClickOutside) {
        return;
      }
      onClose(event);
    },
    [closeOnClickOutside, onClose, visible]
  );

  const onResizeCallback = useCallback(() => {
    if (!updatePositionOnContentResize) return;
    forceUpdate && forceUpdate();
  }, [forceUpdate, updatePositionOnContentResize]);

  useOnClickOutside(containerRef, onOutsideClick);
  useKeyboardShortcut({ code: 'Escape' }, onEscapeClose);
  useResizeListener(containerRef, onResizeCallback);

  if (!visible && !prerender) {
    return null;
  }

  const content = (
    <div
      id='popOverContentContainer'
      ref={setPopOverRef}
      style={{
        ...styles.popper,
        zIndex: 1000,
        display: visible ? 'block' : 'none',
      }}
      {...attributes.popper}
      className={classNames(Styles.Popper, attributes?.popper?.className)}>
      <div
        className={classNames(
          Styles.PopOver,
          {
            hasConnectorElement: !!connectorElement,
          },
          className
        )}
        ref={containerRef}>
        {connectorElement && (
          <span
            className={Styles.ConnectorElement}
            style={{
              width: connectorElement.width,
            }}
          />
        )}
        {children}
      </div>
    </div>
  );

  if (!usePortal) {
    return <>{content}</>;
  }

  return <Portal>{content}</Portal>;
};
