import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import zustellungen from './zustellungen';
import touren from './touren';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const expression = new QuotientValueExpression(
  zustellungen,
  touren,
  'Ø Zustellungen',
  null,
  null,
  null,
  getDefinitionByName('Zustellungen')
);

expression.setIdentifier('zustellungen/touren');

export default expression;
