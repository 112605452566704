import { WeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';
import BasePayload from '../../../../common/models/websocket/payloads/BasePayload';
import { DefaultType, MemberType } from '../../../../utils/Model';
import { PlainObjectOf } from '../org/OrgUnitTreeRequestPayload';
import { AggregationType } from '../../enumerations/AggregationType';

export default class MetricsOverviewQueryRequestPayload extends BasePayload<MetricsOverviewQueryRequestPayload> {
  types: Array<string>;
  orgKeys: Array<string>;
  dateFilter: {
    range: {
      from: string;
      until: string;
    };
    weekdays: WeekdaysFilter;
  };
  valueKey?: string;
  aggregations?: AggregationType[];
  contractorKey?: string;

  constructor(
    source: Pick<
      MetricsOverviewQueryRequestPayload,
      'types' | 'orgKeys' | 'dateFilter' | 'valueKey' | 'aggregations' | 'contractorKey'
    >,
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<MetricsOverviewQueryRequestPayload> {
    return undefined;
  }

  getMembers(): MemberType<MetricsOverviewQueryRequestPayload> {
    return undefined;
  }
}
