import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.StockInventory,
  label: 'Bestand',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: 'down',
  category: 'stock',
  description: '<p>Description for stock.inventory - tbd</p>\n',
  definition: getDefinitionByName('Bestand'),
});

export default new SingleValueExpression(metric);
