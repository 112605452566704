import React, { ReactElement } from 'react';
import style from './mapbox-popup.module.scss';
import classNames from 'classnames';

import { Popup, PopupProps } from 'react-map-gl';

export type MapboxPopupProps = PopupProps & {
  /**
   * Is true, popup will be visible
   * @default false
   */
  shown?: boolean;
};

/**
 * Mapbox popup wrapper (https://www.mapbox.com/mapbox-gl-js/api/#popup).
 */
export function MapboxPopup({ shown = false, ...props }: MapboxPopupProps): ReactElement {
  return shown ? <Popup {...props} className={classNames(style.mapboxPopup)} /> : null;
}
export default MapboxPopup;
