import moment from 'moment-timezone';

export class ToObjectFieldConfig {
  constructor(
    fields: Array<string>,
    fieldMapper: Object = {},
    includeByDefault: boolean = true,
    postProcessor: Function | null | undefined = null
  ) {
    this.includeByDefault = includeByDefault;
    this.fields = fields;
    this.fieldMapper = fieldMapper;
    this.postProcessor = postProcessor;
  }

  includeByDefault: boolean = true;
  fields: Array<string> = [];
  fieldMapper: Object = {};
  postProcessor: Function | null | undefined = null;
}

export class ToObjectConfiguration {
  constructor(mode: string = 'default', typeConfigs: Object | null | undefined = null) {
    this.mode = mode;
    this.typeConfigs = typeConfigs;
  }
  mode: string = 'default';
  typeConfigs: Object | null | undefined = null;
}

export interface ToObjectInterface {
  toObject(obj: ToObjectConfiguration | null | undefined): Object;
}

function _genericToObject(
  obj: any,
  configuration: ToObjectConfiguration | null | undefined = null,
  useToObject: boolean = false
) {
  if (obj instanceof Array) {
    return obj.map((o) => _genericToObject(o, configuration, true));
  } else if (obj instanceof Object) {
    if (moment.isMoment(obj)) {
      return obj.toISOString();
    } else if (useToObject && obj.toObject instanceof Function) {
      return obj.toObject(configuration);
    } else {
      const value = {};
      Object.entries(obj).forEach(([key, val]) => {
        value[key] = _genericToObject(val, configuration, true);
      });
      return value;
    }
  }
  return obj;
}

export default function toObject(
  object: Object,
  toObjectConfiguration: ToObjectConfiguration | null | undefined = null,
  fieldConfig: ToObjectFieldConfig | null | undefined = null
): any {
  let result = {};
  if (fieldConfig) {
    if (fieldConfig.includeByDefault) {
      // $FlowFixMe
      Object.entries(object)
        .filter(([key, val]) => !fieldConfig.fields.includes(key))
        .forEach(([key, val]) => {
          // $FlowFixMe
          if (fieldConfig.fieldMapper.hasOwnProperty(key)) {
            // $FlowFixMe
            result[key] = fieldConfig.fieldMapper[key](val);
          } else {
            result[key] = val;
          }
        });
    } else {
      fieldConfig.fields.forEach((key) => {
        const val = object[key];
        // $FlowFixMe
        if (fieldConfig.fieldMapper.hasOwnProperty(key)) {
          // $FlowFixMe
          result[key] = fieldConfig.fieldMapper[key](val);
        } else {
          result[key] = val;
        }
      });
    }
    if (fieldConfig.postProcessor) {
      fieldConfig.postProcessor(result, object, toObjectConfiguration);
    }
  } else {
    result = _genericToObject(object, toObjectConfiguration);
  }
  return result;
}
