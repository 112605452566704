import OrgUnit from '../../metrics2/models/entities/organization/OrgUnit';
import { TreeType } from '../../metrics2/models/enumerations/TreeType';
import OrgUnitNode from '../../metrics2/models/websocket/org/OrgUnitNode';

export type SearchResultGroup = {
  treeType: TreeType;
  score: number;
  results: Array<OrgUnitNode>;
  id: string;
};

export enum OrgSearchScore {
  // if you search for ZSB Alzey and you type in "zsb alzey"
  Exact = 4,
  // if you search for ZSB Alzey and you type in "zsb alz"
  ExactStartWith = 3,
  // if you search for ZSB Alzey and you type in "alzey"
  WordStartWith = 2,
  // if you search for ZSB Alzey and you type in "alz"
  UnitContains = 1,
}

type SearchResultType = {
  exact: Array<ScoredUnitNode>;
  exactStartWith: Array<ScoredUnitNode>;
  wordStartWith: Array<ScoredUnitNode>;
  unitMatches: Array<ScoredUnitNode>;
};

type ScoredUnitNode = {
  unitScore: OrgSearchScore;
  node: OrgUnitNode;
};

export class OrgSearchService {
  public filterOrgUnits(input: Array<OrgUnitNode>, searchValue: string): Array<SearchResultGroup> {
    const searchValueLowered = searchValue.toLowerCase();
    const orgUnitGroups: {
      [key in TreeType]?: Array<OrgUnitNode>;
    } = input.reduce((acc: { [key: string]: Array<OrgUnitNode> }, ou: OrgUnitNode) => {
      // country:de has no treeType assigned to it, we want to have the ability to search for it anyway
      if (!ou.orgUnit.treeType && ou.orgUnit.orgKey !== 'country:de') {
        return acc;
      }
      (acc[ou.orgUnit.treeType] = acc[ou.orgUnit.treeType] || []).push(ou);
      return acc;
    }, {});

    return Object.keys(orgUnitGroups)
      .map((treeType: TreeType, i) => {
        const units = orgUnitGroups[treeType];

        const searchResults: SearchResultType = this.getSearchResults(units, searchValueLowered);

        const results = this.sortAndTakeResults(searchResults, 10);
        // As results are sorted we can just take the first as it contains the highest
        // score number
        const score = results.length ? results[0].unitScore : 0;

        return {
          treeType,
          score,
          results: results.map((r) => r.node),
          id: `${i}`,
        };
      })
      .sort((a, b) => b.score - a.score);
  }

  private getSearchResults(units: OrgUnitNode[], searchValueLowercased: string): SearchResultType {
    return units.reduce<SearchResultType>(
      (prev, curr) => {
        if (this.isExactMatch(curr, searchValueLowercased)) {
          prev.exact.push({
            node: curr,
            unitScore: OrgSearchScore.Exact,
          });
        } else if (this.isStartWithMatch(curr.orgUnit, searchValueLowercased)) {
          prev.exactStartWith.push({
            node: curr,
            unitScore: OrgSearchScore.ExactStartWith,
          });
        } else if (this.isWordStartWithMatch(curr.orgUnit, searchValueLowercased)) {
          prev.wordStartWith.push({
            node: curr,
            unitScore: OrgSearchScore.WordStartWith,
          });
        } else if (this.isUnitMatch(curr.orgUnit, searchValueLowercased)) {
          prev.unitMatches.push({
            node: curr,
            unitScore: OrgSearchScore.UnitContains,
          });
        }
        return prev;
      },
      {
        exact: [],
        exactStartWith: [],
        wordStartWith: [],
        unitMatches: [],
      }
    );
  }

  private sortAndTakeResults(
    { exact, exactStartWith, wordStartWith, unitMatches }: SearchResultType,
    amount: number
  ): ScoredUnitNode[] {
    return [...exact, ...exactStartWith, ...wordStartWith, ...unitMatches]
      .sort((a, b) => b.unitScore - a.unitScore)
      .slice(0, amount);
  }

  isUnitMatch(unit: OrgUnit, searchValueLowercased: string) {
    return unit.name.toLowerCase().includes(searchValueLowercased);
  }

  private isStartWithMatch = (unit: OrgUnit, searchValueLowercased: string) => {
    return unit.name.toLowerCase().startsWith(searchValueLowercased);
  };

  private isExactMatch = (orgUnit: OrgUnitNode, searchValueLowercased: string) => {
    const orgKeyMatches = (ou: OrgUnit, possibleOrgKey: string): boolean => {
      const cleanedKey = possibleOrgKey.replace(/^.*:/, '');

      if (ou.orgKey.replace(/^.*:/, '') === cleanedKey) {
        return true;
      }
      return false;
    };

    return (
      orgUnit.orgUnit.name.toLowerCase() === searchValueLowercased ||
      orgKeyMatches(orgUnit.orgUnit, searchValueLowercased)
    );
  };

  isWordStartWithMatch(unit: OrgUnit, searchValueLowercased: string) {
    const orgNameParts = unit.name.split(' ');
    return orgNameParts.some((part) => part.toLowerCase().startsWith(searchValueLowercased));
  }
}
