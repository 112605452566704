import React from 'react';
import Styles from './TextLoader.module.scss';

type Props = {
  style?: React.CSSProperties;
  text: string;
};

export const TextLoader = ({ style, text }: Props) => (
  <div className={Styles.LoaderContainerSpinner}>
    <div style={style}>{text}</div>
    <div className={Styles.LdsDualRing} />
  </div>
);
