import React, { createContext, PropsWithChildren, ReactElement, useContext, useMemo } from 'react';
import SystemErrorPayload from '../../../../modules/common/models/websocket/payloads/system/SystemErrorPayload';
import WebsocketEvent from '../../../../modules/common/models/websocket/WebsocketEvent';
import WebsocketApiService from '../../../../modules/common/services/WebsocketApiService';
import { KnownWebsocketEvent } from './request.types';

type DefaultWebsocketApiResponse<T = unknown> = {
  id: string;
  name: KnownWebsocketEvent;
  payload: T;
  replyTo: string;
  version: number;
};
export type ApiContextType = {
  wsFetch: <OutputDataType, ServerDataType = OutputDataType>(
    name: KnownWebsocketEvent,
    payload: unknown,
    transformFn?: (data: ServerDataType) => OutputDataType
  ) => Promise<OutputDataType>;
  legacyService?: WebsocketApiService;
};

const defaultValue: ApiContextType = {
  wsFetch: async () => Promise.reject(new Error('Websocket Service not available')),
  legacyService: null,
};
export const ApiContext = createContext(defaultValue);

export const useApiContext = (): ApiContextType => {
  return useContext<ApiContextType>(ApiContext);
};

type ApiContextProviderProps = Required<
  PropsWithChildren<{
    websocketApiService: WebsocketApiService;
  }>
>;

export default function ApiContextProvider({ websocketApiService, children }: ApiContextProviderProps): ReactElement {
  const context: ApiContextType = useMemo(
    () => ({
      wsFetch: async function <T, V = T>(name: KnownWebsocketEvent, payload: unknown, transformFn?: (data: V) => T) {
        const response = await websocketApiService.request<DefaultWebsocketApiResponse<V | SystemErrorPayload>>(
          new WebsocketEvent({ name, payload })
        );
        if (response.payload instanceof SystemErrorPayload) {
          return Promise.reject(response);
        }
        return transformFn ? transformFn(response?.payload) : (response?.payload as unknown as T);
      },
      legacyService: websocketApiService,
    }),
    [websocketApiService]
  );

  return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>;
}
