import BasePayload from '../BasePayload';
import { DefaultType, MemberType } from '../../../../../utils/Model';
import { PlainObjectOf } from '../../../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

type Root = 'overview' | 'dashboard' | 'stoppdetail';

export type RootOrgKeys = { [k in Root]: string };

export type UserDetails = {
  displayName: string;
  email: string;
  username: string;
};

export default class AuthStatusPayload extends BasePayload<AuthStatusPayload> {
  loggedIn: boolean;
  username?: string;
  permissions?: Array<Array<string>>;
  rootOrgKey?: string;
  rootOrgKeys?: RootOrgKeys;
  tokenId?: string;
  userDetails: UserDetails;

  constructor(source: PlainObjectOf<AuthStatusPayload>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<AuthStatusPayload> {
    return undefined;
  }

  getMembers(): MemberType<AuthStatusPayload> {
    return undefined;
  }
}
