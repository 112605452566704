import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';

const metric = new MetricType({
  key: MetricTypeKey.PSEsum,
  aggregation: AggregationType.sum,
  hidden: true,
  positiveDirection: PositiveDirection.custom,
  label: 'PS Mitnahmen',
  definition: getDefinitionByName('PS Mitnahmen'),
});

const expression = new SingleValueExpression(metric);
expression.setIdentifier('ps_mitnahmen');

export default new SingleValueExpression(metric);
