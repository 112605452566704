import { reduce, union } from 'lodash';
import TourDetailsInTime from '../models/entities/TourDetailsInTime';
import { IRawAnomalyResponse } from '../../dashboard/anomalies/interfaces/IRawAnomalyResponse';
import { ServiceBadge } from '../components/ServicesFilterPanel';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import { AnomalyReason } from '../../dashboard/anomalies/utils/AnomalyUtils';
import { filterStopsByBadge } from '../utils/getDeliveryBadge/GetDeliveryBadge';

export const getAnomaliesStopsForDetails = (anomalies: IRawAnomalyResponse[]) =>
  (anomalies || []).flatMap((a) => a.tourStopRefs.flatMap((stopRef) => stopRef.stopNumber));

export class TourFilterService {
  filterByAnomalies(filterByAnomalies: boolean, anomalies: IRawAnomalyResponse[], list: FinishedDelivery[]) {
    if (!filterByAnomalies) return list;

    const anomalyStopNumbers = getAnomaliesStopsForDetails(anomalies);
    return list.filter((item) => anomalyStopNumbers.includes(item.index));
  }

  private filterByBadges(badges: Array<string>, list: FinishedDelivery[]): FinishedDelivery[] {
    return filterStopsByBadge(badges, list);
  }

  filterByServices(activeFilters: ServiceBadge[], list: FinishedDelivery[]): FinishedDelivery[] {
    // if there are no filters, we can return the list unfiltered
    if (!activeFilters.length) return list;

    const filtered = activeFilters.map((service) => service.serviceFn(list));
    const allFiltered = reduce(filtered, (acc, acc1) => {
      return union(acc, acc1);
    });

    const filteredRet = activeFilters.map((serviceBadge) => serviceBadge.serviceFnRet(list));
    const allFilteredRet = reduce(filteredRet, (acc, acc1) => {
      return union(acc, acc1);
    });
    return union(allFiltered, allFilteredRet);
  }

  public filter(
    tourDetailsInTime: TourDetailsInTime,
    anomalies: IRawAnomalyResponse[],
    filters: {
      badges: string[];
      services: ServiceBadge[];
      anomalyFilterAll: boolean;
      resolvedAnomalyNames: string[];
    }
  ) {
    const { services: servicesToFilter, badges: badgesToFilter } = filters;

    if (tourDetailsInTime == null) {
      return [];
    }
    const { filteredFinishedDeliveries } = tourDetailsInTime;

    // If we filter for anomaly names, the other filter will not apply
    if (Array.isArray(filters.resolvedAnomalyNames) && filters.resolvedAnomalyNames.length > 0) {
      const filteredAnomalies = anomalies.filter((a) =>
        filters.resolvedAnomalyNames.includes(AnomalyReason[a.anomalyType])
      );
      const anomalyStopNumbers = getAnomaliesStopsForDetails(filteredAnomalies);
      return filteredFinishedDeliveries.filter((item) => anomalyStopNumbers.includes(item.index));
    }

    const filteredByServices = this.filterByServices(servicesToFilter, filteredFinishedDeliveries);
    const filteredByBadges = this.filterByBadges(badgesToFilter, filteredByServices);
    const filteredByAnomalies = this.filterByAnomalies(filters.anomalyFilterAll, anomalies, filteredByBadges);
    return filteredByAnomalies;
  }
}

export const TourFilterServiceInstance = new TourFilterService();
