import { OrgKey } from '@data-table/data-table.types';
import { TreeType } from '@legacy-modules/metrics2/models/enumerations/TreeType';
import { MapValueMode } from '@legacy-modules/overview/models/enumerations/MapValueMode';
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

export type SelectedTreeTypes = {
  [key: string]: TreeType;
};

export type OverviewContextType = {
  hoverOrgKeyState: [string, Dispatch<SetStateAction<string>>];
  selectedTreeTypesState: [SelectedTreeTypes, Dispatch<SetStateAction<SelectedTreeTypes>>];
  mapValueModeState: [MapValueMode, Dispatch<SetStateAction<MapValueMode>>];
};

const defaultValue: OverviewContextType = {
  hoverOrgKeyState: [null, () => null],
  selectedTreeTypesState: [null, () => null],
  mapValueModeState: [null, () => null],
};
export const OverviewContext = createContext(defaultValue);

export const useOverviewContext = (): OverviewContextType => {
  return useContext<OverviewContextType>(OverviewContext);
};

type OverviewContextProviderProps = Required<PropsWithChildren<{}>>;

export default function OverviewContextProvider({ children }: OverviewContextProviderProps): ReactElement {
  const hoverOrgKeyState = useState<OrgKey>(null);
  const selectedTreeTypesState = useState<SelectedTreeTypes>({ country: TreeType.hermes_org });
  const mapValueModeState = useState<MapValueMode>(MapValueMode.primary);

  const context: OverviewContextType = useMemo(
    () => ({
      hoverOrgKeyState,
      selectedTreeTypesState,
      mapValueModeState,
    }),
    [hoverOrgKeyState, selectedTreeTypesState, mapValueModeState]
  );

  return <OverviewContext.Provider value={context}>{children}</OverviewContext.Provider>;
}
