import * as React from 'react';
import Model, { DefaultType, MemberType } from '../../../utils/Model';
import { MarkerType } from '../enumerations/MarkerType';
import GeoCoordinate from '../../../geo/models/GeoCoordinate';
import Color from 'color';
import moment from 'moment';

export default class MarkerDetails extends Model<MarkerDetails> {
  markerType: MarkerType;
  markerRef: string;
  location: GeoCoordinate;
  time?: moment.Moment;
  content: string | React.ReactElement;
  isClustered?: boolean;
  stopNumber?: number;
  opacity?: number;
  iconSize: Array<number>;
  color: Color;
  tooltipCallback: () => React.ReactNode;

  constructor(
    source: any, // FIXME: set proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<MarkerDetails> {
    return undefined;
  }

  getMembers(): MemberType<MarkerDetails> {
    return {
      location: (src) => (src ? new GeoCoordinate(src) : null),
      color: (src) => (src ? new Color(src) : new Color('white')),
      // Must be here, because otherwise the function would be a new empty one
      tooltipCallback: (src) => src,
      content: (src) => src,
    };
  }
}
