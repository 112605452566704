import { createSelector } from '@reduxjs/toolkit';

import { ComparisonMode } from '@legacy-modules/overview/models/enumerations/ComparisonMode';
import { RootState } from './store';
import MetricsFilter from '@legacy-modules/metrics2/models/filter/MetricsFilter';
import { getHumanReadableDateText } from '@legacy-modules/navigation/utils/DateRangeTextUtils';

export const selectOverview = (state: RootState) => state.overview;
export const selectOverviewOrgKey = createSelector(selectOverview, (overviewState) => overviewState.rootOrgKey);
export const selectOverviewWeekdayFilter = createSelector(
  selectOverview,
  (overviewState) => overviewState.weekdayFilter
);
export const selectOverviewRoutingParams = createSelector(selectOverview, (overviewState) => {
  const { primaryFilter, compareFilter, weekdayFilter } = overviewState;
  return {
    primaryFilter,
    compareFilter,
    weekdayFilter,
  };
});
export const selectPrimaryFilter = createSelector(
  selectOverview,
  (overviewState) => new MetricsFilter(overviewState.primaryFilter)
);
export const selectCompareFilter = createSelector(selectOverview, (overviewState) =>
  overviewState.compareFilter ? new MetricsFilter(overviewState.compareFilter) : null
);
export const selectComparisonMode = createSelector(
  selectPrimaryFilter,
  selectCompareFilter,
  (primaryFilter, compareFilter) => {
    if (!compareFilter) {
      return ComparisonMode.none;
    } else if (primaryFilter.orgKey !== compareFilter.orgKey) {
      return ComparisonMode.organization;
    } else if (
      !primaryFilter.from.isSame(compareFilter.from, 'day') ||
      !primaryFilter.to.isSame(compareFilter.to, 'day')
    ) {
      return ComparisonMode.daterange;
    }
    return ComparisonMode.none;
  }
);
export const selectPrimaryText = createSelector(selectPrimaryFilter, (primaryFilter) => {
  return getHumanReadableDateText(primaryFilter?.from, primaryFilter?.to)?.text;
});
export const selectCompareText = createSelector(selectCompareFilter, (compareFilter) => {
  if (!compareFilter) return null;
  return getHumanReadableDateText(compareFilter?.from, compareFilter?.to)?.text;
});
export const selectOverviewValueExpression = createSelector(selectOverview, (overviewState) => {
  return overviewState.valueExpressionIdentifier;
});
export const selectValueExpressionHistory = createSelector(selectOverview, (overviewState) => {
  return overviewState.valueExpressionHistory;
});
