import React, { CSSProperties, HTMLAttributes, ReactElement } from 'react';
import style from './data-table-body.module.scss';
import classNames from 'classnames';
import { flexRender } from '@tanstack/react-table';
import { css } from '@emotion/css';
import { useDataTableContext } from '@data-table/contexts/data-table-context';
import { DataTableColumnMeta } from '@data-table/data-table.types';
import { DataTableColumnHeadersProps } from '../data-table-column-headers';
import { DataTableFooterProps } from '../data-table-footer';
import { sortTableColumns } from '@data-table/utils';

export type DataTableBodyProps = HTMLAttributes<HTMLDivElement> & {
  columnHeader?: ReactElement<DataTableColumnHeadersProps>;
  footer?: ReactElement<DataTableFooterProps>;
};
export function DataTableBody({ columnHeader, footer, ...htmlProps }: DataTableBodyProps): ReactElement {
  const { table } = useDataTableContext();

  const rowsModel = table.getRowModel();

  const columns = table.getVisibleFlatColumns();
  const columnOrder = table
    ._getOrderColumnsFn()(columns)
    .map((column) => column.id);
  const orderedColumns = sortTableColumns(columns, columnOrder);

  const tableScrollbarStyle = css([
    {
      '&::-webkit-scrollbar-button:vertical:end': {
        height: footer ? 40 : 0,
      },
    },
    {
      '&::-webkit-scrollbar-button:vertical:start': {
        height: columnHeader ? 21 : 0,
      },
    },
  ]);

  const tableRowsLayoutStyle = css`
    grid-template-rows: ${columnHeader && '42px'} 1fr ${footer && '80px'};
  `;

  const columnStyle: CSSProperties = {
    gridTemplateColumns: orderedColumns
      .map((column) => {
        return (column.columnDef.meta as DataTableColumnMeta)?.columnStyle?.gridColumn;
      })
      .join(' '),
  };

  const rowsStyle = css`
    grid-template-rows: repeat(${rowsModel.rows.length}, 42px);
  `;

  return (
    <div
      data-testid='data-table-body'
      className={classNames(style.dataTable, tableRowsLayoutStyle, tableScrollbarStyle)}
      {...htmlProps}>
      {columnHeader}
      <div className={classNames(style.body, rowsStyle)}>
        {rowsModel.rows.map((row) => {
          const cells = row.getVisibleCells();
          return (
            <div
              data-testid='data-table-body-row'
              data-orgname={row?.original?.orgName}
              key={row.id}
              className={style.bodyRow}
              style={columnStyle}>
              {cells.map((cell) =>
                flexRender(cell.column.columnDef.cell, {
                  ...cell.getContext(),
                  key: cell.id,
                })
              )}
            </div>
          );
        })}
      </div>
      {footer}
    </div>
  );
}

export default DataTableBody;
