import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import nettoHtZeit from './netto-ht-zeit';
import zustellungen from './zustellungen';
import abholungen from './abholungen';

const expression = new QuotientValueExpression(
  new SumValueExpression(zustellungen, abholungen),
  nettoHtZeit,
  'HT-Produktivität',
  false,
  undefined,
  undefined,
  getDefinitionByName('HT-Produktivität')
);

expression.setIdentifier('ht-produktivitaet');

export default expression;
