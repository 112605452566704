import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Styles from './TreeMenu.module.scss';
import { MdChevronRight } from 'react-icons/md';
import classNames from 'classnames';
import OrgTree from '../metrics2/models/websocket/org/OrgTree';
import { InputCheckbox } from '../dashboard/components/InputCheckbox';

const ErrorFn = () => {
  throw new Error('No TreeContext provider found');
};

type CustomLeafRenderFunction = (
  node: TreeMenuNode<any>,
  depth: number,
  selected: boolean,
  hovering: boolean
) => React.ReactNode;

const IS_SELECTABLE_DEFAULT = () => true;

const TreeContext = createContext<{
  isNodeOpen: (node: TreeMenuNode<any>) => boolean;
  toggleNodeOpen: (node: TreeMenuNode<any>) => void;
  isNodeSelected: (node: TreeMenuNode<any>) => boolean;
  toggleNodeSelection: (node: TreeMenuNode<any>) => void;
  isCollapsable: (node: TreeMenuNode<any>, depth: number) => boolean;
  isCheckable: (node: TreeMenuNode<any>, depth: number) => boolean;
  isMultiSelectionEnabled: boolean;
  onScrollToSelectedNode: () => void;
  shouldScrollToSelectedNode: boolean;
  isSelectableCallback?: (item: TreeMenuNode<any>) => boolean;
}>({
  isNodeOpen: ErrorFn,
  toggleNodeOpen: ErrorFn,
  isNodeSelected: ErrorFn,
  toggleNodeSelection: ErrorFn,
  isCollapsable: () => true,
  isCheckable: () => false,
  isMultiSelectionEnabled: false,
  onScrollToSelectedNode: ErrorFn,
  shouldScrollToSelectedNode: false,
  isSelectableCallback: IS_SELECTABLE_DEFAULT,
});

export interface NodeData {
  toString(): string;
  getIdentifier(): string;
}

export interface TreeMenuNode<T extends NodeData> {
  data: T;
  parent?: TreeMenuNode<T>;
  children?: Array<TreeMenuNode<T>>;
  opened?: boolean;
  matchesSearchInput?: (inputValue: string) => boolean;
}

export enum SelectionBehaviour {
  ALLOW_RESELECT_SAME_VALUE,
  DISABLE_RESELECT_SAME_VALUE,
}

export enum ScrollBehaviour {
  // If the menu is opened it will scroll to the first selected item, if
  // there are multiple selected, it will not scroll at all.
  SCROLL_TO_FIRST_SELECTION,
  // If the menu is opened it will not scroll to the first selected item
  DO_NOT_SCROLL,
}

type Props<T extends NodeData> = {
  tree: Array<TreeMenuNode<T>> | TreeMenuNode<T>;
  multiSelection?: boolean;
  onNodeSelection: (nodes: Array<TreeMenuNode<T>>) => void;
  customLeaf?: CustomLeafRenderFunction;
  leafClassName?: string;
  customExpandIcon?: CustomLeafRenderFunction;
  defaultOpenedNodes?: Array<TreeMenuNode<T>>;
  defaultSelectedNode?: TreeMenuNode<T>;
  isCollapsable?: (n: TreeMenuNode<T>, depth: number) => boolean;
  isCheckable?: (n: TreeMenuNode<T>, depth: number) => boolean;
  selectedNodes: Array<TreeMenuNode<T>>;
  selectionBehaviour?: SelectionBehaviour;
  scrollBehaviour?: ScrollBehaviour;
  isSelectableCallback?: (item: TreeMenuNode<any>) => boolean;
};

export const getTreeMenuDepthForNode = (node: TreeMenuNode<any>) => {
  if (!node || !node.parent) {
    // 0 because it's parent is the shadow root
    return 0;
  }
  return getTreeMenuDepthForNode(node.parent) + 1;
};

type DefaultTreeProps = {
  item: TreeMenuNode<any>;
  depth: number;
  onExpand;
  onNodeSelect: React.MouseEventHandler<HTMLDivElement | HTMLInputElement>;
  nodeSelectable: boolean;
  hasChildren: boolean;
  open: boolean;
  focusedNode;
  customLeaf: CustomLeafRenderFunction;
  customExpandIcon?: CustomLeafRenderFunction;
  showCollapseIcon: boolean;
  showCheckbox: boolean;
  selected: boolean;
  selectionBehaviour: SelectionBehaviour;
  isSelectableCallback?: (item: TreeMenuNode<any>) => boolean;
  leafClassName?: string;
};

const DefaultTreeNode: React.FC<DefaultTreeProps> = ({
  item,
  depth,
  onExpand,
  onNodeSelect,
  nodeSelectable,
  hasChildren,
  open,
  focusedNode,
  customLeaf,
  customExpandIcon = () => <MdChevronRight size={20} />,
  showCollapseIcon,
  showCheckbox,
  selected,
  selectionBehaviour,
  isSelectableCallback = IS_SELECTABLE_DEFAULT,
  leafClassName = '',
}) => {
  // memo because it's a dependency of a useCallback hook
  const isSelectable = useMemo(() => isSelectableCallback(item), [isSelectableCallback, item]);

  const onNodeSelectCallback = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (selected && selectionBehaviour === SelectionBehaviour.DISABLE_RESELECT_SAME_VALUE) {
        // don't allow selection in given mode
        return;
      }
      if (nodeSelectable && isSelectable) {
        onNodeSelect(e);
      } else {
        onExpand();
      }
    },
    [selected, selectionBehaviour, nodeSelectable, isSelectable, onNodeSelect, onExpand]
  );

  const { shouldScrollToSelectedNode, onScrollToSelectedNode } = useContext(TreeContext);

  const focusRef = useRef<HTMLLIElement>(null);
  const scrollToRef = useRef<HTMLLIElement>(null);
  const hasFocus = (() => {
    if (!item?.data?.getIdentifier()) return false;
    return focusedNode?.data?.getIdentifier() === item?.data?.getIdentifier();
  })();

  const onExpandCallback = useCallback(
    (event) => {
      event.stopPropagation();
      onExpand();
    },
    [onExpand]
  );

  const setRefCallback = useCallback(
    (r) => {
      if (hasFocus) {
        focusRef.current = r;
      }
      if (selected) {
        scrollToRef.current = r;
      }
    },
    [hasFocus, selected]
  );

  useEffect(() => {
    if (selected && shouldScrollToSelectedNode) {
      scrollToRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
      onScrollToSelectedNode();
    }
  }, [shouldScrollToSelectedNode, onScrollToSelectedNode, selected]);

  useEffect(() => {
    if (focusRef?.current) {
      focusRef.current.focus();
    }
  }, []);

  const iconRef = useRef<HTMLDivElement>(null);

  const hasExpandIcon = nodeSelectable && hasChildren && showCollapseIcon;

  const paddingLeft = (() => {
    if (depth === 1 && !hasExpandIcon) {
      return 26;
    } else {
      if (!hasExpandIcon) {
        return 16 * depth + 15;
      }
      return 16 * depth;
    }
  })();

  const [hovering, setHovering] = useState(false);
  const onItemHover = useCallback(() => setHovering(true), []);
  const onItemLeave = useCallback(() => setHovering(false), []);
  return (
    <li
      data-testid={(item as any)?.type === 'category' ? 'tree-category' : 'tree-item'}
      data-selected={selected ?? false}
      ref={setRefCallback}
      className={classNames(
        Styles.TreeLeaf,
        {
          [Styles.Open]: open,
          [Styles.Selected]: selected && selectionBehaviour === SelectionBehaviour.ALLOW_RESELECT_SAME_VALUE,
          [Styles.Disabled]: selected && selectionBehaviour === SelectionBehaviour.DISABLE_RESELECT_SAME_VALUE,
          [Styles.NoExpandIcon]: !hasExpandIcon,
          [Styles.Focus]: hasFocus,
          [Styles.NoCustomLeaf]: !customLeaf,
        },
        leafClassName
      )}
      onMouseOver={onItemHover}
      onMouseLeave={onItemLeave}
      style={{
        paddingLeft,
        gridTemplateColumns:
          hasExpandIcon || showCheckbox
            ? `${hasExpandIcon ? 'min-content' : ''} ${showCheckbox ? 'min-content' : ''} 1fr`
            : '1fr',
      }}>
      {hasExpandIcon && (
        <div className={Styles.ExpandIcon} onClick={onExpandCallback} ref={iconRef}>
          {customExpandIcon(item, depth, selected, hovering)}
        </div>
      )}
      {showCheckbox && (
        <InputCheckbox
          defaultChecked={selected}
          onClick={onNodeSelectCallback}
          containerClassName={Styles.CheckboxContainer}
        />
      )}
      <div
        onClick={onNodeSelectCallback}
        className={classNames(Styles.Name, {
          // [Styles.NotSelectable]: !isSelectable,
        })}>
        {customLeaf ? customLeaf(item, depth, selected, hovering) : item.data.toString()}
      </div>
    </li>
  );
};

type TreeItemProps = {
  item: TreeMenuNode<any>;
  hideLeavesInDepth?: number;
  customLeafComponent?: CustomLeafRenderFunction;
  keyboardSelectedNode: TreeMenuNode<any>;
  selectionBehaviour: SelectionBehaviour;
  customExpandIcon: CustomLeafRenderFunction;
  leafClassName?: string;
};

const TreeItem = ({
  item,
  hideLeavesInDepth = 0,
  customLeafComponent,
  keyboardSelectedNode,
  selectionBehaviour,
  customExpandIcon,
  leafClassName,
}: TreeItemProps) => {
  const hasChilds = !!item.children?.length;
  const depth = getTreeMenuDepthForNode(item);
  const {
    isNodeOpen,
    toggleNodeOpen,
    toggleNodeSelection,
    isNodeSelected,
    isCollapsable,
    isCheckable,
    isMultiSelectionEnabled,
    isSelectableCallback,
  } = useContext(TreeContext);
  const isOpen = isNodeOpen(item);

  const toggleExpansion = useCallback(() => toggleNodeOpen(item), [item, toggleNodeOpen]);

  const toggleNodeSelectCallback = useCallback(() => toggleNodeSelection(item), [item, toggleNodeSelection]);

  const showCheckbox = isMultiSelectionEnabled && isCheckable(item, depth);

  const Leaf = () => {
    if (hideLeavesInDepth === depth) {
      return null;
    }
    return (
      <DefaultTreeNode
        item={item}
        depth={depth}
        onExpand={toggleExpansion}
        onNodeSelect={toggleNodeSelectCallback}
        nodeSelectable
        hasChildren={hasChilds}
        open={isOpen}
        focusedNode={keyboardSelectedNode}
        customLeaf={customLeafComponent}
        showCollapseIcon={isCollapsable(item, depth)}
        showCheckbox={showCheckbox}
        selected={isNodeSelected(item)}
        selectionBehaviour={selectionBehaviour}
        isSelectableCallback={isSelectableCallback}
        customExpandIcon={customExpandIcon}
        leafClassName={leafClassName}
      />
    );
  };

  if (hasChilds) {
    return (
      <>
        <Leaf />
        <ul className={Styles.List}>
          {isOpen &&
            item.children
              .filter((c) => c != null)
              .map((c, i) => (
                <TreeItem
                  keyboardSelectedNode={keyboardSelectedNode}
                  key={`${depth} ${i}`}
                  item={c}
                  hideLeavesInDepth={hideLeavesInDepth}
                  customLeafComponent={customLeafComponent}
                  selectionBehaviour={selectionBehaviour}
                  customExpandIcon={customExpandIcon}
                  leafClassName={leafClassName}
                />
              ))}
        </ul>
      </>
    );
  }

  return <Leaf />;
};

const getNodeParentIds = (node: TreeMenuNode<any>): string[] => {
  if (node?.parent) {
    return [node.parent?.data?.getIdentifier(), ...getNodeParentIds(node.parent)];
  } else {
    return [];
  }
};

const TreeMenu = <T extends NodeData>({
  tree,
  multiSelection = false,
  onNodeSelection,
  customLeaf,
  defaultOpenedNodes,
  defaultSelectedNode,
  isCollapsable = () => true,
  isCheckable,
  selectedNodes: selectedNodesFromProps,
  selectionBehaviour = SelectionBehaviour.ALLOW_RESELECT_SAME_VALUE,
  scrollBehaviour = ScrollBehaviour.SCROLL_TO_FIRST_SELECTION,
  isSelectableCallback,
  customExpandIcon,
  leafClassName,
}: PropsWithChildren<Props<T>>) => {
  const [selectedNodes, setSelectedNodes] = useState<{
    [k: string]: boolean;
  }>({});
  const [openNodes, setOpenNodes] = useState<{
    [k: string]: true;
  }>({});
  useContext(TreeContext);
  const initialized = useRef(false);

  useEffect(() => {
    if (!Array.isArray(selectedNodesFromProps)) {
      return;
    }

    const nodesWithData = selectedNodesFromProps.filter((v) => v?.data?.getIdentifier() != null);

    if (
      nodesWithData.every((v) => Object.keys(selectedNodes).includes(v.data.getIdentifier())) &&
      nodesWithData.length === Object.keys(selectedNodes).length
    ) {
      return;
    }
    const asObject = nodesWithData.reduce((prev, curr) => ({ ...prev, [curr.data.getIdentifier()]: true }), {});
    setSelectedNodes(asObject);

    if (!initialized.current && selectedNodesFromProps.length > 0) {
      const toOpen = selectedNodesFromProps
        ?.flatMap((node) => {
          return getNodeParentIds(node);
        })
        .filter((id) => !!id);

      const openObject = toOpen.reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: true,
        }),
        {}
      );
      setOpenNodes(openObject);
      initialized.current = true;
    }
  }, [selectedNodes, selectedNodesFromProps]);

  useEffect(() => {
    if (!defaultOpenedNodes) {
      return;
    }
    const nodeIdentifier = defaultOpenedNodes.map((n) => n.data.getIdentifier());
    const openNodes = nodeIdentifier.reduce((acc, curr) => ({ ...acc, [curr]: true }), {});
    setOpenNodes((v) => ({
      ...v,
      ...openNodes,
    }));
  }, [defaultOpenedNodes]);

  useEffect(() => {
    setKeyboardSelectedNode(defaultSelectedNode);
  }, [defaultSelectedNode]);

  const rootNodes = useMemo(() => (Array.isArray(tree) ? tree : [tree]), [tree]);

  const isNodeOpen = useCallback(
    (node) => {
      if (!node?.data?.getIdentifier()) return false;
      return !!openNodes[node.data.getIdentifier()];
    },
    [openNodes]
  );

  const isNodeSelected = useCallback(
    (node) => {
      if (!node?.data?.getIdentifier()) return false;
      return selectedNodes[node.data.getIdentifier()];
    },
    [selectedNodes]
  );

  const [shadowRoot, rootNodesWithShadow] = useMemo(() => {
    const shadowRoot: TreeMenuNode<T> = {
      data: undefined,
      parent: null,
      children: [...rootNodes],
      opened: false,
    };
    rootNodes.forEach((t) => {
      if (!t.parent) {
        t.parent = shadowRoot;
      }
    });

    return [shadowRoot, rootNodes.filter((n) => n != null)];
  }, [rootNodes]);

  const sendNodeChangeCallback = useCallback(
    (selectedNodes: { [k: string]: any }) => {
      if (!onNodeSelection) {
        return;
      }

      const nodes = Object.keys(selectedNodes).map((n) =>
        OrgTree.findNodeInTree<TreeMenuNode<T>>(shadowRoot, (traverseElem) => traverseElem?.data?.getIdentifier() === n)
      );
      onNodeSelection(nodes);
    },
    [onNodeSelection, shadowRoot]
  );

  const toggleNodeOpen = useCallback(
    (node: TreeMenuNode<T>) => {
      const identifier = node.data.getIdentifier();
      if (!identifier) {
        return;
      }
      if (isNodeOpen(node)) {
        setOpenNodes(({ [identifier]: _, ...rest }) => rest);
      } else {
        setOpenNodes((v) => ({ ...v, [identifier]: true }));
      }
    },
    [isNodeOpen]
  );

  const toggleNodeSelection = useCallback(
    (node: TreeMenuNode<T>) => {
      const identifier = node.data.getIdentifier();
      if (!identifier) {
        return;
      }
      const newSelectedNodes = (() => {
        if (!multiSelection) {
          return {
            [identifier]: true,
          };
        }
        if (isNodeSelected(node)) {
          const { [identifier]: _, ...remainingElements } = selectedNodes;
          return remainingElements;
        } else {
          return {
            ...selectedNodes,
            [identifier]: true,
          };
        }
      })();
      setSelectedNodes(newSelectedNodes);
      sendNodeChangeCallback(newSelectedNodes);
    },
    [isNodeSelected, multiSelection, selectedNodes, sendNodeChangeCallback]
  );

  const [keyboardSelectedNode, setKeyboardSelectedNode] = useState<TreeMenuNode<any>>();

  const [shouldScrollToSelectedNode, setShouldScrollToSelectedNode] = useState(false);

  const onScrollToSelectedNode = useCallback(() => setShouldScrollToSelectedNode(false), []);

  useEffect(() => {
    if (scrollBehaviour === ScrollBehaviour.DO_NOT_SCROLL) return;

    // If there is only one selected node we want to scroll to it
    // when the tree menu is visible again
    if (selectedNodes && Object.keys(selectedNodes).length === 1) {
      setShouldScrollToSelectedNode(true);
    }
  }, [scrollBehaviour, selectedNodes]);

  const keyboardListener = useCallback(
    (event) => {
      const depth = getTreeMenuDepthForNode(keyboardSelectedNode);

      const selectNext = () => {
        if (!keyboardSelectedNode) {
          setKeyboardSelectedNode(rootNodes[0]);
          return;
        }

        const siblings = keyboardSelectedNode?.parent?.children || [];
        const myIdx = siblings.findIndex((n) => n === keyboardSelectedNode);
        const nextTarget = siblings[(myIdx + 1) % siblings.length];
        setKeyboardSelectedNode(nextTarget);
      };
      const selectPrev = () => {
        const siblings = keyboardSelectedNode.parent?.children || [];
        const myIdx = siblings.findIndex((n) => n === keyboardSelectedNode);
        const nextIdx = myIdx - 1 < 0 ? siblings.length - 1 : myIdx - 1;
        const nextTarget = siblings[nextIdx];
        setKeyboardSelectedNode(nextTarget);
      };
      const collapse = () => {
        if (keyboardSelectedNode?.parent) {
          // If grandparent is null, it's the shadow root. In this
          // case we don't move the keyboard cursor up
          if (keyboardSelectedNode.parent?.parent === null) {
            toggleNodeOpen(keyboardSelectedNode.parent);
            return;
          }
          setKeyboardSelectedNode(keyboardSelectedNode.parent);
          // Only toggle node if it's open
          if (
            openNodes[keyboardSelectedNode.parent.data.getIdentifier()] &&
            isCollapsable(keyboardSelectedNode.parent, getTreeMenuDepthForNode(keyboardSelectedNode.parent))
          ) {
            toggleNodeOpen(keyboardSelectedNode.parent);
          }
        }
      };
      const expand = () => {
        if (!keyboardSelectedNode.children?.length) {
          return;
        }

        setKeyboardSelectedNode(keyboardSelectedNode.children[0]);
        // Only toggle node if it is not opened already. If you click
        // open a node, this could happen
        if (
          !openNodes[keyboardSelectedNode.data.getIdentifier()] &&
          isCollapsable(keyboardSelectedNode, getTreeMenuDepthForNode(keyboardSelectedNode))
        ) {
          toggleNodeOpen(keyboardSelectedNode);
        }
      };
      const selectCurrent = () => {
        toggleNodeSelection(keyboardSelectedNode);
      };
      const openFocused = () => {
        if (!multiSelection || isCheckable(keyboardSelectedNode, depth)) {
          selectCurrent();
        }
      };
      switch (event.key) {
        case 'ArrowDown':
          selectNext();
          break;
        case 'ArrowUp':
          selectPrev();
          break;
        case 'ArrowLeft':
        case '-':
          collapse();
          break;
        case 'ArrowRight':
        case '+':
          expand();
          break;
        case 'Enter':
          openFocused();
          break;
        case ' ': {
          openFocused();
          break;
        }
      }
    },
    [
      isCheckable,
      isCollapsable,
      keyboardSelectedNode,
      multiSelection,
      openNodes,
      rootNodes,
      toggleNodeOpen,
      toggleNodeSelection,
    ]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyboardListener);

    return () => {
      document.removeEventListener('keydown', keyboardListener);
    };
  }, [keyboardListener]);

  return (
    <>
      <TreeContext.Provider
        value={{
          isNodeOpen,
          toggleNodeOpen,
          isNodeSelected,
          toggleNodeSelection,
          isCollapsable: isCollapsable ?? (() => true),
          isCheckable: isCheckable ?? (() => true),
          isMultiSelectionEnabled: multiSelection,
          onScrollToSelectedNode,
          shouldScrollToSelectedNode,
          isSelectableCallback,
        }}>
        <div data-testid='tree-menu' className={Styles.TreeMenu}>
          <ul className={classNames(Styles.List)}>
            {rootNodesWithShadow.map((n, i) => {
              return (
                <TreeItem
                  key={n.data?.getIdentifier() + i}
                  keyboardSelectedNode={keyboardSelectedNode}
                  item={n}
                  customLeafComponent={customLeaf}
                  selectionBehaviour={selectionBehaviour}
                  customExpandIcon={customExpandIcon}
                  leafClassName={leafClassName}
                />
              );
            })}
          </ul>
        </div>
      </TreeContext.Provider>
    </>
  );
};

export { TreeMenu };
