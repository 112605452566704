import { UndeliverableReasonShortLabels } from '../../../metrics2/models/enumerations/UndeliverableReason';

export const AttributeLabels = Object.freeze({
  'shipment.itemCategory': 'Sendungskategorie',
  'shipment.undeliverableReason': 'Rücklaufgrund',
  'shipment.deliveryMode': 'Zustellart',
  'shipment.itemType': 'Sendungstyp',
  'shipment.recipientPerson': 'Empfänger',
  'shipment.itemCount': 'Sendungsanzahl',
  'shipment.detailedDeliveryMode': 'Detaillierte Zustellart',
  'shipment.client': 'Auftraggeber',
  'shipment.services': 'Sonderservices',
  'shipment.totalItemCount': 'Anzahl Sendungen des Zustellvorgangs',
  'shipment.totalCustomerCount': 'Anzahl Empfänger des Zustellvorgangs',
  'shipment.time.hour': 'Tageszeit (Stunde)',
  'shipment.time.weekday': 'Wochentag',
  'shipment.authorization': 'Vollmacht',
});

export const AttributeChoiceLabels = Object.freeze({
  'shipment.itemCategory': {
    PKT: 'Paket',
    ABA: 'Abholauftrag',
    SPE: 'Sperrgut',
    HP: 'Hängepaket',
    GEP: 'Gepäck',
    UNB: 'Unbekannt',
    KAT: 'Katalog',
    KWS: 'Kleinwarensendung',
    BRF: 'Brief',
  },
  'shipment.undeliverableReason': UndeliverableReasonShortLabels,
  'shipment.itemType': {
    ABA: 'Abholauftrag',
    BEH: 'Behälter',
    BRF: 'Brief',
    BRFBU: 'Businessbrief',
    BRFST: 'Brief Standard',
    FAH: 'Fahrrad',
    GEP: 'Gepäck',
    GEPSO: 'Sondergepäck',
    GEPST: 'Gepäck Standard',
    GRST: 'Großstück',
    HERVP: 'Herstellerverpackung',
    HP: 'Hängepaket',
    HPGE: 'Hängepaket gebündelt',
    HPST: 'Hängepaket Standard',
    KAT: 'Katalog',
    KATST: 'Katalog Standard',
    KATUN: 'Katalog unfoliert',
    KFS: 'Krankenfahrstuhl',
    KRTS: 'Kartusche',
    KWS: 'Kleinwarensendung',
    LEB: 'Lebensmittel',
    PKT: 'Paket',
    SG: 'Servicegebühr',
    SKI: 'Ski',
    SPE: 'Sperrgut',
    TUE: 'Tüte',
    LTUE: 'Leichttüte',
    UNB: 'Unbekannt',
  },
  'shipment.detailedDeliveryMode': {
    intendedReceiverAndNextDoor: 'Originalempfänger und Nachbar',
    'person:atDoor': 'In Wohnung',
    'person:intendedReceiver': 'Originalempfänger',
    'person:nextDoor': 'Nachbar',
    'person:reception': 'Rezeption',
    'person:staff': 'Mitarbeiter',
    'putDownOutside:Mailbox': 'Briefkasten',
    'putDownOutside:Bag': 'Zustelltüte',
    'putDownOutside:oneTimeAuthorization': 'Einmalvollmacht',
    'putDownOutside:permanentAuthorization': 'Dauervollmacht',
    'putDownOutside:bnkAuthorization': 'BNK-Vollmacht',
    'putDownOutside:unknownAuthorization': 'Vollmacht',
    'undeliverable:AuthorityNotFound': 'Rückläufer: Ablageort nicht gefunden',
    'undeliverable:AuthorityNotPossible': 'Rückläufer: Ablage nicht möglich',
    'undeliverable:Canceled': 'Rückläufer: Storno',
    'undeliverable:DeliveryItemDamaged': 'Rückläufer: Schadenfall',
    'undeliverable:DeliveryRefused': 'Rückläufer: Annahmeverweigerung',
    'undeliverable:Ident': 'Rückläufer: Identservice',
    'undeliverable:IdentAge': 'Rückläufer: Altersverifizierung nicht möglich',
    'undeliverable:IdentBirthday': 'Rückläufer: Identservice Geburtsdatum',
    'undeliverable:IdentDocument': 'Rückläufer: Identservice Dokument',
    'undeliverable:IdentName': 'Rückläufer: Identservice Name',
    'undeliverable:IdentPicture': 'Rückläufer: Identservice Lichtbild',
    'undeliverable:IdentPin': 'Rückläufer: Identservice PIN-Code',
    'undeliverable:IdVerificationFailed': 'Rückläufer: Identservice Dokument',
    'undeliverable:Loss': 'Rückläufer: Verlust',
    'undeliverable:MinAgeVerificationFailed': 'Rückläufer: Identservice Geburtsdatum',
    'undeliverable:MisroutedDeliveryItem': 'Rückläufer: Irrläufer',
    'undeliverable:NameVerificationFailed': 'Rückläufer: Identservice Name',
    'undeliverable:NoMoney': 'Rückläufer: Empfänger hat kein Bargeld',
    'undeliverable:NotMet': 'Rückläufer: Nicht angetroffen',
    'undeliverable:NotMet1': 'Rückläufer: Nicht angetroffen - N1',
    'undeliverable:NotMet2': 'Rückläufer: Nicht angetroffen - N2',
    'undeliverable:NotMet3': 'Rückläufer: Nicht angetroffen - N3',
    'undeliverable:NotMet4': 'Rückläufer: Nicht angetroffen - N4',
    'undeliverable:NotPickedUp': 'Rückläufer: Nicht am Paketshop abgeholt',
    'undeliverable:PackagingDamaged': 'Rückläufer: Verpackung ausgebessert',
    'undeliverable:PictureVerificationFailed': 'Rückläufer: Identservice Lichtbild',
    'undeliverable:RLoGGS': 'Rückläufer: Rücklauf ohne Gegenscannung',
    'undeliverable:SingleAuthorityNotPossible': 'Rückläufer: Einmalvollmacht nicht möglich',
    'undeliverable:TooLate': 'Rückläufer: Zeitmangel',
    'undeliverable:WrongAddress': 'Rückläufer: Falsche Anschrift',
    person: 'Person',
    paketShop: 'PaketShop',
    props: 'ProPS',
    undeliverable: 'Rückläufer',
    putDownOutside: 'Quittungslose Sendung/Vollmacht',
    // osbolete
    oneTimeAuthorizationPutDown: 'Einmalvollmacht',
    authorization: 'Vollmacht',
  },
  'shipment.recipientPerson': {
    intendedReceiver: 'Originalempfänger',
    atDoor: 'In Wohnung',
    nextDoor: 'Nachbar',
    staff: 'Mitarbeiter',
    reception: 'Rezeption',
  },
  'shipment.time.weekday': {
    1: 'Sonntag',
    2: 'Montag',
    3: 'Dienstag',
    4: 'Mittwoch',
    5: 'Donnerstag',
    6: 'Freitag',
    7: 'Samstag',
  },
});
