import Logger from '../../utils/logging/Logger';
import { QueryPriority } from '../models/enumerations/QueryPriority';
import { compareQueryPriorities, QueryPriorities, PriorityValues } from '../models/enumerations/QueryPriority';
import ScheduledQuery from '../models/queries/ScheduledQuery';
import WebsocketApiService from '../../common/services/WebsocketApiService';
import WebsocketEvent from '../../common/models/websocket/WebsocketEvent';
import AuthService from '../../common/services/AuthService';
import BasePayload from '../../common/models/websocket/payloads/BasePayload';

export default class RequestScheduler {
  static AUTH_TIMEOUT: number = 10000;
  static DEFAULT_PARRALLELISM: number = 5;
  static DEFAULT_INTERVAL_MS: number = 25;

  logger: Logger = Logger.getInstance('RequestScheduler');
  websocketApiService: WebsocketApiService;
  authService: AuthService;

  _runningQueries: Set<ScheduledQuery>;
  _scheduledQueries: Set<ScheduledQuery>;
  _allowedParrallelism: number;

  _timeoutHandle: number | null | undefined;

  constructor(
    websocketApiService: WebsocketApiService,
    authService: AuthService,
    allowedParrallelism: number = RequestScheduler.DEFAULT_PARRALLELISM
  ) {
    this.websocketApiService = websocketApiService;
    this.authService = authService;
    this._runningQueries = new Set();
    this._scheduledQueries = new Set();
    this._allowedParrallelism = allowedParrallelism;
  }

  init(): void {
    this._scheduleProcessing(0);
  }

  destruct(): void {
    if (this._timeoutHandle) {
      window.clearTimeout(this._timeoutHandle);
    }
  }

  request(
    requestType: string,
    payload: BasePayload<any> | null | undefined = null,
    priority: QueryPriority = QueryPriorities.normal
  ): ScheduledQuery {
    const request = new WebsocketEvent({ name: requestType, payload });
    const scheduledQuery: ScheduledQuery = new ScheduledQuery(request, priority);
    this._scheduledQueries.add(scheduledQuery);
    return scheduledQuery;
  }

  /**
   * Checks whether processing can occur and schedules the next processing loop.
   * @returns {Promise<void>}
   * @private
   */
  async _processLoop() {
    if (this.authService.isAuthenticated && this._scheduledQueries.size > 0) {
      if (this._runningQueries.size < this._allowedParrallelism) {
        await this._process();
      }
    }
    this._scheduleProcessing();
  }

  /**
   * Schedules processing if it wasn't scheduled before
   * @private
   */
  _scheduleProcessing(timeout: number = RequestScheduler.DEFAULT_INTERVAL_MS) {
    if (this._timeoutHandle) {
      window.clearTimeout(this._timeoutHandle);
    }
    this._timeoutHandle = window.setTimeout(this._processLoop.bind(this), timeout);
  }

  /**
   * Performs the actual processing of queries
   * @returns {Promise<void>}
   * @private
   */
  async _process() {
    this._removeAbortedQueries();
    const prioritizedQueries = [...this._scheduledQueries].sort((a: ScheduledQuery, b: ScheduledQuery) =>
      compareQueryPriorities(b.priority, a.priority)
    );
    const executableQueries = prioritizedQueries.slice(0, this._allowedParrallelism - this._runningQueries.size);
    executableQueries.forEach((q) => this._scheduledQueries.delete(q));
    executableQueries.forEach((q) => this._executeQuery(q));
  }

  /**
   * Executes a single query, resolves it's promise and removes it from the running queries.
   * @param query the query to execute
   * @returns {Promise}
   * @private
   */
  async _executeQuery(query: ScheduledQuery) {
    query.setRunning(true);
    this._runningQueries.add(query);
    try {
      const response = await this.websocketApiService.request(query.request);
      query.resolve(response);
    } catch (e) {
      query.reject(e);
    }
    this._runningQueries.delete(query);
    return query.promise;
  }

  /**
   * Filters the currently scheduled queries to remove aborted queries.
   * @private
   */
  _removeAbortedQueries(): void {
    [...this._scheduledQueries].filter((q) => q.isAborted()).forEach((q) => this._scheduledQueries.delete(q));
  }

  async awaitScheduledRequests(minPriority: QueryPriority | null | undefined = null) {
    const minPriorityValue: number = PriorityValues[minPriority] || 0;
    const promises = [...this._scheduledQueries, ...this._runningQueries]
      .filter((q) => PriorityValues[q.priority] >= minPriorityValue)
      .map((q) => q.promise);
    return Promise.all(promises);
  }
}
