import { KpiDefinition, KpiDefinitionWithoutName, KpiName } from '@custom-types/kpi';

/* eslint-disable max-len */
const tourExplanationAddition =
  'Es kann zu leichten Differenzen zwischen der offiziellen Abrechnungsmenge auf der Rechnung und der angezeigten Mengen in LMA kommen. Dies tritt dann auch, wenn der Tourenabschluss des Vormonats oder dieses Monats nicht rechtzeitig zum Monatsabschluss erfolgt ist.';

/* eslint-disable max-len */
export const definitions: ReadonlyArray<KpiDefinition> = [
  {
    name: 'IST-Lademenge',
    definition: 'Anzahl der auf Tour geladenen Sendungen mit mind. einem Stopp.',
    explanation:
      'Sendungen, die einen Tourenausgangs-Scan und/oder einen Scan auf Tour erhalten haben. Die dazugehörige Tour muss mindestens einen Stopp ausgeführt haben, damit die Sendungen in die IST-Lademenge des Standortes einfließen.\nSollten Sendungen am gleichen Tag (gleiches Tourdatum) von mehreren Touren bearbeitet werden, werden sie nur bei der Tour der letzten Bearbeitung gezählt.\nDamit wird eine Sendung an einem Tag immer nur einmal in die IST-Lademenge gezählt. Geht sie allerdings an mehreren Tagen auf Tour (z.B. Rücklauf beim ersten Tag), dann zählt diese Sendung auch an den jeweiligen Tagen zur IST-Lademenge.',
  },
  {
    name: 'SOLL-Lademenge',
    definition: 'Anzahl der auf Tour geladenen Sendungen.',
    explanation:
      'Sendungen, die einen Tourenausgangs-Scan und/oder einen Scan auf Tour erhalten haben. Die dazugehörige Tour hat jedoch nicht unbedingt auch einen Stopp ausgeführt, aber fließt mit in die SOLL-Lademenge des Standortes ein.\nSollten Sendungen am gleichen Tag (gleiches Tourdatum) von mehreren Touren bearbeitet werden, werden sie nur bei der Tour der letzten Bearbeitung gezählt.\nDamit wird eine Sendung an einem Tag immer nur einmal in die SOLL-Lademenge gezählt. Geht sie allerdings an mehreren Tagen auf Tour (z.B. Rücklauf beim ersten Tag), dann zählt diese Sendung auch an den jeweiligen Tagen zur SOLL-Lademenge.',
  },
  {
    name: 'ø SOLL-Lademenge',
    definition: 'SOLL-Lademenge durch die Anzahl der Touren.',
    explanation:
      'Die ø SOLL-Lademenge ist die SOLL-Lademenge (siehe Definition unter SOLL-Lademenge) geteilt durch die Anzahl der Touren.',
  },
  {
    name: 'ø IST-Lademenge',
    definition: 'IST-Lademenge durch die Anzahl der Touren.',
    explanation:
      'Die ø IST-Lademenge ist die IST-Lademenge (siehe Definition unter IST-Lademenge) geteilt durch die Anzahl der Touren.',
  },
  {
    name: 'Zustellungen',
    definition: 'Anzahl aller erfolgreich zugestellten Sendungen (enthält keine PS Abgaben & Abholaufträge).',
    explanation:
      'Sendungen, die auf Tour (nicht am Paketshop & Abholaufträge) zugestellt wurden.\nDies kann der Originalempfänger sein, aber auch ein Nachbar, etc. Außerdem sind hier über Vollmachten zugestellte Sendungen enthalten.',
  },
  {
    name: 'Dauervollmachtquote',
    definition:
      'Anteil der Sendungen, die per Dauervollmacht zugestellt wurden, an allen erfolgreich zugestellten Sendungen.',
    explanation:
      'Es wird die Anzahl der Zustellungen per Dauervollmacht durch die Gesamtanzahl der Zustellungen geteilt.',
  },
  {
    name: 'Rückläufer',
    definition: 'Anzahl der auf Tour in den Rücklauf gescannten Sendungen.',
    explanation:
      'Bis zum 15.08.2021 wurden alle Rücklaufscans gezählt. Seit dem 16.08.2021 werden nur noch echte Rückläufer gezählt. Also eine Sendung, die erst einen Rücklauf-Scan erhalten hat und dann doch noch am gleichen Tag zugestellt wurde, zählt nicht mehr als Rücklaufer.\nEs werden nur die Rücklaufscans auf Tour betrachtet und nicht die Rücklaufscans am Standort am Tourende.',
  },
  {
    name: 'Rückläufer Eil',
    definition: 'Anzahl der auf Tour in den Rücklauf gescannten Eil-Sendungen.',
    explanation: 'Siehe Rückläufer mit der Einschränkung auf Eil-Sendungen.',
  },
  {
    name: 'Rücklaufquote',
    definition: 'Anteil der Rückläufer an der gesamten Lademenge.',
    explanation:
      'Anteil der Rückläufer an der gesamten Lademenge der Tour(en).\nEine Sendung, die an mehreren Tagen auf Tour geht, wird an jedem Tag in die Rücklaufquote mit einberechnet.',
  },
  {
    name: 'Rücklauf Abholaufträge',
    definition: 'Anzahl der Abholkarten, die auf Tour in den Rücklauf gescannt wurden.',
    explanation: 'Analog zu Rückläufer. Allerdings werden hier nur Abholaufträge und keine Sendungen gezählt.',
  },
  {
    name: 'Abholungen',
    definition: 'Anzahl aller auf Tour abgeholten und mitgenommenen Sendungen (ohne PaketShop und ATG).',
    explanation:
      'Sendungen, die der Fahrer auf Tour abholt (mittels einer Retourenabholkarte bei einer Privatperson) oder ungeplant mitnimmt (übergeben von einer Privatperson).\nPS- und ProPS-Abholungen sind nicht enthalten. Diese Sendungen sind nicht Teil der Lademenge.',
  },
  {
    name: 'Abholungen ohne Mitnahmen',
    definition: 'Anzahl aller auf Tour abgeholten Sendungen (ohne PaketShop und ATG).',
    explanation:
      'Sendungen, die der Fahrer auf Tour abholt (mittels einer Retourenabholkarte bei einer Privatperson).\nPS- und ProPS-Abholungen sind nicht enthalten. Diese Sendungen sind nicht Teil der Lademenge.',
  },
  {
    name: 'Abholaufträge',
    definition: 'Anzahl der auf Tour geladenen Abholaufträge.',
    explanation: 'Analog zur Lademenge. Allerdings werden hier nur Abholaufträge und keine Sendungen gezählt.',
  },
  {
    name: 'Erfolgreiche Abholaufträge',
    definition: 'Anzahl der erfolgreich bearbeiteten Abholaufträge auf Tour.',
    explanation: 'Anzahl der Abholaufträge, die auf Tour erfolgreich bearbeitet wurden.',
  },
  {
    name: 'PS Abgaben',
    definition: 'Anzahl aller am Paketshop abgegebenen Sendungen.',
    explanation: 'Anzahl Sendungen, die der Fahrer am Paketshop abgegeben hat.',
  },
  {
    name: 'PS Mitnahmen',
    definition: 'Anzahl aller am Paketshop mitgenommenen Sendungen.',
    explanation:
      'Anzahl Sendungen, die der Fahrer vom Paketshop abgeholt hat.\nEnthält Abholungen und Mitnahmen von Retouren, PriPS-Sendungen und Rückläufer vom Paketshop.',
  },
  {
    name: 'ATG Abholungen',
    definition: 'Anzahl aller auf Tour bei einem Auftraggeber (z.B. ProPS und DDB) abgeholten Sendungen',
    explanation: 'Anzahl Sendungen, die bei kleineren Auftraggebern mit einer letzte Meile Tour abgeholt wurden.',
  },
  {
    name: 'Brutto-Zustellzeit',
    definition: 'Zeit vom ersten bis zum letzten taggleichen Scan auf Tour.',
    explanation:
      'Zeit vom ersten Scan auf Tour bis zum letzten Scan auf Tour am selben Tag. Dabei werden alle Scans auf Tour und am PS berücksichtigt. Der Tourenausgang und der Rücklauf an der ZSB ist dementsprechend nicht enthalten.',
  },
  {
    name: 'Netto-Zustellzeit alt',
    definition: 'Zeit vom ersten bis zum letzten taggleichen Scan auf Tour bereinigt um Pausenzeiten.',
    explanation:
      'Bereinigung der Brutto-Zustellzeit um Pausenzeiten. Aufgrund besserer Pausenerkennung wurde diese Kennzahl durch eine neue ersetzt.',
  },
  {
    name: 'Netto-Zustellzeit',
    definition: 'Zeit vom ersten bis zum letzten Stopp bereinigt um Pausenzeiten.',
    explanation:
      'Bei der Netto-Zustellzeit wird die Zeit vom ersten bis zum letzten Stopp um Pausen bereinigt. Die Pausen werden hierbei automatisiert aufgrund der vergangenen Zeit zwischen den Stopps erkannt.' +
      '\nDie Dauer zwischen zwei Stopps wird als Pause erkannt, wenn' +
      '\n1. Mehr als 20 Minuten zwischen 2 Stopps an der Haustür liegen' +
      '\n2. Mehr als 25 Minuten zwischen einem Stopp an der Haustür und einem Stopp am Paketshop bzw. beim Auftraggeber liegen' +
      '\n3. Mehr als 40 Minuten zwischen zwei Paketshop- bzw. Auftraggeber-Stopps liegen' +
      '\nIm Gegensatz zu älteren Definitionen wird die erkannte Pause nicht komplett als Pause gewertet. Im ersten Fall werden 3 Minuten, im zweiten Fall 5 Minuten und im letzten Fall 15 Minuten noch als Arbeitszeit gewertet, die für den Abschluss bzw. den Start der Stopps vor und nach der Pause benötigt werden.',
  },
  {
    name: 'Tourstrecke',
    definition: 'Berechnete Strecke anhand der Adressen und Scans der Tour inkl. An- und Rückfahrt.',
    explanation: `Mit einer Routenplanungssoftware wird eine Strecke von der ZSB über die Adressen der Pakete (in Stoppreihenfolge) zurück zur ZSB berechnet.\n
      GPS-Positionen werden hierbei nicht verwendet, weil diese noch zu häufig fehlen.\n
      Die Adressen von Paketen mit Rücklauf-Scans U, Z, oGGS und Storno werden ignoriert, weil der Fahrer bei diesen Scans in der Regel nicht an der Adresse ist.\n
      Generell werden zusätzlich Stoppabstände über 100 km und unrealistische Stoppabstände (Tour müsste sich mit über 100 km/h bewegen) herausgefiltert.\n
      Es wird der Standort der ZSB aus dem Last Mile Administration Tool verwendet. Ist der Standort dort nicht hinterlegt, wird keine Strecke berechnet. \n
      Tagversetzte Bearbeitungen werden hierbei nicht berücksichtigt und somit nicht einberechnet.`,
  },
  {
    name: 'Tourfahrtzeit',
    definition: 'Berechnete Fahrzeit anhand der Adressen und Scans der Tour inkl. An- und Rückfahrt.',
    explanation: `Mit einer Routenplanungssoftware wird die Fahrzeit der Strecke von der ZSB über die Adressen der Pakete (in Stoppreihenfolge) zurück zur ZSB berechnet.\n
      Die Zeit passt zu der berechneten Tourstrecke. Besonderheiten siehe Erklärungen zur Tourstrecke. \n
      Tagversetzte Bearbeitungen werden hierbei nicht berücksichtigt und somit nicht einberechnet.`,
  },
  {
    name: 'Anfahrtsstrecke',
    definition: 'Berechnete Strecke von ZSB bis erstem Stopp.',
    explanation: `Mit einer Routenplanungssoftware wird die benötigte Strecke von der ZSB bis zur Adresse des ersten Stopps berechnet.\n
Sollte am Anfang einer Tour zunächst RL U, Z, oGGS und Storno gescannt werden, so werden diese Scans übersprungen. Genauso werden Anfahrten über 150 km gefiltert, weil dies unplausibel ist.\n
Es wird der Standort der ZSB aus dem Last Mile Administration Tool verwendet. Ist der Standort dort nicht hinterlegt, wird keine Strecke berechnet.`,
  },
  {
    name: 'Anfahrtszeit',
    definition: 'Berechnete Fahrzeit von ZSB bis erstem Stopp.',
    explanation: `Mit einer Routenplanungssoftware wird die benötigte Fahrzeit von der ZSB bis zur Adresse des ersten Stopps berechnet.\n
Die Anfahrtszeit passt zu der berechneten Anfahrtsstrecke. Besonderheiten siehe Erklärungen zur Anfahrtsstrecke.`,
  },
  {
    name: 'Rückfahrtstrecke',
    definition: 'Berechnete Strecke von letztem Stopp bis ZSB.',
    explanation: `Mit einer Routenplanungssoftware wird die benötigte Strecke von der Adresse des letzten Stopps bis zur ZSB berechnet.\n
Sollte am Ende einer Tour RL U, Z, oGGS und Storno gescannt werden, so wird der Stopp davor als letzter Stopp genommen. Genauso werden Rückfahrten über 150 km gefiltert, weil dies unplausibel ist.\n
Es wird der Standort der ZSB aus dem Last Mile Administration Tool verwendet. Ist der Standort dort nicht hinterlegt, wird keine Strecke berechnet.`,
  },
  {
    name: 'Rückfahrtzeit',
    definition: 'Berechnete Fahrzeit von letztem Stopp bis ZSB.',
    explanation: `Mit einer Routenplanungssoftware wird die benötigte Fahrzeit von der Adresse des letzten Stopps bis zur ZSB berechnet.\n
Die Rückfahrtzeit passt zu der berechneten Rückfahrtstrecke. Besonderheiten siehe Erklärungen zur Rückfahrtstrecke.`,
  },
  {
    name: 'Produktivität alt',
    definition: 'Erfolgreich bearbeitete Sendungen pro Netto-Zustellzeit.',
    explanation:
      'Erfolgreiche bearbeitete Sendung pro Stunde (Netto-Zustellzeit alt).\nErfolgreich bearbeitete Sendungen sind: Zugestellte Sendungen, abgeholte Sendungen, PS Abgaben, PS Mitnahmen und ProPS.\nEs gibt neue Definitionen der Produktivität siehe: HT-Produktivität, ATG-Produktivität und PS-Produktivität.',
  },
  {
    name: 'Einsätze (Touren)',
    definition: 'Anzahl aller Touren mit mindestens einem Stopp.',
    explanation:
      'Anzahl der geladenen Touren, von denen mindestens eine Sendung erfolgreich im Tourscanner bearbeitet wurde.',
  },
  {
    name: 'Prognostizierte Sendungseingangsmenge',
    definition:
      'Die zum jeweiligen Tag an der entsprechenden ZSB zu erwartende Sendungseingangsmenge für die Zustellung.',
    explanation:
      'Die Sendungseingangsmenge wird aus der Vertriebsprognose berechnet. Die Prognosen werden regelmäßig (im Wochenturnus) donnerstags erstellt. Unabhängig davon wird die Prognose bei Bekanntwerden relevanter Sachverhalte (z.B. aktualisierte Vertriebsdaten, operative Einflüsse) auch kurzfristig aktualisiert. Die Prognose berücksichtigt KEINE Rückläufer und Liegenbleiber. Es handelt sich um die erwarteten erst- bzw. einmaligen Sendungseingänge für die Zustellung am Standort. Weitere Informationen sind im FAQ unter dem Stichpunkt Mengenprognose zu finden.',
  },
  {
    name: 'Benachrichtigungen',
    definition: 'Anzahl aller Benachrichtigungen zum Sendungsverbleib an den Empfänger.',
    explanation:
      'Es gibt zwei Arten von Benachrichtigungen zum Sendungsverbleib an den Empfänger: Die analoge Benachrichtigungskarte und die digitale Benachrichtigung per Email. Beide Benachrichtigungsarten werden in dieser Kennzahl gezählt.',
  },
  {
    name: 'Digitale Benachrichtigungsquote',
    definition: 'Anteil der digitalen Benachrichtigung an allen Benachrichtigungen.',
    explanation:
      'Es gibt zwei Arten von Benachrichtigungen zum Sendungsverbleib an den Empfänger: Die analoge Benachrichtigungskarte und die digitale Benachrichtigung per Email. Diese Kennzahl gibt an, wie hoch der Anteil der digitalen Benachrichtigung per Email an allen Benachrichtigungen ist.',
  },
  {
    name: 'Wertsendungen auf Tour',
    definition: 'Anzahl aller auf Tour geladenen Wertsendungen.',
    explanation: 'Anzahl der mit einem Wertkennzeichen versehen Sendungen, die auf Tour geladen werden.',
  },
  {
    name: 'Nutzungsquote Foto-Unterschriften',
    definition: 'Anteil der durch ein Foto aufgenommenen Unterschriften an allen Unterschriften.',
    explanation:
      'Es gibt zwei Arten von Nachweisen durch eine Unterschrift von dem Empfänger: Die Unterschrift direkt auf dem Scanner und die Unterschrift dokumentiert durch ein Foto. Diese Kennzahl gibt an, wie hoch der Anteil der Foto-Unterschriften an allen Unterschriften ist.',
  },
  {
    name: 'Bestand',
    definition: 'Anzahl an Warensendungen, die morgens vor dem Tourenausgang an einer ZSB sind.',
    explanation:
      'Bei dieser Kennzahlen werden nur Warensendungen gezählt, also keine Kataloge und Briefe. Zudem werden nur Sendungen an ZSBs und nicht an Depots ausgewiesen.\nDer Bestand wird vier Mal pro Tag in H@V berechnet:\n\t- 08:15 im Operativen Controlling für den aktuellen Tag\n\t- 11:45 im Operativen Controlling für den aktuellen Tag\n\t- 13:45 im Operativen Controlling für den aktuellen Tag\n\t- 03:00 in Sendungsbewegungen der Organisationen für den Vortag',
  },
  {
    name: 'Liegenbleiber',
    definition:
      'Anzahl an Warensendungen an einer ZSB, die weder auf Tour gegangen, noch im Lager oder der AFNUS-Klärung sind.',
    explanation:
      'Bei dieser Kennzahlen werden nur Warensendungen gezählt, also keine Kataloge und Briefe. Zudem werden nur Sendungen an ZSBs und nicht an Depots ausgewiesen.\nDie Liegenbleiber werden drei Mal pro Tag in H@V berechnet:\n\t- 11:45 im Operativen Controlling für den aktuellen Tag\n\t- 13:45 im Operativen Controlling für den aktuellen Tag\n\t- 03:00 in Sendungsbewegungen der Organisationen für den Vortag',
  },
  {
    name: 'Liegenbleiber-Quote',
    definition: 'Anteil der Liegenbleiber am Bestand einer ZSB.',
    explanation:
      'Die Liegenbleiber-Quote ist ab 11:45 mit der ersten Liegenbleiberberechnung verfügbar. Details siehe Liegenbleiber und Bestand.',
  },
  {
    name: 'Liegenbleiber-Quote Eil',
    definition:
      'Anteil der liegengebliebenen Eilwarensendungen am Bestand von Eilsendungen vor Tourenausgang einer ZSB.',
    explanation:
      'Die Liegenbleiber-Quote Eil ist ab 11:45 mit der ersten Liegenbleiberberechnung verfügbar. Details siehe Liegenbleiber und Bestand.',
  },
  {
    name: 'Lagernd-Quote',
    definition: 'Anteil der lagernden Sendungen am Bestand einer ZSB.',
    explanation:
      'Die Lagernd-Quote ist ab 11:45 mit der ersten Berechnung der Lagernden verfügbar. Details siehe Lagernde und Bestand.',
  },
  {
    name: 'Lagernde',
    definition: 'Anzahl an Warensendungen einer ZSB, die sich im dortigen Lager befinden.',
    explanation:
      'Bei dieser Kennzahlen werden nur Warensendungen gezählt, also keine Kataloge und Briefe. Zudem werden nur Sendungen an ZSBs und nicht an Depots ausgewiesen.\nDie lagernden Sendungen werden drei Mal pro Tag in H@V berechnet:\n\t- 11:45 im Operativen Controlling für den aktuellen Tag\n\t- 13:45 im Operativen Controlling für den aktuellen Tag\n\t- 03:00 in Sendungsbewegungen der Organisationen für den Vortag',
  },
  {
    name: 'AFNUS',
    definition: 'Anzahl an Warensendungen einer ZSB, die sich in der dortigen AFNUS-Klärung befinden.',
    explanation:
      'Bei dieser Kennzahlen werden nur Warensendungen gezählt, also keine Kataloge und Briefe. Zudem werden nur Sendungen an ZSBs und nicht an Depots ausgewiesen.\nDie AFNUS-Sendungen werden drei Mal pro Tag in H@V berechnet:\n\t- 11:45 im Operativen Controlling für den aktuellen Tag\n\t- 13:45 im Operativen Controlling für den aktuellen Tag\n\t- 03:00 in Sendungsbewegungen der Organisationen für den Vortag',
  },
  {
    name: 'Zugangsmenge',
    definition: 'Anzahl an Warensendungen, die morgens an die ZSB geliefert werden.',
    explanation:
      'Bei dieser Kennzahlen werden nur Warensendungen gezählt, also keine Kataloge und Briefe. Zudem werden nur Sendungen an ZSBs und nicht an Depots ausgewiesen.\nDie Zugangsmenge wird vier Mal pro Tag in H@V berechnet:\n\t- 08:15 im Operativen Controlling für den aktuellen Tag\n\t- 11:45 im Operativen Controlling für den aktuellen Tag\n\t- 13:45 im Operativen Controlling für den aktuellen Tag\n\t- 03:00 in Sendungsbewegungen der Organisationen für den Vortag',
  },
  {
    name: 'E+0 Standard',
    definition:
      'Anteil an Warensendungen ohne Eilservice für die taggleich zu ihrem ersten Zustellversuch auch der Depoteingang gescannt wurde.',
    explanation:
      'Diese Quote wird rückwärst berechnet. D.h., erst beim Zustellversuch wird geschaut, ob der Depoteingang am gleichen Tag stattfand.\nBei dieser Kennzahlen werden nur Warensendungen (keine Briefe und Kataloge) gezählt. Zudem werden lagernde Sendungen, mehrcollige Sendungen, Umverfügungen und Sendungen mit Teminservice ausgeschlossen.\n"Taggleich" bedeutet hier, dass der Eingang am Depot vor 08:00 morgens stattgefunden hat (an einigen LCs gibt es frühere, individuelle "Tagesgrenzen"). Eingänge nach 08:00 erfüllen auch mit einem Zustellversuch am folgenden Werktag die E+0-Quote.\nDie E+0-Quote wird in einem separaten Tool morgens 08:00 Uhr für die letzten 10 Tage berechnet und dann hier im GU-Dashboard aktualisiert. Aufgrund von neuen Scans kann sich die Quote auch rückwirkend noch leicht verändern.',
  },
  {
    name: 'E+0 Eil',
    definition:
      'Anteil an Warensendungen mit Eilservice für die taggleich zu ihrem ersten Zustellversuch auch der Depoteingang gescannt wurde.',
    explanation: 'Analog zu E+0 nur mit der Filterung auf Sendungen mit Eilservice.',
  },
  {
    name: 'ZSB+0 Standard',
    definition:
      'Anteil an Warensendungen ohne Terminservice für die taggleich zu ihrem ersten Zustellversuch auch der Sendungseingang an der ZSB gescannt wurde.',
    explanation:
      'Diese Quote wird rückwärst berechnet. D.h., erst beim Zustellversuch wird geschaut, ob der Sendungseingang am gleichen Tag stattfand. Bei dieser Kennzahlen werden nur Warensendungen (keine Briefe und Kataloge) gezählt. Zudem werden lagernde Sendungen, mehrcollige Sendungen, Umverfügungen (Ausnahme Wunschablagort) und Sendungen mit Teminservice ausgeschlossen. "Taggleich" bedeutet hier, dass der Sendungseingang an der ZSB und der Zustellversuch am gleichen Datum stattgefunden haben. Die ZSB+0-Quote wird in einem separaten Tool über Nacht für die letzten 10 Tage berechnet und dann hier im GU-Dashboard aktualisiert. Aufgrund von neuen Scans kann sich die Quote auch rückwirkend noch leicht verändern.\n' +
      'Bitte beachten:\n' +
      '1) Vollständige Sendungseingangsscannungen sind nötig. ASE gilt nicht als Sendungseingang-Scan und wird nicht in der Quotenberechnung berücksichtigt.\n' +
      '2) VP+ an HZBs werden nicht qualifiziert dargestellt, wenn die HZB in H@V als ZSB angelegt ist.\n' +
      '3) Quote steht nicht für die VP zur Verfügung.',
  },
  {
    name: 'ZSB+0 Eil',
    definition:
      'Anteil an Warensendungen mit Eilservice für die taggleich zu ihrem ersten Zustellversuch auch der Sendungseingang an der ZSB gescannt wurde.',
    explanation:
      'Siehe Erklärung der ZSB+0 Standard Quote. Bei der Quote ZSB+0 Eil wird entsprechend auf Sendungen mit Eilservice gefiltert.',
  },
  {
    name: 'SB (Meldung)',
    definition:
      'Anzahl der beim Kundendienst gemeldeten Servicebeanstandungen zu Sendungen. Dabei wird die Beschwerde immer dem Datum zugeordnet, an dem sich der Kunde gemeldet hat.',
    explanation:
      'Eine Servicebeanstandung entsteht dadurch, dass sich ein Kunde über den Vorgang zu einer bestimmten Sendung beim Kundenservice beschwert. Als Verursacher einer Servicebeanstandung wird immer automatisch der Standort zugeordnet, der die Sendung vor der letzten Bearbeitung der Beschwerde als letztes bearbeitet hat. Dies muss nicht immer richtig sein und ist im Einzelfall zu klären. Beschwert sich ein Kunde mit dem gleichen Grund zur gleichen Sendung an einem Tag mehrfach, so wird nur die erste Beschwerde als Servicebeanstandung gezählt.\nDer Grund einer Servicebeanstandung wird manuell von einem Mitarbeiter im Customer Service zugeordnet. Die Kennzahl wird jede Nacht für die am Vortag gemeldeten Servicebeanstandungen aktualisiert.',
  },
  {
    name: 'SB-Quote (ZSB/VP+)',
    definition:
      'Quotient aus den gemeldeten Servicebeanstandungen und der Abwicklungsmenge. Steht zur Zeit nur für ZSB und VP+ zur Verfügung, nicht für Depots.',
    explanation:
      'Es wird die Kennzahl SB (Meldung) durch die Abwicklungsmenge geteilt. Dabei ist die Abwicklungsmenge, die Anzahl der kostenwirksamen Sendungen. D.h., alle Sendungen, die zugestellt, abgeholt oder mitgenommen wurden.\nDie SB-Quote ist täglich ab 14:00 für den Vortag verfügbar.',
  },
  {
    name: 'vSB (Meldung)',
    definition:
      'Anzahl der beim Kundendienst gemeldeten verlustrelevanten Servicebeanstandungen zu Sendungen. Dabei wird die Beschwerde immer dem Datum zugeordnet, an dem sich der Kunde gemeldet hat.',
    explanation:
      'Verlustrelevante Servicebeanstandungsgründe sind unklarer Sendungsverbleib, unklarer Abliefernachweis und Inhalt fehlt.',
  },
  {
    name: 'vSB-Quote (ZSB/VP+)',
    definition:
      'Quotient aus den gemeldeten verlustrelevanten Servicebeanstandungen und der Abwicklungsmenge. Hinweis: Steht zur Zeit nur für ZSB und VP+ zur Verfügung. Nicht für Depots.',
    explanation:
      'Es wird die Kennzahl vSB (Meldung) durch die Abwicklungsmenge geteilt. Dabei ist die Abwicklungsmenge, die Anzahl der kostenwirksamen Sendungen. D.h., alle Sendungen, die zugestellt, abgeholt oder mitgenommen wurden.\nDie SB-Quote ist täglich ab 14:00 für den Vortag verfügbar.',
  },
  {
    name: 'Abwicklungsmenge (ZSB/VP+)',
    definition: 'Anzahl der kostenwirksamen Sendungen.',
    explanation:
      'Alle Sendungen, die zugestellt, abgeholt oder mitgenommen wurden.\nDie Abwicklungsmenge ist täglich ab 14:00 für den Vortag verfügbar.',
  },
  {
    name: 'Abwicklungsmenge',
    definition: 'Anzahl der kostenwirksamen Sendungen. Enthält Depot/ZSB/VP+.',
    explanation:
      'Alle Sendungen, die zugestellt, abgeholt oder mitgenommen wurden. Enthält Depot/ZSB/VP+.\nDie Abwicklungsmenge ist täglich ab 14:00 für den Vortag verfügbar.',
  },
  {
    name: 'Tagversetzte Bearbeitung',
    definition: 'Anzahl an Sendungsbearbeitungen auf Tour, die erst nach dem Tag des Tourstarts durchgeführt werden.',
    explanation:
      'Hier wird jede Sendungsbearbeitung (Zustellung, Rücklauf, Mitnahme, Abholung) gezählt, also auch jeder Rücklaufgrund.\n' +
      'Hintergrund: Verbesserte Kundenkommunikation, weil die Empfänger am Tag an dem die Sendung auf Tour geht, auch noch ein Update zu ihrer Sendung erhalten.',
  },
  {
    name: 'Rücklauf A am Wunschtag',
    definition: 'Anzahl an Sendungen oder Abholaufträgen, die an ihrem Wunschtag einen Rücklauf A Scan erhalten.',
    explanation:
      'Dieser Hinweis taucht auch auf, wenn die Sendung anschließend noch korrekt bearbeitet wird.\n' +
      'Hintergrund: Die Empfänger mit Wunschtag wollen das Paket mit sehr hoher Sicherheit auch wirklich an diesem Tag haben.',
  },
  {
    name: 'Außerhalb Hermes Zustellzeit',
    definition:
      'Anzahl an qualifizierten Zustellversuchen beim Empfänger, die vor 07:00 oder nach 20:00 vollzogen wurden.',
    explanation:
      'Qualifizierte Zustellversuche sind Zustellungen (Ausnahme: Briefkasten), Abholungen mit Abholauftrag und die Rücklaufgründe nicht angetroffen, Annahmeverweigerung, falsche Adresse und erfolglose Identifizierung bei Identservice bzw. kein Bargeld bei Nachnahme.\n' +
      'Dementsprechend wird bei Briefkastenzustellung, wilder Mitnahme und den Rückläufen Zeitmange, Umleitung und ohne Gegenscan dieser Hinweis nicht erzeugt.\n' +
      'Hintergrund: Ein Klingeln vor 07:00 oder nach 20:00 bei Empfängern soll laut Hermes-Richtlinie vermieden werden. Daher wird auf diese Sendungen hingewiesen.',
  },
  {
    name: 'Außerhalb ATG-AZF',
    definition:
      '"Außerhalb Auftraggeber-Abholzeitfenster": Anzahl an Abholzeitfenstern bei Auftraggebern, die nicht eingehalten wurden.',
    explanation:
      'Alle Scannungen auf Tour (Ausnahme: RL Z, RL U und RL oGGS) eines ATG-Abholauftrages mit Wunschzeitfensters außerhalb des hinterlegten Zeitfensters führen zu diesem Hinweis.',
  },
  {
    name: 'Außerhalb WZF',
    definition:
      'Außerhalb Wunschzeitfenster": Anzahl an Zustellsendungen und Abholaufträgen (an der Haustür), bei denen das Wunschzeitfenster nicht eingehalten wurde.',
    explanation:
      'Alle Scannungen auf Tour (Ausnahme: RL Z, RL U und RL oGGS) einer Zustellsendung oder eines Haustür-Abholauftrages mit Wunschzeitfensters außerhalb des hinterlegten Zeitfensters führen zu diesem Hinweis.\n' +
      'Anmerkung: Das Wunschzeitfenster gilt im Gegensatz zum ATG-Abholzeitfenster nur an einem bestimmten Tag.',
  },
  {
    name: 'Netto-HT-Zeit',
    definition: 'Zeitaufwand im Tourgebiet für die Zustellung und Abholung an der Haustür.',
    explanation:
      'Damit realistischere Produktivitäten für Misch- und Paketshoptouren berechnet werden können, wird die Netto-Zeit nochmal nach den Prozessen Haustür, Paketshop und Auftraggeberabholungen aufgeteilt.\n' +
      'Die Netto-HT-Zeit ist die Zeit zwischen dem ersten und dem letzten Stopp einer Tour (Stoppbearbeitung und Fahrzeit) ohne Pausen, die für die Bearbeitung von Haustürsendungen benötigt wurde.',
  },
  {
    name: 'Netto-PS-Zeit',
    definition: 'Zeitaufwand im Tourgebiet für die Bearbeitung von Paketshops.',
    explanation:
      'Damit realistischere Produktivitäten für Misch- und Paketshoptouren berechnet werden können, wird die Netto-Zeit nochmal nach den Prozessen Haustür, Paketshop und Auftraggeberabholungen aufgeteilt.\n' +
      'Die Netto-PS-Zeit ist die Zeit zwischen dem ersten und dem letzten Stopp einer Tour (Stoppbearbeitung und Fahrzeit) ohne Pausen, die für die Bearbeitung von Paketshops benötigt wurde.',
  },
  {
    name: 'Netto-ATG-Zeit',
    definition: 'Zeitaufwand im Tourgebiet für die Abholung bei Auftraggebern.',
    explanation:
      'Damit realistischere Produktivitäten für Misch- und Paketshoptouren berechnet werden können, wird die Netto-Zeit nochmal nach den Prozessen Haustür, Paketshop und Auftraggeberabholungen aufgeteilt.\n' +
      'Die Netto-ATG-Zeit ist die Zeit zwischen dem ersten und dem letzten Stopp einer Tour (Stoppbearbeitung und Fahrzeit) ohne Pausen, die für die Bearbeitung von Auftraggebern benötigt wurde.',
  },
  {
    name: 'HT-Produktivität',
    definition: 'Erfolgreiche an der Haustür zugestellte oder abgeholte Sendungen pro Netto-HT-Zeit.',
    explanation:
      'Die Summe aus den Kennzahlen Zustellungen und Abholungen (enthält auch Mitnahmen) geteilt durch die Netto-HT-Zeit.',
  },
  {
    name: 'PS-Produktivität',
    definition: 'Am PS zugestellte oder abgeholte Sendungen pro Netto-PS-Zeit.',
    explanation: 'Die Summe aus den Kennzahlen PS Abgaben und PS Mitnahmen geteilt durch die Netto-PS-Zeit.',
  },
  {
    name: 'ATG-Produktivität',
    definition: 'Beim ATG abgeholte Sendungen pro Netto-ATG-Zeit.',
    explanation: 'Die Kennzahl ATG Abholungen geteilt durch die Netto-HT-Zeit.',
  },
  {
    name: 'Bulky auf Tour',
    definition: 'Anzahl aller auf Tour geladenen Bulkys.',
    explanation:
      'Als Bulky gelten alle Sendungen, bei denen eine Seite >120cm oder zwei Seiten >60cm sind und auf Tour geladen wurden.',
  },
  {
    name: 'Bulky zugestellt',
    definition: 'Anzahl aller auf Tour geladenen und erfolgreich zugestellten Bulkys.',
    explanation:
      'Als Bulky gelten alle Sendungen, bei denen eine Seite >120cm oder zwei Seiten >60cm sind, auf Tour geladen und erfolgreich zugestellt wurden.',
  },
  {
    name: 'Teillieferung (mehrcollig)',
    definition: 'Wenn eine mehrcollige Sendung nur zum Teil zugestellt wird.',
    explanation:
      'Alle Sendungen, die zusammen zugestellt werden sollen (mehrcollig), jedoch nur eine Teillieferung zugestellt wurde führen zu einem Hinweis.',
  },
  {
    name: 'Standardleistungen',
    definition: 'Jede an einer Haustür zugestellte/abgeholte Sendung, welche im jeweiligen Zeitraum zugestellt wurden.',
    explanation:
      'Jede an einer Haustür zugestellte/abgeholte Sendung wird produktunabhängig als Standardleistung vergütet. Die Zustellungsarten Nachbar und (Wunsch-)Ablageort, sowie Abholungen sind hier mit inbegriffen. Für gesondert definierte Produkte wird die Standardleistung um weitere Zu- oder Abschläge ergänzt.\n' +
      'Bezieht sich auf diejenigen Sendungen, welche im jeweiligen Zeitraum zugestellt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'Bulky',
    definition:
      'Sendung, welche die Maße (LxBxH) 120x60x60 überschreiten und im jeweiligen Zeitraum zugestellt wurden.',
    explanation:
      'Als Großstück werden jene Sendungen bezeichnet, welche die Maße (LxBxH) 120x60x60 überschreiten.\n' +
      'Bezieht sich auf diejenigen Sendungen, welche im jeweiligen Zeitraum zugestellt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'Identservice',
    definition:
      'Sendung, welche in der Zustellung eine Identifizierung voraussetzt und im jeweiligen Zeitraum zugestellt wurde.',
    explanation:
      'Sendungen, welche in der Zustellung eine Identifizierung voraussetzen, werden als Identservice vergütet. Bei mehreren Identsendungen für einen identischen Empfänger, wird der Zuschlag nur einmal gezahlt.\n' +
      'Bezieht sich auf diejenigen Sendungen, welche im jeweiligen Zeitraum zugestellt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'Nachnahme',
    definition:
      'Sendung, welche bei der Zustellung oder Abholung einen physischen Zahlvorgang beinhalten und im jeweiligen Zeitraum zugestellt wurden.',
    explanation:
      'Sendungen, welche bei der Zustellung oder Abholung einen physischen Zahlvorgang beinhalten, erhalten den Zuschlag Nachnahme. Bei mehreren Nachnahmesendungen für einen identischen Empfänger, wird dieser Zuschlag nur einmal gezahlt.\n' +
      'Bezieht sich auf diejenigen Sendungen, welche im jeweiligen Zeitraum zugestellt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'Gepäckabholung',
    definition: 'Gepäckabholungen und Gepäckmitnahmen, die im jeweiligen Zeitraum zugestellt wurden.',
    explanation:
      'Gepäckabholungen und Gepäckmitnahmen erhalten den Zuschlag Gepäckabholung. Hierbei erhält jedes Gepäckstück den Zuschlag. Ein Gepäckstück ist nicht mit einem Mitnahmeabschlag kombinierbar.\n' +
      'Bezieht sich auf diejenigen Sendungen, welche im jeweiligen Zeitraum zugestellt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'Zeitfenster',
    definition:
      'Sendung, die zu einem geplanten Zeitfenster innerhalb eines Tages (keine Tageswunschzustellungen) zugestellt wurde.',
    explanation:
      'Sendungen, welche zu einem geplanten Zeitfenster innerhalb eines Tages (keine Tageswunschzustellungen) zugestellt werden, erhalten einen Zuschlag Zeitfenster. Bei mehreren Zeitfensterzustellungen für einen identischen Empfänger, wird dieser Zuschlag nur einmal gezahlt.\n' +
      'Bezieht sich auf diejenigen Sendungen, welche im jeweiligen Zeitraum zugestellt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'zus. Mitnahme',
    definition:
      'Sendung, welche zusätzlich zu einer voll vergüteten Zustellung oder Abholung oder „wild“ während der Tour dem Zusteller mitgegeben wurde und im jeweiligen Zeitraum zugestellt wurde.',
    explanation:
      'Sendungen welche zusätzlich zu einer voll vergüteten Zustellung oder Abholung oder „wild“ während der Tour dem Zusteller mitgegeben werden, erzeugen nur einen sehr geringen Zusatzaufwand und erhalten neben dem Standardpreis einen Mitnahmeabschlag.\n' +
      'Bezieht sich auf diejenigen Sendungen, welche im jeweiligen Zeitraum zugestellt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'Quittungslos',
    definition:
      'Quittungslose Sendung, welche in den Briefkasten eingeworfen werden kann und im jeweiligen Zeitraum zugestellt wurde.',
    explanation:
      'Quittungslose Sendungen, welche in den Briefkasten eingeworfen werden können, erhalten den Abschlag Quittungslos. Der Abschlag kann nicht zusätzlich mit dem Abschlag Coincident kombiniert werden.\n' +
      'Bezieht sich auf diejenigen Sendungen, welche im jeweiligen Zeitraum zugestellt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'Coincident',
    definition:
      'Mehr als eine Sendung, die an den identischen Empfänger gehen und im jeweiligen Zeitraum zugestellt wurde.',
    explanation:
      'Bei mehr als einer Sendung an den identischen Empfänger haben alle weiteren Sendungen nur einen sehr geringen Zusatzaufwand und erhalten einen Coincident-Abschlag. Der Coincident-Abschlag wird nicht auf Mitnahmen und Quittungslose Sendungen angewendet.\n' +
      'Bezieht sich auf diejenigen Sendungen, welche im jeweiligen Zeitraum zugestellt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'RL am Tourende',
    definition: 'Anzahl Touren mit auffällig viel Rücklauf am Tourende.',
    explanation:
      'Hat eine Tour auffällig viele Rückläufer (Ausnahme: Zeitmangel), so wird dies automatisch als Hinweis erkannt. Nicht berücksichtigt hierbei wird der Rücklauf aufgrund von Zeitmangel (RL Z). Das Tourende kann auch tagversetzt vorkommen.\n' +
      'Das Tourende sind hierbei immer die letzten 5 Stopps einer Tour. Die Tour ist auffällig, wenn auf den letzten Stopps mindestens 5 Rückläufer sind.',
  },
  {
    name: 'ATG Sendungen',
    definition:
      'Auftraggebersendungen sind regelmäßig vereinbarte Abholungen, die im jeweiligen Zeitraum erfolgreich abgeholt wurden.',
    explanation:
      'Auftraggebersendungen sind Abholungen bei kleinen und mittelgroßen Auftraggebern mit denen regelmäßige Abholungen vereinbart sind. Es wird die Menge der Sendungen gezählt, die erfolgreich abgeholt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'ATG Stopps',
    definition:
      'Unter Auftraggeber Stopps ist die Anzahl der Stopps gemeint, die im jeweiligen Zeitraum durchgeführt wurden.',
    explanation:
      'Unter Auftraggeber Stopps sind Stopps für Abholungen kleiner und mittelgroßer Auftraggeber gemeint. Es wird nur der Stopp ohne Sendungsmenge gezählt. Bei Leeranfahrt kann der Stopp aus technischen Gründen nicht gezählt werden. Es ist nur die Anzahl der Stopps gemeint, die im jeweiligen Zeitraum durchgeführt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'Eilsendungen',
    definition: 'Sendungsmenge mit Eilservice, die im jeweiligen Zeitraum durchgeführt wurden.',
    explanation:
      'Unter Eilsendungen versteht sich die Sendungsmenge mit Eilservice. Eine übergebene Sendung bedarf einer taggleichen Zustellung. Es wird die Menge an Sendungen gezählt, die im jeweiligen Zeitraum im Rahmen des neuen Vergütungsmodells abgerechnet wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'PS Sendungen',
    definition: 'Paketshop Sendungen, die im jeweiligen Zeitraum durchgeführt wurden.',
    explanation:
      'Paketshop Sendungen, sind sowohl abzuliefernde als auch abzuholende Sendungen am Paketshop, die im jeweiligen Zeitraum durchgeführt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'PS Stopps',
    definition:
      'Paketshop Stopps ist die Anzahl an angefahrenen Paketshops, die im jeweiligen Zeitraum durchgeführt wurden.',
    explanation:
      'Paketshop Stopp ist die Zählmenge an angefahrenen Paketshops, bei welchen mindestens eine Sendung zugestellt oder abgeholt wurde und im jeweiligen Zeitraum durchgeführt wurden.\n' +
      tourExplanationAddition,
  },
  {
    name: 'S&V-Quote (ZSB/VP+)',
    definition:
      'Quotient aus der Anzahl der Regresse und der Abwicklungsmenge (Letzte Meile). Steht zur Zeit nur für ZSB und VP+ zur Verfügung, nicht für Depots.',
    explanation:
      'Die Schaden- und Verlustquote (S+V Quote) zeigt die Entwicklung der jeweils an Hermes Germany GmbH gerichteten monetären Forderungen seitens der Auftraggeber (inbegriffen der Privat-Paket-Service-Versender) auf, welche bei der Leistungserbringung (Sendungszustellung) einen Mangel aufweisen. Als Mangel werden die Kategorien: Totalschaden, Teilschaden, Totalverlust und Teilverlust definiert und zur Regressanzahl-Ermittlung hinzugezogen.\n' +
      'Die Quotenbildung erfolgt auf Basis der Regressanzahl und wird der Abwicklungsmenge (Letzte Meile) gegenübergestellt.\n' +
      'Die S&V-Quote ist täglich ab 14:00 für den Vortag verfügbar.',
  },
  {
    name: 'Nachbarschaftsabgabe Quote',
    definition:
      'Quotient aus der Anzahl der Nachbarschaftsabgaben und der erfolgreichen Zustellungen (enthält keine PS Abgaben & Abholaufträge).',
    explanation:
      'Anteil der Nachbarschaftsabgaben an der gesamten Menge der erfolgreichen Zustellungen (enthält keine PS Abgaben & Abholaufträge).',
  },
  {
    name: 'Uhrzeit Tourfreigabe',
    definition:
      'Uhrzeit zu der ein*e Abwickler*In einem*r Zusteller*in die Freigabe erteilt hat seine*ihre heutige Tour zu starten.',
    explanation:
      'Die Freigabe erfolgt dadurch, dass ein*e Abwickler*In mit Delta einen Barcode scannt, in dem die notwendigen Berechtigungen für diese Freigabe enthalten sind. Da eine Tour an einem Tag mehrfach freigegeben werden kann, ist als Uhrzeit der Tourfreigabe der Zeitpunkt des letztmaligen Barcode-Scans für die jeweilige Tour definiert.',
  },
  {
    name: 'Altgerätemitnahme',
    definition:
      'Anzahl aller auf Tour mitgenommenen Altgeräte, die in Verbindung mit der Zustellung eines neuen Elektrogeräts mitgegeben werden. Diese Bestimmung gilt für bestimmte Produktgruppen.',
    explanation:
      'Händler müssen für gewisse Elektrokleingeräte in unmittelbarer Nähe zur Haustür eine kostenlose Rücknahme von Altgeräten anbieten, wenn ein Neugerät ausgeliefert wird. Hermes setzt diesen Service für OTTO um. Bei der Zustellung wird ein Paket mit einem neuen Elektrogerät übergeben. Im Anschluss wird das alte, unverpackte Elektrogerät mitgenommen.',
  },
  {
    name: 'Tour-Stopps-ATG',
    definition: 'Anzahl der Stops einer Tour die als ATG-Stopp eingestuft wurden.',
    explanation: 'Erklärung in Arbeit',
  },
  {
    name: 'Tour-Stopps-PS',
    definition: 'Anzahl der Stops einer Tour die als PS-Stopp eingestuft wurden.',
    explanation: 'Erklärung in Arbeit',
  },
  {
    name: 'Tour-Stopps-HT',
    definition: 'Anzahl der Stops einer Tour die als HT-Stopp eingestuft wurden.',
    explanation: 'Erklärung in Arbeit',
  },
  {
    name: 'RF-Einhaltung',
    definition: 'Anteil an Stopps (Adressen) bei denen die in Routemaster geplante Reihenfolge eingehalten wurde.',
    explanation:
      'Am Ende einer Tour wird bewertet, wie die in Routemaster geplante Reihenfolge und die tatsächlich gefahrene Reihenfolge übereinstimmen.' +
      '<ul>' +
      '<li>Bei dieser Definition entspricht ein Stopp einer Adresse (alle Sendungen mit der gleichen Zieladresse werden zusammen gefasst). Damit hat die Scan-Reihenfolge von Sendungen an der gleichen Adresse keine Auswirkungen auf die KPI.</li>' +
      '<li>Für jeden Stopp wird die geplante Reihenfolgenummer und die Reihenfolge des jeweils ersten Scans miteinander verglichen. Ist die Abweichung der Reihenfolgenummer kleiner als ein Zwölftel der Stoppanzahl (oder bei kurzen Touren kleiner als 5), dann gilt die Reihenfolge für diesen Stopp als eingehalten.</li>' +
      '<li>Touren mit weniger als 12 Stopps erhalten keine Bewertung.</li>' +
      '<li>Sendungen, die nicht verplant wurden (z.B. fehlende Geokoordinate) oder keinen Zustellversuch hatten (z.B. RL Z, RL U oder RL F) haben keinen Einfluss auf die Reihenfolgeeinhaltung.</li>' +
      '</ul>\n',
  },
];

const definitionMap = new Map<KpiName, KpiDefinitionWithoutName>();
definitions.forEach((d) =>
  definitionMap.set(d.name, {
    definition: d.definition,
    explanation: d.explanation,
  })
);

export const getDefinitionByName = (name: KpiName): KpiDefinitionWithoutName | undefined => {
  return definitionMap.get(name) || undefined;
};
