import React, { useCallback } from 'react';
import Styles from './ZoomControl.module.scss';
import Mapbox from 'mapbox-gl';

type Props = {
  map: Mapbox.Map;
  zoomDiff?: number;
};

const ZoomControl = ({ map, zoomDiff = 0.5 }: Props) => {
  const onControlClick = useCallback((map: Mapbox.Map, zoomDiff: number) => {
    map.zoomTo(map.getZoom() + zoomDiff);
  }, []);

  const onClickPlus = useCallback(() => {
    onControlClick(map, zoomDiff);
  }, [map, onControlClick, zoomDiff]);

  const onClickMinus = useCallback(() => {
    onControlClick(map, -zoomDiff);
  }, [map, onControlClick, zoomDiff]);

  return (
    <div className={Styles.Wrapper}>
      <button onClick={onClickPlus} className={Styles.Button}>
        +
      </button>
      <button onClick={onClickMinus} className={Styles.Button}>
        −
      </button>
    </div>
  );
};

export { ZoomControl };
