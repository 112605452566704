import pako from 'pako';
import Logger from '../../../utils/logging/Logger';

export default class CompressedMessageHandler {
  _logger: Logger = Logger.getInstance('CompressedMessageHandler');
  _defaultAlgorithm = 'deflate';
  _handlers: {
    [key: string]: (arg0: string) => string;
  } = {
    deflate: (i) => pako.inflate(i, { to: 'string' }),
  };

  registerHandler(type: string, handler: (arg0: string) => string): void {
    this._handlers[type] = handler;
  }

  handle(payload: { content?: string; algorithm: string }): string {
    if (!payload.content) {
      throw Error('Compressed message has no content.');
    }
    const algorithm = payload.algorithm || this._defaultAlgorithm;
    return this._decompress(payload.content, algorithm);
  }

  _decompress(content: string, algorithm: string): string {
    try {
      const rawContent = atob(content);
      const result = this._handlers[algorithm](rawContent);
      return result;
    } catch (e) {
      this._logger.error('Error while decoding compressed server response', e);
    }
  }
}
