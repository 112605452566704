export type FaqItemType = {
  question: string;
  answer: string;
};

export type FaqSectionType = {
  [key: string]: Array<FaqItemType>;
};

export type FaqData = Array<FaqSectionType>;

/* eslint-disable max-len */
export const faqData: FaqData = [
  {
    'GU Dashboard Allgemein': [
      {
        question: 'Wie erfahre ich von Updates im Dashboard?',
        answer:
          'Updates werden automatisch im GU-Dashboard über ein Pop-Up-Fenster angezeigt, so bekommt Ihr immer automatisch mit, wenn es etwas Neues gibt. Bei jedem größerem Update des GU-Dashboards informieren wir zusätzlich alle Nutzer per Email über Neuerungen. Alle angemeldeten externen Nutzer (GU, VP, etc.) erhalten diese Email automatisch. Alle HermesMitarbeiter müssen sich für den entsprechenden Email-Verteiler anmelden (<a href="https://helios.prd.otc.hlg.de/anwendungen/abonnierbare-mailverteiler/">https://helios.prd.otc.hlg.de/anwendungen/abonnierbare-mailverteiler/</a>). ',
      },
      {
        question: 'Was mache ich bei Problemen bei der Anmeldung oder wenn ich neue Zugänge, beantragen möchte?',
        answer:
          'Bei allen Themen rund um den Log-In kannst Du Dich an <a href="mailto:LM-Benutzerverwaltung@hermesworld.com">LM-Benutzerverwaltung@hermesworld.com</a> wenden. Da die Log-In-Daten gleichzeitig auch für das ZSB Cockpit genutzt werden können, gibt es hierfür ein gesondertes Postfach. Für diese Themen wird es bald auch ein gesondertes FAQ geben.',
      },
      {
        question: 'Ich benutze das GU-Dashboard zum ersten Mal. Wo finde ich Anleitungen und Schulungsunterlagen?',
        answer:
          'Innerhalb der Sidebar ist im unteren Bereich ein Hilfe Button platziert, welcher das Hilfe-Menü öffnet. Hier sind neben diesem FAQ auch die aktuellste Schulungsunterlage, eine Erklärung der Kennzahlen sowie alle Updateinformationen zu finden. Für weitere Fragen kannst Du dich an <a href="mailto:lma@hermesworld.com">lma@hermesworld.com</a> wenden.',
      },
      {
        question: 'Was kann ich tun, wenn mir ein Fehler auffällt?',
        answer:
          'Wenn es im Tool Fehler in den Daten gibt, melden wir diese in der Regel direkt im Tool. Es gibt dann ein Icon oben rechts, das entweder rot (Fehler mit großen Auswirkungen), gelb (Hinweis) oder blau (Information) sein kann. Ist der entdeckte Fehler dort nicht aufgelistet, schreibe bitte sofort eine Email an <a href="mailto:lma@hermesworld.com">lma@hermesworld.com</a>, damit wir diesen Fall schnellstmöglich analysieren und ggfs. andere Nutzer informieren können. ',
      },
      {
        question: 'Was kann ich tun, wenn ich Verbesserungsvorschläge habe?',
        answer:
          'Das GU-Dashboard wird ständig weiterentwickelt und wird nur mit Deinem Input auch zu dem Tool, das Dir hilft. Melde uns Deinen Vorschlag mit der Beschreibung wie und wozu Du ihn nutzen würdest an <a href="mailto:lma@hermesworld.com">lma@hermesworld.com</a>. Auch wenn wir nicht alles sofort umsetzen können, so lebt die Weiterentwicklung auch von Deinem Input. ',
      },
      {
        question: 'Wie aktuell sind die Daten im GU-Dashboard?',
        answer:
          'In der Regel dauert es weniger als 15 Minuten von der Bearbeitung eines Stopps im Scanner bis zur Verarbeitung und Anzeige der entsprechenden Daten im GU-Dashboard.',
      },
      {
        question: 'Welche Standorte werden im GU-Dashboard angezeigt?',
        answer:
          'Im GU-Dashboard sind alle Standorte, die laut H@V gültig sind. Das heißt, neue Standorte erscheinen nach dem Beginn des Betriebs automatisch im Tool und geschlossene Standorte werden einen Monat nach ihrer Deaktivierung in H@V nicht mehr angezeigt. ',
      },
      {
        question: 'Wo finde ich den Vergleichszeitraum?',
        answer:
          'Bei vielen Kennzahlen und auf vielen Seiten wird eine Unterscheidung zum vorherigen Zeitraum durch eine grüne bzw. rote Zahl angezeigt. Den genauen Zeitraum für diesen Vergleich erhältst Du, wenn Du die Maus über diese rote/grüne Zahl legst.',
      },
      {
        question: 'Warum wird mir eine Tour im GU-Dashboard nicht angezeigt?',
        answer:
          'Im Gegensatz zu H@V zählt das GU-Dashboard eine Tour erst, wenn der erste Stopp bearbeitet wurde. Spätestens 15 Minuten nach der ersten Stoppbearbeitung sollte die Tour im Tool auftauchen. Zudem kann es sein, dass die Tour in einem Gebiet mit schlechter Internetverbindung unterwegs ist oder der Scanner einen technischen Defekt hat, wodurch die Datenübertragung nicht funktioniert. Wenn es immer bei der gleichen Tour passiert, kannst Du dafür sorgen, dass der Fahrer einen anderen Scanner benutzt. Dies hat dieses Phänomen schon bei anderen Touren behoben. Kannst Du alle diese Fälle ausschließen, dann melde diese Tour an <a href="mailto:lma@hermesworld.com">lma@hermesworld.com</a> und wir schauen uns das genauer an.',
      },
      {
        question: 'Können auch Vertragspartner in das Tool integriert werden?',
        answer:
          'Aktuell können wir das Tool nur jenen Standorten zur Verfügung stellen, die eine eigene Organisations-ID haben. Dazu zählen ZSB und VP+. Wir sind bestrebt auch die VP ohne eigene Org-ID in das Tool zu implementieren. Der zeitliche Horizont hierbei ist allerdings offen, weil wir u.a. auf die Zuarbeit eines anderen Teams angewiesen sind (eigene Abrechnungsnummer für Vertragspartner).',
      },
      {
        question: 'Kann ich in der Tourliste nach Tourart (reine Haustür-Tour, Mischtour oder PSTour) filtern?',
        answer:
          'Wir wissen, dass der Vergleich von Haustür-Touren und PS-Touren in der Regel nicht sinnvoll ist, weshalb wir eine Filterfunktion nach Tourart planen. Aktuell ist dies jedoch nicht möglich. ',
      },
    ],
  },

  {
    Kennzahlen: [
      {
        question: 'Warum werden in LMA manchmal mehr Rückläufer oder Zustellungen angezeigt als es wirklich waren?',
        answer:
          'Dies ist ein technisches Problem der aktuellen Version des GU-Dashboards. Im Tool wird bisher jeder Scan gezählt. Das heißt, wenn ein Fahrer eine Sendung mehrfach bearbeitet, wird diese Sendung mehrfach gezählt. Ein Beispiel: Der Fahrer scannt eine Sendung erst in den Rücklauf, schafft es dann aber doch noch das Paket zuzustellen. Dann wird diese Sendung im GUDashboard weiterhin als Rückläufer gezählt. Dies ist fachlich natürlich falsch und stört das Team hinter dem GU-Dashboard genauso wie die Benutzer. Leider ist dies ein komplizierter und umfangreicher Umbau des Systems, der allerdings mit höchster Priorität auf die zeitnahe Umsetzung wartet.',
      },
      {
        question: 'Kann ich die Zustellzeit zur Arbeitszeiterfassung verwenden?',
        answer:
          'Nein, die Zustellzeit ist nur ein Teil der Arbeitszeit. Sie zählt nur vom ersten bis zum letzten (taggleichen) Stopp. Somit ist die Rüstzeit (Sortierung und Fahrzeugbeladung), Anfahrt, Rückfahrt und Rücklaufrückgabe nicht enthalten.',
      },
      {
        question: 'Wie funktioniert die Pausenerkennung bei der Netto-Zustellzeit?',
        answer:
          'Es handelt sich nicht um eine echte Pausenerkennung, sondern es wurde ein Verfahren von bei Hermes bereits bestehenden Berichten beibehalten: Wenn über 20 Minuten keine Scannung stattfindet, wird dies als Pause definiert und nicht gezählt, obwohl der Fahrer in der Zeit durchaus gearbeitet haben kann (z.B. lange Wege zwischen den Stopps oder umfangreiche Stopps). Diese Annahme ist insbesondere bei Paketshoptouren nicht sinnvoll, daher wird bereits eine Anpassung der Definition diskutiert. ',
      },
      {
        question: 'Warum weicht die Kennzahl Produktivität von SE/h in anderen Berichten ab?',
        answer:
          'In unserem Tool berechnen wir die Produktivität mit erfolgreich bearbeiteten Sendungen pro Stunde (Netto-Zustellzeit). Erfolgreich bearbeitete Sendungen sind: Zugestellte Sendungen, abgeholte Sendungen, PS Abgaben, PS Mitnahmen und ProPS-Abholungen. Andere Berichte nutzen für die Berechnung ggf. nicht die Netto-Zustellzeit oder berücksichtigen andere Parameter. Definitionen zu jeder Kennzahl findest Du im Hilfe-Menü.',
      },
    ],
  },

  {
    Servicebeanstandungen: [
      {
        question: 'Wie wird der Grund einer Servicebeanstandung erfasst?',
        answer:
          'Eine Servicebeanstandung entsteht durch eine Beschwerde eines Kunden beim Customer Service von Hermes. Auf Basis der Beschreibung des Kunden entscheidet sich der bearbeitende Kollege im Customer Service für einen Grund.',
      },
      {
        question: 'Wie wird eine Servicebeanstandung einem Standort oder einer Tour zugewiesen?',
        answer:
          'Als Verursacher einer Servicebeanstandung wird bei Hermes automatisch der Standort/die Tour zugeordnet, der/die die Sendung vor der Bearbeitung der Servicebeanstandung in KISS als letztes gescannt hat. Dieser Scan wird in der Detailtabelle zu Servicebeanstandungen ausgewiesen (Spalte Status). Dies muss nicht immer richtig sein und ist im Einzelfall zu klären. Diese Einzelfallprüfung wird mit der verknüpften Sendungshistorie unterstützt. ',
      },
      {
        question:
          'Warum passt der Status und die zugeordnete Tour einer Sendung mit Servicebeanstandung manchmal nicht zum Standort?',
        answer:
          'Bei der Aufnahme einer Servicebeanstandung können die Kollegen im Customer Service den zugewiesenen Standort ändern. Dies passiert, wenn aufgrund der Beschreibung des Kunden die Vermutung naheliegt, dass nicht der Standort, der die Sendung als letztes bearbeitet hat, für die Beschwerde verantwortlich ist. In diesem Fall wird allerdings nur der Standort geändert, nicht jedoch die Tournummer oder der verursachende Scan. In diesem Fall kommt es in der Regel zu fehlerhaften Tourzuordnungen. Allerdings passiert so eine Standortänderung durch den Customer Service nur selten.',
      },
      {
        question: 'Wann werden die Servicebeanstandungen im GU-Dashboard aktualisiert?',
        answer:
          'Die Servicebeanstandungen, die an einem bestimmten Tag gemeldet wurden, werden in der folgenden Nacht um ca. 03:00 in das GU-Dashboard eingespielt. Ausnahme: Am Samstagnachmittag gemeldete Servicebeanstandungen sind erst am Montagmorgen im GU-Dashboard verfügbar.',
      },
      {
        question: 'An wen kann ich mich bei weiteren Fragen zu den Servicebeanstandungen wenden?',
        answer:
          'Für das Reporting der Servicebeanstandungen ist die Abteilung Quality bei Hermes verantwortlich. Bei weiteren Fragen wende Dich gern an <a href="mailto:Quality_Reporting@hermesworld.com">Quality_Reporting@hermesworld.com</a>.',
      },
    ],
  },

  {
    'Karte und Stopps': [
      {
        question: 'Kann ich alle Stopps live verfolgen?',
        answer:
          'Nicht alle Informationen eines Stopps werden sofort bei der Durchführung an alle weiteren Systeme gemeldet. Einige Daten werden erst bei Abschluss der Tour übermittelt. Im GU-Dashboard hat dies zur Zeit folgende Auswirkungen: \n▪ Die Information, dass eine Sendung per Vollmacht zugestellt wurde, ist erst nach Tourabschluss ersichtlich \n▪ In einigen Spezialfällen (z.B.: Originalempfänger und Nachbarschaftszustellung in einem Stopp) wird die Information über die BNK an den Nachbarn erst bei Tourabschluss übermittelt\n▪ Folgende Stopps können generell erst nach Tourenabschluss eingesehen werden: Mitnahme von Retouren, Zustellung im Briefkasten',
      },
      {
        question: 'Wieso passen die Kennzahlen einer Tour manchmal nicht zur Anzeige der Tour in der Karte?',
        answer:
          'Im GU-Dashboard sind die Berechnung der Kennzahlen und die Darstellung in der Karte technisch zwei verschiedene Prozesse. Dadurch kann es in der Live-Betrachtung einer Tour zu leichten Unterschieden zwischen Kennzahl und Karte kommen. Je nach aktueller Last im System, kann der eine oder der andere Prozess schneller sein. Scannt ein Fahrer zum Beispiel eine Sendung auf Rücklauf, kann es sein, dass Rücklauf um 1 hochgezählt wird, bevor der Stopp auf der Karte zu sehen ist und genauso andersherum, dass der Stopp zu sehen ist, bevor er hochgezählt wird.',
      },
      {
        question: 'Kann ich alle Stopps live verfolgen?',
        answer:
          'Nein, die Daten von Briefkastenzustellungen und Mitnahmen von Retouren sind zur Zeit erst nach Tourabschluss verfügbar.',
      },
      {
        question: 'Warum haben nicht alle Stopps eine GPS-Koordinate?',
        answer:
          'Der Delta-Scanner erfasst theoretisch bei jedem Stopp die dazugehörige GPS-Position. Diese Position wird dann im GU-Dashboard auf der Karte dargestellt. In der Praxis zeigt sich allerdings, dass es nicht immer möglich ist eine Postion zu erfassen, die genau genug ist (aktuell gibt es hier einen festen Filter auf eine Genauigkeit des Signals von 50 Meter). Dies liegt in den meisten Fällen an einem zu schwachen Mobilfunknetz kombiniert mit schlechtem GPS-Empfang (zum Beispiel, wenn sich der Zusteller in einem Gebäude befindet). Zudem ist die Erfassung des GPS-Signals ausgeschaltet, wenn der Akku des Scanners unter 15% ist (Energiesparmodus).',
      },
      {
        question: 'Warum haben nicht alle Stopps eine Adress-Position und warum ist diese manchmal falsch?',
        answer:
          'Die Position in der Karte bekommen wird nicht vom Auftraggeber übermittelt, sondern Hermes muss diese durch ein System aus der Adresse automatisch erzeugen lassen. Ähnlich wie man in Google Maps nach Eingabe einer Adresse eine Position in der Karte angezeigt bekommt. Es gibt verschiedene Gründe warum es hierbei zu falschen oder fehlenden Positionen kommen kann: \n▪ Die vom Auftraggeber übermittelte Adresse enthält Tippfehler oder ist zu stark abgekürzt. \n▪ Die vom Auftraggeber übermittelte Adresse ist unvollständig (z.B. Hausnummer oder Straßenname fehlt). \n▪ Die Adresse ist nicht eindeutig (häufig bei Eingemeindungen: Es gibt Gebiete in Deutschland, in denen der gleiche Straßenname in einem Postleitzahlgebiet mehrfach vorkommt). \n▪ Das System, das Hermes zur Ermittlung der Positionen verwendet, kennt die Adresse nicht (häufig bei Neubaugebieten, aber manchmal leider auch bei schon länger existierenden Adressen). \nAuch wenn die Adresse zu 98% richtig in der Karte angezeigt wird, so gibt es doch ein paar Fehler. Zur Sicherheit kannst Du die Adresse im Zweifel mit einem anderen System (z.B. Google Maps) abgleichen. ',
      },
      {
        question: 'Warum haben einige Stopps deutliche Ausreißer auf der Karte?',
        answer:
          'Hierfür gibt es zwei Möglichkeiten: Entweder der Scanner konnte keine GPS-Position für den Stopp aus o.g. Gründen mitliefern und die stattdessen dargestellte Adresskoordinate hat eine schlechte Qualität oder es ist ganz simpel ein Irrläufer auf der Tour.',
      },
      {
        question: 'Warum finde ich einen Stopp nicht in der Karte?',
        answer:
          'Auf der Karte kann ein Stopp nur angezeigt werden, wenn eine GPS-Position des Fahrers oder eine Geokoordinate der Adresse vorliegt. In sehr seltenen Fällen (weniger als 1%) ist beides nicht vorhanden. Dieser Stopp wird allerdings trotzdem in der Liste aller Stopps angezeigt.',
      },
      {
        question: 'Warum sind auf einigen Touren gar keine GPS-Koordinaten verfügbar?',
        answer:
          'Möglicherweise ist die SIM-Karte im Scanner nicht aktiv, so dass wir keine GPS-Koordinaten erhalten und somit auch nicht darstellen können. Bitte überprüfe im Scanner die Aktivität der SIM-Karte.',
      },
      {
        question: 'Warum sind manche Stopps noch offen, obwohl der Fahrer sie bereits bearbeitet hat?',
        answer:
          'Im GU-Dashboard werden alle Sendungen als offen dargestellt, die auf Tourenausgang gescannt und auf der Tour noch bisher nicht bearbeitet wurden. Bei einigen Stopps erhalten wir die Informationen jedoch erst bei Tourenabschluss, sodass der Stopp bis dahin offen bleibt (siehe erste Frage dieses Abschnittes). ',
      },
      {
        question: 'Warum sind manche Stopps auch nach Tourabschluss noch offen?',
        answer:
          'Dies ist ein Zeichen dafür, dass diese Stopps nicht richtig vom Zusteller bearbeitet worden sind. Zwei verbreitete Optionen: \n▪ Gar keine Bearbeitung der Sendung auf Tour: Diese Sendung wird beim Tourabschluss mit Rücklauf ohne Gegenscan markiert. \n▪ Diese Sendung wurde unrechtmäßig am Paketshop abgegeben.',
      },
    ],
  },

  {
    Mengenprognose: [
      {
        question: 'Wie erreiche ich das Team Mengenprognose?',
        answer:
          'Für Fragen und Anregungen zum das Thema Mengenprognose (Inhalt und Darstellung) haben wir ein email-Sammelkonto eingerichtet. Schickt Eure Emails bitte an: <a href="mailto:MP-Coreteam@hermesworld.com">MP-Coreteam@hermesworld.com</a>',
      },
      {
        question: 'Wann wird die Sendungseingangsmengenprognose aktualisiert?',
        answer:
          'Die regelmäßige Aktualisierung der Mengenprognose durch das Team Mengenprognose erfolgt monatlich zum Anfang des Monats. Sofern darüber hinaus neue Erkenntnisse aus Vertrieb bzw. Operations eine signifikante Prognoseveränderung ausweisen, kann eine außerplanmäßige Aktualisierung der Zahlen jederzeit erfolgen.',
      },
      {
        question: 'Welchen Nutzen bringt mir die ZSB Prognose?',
        answer:
          'Die kurz-, mittel- und langfristigen ZSB Prognosen ermöglichen eine bessere Planung in verschiedenen Bereichen. z.B.: \n• Personaleinsatzplanung \n• Urlaubsplanung \n• Personalaufbauplanung \n• Fahrzeugbeschaffungsplanung',
      },
      {
        question: 'Wie werden die ZSB Prognosewerte ermittelt?',
        answer:
          'Die ZSB-Prognosedaten werden derzeit mittels eines vergangenheitsbasierten Verteilschlüssel aus den monatlichen Vertriebsplanmengen berechnet. Die Schlüsselung der Monatsmengen erfolgt je Tag, Auftraggeber und ZSB unter Mitberücksichtigung folgender Punkte: \n• Regionale und bundesweite Feiertage \n• Geplante (gemeldete!) Gebietsverschiebungen ',
      },
      {
        question: 'Warum passen die Prognosewerte nicht zu meinem Tourausgangsbestand?',
        answer:
          'Die Prognose stellt eine Schätzung nur für Sendungs(neu)zugänge dar. In den Tourausgangsbestand fließen neben dem eigentlichen Sendungs(neu)zugang auch der Anfangsbestand (z.B. Liegenbleiber des Vortages) sowie Rückläufer und Rückversendungen des Tages ein. ',
      },
      {
        question: 'Warum trifft die Prognose die ZSB-IST-Werte oft ziemlich gut und manchmal richtig schlecht?',
        answer:
          'Bei der Prognose handelt es sich um eine theoretische Sendungsmenge, der unter anderem folgende Annahmen voraussetzt: \n• Operative Abläufe (Umschlag, Hauptlauf, Feeder-Verkehr) funktionieren reibungslos und wie geplant \n• Die vom Vertrieb bzw. den Auftraggebern gemeldeten Wochen- und Monatsmengen treffen zu \n• Die Gesamtmenge verteilt sich auch zukünftig so wie in der Vergangenheit auf die ZSB\nSobald diese Annahmen in der Realität nicht mehr zutreffen, kann dies dazu führen, dass: \n• Mengenankünfte sich tageweise verschieben \n• Sendungsmengen einzelner Auftraggeber ausbleiben \n• Relevante Mengenverschiebungen zwischen ZSB auftreten',
      },
      {
        question: 'Welche Features werden noch umgesetzt?',
        answer:
          'Unter anderem werden folgende wichtige Features noch umgesetzt: • Sendungsforecast für den Folgetag (Welche konkreten Sendungen/Sendungsmengen erwarte ich morgen?) \n• Langfristige Prognose (Prognosehorizont 12 Monate) auf Wochen- bzw. Monatsbasis. \n• Darstellung des SOLL/IST-Abgleichs \n• Integration weiterer Informationsquellen zur Verbesserung der Prognosequalität insgesamt und auf Standortebene',
      },
      {
        question: 'Warum ist die Prognose auf Wochenebene oft besser als auf Tagesebene?',
        answer:
          'Auf Tageseben treten an den ZSB Schwankungen gegenüber den geplanten Sendungsmengen sowohl nach oben als auch unten auf. Über die gesamte Woche betrachtet können sich diese Schwankungen ausgleichen, so dass die durchschnittliche Wochenabweichung sogar unter der besten Tagesabweichung liegen kann. In der Regel gilt: Je weiter man die Mengen zusammenfasst (Tag→Woche → Monat→ Jahr), desto genauer wird die Prognose. ',
      },
      {
        question: 'Warum ist die Tagesprognose auf Depot- oder Area-Ebene meist genauer als die ZSB Prognose?',
        answer:
          'Auf ZSB Ebene treten Schwankungen gegenüber den geplanten Sendungsmengen sowohl nach oben als auch unten auf. Durch die Zusammenfassung der Mengen auf Depot- bzw. Area-Ebene können sich diese Schwankungen ausgleichen, so dass die durchschnittliche Depot-/Area-Abweichung sogar unter der besten ZSB-Abweichung liegen kann. In der Regel gilt: Je weiter man die Mengen zusammenfasst (ZSB → Depot → Area → Deutschland), desto genauer wird die Prognose. ',
      },
      {
        question: 'Warum sind die Prognosen für neue ZSB anfangs oft ungenau?',
        answer:
          'Für neue ZSB liegen üblicherweise noch keine historischen Sendungseingangsmengen vor. Daher können für diese ZSB keine verlässlichen Verteilschlüssel bestimmt werden und die Gesamtprognosemenge wird ungenau auf die einzelnen ZSB verteilt. Unter Umständen haben die ungenauen Verteilschlüssel für eine neue ZSB auch Einfluss auf die prognostizierten Mengen für andere ZSB des gleichen Depots, so dass mit Einführung einer  neuen ZSB kurzfristig auch die Prognosequalität der „Schwester“-ZSB zurückgehen kann. Dieser Effekt sollte aber spätestens nach 2 Wochen nicht mehr spürbar sein. ',
      },
      {
        question: 'Ab wann bekommen neue ZSBs das erste Mal eine Prognose?',
        answer:
          'Der Algorithmus kann für neue Standorte erst automatisiert Prognosen berechnen, nachdem diese bereits 2-3 Wochen im Betrieb sind. Wenn bereits früher Prognosen gebraucht werden, können neue Standorte beim Mengenprognose-Team gemeldet werden (<a href="mailto:MP-Coreteam@hermesworld.com">MP-Coreteam@hermesworld.com</a>).Das Team benötigt dann die Information, wie viel Menge von welchem/n Standort(en) die ZSB übernehmen wird.',
      },
    ],
  },
];
/* eslint-enable max-len */
