import DataConnectionsService from '@legacy-modules/dataconnection/services/DataConnectionsService';
import React, { createContext, PropsWithChildren, ReactElement, useContext } from 'react';

export type DataConnectionContextType = DataConnectionsService;

const defaultValue: DataConnectionContextType = null;
export const DataConnectionContext = createContext(defaultValue);

export const useDataConnectionContext = (): DataConnectionContextType => {
  return useContext<DataConnectionContextType>(DataConnectionContext);
};

type DataConnectionContextProviderProps = Required<
  PropsWithChildren<{ dataConnectionsService: DataConnectionsService }>
>;

export default function DataConnectionContextProvider({
  children,
  dataConnectionsService,
}: DataConnectionContextProviderProps): ReactElement {
  return <DataConnectionContext.Provider value={dataConnectionsService}>{children}</DataConnectionContext.Provider>;
}
