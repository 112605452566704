import TourDetailsStatsContainer from '../../tour/containers/TourDetailsStatsContainer';
import React from 'react';
import Styles from './TourPage.module.scss';
import TourDetailSubHeader from '../../tour/containers/TourDetailSubHeader';
import SignaturesContainer from '../../tour/containers/SignaturesContainer';
import AuthGuard from '../../auth/components/AuthGuard';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import SingleTourOverTime from '../../tour/containers/SingleTourOverTime';
import { TourDetailsMap } from '../../../refactoring/other/tour-details-map';
import { useTourDetailsContext } from '@other-components/tour-details-context';

type Props = {};

const TourPage: React.FC<Props> = () => {
  const {
    signaturesState: [signatureIsOpen],
    tourInTimeState: [tourInTimeOpen],
  } = useTourDetailsContext();

  return (
    <>
      <div className={Styles.TourPage}>
        <div className='subheader-wrapper'>
          <TourDetailSubHeader />
        </div>
        <TourDetailsStatsContainer />
        <TourDetailsMap />
      </div>
      <AuthGuard requiredPermissions={[WellKnownPermission.TourReport]}>
        {signatureIsOpen && (
          <div className='tour-intime-container'>
            <SignaturesContainer />
          </div>
        )}
      </AuthGuard>
      <AuthGuard requiredPermissions={[WellKnownPermission.TourSeeDetails]}>
        {tourInTimeOpen && (
          <div>
            <div className='tour-intime-container'>
              <SingleTourOverTime />
            </div>
          </div>
        )}
      </AuthGuard>
    </>
  );
};

export { TourPage };
