import BasePayload from '../BasePayload';
import { DefaultType, MemberType } from '../../../../../utils/Model';
import { PlainObjectOf } from '../../../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export default class StateFetchResponsePayload extends BasePayload<StateFetchResponsePayload> {
  content: string | null | undefined;

  constructor(source: PlainObjectOf<StateFetchResponsePayload>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getContentAsObject<T>(): T | null | undefined {
    if (!this.content) {
      return null;
    }
    return JSON.parse(this.content);
  }

  getDefaults(): DefaultType<StateFetchResponsePayload> {
    return undefined;
  }

  getMembers(): MemberType<StateFetchResponsePayload> {
    return undefined;
  }
}
