import { useApiContext } from '@contexts/api-context';
import { KnownWebsocketEvent } from '@contexts/api-context/request.types';
import { OrgKey } from '@data-table/data-table.types';
import { injectContractorViewMode } from '@legacy-modules/dashboard/utils/OrgKeyUtils';
import { ContractorViewMode } from '@legacy-modules/navigation/constants/ContractorViewMode';
import MetricsResponsePayload from '@legacy-modules/tour/models/websocket/MetricsResponsePayload';
import { useQuery, UseQueryResult } from 'react-query';

export default function useTourReport(
  orgKey: OrgKey,
  from: string,
  until: string,
  contractorKey?: string
): UseQueryResult<MetricsResponsePayload> {
  const apiCtx = useApiContext();
  return useQuery<MetricsResponsePayload>({
    queryKey: [KnownWebsocketEvent.METRICS_LOAD_EVENT, KnownWebsocketEvent.TOUR_REPORT_LOAD_EVENT, orgKey, from, until],
    queryFn: () =>
      apiCtx.wsFetch(KnownWebsocketEvent.METRICS_LOAD_EVENT, {
        type: KnownWebsocketEvent.TOUR_REPORT_LOAD_EVENT,
        orgKey,
        dateFilter: { range: { from, until } },
        contractorKey: contractorKey ? injectContractorViewMode(contractorKey, ContractorViewMode.All) : null,
      }),
    enabled: !!orgKey && !!from && !!until,
    keepPreviousData: true,
  });
}
