import Model, { DefaultType, MemberType } from '../../../utils/Model';
import PermissionGroup from './PermissionGroup';

export default class Permissions extends Model<Permissions> {
  groups: Array<PermissionGroup>;

  constructor(
    source: any = null, // FIXME: set proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  static _defaults: Object = {
    groups: () => [],
  };

  static _memberbuilders: Object = {
    groups: (src) => src.map((s) => new PermissionGroup(s)),
  };

  can(permission: string): boolean {
    if (!this.groups) {
      return false;
    }

    const result = this.groups
      .map((g) => {
        const permissionGroup = new PermissionGroup();
        permissionGroup.hydrateFrom(g);
        return permissionGroup.can(permission);
      })
      .find((r) => r !== 0);
    return result === 1;
  }

  getDefaults(): DefaultType<Permissions> {
    return undefined;
  }

  getMembers(): MemberType<Permissions> {
    return undefined;
  }
}
