import React, { useCallback, useMemo, useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { MdInfoOutline } from 'react-icons/md';
import { DatasetType } from './DetailedChart';
import classNames from 'classnames';
import Styles from './DetailedChartLegend.module.scss';

export type LegendItem = { legendIndex: number };

type Props = {
  datasets: Array<DatasetType>;
  onLegendItemClick: (item: LegendItem) => void;
  onLegendItemToggleAll: (arg0: DatasetType[], arg1?: boolean) => void;
};

const DetailedChartLegend = (props: Props) => {
  const { datasets, onLegendItemClick, onLegendItemToggleAll } = props;

  const [hiddenDatasetsState, setHiddenDatasetsState] = useState<number[]>([]);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const _onLegendItemClick = useCallback(
    (item: LegendItem) => {
      const hiddenDatasets = hiddenDatasetsState != null ? [...hiddenDatasetsState] : [];
      const index = hiddenDatasets.indexOf(item.legendIndex);
      if (index > -1) {
        hiddenDatasets.splice(index, 1);
      } else {
        hiddenDatasets.push(item.legendIndex);
      }
      setHiddenDatasetsState(hiddenDatasets);
      onLegendItemClick(item);
    },
    [hiddenDatasetsState, onLegendItemClick]
  );

  const onLegendItemToggleAllCallback = useCallback(() => {
    if (hiddenDatasetsState?.length > 0) {
      setHiddenDatasetsState([]);
      onLegendItemToggleAll(datasets, null);
    } else {
      setHiddenDatasetsState(datasets.map((ds, legendIndex) => legendIndex));
      onLegendItemToggleAll(datasets, true);
    }
  }, [datasets, hiddenDatasetsState?.length, onLegendItemToggleAll]);

  const datasetsMapped = useMemo(
    () =>
      datasets.map((ds, legendIndex) => {
        const sum = ds.data.filter((v) => !isNaN(v) && v != null).reduce((a, b) => a + b, 0);
        return { ...ds, ...{ legendIndex, sum } };
      }),
    [datasets]
  );

  const total = useMemo(
    () =>
      datasetsMapped
        .map((ds) => ds.sum)
        .filter((d) => !isNaN(d) && d != null)
        .reduce((a, b) => a + b, 0),
    [datasetsMapped]
  );
  const formattedTotal = Number(total).toLocaleString('de-DE', {
    maximumFractionDigits: 2,
  });

  const tableBody = useMemo(() => {
    const renderDetails = (dataset) => {
      return (
        <React.Fragment>
          <MdInfoOutline size={16} id='details-popover' style={{ marginLeft: 5 }} />
          <Popover
            placement='bottom'
            target='details-popover'
            trigger='hover'
            isOpen={popoverOpen}
            toggle={() => setPopoverOpen((v) => !v)}>
            <PopoverBody>
              {dataset.details.map((ds, i) => {
                const formattedSum = Number(ds.sum).toLocaleString('de-DE', {
                  maximumFractionDigits: 2,
                });
                const formattedPercentage = Number(ds.percentage).toLocaleString('de-DE', { maximumFractionDigits: 2 });
                return (
                  <div className={Styles.DetailedChartLegendDetailsRow} key={`DetailedChartLegendDetailsRow--${i}`}>
                    <span className={Styles.Label}>{ds.label}</span>
                    <span className={Styles.Value}>
                      <span>{formattedSum}</span>
                      <span>{formattedPercentage}%</span>
                    </span>
                  </div>
                );
              })}
            </PopoverBody>
          </Popover>
        </React.Fragment>
      );
    };

    return [...datasetsMapped].reverse().map((ds) => {
      const safeSum = ds.sum === 0 ? 1 : ds.sum;
      const dividend = total / safeSum === 0 ? 1 : total / safeSum;
      const perc = 100 / dividend;
      const formattedSum = Number(ds.sum).toLocaleString('de-DE', {
        maximumFractionDigits: 2,
      });
      const formattedPercentage = Number(perc).toLocaleString('de-DE', {
        maximumFractionDigits: 2,
      });

      return (
        <tr
          key={ds.legendIndex}
          className={classNames({
            [Styles.hasDetails]: ds.details,
            [Styles.inactive]: hiddenDatasetsState != null && hiddenDatasetsState.indexOf(ds.legendIndex) > -1,
          })}>
          <td>
            <div className={Styles.LegendLabel} onClick={() => _onLegendItemClick(ds)}>
              <span className={Styles.LegendColorBox} style={{ backgroundColor: ds.backgroundColor }} />
              {ds.label}
              {ds.details && renderDetails(ds)}
            </div>
          </td>
          <td className='text-right'>
            <span className={Styles.ValuePercentage}>{formattedPercentage}%</span>
          </td>
          <td className='text-right'>{formattedSum}</td>
        </tr>
      );
    });
  }, [_onLegendItemClick, datasetsMapped, hiddenDatasetsState, popoverOpen, total]);

  return (
    <div className={Styles.DetailedChartLegend}>
      <table>
        <thead>
          <tr
            className={classNames({
              [Styles.inactive]: hiddenDatasetsState?.length > 0,
            })}>
            <th>
              <div className={Styles.LegendLabel} onClick={onLegendItemToggleAllCallback}>
                <span className={Styles.LegendColorBox} style={{ backgroundColor: 'var(--gray12)' }} />
                <b>Alle</b>
              </div>
            </th>
            <th className='text-right'>
              <span className={Styles.ValuePercentage}>100%</span>
            </th>
            <th className='text-right'>{formattedTotal}</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </table>
    </div>
  );
};

export default DetailedChartLegend;
