import React, { RefCallback, useCallback, useEffect, useRef, useState } from 'react';
import Styles from './TruncatedText.module.scss';
import classNames from 'classnames';
import * as Popper from '@popperjs/core';
import { Tooltip } from './Tooltip';

type Props = {
  children: string | string[];
  className?: string;
  placement?: Popper.Placement;
  showTooltipIfCutOff?: boolean;
  innerRef?: React.RefCallback<HTMLSpanElement>;
  disableTooltip?: boolean;
};

const TruncatedText: React.FC<Props> = ({
  children,
  className,
  placement = 'top',
  showTooltipIfCutOff = true,
  innerRef,
}: Props) => {
  const textRef = useRef<HTMLSpanElement>();
  const [popperVisible, setPopperVisible] = useState(false);

  function isEllipsisActive() {
    return textRef.current?.offsetWidth < textRef.current?.scrollWidth;
  }

  useEffect(() => {
    if (!showTooltipIfCutOff) {
      setPopperVisible(false);
    }
  }, [showTooltipIfCutOff]);

  const openPopup = useCallback(() => {
    if (!showTooltipIfCutOff) {
      return;
    }
    if (!isEllipsisActive()) {
      return;
    }
    setPopperVisible(true);
  }, [showTooltipIfCutOff]);

  return (
    <>
      <span
        ref={textRef}
        onMouseOver={() => openPopup()}
        onMouseOut={() => setPopperVisible(false)}
        className={classNames(Styles.TruncatedText, className)}>
        <span ref={innerRef}>{children}</span>
      </span>
      {textRef?.current && showTooltipIfCutOff && (
        <Tooltip anchorElement={textRef.current} visible={popperVisible} placement={placement}>
          {children}
        </Tooltip>
      )}
    </>
  );
};

export { TruncatedText };
