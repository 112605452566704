import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.SatDeliveryitemsIntakeForecastAmount,
  label: 'Prognostizierte Sendungseingangsmenge',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'sat',
  description: '<p>Prognostizierte Sendungseingangsmenge</p>\n',
  hidden: true,
  definition: getDefinitionByName('Prognostizierte Sendungseingangsmenge'),
});

const expression = new SingleValueExpression(metric);

expression.setIdentifier('mengenprognose');

export default expression;
