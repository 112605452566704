import React, { PropsWithChildren, useMemo } from 'react';
import { HeaderContext } from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import style from '../data-table-column-footer.module.scss';
import { DataTableDataType, ValueExpressionDataTableColumnMeta } from '@data-table/data-table.types';
import { useDataTableContext } from '@data-table/contexts/data-table-context';
import { formatValueExpressionValue } from '@legacy-modules/dashboard/utils/FormatValueExpression';
import { compareKpiValue } from '@utils/compare-kpi-value';
import { DurationUtils } from '@utils/duration-utils';
import { formatValueExpressionComparisonValue } from '@data-table/utils';
import { selectDashboardOrgKey } from '@redux/dashboard.selectors';
import { useOrgConfig } from '@hooks/use-org-config-hook';
import { reviveGoalClass } from '@hooks/use-org-config-hook/use-org-config.hook';

export type ValueExpressionColumnFooterProps = PropsWithChildren<{
  ctx: HeaderContext<any, string>;
  text: string;
  comparisonText: string;
  tooltipText: string;
  comparisonClassName?: string;
}>;
const withDataTableColumnFooter =
  (Component: React.ComponentType<ValueExpressionColumnFooterProps>) =>
  <T,>(ctx: HeaderContext<DataTableDataType<T>, string>) => {
    const { comparisonTable } = useDataTableContext();

    const currentOrgKey = useSelector(selectDashboardOrgKey);

    const { valueExpression, duration } = ctx.column.columnDef.meta as ValueExpressionDataTableColumnMeta;

    const { data: orgConfig } = useOrgConfig(currentOrgKey, valueExpression.identifier);
    const zielwert = useMemo(() => {
      return reviveGoalClass(
        orgConfig?.values?.find(
          (config) => config.orgKey === currentOrgKey && config.configKey === valueExpression?.identifier
        )?.value
      );
    }, [orgConfig, valueExpression, currentOrgKey]);

    const rowModel = ctx.table.getCoreRowModel();
    const filteredRowModel = ctx.table.getFilteredRowModel();

    const comparisonRowModel = comparisonTable.getCoreRowModel();
    const filteredComparisonRowModel = comparisonTable.getFilteredRowModel();

    const summ = useMemo(
      () => ctx.column.getAggregationFn()(ctx.column.id, filteredRowModel.rows, rowModel.rows),
      [filteredRowModel.rows, rowModel.rows, ctx.column]
    );
    const comparisonSumm = useMemo(() => {
      const column = comparisonTable.getColumn(ctx.column.id);
      return column.getAggregationFn()(column.id, filteredComparisonRowModel.rows, comparisonRowModel.rows);
    }, [filteredComparisonRowModel.rows, comparisonRowModel.rows, ctx.column.id, comparisonTable]);

    const comparisonValue = Number.isFinite(summ) && Number.isFinite(comparisonSumm) ? summ - comparisonSumm : null;

    const formattedSumm = formatValueExpressionValue(valueExpression, summ)?.toString();
    const formattedComparisonValue = formatValueExpressionComparisonValue(valueExpression, comparisonValue);

    const comparisonResult = Number.isFinite(comparisonValue)
      ? compareKpiValue(valueExpression, comparisonValue, zielwert)
      : undefined;

    const { from, to } = DurationUtils.getComparisonDateRange(duration);

    const toText = from.isSame(to, 'day') ? '' : ` bis ${to.format('Do MMMM YYYY')}`;
    const tooltipText = Number.isFinite(comparisonValue)
      ? `Absolute Veränderung der ${valueExpression.getLabel()} zum Vergleichszeitraum: ${from.format(
          'Do MMMM YYYY'
        )}${toText}.`
      : `Für die Kennzahl ${valueExpression.getLabel()} liegen zum Vergleichszeitraum: ${from.format(
          'Do MMMM YYYY'
        )}${toText} keine Daten vor.`;

    return (
      <Component
        ctx={ctx}
        text={formattedSumm}
        comparisonText={formattedComparisonValue}
        tooltipText={tooltipText}
        comparisonClassName={style[comparisonResult]}
      />
    );
  };

export default withDataTableColumnFooter;
