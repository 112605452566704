import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import { ValueType } from '@legacy-modules/metrics2/models/enumerations/ValueType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.RLExpressByUndeliverableReason,
  label: 'Rückläufer Eil nach Grund',
  aggregation: AggregationType.sum,
  sumLabel: 'Rückläufer',
  category: 'delivery-items',
  hidden: true,
  positiveDirection: PositiveDirection.down,
  valueType: ValueType.map,
});

export default new SingleValueExpression(metric);
