import classNames from 'classnames';
import React, { FC, ReactElement, CSSProperties } from 'react';
import MarkerDetails from '../../../../../../modules/tour/models/entities/MarkerDetails';
import { MarkerType } from '../../../../../../modules/tour/models/enumerations/MarkerType';
import { MapboxMarkerBaseProps } from '../mapbox-marker';
import style from './with-marker-details.module.scss';

export type WithMarkerDetailsProps = Partial<MarkerDetails> &
  Pick<MapboxMarkerBaseProps, 'anchor'> &
  Pick<MapboxMarkerBaseProps, 'scaleSizeByZoom'> &
  Pick<MapboxMarkerBaseProps, 'size'> &
  Pick<MapboxMarkerBaseProps, 'icon'> &
  Pick<MapboxMarkerBaseProps, 'onMouseMove'> &
  Pick<MapboxMarkerBaseProps, 'onMouseLeave'> &
  Pick<MapboxMarkerBaseProps, 'onMouseEnter'> &
  Pick<MapboxMarkerBaseProps, 'onClick'> &
  Pick<MapboxMarkerBaseProps, 'active'> &
  Pick<CSSProperties, 'zIndex'>;

export function withMarkerDetails(MapboxMarker: FC<MapboxMarkerBaseProps>) {
  return function ({
    content,
    color,
    onMouseMove,
    onMouseLeave,
    onMouseEnter,
    onClick,
    zIndex,
    active,
    ...props
  }: WithMarkerDetailsProps): ReactElement {
    return (
      <MapboxMarker
        longitude={props?.location?.lng}
        latitude={props?.location?.lat}
        anchor={props?.anchor}
        scaleSizeByZoom={props?.scaleSizeByZoom}
        size={props?.size}
        color={color?.rgb()?.string()}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        style={{ zIndex }}
        active={active}
        // required only when active marker is set
        content={active && content}>
        <MarkerContent content={content} color={color} markerType={props?.markerType} />
      </MapboxMarker>
    );
  };
}

type MarkerContentType = Required<Pick<WithMarkerDetailsProps, 'content'>> &
  Required<Pick<WithMarkerDetailsProps, 'color'>> &
  Required<Pick<WithMarkerDetailsProps, 'markerType'>>;
const MarkerContent = ({ content, color, markerType }: MarkerContentType): ReactElement => {
  switch (markerType) {
    case MarkerType.tourStart:
    case MarkerType.tourFinish:
      return <div className={style.content}>{content}</div>;
    case MarkerType.ghostLocation:
    case MarkerType.primaryLocation:
      return null;
    case MarkerType.finishedDelivery:
    case MarkerType.customer:
    default:
      return (
        <div
          className={classNames('mapbox-marker-content', style.content)}
          style={{
            color: color?.rgb()?.string(),
          }}>
          <span>{content}</span>
        </div>
      );
  }
};
