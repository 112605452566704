import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import liegenbleiberEil from './liegenbleiber-eil';
import bestandEil from './bestand-eil';

const expression = new QuotientValueExpression(
  liegenbleiberEil,
  bestandEil,
  'Liegenbleiber-Quote Eil',
  true,
  undefined,
  undefined,
  getDefinitionByName('Liegenbleiber-Quote Eil')
);

expression.setIdentifier('stock.remaining_express_per_inventory_express');

export default expression;
