import React, { ReactElement } from 'react';
import style from './data-table-placeholders.module.scss';
import { MdRoom } from 'react-icons/md';
import NoDataIconSrc from '/assets/icons/cloud_off.svg';
import { Loader } from '@legacy-modules/common/components/Loader';

export const DataTableNoDataPlaceholder = (): ReactElement => (
  <div className={style.noDataPlaceholder}>
    <img src={NoDataIconSrc} className={style.noDataPlaceholderIcon} alt='No data for selected period.' />
    <div className={style.noDataPlaceholderText}>Für diesen Zeitraum sind keine Daten verfügbar.</div>
  </div>
);

export const HinweiseDataTableNoDataPlaceholder = (): ReactElement => (
  <div className={style.hinweiseNoDataPlaceholder}>
    <MdRoom size={56} className={style.noDataPlaceholderIcon} />
    <div className={style.noDataPlaceholderText}>
      Du hast ein übergeordnetes Gebiet ausgewählt.
      <br />
      Bitte wähle einen Standort aus, um weitere Details zu erfahren.
    </div>
  </div>
);

export const DataTableLoadingPlaceholder = (): ReactElement => (
  <Loader className={style.loader} data-testid='data-table-loading' />
);
