import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import anfahrtszeit from './anfahrtszeit';
import rueckfahrtzeit from './rueckfahrtzeit';
import stoppsDauer from './stopps-dauer';

const expression = new SumValueExpression(
  stoppsDauer,
  new SumValueExpression(anfahrtszeit, rueckfahrtzeit),
  'Tourfahrtzeit',
  undefined,
  '-#.###.##0,0 h',
  getDefinitionByName('Tourfahrtzeit')
);

expression.setIdentifier('tourfahrtzeit');

export default expression;
