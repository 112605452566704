import { DataTableComponentId, DataTableUserConfig } from '@data-table/data-table.types';
import { useUserConfig } from '@hooks/use-user-config-hook';
import { OnChangeFn, PaginationState, Updater } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

const defaultPaginationState: PaginationState = {
  pageIndex: 0,
  pageSize: 10,
};

type UseDataTablePaginationOutput = [PaginationState, OnChangeFn<PaginationState>];

const useDataTablePaginationState = (
  componentId: DataTableComponentId,
  defaultState: PaginationState = defaultPaginationState
): UseDataTablePaginationOutput => {
  const [paginationState, setPaginationState] = useState<PaginationState>(defaultState);

  const defaultValue = useMemo(() => ({ pageSize: defaultState.pageSize }), [defaultState]);

  const [dataTableUserConfig, updateDataTableUserConfig, isLoading] = useUserConfig<DataTableUserConfig>(
    componentId,
    defaultValue
  );

  useEffect(() => {
    if (isLoading || !dataTableUserConfig.pageSize) {
      return;
    }
    setPaginationState({ ...defaultState, pageSize: dataTableUserConfig.pageSize });
  }, [dataTableUserConfig.pageSize, defaultState, isLoading]);

  const savePaginationState: OnChangeFn<PaginationState> = (updater: Updater<PaginationState>) => {
    const newState = typeof updater === 'function' ? updater(paginationState) : updater;
    // Pagination callback will get called on altered data state, e.g. mutation by filtering,
    // so were exiting early if no actual pagination state changed happened
    if (newState.pageIndex === paginationState.pageIndex && newState.pageSize === paginationState.pageSize) {
      return;
    }
    setPaginationState(newState);
    updateDataTableUserConfig({
      ...dataTableUserConfig,
      pageSize: newState.pageSize,
    });
  };

  return [paginationState, savePaginationState];
};

export default useDataTablePaginationState;
