export class ZSBUtils {
  static ZSB_PREFIX = 'oz';

  static isZSB(orgKey: string): boolean {
    if (!orgKey) {
      return false;
    }
    return orgKey.startsWith(ZSBUtils.ZSB_PREFIX);
  }
}
