import { Button } from 'reactstrap';
import { MdChevronLeft, MdCompareArrows } from 'react-icons/md';
import { ComparisonMode } from '../models/enumerations/ComparisonMode';
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useOverviewContext } from '@contexts/overview-context';
import { selectCompareText, selectComparisonMode, selectPrimaryText } from '@redux';

type Props = {
  showParentOrgUnitBtn?: boolean;
  onUpdateOrgKey: () => void;
  orgUnitLabel: string;
};

const LocationDetails = (props: Props) => {
  const { showParentOrgUnitBtn = false, onUpdateOrgKey, orgUnitLabel } = props;
  const { hoverOrgKeyState } = useOverviewContext();
  const comparisonMode = useSelector(selectComparisonMode);
  const compareText = useSelector(selectCompareText);
  const primaryText = useSelector(selectPrimaryText);
  const [hoverOrgKey] = hoverOrgKeyState;
  const isHover = !!hoverOrgKey;

  return (
    <>
      <h2
        className={classNames('headline', {
          'hover-selection': isHover,
        })}>
        {isHover ? 'SELEKTIERTES GEBIET' : 'DETAILANSICHT'}
      </h2>
      <h1 className='organization-title' style={{ display: 'flex', alignItems: 'center' }}>
        {showParentOrgUnitBtn && (
          <Button
            color='secondary'
            outline
            style={{
              width: 20,
              height: 20,
              padding: 0,
              marginRight: 5,
              lineHeight: 1.25,
            }}
            onClick={onUpdateOrgKey}>
            <MdChevronLeft size={18} />
          </Button>
        )}
        {orgUnitLabel}
      </h1>
      <h2 className='daterange-title'>
        {primaryText}
        {comparisonMode === ComparisonMode.daterange && (
          <span>
            <span className='compare-icon'>
              <MdCompareArrows />
            </span>
            {compareText}
          </span>
        )}
      </h2>
    </>
  );
};

export { LocationDetails };
