/* eslint-disable camelcase */
export const ValuableComplaintReasons = Object.freeze({
  missing_contents: 'Inhalt fehlt (Teilverlust)',
  unsure_proof: 'unklarer Abliefernachweis',
  unsure_delivery: 'unklarer Sendungsverbleib',
});

export const ValuableComplaintReasonsColors = {
  Verspätung: '#64B5F6',
  'BNK - Keine': '#AED581',
  'unklarer Abliefernachweis': '#FFB74D',
  'F-Adresse ist richtig': '#81D4FA',
  'Fehler bei der Zustellung': '#CE93D8',
  Sendungsverbleib: '#FFD54F',
  'fehlerhafte Scannung': '#B0BEC5',
  'fehlerhafte A-Scannung': '#B0BEC5',
  'fehlerhafte N-Scannung': '#B0BEC5',
  'Umverfügung nicht eingehalten': '#BCAAA4',
  'Schaden gemeldet': '#26C6DA',
  'Fehlerhafte BNK': '#D4E157',
  'Abholung nicht erfolgt': '#9FA8DA',
  'Inhalt fehlt (Teilverlust)': '#E57373',
  'Unerwünschte Zustellung am PaketShop (PS)': '#4DB6AC',
  'Unfreundlicher Mitarbeiter': '#FFE082',
  'Paketankündigung nicht eingehalten': '#81C784',
  '-Fehler bei der Abholung': '#F48FB1',
  'Bearbeitungszeit überschritten': '#E0E0E0',
  Widerspruch: '#7986CB',
  Haftpflichtschaden: '#90CAF9',
  'unzureichende / falsche Beratung': '#FFE0B2',
  'Fehlerhafte Datenänderung': '#B3E5FC',
  'sprachliche Probleme / Verständigungsprobleme': '#E1BEE7',
  'Reklamation unvollständig abgeschlossen': '#FFE57F',
  'Sendung verschmutzt/riecht nach Rauch': '#CFD8DC',
  Prävention: '#D7CCC8',
  'keine Quittung': '#80DEEA',
  'Korrekturbeleg eingetroffen': '#E6EE9C',
  'Rückzahlung von KD/ATG': '#C5CAE9',
  'Ermittlung durch Polizei/Staatsanwaltschaft': '#EF9A9A',
  'PaketShop existiert nicht mehr/ falsche Adresse': '#80CBC4',
  'Ungepflegtes Erscheinungsbild': '#A5D6A7',
  Zahlungsrückläufer: '#A5D6A7',
  'Kontaktlose Zustellung nicht erfolgt': '#FFCDD2',
  'Hermes App - allgemeine Probleme': '#64B5F6',
  'Hermes App - Verbindungsabbrüche': '#64B5F6',
  'Hermes App - Sendungsverfolgung fehlerhaft (Sonstiges)': '#64B5F6',
};
/* eslint-enable camelcase */
