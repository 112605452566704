import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: 'anfahrtsstrecke/touren',
  label: 'Ø Anfahrtsstrecke (V2)',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'tours',
  description: undefined,
  valueFormat: '-#.###.##0,0 km',
  definition: getDefinitionByName('Einsätze (Touren)'),
  apiVersion: 'graphql',
});

export default new SingleValueExpression(metric);
