const colorSets = [
  [
    '#bff5ff',
    '#60b0bf',
    '#e6fbff',
    '#80ebff',
    '#bfcfff',
    '#6078bf',
    '#e6ecff',
    '#809fff',
    '#bfffd6',
    '#60bf82',
    '#e6ffef',
    '#80ffae',
    '#ffdbbf',
    '#bf8960',
    '#fff1e6',
    '#ffb780',
  ],
  [
    '#fff7ec',
    '#fee8c8',
    '#fdd49e',
    '#fdbb84',
    '#fc8d59',
    '#ef6548',
    '#d7301f',
    '#b30000',
    '#7f0000',
    '#fff7fb',
    '#ece7f2',
    '#d0d1e6',
    '#a6bddb',
    '#74a9cf',
  ],
];

export default function getRandomHexColorByIndex(i: number, setIndex: number = 0) {
  const set = colorSets[setIndex % colorSets.length];
  return set[i % set.length];
}
