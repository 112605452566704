import React from 'react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  DataTableComponentProps,
  DataTableConfigProps,
  DataTableDataType,
  DataTableMeta,
} from '@data-table/data-table.types';
import { useDataTableSortingState } from '@data-table/hooks/use-data-table-sorting-state-hook';
import { useDataTableFiltersState } from '@data-table/hooks/use-data-table-filter-state-hook';
import { useDataTableColumnOrderState } from '@data-table/hooks/use-data-table-column-order-state-hook';
import { useDataTablePaginationState } from '@data-table/hooks/use-data-table-pagination-state-hook';

const withDataTableBaseConfig =
  <T,>(WrappedComponent: React.FC<DataTableComponentProps<T>>) =>
  ({
    componentId,
    defaultSorting,
    defaultColumnFilters,
    defaultColumnOrder,
    columns,
    data,
    comparisonData,
    comparisonColumns,
    state = {},
    pagination: PaginationComponent,
    ...rest
  }: DataTableConfigProps<T>) => {
    const [sorting, onSortingChange] = useDataTableSortingState(componentId, defaultSorting);

    const [columnFilters, onColumnFiltersChange] = useDataTableFiltersState(componentId, defaultColumnFilters);

    const [columnOrder, onColumnOrderChange, saveColumnOrder] = useDataTableColumnOrderState(
      componentId,
      defaultColumnOrder
    );

    const [paginationState, onPaginationChange] = useDataTablePaginationState(componentId);

    const safeColumnOrder = columnOrder.filter((columnId) => columns.some((column) => column.id === columnId));
    const safeSorting = sorting.filter((sortingEntry) => columns.some((column) => column.id === sortingEntry.id));
    // Only allow filters that are EITHER set as true in the column visibility or not listed at all (undefined),
    // so anything explicitly set to false in the column visibility state will get filtered out
    const safeFiltering = columnFilters.filter(
      (filterEntry) =>
        columns.some((column) => column.id === filterEntry.id) &&
        (state.columnVisibility?.[filterEntry.id] === undefined || state.columnVisibility?.[filterEntry.id] === true)
    );

    const table = useReactTable<DataTableDataType<T>>({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: PaginationComponent && getPaginationRowModel(),
      state: {
        ...state,
        columnOrder: safeColumnOrder,
        columnFilters: safeFiltering,
        sorting: safeSorting,
        pagination: paginationState,
      },
      autoResetPageIndex: false,
      enableMultiSort: true,
      enableSortingRemoval: false,
      sortDescFirst: true,
      onSortingChange,
      onColumnFiltersChange,
      onColumnOrderChange,
      onPaginationChange,
      meta: {
        saveColumnOrder,
      } as DataTableMeta,
    });

    const comparisonTable = useReactTable<DataTableDataType<T>>({
      data: comparisonData,
      columns: comparisonColumns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      state: {
        columnFilters: safeFiltering,
      },
    });

    return (
      <WrappedComponent
        table={table}
        comparisonTable={comparisonTable}
        columnOrder={safeColumnOrder}
        pagination={PaginationComponent}
        {...rest}
      />
    );
  };

export default withDataTableBaseConfig;
