import { cloneDeep } from 'lodash';

import { TreeMenuNode } from '../../../../navigation/TreeMenu';

export interface TreeNode {
  children?: Array<TreeNode>;
  parent?: TreeNode;
}

export default class GenericTree<DATA, NODE extends TreeMenuNode<any>> {
  constructor(public readonly rootNode?: NODE) {}

  static findNodeInTree<T extends TreeNode>(node: T, func: (val: T) => boolean): T | null {
    if (func(node)) {
      return node;
    }
    if (!node) return null;
    if (!node.children || !node.children.length) {
      return null;
    }
    for (let i = 0; i < node.children.length; i++) {
      const match = this.findNodeInTree<T>(node.children[i] as T, func);
      if (match) {
        return match;
      }
    }
    return null;
  }

  findOrgUnitValueByFunction(func: (val: NODE) => boolean): NODE {
    return GenericTree.findNodeInTree<NODE>(this.rootNode, (node) => func(node));
  }

  flatten(): Array<NODE> {
    if (!this.rootNode) {
      return [];
    }
    return [this.rootNode, ...this.flat(this.rootNode.children || [])];
  }

  protected flat(data, parent = null): Array<NODE> {
    const dataCopy = cloneDeep(data);

    return dataCopy.reduce((result, next) => {
      if (!next) return result;

      const ou = next;
      if (parent) {
        ou.parent = parent;
      }
      result.push(ou);
      if (next.children) {
        result = result.concat(this.flat(next.children, next));
      }
      return result;
    }, []);
  }
}
