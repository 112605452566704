/**
 * Generates a new array of fixed length where you can pre-populate the first few elements with your desired values
 * and fill the remaining slots with a default value.
 * @param length a number indicating the desired length of the output array. Defaults to 0.
 * @param initialValues an array of values to insert at the start of the output array. Defaults to an empty array.
 * @param defaultValue parameter which will fill any remaining element after. Defaults to null.
 */
export function createFixedLengthArray<T = unknown>(
  length: number = 0,
  initialValues: T[] = [],
  defaultValue = null
): T[] {
  const clonedValues = [...initialValues].slice(0, length);
  clonedValues.push(
    ...Array.from({ length: length - clonedValues.length }, () => {
      return defaultValue;
    })
  );
  return clonedValues;
}
