import React, { useEffect, useState } from 'react';
import { AppInitializing } from '../components/AppInitializing';
import { useDeepLinkContext } from '@contexts/deep-link-context';

type Props = {
  children: React.ReactNode;
};

const UrlRestorer: React.FC<Props> = ({ children }) => {
  const [restored, setRestored] = useState(false);
  const deepLinkingService = useDeepLinkContext();

  useEffect(() => {
    if (!deepLinkingService) {
      return;
    }

    deepLinkingService.processUrlParams().finally(() => {
      setRestored(true);
    });
  }, [deepLinkingService]);

  if (!restored) {
    return <AppInitializing loadingText={'Stelle Anwendung aus Routing-Parametern wieder her...'} />;
  }

  return <>{children}</>;
};

export { UrlRestorer };
