import React from 'react';
import { DataTableBody } from '@data-table/components/data-table-body';
import { DataTableColumnHeaders } from '@data-table/components/data-table-column-headers';
import { HinweiseTableHeader } from '@data-table/components/data-table-header';
import { DataTablePagination } from '@data-table/components/data-table-pagination';
import {
  DataTableLoadingPlaceholder,
  DataTableNoDataPlaceholder,
  HinweiseDataTableNoDataPlaceholder,
} from '@data-table/components/data-table-placeholders';
import { DataTableConfigProps, HinweisData } from '@data-table/data-table.types';
import { useDataTableCustomInfo } from '@data-table/hooks/use-data-table-custom-info-hook';
import { useDataTableDuration } from '@data-table/hooks/use-data-table-duration-hook';
import { useDataTableOrgKey } from '@data-table/hooks/use-data-table-org-key-hook';
import { useHinweiseData } from '@data-table/hooks/use-hinweise-data-hook';
import { useHinweiseDataTableColumns } from '@data-table/hooks/use-hinweise-data-table-columns-hook';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';

type HinweiseDataTableConfigProps = Omit<DataTableConfigProps<HinweisData>, 'data' | 'columns' | 'isTour'> & {
  defaultDuration?: Duration;
};

const withHinweiseDataTableConfig =
  (WrappedComponent: React.FC<DataTableConfigProps<HinweisData>>) =>
  ({ componentId, defaultDuration, ...rest }: HinweiseDataTableConfigProps) => {
    const [durationIsLoading, duration, onDurationChange] = useDataTableDuration(componentId, defaultDuration);

    const [orgKey] = useDataTableOrgKey();

    const { isTour, title } = useDataTableCustomInfo(orgKey, duration);

    const [hinweise, hinweiseLoading] = useHinweiseData(orgKey, duration, isTour && !durationIsLoading);

    const columns = useHinweiseDataTableColumns();

    const loading = hinweiseLoading || durationIsLoading;

    return (
      <WrappedComponent
        componentId={componentId}
        data={hinweise}
        columns={columns}
        state={{
          columnPinning: { left: ['tourIdentifier'] },
        }}
        header={
          <HinweiseTableHeader
            title={title}
            duration={duration}
            disabled={!hinweise?.length}
            showWeekdaysFilter={false}
            onDurationChange={onDurationChange}
          />
        }
        body={
          <>
            {loading && <DataTableLoadingPlaceholder />}
            {!loading && !isTour && <HinweiseDataTableNoDataPlaceholder />}
            {!loading && isTour && hinweise.length === 0 && <DataTableNoDataPlaceholder />}
            {!loading && isTour && hinweise.length > 0 && <DataTableBody columnHeader={<DataTableColumnHeaders />} />}
          </>
        }
        pagination={<DataTablePagination />}
        {...rest}
      />
    );
  };

export default withHinweiseDataTableConfig;
