import React, { ReactElement } from 'react';
import style from './csv-export-action.module.scss';
import classNames from 'classnames';
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs';
import { CsvExportActionComponentProps, withCsvExport } from './with-csv-export.hoc';
import { Tooltip } from '@other-components/tooltip';

export function CsvExportAction({
  onExport,
  disabled = false,
  className,
  ...htmlProps
}: CsvExportActionComponentProps): ReactElement {
  return (
    <Tooltip data-testid='csv-export-action-tooltip' tooltipText='Exportieren'>
      <div
        data-testid='csv-export-action'
        data-disabled={disabled}
        {...htmlProps}
        className={classNames(style.csvExportAction, className, {
          [style.disabled]: disabled,
        })}
        onClick={disabled ? null : onExport}>
        <BsFileEarmarkSpreadsheet />
      </div>
    </Tooltip>
  );
}
export default withCsvExport(CsvExportAction);
