import { useDataTableContext } from '@data-table/contexts/data-table-context';
import React, { useCallback, HTMLAttributes, useState, useEffect } from 'react';

type FullscreenActionProps = HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
};
export type FullscreenActionComponentProps = FullscreenActionProps & {
  isFullscreen: boolean;
  disableTooltip: boolean;
  onClick?: () => void;
};

const withFullscreen =
  (Component: React.ComponentType<FullscreenActionComponentProps>) =>
  ({ disabled, ...htmlProps }: FullscreenActionProps) => {
    const { tableWrapperRef } = useDataTableContext();

    const fullscreenEnabled = document.fullscreenEnabled;

    const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);

    useEffect(() => {
      const onFullscreenChange = () => {
        setIsFullscreen(!!document.fullscreenElement);
      };
      document.addEventListener('fullscreenchange', onFullscreenChange);
      return () => {
        document.removeEventListener('fullscreenchange', onFullscreenChange);
      };
    }, [isFullscreen]);

    const onClick = useCallback(() => {
      if (!tableWrapperRef.current) {
        return;
      }
      if (!isFullscreen) {
        tableWrapperRef.current.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }, [tableWrapperRef, isFullscreen]);

    return (
      <Component
        {...htmlProps}
        onClick={onClick}
        disabled={disabled}
        isFullscreen={isFullscreen}
        disableTooltip={disabled || !fullscreenEnabled}
      />
    );
  };

export default withFullscreen;
