import { injectContractorViewMode } from '@legacy-modules/dashboard/utils/OrgKeyUtils';
import { ContractorViewMode } from '@legacy-modules/navigation/constants/ContractorViewMode';
import { StopType, StopTypeType } from '@legacy-modules/tour/models/state/TourDetailsState';
import { TourIdentifier } from '@legacy-modules/tour/models/state/tourDetails/TourIdentifier';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { updateFromRoutingParams } from './store';
import { AppRoutingParams } from '@legacy-modules/routing/models/AppRoutingParams';

type TourIdentifierObject = Pick<TourIdentifier, 'number' | 'orgId' | 'date' | 'contractorKey'>;

export type TourDetailsState = {
  tourIdentifier: TourIdentifierObject;
  selectedStop: StopType;
};

const initialState: TourDetailsState = {
  tourIdentifier: null,
  selectedStop: null,
};

export const tourDetailsSlice = createSlice({
  name: 'tourDetails',
  initialState,
  reducers: {
    setTourDate: (state, action: PayloadAction<string>) => {
      if (!state.tourIdentifier || !action.payload) {
        return state;
      }
      state.tourIdentifier.date = action.payload;
    },
    setTourIdentifier: (state, action: PayloadAction<TourIdentifierObject>) => {
      state.tourIdentifier = action.payload;
      if (action.payload.contractorKey) {
        state.tourIdentifier.contractorKey = injectContractorViewMode(
          action.payload.contractorKey,
          ContractorViewMode.All
        );
      }
    },
    setContractorKey: (state, action: PayloadAction<string>) => {
      if (!state.tourIdentifier) {
        return state;
      }
      state.tourIdentifier.contractorKey = injectContractorViewMode(action.payload, ContractorViewMode.All);
    },
    setSelectedStop: (state, action: PayloadAction<StopType>) => {
      state.selectedStop = action.payload;
    },
    setSelectedStopNumber: (state, action: PayloadAction<number>) => {
      state.selectedStop = { stopNumber: action.payload, type: StopTypeType.Processed, customerRef: null };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFromRoutingParams, (state, action: PayloadAction<AppRoutingParams>) => {
      state.tourIdentifier = action.payload.tourDetails.tourIdentifier;
      state.selectedStop = null;
    });
  },
});
