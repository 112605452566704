import { KpiDefinitionWithoutName } from '@custom-types/kpi';
import MathValueExpression from './MathValueExpression';
import ValueExpression from './ValueExpression';

export default class SumValueExpression extends MathValueExpression {
  constructor(
    first: ValueExpression,
    second: ValueExpression,
    label: string | null | undefined = null,
    description: string | null | undefined = null,
    valueFormat: string | null | undefined = null,
    definition?: KpiDefinitionWithoutName
  ) {
    super(first, second, '+', label, description, valueFormat, definition);
  }
}
