import events from 'events';
import PromisableEvent from './PromisableEvent';
import { PublisherInterface } from './PublisherInterface';

export default class ExtendedEventEmitter extends events.EventEmitter {
  emitPromise(eventName: string, ...args: any[]) {
    const event = new PromisableEvent();
    this.emit(eventName, event, ...args);
    return event.createEventPromise();
  }

  registerListenerInterface(eventObject: PublisherInterface) {
    eventObject.on = this.on.bind(this);
    eventObject.once = this.once.bind(this);
    eventObject.addListener = this.addListener.bind(this);
    eventObject.removeListener = this.removeListener.bind(this);
  }
}
