export const BADGE_SORT_ORDER = [
  'OE',
  'OE+',
  'NB',
  'IW',
  'MA',
  'REZ',
  'DV',
  'TU',
  'BV',
  'EV',
  'BK',
  'DV',
  'VM',
  'SEZ',
  'N',
  'N1',
  'N2',
  'N3',
  'N4',
  'NP',
  'A',
  'U',
  'S',
  'I',
  'IA',
  'ID',
  'IL',
  'IP',
  'V',
  'IN',
  'KG',
  'oGS',
  'Z',
  'F',
  'RL',
  'RET',
  'PS',
];

export const BadgeSortFn = (a: { value: string }, b: { value: string }) =>
  BADGE_SORT_ORDER.indexOf(a.value) - BADGE_SORT_ORDER.indexOf(b.value);
