import React, { ReactElement, useState, HTMLAttributes } from 'react';
import style from './mapbox-legend.module.scss';
import classNames from 'classnames';
import { MapboxControl } from '../mapbox-control';
import { MdInfoOutline, MdRemoveCircle } from 'react-icons/md';
import { MarkerColors } from '../../../../../modules/tour/models/enumerations/MarkerColors';

export type MapboxLegendProps = HTMLAttributes<HTMLDivElement> & {};

function MapboxLegendBase({ ...htmlProps }: MapboxLegendProps): ReactElement {
  const [open, setOpen] = useState(false);

  return (
    <div className={classNames(style.mapboxLegend)} {...htmlProps}>
      <ul className={classNames(style.content, open ? style.open : undefined)}>
        {Object.values(MarkerColors).map(({ color, label }) => (
          <li className={classNames(style.legendItem)} key={label}>
            <span className={classNames(style.itemBadge)} style={{ backgroundColor: color }} />
            {label}
          </li>
        ))}
      </ul>
      <div role='button' className={style.toggleButton} onClick={() => setOpen((currentValue) => !currentValue)}>
        {open ? (
          <MdRemoveCircle size={18} className={style.toggleButtonIcon} />
        ) : (
          <MdInfoOutline size={18} className={style.toggleButtonIcon} />
        )}
        {open ? 'Schließen' : 'Legende'}
      </div>
    </div>
  );
}
const MapboxLegend = MapboxControl<MapboxLegendProps>(MapboxLegendBase);
export default MapboxLegend;
