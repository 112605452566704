import { useCallback, useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

type UseScrollbarWidthReturnType = {
  hasScrollbar: boolean;
  scrollbarWidth: number;
};

export const useScrollbarWidth = (target: React.RefObject<any>): UseScrollbarWidthReturnType => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  const checkScrollbarWidth = useCallback(
    (target) => setScrollbarWidth(target.current?.offsetWidth - target.current?.clientWidth),
    []
  );

  useEffect(() => {
    checkScrollbarWidth(target);

    const currTarget = target.current;

    if (!ResizeObserver) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => window.requestAnimationFrame(() => checkScrollbarWidth(target)));

    resizeObserver.observe(currTarget);

    // Resize observer code is wrapped to prevent errors: https://stackoverflow.com/a/58701523
    return () => {
      if (!ResizeObserver) {
        return;
      }

      resizeObserver.unobserve(currTarget);
    };
  }, [checkScrollbarWidth, target]);

  return {
    hasScrollbar: scrollbarWidth > 0,
    scrollbarWidth,
  };
};
