import React from 'react';
import Styles from './ContextMenuList.module.scss';

type Props = {
  children?: React.ReactNode;
};

export const ContextMenuList: React.FC<Props> = ({ children }: Props) => {
  return <ul className={Styles.List}>{children}</ul>;
};
