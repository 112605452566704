import React, { useCallback, useRef, useState } from 'react';
import Alert from '../../app/models/Alert';
import { Button } from 'reactstrap';
import { MdWarning, MdInfo, MdError } from 'react-icons/md';
import { Tooltip } from '../../common/components/Tooltip';
import Styles from './AlertsDisplay.module.scss';
import classNames from 'classnames';
import { useOnClickOutside } from '../../common/hooks/useOnClickOutside';
import { NewLineText } from './NewLineText';
import { useSelector } from 'react-redux';
import { selectAlerts, selectAlertSeenTime } from '@redux';
import { useAlertsContext } from '@contexts/alerts-context';

const LevelValues = {
  info: 0,
  warning: 1,
  danger: 2,
};
const ReverseLevelValues = Object.keys(LevelValues);

const getHighestLevel = (alerts: Alert[]) => {
  const levelValue = Math.max(...(alerts || []).map((a) => LevelValues[a.level]));
  return ReverseLevelValues[levelValue];
};

const AlertDisplay = () => {
  const { acknowledgeAlerts: onAcknowledge } = useAlertsContext();

  const alerts = useSelector(selectAlerts);
  const alertSeenTime = useSelector(selectAlertSeenTime);

  const getUnseenAlertCount = useCallback(() => {
    if (!alerts) {
      return 0;
    }

    return alerts.filter((a) => a.timestamp * 1000 > alertSeenTime).length;
  }, [alertSeenTime, alerts]);

  const [open, setOpen] = useState<boolean>(false);

  const unseenCount = getUnseenAlertCount();
  const level = getHighestLevel(alerts);

  const close = useCallback(() => {
    if (open && onAcknowledge) {
      onAcknowledge();
    }
    setOpen(false);
  }, [onAcknowledge, open]);

  const onToggle = useCallback(() => {
    if (open && onAcknowledge) {
      onAcknowledge();
    }
    setOpen((v) => !v);
  }, [onAcknowledge, open]);

  const anchorRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(anchorRef, close);

  if (!alerts || alerts.length === 0) {
    return null;
  }

  return (
    <div className={Styles.AlertsOverlay} ref={anchorRef}>
      <div id='alerts-display-button'>
        <Button
          color='secondary'
          outline
          style={{ width: 40, height: 40, padding: 0 }}
          className={classNames(Styles.AlertsDisplayButton, Styles[level])}
          onClick={onToggle}>
          {level === 'info' && <MdInfo size={18} />}
          {level === 'warning' && <MdWarning size={18} />}
          {level === 'danger' && <MdError size={18} />}
          {unseenCount > 0 && <span className={Styles.UnseenCount}>{unseenCount}</span>}
        </Button>
      </div>
      <Tooltip visible={open} onClose={close} anchorElement={anchorRef?.current} placement={'bottom-end'}>
        <div className={Styles.AlertsOverlayPopper}>
          <div className={Styles.AlertContainer}>
            {alerts.map((alert: Alert, index: number) => {
              return (
                <div key={`alert-${index}`} className={classNames(Styles.AlertLine, Styles[alert.level])}>
                  {alert.level === 'info' && <MdInfo size={18} />}
                  {alert.level === 'warning' && <MdWarning size={18} />}
                  {alert.level === 'danger' && <MdError size={18} />}
                  <NewLineText>{alert.message}</NewLineText>
                </div>
              );
            })}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default AlertDisplay;
