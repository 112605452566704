import GeoCoordinate from './GeoCoordinate';

export default class GeoCodedAddress extends GeoCoordinate {
  reliable: boolean;
  provider: string;
  accuracyDescription: string | null | undefined;

  static _defaults: Object = {
    lat: () => 0.0,
    lng: () => 0.0,
    reliable: () => false,
    accuracyDescription: () => null,
  };
}
