import * as React from 'react';
import Color from 'color';
import { Marker } from 'react-mapbox-gl';
import MarkerDetails from '../../tour/models/entities/MarkerDetails';
import { MarkerType } from '../../tour/models/enumerations/MarkerType';
import FinishedDelivery from '../../tour/models/entities/FinishedDelivery';
import { Anchor } from 'react-mapbox-gl/lib/util/types';
import { useState } from 'react';

type Props = {
  markerDetails: MarkerDetails;
  coordinates: number[];
  color: Color;
  zIndex?: number;
  anchor?: Anchor;
  offset?: number[];
  size?: 'default' | 'small' | 'point' | 'big';
  isActive: boolean;
  isStopDetail?: boolean;
  isHovered: boolean;
  isClustered?: boolean;
  highlightMode?: 'none' | 'highlight' | 'blurred';
  contents: React.ReactNode;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  finishedDelivery?: FinishedDelivery;
};

const OFFSETS = {
  default: [0, 16],
  small: [0, 5],
  point: [0, 3],
};

const ACTIVE_OFFSET = [0, 5];

const StopMarker: React.FC<Props> = ({
  markerDetails,
  coordinates,
  color,
  zIndex = 1,
  anchor = 'bottom',
  offset,
  size = 'default',
  isActive,
  isStopDetail,
  isHovered: isHoveredProps,
  isClustered,
  highlightMode,
  contents,
  onClick,
  onMouseEnter,
  onMouseLeave,
  finishedDelivery,
}) => {
  const [isHovered, setIsHovered] = useState(isHoveredProps);

  const _onMouseEnter = () => {
    setIsHovered(true);
    onMouseEnter && onMouseEnter();
  };

  const _onMouseLeave = () => {
    setIsHovered(false);
    onMouseLeave && onMouseLeave();
  };

  const _renderIconContent = () => {
    return (
      <g
        id='ic_place_white'
        data-name='ic/place_white'
        transform={`translate(6 10) ${
          markerDetails.markerType === MarkerType.customer ? 'scale(1.8 1.8)' : 'scale(1.3 1.3)'
        }`}>
        {markerDetails.content}
      </g>
    );
  };

  const _renderTextContent = () => {
    return (
      <text x='22' y='32' textAnchor='middle' fontSize='20' fill='white'>
        {markerDetails.content}
      </text>
    );
  };

  const _renderClusteredMarker = (): React.ReactNode => {
    const isUndeliverable = finishedDelivery.deliveryMode === 'undeliverable';
    const color = isUndeliverable ? '#F4B6B3' : '#96C679';
    return (
      <div className={`round-marker ${size}`}>
        <div
          className='marker'
          style={{
            backgroundColor: `${color}`,
            border: `${isUndeliverable ? '5px solid rgba(255, 158, 158, 0.5)' : '5px solid rgba(150, 198, 121, 0.5)'}`,
          }}>
          <div className='marker-shadow-content'>{size === 'big' ? contents : null}</div>
        </div>
      </div>
    );
  };

  const renderActiveMarker = (): React.ReactNode => {
    const isIconContent = typeof markerDetails.content !== 'string';
    const isNumberContent = typeof markerDetails.content === 'number';

    /* eslint-disable max-len */
    return (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='454 287 64 74.026' width={52} height={60}>
        <defs>
          <style>
            {`.cls-1-${markerDetails.markerRef} {
              fill: ${markerDetails.color.toString()};
            }
              .cls-2-${markerDetails.markerRef} {
              fill: url(#linear-gradient);
            }
              .cls-3-${markerDetails.markerRef} {
              fill: none;
              stroke: ${highlightMode === 'highlight' ? '#facb83' : '#fff'};
              stroke-width: 3px;
            }
              .cls-4-${markerDetails.markerRef} {
              fill: var(--white);
            }`}
          </style>
          <filter x='-58.9%' y='-32.8%' width='217.9%' height='203.1%' filterUnits='objectBoundingBox' id='dropwshadow'>
            <feOffset dx='0' dy='1' in='SourceAlpha' result='shadowOffsetOuter1' />
            <feGaussianBlur stdDeviation='2.5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
            <feColorMatrix
              values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0'
              type='matrix'
              in='shadowBlurOuter1'
              result='shadowMatrixOuter1'
            />
            <feOffset dx='0' dy='3' in='SourceAlpha' result='shadowOffsetOuter2' />
            <feGaussianBlur stdDeviation='2' in='shadowOffsetOuter2' result='shadowBlurOuter2' />
            <feColorMatrix
              values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.12 0'
              type='matrix'
              in='shadowBlurOuter2'
              result='shadowMatrixOuter2'
            />
            <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter3' />
            <feGaussianBlur stdDeviation='2' in='shadowOffsetOuter3' result='shadowBlurOuter3' />
            <feColorMatrix
              values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.14 0'
              type='matrix'
              in='shadowBlurOuter3'
              result='shadowMatrixOuter3'
            />
            <feMerge>
              <feMergeNode in='shadowMatrixOuter1' />
              <feMergeNode in='shadowMatrixOuter2' />
              <feMergeNode in='shadowMatrixOuter3' />
              <feMergeNode in='SourceGraphic' />
            </feMerge>
          </filter>
          <linearGradient
            id={`linear-gradient-${markerDetails.markerRef}`}
            x1='0.5'
            x2='0.5'
            y2='0.986'
            gradientUnits='objectBoundingBox'>
            <stop offset='0' stopColor='#fff' stopOpacity='0.2' />
            <stop offset='1' stopColor={markerDetails.color.darken(50).toString()} stopOpacity='0.2' />
          </linearGradient>
        </defs>
        <g
          id='_200_Archiv_Live---Marker'
          data-name='200_Archiv_Live---Marker'
          transform='translate(358 277)'
          opacity={highlightMode === 'blurred' ? 0.2 : 1}>
          <g id='Molecule_-Marker_Sendungen_on' data-name='Molecule/-Marker_Sendungen_on' transform='translate(88)'>
            <g id='Sendungen_on' filter='url(#dropwshadow)' transform='translate(18 10)'>
              <g id='Shape'>
                <path
                  id='Pfad_8'
                  data-name='Pfad 8'
                  className={`cls-1-${markerDetails.markerRef}`}
                  d='M22,0A22.186,22.186,0,0,0,0,22.4C0,39.2,22,64,22,64S44,39.2,44,22.4A22.186,22.186,0,0,0,22,0Z'
                />
                <path
                  id='Pfad_9'
                  data-name='Pfad 9'
                  className={`cls-2-${markerDetails.markerRef}`}
                  d='M22,0A22.186,22.186,0,0,0,0,22.4C0,39.2,22,64,22,64S44,39.2,44,22.4A22.186,22.186,0,0,0,22,0Z'
                />
                <path
                  id='Pfad_10'
                  data-name='Pfad 10'
                  className={`cls-3-${markerDetails.markerRef}`}
                  d='M22,61.7c.691-.821,1.451-1.748,2.265-2.768,2.552-3.2,5.1-6.664,7.485-10.256a89.633,89.633,0,0,0,7.586-13.623C41.4,30.279,42.5,26.013,42.5,22.4A20.687,20.687,0,0,0,22,1.5,20.687,20.687,0,0,0,1.5,22.4c0,3.613,1.1,7.879,3.164,12.649A89.633,89.633,0,0,0,12.25,48.671c2.38,3.592,4.933,7.054,7.485,10.256C20.549,59.948,21.309,60.875,22,61.7Z'
                />
              </g>
              {isIconContent && _renderIconContent()}
              {(!isIconContent || isNumberContent) && _renderTextContent()}
            </g>
          </g>
        </g>
      </svg>
    );
  };

  const styleColor = color;
  const styleColorOpacified = color.fade(0.2);
  // const isHovered = isHovered || isHovered;

  return (
    <Marker
      coordinates={coordinates}
      style={{
        zIndex: isActive ? (isStopDetail ? zIndex : 10) : zIndex,
        cursor: 'pointer',
      }}
      offset={offset || (isActive ? ACTIVE_OFFSET : OFFSETS[size])}
      anchor={anchor}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
      onClick={
        onClick
          ? onClick
          : () => {
              /* ignore */
            }
      }>
      {isActive ? (
        isClustered ? (
          <>{_renderClusteredMarker()}</>
        ) : (
          <>{renderActiveMarker()}</>
        )
      ) : (
        <div
          className={`round-marker ${highlightMode !== 'none' ? highlightMode : ''} ${size}`}
          style={{ opacity: markerDetails.opacity }}>
          <div className='marker' style={{ backgroundColor: styleColor.rgb().string() }}>
            <div
              className='marker-shadow-content'
              style={{
                background: isHovered
                  ? 'linear-gradient(to top, rgba(51, 51, 51, 0.6) 0%, ' + styleColorOpacified.rgb().string() + ' 100%)'
                  : 'linear-gradient(to bottom, ' +
                    styleColorOpacified.rgb().string() +
                    ' 0%, rgba(51, 51, 51, 0.2) 100%)',
              }}>
              {size === 'default' ? contents : null}
            </div>
          </div>
        </div>
      )}
    </Marker>
  );
};

export default StopMarker;
