import GeoCoordinate from './GeoCoordinate';
import moment from 'moment-timezone';

export default class GeoLocation extends GeoCoordinate {
  date?: moment.Moment;
  provider?: string;
  accuracy?: number;

  static _defaults: Object = {
    lat: () => 0.0,
    lng: () => 0.0,
    date: () => null,
    provider: () => null,
    accuracy: () => null,
  };

  static _memberBuilders: Object = {
    date: (src) => (src ? moment(src) : moment()),
  };

  isGpsLocation?(): boolean {
    return ['gps', 'fused'].includes(this.provider);
  }
}
