import * as React from 'react';
import { DeliveryItemTrackingData } from '../models/types/DeliveryItemTrackingData';
import ReturnTypes from '../models/enumerations/ReturnType';
import { useCallback, useRef, useState } from 'react';
import { Tooltip } from '../../common/components/Tooltip';
import { badgeDictionary } from '@components/delivery-badge/types';
import { DeliveryBadge } from '../utils/getDeliveryBadge/GetDeliveryBadge';

type Props = {
  deliveryItem: DeliveryItemTrackingData;
};

const ReturnDeliveryItemBadge: React.FC<Props> = (props) => {
  // TODO: Check how this can be unified with "getDeliveryBadge" function
  // which looks similar
  const _getBadge = useCallback((deliveryItem: DeliveryItemTrackingData): DeliveryBadge => {
    const badge: DeliveryBadge = badgeDictionary.default;

    if (!deliveryItem || !deliveryItem.returnParcelDetails) {
      return badge;
    }
    switch (deliveryItem.returnParcelDetails.returnType) {
      case ReturnTypes.DBCourier:
        return badgeDictionary.DB;
      case ReturnTypes.NewParcel:
        if (deliveryItem.returnParcelDetails.clientType === 'ProPS') {
          return badgeDictionary.PRO;
        } else {
          return badgeDictionary.PRI;
        }
      case ReturnTypes.ReturnToSender:
        return badgeDictionary.RET;
      case ReturnTypes.Undeliverable:
        return badgeDictionary.RL;
      default:
        return badge;
    }
  }, []);

  const { deliveryItem } = props;
  const badge = _getBadge(deliveryItem);

  // TODO: Unify style on all badges
  const badgeStyles = {
    display: 'inline-block',
    color: 'white',
    backgroundColor: badge.color,
    borderRadius: '2px',
    padding: '0 2px',
    textAlign: 'center' as const,
    width: '27px',
    fontSize: '13px',
  };
  const badgeStylesDB = {
    display: 'inline-block',
    color: '#E30613',
    backgroundColor: badge.color,
    borderRadius: '2px',
    padding: '0 2px',
    textAlign: 'center' as const,
    width: '27px',
    height: '19px',
    fontSize: '13px',
    fontWeight: 500,
    border: '2px solid #E30613',
    lineHeight: '16px',
    boxShadow: 'none',
  };
  const containerStyles = {
    display: 'flex',
    alignItems: 'center',
  };

  const [hovering, setHovering] = useState(false);
  const showTooltip = useCallback(() => setHovering(true), []);
  const hideTooltip = useCallback(() => setHovering(false), []);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <div onMouseOver={showTooltip} onMouseLeave={hideTooltip} style={containerStyles} ref={ref}>
        <div
          className='badge-span-container'
          style={deliveryItem.returnParcelDetails.returnType === ReturnTypes.DBCourier ? badgeStylesDB : badgeStyles}>
          {badge.value}
        </div>
      </div>
      <Tooltip anchorElement={ref?.current} visible={hovering}>
        {badge.description}
      </Tooltip>
    </>
  );
};

export default ReturnDeliveryItemBadge;
