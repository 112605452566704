import React from 'react';
import Styles from './TableBody.module.scss';
import classNames from 'classnames';

type TableBodyProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableSectionElement>,
  HTMLTableSectionElement
> & {
  height?: string | number;
  hasFooter?: boolean;
};

const TableBody: React.FC<TableBodyProps> = React.forwardRef(
  ({ children, height, hasFooter = false, style, className, ...props }, ref) => {
    const ourStyle = (() => {
      const ourStyle: React.CSSProperties = {
        width: '100%',
        ...style,
      };
      if (height) {
        ourStyle.display = 'block';
        ourStyle.maxHeight = height;
        ourStyle.overflowY = 'auto';
      }

      return ourStyle;
    })();

    return (
      <tbody
        ref={ref}
        className={classNames(
          Styles.TableBody,
          {
            [Styles.hasFooter]: hasFooter,
          },
          className
        )}
        style={ourStyle}
        {...props}>
        {children}
      </tbody>
    );
  }
);

TableBody.displayName = 'TableBody';

export { TableBody };
