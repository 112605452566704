import React from 'react';
import '../../dashboard/cssModules/kpiDetailsContainerStyles.scss';
import { useSelector } from 'react-redux';
import DetailedChart from '../charts/DetailedChart';
import detailsMapping from '../utils/DashboardDetailsMapping';
import CalendarTable from '../tables/CalendarTable';
import KpiCompare from '../kpiCompare/KpiCompare';
import ComplaintsChart from '../../complaints/containers/ComplaintsChart/ComplaintsChart';
import AuthGuard from '../../auth/components/AuthGuard';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import DataTable from '@data-table';
import { selectActiveDashboardView } from '@redux/dashboard.selectors';

type Props = {};

const classMapping = {
  DetailedChart: DetailedChart,
  CalendarTable: CalendarTable,
  KpiCompare: KpiCompare,
  ComplaintsChart: ComplaintsChart,
  RuecklaufDataTable: DataTable.RuecklaufDataTable,
  KennzahlenDataTable: DataTable.KennzahlenDataTable,
  BeanstandungenDataTable: DataTable.BeanstandungenDataTable,
};

const KpiDetailsContainer: React.FC<Props> = () => {
  const activeDashboard = useSelector(selectActiveDashboardView);
  const details = detailsMapping.find((entry) => entry.tabMeta.valueExpressionKey === activeDashboard);
  return (
    <>
      {details?.components?.map((c, i) => {
        const componentAbsencePermission = c.permissions?.absence || [];

        return (
          <AuthGuard
            key={`${c.component}-${i}`}
            absencePermissions={componentAbsencePermission as WellKnownPermission[]}>
            <div>{React.createElement(classMapping[c.component], c.props)}</div>
          </AuthGuard>
        );
      })}
    </>
  );
};

export default KpiDetailsContainer;
