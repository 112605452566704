import { $Keys } from 'utility-types';

export enum MetricDataConnectionOutputFormats {
  // data will be of type MetricsValue
  single = 'single',
  // data will be of type Array<MetricsValue>
  dateArray = 'dateArray',
  // data will be of type {[valueKey]: Array<number>}
  mappedDateArrays = 'mappedDateArrays',
  // data will be of type Object{[dateString]: MetricsValue}
  // dateString will be in format fromDate.format('YYYY-MM-DD')+':'+numberOfDays
  dateObject = 'dateObject',
  // data will be of type Object{[string]: MetricsValue}
  orgKeyObject = 'orgKeyObject',
  // data will be of type Map<MetricsEntityKey, MetricsCacheEntity> (default)
  entities = 'entities',
}
