import React, { useCallback, useState } from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import Styles from './TablePagination.module.scss';
import { MdChevronLeft, MdChevronRight, MdUnfoldMore } from 'react-icons/md';
import { range } from 'lodash';

export const TablePagination = ({
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
  totalResultCount,
}) => {
  const [perPageOpen, setPerPageOpen] = useState(false);
  const [pageSelectOpen, setPageSelectOpen] = useState(false);

  const onClickPerPage = useCallback(
    (value: number) => {
      setPageSize(value);
      setPerPageOpen(false);
      setPageSelectOpen(false);
    },
    [setPageSize]
  );

  const onGoToCallback = useCallback(
    (pageIndex: number) => {
      gotoPage(pageIndex);
      setPerPageOpen(false);
      setPageSelectOpen(false);
    },
    [gotoPage]
  );

  const values = [10, 15, 25, 50, 100];

  return (
    <div className={Styles.TablePagination}>
      <div className={Styles.PerPageSelector}>
        <Button color='link' id='per-page-popover'>
          <MdUnfoldMore size={26} />
          <span>{pageSize} Einträge pro Seite</span>
        </Button>
        <Popover
          placement='top'
          target='per-page-popover'
          trigger='legacy'
          isOpen={perPageOpen}
          toggle={() => setPerPageOpen((v) => !v)}>
          <PopoverBody>
            {values.map((v) => (
              <Button key={`button-${v}`} color='link' onClick={() => onClickPerPage(v)}>
                {v}
              </Button>
            ))}
          </PopoverBody>
        </Popover>
      </div>

      <div className={Styles.PageSelector}>
        <Button color='link' onClick={previousPage} disabled={!canPreviousPage}>
          <MdChevronLeft size={26} />
        </Button>
        <Button color='link' id='page-select-popover' disabled={pageCount < 2}>
          Seite {pageIndex + 1} von {!pageCount ? 1 : pageCount}
        </Button>
        <Button color='link' onClick={nextPage} disabled={!canNextPage}>
          <MdChevronRight size={26} />
        </Button>
        <Popover
          placement='top'
          target='page-select-popover'
          trigger='legacy'
          isOpen={pageSelectOpen}
          toggle={() => setPageSelectOpen((v) => !v)}>
          <PopoverBody>
            {range(pageCount).map((value, i) => {
              return (
                <Button key={`button-${value}`} color='link' onClick={() => onGoToCallback(i)}>
                  {i + 1}
                </Button>
              );
            })}
          </PopoverBody>
        </Popover>
      </div>

      <div className={Styles.PageTotalCount}>{totalResultCount} Ergebnisse</div>
    </div>
  );
};
