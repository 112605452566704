import React, { ReactElement } from 'react';

import { ScaleControl, ScaleControlProps } from 'react-map-gl';

export type MapboxScaleControlProps = ScaleControlProps & {};

export function MapboxScaleControl(props: MapboxScaleControlProps): ReactElement {
  return <ScaleControl {...props} />;
}
export default MapboxScaleControl;
