import React, { ReactElement, HTMLAttributes } from 'react';

import { Source, SourceProps } from 'react-map-gl';

export type MapboxSourceProps = SourceProps & HTMLAttributes<HTMLDivElement> & {};

export function MapboxSource(props: MapboxSourceProps): ReactElement {
  return <Source {...props} />;
}
export default MapboxSource;
