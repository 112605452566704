import Color from 'color';
import { MapValueMode } from '../../overview/models/enumerations/MapValueMode';
import { MapLayerStyle } from './MapLayerStyle';

export type Bounds = [[number, number], [number, number]];

export default class MapUtils {
  static DEFAULT_LAYER_STYLE = {
    color: '#747474',
    weight: 1,
    opacity: 1,
    fillOpacity: 0.4,
    fillColor: '#CBCBCB',
  };

  static calculateGradientColor(
    percentage: number,
    isHover: boolean = false,
    mapValueMode: MapValueMode = MapValueMode.primary,
    reverse: boolean = false
  ): Partial<MapLayerStyle> {
    if (reverse) {
      percentage = 1 - percentage;
    }
    const gradientColor =
      mapValueMode === MapValueMode.comparison
        ? new Color({
            r: 255 - percentage * 3,
            g: 255 - percentage * 103,
            b: 255 - percentage * 255,
          }).rgb()
        : mapValueMode === MapValueMode.difference && percentage < 0.5
        ? new Color({
            r: 220 + percentage * 2 * 35,
            g: 53 + percentage * 2 * 202,
            b: 69 + percentage * 2 * 161,
          }).rgb()
        : mapValueMode === MapValueMode.difference && percentage
        ? new Color({
            r: 255 - (percentage - 0.5) * 2 * 215,
            g: 255 - (percentage - 0.5) * 2 * 88,
            b: 230 - (percentage - 0.5) * 2 * 161,
          }).rgb()
        : new Color({
            r: 255 - percentage * 253,
            g: 255 - percentage * 110,
            b: 255 - percentage * 50,
          }).rgb();
    return {
      fillColor: gradientColor.rgb().string(),
      fillOpacity: isHover ? 0.8 : 0.6,
      weight: isHover ? 1.5 : 1,
    };
  }

  static boundsChanged(previous: Bounds | null | undefined, next: Bounds | null | undefined): boolean {
    return (
      previous !== next &&
      (!previous ||
        !next ||
        previous.length !== next.length ||
        !!previous.filter((c, i) => {
          const nc = next && next[i];
          return c[0] !== (nc && nc[0]) || c[1] !== (nc && nc[1]);
        })[0])
    );
  }
}
