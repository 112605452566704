import { DebounceInput } from '@components/debounce-input';
import { FilterControls } from '../filter-controls';
import { ColumnFilterContentProps } from '@data-table/data-table.types';
import { getDataTableColumnFilterTitle, getSortedMultiselectValues } from '@data-table/utils';
import { InputCheckbox } from '@legacy-modules/dashboard/components/InputCheckbox';
import classNames from 'classnames';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import style from './multi-select-filter-content.module.scss';

type MultiSelectFilterContentProps<T, V> = ColumnFilterContentProps<T, V> & {
  allValues: string[];
  inactiveValues?: string[];
  headerControls?: React.ReactNode;
};
const MultiSelectFilterContent = <T, V>({
  allValues,
  inactiveValues = [],
  headerControls,
  column,
  table,
  onCancel,
  onConfirm,
}: MultiSelectFilterContentProps<T, V>) => {
  const filterValue = column.getFilterValue() as string[];

  const showCount = column.id !== 'orgName';

  const title = getDataTableColumnFilterTitle(column.id);

  const valuesMap = allValues.reduce((acc, value) => {
    if (acc.has(value)) {
      acc.set(value, acc.get(value) + 1);
    } else {
      acc.set(value, 1);
    }
    return acc;
  }, new Map<string, number>());

  const activeValues = [...valuesMap.keys()];

  const values = getSortedMultiselectValues(activeValues, inactiveValues);

  const [selectedValues, setSelectedValues] = useState<Set<string>>(new Set<string>(filterValue));

  const [filter, setFilter] = useState<string>(null);

  const allSelected = selectedValues.size === activeValues.length && activeValues.every((v) => selectedValues.has(v));

  const indeterminate = values.some((v) => selectedValues.has(v));

  useEffect(() => {
    setSelectedValues(new Set<string>(filterValue));
  }, [filterValue]);

  const toggleAll = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValues(new Set(event.target.checked ? activeValues : []));
    column.setFilterValue(event.target.checked ? activeValues : undefined);
    table.resetPagination();
  };

  const toggleOne = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setSelectedValues((currentValues) => {
      const nextValues = new Set(currentValues);
      if (event.target.checked) {
        nextValues.add(event.target.value);
      } else {
        nextValues.delete(event.target.value);
      }
      column.setFilterValue(Array.from(nextValues));
      table.resetPagination();
      return new Set(nextValues);
    });
  };

  const onFilterChange = useCallback((filterValue: string) => {
    filterValue?.length > 0 ? setFilter(filterValue.toLowerCase()) : setFilter(null);
  }, []);

  const filteredValues = filter ? values.filter((value) => value.toLowerCase().includes(filter)) : values;

  return (
    <section className={classNames(style.filterContent)}>
      <FilterControls
        cancelCallback={onCancel}
        confirmCallback={() => onConfirm(Array.from(selectedValues.values()) as V)}>
        <div
          data-testid='data-table-filter-title'
          className={classNames(style.filterContentHeadline, style.multiselectFilterContentHeadline)}>
          {title}
        </div>
        {headerControls}
        <DebounceInput
          debounceTimeout={200}
          onValueChange={onFilterChange}
          isValid={filteredValues?.length > 0}
          className={style.multiselectFilterContentSearch}
          inputProps={{ placeholder: 'Auswahl durchsuchen...' }}
        />
        <div className={style.multiSelectFilterContentContainer}>
          <InputCheckbox
            data-testid='data-table-filter-checkbox'
            containerClassName={style.checkbox}
            checked={allSelected}
            indeterminate={indeterminate}
            onChange={toggleAll}>
            (Alles auswählen)
          </InputCheckbox>
          {filteredValues.map((value) => (
            <InputCheckbox
              data-testid='data-table-filter-checkbox'
              data-value={value}
              containerClassName={style.checkbox}
              style={{
                color: valuesMap.get(value) > 0 ? 'var(--black1)' : 'var(--gray23)',
              }}
              key={value}
              value={value}
              disabled={inactiveValues?.includes(value)}
              checked={selectedValues.has(value)}
              onChange={toggleOne}>
              {showCount ? `${value} (${valuesMap.get(value) || 0})` : value}
            </InputCheckbox>
          ))}
        </div>
      </FilterControls>
    </section>
  );
};

export default MultiSelectFilterContent;
