import MathValueExpression from './MathValueExpression';
import ValueExpression from './ValueExpression';
import {} from '../../../common/util/kpiDefinitions';
import { KpiDefinitionWithoutName } from '@custom-types/kpi';

export default class DifferenceValueExpression extends MathValueExpression {
  constructor(
    first: ValueExpression,
    second: ValueExpression,
    label: string | null | undefined = null,
    description: string | null | undefined = null,
    valueFormat: string | null | undefined = null,
    definition?: KpiDefinitionWithoutName
  ) {
    super(first, second, '-', label, description, valueFormat, definition);
  }
}
