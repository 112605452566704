export enum MetricTypeKey {
  FinishedDeliveriesItemsByDeliveryMode = 'finished_deliveries.items.by_delivery_mode',
  FinishedDeliveriesItemsDeliveredByHour = 'finished_deliveries.items.delivered.by_hour',
  FinishedDeliveriesItemsDeliveredByRecipientPerson = 'FinishedDeliveriesItemsDeliveredByRecipientPerson',
  TourLoadedDeliveryitemsCustomerAuthorizationCount = 'tour_loaded.deliveryitems.customer.authorization.count',
  TourLoadedDeliveryitemsServicesEilserviceCount = 'tour_loaded.deliveryitems.services.eilservice.count',
  TourLoadedDeliveryitemsServicesIdentserviceCount = 'tour_loaded.deliveryitems.services.identservice.count',
  TourLoadedDeliveryitemsServicesNachnahmeCount = 'tour_loaded.deliveryitems.services.nachnahme.count',
  TourLoadedDeliveryitemsServicesWunschzeitfensterCount = 'tour_loaded.deliveryitems.services.wunschzeitfenster.count',
  TourLoadedDeliveryitemsServicesNoSignatureCount = 'tour_loaded.deliveryitems.services.no_signature.count',
  TourLoadedDeliveryitemsServicesNachnahmeSum = 'tour_loaded.deliveryitems.services.nachnahme.sum',
  TourLoadedCustomersCount = 'tour_loaded.customers.count',
  ReturnsFinishedDeliveriesCount = 'returns.finished_deliveries.count',
  ReturnsFinishedDeliveriesByHour = 'returns.finished_deliveries.by_hour',
  ReturnsFinishedDeliveriesByItemCount = 'returns.finished_deliveries.by_item_count',
  ReturnsFinishedDeliveriesBySource = 'returns.finished_deliveries.by_source',
  ReturnsFinishedDeliveriesItemsCount = 'returns.finished_deliveries.items.count',
  ReturnsFinishedDeliveriesItemsByType = 'returns.finished_deliveries.items.by_type',
  ReturnsFinishedDeliveriesItemsByHour = 'returns.finished_deliveries.items.by_hour',
  FinishedDeliveriesCount = 'finished_deliveries.count',
  FinishedDeliveriesByCustomerCount = 'finished_deliveries.by_customer_count',
  FinishedDeliveriesByItemCount = 'finished_deliveries.by_item_count',
  FinishedDeliveriesItemsDeliveredInkassoAmount = 'finished_deliveries.items.delivered.inkasso_amount',
  FinishedDeliveriesNotificationsCount = 'finished_deliveries.notifications.count',
  FinishedDeliveriesNotificationsByType = 'finished_deliveries.notifications.by_type',
  FinishedDeliveriesProofOfDeliveryByType = 'finished_deliveries.proof_of_delivery.by_type',
  TourLoadedCount = 'tour_loaded.count',
  ComputedActiveDays = 'computed.active_days',
  FinishedDeliveriesStopJourneyDurationSameDayNet = 'finished_deliveries.stop_journey.duration_same_day.net',
  FinishedDeliveriesStopJourneyLocationAccuracy = 'finished_deliveries.stop_journey.location.accuracy',
  FinishedDeliveriesStopJourneyLocationTimeLag = 'finished_deliveries.stop_journey.location.time_lag',
  SatDeliveryitemsIntakeForecastAmount = 'sat.deliveryitems.intake.forecast.amount',
  SatDeliveryitemsIntakeForecastCreatedAt = 'sat.deliveryitems.intake.forecast.created_at',
  AllTours = 'all.tours',
  ErpCostsToursAmount = 'erp.costs.tours.amount',
  ErpCostsToursAmountByArticle = 'erp.costs.tours.amount_by_article',
  ErpCostsToursAmountByType = 'erp.costs.tours.amount_by_type',
  StaticMapPopulation = 'static.map.population',
  StaticMapArea = 'static.map.area',
  ComplaintsHappenedCountWithTours = 'complaints.happened.count_with_tours',
  ComplaintsReportedCountTotal = 'complaints.reported.count_total',
  ComplaintsReportedCountByType = 'complaints.reported.count_by_type',
  StockRemaining = 'stock.remaining',
  StockRemainingExpress = 'stock.remaining_express',
  StockStored = 'stock.stored',
  StockAFNUS = 'stock.afnus',
  StockInventory = 'stock.inventory',
  StockInventoryExpress = 'stock.inventory_express',
  StockIncoming = 'stock.incoming',
  SameDayDefaultTotal = 'same_day.amount',
  SameDayDefaultEplus0 = 'same_day.e+0.amount',
  SameDayExpressTotal = 'same_day.amount_express',
  SameDayExpressEplus0 = 'same_day.e+0.amount_express',
  ZsbPlus0StandardBase = 'boimport.same_day_try.zsb+0_standard_base',
  ZsbPlus0StandardSuccessful = 'boimport.same_day_try.zsb+0_standard_successful',
  ZsbPlus0ExpressBase = 'boimport.same_day_try.zsb+0_express_base',
  ZsbPlus0ExpressSuccessful = 'boimport.same_day_try.zsb+0_express_successful',
  ComplaintQuoteCount = 'complaints.quote.count',
  ComplaintQuoteCountLossRelevant = 'complaints.quote.count_lossrelevant',
  AbwicklungsmengeZsb = 'boimport.abwicklungsmenge.zsb.count.total',
  AbwicklungsmengeAll = 'boimport.abwicklungsmenge.all.count.total',
  ABHbyReturnType = 'lma-shipmentbased.ABH.all.count.by_return_type',
  MITbyReturnType = 'lma-shipmentbased.MIT.all.count.by_return_type',
  ABHMITbyReturnType = 'lma-shipmentbased.ABH+MIT.all.count.by_return_type',
  ABHMITsum = 'lma-shipmentbased.ABH+MIT.all.count.total',
  ABHsum = 'lma-shipmentbased.ABH.all.count.total',
  MITsum = 'lma-shipmentbased.MIT.all.count.total',
  ATGsum = 'lma-shipmentbased.ATG.all.count.total',
  PAGsum = 'lma-shipmentbased.PAG.all.count.total',
  PSEbyReturnType = 'lma-shipmentbased.PSE.all.count.by_return_type',
  PSEsum = 'lma-shipmentbased.PSE.all.count.total',
  RAAbyUndeliverableReason = 'lma-shipmentbased.RAA.all.count.by_undeliverable_reason',
  RAAsum = 'lma-shipmentbased.RAA.all.count.total',
  RLbyUndeliverableReason = 'lma-shipmentbased.RL.all.count.by_undeliverable_reason',
  RLsum = 'lma-shipmentbased.RL.all.count.total',
  TAsum = 'lma-shipmentbased.TA.all.count.total',
  TARelevantSum = 'lma-shipmentbased.TA.relevant.count.total',
  TAAsum = 'lma-shipmentbased.TAA.all.count.total',
  TAARelevantSum = 'lma-shipmentbased.TAA.relevant.count.total',
  ZAAsum = 'lma-shipmentbased.ZAA.all.count.total',
  ZUbyDeliveryType = 'lma-shipmentbased.ZU.all.count.by_delivery_type',
  ZUsum = 'lma-shipmentbased.ZU.all.count.total',
  RLExpressByUndeliverableReason = 'lma-shipmentbased.RL.express.count.by_undeliverable_reason',
  RLExpressSum = 'lma-shipmentbased.RL.express.count.total',
  TARelevantValuableSum = 'lma-shipmentbased.TA.relevant_valuable.count.total',
  TAValuableSum = 'lma-shipmentbased.TA.valuable.count.total',
  TABulkyTotalCount = 'lma-shipmentbased.TA.relevant_bulky.count.total',
  ZABulkyZugestellt = 'lma-shipmentbased.ZU.bulky.count.total',
  TARelevantByShipmentType = 'lma-shipmentbased.TA.relevant.count.by_shipment_type',
  BillingTourDateStandard = 'alen.lde.billing_tourdate.1000.no-pauschal',
  BillingTourDateBulky = 'alen.lde.billing_tourdate.1101.no-pauschal',
  BillingTourDateIdentservice = 'alen.lde.billing_tourdate.1102.no-pauschal',
  BillingTourDateNachnahme = 'alen.lde.billing_tourdate.1103.no-pauschal',
  BillingTourDateGepaeckabholung = 'alen.lde.billing_tourdate.1104.no-pauschal',
  BillingTourDateZeitfenster = 'alen.lde.billing_tourdate.1105.no-pauschal',
  BillingTourDateZusMitnahme = 'alen.lde.billing_tourdate.1201.no-pauschal',
  BillingTourDateQuittungslos = 'alen.lde.billing_tourdate.1202.no-pauschal',
  BillingTourDateCoicident = 'alen.lde.billing_tourdate.1203.no-pauschal',
  BillingTourDatePSStopps = 'alen.lde.billing_tourdate.2000.no-pauschal',
  BillingTourDatePSSendungen = 'alen.lde.billing_tourdate.2001.no-pauschal',
  BillingTourDateATGStopps = 'alen.lde.billing_tourdate.3000.no-pauschal',
  BillingTourDateATGSendungen = 'alen.lde.billing_tourdate.3001.no-pauschal',
  BillingTourDateEilSendungen = 'alen.lde.billing_tourdate.9000.no-pauschal',
  Altgeraetemitnahme = 'alen.lde.billing_tourdate.1106.no-pauschal',
  AnomaliesWzfHTZustellung = 'lma-anomalies.wzf.ht-zustellung.count',
  AnomaliesWzfHTAbholung = 'lma-anomalies.wzf.ht-abholung.count',
  AnomaliesWzfAtgAbholung = 'lma-anomalies.wzf.atg-abholung.count',
  AnomaliesWzfUnklassifiziert = 'lma-anomalies.wzf.unklassifiziert.count',
  AnomaliesZustelltag = 'lma-anomalies.hr.zustelltag.count',
  AnomaliesWunschtag = 'lma-anomalies.uv.annahmeverweigerung-wunschtag.count',
  AnomaliesZustellzeit = 'lma-anomalies.hr.zustellzeit.count',
  AnomaliesTeillieferung = 'lma-anomalies.s.colli-teillieferung.count',
  AnomaliesRuecklaufAmTourende = 'lma-anomalies.usk.ruecklauf-am-tourende.count',
  TourDauerAnfahrt = 'lma-tours.route.duration.outward',
  TourDauerRückfahrt = 'lma-tours.route.duration.return',
  TourDauerStops = 'lma-tours.route.duration.stops',
  TourStreckeAnfahrt = 'lma-tours.route.distance.outward',
  TourStreckeRückfahrt = 'lma-tours.route.distance.return',
  TourStreckeStops = 'lma-tours.route.distance.stops',
  TourStoppsHt = 'lma-tours.tourstop.count.ht',
  TourStoppsPs = 'lma-tours.tourstop.count.ps',
  TourStoppsAtg = 'lma-tours.tourstop.count.atg',
  BruttoZustellzeitGesamt = 'lma-tours.grosstourtime.total',
  NettoHTZeit = 'lma-tours.nettourtime.ht',
  NettoPSZeit = 'lma-tours.nettourtime.ps',
  NettoATGZeit = 'lma-tours.nettourtime.atg',
  SchadenVerlustGutschriftenAnzahl = 'boimport.schaden_verlust.credits.all.count.total',
  SchadenVerlustRegresseAnzahl = 'boimport.schaden_verlust.recourses.all.count.total',
  TourfreigabeZeitpunkt = 'lma-tours.tourreleasetime.total',
  ReihenfolgeeinhaltungQuote = 'planned_stops.order_compliance.quote',
}
