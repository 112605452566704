import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import ruecklaufer from './ruecklaufer';
import istLademenge from './lademenge-ist';

const expression = new QuotientValueExpression(
  ruecklaufer,
  istLademenge,
  'RL-Quote',
  true,
  null,
  null,
  getDefinitionByName('Rücklaufquote')
);

expression.setIdentifier('ruecklaufquote');

export default expression;
