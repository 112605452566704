import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.TourLoadedDeliveryitemsServicesEilserviceCount,
  label: 'Sendungen mit Eilservice',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'delivery-items',
  description:
    '<p>Anzahl aller auf der geladenen Tour geplanten Empfänger mit einer Eilservice-Sendung gezählt nach ' +
    'dem ersten abgeschlossenen Zustellvorgang</p>\n',
});

export default new SingleValueExpression(metric);
