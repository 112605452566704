import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import BasePayload from '../../common/models/websocket/payloads/BasePayload';
import { PlainObjectOf } from '../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';
import { DefaultType, MemberType } from '../../utils/Model';
import { Duration } from '../models/enums/Duration';

// Caution: Changing this value can result in data loss for the user
// as it's used to get configuration using it as a key
export enum ConfigurationKey {
  KpiOverview = 'dashboard.kpi_overview',
  DashboardTable = 'dashboard.table',
  DashboardChart = 'dashboard.chart',
  DashboardComponents = 'dashboard.components',
  RuecklaufTable = 'ruecklauf.table',
  KennzahlenTable = 'kennzahlen.table',
  HinweiseTable = 'hinweise.table',
  BeanstandungenTable = 'beanstandungen.table',
  TourenTable = 'touren.table',
  tourenTableAllgemein = 'touren.table.allgemein',
  tourenTableHTProduktivitaet = 'touren.table.htproduktivitaet',
  tourenTablePSProduktivitaet = 'touren.table.psproduktivitaet',
  tourenTableATGProduktivitaet = 'touren.table.atgproduktivitaet',
  tourenTableRuecklaufquote = 'touren.table.ruecklaufquote',
  tourenTableProduktivitaet = 'touren.table.produktivitaet',
}

export type ValueExpressionIdentifier = string;
export type EnrichedKpiTile = {
  identifier: string;
  valueExpression: ValueExpression;
};

export type KpiTab = {
  name: string;
  tiles: ValueExpressionIdentifier[];
};

export interface LegacyKpiOverviewConfig {
  duration: Duration;
  boxes?: ValueExpressionIdentifier[];
}

export interface KpiOverviewConfig {
  duration: Duration;
  tabs: KpiTab[];
}

export class ConfigEntry<T> extends BasePayload<ConfigEntry<T>> {
  configKey: ConfigurationKey;
  value: T;

  constructor(source: PlainObjectOf<ConfigEntry<T>>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<ConfigEntry<T>> {
    return undefined;
  }

  getMembers(): MemberType<ConfigEntry<T>> {
    return undefined;
  }
}
