import { useValueExpressionContext } from '@contexts/value-expression-context';
import { MetricTypeKey } from '@contexts/value-expression-context/value-expressions/metric-type-keys';
import { DragEndEvent, DragOverEvent } from '@dnd-kit/core';
import { useUserConfig } from '@hooks/use-user-config-hook';
import { Duration, Durations } from '@legacy-modules/dashboard/models/enums/Duration';
import {
  ConfigurationKey,
  KpiOverviewConfig,
  LegacyKpiOverviewConfig,
} from '@legacy-modules/dashboard/websocket/ConfigPayload';
import { ContractorUtils } from '@legacy-modules/utils/tours/ContractorUtils';
import arrayMove from 'array-move';
import { useCallback, useState, useMemo } from 'react';

const KPI_OVERVIEW_DEFAULT_EMPLOYEE_KEYS: string[] = [
  'same_day.e_plus_0',
  'same_day.e_plus_0_express',
  'ruecklaufquote',
  'ht-produktivitaet',
  'complaints.quote.count/complaints.quote.base',
  'stock.remaining_per_inventory',
  MetricTypeKey.StockRemainingExpress,
];

const KPI_OVERVIEW_DEFAULT_CONTRACTOR_KEYS: string[] = [
  MetricTypeKey.TARelevantSum,
  'soll_lademenge/touren',
  'ruecklaufquote',
  'ht-produktivitaet',
  'zustellungen',
  'tour_loaded.count',
];

export const getDefaultKeysForOrgKey = (orgKey: string) => {
  return !orgKey || ContractorUtils.isContractor(orgKey)
    ? KPI_OVERVIEW_DEFAULT_CONTRACTOR_KEYS
    : KPI_OVERVIEW_DEFAULT_EMPLOYEE_KEYS;
};

type UseKpiTabsOutput = {
  config: KpiOverviewConfig;
  configLoading: boolean;
  activeTab: number;
  canAddMoreTabs: boolean;
  onTabChange: (tab: number) => void;
  onTabAdd: (tabName?: string) => void;
  onKpiAdd: (tileName: string) => void;
  onKpiRemove: (kpiIndex: number) => void;
  onKpiChange: (oldName: string, newName: string) => void;
  onKpiSort: (oldIndex: number, newIndex: number) => void;
  onDurationSelection: (duration: Duration) => void;
  onTabDragOver: (e: DragOverEvent) => void;
  onTabDragEnd: (e: DragEndEvent) => void;
  onTabRemove: (tabIndex: number) => void;
  onTabRename: (tabIndex: number, tabName: string) => void;
};

const defaultConfig: KpiOverviewConfig = {
  duration: Durations.yesterday as Duration,
  tabs: [],
};

const MAXIMUM_TAB_AMOUNT = 5;

export default function useKpiTabs(orgKey: string, initialActiveTabIndex: number = 0): UseKpiTabsOutput {
  const valueExpressions = useValueExpressionContext();

  const [activeTab, setActiveTab] = useState(initialActiveTabIndex);

  const [loadedConfig, updateKpiOverviewConfig, configLoading] = useUserConfig<
    LegacyKpiOverviewConfig | KpiOverviewConfig
  >(ConfigurationKey.KpiOverview);

  const tabsConfig: KpiOverviewConfig = useMemo(() => {
    // We're still loading, return default config.
    if (configLoading) {
      return defaultConfig;
    }

    if (!loadedConfig) {
      // Fallback for new users without any config at all.
      return { ...defaultConfig, tabs: [{ name: 'Allgemein', tiles: getDefaultKeysForOrgKey(orgKey) }] };
    } else if (!(loadedConfig as LegacyKpiOverviewConfig).boxes && !(loadedConfig as KpiOverviewConfig).tabs) {
      // Fallback when config contains neither boxes nor tabs.
      return {
        duration: loadedConfig?.duration || defaultConfig.duration,
        tabs: [{ name: 'Allgemein', tiles: getDefaultKeysForOrgKey(orgKey) }],
      };
    } else if ((loadedConfig as LegacyKpiOverviewConfig)?.boxes) {
      // Fallback for old config before tabs existed.
      // boxes was saved into the config as direct "boxes" prop instead of per tab.
      const tiles = (loadedConfig as LegacyKpiOverviewConfig)?.boxes?.filter((box) => valueExpressions.has(box));
      return {
        duration: loadedConfig?.duration || defaultConfig.duration,
        tabs: [{ name: 'Allgemein', tiles: [...new Set(tiles)] }],
      };
    } else {
      // Case for new overview config. Filter out tiles that are not available anymore.
      const filteredTabs = (loadedConfig as KpiOverviewConfig)?.tabs?.map((tab) => {
        const tiles = [...tab.tiles]?.filter((tile) => valueExpressions.has(tile));
        return {
          ...tab,
          tiles: [...new Set(tiles)],
        };
      });
      return { duration: loadedConfig?.duration || defaultConfig.duration, tabs: filteredTabs };
    }
  }, [valueExpressions, loadedConfig, configLoading, orgKey]);

  const [canAddMoreTabs, allCurrentTabNames] = useMemo(() => {
    return [tabsConfig?.tabs?.length < MAXIMUM_TAB_AMOUNT, tabsConfig?.tabs?.map((tab) => tab.name)];
  }, [tabsConfig]);

  const onTabAdd = useCallback(() => {
    if (!canAddMoreTabs) {
      return;
    }
    const newConfig = {
      ...tabsConfig,
      tabs: [...tabsConfig.tabs, { name: getAvailableTabName('Neuer Tab', allCurrentTabNames), tiles: [] }],
    };
    setActiveTab(newConfig.tabs.length - 1);
    updateKpiOverviewConfig(newConfig);
  }, [allCurrentTabNames, canAddMoreTabs, tabsConfig, setActiveTab, updateKpiOverviewConfig]);

  const onTabDragOver = useCallback(
    (e: DragOverEvent) => {
      const currentOrder: string[] = e.active.data.current?.sortable?.items;
      const dragIndex = currentOrder.indexOf(e.active?.id as string);
      const dropIndex = currentOrder.indexOf(e.over?.id as string);
      if (dragIndex === -1 || dropIndex === -1) {
        return;
      }
      updateKpiOverviewConfig({ ...tabsConfig, tabs: arrayMove(tabsConfig.tabs, dragIndex, dropIndex) });
    },
    [tabsConfig, updateKpiOverviewConfig]
  );

  const onTabDragEnd = useCallback(
    (e: DragEndEvent) => {
      const dropIndex = e.active.data.current?.sortable?.items?.indexOf(e.over?.id as string);
      setActiveTab(dropIndex);
      updateKpiOverviewConfig(tabsConfig);
    },
    [tabsConfig, updateKpiOverviewConfig]
  );

  const onTabRemove = useCallback(
    (tabIndex: number) => {
      const newTabs = [...tabsConfig.tabs];
      newTabs.splice(tabIndex, 1);
      const newConfig = { ...tabsConfig, tabs: newTabs };
      setActiveTab((currentTabIdx) => Math.max(currentTabIdx - 1, 0));
      updateKpiOverviewConfig(newConfig);
    },
    [tabsConfig, updateKpiOverviewConfig]
  );

  const onTabRename = useCallback(
    (tabIndex: number, tabName: string) => {
      const newTabs = [...tabsConfig.tabs];
      const takenTabNames = [...allCurrentTabNames];
      takenTabNames.splice(tabIndex, 1);
      newTabs[tabIndex].name = getAvailableTabName(tabName, takenTabNames);
      updateKpiOverviewConfig({ ...tabsConfig, tabs: newTabs });
    },
    [allCurrentTabNames, tabsConfig, updateKpiOverviewConfig]
  );

  const onKpiRemove = useCallback(
    (kpiIndex: number) => {
      const newTabs = [...tabsConfig.tabs];
      if (newTabs[activeTab]?.tiles?.length > kpiIndex) {
        newTabs[activeTab].tiles.splice(kpiIndex, 1);
      }
      updateKpiOverviewConfig({ ...tabsConfig, tabs: newTabs });
    },
    [tabsConfig, activeTab, updateKpiOverviewConfig]
  );

  const onKpiAdd = useCallback(
    (tile: string) => {
      const newTabs = [...tabsConfig.tabs];
      newTabs[activeTab]?.tiles?.push(tile);
      updateKpiOverviewConfig({ ...tabsConfig, tabs: newTabs });
    },
    [tabsConfig, activeTab, updateKpiOverviewConfig]
  );

  const onKpiChange = useCallback(
    (oldKpiName: string, newKpiName: string) => {
      const tabs = [...tabsConfig.tabs];
      const tiles = tabs[activeTab].tiles;
      const tilesIndex = tiles.findIndex((identifier) => identifier === oldKpiName);
      tiles[tilesIndex] = newKpiName;
      updateKpiOverviewConfig({ ...tabsConfig, tabs });
    },
    [activeTab, tabsConfig, updateKpiOverviewConfig]
  );

  const onKpiSort = useCallback(
    (oldKpiIndex: number, newKpiIndex: number) => {
      const newTabs = [...tabsConfig.tabs];
      const newTiles = arrayMove(newTabs[activeTab].tiles, oldKpiIndex, newKpiIndex);
      newTabs[activeTab].tiles = newTiles;
      updateKpiOverviewConfig({ ...tabsConfig, tabs: newTabs });
    },
    [activeTab, tabsConfig, updateKpiOverviewConfig]
  );

  const onDurationSelection = useCallback(
    (duration: Duration) => {
      updateKpiOverviewConfig({ ...tabsConfig, duration });
    },
    [tabsConfig, updateKpiOverviewConfig]
  );

  return {
    config: tabsConfig,
    configLoading,
    activeTab,
    canAddMoreTabs,
    onTabChange: setActiveTab,
    onTabAdd,
    onKpiAdd,
    onKpiRemove,
    onKpiChange,
    onDurationSelection,
    onKpiSort,
    onTabDragOver,
    onTabDragEnd,
    onTabRemove,
    onTabRename,
  };
}

const getAvailableTabName = (targetName: string, tabNames: string[]) => {
  let increment = 0;
  let numberedName = targetName;
  while (tabNames.includes(numberedName)) {
    increment++;
    numberedName = `${targetName} ${increment}`;
  }
  return increment ? numberedName : targetName;
};
