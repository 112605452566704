import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TourUtils } from '../../../../utils/tours/TourUtils';
import { Loader } from '../../../../common/components/Loader';
import Styles from '../ComplaintsChart.module.scss';
import { Duration } from '../../../../dashboard/models/enums/Duration';
import { Table, TableColumn, TableRow } from '../../../../common/components/table/Table';
import { useBeanstandungenData } from '@data-table/hooks/use-beanstandungen-data-hook';
import { selectDashboardOrgKey } from '@redux/dashboard.selectors';
import { ValuableComplaintReasons } from '@legacy-modules/complaints/models/enums/ValuableComplaintsReason';

const valuableReasons = Object.values(ValuableComplaintReasons) as string[];

type Props = {
  orgKey: string;
  duration: Duration;
  onlyValuable: boolean;
  resultCount?: number;
};

export const FlopTourComplaints: React.FC<Props> = ({ duration, onlyValuable, resultCount = 5 }: Props) => {
  const orgKey = useSelector(selectDashboardOrgKey);
  const [complaints, loading] = useBeanstandungenData(orgKey, duration, !!orgKey);

  const topTours = useMemo(() => {
    const map = complaints?.reduce((prev, next) => {
      if (!next.tourIdentifier.tourNumber || (onlyValuable && !valuableReasons.includes(next.beanstandungGrund))) {
        return prev;
      } else if (prev.has(next.tourIdentifier.tourNumber)) {
        prev.set(next.tourIdentifier.tourNumber, prev.get(next.tourIdentifier.tourNumber) + 1);
      } else {
        prev.set(next.tourIdentifier.tourNumber, 1);
      }
      return prev;
    }, new Map<string, number>());
    return Array.from(map.entries())
      .sort((a, b) => {
        if (b[1] - a[1] === 0) {
          return Number(a[0]) - Number(b[0]);
        } else {
          return b[1] - a[1];
        }
      })
      .splice(0, resultCount);
  }, [complaints, onlyValuable, resultCount]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={Styles.Headline}>
        <span>Touren mit den meisten Beanstandungen</span>
      </div>

      <Table
        alteringColors
        body={topTours.map(([tour, count]) => (
          <TableRow key={tour}>
            <TableColumn variant={'small'}>{TourUtils.getTourNumberByOrgKey(tour)}</TableColumn>
            <TableColumn variant={'small'} isNumber>
              {count}
            </TableColumn>
          </TableRow>
        ))}
      />
    </>
  );
};

export default FlopTourComplaints;
