import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import anzahlTagEPlus0 from './anzahl-tag-e-plus-0';
import anzahlTag from './anzahl-tag';

const expression = new QuotientValueExpression(
  anzahlTagEPlus0,
  anzahlTag,
  'E+0 Standard',
  true,
  undefined,
  undefined,
  getDefinitionByName('E+0 Standard')
);

expression.chartRenderOptions.startYAxisOnZero = false;
expression.setIdentifier('same_day.e_plus_0');

export default expression;
