import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

type Props = {
  className?: string;
  value?: React.ReactText;
  onValueChange: (decimal: number) => void;
  onBlur?: () => void;
  name?: string;
  tabIndex?: number;
};

const FloatNumberInput = forwardRef<NumberFormat<Props>, Props>(
  ({ className = '', value, onValueChange, onBlur, name, tabIndex, ...other }: Props, ref) => {
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        className={className}
        value={value}
        name={name}
        decimalSeparator={','}
        displayType={'input'}
        thousandSeparator={'.'}
        tabIndex={tabIndex}
        onBlur={onBlur}
        onValueChange={(values) => {
          const { value } = values;

          if (value == null) {
            onValueChange && onValueChange(null);
            return;
          }

          const parsedValue = Number.parseFloat(value);
          onValueChange && onValueChange(parsedValue);
        }}
      />
    );
  }
);

FloatNumberInput.displayName = 'FloatNumberInput';
export default FloatNumberInput;
