import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.FinishedDeliveriesCount,
  label: 'Zustellvorgänge',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'deliveries',
  description:
    '<p>Anzahl aller abgeschlossenen Zustellvorgänge, bei der ein Zustellvorgang die Bearbeitung eines oder mehrerer ' +
    'Pakete für ein oder mehrere Empfänger ist</p>\n',
});

export default new SingleValueExpression(metric);
