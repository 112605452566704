import { Duration, Durations } from './Duration';

export const ComponentOptions = {
  DashboardTourListContainer: {
    duration: Durations.last_7,
  },
  mengenprognoseChartPrognose: {
    selectedChartType: 'bar',
    duration: Durations.next_56,
  },
  dashboardTable: {
    sorting: {
      order: 'asc',
      field: 'name',
    },
    duration: Durations.yesterday,
  },
  tourenTableProduktivitaet: {
    sorting: {
      order: 'asc',
      field: 'produktivitaet',
    },
    duration: Durations.last_7,
  },
  tourenTableHTProduktivitaet: {
    sorting: {
      order: 'asc',
      field: 'ht-produktivitaet',
    },
    duration: Durations.last_7,
  },
  tourenTablePSProduktivitaet: {
    sorting: {
      order: 'asc',
      field: 'ps-produktivitaet',
    },
    duration: Durations.last_7,
  },
  tourenTableATGProduktivitaet: {
    sorting: {
      order: 'asc',
      field: 'atg-produktivitaet',
    },
    duration: Durations.last_7,
  },
  tourenTableAllgemein: {
    sorting: {
      field: 'number',
      order: 'asc',
    },
    duration: Durations.last_7,
  },
  kpiDashboardOverview: {
    duration: Durations.last_7,
  },
  tourenTableRuecklaufquote: {
    sorting: {
      order: 'desc',
      field: 'ruecklaufquote',
    },
    duration: Durations.last_7,
  },
  produktivitaetChartKpi: {
    selectedChartType: 'line',
    duration: Durations.last_7,
  },
  produktivitaetTableTouren: {
    sorting: {
      order: 'desc',
      field: 'produktivitaet',
    },
    duration: Durations.last_7,
  },
  produktivitaetChartKpiParameters: {
    showAverage: true,
    selectedIndex: 0,
    duration: Durations.last_7,
  },
  ruecklaufChartRuecklaufquote: {
    selectedChartType: 'line',
    duration: Durations.last_7,
  },
  ruecklaufTableTouren: {
    sorting: {
      order: 'desc',
      field: 'ruecklaufquote',
    },
    additionalVeKeys: [],
    duration: Durations.last_7,
  },
  ruecklaufChartRuecklaufgruende: {
    selectedChartType: 'lineStacked',
    duration: Durations.last_7,
  },
  SbDetailsChart: {
    selectedChartType: 'bar',
    duration: Durations.last_7,
  },
  kpiCompareChart: {
    duration: Durations.last_28,
  },
  kennzahlenTableTouren: {
    sorting: {
      order: 'desc',
      field: 'soll_lademenge/touren',
    },
    additionalVeKeys: [],
    duration: Durations.last_28,
  },
  ComplaintsDetailTable: {
    duration: Durations.last_7,
    sorting: {
      order: 'desc',
      field: 'date',
    },
    perPage: 15,
    page: 1,
    selectedReason: '',
    onlyValuable: false,
  },
  SbTopChart: {
    duration: Durations.last_7,
    veKey: 'sb_all',
  },
  ComplaintsTourTable: {
    duration: Durations.last_7,
    sorting: {
      order: 'desc',
      field: 'sb/tour',
    },
    additionalVeKeys: [],
  },
};

export const defaultDuration: (
  arg0: string
) => Pick<Duration, 'key' | 'mode' | 'label' | 'offset' | 'duration' | 'range' | 'isVisibleInForecast'> = (id) => {
  const obj = ComponentOptions[id];
  return obj ? (obj.duration as Duration) : Durations.last_7;
};
