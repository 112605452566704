import React, { useCallback } from 'react';

type Props = {
  onChange: (val: number) => void;
  value: number;
  min: number;
  max: number;
  step?: number;
};

export const Range: React.FC<Props> = ({ onChange, value, min, max, step = 1 }) => {
  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(Number.parseInt(event.target.value));
    },
    [onChange]
  );

  return <input onChange={onChangeCallback} type='range' min={min} max={max} step={step} value={value} />;
};
