import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useIsMounted } from '../../dashboard/hooks/useIsMounted';

export type IImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  loadFn: () => Promise<string>;
  selected?: boolean;
  onDeselect?: () => void;
  onSelect?: () => void;
};

const Image = React.forwardRef<IImageProps, any>((props: IImageProps, ref: any) => {
  const { src, loadFn, selected, onSelect, onDeselect, alt, style, ...otherProps } = props;

  const [imageSrc, setImageSrc] = useState<string>();
  const [error, setError] = useState(null);
  const mounted = useIsMounted();

  useEffect(() => {
    if (src && src !== imageSrc) {
      setImageSrc(src);
    }
    if (loadFn) {
      loadFn()
        .then((src) => {
          if (!mounted.current) {
            return;
          }
          setImageSrc(src);
        })
        .catch((e) => {
          if (!mounted.current) {
            return;
          }
          setError(e);
        });
    }
  }, [imageSrc, loadFn, mounted, src]);

  const onClickCallback = useCallback(() => {
    if (!selected) {
      onSelect && onSelect();
    } else {
      onDeselect && onDeselect();
    }
  }, [onDeselect, onSelect, selected]);

  return (
    <>
      {error ? (
        <span>Bild konnte nicht geladen werden: {error}</span>
      ) : (
        <img
          {...otherProps}
          className={classNames('PhotoItem-Image')}
          src={imageSrc}
          alt={alt}
          ref={ref}
          style={style}
          onClick={onClickCallback}
        />
      )}
    </>
  );
});

Image.displayName = 'Image';

export { Image };
