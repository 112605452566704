import { ILimitBounds } from '../websocket/DashboardLoadLimitResponsePayload';
import { PositiveDirection } from '../../metrics2/models/entities/PositiveDirection';

export enum LimitType {
  LIMIT = 'limit',
  CUSTOM_DIRECTION = 'custom_drection',
  TARGET_AREA = 'target_area',
}

export abstract class AbstractLimit {
  protected constructor(public readonly type: LimitType) {}
}

export type LimitClassTypes = DefaultLimit | TargetArea | CustomLimit;

export class DefaultLimit extends AbstractLimit implements ILimitBounds {
  constructor(public readonly positive: number, public readonly negative: number) {
    super(LimitType.LIMIT);
  }
}
export class TargetArea extends AbstractLimit {
  constructor(public readonly targetAreas: Area) {
    super(LimitType.TARGET_AREA);
  }
}

export type Area = [number, number];

export class CustomLimit extends AbstractLimit {
  constructor(
    public readonly direction: PositiveDirection.up | PositiveDirection.down,
    public readonly limit: ILimitBounds
  ) {
    super(LimitType.CUSTOM_DIRECTION);
  }
}
