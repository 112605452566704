import React, { useCallback, useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Tooltip } from '../../common/components/Tooltip';
import Styles from './DetailedChartTooltip.module.scss';
import classNames from 'classnames';
import { Dot, DotColor } from '../../common/components/Dot';
import { ChartTooltipLabelColor } from 'chart.js';
import { Position } from '../types/Position';

export type TooltipModel = {
  _bodyFontFamily: string;
  xPadding: number;
  yPadding: number;
  _bodyFontStyle: string;
  bodyFontSize: string;
  opacity: number;
  x: number;
  y: number;
  title: Array<string>;
  body: Array<{
    lines: Array<string>;
    after: Array<string>;
    before: Array<string>;
  }>;
  beforeBody?: string[];
  labelColors: Array<ChartTooltipLabelColor & { label: string }>;
};

export type Props = {
  target: Line | Bar;
  activeTooltipIndex: number;
  model: TooltipModel;
  // shows the target div where the tooltip points to. setting this to true makes debugging exact positions easier
  showTarget?: boolean;
  position: Position;
  xOffset?: number;
  compareFallbackColor?: string;
};

const ChartTooltip = ({
  target,
  model,
  activeTooltipIndex,
  position,
  showTarget = false,
  xOffset = 0,
  compareFallbackColor,
}: Props) => {
  const [chartJsTooltip, setChartJsTooltip] = useState<HTMLDivElement | null>(null);

  const [chartInstance, setChartInstance] = useState(null);
  const [hide, setHidden] = useState(false);

  useEffect(() => {
    if (!target) {
      return;
    }
    setChartInstance(target.chartInstance);
  }, [target, setChartInstance]);

  const toggleVisibility = useCallback(
    (visible: boolean) => {
      setHidden(!visible);
    },
    [setHidden]
  );

  const showFn = useCallback(() => {
    toggleVisibility(true);
  }, [toggleVisibility]);
  const hideFn = useCallback(() => {
    toggleVisibility(false);
  }, [toggleVisibility]);

  useEffect(() => {
    if (!chartInstance || !chartInstance.canvas || !model) {
      return;
    }
    chartInstance.canvas.addEventListener('mouseout', hideFn);
    chartInstance.canvas.addEventListener('mouseover', showFn);
    return () => {
      if (!chartInstance?.canvas) {
        return;
      }
      chartInstance.canvas.removeEventListener('mouseout', hideFn);
      chartInstance.canvas.removeEventListener('mouseover', showFn);
    };
  }, [target, chartInstance, showFn, hideFn, model]);

  const style: React.CSSProperties = model
    ? {
        opacity: hide ? '0' : '1',
        position: 'absolute',
        height: 1,
        width: 1,
        maxWidth: '1px',
        maxHeight: '1px',
        top: (chartInstance?.canvas?.offsetTop || 0) + position.y,
        left: (chartInstance?.canvas?.offsetLeft || 0) + xOffset + position.x,
        fontFamily: model._bodyFontFamily,
        fontSize: model.bodyFontSize + 'px',
        fontStyle: model._bodyFontStyle,
        pointerEvents: 'none',
        paddingTop: 5,
        visibility: hide ? 'hidden' : 'visible',
      }
    : {};

  const getBody = (() => {
    if (!model?.body) {
      return null;
    }

    const title = model.title[0];
    const bodyLines = model.body.map((body) => {
      return {
        label: body.lines[0],
        value: body.after[0],
        datasetIndex: parseInt(body.before[0]),
      };
    });

    const totalFormatted = (
      <>
        {model?.beforeBody?.length > 0 && (
          <div className={classNames(Styles.Row, Styles.sum)}>
            <div className={Styles.Label}>Gesamt:</div>
            <span className={Styles.Value}>{model.beforeBody[0]}</span>
          </div>
        )}
      </>
    );

    const colors = model.labelColors;

    if (hide) {
      return null;
    }

    return (
      <>
        <div className={Styles.Title}>{title}</div>
        {totalFormatted}
        {bodyLines.reverse().map((body) => {
          const color: string = (() => {
            if (colors.length === 1) {
              return colors[0].backgroundColor as string;
            }

            const foundColor = colors.find((c) => c.label === body.label);

            if (foundColor) {
              return foundColor.backgroundColor as string;
            }

            if (colors[body.datasetIndex]?.backgroundColor) {
              return colors[body.datasetIndex]?.backgroundColor as string;
            }
            if (body.datasetIndex === 0) {
              return DotColor.primary;
            }
            if (body.datasetIndex === 1) {
              return compareFallbackColor || DotColor.compare;
            }
            return DotColor.primary;
          })();

          return (
            <div
              key={body.datasetIndex}
              className={classNames(Styles.Row, {
                [Styles.active]: body.datasetIndex === activeTooltipIndex,
              })}>
              <div className={Styles.Label}>
                <span className={Styles.Badge}>
                  <Dot color={color} />
                </span>
                {body.label}
              </div>
              <span className={Styles.Value}>{body.value}</span>
            </div>
          );
        })}
      </>
    );
  })();

  const isTooltipVisible = !hide && model && model.opacity === 1;

  const hasValues = model?.body?.some((bodyLine) => bodyLine.after[0] != null && bodyLine.after[0] !== 'null');

  if (!model?.body?.length || !hasValues) {
    return null;
  }

  return (
    <>
      <div
        style={
          !showTarget
            ? style
            : {
                ...style,
                maxWidth: 5,
                maxHeight: 5,
                width: 5,
                height: 5,
              }
        }
        ref={setChartJsTooltip}
      />
      <div style={{ pointerEvents: 'none' }}>
        <Tooltip
          key={`${position?.x}${position?.y}}`}
          anchorElement={chartJsTooltip}
          visible={isTooltipVisible}
          placement={'top'}
          offset={[-5, 15]}>
          <div className={Styles.Inner}>
            <section className={Styles.Content}>{getBody}</section>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

ChartTooltip.displayName = 'ChartTooltip';

export default ChartTooltip;
