import DeepLinkService from '@legacy-modules/routing/services/DeepLinkService';
import React, { createContext, PropsWithChildren, ReactElement, useContext } from 'react';

export type DeepLinkContextType = DeepLinkService;

const defaultValue: DeepLinkContextType = null;
export const DeepLinkContext = createContext(defaultValue);

export const useDeepLinkContext = (): DeepLinkContextType => {
  return useContext<DeepLinkContextType>(DeepLinkContext);
};

type DeepLinkContextProviderProps = Required<
  PropsWithChildren<{
    deepLinkService: DeepLinkService;
  }>
>;

export default function DeepLinkContextProvider({
  children,
  deepLinkService,
}: DeepLinkContextProviderProps): ReactElement {
  return <DeepLinkContext.Provider value={deepLinkService}>{children}</DeepLinkContext.Provider>;
}
