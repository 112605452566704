import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './ComplaintsChart.module.scss';
import { Duration, Durations } from '../../../dashboard/models/enums/Duration';
import DetailedChart from '../../../dashboard/charts/DetailedChart';
import { Row, Col, ButtonGroup, Button } from 'reactstrap';
import ComplaintsValueRows from './components/ComplaintsValueRows';
import FlopTourComplaints from './components/FlopTourComplaints';
import { ZSBUtils } from '../../../utils/tours/ZSBUtils';
import DateSelect, {
  DateSelectIncludingTodayConfig,
  DateSelectionDefaultConfig,
} from '../../../common/components/DateSelect';
import { FloatingComponent } from '../../../common/components/FloatingComponent';
import { ComponentHeader } from '../../../common/components/ComponentHeader';
import { useAuthContext } from '@contexts/auth-context';
import { selectDashboardOrgKey, selectLocalComponentOptions } from '@redux/dashboard.selectors';
import { dashboardSlice } from '@redux/dashboard.slice';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

export type ComplaintsChartOptions = {};

type Props = {
  uID: string | null | undefined;
  title: string;
  hideTodayInSelection?: boolean;
};

function ComplaintsChart({ title, uID, hideTodayInSelection }: Props) {
  const dispatch = useDispatch();
  const orgKey = useSelector(selectDashboardOrgKey);
  const options = useSelector(selectLocalComponentOptions(uID, 'default'));
  const duration = options?.duration || Durations.last_7;
  const valueExpressionKey = options?.valueExpressionKey || 'sb_all';

  const authService = useAuthContext();
  const componentOptionsToState = useCallback(
    (update = {}) => {
      dispatch(
        dashboardSlice.actions.setLocalComponentOptions({
          componentId: uID,
          optionKey: 'default',
          value: update,
        })
      );
    },
    [dispatch, uID]
  );

  const onDurationSelected = useCallback(
    (duration: Duration) => {
      componentOptionsToState({ duration });
    },
    [componentOptionsToState]
  );

  const showChart = authService.can('complaints');
  const complaintsHeadline = useMemo(() => {
    if (!valueExpressionKey) {
      return 'Beanstandungsgründe';
    } else if (valueExpressionKey === 'sb_all') {
      return 'Häufigste Beanstandungsgründe';
    } else if (valueExpressionKey === 'sb_verlust') {
      return 'Verlustrelevante Beanstandungsgründe';
    } else {
      return 'Beanstandungsgründe';
    }
  }, [valueExpressionKey]);

  if (!showChart) {
    return null;
  }
  return (
    <FloatingComponent>
      <ComponentHeader
        headline={title}
        right={
          <DateSelect
            selectedDuration={duration}
            onDurationSelected={onDurationSelected}
            config={hideTodayInSelection ? DateSelectionDefaultConfig : DateSelectIncludingTodayConfig}
          />
        }
      />
      <div className={Styles.ComplaintsChart}>
        <Row>
          <Col md='8'>
            <DetailedChart
              orgKey={orgKey}
              uID={uID}
              threshold={2}
              valueExpressionKey={valueExpressionKey}
              defaultChartType={ChartType.line}
              showKpiAvg={true}
              duration={duration}
              style={{ boxShadow: 'none', margin: 0 }}
            />
          </Col>
          <Col md='4'>
            <div className={Styles.Toggle}>
              <ButtonGroup className={Styles.ButtonGroup}>
                <Button
                  outline={valueExpressionKey !== 'sb_all'}
                  onClick={() => componentOptionsToState({ valueExpressionKey: 'sb_all' })}
                  color='primary'>
                  Alle
                </Button>
                <Button
                  outline={valueExpressionKey !== 'sb_verlust'}
                  onClick={() => componentOptionsToState({ valueExpressionKey: 'sb_verlust' })}
                  color='primary'>
                  Verlustrelevant
                </Button>
              </ButtonGroup>
              <div className={Styles.TotalValue}>
                <ComplaintsValueRows valueExpressionKey={valueExpressionKey} duration={duration} />
              </div>
            </div>
            <div className={Styles.TopReasons}>
              <ComplaintsValueRows
                headline={complaintsHeadline}
                valueExpressionKey='complaints.reported.count_by_type'
                onlyValuable={valueExpressionKey === 'sb_verlust'}
                duration={duration}
              />
            </div>
            {ZSBUtils.isZSB(orgKey) && (
              <div className={Styles.TopReasons}>
                <FlopTourComplaints
                  orgKey={orgKey}
                  onlyValuable={valueExpressionKey === 'sb_verlust'}
                  duration={duration}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </FloatingComponent>
  );
}

export default ComplaintsChart;
