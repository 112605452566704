import React, { InputHTMLAttributes, ReactElement, useState } from 'react';
import stylesheet from './radio-button.module.scss';
import classNames from 'classnames';

export type RadioButtonProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'value' | 'checked' | 'onChange' | 'defaultChecked'
> & {
  name: string;
  value: string;
  state: ReturnType<typeof useState<string>>;
  pixelSize?: number;
};

export function RadioButton({
  value,
  name,
  pixelSize,
  style,
  state,
  className,
  ...htmlProps
}: RadioButtonProps): ReactElement {
  const [selectedValue, selectValue] = state;
  return (
    <input
      type='radio'
      value={value}
      name={name}
      {...htmlProps}
      style={{
        ...style,
        width: pixelSize,
        height: pixelSize,
      }}
      className={classNames(stylesheet.radioButton, className)}
      onChange={(e) => selectValue(e?.target?.value)}
      checked={selectedValue === value}
      data-checked={selectedValue === value ? 'true' : 'false'}
    />
  );
}
export default RadioButton;
