export default function genericDeepCopy(obj: any) {
  if (obj instanceof Array) {
    return obj.map(genericDeepCopy);
  } else if (obj instanceof Object) {
    if (obj.clone instanceof Function) {
      return obj.clone();
    }
    const value = new obj.constructor();
    if (value.hydrateFrom instanceof Function) {
      value.hydrateFrom(obj);
    } else if (value instanceof Map) {
      obj.forEach((V, K) => value.set(K, genericDeepCopy(V)));
    } else if (value instanceof Set) {
      obj.forEach((V) => value.add(genericDeepCopy(V)));
    } else {
      Object.entries(obj).forEach(([key, val]) => {
        value[key] = genericDeepCopy(val);
      });
    }
    return value;
  }

  return obj;
}
