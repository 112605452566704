import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button, ButtonGroup, Input } from 'reactstrap';
import { ColumnFilterContentProps, ValueExpressionDataTableColumnMeta } from '@data-table/data-table.types';
import { FilterControls } from '../../filter-controls';
import sharedStyle from '../value-expression-filter-content.module.scss';

// TODO: refactor this component to make it more readable and maintainable
// check if all default hooks are indeed needed
// check if you can simplify the code using input ref
const QuotientenValueExpressionFilterContent = <T, V>({
  column,
  decimalPlaces = 2,
  onCancel,
  onConfirm,
}: ColumnFilterContentProps<T, V> & { decimalPlaces?: number }) => {
  const filterValue = column.getFilterValue() as V;

  const [value, setValue] = useState(() => {
    const _filterValue = filterValue?.toString()?.replace(/>|</g, '');
    if (_filterValue) {
      return Number(_filterValue)?.toLocaleString('de-DE');
    }
    return '';
  });

  const meta = column?.columnDef?.meta as ValueExpressionDataTableColumnMeta;

  const [toggleState, setToggleState] = useState<'greater' | 'less'>(
    filterValue?.toString()?.startsWith('<') ? 'less' : 'greater'
  );

  const title = useMemo(() => {
    return `${meta?.valueExpression?.getLabel() ?? 'Wert'} filtern`;
  }, [meta?.valueExpression]);

  const onConfirmCallback = useCallback(() => {
    onConfirm(filterValue);
  }, [filterValue, onConfirm]);

  const onCancelCallback = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const regexp = useMemo(() => {
    // This regular expression matches a number that can have up to 10 digits, optionally followed by a comma
    // and another up to 3 digits. The entire number is optional, meaning it can also match an empty string or undefined
    return decimalPlaces === 3 ? /^((100)|(\d{1,10})(,)?(\d{1,3})?)?$/ : /^((100)|(\d{1,10})(,)?(\d{1,2})?)?$/;
  }, [decimalPlaces]);

  const onValueChange = useCallback(
    (e) => {
      const inputValue: string = e.target.value;
      // This regular expression matches a number that can have up to 10 digits, optionally followed by a comma
      // and another digit. The entire number is optional, meaning it can also match an empty string or undefined
      const isValid = regexp.test(inputValue);
      if (isValid) {
        setValue(inputValue);
        if (inputValue === '') {
          column.setFilterValue(undefined);
          return;
        }
        const numberValue = Number(inputValue.replace(/,/g, '.'));
        if (isFinite(numberValue)) {
          column.setFilterValue(`${toggleState === 'less' ? '<' : '>'}${numberValue}`);
        }
      }
    },
    [column, toggleState, regexp]
  );

  const onFilterToggleChange = useCallback(
    (value) => () => {
      setToggleState(value);
      if (filterValue === '') {
        column.setFilterValue(undefined);
        return;
      }
      const _filterValue = filterValue?.toString()?.replace(/>|</g, '');
      if (isFinite(Number(_filterValue))) {
        column.setFilterValue(`${value === 'less' ? '<' : '>'}${_filterValue}`);
      }
    },
    [filterValue, column]
  );

  return (
    <div className={classNames(sharedStyle.filterContent)}>
      <FilterControls
        className={sharedStyle.valueExpressionFilterContent}
        cancelCallback={onCancelCallback}
        confirmCallback={onConfirmCallback}>
        <div className={sharedStyle.filterContentHeadline}>{title}</div>
        <div className={sharedStyle.valueExpressionFilterInput}>
          Nur Werte
          <ButtonGroup size='sm' className={sharedStyle.valueExpressionFilterButtonGroup}>
            <Button
              color={toggleState === 'greater' ? 'primary' : 'secondary'}
              outline={toggleState === 'greater' ? false : true}
              size='sm'
              onClick={onFilterToggleChange('greater')}>
              größer
            </Button>
            <Button
              color={toggleState === 'less' ? 'primary' : 'secondary'}
              outline={toggleState === 'less' ? false : true}
              size='sm'
              onClick={onFilterToggleChange('less')}>
              kleiner
            </Button>
          </ButtonGroup>
          <Input value={value} onChange={onValueChange} />
        </div>
      </FilterControls>
    </div>
  );
};

export default QuotientenValueExpressionFilterContent;
