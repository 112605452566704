import React from 'react';
import LastMileAdministrationIcon from '../assets/last_mile_administration_icon.svg';
import ZsbCockpitIcon from '../assets/zsb_cockpit.svg';
import SorterConfigurationIcon from '../assets/sorter_management.svg';
import DeviceManagementIcon from '../assets/device_management.svg';
import Styles from './AppMenu.module.scss';

/**
 * These components will not be needed anymore once the shared hermes sidebar component can be used
 */

const apps = [
  {
    label: 'MultiApp Cockpit',
    icon: DeviceManagementIcon,
    href: 'https://dm-ui.pps-prd.hermes-services.com/',
  },
  {
    label: 'Last Mile Administration Tool',
    icon: LastMileAdministrationIcon,
    href: 'https://last-mile-administration.hermesgermany.digital/',
  },
  {
    label: 'ZSB-Cockpit',
    icon: ZsbCockpitIcon,
    href: 'https://zsb-cockpit.hermesgermany.digital/',
  },
  {
    label: 'ZSB Sorter Konfiguration',
    icon: SorterConfigurationIcon,
    href: 'https://zsb-sorter-config.hermesgermany.digital/',
  },
  {
    label: 'Device Management',
    icon: DeviceManagementIcon,
    href: 'https://mpt-devicemanagement.hermesgermany.digital/',
  },
];

const AppTile = (props) => {
  return (
    <a className={Styles.AppTile} target='_blank' href={props.href} rel='noopener noreferrer'>
      {props.icon && <img src={props.icon} alt={`${props.label} Logo`} />}
      <div>
        <span>{props.label}</span>
      </div>
    </a>
  );
};

const AppMenu = () => {
  return (
    <div className={Styles.AppMenuWrapper}>
      <span>Hermes Apps</span>
      <div className={Styles.AppMenu}>
        {apps.map((app) => (
          <AppTile {...app} />
        ))}
      </div>
    </div>
  );
};

export default AppMenu;
