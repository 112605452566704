import React, { useCallback, useMemo, useRef, useState } from 'react';
import BaseSelect from '../../navigation/components/BaseSelect';
import KpiSelectorOverlay from './KpiSelectorOverlay';
import Styles from './KpiSelector.scss';
import { PopOver } from '../../common/components/PopOver';
import { Placement } from '@popperjs/core';
import { getKpiTree } from '../utils/KpiTree';
import { NodeData, TreeMenuNode } from '../../navigation/TreeMenu';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { useAuthContext } from '@contexts/auth-context';
import { useSelector } from 'react-redux';
import { selectDashboardOrgKey } from '@redux/dashboard.selectors';

type Props = {
  selectedValueExpressionKeys: Array<string>;
  dotColor?: string;
  placement?: Placement;
  onSelect: (arg0: string) => void;
  showDot?: boolean;
  categoriesSelectable?: boolean;
  tree?: TreeMenuNode<NodeData>[];
};

const NO_SELECTION_TEXT = 'Kennzahl wählen ...';

const KpiSelector: React.FC<Props> = (props: Props) => {
  const {
    selectedValueExpressionKeys,
    showDot = true,
    placement = 'bottom-start',
    dotColor,
    onSelect,
    categoriesSelectable,
    tree: treeFromProps,
  } = props;

  const authService = useAuthContext();
  const orgKey = useSelector(selectDashboardOrgKey);

  const [open, setOpen] = useState(false);

  const ref = useRef(null);
  const baseRef = useRef(null);

  const toggleSelectCallback = useCallback(() => setOpen((v) => !v), []);
  const closeCallback = useCallback(() => {
    if (!open) {
      return;
    }
    setOpen(false);
  }, [open]);

  const onSelectCallback = useCallback(
    (veKey: string) => {
      setOpen(false);
      onSelect(veKey);
    },
    [onSelect]
  );

  const valueExpressionMap = useValueExpressionContext();

  const selectedValueExpression = valueExpressionMap.get(props.selectedValueExpressionKeys[0]);

  const tree = useMemo(() => {
    if (!treeFromProps) {
      return getKpiTree(authService, valueExpressionMap, orgKey);
    }
    return treeFromProps;
  }, [authService, treeFromProps, valueExpressionMap, orgKey]);

  return (
    <div ref={baseRef} className='KpiSelector'>
      <div ref={ref}>
        <BaseSelect
          disabled={tree.length === 0}
          icon={showDot && <span className='badge' style={{ backgroundColor: dotColor }} />}
          onSelectClick={toggleSelectCallback}
          text={
            selectedValueExpressionKeys[0] && selectedValueExpression
              ? selectedValueExpression.getLabel()
              : NO_SELECTION_TEXT
          }
          style={{ width: 230, padding: '.25rem .5rem' }}
        />
      </div>
      <PopOver
        anchorElement={ref?.current}
        visible={open}
        placement={placement}
        // we need to offset x, because the selector itself has a padding
        // and the ref element is the wrapper element around it
        offset={[8, 2]}
        closeOnEscape
        closeOnClickOutside
        onClose={closeCallback}>
        <KpiSelectorOverlay
          tree={tree}
          onSelect={onSelectCallback}
          selected={selectedValueExpressionKeys}
          className={Styles.KpiOverlay}
          categoriesSelectable={categoriesSelectable}
        />
      </PopOver>
    </div>
  );
};

export default KpiSelector;
