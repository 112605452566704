import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavItem } from 'reactstrap';
import { OrgMultiSelect } from '../../navigation/components/OrganizationMultiSelect';
import { TreeMenuNode } from '../../navigation/TreeMenu';
import OrgUnitNode from '../../metrics2/models/websocket/org/OrgUnitNode';
import DashboardBreadcrumb from '../components/DashboardBreadcrumb';
import { ContractorUtils } from '../../utils/tours/ContractorUtils';
import { useAuthContext } from '@contexts/auth-context';
import { selectActiveDashboardView, selectDashboardOrgKey, dashboardSlice } from '@redux';

type Props = {};

const NavigationBarDashboardContent: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const authService = useAuthContext();
  const orgKey = useSelector(selectDashboardOrgKey);
  const activeDashboard = useSelector(selectActiveDashboardView);

  const onOrganizationSelectedCallback = useCallback(
    (organizations: Array<TreeMenuNode<OrgUnitNode['data']>>) => {
      const orgKeys = organizations.map((o: TreeMenuNode<OrgUnitNode['data']>) => o.data.getIdentifier());

      dispatch(dashboardSlice.actions.setContractorViewMode(null));
      dispatch(
        dashboardSlice.actions.setOrgKey(orgKeys.length > 0 ? orgKeys[0] : authService.rootOrgKeys.dashboard[0])
      );

      const returnToDashboard =
        (orgKeys.every(ContractorUtils.isContractor) && ['sb_all', 'mengenprognose'].includes(activeDashboard)) ||
        'tour_loaded.list' === activeDashboard;

      returnToDashboard && dispatch(dashboardSlice.actions.setActiveDashboardView(null));
    },
    [activeDashboard, dispatch, authService.rootOrgKeys.dashboard]
  );

  return (
    <React.Fragment>
      <NavItem key='navbar-orgselect'>
        <OrgMultiSelect
          selectedOrgKeys={[orgKey]}
          onOrganizationSelect={onOrganizationSelectedCallback}
          hideMultiSelect
        />
      </NavItem>
      <DashboardBreadcrumb />
    </React.Fragment>
  );
};

export default NavigationBarDashboardContent;
