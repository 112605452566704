import genericDeepCopy from '../genericDeepCopy';

type DefaultType = {
  [key: string]: () => void;
};

export default function genericHydrateFrom(
  target: any,
  source: Object | null | undefined,
  memberBuilders: Object = {},
  defaults: DefaultType | Object = {},
  shallow: boolean = false
) {
  if (shallow) {
    if (source) {
      Object.entries(source).forEach(([key, val]) => {
        target[key] = val;
      });
    }
  } else {
    if (source) {
      Object.entries(source).forEach(([key, val]) => {
        if (memberBuilders[key]) {
          target[key] = memberBuilders[key](val, source);
        } else {
          target[key] = genericDeepCopy(val);
        }
      });
    }
    Object.keys(defaults).forEach((key) => {
      if (!source || !source.hasOwnProperty(key)) {
        const value = defaults[key]();
        target[key] = value;
      }
    });
  }
}
