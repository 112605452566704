import { useDataTableValueExpressionData } from '@data-table/hooks/use-data-table-value-expression-data-hook';
import { TabConfig } from '@data-table/hooks/use-touren-table-tabs-hook/use-touren-table-tabs.hook';
import { formatValueExpressionComparisonValue } from '@data-table/utils';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { formatValueExpressionValue } from '@legacy-modules/dashboard/utils/FormatValueExpression';
import { compareKpiValue } from '@utils/compare-kpi-value';
import { DurationUtils } from '@utils/duration-utils';
import React, { ComponentType, HTMLAttributes, useCallback, useMemo } from 'react';
import style from './touren-table-tab.module.scss';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { useOrgConfig } from '@hooks/use-org-config-hook';
import { reviveGoalClass } from '@hooks/use-org-config-hook/use-org-config.hook';

type TourenTableTabProps = HTMLAttributes<HTMLDivElement> & {
  tabConfig: TabConfig;
  uID: string;
  currentTab: string;
  duration: Duration;
  currentOrgKey: string;
  setCurrentTab: (tab: string) => void;
};
export type TourenTableTabComponentProps = HTMLAttributes<HTMLDivElement> & {
  isActive: boolean;
  title: string;
  subTitle: string;
  valueExpressionKey: string;
  text: string;
  tooltipText: string;
  comparisonClassName: string;
  comparisonText: string;
  onTabClick: () => void;
};

const withTourenTableTab =
  (Component: ComponentType<TourenTableTabComponentProps>) =>
  ({ tabConfig, uID, currentTab, duration, currentOrgKey, setCurrentTab, ...props }: TourenTableTabProps) => {
    const valueExpressionMap = useValueExpressionContext();

    const { title, subTitle, valueExpressionKey } = tabConfig;

    const valueExpression = valueExpressionKey && valueExpressionMap.get(valueExpressionKey);
    const { data: orgConfig } = useOrgConfig(currentOrgKey, valueExpression?.identifier);
    const zielwert = useMemo(() => {
      return reviveGoalClass(
        orgConfig?.values?.find(
          (config) => config.orgKey === currentOrgKey && config.configKey === valueExpression?.identifier
        )?.value
      );
    }, [orgConfig, valueExpression, currentOrgKey]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [orgKeyMap, getFilteredSumm, getRootOrgSumm, loading] = useDataTableValueExpressionData(
      currentOrgKey,
      [],
      valueExpression ? [valueExpression] : [],
      duration,
      false,
      !!currentOrgKey && !!valueExpression && !!duration
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [comparisonOrgKeyMap, getComparisonFilteredSumm, getComparisonRootOrgSumm, comparisonLoading] =
      useDataTableValueExpressionData(
        currentOrgKey,
        [],
        valueExpression ? [valueExpression] : [],
        duration,
        true,
        !!currentOrgKey && !!valueExpression && !!duration
      );

    const [text, comparisonText, comparisonClassName, tooltipText] = useMemo(() => {
      if (!valueExpression) {
        return [];
      }
      if (loading || comparisonLoading) {
        return ['-', '-', style.neutral, ''];
      }
      const value = getRootOrgSumm(valueExpression.identifier);
      const comparisonSumm = getComparisonRootOrgSumm(valueExpression.identifier);
      const comparisonValue = Number.isFinite(value) && Number.isFinite(comparisonSumm) ? value - comparisonSumm : null;
      const comparisonResult = compareKpiValue(valueExpression, comparisonValue, zielwert);
      const { from, to } = DurationUtils.getComparisonDateRange(duration);
      const toText = from.isSame(to, 'day') ? '' : ` bis ${to.format('Do MMMM YYYY')}`;
      const tooltipText = comparisonValue
        ? `Absolute Veränderung der ${valueExpression.getLabel()} zum Vergleichszeitraum: ${from.format(
            'Do MMMM YYYY'
          )}${toText}.`
        : `Für die Kennzahl ${valueExpression.getLabel()} liegen zum Vergleichszeitraum: ${from.format(
            'Do MMMM YYYY'
          )}${toText} keine Daten vor.`;
      return [
        formatValueExpressionValue(valueExpression, value)?.toString(),
        comparisonValue ? formatValueExpressionComparisonValue(valueExpression, comparisonValue) : '-',
        style[comparisonResult],
        tooltipText,
      ];
    }, [zielwert, valueExpression, duration, loading, comparisonLoading, getRootOrgSumm, getComparisonRootOrgSumm]);
    const isActive = useMemo(() => uID === currentTab, [uID, currentTab]);
    const onTabClick = useCallback(() => setCurrentTab(uID), [uID, setCurrentTab]);
    return (
      <Component
        {...props}
        isActive={isActive}
        onTabClick={onTabClick}
        title={title}
        subTitle={subTitle}
        valueExpressionKey={valueExpressionKey}
        comparisonClassName={comparisonClassName}
        comparisonText={comparisonText}
        tooltipText={tooltipText}
        text={text}
      />
    );
  };

export default withTourenTableTab;
