import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';
import Permissions from '@legacy-modules/common/models/auth/Permissions';
import { ViewMode } from './app.slice';

export const selectAuth = (state: RootState) => state.auth;
export const selectTokenId = createSelector(selectAuth, (auth) => auth.tokenId);
export const selectToken = createSelector(selectAuth, (auth) => auth.token);
export const selectPermissions = createSelector(selectAuth, (auth) => auth.permissions);
export const selectRootOrgKeys = createSelector(selectAuth, (auth) => auth.rootOrgKeys);
export const selectInitialized = createSelector(selectAuth, (auth) => auth.initialized);
export const selectLoggedIn = createSelector(selectAuth, (auth) => auth.loggedIn);
export const selectAvailableViewModes = createSelector(selectPermissions, (rawPermissions) => {
  const permissions = new Permissions(rawPermissions);
  return (['overview', 'dashboard'] as Array<ViewMode>).filter((viewMode) =>
    permissions.can(`ui.general.view-modes.${viewMode}`)
  );
});
export const selectUserDetails = createSelector(selectAuth, (auth) => auth.userDetails);
