import { OrgKey } from '@data-table/data-table.types';
import AuthStatusPayload from '@legacy-modules/common/models/websocket/payloads/auth/AuthStatusPayload';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const DEFAULT_ORG_KEY = 'country:de';

export type UserDetails = {
  displayName: string;
  email: string;
  username: string;
};

export type RootOrgKeys = {
  overview: OrgKey[];
  dashboard: OrgKey[];
  stoppdetail: OrgKey[];
};

export type Permissions = {
  groups: Array<{
    positivePermissions: string[];
    negativePermissions: string[];
  }>;
};

export type AuthState = {
  connected: boolean;
  initialized: boolean;
  loggedIn: boolean;
  loggedInSince: string;
  token: string;
  tokenId: string;
  permissions: Permissions;
  rootOrgKeys: RootOrgKeys;
  userDetails: UserDetails;
};

const initialState: AuthState = {
  connected: false,
  initialized: false,
  loggedIn: false,
  loggedInSince: null,
  token: null,
  tokenId: null,
  permissions: null,
  rootOrgKeys: {
    overview: [DEFAULT_ORG_KEY],
    dashboard: [DEFAULT_ORG_KEY],
    stoppdetail: [DEFAULT_ORG_KEY],
  },
  userDetails: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
      state.loggedInSince = action.payload ? moment().toISOString() : null;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.loggedInSince = null;
      state.token = null;
      state.tokenId = null;
      state.permissions = null;
      state.userDetails = null;
    },
    connect: (state) => {
      state.connected = true;
    },
    disconnect: (state) => {
      state.connected = false;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setTokenId: (state, action: PayloadAction<string>) => {
      state.tokenId = action.payload;
    },
    setPermissions: (state, action: PayloadAction<AuthStatusPayload['permissions']>) => {
      const groups = action.payload.map((pg) => {
        const positive = pg.filter((s) => !s.startsWith('-')).map((s) => parseAnt(s));
        const negative = pg
          .filter((s) => s.startsWith('-'))
          .map((s) => s.substring(1))
          .map((s) => parseAnt(s));
        return {
          positivePermissions: positive,
          negativePermissions: negative,
        };
      });
      groups.reverse();
      state.permissions = { groups };
    },
    setRootOrgKeys: (state, action: PayloadAction<{ overview: string; dashboard: string; stoppdetail: string }>) => {
      Object.keys(action.payload).forEach((key) => {
        state.rootOrgKeys[key] = action.payload[key]?.split(',');
      });
    },
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<UserDetails>) => {
      state.userDetails = action.payload;
    },
  },
});

export const parseAnt = function (str: string) {
  const quote = function (str: string) {
    return (str + '').replace(/[.?*+^$[\]\\(){}|-]/g, '\\$&');
  };

  let newStr = str.replace(/\*{2}/g, '%MATCH_ALL%').replace(/\*/g, '%MATCH_SINGLE%');
  newStr = quote(newStr);
  newStr = `^${newStr}$`;
  newStr = newStr.replace(/%MATCH_ALL%/g, '.*').replace(/%MATCH_SINGLE%/g, '[^\\.]{1}');
  return newStr;
};
