import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Collapse, Card, CardBody } from 'reactstrap';
import '../assets/questionAnswerCollapse.scss';
import { MdExpandLess } from 'react-icons/md';

type Props = {
  question: string;
  answer: string;
  search: string;
};

const QuestionAnswerCollapse = (props: Props) => {
  const { question, answer, search } = props;
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const ref = useRef<HTMLDivElement>(null);

  const containerStyles = {
    backgroundColor: open ? '#F3FCFF' : '#FFF',
  };
  const str = question.replace(new RegExp(search, 'gi'), (match) => `<mark>${match}</mark>`);
  const highlighter = useCallback(() => {
    if (search.length > 0) {
      ref.current.innerHTML = str;
    }
  }, [search.length, str]);
  useEffect(() => {
    highlighter();
  }, [highlighter, search]);

  return (
    <div style={containerStyles} className='question-answer-container'>
      <div className='question-panel pt-2' onClick={toggle}>
        <div ref={ref}>{search.length === 0 && question}</div>
        {open && <MdExpandLess className='icon-expand' size={20} />}
      </div>
      <Collapse className='collapse-container' isOpen={open}>
        <Card style={{ backgroundColor: 'transparent', border: 'none' }}>
          <CardBody style={{ backgroundColor: 'transparent', border: 'none' }}>
            <div className='text-left answer-container pr-5' dangerouslySetInnerHTML={{ __html: answer }} />
            <div />
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};
export default QuestionAnswerCollapse;
