import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import ReactMapboxGl, { GeoJSONLayer, Popup, ScaleControl } from 'react-mapbox-gl';
import MarkerDetails from '../../../tour/models/entities/MarkerDetails';
import ImageMarker from '../../../tour/components/map/ImageMarker';
import MapboxGL, { MapMouseEvent, MapboxGeoJSONFeature } from 'mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { debounce } from 'lodash';
import { Bounds } from '../../../utils/map/MapUtils';
import classNames from 'classnames';
import { ZoomControl } from '../../../tour/components/ZoomControl';
import { ConfigKey, EnvironmentConfigurationService } from '../../services/EnvironmentConfigurationService';
import { GeoFeature } from '@legacy-modules/metrics2/models/entities/organization/GeoFeature';

// eslint-disable-next-line new-cap
const MapboxMap = ReactMapboxGl({
  accessToken: EnvironmentConfigurationService.get(ConfigKey.MAPBOX_TOKEN),
  minZoom: 5,
});

type MBLatLng = {
  lat: number;
  lng: number;
};

export type Tile = {
  type: string;
  geometry?: GeoFeature;
  properties: {
    key: string;
    tooltip: string | null | undefined;
    style: {
      weight: number;
      color: string;
      fillColor: string;
      fillOpacity: number;
    };
  };
};

type Props = {
  bounds: Bounds;
  tiles: Array<Tile>;
  onTileMouseEnter: (arg0: string) => void | null | undefined;
  onTileMouseLeave: () => void | null | undefined;
  onTileClick: (arg0: string) => void | null | undefined;
  markers?: Array<MarkerDetails>;
  tilePopups: boolean;
  interactiveTiles: boolean;
  children?: React.ReactNode;
};

const didBoundsUpdate = (prevBounds: Bounds, nextBounds: Bounds) => {
  return (
    nextBounds.length !== (prevBounds && prevBounds.length) || // Added element
    !!prevBounds.filter((c, i) => {
      // Check for equality
      const nc = nextBounds[i];
      return c[0] !== (nc && nc[0]) || c[1] !== (nc && nc[1]);
    })[0]
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.tiles === nextProps.tiles && !didBoundsUpdate(prevProps.bounds, nextProps.bounds);
};

const MapboxGLMap: React.FC<PropsWithChildren<Props>> = React.memo(
  ({
    bounds,
    tiles,
    onTileMouseEnter,
    onTileMouseLeave,
    onTileClick,
    children,
    markers,
    tilePopups,
    interactiveTiles,
  }) => {
    const [hoveredMarkerKey, setHoveredMarkerKey] = useState<string>('');
    const [hoveredTileKey, setHoveredTileKey] = useState<string>('');
    const [tileMousePosition, setTileMousePosition] = useState<number[]>([0, 0]);

    const mapRef = useRef<MapboxGL.Map>(null);
    const lastEnteredTileRef = useRef<string>(null);

    useEffect(() => {
      window.addEventListener('resize', onResize);

      return () => {
        window.removeEventListener('resize', onResize);
      };
    }, []);

    const onResize = () => {
      if (mapRef.current) {
        window.setTimeout(() => {
          mapRef.current.resize();
        }, 250);
      }
    };

    const getTiles = useCallback((): Object => {
      let features = tiles || [];
      features = features.filter((f) => {
        return !(
          (f.geometry?.type === 'Polygon' || f.geometry?.type === 'MultiPolygon') &&
          f.geometry?.coordinates?.length === 0
        );
      });
      return {
        type: 'FeatureCollection',
        features,
      };
    }, [tiles]);

    const getTileFillPaint = (): Object => {
      return {
        'fill-color': ['get', 'fillColor', ['get', 'style']],
        'fill-opacity': ['get', 'fillOpacity', ['get', 'style']],
      };
    };

    const getTileLinePaint = (): Object => {
      return {
        'line-color': ['get', 'color', ['get', 'style']],
        'line-width': ['get', 'weight', ['get', 'style']],
      };
    };

    const onTileMouseMoveHandle = useCallback(
      (features: Array<MapboxGeoJSONFeature>, lngLat: MBLatLng) => {
        if (!features || !features.length) {
          return;
        }

        const feature = features[0];
        if (tilePopups) {
          setTileMousePosition([lngLat.lng, lngLat.lat]);
        }
        if (hoveredTileKey !== feature.properties.key) {
          setHoveredTileKey(feature.properties.key);
        }
        if (feature.properties.key !== lastEnteredTileRef.current) {
          lastEnteredTileRef.current = feature.properties.key;
          if (onTileMouseEnter) {
            onTileMouseEnter(feature.properties.key);
          }
        }
      },
      [hoveredTileKey, onTileMouseEnter, tilePopups]
    );

    const onTileMouseLeaveHandle = useCallback(() => {
      lastEnteredTileRef.current = null;
      setHoveredTileKey(null);
      setTileMousePosition([0, 0]);
      if (onTileMouseLeave) {
        onTileMouseLeave();
      }
    }, [onTileMouseLeave]);

    const onTileClickHandle = useCallback(
      (event: MapMouseEvent & { features: Array<MapboxGeoJSONFeature> }) => {
        if (event.features.length > 0 && onTileClick) {
          const feature = event.features[0];
          onTileClick(feature.properties.key);
        }
      },
      [onTileClick]
    );

    const onMapLoaded = useCallback(
      (map: MapboxGL.Map) => {
        map.addControl(new MapboxLanguage({ defaultLanguage: 'de' }));

        const deboucedMouseMove = debounce(onTileMouseMoveHandle, 100);
        const debounceMouseLeave = debounce(onTileMouseLeaveHandle, 100);

        mapRef.current = map;
        mapRef.current.on('mousemove', 'tiles-fill-fill', (e) => {
          deboucedMouseMove(e.features, e.lngLat);
        });
        mapRef.current.on('mouseleave', 'tiles-fill-fill', () => {
          debounceMouseLeave();
        });
        mapRef.current.on('click', 'tiles-fill-fill', onTileClickHandle);
      },
      [onTileClickHandle, onTileMouseLeaveHandle, onTileMouseMoveHandle]
    );

    const onMarkerMouseEnter: React.MouseEventHandler<HTMLDivElement> = (e) => {
      setHoveredMarkerKey((e.target as HTMLInputElement).src);
    };

    const onMarkerMouseLeave = () => {
      setHoveredMarkerKey(null);
    };

    const showPointer = hoveredTileKey && interactiveTiles;

    return (
      <MapboxMap
        className={classNames({ 'cursor-pointer': showPointer })}
        containerStyle={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
        style={EnvironmentConfigurationService.getMapboxStyles().default}
        fitBoundsOptions={{ padding: 60, linear: true }}
        fitBounds={bounds}
        onStyleLoad={onMapLoaded}>
        <>
          <ScaleControl />
          <ZoomControl map={mapRef.current} />
          <GeoJSONLayer id='tiles-fill' data={getTiles()} fillPaint={getTileFillPaint()} />
          <GeoJSONLayer id='tiles-outline' data={getTiles()} linePaint={getTileLinePaint()} />

          {markers?.map((marker: MarkerDetails) => {
            if (marker.markerType) {
              return (
                <ImageMarker
                  key={`marker_${marker.markerRef}`}
                  coordinates={marker.location.toReversedArray()}
                  icon={typeof marker.content === 'string' ? marker.content : null}
                  iconSize={marker.iconSize}
                  onMouseEnter={onMarkerMouseEnter}
                  onMouseLeave={onMarkerMouseLeave}
                />
              );
            }
            return null;
          })}
          {markers
            ?.filter((marker: MarkerDetails) => marker.tooltipCallback)
            .filter((marker: MarkerDetails) => marker.markerRef === hoveredMarkerKey)
            .map((marker: MarkerDetails) => {
              const tooltipContent: React.ReactNode = marker.tooltipCallback();
              return (
                <Popup
                  style={{ zIndex: 100 }}
                  key={`marker_tooltip_${marker.markerRef}`}
                  coordinates={[marker.location.lng, marker.location.lat]}
                  anchor='bottom'
                  // wrong typing, see https://github.com/alex3165/react-mapbox-gl/blob/master/docs/API.md#popup
                  offset={
                    {
                      'bottom-left': [12, -16],
                      bottom: [0, -40],
                      'bottom-right': [-12, -16],
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                  }>
                  {tooltipContent}
                </Popup>
              );
            })}
          {tiles
            ?.filter((tile: Tile) => tile.properties.tooltip && tilePopups)
            .filter((tile: Tile) => tile.properties.key === hoveredTileKey && !hoveredMarkerKey)
            .map((tile: Tile) => {
              return (
                <Popup
                  style={{ zIndex: 100 }}
                  key={`tile_tooltip_${tile.properties.key}`}
                  coordinates={tileMousePosition}
                  anchor='bottom'
                  // wrong typing, see https://github.com/alex3165/react-mapbox-gl/blob/master/docs/API.md#popup
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  offset={{ bottom: [0, -15] } as any}>
                  <strong>{tile.properties.tooltip}</strong>
                </Popup>
              );
            })}
          {children}
        </>
      </MapboxMap>
    );
  },
  areEqual
);

export default MapboxGLMap;
