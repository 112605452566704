import Model, { DefaultType, MemberType } from '../../../utils/Model';
import { TourIdentifier } from '../state/tourDetails/TourIdentifier';
import createMapMap from '../../../utils/createMapMap';
import moment from 'moment-timezone';
import TourPlanStop from './TourPlanStop';
import GeoCoordinate from '../../../geo/models/GeoCoordinate';

export default class TourPlan extends Model<TourPlan> {
  tourIdentifier: TourIdentifier;
  planUuid: string;
  stopOrder: Array<string>;
  stops: Map<string, TourPlanStop>;
  plannedAt: moment.Moment;
  finalDestination: GeoCoordinate | null | undefined;
  estimatedFinalAt: moment.Moment | null | undefined;

  constructor(
    source: any, // FIXME: set proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getSortedStops(): Array<TourPlanStop> {
    return this.stopOrder.map((so) => this.stops.get(so));
  }

  getDefaults(): DefaultType<TourPlan> {
    return {
      stopOrder: () => [],
      stops: () => new Map(),
      plannedAt: () => moment(),
    };
  }

  getMembers(): MemberType<TourPlan> {
    return {
      tourIdentifier: (src) => new TourIdentifier(src),
      stops: (src) => (src ? createMapMap(src, TourPlanStop) : new Map()),
      plannedAt: (src) => (src ? moment(src) : moment()),
      estimatedFinalAt: (src) => (src ? moment(src) : null),
      finalDestination: (src) => (src ? new GeoCoordinate(src) : null),
    };
  }
}
