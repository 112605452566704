import AlertsService from '@legacy-modules/app/services/AlertsService';
import React, { createContext, PropsWithChildren, ReactElement, useContext } from 'react';

export type AlertsContextType = AlertsService;

const defaultValue: AlertsContextType = null;
export const AlertsContext = createContext(defaultValue);

export const useAlertsContext = (): AlertsContextType => {
  return useContext<AlertsContextType>(AlertsContext);
};

type AlertsContextProviderProps = Required<
  PropsWithChildren<{
    alertsService: AlertsService;
  }>
>;

export default function AlertsContextProvider({ children, alertsService }: AlertsContextProviderProps): ReactElement {
  return <AlertsContext.Provider value={alertsService}>{children}</AlertsContext.Provider>;
}
