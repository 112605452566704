import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import anfahrtsstrecke from './anfahrtsstrecke';
import touren from './touren';

const expression = new QuotientValueExpression(
  anfahrtsstrecke,
  touren,
  'Ø Anfahrtsstrecke',
  false,
  '-#.###.##0,0 km',
  undefined,
  getDefinitionByName('Anfahrtsstrecke')
);

expression.setIdentifier('anfahrtsstrecke/touren_v1');

export default expression;
