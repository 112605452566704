import BasePayload from '../../../../common/models/websocket/payloads/BasePayload';
import { MetricsQueryResponsePayloadValue } from './MetricsQueryResponsePayloadValue';
import { DefaultType, MemberType } from '../../../../utils/Model';
import { PlainObjectOf } from '../org/OrgUnitTreeRequestPayload';

export default class MetricsQueryResponsePayload extends BasePayload<MetricsQueryResponsePayload> {
  orgKey: string;
  type: string;
  values: Array<MetricsQueryResponsePayloadValue>;

  constructor(source: PlainObjectOf<MetricsQueryResponsePayload>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<MetricsQueryResponsePayload> {
    return undefined;
  }

  getMembers(): MemberType<MetricsQueryResponsePayload> {
    return undefined;
  }
}
