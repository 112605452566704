import 'moment/locale/de';
import React, { useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { useToday } from '../hooks/useToday';
import { DateRange } from '../../utils/dates/DateRange';
import classNames from 'classnames';
import styles from './CalendarMonth.module.scss';
import { weekdayLabels } from './DateRangeSelectorPopup';

const DisabledFunction = () => {
  /* noop */
};

const calendarWeeksInMonth = 6;
const calendarWeekIndexes = Array.from(Array(calendarWeeksInMonth).keys());
const daysInWeek = 7;
const weekdayIndexes = Array.from(Array(daysInWeek).keys());

type Props = {
  month: moment.Moment;
  filteredWeekdays: string[];
  dayOnly?: boolean;
  primaryRange: DateRange;
  compareRange?: DateRange;
  disableHeaderSelection: boolean;
  onCalenderUnitSelect: (date: moment.Moment, endOf: moment.Moment, unit: moment.unitOfTime.StartOf) => void;
  futureEnabled: boolean;
  earliestDate: moment.Moment;
  onDaySelect: (day: moment.Moment) => void;
  onSubmit: () => void;
  activeDay: moment.Moment;
};
const CalendarMonth: React.FC<Props> = ({
  month,
  primaryRange,
  compareRange,
  filteredWeekdays,
  disableHeaderSelection,
  onDaySelect,
  onSubmit,
  onCalenderUnitSelect,
}: Props) => {
  const { endOfMonth, calendarWeeks, weekdays, title } = useMemo(() => {
    const endOfMonth = month.clone().endOf('month');
    const calendarWeeks = calendarWeekIndexes.map((idx) => month.clone().startOf('month').add(idx, 'week'));
    const title = month.format('MMMM YYYY');
    const weekdays = Object.values(weekdayLabels);
    return { endOfMonth, calendarWeeks, weekdays, title };
  }, [month]);

  const selectFullMonth = () => {
    if (disableHeaderSelection) {
      return;
    }
    onCalenderUnitSelect(month, endOfMonth, 'month');
  };

  const titleComponent = (
    <div
      className={classNames(styles.header, {
        [styles.disabled]: disableHeaderSelection,
      })}
      onClick={selectFullMonth}>
      {title}
    </div>
  );
  const weekdayHeadline = (
    <div className={styles.weekdayHeadline}>
      <span className={styles.weekdayLabel} />
      {weekdays.map((weekday) => (
        <span key={weekday} className={styles.weekdayLabel}>
          {weekday}
        </span>
      ))}
    </div>
  );

  const onDaySelectCallback = useCallback(
    (calendarWeek: Moment, weekdayIndex: number, submit: boolean = false) =>
      () => {
        onDaySelect(calendarWeek.clone().weekday(weekdayIndex).clone());
        if (submit) {
          onSubmit();
        }
      },
    [onDaySelect, onSubmit]
  );

  return (
    <section className={styles.container}>
      {titleComponent}
      {weekdayHeadline}
      <>
        {calendarWeeks.map((calendarWeek) => (
          <div key={calendarWeek.week()} className={styles.weekRow}>
            <span className={styles.weekLabel}>{calendarWeek.week()}</span>
            {weekdayIndexes.map((weekdayIndex) => {
              return (
                <CalendarWeekday
                  key={weekdayIndex}
                  month={month.clone()}
                  day={calendarWeek.clone().weekday(weekdayIndex)}
                  primaryRange={primaryRange}
                  compareRange={compareRange}
                  filteredWeekdays={filteredWeekdays}
                  onClick={onDaySelectCallback(calendarWeek, weekdayIndex)}
                  onDoubleClick={onDaySelectCallback(calendarWeek, weekdayIndex, true)}
                />
              );
            })}
          </div>
        ))}
      </>
    </section>
  );
};

type CalendarWeekdayProps = React.HTMLAttributes<HTMLSpanElement> & {
  month: moment.Moment;
  day: moment.Moment;
  filteredWeekdays: string[];
  primaryRange: DateRange;
  compareRange?: DateRange;
};
const CalendarWeekday: React.FC<CalendarWeekdayProps> = ({
  month,
  day,
  primaryRange,
  compareRange,
  filteredWeekdays,
  onClick,
  onDoubleClick,
  ...rest
}) => {
  const isSameMonth = day.isSame(month, 'month');
  const primarySelectionFrom = primaryRange.from;
  const primarySelectionTo = primaryRange.to;
  const comparisonSelectionFrom = compareRange?.from;
  const comparisonSelectionTo = compareRange?.to;
  const weekdayKey = Object.keys(weekdayLabels)[day.weekday()];
  const today = useToday();
  const isPrimaryStart = primarySelectionFrom.isSame(day, 'day');
  const isPrimaryEnd = primarySelectionTo.isSame(day, 'day');
  const isPrimarySingleDay = primarySelectionFrom.isSame(primarySelectionTo, 'day');
  const isPrimary = day.isBetween(primarySelectionFrom, primarySelectionTo, 'day', '[]');
  const isComparisonStart = comparisonSelectionFrom?.isSame(day, 'day') || false;
  const isComparisonEnd = comparisonSelectionTo?.isSame(day, 'day') || false;
  const isComparisonSingleDay = comparisonSelectionFrom?.isSame(comparisonSelectionTo, 'day');
  const isComparison =
    comparisonSelectionFrom && day.isBetween(comparisonSelectionFrom, comparisonSelectionTo, 'day', '[]');
  const isFilteredOutDay = (isPrimary || isComparison) && !filteredWeekdays.includes(weekdayKey);
  const isFuture = day.isAfter(today, 'day');
  const isDisabled = !isSameMonth || isFuture;

  return (
    <span
      className={classNames(styles.dayLabel, {
        [styles.disabled]: isDisabled,
        [styles.filtered]: isFilteredOutDay,
        [styles.primary]: isPrimary,
        [styles.comparison]: isComparison,
        [styles.singleDayPrimary]: isPrimarySingleDay,
        [styles.singleDayComparison]: isComparisonSingleDay,
        [styles.selectionStart]: isPrimaryStart || isComparisonStart,
        [styles.selectionEnd]: isPrimaryEnd || isComparisonEnd,
      })}
      {...rest}
      onClick={isDisabled ? DisabledFunction : onClick}
      onDoubleClick={isDisabled ? DisabledFunction : onDoubleClick}>
      {isSameMonth ? day.date() : ''}
    </span>
  );
};

export { CalendarMonth };
