import moment from 'moment';
import { useQueries } from 'react-query';
import { useCallback, useMemo } from 'react';

import { useApiContext } from '@contexts/api-context';
import { OrgKey } from '@data-table/data-table.types';
import { KnownWebsocketEvent } from '@contexts/api-context/request.types';
import { Duration, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';
import MetricsEntityKey from '@legacy-modules/metrics2/models/entities/MetricsEntityKey';
import { DateRangeGrouping } from '@legacy-modules/metrics2/models/enumerations/DateRangeGrouping';
import MetricsQueryResponsePayload from '@legacy-modules/metrics2/models/websocket/metrics/MetricsQueryResponsePayload';
import ValueExpressionEntityKey from '@legacy-modules/valueexpressions/models/entities/ValueExpressionEntityKey';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { DurationUtils } from '@utils/duration-utils';

/**
 * Tuple of: [data, isLoading, isError]
 */
type UseMetricQueryOutput = [
  Map<ValueExpressionEntityKey, number>,
  boolean,
  boolean,
  (orgKey: OrgKey, dateIdentifier?: string) => number
];

export default function useMetricQuery(
  orgKeys: OrgKey[],
  valueExpression: ValueExpression,
  duration: Duration,
  contractorKey?: string,
  dateRangeGrouping: DateRangeGrouping = DateRangeGrouping.none,
  isComparison: boolean = false,
  enabled: boolean = true
): UseMetricQueryOutput {
  const [weekdayFilter, weekdayFilterCashId] = useMemo(() => {
    const filterObject = duration.weekdayFilter || defaultWeekdaysFilter;
    return [
      filterObject,
      Object.keys(filterObject)
        .filter((key) => filterObject[key])
        .join(','),
    ];
  }, [duration.weekdayFilter]);

  const apiCtx = useApiContext();

  const dateRange = isComparison
    ? DurationUtils.getComparisonDateRange(duration)
    : DurationUtils.getDateRange(duration);

  const { from, to } = {
    from: dateRange?.from?.format('YYYY-MM-DD'),
    to: dateRange?.to?.format('YYYY-MM-DD'),
  };

  const results = useQueries(
    valueExpression && orgKeys?.length > 0
      ? orgKeys.flatMap((orgKey) =>
          valueExpression.getRequiredMetricTypes()?.map(({ type, valueKey }) => ({
            queryKey: [
              KnownWebsocketEvent.METRICS_QUERY_LOAD_EVENT,
              type.key,
              valueKey,
              orgKey,
              from,
              to,
              dateRangeGrouping,
              contractorKey,
              weekdayFilterCashId,
            ],
            queryFn: () =>
              apiCtx.wsFetch<MetricsQueryResponsePayload>(KnownWebsocketEvent.METRICS_QUERY_LOAD_EVENT, {
                type: type.key,
                orgKey,
                dateFilter: {
                  range: {
                    from,
                    until: to,
                  },
                  weekdays: weekdayFilter,
                },
                grouping: dateRangeGrouping,
                valueKey,
                contractorKey: contractorKey && orgKey.startsWith('oz_t:') ? contractorKey : null,
                aggregation: type.aggregation,
              }),
            enabled,
          }))
        )
      : []
  );

  const valuesMap = useMemo(() => {
    if (!valueExpression) {
      return new Map();
    }
    const data = results?.filter((result) => !result.isError && result.data)?.map((result) => result.data) || [];

    const metricsEntityMap = new Map(
      data.flatMap((dataEntry) =>
        dataEntry.values.flatMap((value) => {
          const valueKey = valueExpression
            ?.getRequiredMetricTypes()
            ?.find((type) => type.type.key === dataEntry.type)?.valueKey;
          // If dateRangeGrouping is set, we need to add the value to the map twice,
          // once for the date range group and once for the sum entry.
          // In case of none value this values are the same
          return dateRangeGrouping === DateRangeGrouping.none
            ? [
                [
                  new MetricsEntityKey(
                    dataEntry.type,
                    dataEntry.orgKey,
                    dateRange.from,
                    dateRange.to,
                    dateRangeGrouping,
                    valueKey,
                    duration.weekdayFilter
                  ),
                  value.value,
                ],
              ]
            : [
                [
                  new MetricsEntityKey(
                    dataEntry.type,
                    dataEntry.orgKey,
                    moment(value.group),
                    moment(value.group),
                    dateRangeGrouping,
                    valueKey,
                    duration.weekdayFilter
                  ),
                  value.value,
                ],
                [
                  new MetricsEntityKey(
                    dataEntry.type,
                    dataEntry.orgKey,
                    dateRange.from,
                    dateRange.to,
                    DateRangeGrouping.none,
                    valueKey,
                    duration.weekdayFilter
                  ),
                  value.value,
                ],
              ];
        })
      )
    );
    const valuesMap = valueExpression.processValues(metricsEntityMap, false);
    return valuesMap;
  }, [results, valueExpression, dateRangeGrouping, duration, dateRange]);

  const getByOrgKey = useCallback(
    (orgKey: OrgKey, dateIdentifier?: string) => {
      if (!orgKey || valuesMap?.size <= 0) {
        return undefined;
      }
      return Array.from(valuesMap.entries())
        .find(([valueExpressionEntityKey]) => {
          if (!dateIdentifier) {
            return valueExpressionEntityKey.orgKey === orgKey;
          }
          return (
            valueExpressionEntityKey.orgKey === orgKey && valueExpressionEntityKey.dateIdentifier === dateIdentifier
          );
        })
        ?.at(1);
    },
    [valuesMap]
  );

  return [valuesMap, results.some((result) => result.isLoading), results.some((result) => result.isError), getByOrgKey];
}
