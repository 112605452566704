import React, { HtmlHTMLAttributes, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import ReactPlaceholder from 'react-placeholder';
import moment from 'moment-timezone';
import numeral from 'numeral';
import 'numeral/locales/de';

import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import { DateRangeGrouping } from '../../metrics2/models/enumerations/DateRangeGrouping';
import { QueryPriorities } from '../../datamanager/models/enumerations/QueryPriority';
import ValueExpressionQuery from '../../valueexpressions/models/queries/ValueExpressionQuery';
import ChartUtils, { FromTo } from '../utils/ChartUtils';
import { Duration } from '../models/enums/Duration';
import { MetricDataConnectionOutputFormats } from '../../metrics2/models/enumerations/MetricDataConnectionOutputFormat';
import GroupedDataConnection from '../../dataconnection/connections/GroupedDataConnection';
import { useGroupedDataConnection } from '../hooks/useGroupedDataConnection';
import { DateRange } from '../../utils/dates/DateRange';
import { useRanges } from '../hooks/useRanges';
import { Dot, DotColor } from '../../common/components/Dot';
import KpiValue from '../components/KpiValue';
import { KpiCompareValue } from '../components/KpiCompareValue';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { useValueExpressionDataContext } from '@contexts/value-expression-data-context';
import { useDataConnectionContext } from '@contexts/data-connection-context';

import Styles from './DetailedChartKpiValueStyles.module.scss';
import { selectTourDetailsContractorKey } from '@redux/tour-details.selectors';

type Props = Pick<HtmlHTMLAttributes<HTMLDivElement>, 'style'> & {
  orgKey: string;
  valueExpressionKey: string;
  duration: Duration;
  selected?: boolean;
  showDetails?: boolean;
  customLabel?: string;
  customColor?: string;
  showDot?: boolean;
  noSpace?: boolean;
  rawData?: { primary: any; compare?: any };
};

const dateFormat = 'Do MMMM YYYY';

export const getKpiDiffValueTitle = (range: DateRange, valueExpression: ValueExpression): string => {
  if (range.from.isSame(range.to, 'day')) {
    return `Absolute Veränderung der ${valueExpression.getLabel()} zum Vergleichszeitraum: ${range.from.format(
      dateFormat
    )}`;
  } else {
    return `Absolute Veränderung der ${valueExpression.getLabel()} zum Vergleichszeitraum: ${range.from.format(
      dateFormat
    )} bis ${range.to.format(dateFormat)}`;
  }
};

type ItemProps = {
  customLabel: string;
  showDetails: boolean;
  dateAsString: string;
  loading: boolean;
  selected?: boolean;
  customColor: string;
  primaryData: number;
  valueExpression: ValueExpression;
  showDiffValue: boolean;
  diffDescription?: string;
  dotColor?: string;
  showDot?: boolean;
  loadingColor?: string;
  primary: number;
  compare: number;
  noSpace?: boolean;
};

const Item: React.FC<ItemProps> = (props: ItemProps) => {
  const {
    customLabel,
    showDetails,
    loading,
    dateAsString,
    selected = false,
    customColor,
    primaryData,
    valueExpression,
    showDiffValue,
    diffDescription,
    dotColor,
    showDot,
    loadingColor = '#ccc',
    primary,
    compare,
    noSpace = false,
  } = props;

  return (
    <div
      className={classNames(Styles.item, {
        [Styles.showDetails]: showDetails,
        [Styles.noSpace]: noSpace,
      })}>
      {customLabel && (
        <div className={Styles.title}>
          <span>{customLabel}</span>
        </div>
      )}
      {showDetails && (
        <ReactPlaceholder ready={!loading} rows={1} type={'text'} showLoadingAnimation={true}>
          <div className={Styles.title}>{dateAsString}</div>
        </ReactPlaceholder>
      )}
      <div className={Styles.Wrapper}>
        {(showDot || showDetails) && (
          <div className={Styles.Dot}>
            <Dot color={DotColor.primary} style={{ backgroundColor: loading ? loadingColor : dotColor }} />
          </div>
        )}
        <div className={Styles.values}>
          <div className={Styles.value} style={selected ? { fontWeight: 'normal' } : {}}>
            {customLabel && (
              <ReactPlaceholder ready={!loading} rows={1} type={'text'} showLoadingAnimation={true}>
                <span style={{ backgroundColor: customColor }} />
              </ReactPlaceholder>
            )}
            <ReactPlaceholder ready={!loading} rows={1} type={'text'} showLoadingAnimation={true}>
              <KpiValue valueExpression={valueExpression} veData={primaryData} />
            </ReactPlaceholder>
          </div>
          {showDiffValue && (
            <div className={Styles.subline}>
              <ReactPlaceholder ready={!loading} rows={1} type={'text'} showLoadingAnimation>
                <KpiCompareValue
                  primaryVE={valueExpression}
                  primaryData={primary}
                  compareData={compare}
                  hoverText={diffDescription}
                />
              </ReactPlaceholder>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * DetailedChartKpiValue
 * this component renders single value expression with a comparison value based on
 * a duration and valueExpression passed within the props
 */
const DetailedChartKpiValue: React.FC<Props> = (props: Props) => {
  const {
    orgKey,
    duration,
    selected = false,
    showDetails = true,
    customLabel,
    customColor,
    showDot = false,
    noSpace = false,
    style,
    valueExpressionKey,
    rawData = null,
  } = props;

  const contractorKey = useSelector(selectTourDetailsContractorKey);
  const valueExpressionMap = useValueExpressionContext();
  const valueExpression = valueExpressionMap.get(valueExpressionKey);

  const valueExpressionDataContext = useValueExpressionDataContext();
  const dataConnectionContext = useDataConnectionContext();

  const { primaryRange, compareRange } = useRanges(duration);

  const getConnection = useCallback(() => {
    if (!valueExpression || rawData?.primary) {
      return;
    }

    const { primary: primaryConnection, compare: compareConnection } = (() => {
      const primaryQuery: ValueExpressionQuery = new ValueExpressionQuery(
        valueExpression,
        orgKey,
        primaryRange.from,
        primaryRange.to,
        duration.weekdayFilter,
        DateRangeGrouping.none,
        null,
        contractorKey
      );

      const compareQuery: ValueExpressionQuery = new ValueExpressionQuery(
        valueExpression,
        orgKey,
        compareRange.from,
        compareRange.to,
        duration.weekdayFilter,
        DateRangeGrouping.none,
        null,
        contractorKey
      );

      const primary = valueExpressionDataContext.requestConnection(primaryQuery, QueryPriorities.high);
      const compare = valueExpressionDataContext.requestConnection(compareQuery, QueryPriorities.high);

      return {
        primary,
        compare,
      };
    })();

    primaryConnection?.setOutputFormat(MetricDataConnectionOutputFormats.single);
    compareConnection?.setOutputFormat(MetricDataConnectionOutputFormats.single);

    return new GroupedDataConnection({
      primary: primaryConnection,
      compare: compareConnection,
    });
  }, [
    valueExpression,
    rawData,
    orgKey,
    primaryRange.from,
    primaryRange.to,
    duration.weekdayFilter,
    contractorKey,
    compareRange.from,
    compareRange.to,
    valueExpressionDataContext,
  ]);

  const dataConnection = useMemo(getConnection, [getConnection]);

  const { data, loading } = useGroupedDataConnection(
    dataConnection,
    dataConnectionContext,
    valueExpression.apiVersion !== 'graphql'
  );

  const { primary, compare } = data ?? rawData ?? {};

  const { diffValue, compareDateRange, diffTitle } = useMemo(() => {
    if (loading || !valueExpression) {
      return {
        compareDateRange: {} as FromTo,
      };
    }

    const compareDateRange = ChartUtils.getComparisonDateRange(duration);

    const primaryFormattedValue = valueExpression.getValueFormatter()(primary);
    const compareFormattedValue = valueExpression.getValueFormatter()(compare);
    numeral.locale('de');

    const diffValue = (() => {
      if (primary == null) {
        return null;
      }

      return primary - compare;
    })();

    const diffTitle = getKpiDiffValueTitle(compareDateRange, valueExpression);

    return {
      diffValue,
      compareDateRange,
      primaryFormattedValue,
      diffTitle,
      compareFormattedValue,
    };
  }, [loading, duration, valueExpression, primary, compare]);

  const dateString = ({ from, to }: FromTo) => {
    if (!from || !to) {
      return '';
    }

    if (moment(from).isSame(moment(to), 'day')) {
      return `${moment(from).format('dddd, Do MMMM YYYY')}`;
    } else {
      return `${moment(from).format('Do MMMM YYYY')} - ${moment(to).format('Do MMMM YYYY')}`;
    }
  };

  return (
    <div
      className={classNames(Styles.DetailedChartKpiValue, {
        [Styles.HideDetails]: showDetails,
      })}
      style={style}>
      <Item
        showDetails={showDetails}
        customLabel={customLabel}
        customColor={customColor}
        loading={loading}
        dateAsString={dateString(primaryRange)}
        valueExpression={valueExpression}
        primaryData={primary}
        showDiffValue={isFinite(diffValue)}
        diffDescription={diffTitle}
        selected={selected}
        showDot={showDot}
        dotColor={customColor}
        primary={primary}
        compare={compare}
        noSpace={noSpace}
      />
      {showDetails && (
        <Item
          showDetails={showDetails}
          customLabel={customLabel}
          customColor={customColor}
          loading={loading}
          dateAsString={dateString(compareDateRange)}
          primaryData={compare}
          valueExpression={valueExpression}
          showDiffValue={false}
          dotColor={'orange'}
          showDot={showDot}
          primary={primary}
          compare={compare}
          noSpace={noSpace}
        />
      )}
    </div>
  );
};

export default DetailedChartKpiValue;
