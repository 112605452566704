import FinishedDelivery from '../models/entities/FinishedDelivery';

export const buildShipmentIdToCustomerIdMap = (
  deliveryItems = new Map(),
  finishedDeliveries: FinishedDelivery[] = []
): Map<string, string> => {
  const res = new Map();
  deliveryItems.forEach((di) => res.set(di.deliveryItemId, di.customerRef));
  finishedDeliveries.forEach((fd) => {
    // TODO: check if field can exist
    // @ts-ignore
    if (res.has(fd.deliveryItemId)) throw new Error('duplicate');
    fd.deliveryItems.forEach((o) => res.set(o.deliveryItemId, o.customerRef));
  });
  return res;
};
