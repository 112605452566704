import React, { HTMLAttributes, ReactElement, useEffect } from 'react';
import style from './tooltip.module.scss';
import classNames from 'classnames';
import { useLayer, useHover, Arrow, UseLayerOptions, UseHoverOptions, ArrowProps } from 'react-laag';
import { useSharedTooltipSettingsContext } from './shared-tooltip-settings-context';

export type TooltipProps = HTMLAttributes<HTMLDivElement> & {
  tooltipText: string;
  children: string | number | React.ReactElement;
  layerOptions?: Partial<UseLayerOptions>;
  layerStyle?: React.CSSProperties;
  hoverOptions?: Partial<UseHoverOptions>;
  arrowOptions?: Partial<ArrowProps>;
  disableTooltip?: boolean;
  'data-testid'?: string;
  triggerStyle?: React.CSSProperties;
};

export function Tooltip({
  children,
  tooltipText,
  layerOptions = {},
  hoverOptions = {},
  arrowOptions = {},
  layerStyle,
  disableTooltip = false,
  'data-testid': testId,
  triggerStyle,
  ...htmlProps
}: TooltipProps): ReactElement {
  const sharedSettings = useSharedTooltipSettingsContext();
  // We use `useHover()` to determine whether we should show the tooltip.
  const [isOver, hoverProps, close] = useHover({
    delayEnter: 200,
    delayLeave: 200,
    hideOnScroll: true,
    ...sharedSettings.hoverOptions,
    ...hoverOptions,
  });
  // Tell `useLayer()` how we would like to position our tooltip
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: !disableTooltip && isOver,
    placement: 'bottom-center',
    triggerOffset: 8, // small gap between wrapped content and the tooltip
    container: 'layers',
    auto: true,
    ...sharedSettings.layerOptions,
    ...layerOptions,
  });

  useEffect(() => {
    if (disableTooltip) {
      close();
    }
  }, [disableTooltip, close]);

  if (!tooltipText) {
    return <>{children}</>;
  }

  return (
    <div className='tooltip-wrapper' {...htmlProps}>
      <div className={style.tooltipTrigger} {...triggerProps} {...hoverProps} style={triggerStyle}>
        {children}
      </div>
      {renderLayer(
        <>
          {!disableTooltip && isOver && (
            <div
              data-testid={testId}
              className={classNames(style.tooltipLayer)}
              {...layerProps}
              style={{
                ...layerStyle,
                ...sharedSettings.layerStyle,
                ...layerProps.style,
              }}>
              {tooltipText}
              <Arrow
                {...arrowProps}
                backgroundColor='white'
                borderColor='#A7AFB2'
                borderWidth={1}
                size={6}
                angle={45}
                {...arrowOptions}
                {...sharedSettings.arrowOptions}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default Tooltip;
