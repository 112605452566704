import BasePayload from '../BasePayload';
import { DefaultType, MemberType } from '../../../../../utils/Model';
import { PlainObjectOf } from '../../../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export default class SystemErrorPayload extends BasePayload<SystemErrorPayload> {
  error: number;
  message: string | null | undefined;

  constructor(source: PlainObjectOf<SystemErrorPayload>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<SystemErrorPayload> {
    return undefined;
  }

  getMembers(): MemberType<SystemErrorPayload> {
    return undefined;
  }
}
