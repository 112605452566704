// filters breadcrumbs so the user can't go back when he don't have permission for the orgUnit
export default function breadcrumbFilter(orgBreadcrumb, stateOrgKeys) {
  const reversedOrgBreadcrumb = [...orgBreadcrumb].reverse();
  const filteredOrgBreadcrumb = [];
  for (const org of reversedOrgBreadcrumb) {
    filteredOrgBreadcrumb.push(org);
    if (stateOrgKeys.includes(org.orgKey)) {
      break;
    }
  }
  return filteredOrgBreadcrumb.reverse();
}
