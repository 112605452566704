import React, { forwardRef } from 'react';
import Styles from './TextButton.module.scss';
import classNames from 'classnames';

export enum TextButtonVariant {
  Primary = 'primary',
  White = 'white',
}

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  text: string;
  disabledText?: string;
  variant?: TextButtonVariant;
};

const TextButton = forwardRef<HTMLDivElement, Props>(
  ({ text, onClick, className = '', style, disabled, variant = TextButtonVariant.Primary, ...props }: Props, ref) => {
    return (
      <div ref={ref}>
        <button
          style={style}
          disabled={disabled}
          onClick={(e) => onClick(e)}
          className={classNames(Styles.TextButton, Styles[variant], className)}
          {...props}>
          {text}
        </button>
      </div>
    );
  }
);

export default TextButton;
