import * as React from 'react';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import '../assets/signaturesDeliveryItem.scss';
import SignaturesCustomerRow from './SignaturesCustomerRow';
import { CustomerMap } from '../models/entities/TourDetails';
import { useMemo } from 'react';
import { IRawAnomalyResponse } from '../../dashboard/anomalies/interfaces/IRawAnomalyResponse';

type Props = {
  finishedDelivery: FinishedDelivery;
  customers: CustomerMap;
  url: string | null;
  shipmentIdToCustomerMap: Map<string, string>;
  anomalies: IRawAnomalyResponse[];
};

const SignaturesTableStop: React.FC<Props> = (props) => {
  const { finishedDelivery, customers, url, shipmentIdToCustomerMap, anomalies = [] } = props;

  const customersArr = useMemo(() => {
    const cd = finishedDelivery && Object.values(finishedDelivery.customerDeliveries);

    return cd.filter((c) => customers && customers.has(c.customerRef)).map((c) => customers.get(c.customerRef));
  }, [finishedDelivery, customers]);

  return (
    <SignaturesCustomerRow
      finishedDelivery={finishedDelivery}
      customers={customersArr}
      url={url}
      shipmentIdToCustomerMap={shipmentIdToCustomerMap}
      anomalies={anomalies}
    />
  );
};

export default SignaturesTableStop;
