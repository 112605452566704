import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdChevronLeft, MdChevronRight, MdCompareArrows, MdPermContactCalendar } from 'react-icons/md';
import BaseSelect from './BaseSelect';
import moment from 'moment';
import { getHumanReadableDateText, getPlainDateText, isThisYear } from '../utils/DateRangeTextUtils';
import { DateRange } from '../../utils/dates/DateRange';
import DateRangeSelectorPopup, { weekdayLabels as allWeekdayLabels } from './DateRangeSelectorPopup';
import { WeekdaysFilter, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';

type Props = {
  dayOnly?: boolean;
  disabled?: boolean;
  comparisonEnabled: boolean;
  compareRange?: DateRange;
  primaryRange: DateRange;
  onSelectionChanged?: (primary: DateRange, compare: DateRange) => void;
  onWeekdayFilterChange?: (filter: WeekdaysFilter) => void;
  futureEnabled?: boolean;
  earliestDate?: moment.Moment;
  usePlainDateDisplay?: boolean;
  weekdayFilter?: WeekdaysFilter;
};

export const DateCompareSelector: React.FC<Props> = (props) => {
  const {
    compareRange,
    primaryRange,
    dayOnly,
    disabled = false,
    comparisonEnabled,
    onSelectionChanged,
    onWeekdayFilterChange,
    futureEnabled = false,
    earliestDate,
    usePlainDateDisplay = false,
    weekdayFilter = defaultWeekdaysFilter,
  } = props;

  const now = moment();
  const primarySelectionFrom = moment(primaryRange.from);
  const enableNextButton = dayOnly && !primarySelectionFrom.isSame(now, 'day');
  let selectProps = {};
  const wrapperRef = useRef();

  const [open, setOpen] = useState(false);

  const [text, setText] = useState<string>('');
  const [selections, setSelections] = useState<{
    primary?: DateRange;
    compare?: DateRange;
  }>({
    primary: primaryRange,
    compare: compareRange,
  });

  const [compareActive, setCompareActive] = useState(!!compareRange);

  const getFullDateRangeSelectionText = useCallback(
    (primary: DateRange, compare: DateRange, useCompare: boolean, usePlainDateDisplay: boolean = false): string => {
      const weekdayLabels = Object.keys(weekdayFilter)
        .filter((weekday) => weekdayFilter[weekday])
        .map((weekday) => allWeekdayLabels[weekday]);

      const isWeekdaySpan =
        weekdayLabels.length > 1 && Object.values(allWeekdayLabels).join(',').includes(weekdayLabels.join(','));

      const showSuffixLabel =
        !primary.from?.isSame(primary.to, 'day') && weekdayLabels.length > 0 && weekdayLabels.length < 7;

      const suffixLabel = isWeekdaySpan
        ? ` (${weekdayLabels[0]} - ${weekdayLabels[weekdayLabels.length - 1]})`
        : ` (${weekdayLabels.join(', ')})`;

      if (usePlainDateDisplay) {
        return (
          getPlainDateText(primary.from, primary.to, isThisYear(primary.from, primary.to) ? 'D. MMM' : 'D. MMM YYYY') +
          (showSuffixLabel ? suffixLabel : '')
        );
      }
      if (!useCompare || !compare) {
        return getHumanReadableDateText(primary.from, primary.to).text + (showSuffixLabel ? suffixLabel : '');
      }
      return (
        [getHumanReadableDateText(primary.from, primary.to), getHumanReadableDateText(compare.from, compare.to)]
          .map((v) => v.text)
          .join(' vs ') + (showSuffixLabel ? suffixLabel : '')
      );
    },
    [weekdayFilter]
  );

  useEffect(() => {
    setSelections((v) => ({
      ...v,
      primary: primaryRange,
    }));
  }, [primaryRange]);

  useEffect(() => {
    setSelections((v) => ({
      ...v,
      compare: compareRange,
    }));
  }, [compareRange]);

  const _toggleSelect = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  const onCancelCallback = useCallback(() => {
    if (!open) {
      return;
    }

    setOpen(false);
    setSelections({
      primary: primaryRange,
      compare: compareRange,
    });
  }, [compareRange, primaryRange, open]);

  const onNavigateLeftClick = useCallback(() => {
    const newSelection = {
      ...selections,
      primary: {
        ...selections.primary,
        from: selections.primary.from.clone().subtract(1, 'day'),
      },
    };
    if (dayOnly) {
      newSelection.primary.to = newSelection.primary.from.clone();
    }

    setSelections(newSelection);

    setText(
      getFullDateRangeSelectionText(newSelection.primary, selections.compare, compareActive, usePlainDateDisplay)
    );
    onSelectionChanged && onSelectionChanged(newSelection.primary, compareActive ? newSelection.compare : null);
  }, [compareActive, dayOnly, getFullDateRangeSelectionText, usePlainDateDisplay, onSelectionChanged, selections]);

  const onNavigationRightCallback = useCallback(() => {
    const newSelection = {
      ...selections,
      primary: {
        ...selections.primary,
        to: selections.primary.to.clone().add(1, 'day'),
      },
    };
    if (dayOnly) {
      newSelection.primary.from = newSelection.primary.to.clone();
    }

    setSelections(newSelection);

    setText(
      getFullDateRangeSelectionText(newSelection.primary, selections.compare, compareActive, usePlainDateDisplay)
    );
    onSelectionChanged && onSelectionChanged(newSelection.primary, compareActive ? newSelection.compare : null);
  }, [compareActive, dayOnly, getFullDateRangeSelectionText, onSelectionChanged, selections, usePlainDateDisplay]);

  const onCompareClickCallback = useCallback(() => {
    const newActiveState = !compareActive;
    setCompareActive(newActiveState);

    if (!onSelectionChanged) {
      return;
    }

    if (!selections) {
      return;
    }

    if (newActiveState) {
      onSelectionChanged(selections.primary, selections.compare);
    } else {
      onSelectionChanged(selections.primary, null);
    }
    setText(getFullDateRangeSelectionText(selections.primary, selections.compare, newActiveState, usePlainDateDisplay));
  }, [compareActive, getFullDateRangeSelectionText, onSelectionChanged, selections, usePlainDateDisplay]);

  useEffect(() => {
    setText(getFullDateRangeSelectionText(primaryRange, compareRange ?? null, !!compareRange, usePlainDateDisplay));
  }, [
    compareActive,
    getFullDateRangeSelectionText,
    compareRange,
    primaryRange,
    selections.primary,
    selections.compare,
    usePlainDateDisplay,
  ]);

  const onDateSelectionCallback = useCallback((primary: DateRange, compare: DateRange) => {
    setSelections({
      primary,
      compare,
    });
  }, []);

  const onDurationSelectedCallback = useCallback(
    (primary: DateRange, compare: DateRange, weekdayFilter: WeekdaysFilter) => {
      setOpen(false);
      setText(getFullDateRangeSelectionText(primary, compare, compareActive, usePlainDateDisplay));
      setSelections({ primary, compare });
      onSelectionChanged?.(primary, compareActive ? compare : null);
      onWeekdayFilterChange?.(weekdayFilter);
    },
    [compareActive, usePlainDateDisplay, getFullDateRangeSelectionText, onSelectionChanged, onWeekdayFilterChange]
  );

  if (dayOnly) {
    selectProps = {
      showAppendButton: true,
      showPrependButton: true,
      prependButtonDisabled: disabled,
      appendButtonDisabled: disabled || !enableNextButton,
      prependButtonIcon: <MdChevronLeft size={24} />,
      appendButtonIcon: <MdChevronRight size={24} />,
      onPrependButtonClick: onNavigateLeftClick,
      onAppendButtonClick: onNavigationRightCallback,
    };
  } else if (comparisonEnabled) {
    selectProps = {
      showAppendButton: true,
      appendButtonIcon: <MdCompareArrows size={24} color={compareActive ? 'white' : 'var(--gray17)'} />,
      appendButtonLabel: 'Hier klicken für Vergleich von Zeiträumen',
      appendButtonColor: compareActive ? 'primary' : 'secondary',
      appendButtonOutline: !compareActive,
      onAppendButtonClick: onCompareClickCallback,
    };
  }

  return (
    <>
      <div ref={wrapperRef}>
        <div>
          <BaseSelect
            disabled={disabled}
            className='base-select'
            style={{
              width: dayOnly || !comparisonEnabled ? 240 : 300,
              height: 40,
              backgroundColor: 'white',
            }}
            icon={<MdPermContactCalendar size={18} />}
            text={text}
            onSelectClick={_toggleSelect}
            infoLabel='Hier Zeitraum wählen'
            {...selectProps}
          />
        </div>
        <DateRangeSelectorPopup
          open={open}
          dayOnly={dayOnly}
          target={wrapperRef}
          futureEnabled={futureEnabled}
          initialComparisonRange={selections.compare}
          initialPrimaryRange={selections.primary}
          comparisonEnabled={comparisonEnabled}
          onCancel={onCancelCallback}
          onCompareActivation={setCompareActive}
          comparisonActive={compareActive}
          onSelectionChanged={onDateSelectionCallback}
          onDurationSelected={onDurationSelectedCallback}
          earliestDate={earliestDate}
          initialWeekdaysFilter={weekdayFilter}
        />
      </div>
    </>
  );
};
