import Model, { DefaultType, MemberType } from '../../../utils/Model';
import moment from 'moment';
import toObject, { ToObjectConfiguration, ToObjectFieldConfig } from '../../../utils/toObject/toObject';
import { MetricFilterRoutingParams } from '../routing/MetricFilterRoutingParams';
import Constants from '../../../common/util/Constants';

export default class MetricsFilter extends Model<MetricsFilter> {
  orgKeys: Array<string>;
  from: moment.Moment;
  to: moment.Moment;

  constructor(
    source: any = null, // FIXME: set proper type
    shallow: boolean = false
  ) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getFromAsString(): string {
    return this.from.format('YYYY-MM-DD');
  }

  getToAsString(): string {
    return this.to.format('YYYY-MM-DD');
  }

  hasSameFilters(other: MetricsFilter): boolean {
    return this.orgKey === other.orgKey && this.from.isSame(other.from) && this.to.isSame(other.to);
  }

  getDateRange(): Array<moment.Moment> {
    const from: moment.Moment = this.from.clone();
    const dates: Array<moment.Moment> = [from.clone()];
    while (from.add(1, 'days').diff(this.to) < 0) {
      dates.push(from.clone());
    }
    dates.push(this.to.clone());
    return dates;
  }

  getRenderedDateRange(): string {
    if (this.from.isSame(this.to)) {
      return this.from.format('DD.MM.YYYY');
    } else {
      return this.from.format('DD.MM.YYYY') + ' - ' + this.to.format('DD.MM.YYYY');
    }
  }

  toObject(toObjectConfiguration: ToObjectConfiguration | null | undefined = null): Object {
    return toObject(
      this,
      toObjectConfiguration,
      new ToObjectFieldConfig([], {
        from: (src) => src.format('YYYY-MM-DD'),
        to: (src) => src.format('YYYY-MM-DD'),
      })
    );
  }

  clone() {
    return new MetricsFilter(this);
  }

  get orgKey(): string {
    return this.orgKeys.length > 0 ? this.orgKeys[0] : Constants.DEFAULT_ORG_KEY;
  }

  set orgKey(orgKey: string) {
    this.orgKeys = this.orgKeys.length > 1 ? [orgKey, ...this.orgKeys.splice(1)] : [orgKey];
  }

  get identifier() {
    return `${this.orgKeys.join(',')}:${this.getFromAsString()}:${this.getToAsString()}`;
  }

  getRoutingParams(): Partial<MetricFilterRoutingParams> {
    return {
      orgKeys: this.orgKeys,
      from: this.from.format('YYYY-MM-DD'),
      to: this.to.format('YYYY-MM-DD'),
    };
  }

  getDefaults(): DefaultType<MetricsFilter> {
    return {
      orgKeys: () => [Constants.DEFAULT_ORG_KEY],
      from: () => moment().subtract(29, 'days'),
      to: () => moment(),
    };
  }

  getMembers(): MemberType<MetricsFilter> {
    return {
      from: (src) => moment(src),
      to: (src) => moment(src),
    };
  }
}
