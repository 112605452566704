import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import Styles from './SearchResultList.module.scss';
import { useKeyboardShortcut } from '../hooks/useKeyboardShortcut';
import classNames from 'classnames';

type GroupedSearchResult<T> = {
  group: string | null;
  results: Array<T>;
};

type Props<T> = {
  results?: Array<GroupedSearchResult<T>>;
  onResultSelect: (unit: T) => void;
  searchValue?: string;
  getNameForNode?: (node: T) => string[];
  customElement?: (res: T) => React.ReactNode;
};

export const SearchResultList = <T,>({
  results = [],
  onResultSelect,
  searchValue = '',
  getNameForNode,
  customElement,
}: PropsWithChildren<Props<T>>) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [maxIdx, setMaxIdx] = useState(-1);
  const [focusedElement, setFocusedElement] = useState<HTMLLIElement>(null);

  const selectNext = useCallback(() => setSelectedIndex((v) => (v + 1) % maxIdx), [maxIdx]);
  const selectPrev = useCallback(() => setSelectedIndex((v) => (v + maxIdx - 1) % maxIdx), [maxIdx]);
  const selectKeyboardSelect = useCallback(() => {
    const allResults = results.map((r) => r.results).flat();
    onResultSelect(allResults[selectedIndex]);
  }, [onResultSelect, results, selectedIndex]);

  useKeyboardShortcut({ code: 'ArrowDown' }, selectNext);
  useKeyboardShortcut({ code: 'ArrowUp' }, selectPrev);
  useKeyboardShortcut({ code: 'Enter' }, selectKeyboardSelect);

  // Reset selected index if results change
  useEffect(() => {
    setSelectedIndex(-1);
    setMaxIdx(results.reduce((prev, curr) => prev + curr.results.length, 0));
  }, [results]);

  useEffect(() => {
    if (!focusedElement) {
      return;
    }

    focusedElement.focus();
  }, [focusedElement]);

  const getListItem = (res: T) => {
    if (customElement) {
      return customElement(res);
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <span
          style={{
            flex: 1,
          }}
          className={Styles.Name}
          dangerouslySetInnerHTML={{
            __html: getNameForNode(res)
              .map((v: string) => v.replace(new RegExp(searchValue, 'i'), `<span class="${Styles.Mark}">$&</span>`))
              .join(' / '),
          }}
        />
      </div>
    );
  };

  return (
    <div className={Styles.SearchResultList}>
      {results.map((result, treeIdx) => (
        <ul key={result.group} className={Styles.List}>
          {result.results.map((res, resIdx) => (
            <li
              ref={(ref) => {
                if (selectedIndex === treeIdx + resIdx) {
                  setFocusedElement(ref);
                }
              }}
              className={classNames(Styles.Item, {
                [Styles.Focus]: selectedIndex === treeIdx + resIdx,
              })}
              key={resIdx}
              onClick={() => onResultSelect(res)}>
              {getListItem(res)}
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};
