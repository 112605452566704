import { useCallback, useMemo } from 'react';
import OrgTree from '../../metrics2/models/websocket/org/OrgTree';
import { isHub } from '../components/OrganizationMultiSelect';
import OrgUnitNode from '../../metrics2/models/websocket/org/OrgUnitNode';
import { ContractorUtils } from '../../utils/tours/ContractorUtils';
import { ViewMode } from '@redux/app.slice';

export const sortOrgUnitByName = (aOrgUnit: OrgUnitNode, bOrgUnit: OrgUnitNode) => {
  return aOrgUnit.orgUnit.name.localeCompare(bOrgUnit.orgUnit.name);
};

export const useOrgTree = (orgTree: OrgTree, viewMode: ViewMode) => {
  const filterOutVp = useCallback(
    (node: OrgUnitNode) => {
      if (viewMode === 'dashboard') {
        return false;
      }
      return ContractorUtils.isContractor(node?.orgUnit?.orgKey);
    },
    [viewMode]
  );

  const { rootNodes, tree } = useMemo(() => {
    orgTree.sortRecursively(sortOrgUnitByName);
    orgTree.removeNodes((o) => isHub(o.orgUnit));

    if (filterOutVp) {
      orgTree.removeNodes(filterOutVp);
    }

    const rootNodes = (() => {
      if (!orgTree.rootNode) {
        return null;
      }
      if (orgTree.rootNode.orgUnit.orgType === 'hermes_org_virtual') {
        if (!Array.isArray(orgTree.rootNode.children)) {
          throw new Error(
            `Invalid orgKey assigned to user (${orgTree.rootNode.orgUnit?.orgKey}) which does not have any children`
          );
        }
        // if node is a virtual node, we trim that of and lift the other ones up.
        // Otherwise there would be a "Multi"-Node ahead of the other one
        return [
          ...orgTree.rootNode.children.map((c) => {
            c.parent = null;
            return c;
          }),
        ];
      }
      return [orgTree.rootNode];
    })();
    return { rootNodes: rootNodes, tree: orgTree };
  }, [filterOutVp, orgTree]);

  return {
    rootNodes,
    tree,
  };
};
