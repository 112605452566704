import React, { ReactElement, useMemo, useState } from 'react';
import { parse } from 'svg-parser';
import { Svg, G, Path, pdf, Text } from '@react-pdf/renderer';
import { Button, Spinner } from 'reactstrap';
import { FaDownload } from 'react-icons/fa';

import OrgUnitNode from '../../../../modules/metrics2/models/websocket/org/OrgUnitNode';
import { TourIdentifier } from '../../../../modules/tour/models/state/tourDetails/TourIdentifier';
import FinishedDelivery from '../../../../modules/tour/models/entities/FinishedDelivery';
import TourDetails from '../../../../modules/tour/models/entities/TourDetails';
import { useOrgTreeContext } from '@contexts/org-tree-context';
import { styles as pdfStyle } from './touren-report';

import styles from './touren-report.module.scss';

type WithTourenReportActionProps = {
  tourIdentifier: TourIdentifier;
  finishedDeliveries: FinishedDelivery[];
  tourDetails: TourDetails;
  imageUrls: Record<number, string>;
};

export type TourenReportActionProps = Omit<WithTourenReportActionProps, 'imageUrls'> & {
  orgUnit: OrgUnitNode;
  loading: boolean;
  signatureMap: Map<string, ReactElement>;
};

export const withTourenReportAction =
  (WrappedComponent: React.ComponentType<TourenReportActionProps>) =>
  ({ imageUrls, finishedDeliveries, tourIdentifier, ...props }: WithTourenReportActionProps) => {
    const [signaturesMap, setSignaturesMap] = useState(new Map<string, ReactElement>([]));
    const [loading, setLoading] = useState(false);

    const orgTreeCtx = useOrgTreeContext();

    const orgUnit = useMemo(
      () => findNodeInTree(orgTreeCtx?.orgTree?.rootNode, `oz:${tourIdentifier.orgId}`),
      [orgTreeCtx?.orgTree?.rootNode, tourIdentifier.orgId]
    );

    const loadReport = async () => {
      setLoading(true);

      const imagePromises = Object.entries(imageUrls)
        .filter(([tourNr]) => finishedDeliveries.some((fd) => fd?.displayableStopNumber?.toString() === tourNr))
        .map(async ([tourNr, url]) => {
          let signatureEntryComponent: ReactElement;
          try {
            const response = await fetch(url);
            if (response.status === 200) {
              const blob = await response.blob();
              const text = await blob.text();
              const parsedSvg: RootNode = parse(text);
              signatureEntryComponent = constrainSvg(parsedSvg, tourNr);
            } else {
              signatureEntryComponent = (
                <Text style={{ ...pdfStyle.text }}>
                  Signatur konnte nicht gefunden
                  <br /> werden
                </Text>
              );
            }
          } catch (error) {
            signatureEntryComponent = (
              <Text style={{ ...pdfStyle.text }}>
                Signatur konnte nicht geladen
                <br /> werden
              </Text>
            );
          } finally {
            setSignaturesMap((signatures) => {
              signatures.set(tourNr, signatureEntryComponent);
              return signatures;
            });
          }
        });

      await Promise.all(imagePromises);
      const _pdf = pdf();
      _pdf.updateContainer(
        <WrappedComponent
          {...props}
          finishedDeliveries={finishedDeliveries}
          signatureMap={signaturesMap}
          orgUnit={orgUnit}
          tourIdentifier={tourIdentifier}
          loading={loading}
        />
      );

      const pdfBlob = await _pdf.toBlob();

      // Create blob link to download
      const url = URL.createObjectURL(pdfBlob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `touren-report-${tourIdentifier.orgId}-${tourIdentifier.number}-${tourIdentifier.date}.pdf`
      );

      const clickHandler = () => {
        setTimeout(() => {
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          URL.revokeObjectURL(url);
          setLoading(false);
        }, 150);
      };

      link.addEventListener('click', clickHandler, false);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      setLoading(false);
    };

    return (
      <Button onClick={loadReport} disabled={loading} color='primary' className={styles.tourenReportAction}>
        {loading ? <Spinner color='light' size='sm' /> : <FaDownload />}
      </Button>
    );
  };

type ElementNode = {
  type: 'element';
  tagName?: string;
  properties?: Record<string, string | number>;
  children: Array<ElementNode | string>;
  value?: string;
  metadata?: string;
};

export interface RootNode {
  type: 'root';
  children: [ElementNode];
}

const constrainSvgChild = (child: ElementNode, key: string): ReactElement => {
  switch (child.tagName) {
    case 'path':
      return (
        <Path
          key={`${key}-path`}
          d={child.properties['d'] as any}
          strokeWidth={child.properties['stroke-width'] as any}
        />
      );
    case 'g':
      return (
        <G
          key={`${key}-g`}
          fill={child.properties['fill'] as any}
          stroke={child.properties['stroke'] as any}
          strokeLineCap={child.properties['stroke-linecap'] as any}>
          {child.children.map((child, index) => constrainSvgChild(child as ElementNode, `${key}-${index}`))}
        </G>
      );
    default:
      return <></>;
  }
};

const constrainSvg = (svg: RootNode, key: string): ReactElement => {
  const { width, height } = svg.children[0].properties;
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <Svg width={'100%'} height={'auto'} viewBox={viewBox}>
      {svg.children[0].children.map((child, index) => constrainSvgChild(child as ElementNode, `${key}-${index}`))}
    </Svg>
  );
};

const findNodeInTree = (node: OrgUnitNode, orgKey: string): OrgUnitNode => {
  const comparisonFunction = (val) => val?.orgUnit?.['orgKey'] === orgKey;
  if (comparisonFunction(node)) {
    return node;
  }
  if (!node?.children?.length) return null;

  for (let i = 0; i < node.children.length; i++) {
    const match = findNodeInTree(node.children[i], orgKey);
    if (match) {
      return match;
    }
  }
  return null;
};
