import BasePayload from '../BasePayload';
import { DefaultType, MemberType } from '../../../../../utils/Model';
import { PlainObjectOf } from '../../../../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export default class StateStoreResponsePayload extends BasePayload<StateStoreResponsePayload> {
  uuid: string;

  constructor(source: PlainObjectOf<StateStoreResponsePayload>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<StateStoreResponsePayload> {
    return undefined;
  }

  getMembers(): MemberType<StateStoreResponsePayload> {
    return undefined;
  }
}
