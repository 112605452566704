import React, { PropsWithChildren, ReactElement } from 'react';
import { HeaderContext } from '@tanstack/react-table';
import classNames from 'classnames';
import { DataTableColumnMeta } from '@data-table/data-table.types';
import { useToggleState } from '@hooks/use-toggle-state-hook';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { DraggableList } from '@components/draggable-list';
import { DataTableColumnFilter } from '../data-table-column-filter';
import { CenterAlignedCell } from '@legacy-modules/dashboard/components/table/CenterAlignedCell';
import { TruncatedText } from '@components/truncated-text';
import style from './data-table-column-header.module.scss';
import { useDndContext } from '@dnd-kit/core';

type ColumnHeaderProps<T, V> = HeaderContext<T, V>;

const DataTableColumnHeaderBase = <T, V>({
  header,
  children,
}: PropsWithChildren<HeaderContext<T, V>>): ReactElement => {
  const { column, getContext } = header;
  const { table } = getContext();
  const isPinned = !!column?.getIsPinned() || false;
  const sortOrder = column?.getIsSorted() || false;
  const sortable = column?.getCanSort() || false;
  const columnCellClassName = (column?.columnDef?.meta as DataTableColumnMeta)?.columnCellClassName || '';
  const pinnedLeftOffset = (column?.columnDef?.meta as DataTableColumnMeta)?.columnStyle?.left || 0;
  const filterable = column?.getCanFilter() || false;
  const [filterIsOpen, toggleFilter] = useToggleState(false);

  const toggleSorting = () => {
    column.toggleSorting();
    table.resetPagination();
  };

  return (
    <DraggableList.Item
      data-testid='data-table-column-header'
      sortableArguments={{
        id: header.id,
        disabled: isPinned || filterIsOpen,
      }}
      className={classNames(
        style.dataTableColumnHeader,
        {
          [style.sortable]: sortable,
          [style.sorted]: sortOrder,
          [style.pinned]: isPinned,
        },
        [style[columnCellClassName]]
      )}
      style={{ left: pinnedLeftOffset }}
      pinned={isPinned}
      onClick={toggleSorting}>
      <div
        className={classNames(style.dataTableColumnHeader_cell, {
          [style.filterable]: filterable,
          [style.sorted]: sortOrder,
        })}>
        {sortable && sortOrder && (
          <>
            {sortOrder === 'desc' ? (
              <TiArrowSortedDown data-testid='arrow-down' className={style.sortIcon} viewBox='-3 -3 30 30' />
            ) : (
              <TiArrowSortedUp data-testid='arrow-up' className={style.sortIcon} viewBox='-3 -3 30 30' />
            )}
          </>
        )}
        <div className={style.dataTableColumnHeader_text}>{children}</div>
        {filterable && (
          <DataTableColumnFilter<T, V> column={column} filterIsOpen={filterIsOpen} toggleFilter={toggleFilter} />
        )}
      </div>
    </DraggableList.Item>
  );
};

function CenterAlignedColumnHeader<T, V>({ table, header, column }: ColumnHeaderProps<T, V>): ReactElement {
  const meta = column.columnDef.meta as DataTableColumnMeta;
  const sortedClassName = meta?.headerCellClassName || 'textHeaderContent';
  return (
    <DataTableColumnHeaderBase<T, V> table={table} column={column} header={header} key={header.id}>
      <CenterAlignedCell>
        <div
          className={classNames({
            [style[sortedClassName]]: !column.getIsSorted(),
          })}>
          {meta?.exportValue}
        </div>
      </CenterAlignedCell>
    </DataTableColumnHeaderBase>
  );
}

function TruncatedColumnHeader<T, V>({ table, header, column }: ColumnHeaderProps<T, V>): ReactElement {
  const { active } = useDndContext();
  const dragged = active?.id === header.id;
  const meta = column.columnDef.meta as DataTableColumnMeta;
  const sortedClassName = meta?.headerCellClassName || 'textHeaderContent';
  return meta?.exportValue ? (
    <DataTableColumnHeaderBase<T, V> table={table} column={column} header={header} key={header.id}>
      <TruncatedText
        className={classNames({
          [style[sortedClassName]]: !column.getIsSorted(),
        })}
        showTooltipIfCutOff={!dragged}>
        {meta.exportValue}
      </TruncatedText>
    </DataTableColumnHeaderBase>
  ) : null;
}

export default {
  CenterAlignedColumnHeader,
  TruncatedColumnHeader,
};
