import React from 'react';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import { MapValueMode } from '../../models/enumerations/MapValueMode';
import MapUtils from '../../../utils/map/MapUtils';
import color from 'color';
import { PositiveDirection } from '../../../metrics2/models/entities/PositiveDirection';
import { useSelector } from 'react-redux';
import OrgUnit from '@legacy-modules/metrics2/models/entities/organization/OrgUnit';
import { UseOverviewLegendDataOutput } from '@other-components/use-overview-legend-data-hook/use-overview-legend-data.hook';
import { selectOverviewValueExpression } from '@redux/overview.selectors';
import { useOverviewContext } from '@contexts/overview-context';

type Props = UseOverviewLegendDataOutput & {
  orgUnits: Array<OrgUnit>;
  onHoverOrgUnit: (orgKey: string) => void | null | undefined;
  onValueModeChanged: (mode: MapValueMode) => void | null | undefined;
};

export default function OverviewMapLegend({
  primaryChartData,
  compareChartData,
  diffChartData,
  percentageMap,
  orgUnits,
  onHoverOrgUnit,
  onValueModeChanged,
}: Props) {
  const primaryValueExpression = useSelector(selectOverviewValueExpression);
  const {
    mapValueModeState: [valueMode],
    hoverOrgKeyState: [hoverOrgKey],
  } = useOverviewContext();

  const chartData =
    valueMode === MapValueMode.difference
      ? diffChartData
      : valueMode === MapValueMode.comparison
      ? compareChartData
      : primaryChartData;

  return (
    <div className={`map-scale ${valueMode}-value positive-direction-${primaryValueExpression?.positiveDirection}`}>
      <div className='value-wrapper '>
        <div className='value-selector'>{primaryValueExpression?.getLabel() || ''}</div>
        {compareChartData && (
          <div className='value-comparison'>
            <ButtonGroup>
              <Button className='primary' color='white' onClick={() => onValueModeChanged(MapValueMode.primary)}>
                {primaryChartData.label}
              </Button>
              <Button className='comparison' color='white' onClick={() => onValueModeChanged(MapValueMode.comparison)}>
                {compareChartData.label}
              </Button>
              <Button className='difference' color='white' onClick={() => onValueModeChanged(MapValueMode.difference)}>
                {diffChartData.label}
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>
      {chartData && (
        <div className='hoverable-map-scale'>
          <div className='scale-wrapper'>
            <div className='scale'>
              {orgUnits
                .map((ou) => {
                  const value = chartData.orgUnitData?.[ou.orgKey];
                  const styles = MapUtils.calculateGradientColor(
                    percentageMap?.get(ou.orgKey) || 0,
                    false,
                    valueMode,
                    valueMode === MapValueMode.difference &&
                      primaryValueExpression?.positiveDirection === PositiveDirection.down
                  );
                  // replace special chars not alloed within document selectors for tooltips
                  const elemId = ou.orgKey?.replace(/[^\w\s]/gi, '');
                  return (
                    <div
                      id={elemId}
                      key={ou?.orgKey}
                      className={`scale-hover-item ${ou.orgKey === hoverOrgKey ? 'active' : ''}`}
                      style={{ left: `${(percentageMap?.get(ou.orgKey) || 0) * 100}%` }}
                      onMouseEnter={() => onHoverOrgUnit(ou.orgKey)}
                      onMouseLeave={() => onHoverOrgUnit(null)}>
                      <span
                        style={{
                          background: color(styles.fillColor).alpha(0.6).rgb().string(),
                        }}
                      />
                      <UncontrolledTooltip placement='top' target={elemId}>
                        {ou.name}: {primaryValueExpression.getValueFormatter()(value)}
                      </UncontrolledTooltip>
                    </div>
                  );
                })
                .filter((c) => !!c)}
            </div>
            <div className='scale-values'>
              <div className='scale-value min-value'>
                {primaryValueExpression.getValueFormatter()(chartData.minValue || 0)}
              </div>
              <div className='scale-value max-value'>
                {primaryValueExpression.getValueFormatter()(chartData.maxValue || 0)}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
