import { useAuthContext } from '@contexts/auth-context';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { getKpiTree, KpiNode, ValueExpressionNode } from '@legacy-modules/dashboard/utils/KpiTree';
import { NodeData, TreeMenuNode } from '@legacy-modules/navigation/TreeMenu';
import { selectDashboardOrgKey } from '@redux/dashboard.selectors';
import React, { ComponentType, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

type ValueExpressionTableSettingsProps = {
  valueExpressionKeys: string[];
  availableKpiSelectorKeys?: string[];
  addValueExpressionKey?: (valueExpressionKey: string) => void;
  removeValueExpressionKey?: (valueExpressionKey: string) => void;
};
export type ValueExpressionTableSettingsComponentProps = {
  tree: TreeMenuNode<NodeData>[];
  selectedTreeNodes: TreeMenuNode<NodeData>[];
  searchValue: string;
  onSearchChange: (searchValue: string) => void;
  onSearchClear: () => void;
  onNodeSelection: (selectedNodes: TreeMenuNode<NodeData>[]) => void;
};

function filterTree(nodes: TreeMenuNode<NodeData>[], filterKeys: string[]): TreeMenuNode<NodeData>[] {
  if (!filterKeys || filterKeys.length === 0) {
    return nodes;
  }
  return nodes
    .map((node) => {
      if (node instanceof ValueExpressionNode) {
        return node;
      } else if (node instanceof KpiNode) {
        const updatedNode = new KpiNode(node?.label, filterTree(node.children, filterKeys) as KpiNode[]);
        updatedNode.parent = node?.parent;
        updatedNode.valueExpressionKey = node?.valueExpressionKey;
        return updatedNode;
      }
    })
    .filter((node) => {
      if (node instanceof ValueExpressionNode) {
        return filterKeys.includes(node.valueExpression.identifier);
      } else {
        return node?.children?.length > 0;
      }
    });
}

// TODO: check why section gets collapsed when selecting a node
export function withValueExpressionTableSettings(Component: ComponentType<ValueExpressionTableSettingsComponentProps>) {
  function WithValueExpressionTableSettings({
    valueExpressionKeys,
    availableKpiSelectorKeys,
    addValueExpressionKey,
    removeValueExpressionKey,
  }: ValueExpressionTableSettingsProps) {
    const [searchValue, setSearchValue] = useState('');
    const valueExpressionMap = useValueExpressionContext();
    const authService = useAuthContext();
    const orgKey = useSelector(selectDashboardOrgKey);
    const tree = useMemo(() => {
      const unfiltered = getKpiTree(authService, valueExpressionMap, orgKey);
      const filtered = filterTree(unfiltered, availableKpiSelectorKeys);
      if (searchValue?.length > 0) {
        return traversalFindNodesByPartialText(searchValue, filtered);
      }
      return filtered;
    }, [valueExpressionMap, searchValue, authService, availableKpiSelectorKeys, orgKey]);
    const selectedTreeNodes = useMemo(() => {
      const nodes = valueExpressionKeys
        ?.map((valueExpressionKey) => {
          return traversalFindNode(tree, valueExpressionKey);
        })
        .filter((node) => !!node);
      return nodes;
    }, [tree, valueExpressionKeys]);
    const onSearchChange = useCallback((searchValue) => {
      setSearchValue(searchValue);
    }, []);
    const onSearchClear = useCallback(() => {
      setSearchValue('');
    }, []);
    const onNodeSelection = useCallback(
      (selectedNodes) => {
        const previousSelectedKeys = traversalGetSelectedValueExpressionKeys(
          selectedTreeNodes.filter((node) => node instanceof ValueExpressionNode)
        );
        const nextSelectedKeys = traversalGetSelectedValueExpressionKeys(
          selectedNodes.filter((node) => node instanceof ValueExpressionNode)
        );
        // There will be only one key difference between the two arrays
        // so we have to either add or remove the key
        const addedKeys = nextSelectedKeys.filter((key) => !previousSelectedKeys.includes(key));
        const removedKeys = previousSelectedKeys.filter((key) => !nextSelectedKeys.includes(key));
        if (addedKeys.length > 0) {
          addValueExpressionKey(addedKeys[0]);
        } else if (removedKeys.length > 0) {
          removeValueExpressionKey(removedKeys[0]);
        }
      },
      [addValueExpressionKey, removeValueExpressionKey, selectedTreeNodes]
    );

    return (
      <Component
        tree={tree}
        searchValue={searchValue}
        selectedTreeNodes={selectedTreeNodes}
        onNodeSelection={onNodeSelection}
        onSearchChange={onSearchChange}
        onSearchClear={onSearchClear}
      />
    );
  }

  return WithValueExpressionTableSettings;
}

const traversalGetSelectedValueExpressionKeys = (tree: TreeMenuNode<NodeData>[]): string[] => {
  const keys = [];
  for (const node of tree) {
    if (node?.children?.length > 0) {
      keys.push(...traversalGetSelectedValueExpressionKeys(node.children));
    } else if (node instanceof ValueExpressionNode && node?.valueExpression?.identifier) {
      keys.push(node.valueExpression.identifier);
    }
  }
  return keys.filter((key) => !!key);
};

const traversalFindNode = (tree: TreeMenuNode<NodeData>[], valueExpressionKey: string): TreeMenuNode<NodeData> => {
  for (const node of tree) {
    const topLevelHit = valueExpressionKey === (node as ValueExpressionNode)?.valueExpression?.identifier;
    if (topLevelHit) {
      return node;
    } else if (node?.children?.length > 0) {
      const childHit = traversalFindNode(node.children, valueExpressionKey);
      if (childHit) {
        return childHit;
      }
    }
  }
  return null;
};

const traversalFindNodesByPartialText = (
  searchValue: string,
  tree: TreeMenuNode<NodeData>[]
): TreeMenuNode<NodeData>[] => {
  const searchString = searchValue.toLowerCase();
  const resultNodes = [];
  for (const node of tree) {
    if (node instanceof ValueExpressionNode && node.matchesSearchInput(searchString)) {
      resultNodes.push(node);
    } else if (node?.children?.length > 0) {
      const childResults = traversalFindNodesByPartialText(searchValue, node.children);
      resultNodes.push(...childResults);
    }
  }
  return resultNodes;
};
