import React, { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useIsMounted } from '../../dashboard/hooks/useIsMounted';
import { IImageProps, Image } from './Image';

export const ImagePlaceholder = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

type ILazyImageProps = IImageProps & {
  onLoadCompleted: (source: string) => void;
  lazyLoadRoot: Element | null;
};

const DEFAULT_LAZY_LOAD_ROOT = undefined;

const DEFAULT_IN_VIEW_OPTIONS = { rootMargin: '100%', triggerOnce: true };

const getOptionsForRoot = (root: any) => {
  if (!root || !(root instanceof Element)) {
    return DEFAULT_IN_VIEW_OPTIONS;
  }
  return {
    ...DEFAULT_IN_VIEW_OPTIONS,
    root,
  };
};

const LazyImage = React.forwardRef<HTMLImageElement, ILazyImageProps>((props, ref) => {
  const {
    selected,
    onSelect,
    onDeselect,
    onLoadCompleted,
    loadFn,
    style,
    lazyLoadRoot = DEFAULT_LAZY_LOAD_ROOT,
    className,
    alt,
  } = props;

  const [imageSrc, setImageSrc] = useState(ImagePlaceholder);
  const [inViewRef, inView] = useInView(getOptionsForRoot(lazyLoadRoot));

  const mounted = useIsMounted();

  useEffect(() => {
    if (imageSrc !== ImagePlaceholder) {
      onLoadCompleted && onLoadCompleted(imageSrc);
    }
  }, [imageSrc, onLoadCompleted]);

  useEffect(() => {
    if (!inView) {
      return;
    }
    loadFn().then((src) => {
      if (!mounted.current) {
        return;
      }
      setImageSrc(src);
    });
  }, [inView, loadFn, onLoadCompleted, mounted]);

  // Use `useCallback` so we don't recreate the function on each render - Could result in infinite loop
  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref != null) {
        ref.current = node;
      }

      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node);
    },
    [inViewRef, ref]
  );

  return (
    <Image
      ref={setRefs}
      src={imageSrc}
      selected={selected}
      onSelect={onSelect}
      onDeselect={onDeselect}
      style={style}
      className={className}
      alt={alt}
    />
  );
});

LazyImage.displayName = 'LazyImage';

export { LazyImage };
