import React, { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { CenterAlignedCell } from './CenterAlignedCell';
import { ContractorBadge } from '../ContractorBadge';
import { ContractorUtils } from '../../../utils/tours/ContractorUtils';
import { tourDetailsSlice } from '@redux/tour-details.slice';
import { dashboardSlice } from '@redux/dashboard.slice';
import { selectDashboardOrgKey } from '@redux/dashboard.selectors';

import Styles from './TourNumberCell.module.scss';

export const notifyError = (message: string) => toast.error(message, {});

// Hotfix for a bug where the backend sends invalid data
// for contractorKeys. Instead of null an invalid string
// was returned.
export const isValidKey = (contractorKey?: string) => contractorKey && /:(\d+)/.test(contractorKey);

type Props = {
  value: string;
  openable?: boolean;
  orgKey: string;
  // in format YYYY-MM-DD
  tourDate: string;
  contractorKey?: string;
};

const TourNumberCell = ({ value, openable = true, orgKey, tourDate, contractorKey = null }: Props) => {
  const dispatch = useDispatch();
  const dashboardOrgKey = useSelector(selectDashboardOrgKey);

  const tourType = (() => {
    const cleanedContractorKey = contractorKey != null && isValidKey(contractorKey) ? contractorKey : null;

    if (cleanedContractorKey == null) {
      return 'normal';
    }
    if (!ContractorUtils.isHomeTour(cleanedContractorKey)) {
      return 'taskforce';
    }
    return 'home';
  })();

  const hideBadge = !['home', 'taskforce'].includes(tourType) || ContractorUtils.isContractor(dashboardOrgKey);

  const badge = (() => {
    if (hideBadge) {
      return null;
    }
    return <ContractorBadge orgKey={contractorKey}>{tourType === 'taskforce' ? 'TF' : 'VP'}</ContractorBadge>;
  })();

  const onTourNumberSelect = useCallback(() => {
    if (!openable) return;
    dispatch(
      tourDetailsSlice.actions.setTourIdentifier({
        orgId: orgKey,
        date: tourDate,
        number: value,
        contractorKey,
      })
    );
    dispatch(dashboardSlice.actions.setActiveDashboardView('tour_loaded.list'));
  }, [contractorKey, dispatch, openable, orgKey, tourDate, value]);

  return (
    <div
      className={classNames(Styles.TourNumberCell, {
        [Styles.withBadge]: !hideBadge,
      })}>
      <CenterAlignedCell>
        {openable ? (
          <button
            className={Styles.Link}
            onClick={(e) => {
              e.preventDefault();
              onTourNumberSelect();
            }}>
            <span className={Styles.TourNumberCell}>
              <span className={Styles.TourNumber}>
                {badge}
                {value}
              </span>
            </span>
          </button>
        ) : (
          <span className={Styles.TourNumberCell}>
            {badge}
            <span>{value}</span>
          </span>
        )}
      </CenterAlignedCell>
    </div>
  );
};

export { TourNumberCell };
