import { undeliverableReasonsShipmentBased } from '@legacy-modules/metrics2/models/entities/MetricType';
import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import ruecklauferEilGrund from './ruecklaufer-eil-grund';
import {
  UndeliverableReasonExpressShortLabels,
  UndeliverableReasonLabels,
  UndeliverableReasonShortLabels,
} from '@legacy-modules/metrics2/models/enumerations/UndeliverableReason';
import { MetricTypeKey } from './metric-type-keys';

const ruecklaufEilGruende = undeliverableReasonsShipmentBased.flatMap((reason) => {
  const expression = new MapDetailValueExpression(
    ruecklauferEilGrund.metricType,
    reason,
    UndeliverableReasonLabels[reason] || `unbekannt (${reason})`
  );
  expression.shortLabel = UndeliverableReasonShortLabels[reason];
  expression.setIdentifier(`${MetricTypeKey.RLExpressByUndeliverableReason}.${reason}`);
  return [expression, ...(expression.getChildren() || [])];
});

const ruecklauferEilGruende = undeliverableReasonsShipmentBased.flatMap((key) => {
  const expression = new MapDetailValueExpression(
    ruecklauferEilGrund.metricType,
    key,
    UndeliverableReasonLabels[key] ?? `unbekannt (${key})`
  );
  expression.shortLabel = UndeliverableReasonExpressShortLabels[key];
  expression.setIdentifier(`ruecklauefer_eil_gruende.${key}`);
  return [expression, ...(expression.getChildren() || [])];
});

export default [...ruecklaufEilGruende, ...ruecklauferEilGruende];
