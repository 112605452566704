import { DashboardDataTableDataType, TourIdentifier } from '@data-table/data-table.types';
import { formatValueExpressionValue } from '@legacy-modules/dashboard/utils/FormatValueExpression';
import { Column, Row } from '@tanstack/react-table';
import moment from 'moment';
import { ValuableComplaintReasons } from '../../../../../modules/complaints/models/enums/ValuableComplaintsReason';
import { AnomalyReason } from '../../../../../modules/dashboard/anomalies/utils/AnomalyUtils';
import { Duration, DurationKey } from '../../../../../modules/dashboard/models/enums/Duration';
import { TourFilterStringParser } from '../../../../../modules/dashboard/services/TourFilterStringParser';
import { complaintReasons } from '../../../../../modules/metrics2/models/entities/MetricType';
import { AreaUtils } from '../../../../../modules/utils/tours/AreaUtils';
import { ContractorUtils } from '../../../../../modules/utils/tours/ContractorUtils';
import { CountryUtils } from '../../../../../modules/utils/tours/CountryUtils';
import { TourUtils } from '../../../../../modules/utils/tours/TourUtils';
import { ZSBUtils } from '../../../../../modules/utils/tours/ZSBUtils';
import QuotientValueExpression from '../../../../../modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import ValueExpression from '../../../../../modules/valueexpressions/models/valueexpressions/ValueExpression';
import { MetricTypeKey } from '@contexts/value-expression-context/value-expressions/metric-type-keys';
moment.locale('de');

export const escapeIdentifier = (id: string) => id.replace(/\//g, '___').replace(/\./g, '__');

export const unescapeIdentifier = (id: string) => id.replace(/___/g, '/').replace(/__/g, '.');

export const filterTimeBasedValueExpressionRows =
  <T>(valueExpression: ValueExpression) =>
  (row: Row<T>, columnId: string, _filterValue: string) => {
    const filterValue = _filterValue?.toString()?.replace(/[>|<]/g, '');
    if (!filterValue) return true;
    const toggleState = _filterValue?.toString()?.startsWith('<') ? 'less' : 'greater';
    const rowValue = row.getValue<number>(columnId);
    const formattedRowValue = Number.isFinite(rowValue) ? valueExpression.getValueFormatter()(rowValue) : null;
    if (!formattedRowValue) return toggleState === 'greater' ? false : true;
    const formattedFilterValue = valueExpression.getValueFormatter()(filterValue);
    if (
      (formattedRowValue.endsWith('h') && formattedFilterValue.endsWith('h')) ||
      (formattedRowValue.endsWith('min') && formattedFilterValue.endsWith('min'))
    ) {
      if (toggleState === 'greater') {
        return (
          Number(formattedRowValue.replace(/min|h/, '').replace(':', '.')) >=
          Number(formattedFilterValue.replace(/min|h/, '').replace(':', '.'))
        );
      } else {
        return (
          Number(formattedRowValue.replace(/min|h/, '').replace(':', '.')) <=
          Number(formattedFilterValue.replace(/min|h/, '').replace(':', '.'))
        );
      }
    } else if (formattedRowValue.endsWith('h') && formattedFilterValue.endsWith('min')) {
      return toggleState === 'greater';
    } else if (formattedRowValue.endsWith('min') && formattedFilterValue.endsWith('h')) {
      return toggleState === 'less';
    } else {
      return true;
    }
  };
export const filterValueExpressionRows = <T = unknown>(valueExpression: ValueExpression) => {
  const isTimeBasedValueExpression = (!!valueExpression && valueExpression?.getValueFormat()?.endsWith(' h')) ?? false;
  if (isTimeBasedValueExpression) {
    return filterTimeBasedValueExpressionRows<T>(valueExpression);
  }
  return (row: Row<T>, columnId: string, _filterValue: string) => {
    const isPercentBasedValueExpression =
      (!!valueExpression && valueExpression?.getValueFormat()?.endsWith('%')) ?? false;
    const isQuotientenValueExpression =
      (!!valueExpression &&
        !isPercentBasedValueExpression &&
        !isTimeBasedValueExpression &&
        valueExpression instanceof QuotientValueExpression) ??
      false;
    const decimalPlaces = isPercentBasedValueExpression
      ? (valueExpression as QuotientValueExpression)?.decimalPlaces === 3
        ? 5
        : 4
      : isQuotientenValueExpression
      ? 1
      : 0;
    const filterValue = _filterValue?.toString()?.replace(/[>|<]/g, '');
    if (!filterValue) return true;
    const factor = Math.pow(10, decimalPlaces);
    const rowValue = row.getValue<number>(columnId);
    const formattedRowValue = Number.isFinite(rowValue)
      ? Math.round((rowValue + Number.EPSILON) * factor) / factor
      : null;
    const toggleState = _filterValue?.toString()?.startsWith('<') ? 'less' : 'greater';
    const formattedFilterValue = Math.round((Number(filterValue) + Number.EPSILON) * factor) / factor;
    if (toggleState === 'greater') {
      if (!Number.isFinite(formattedRowValue)) {
        return false;
      }
      return formattedRowValue >= formattedFilterValue;
    } else {
      return !formattedRowValue || formattedRowValue <= formattedFilterValue;
    }
  };
};
/**
 * cleanup duplicates or not existent or not supported value expression keys
 * @param valueExpressionKeys
 * @returns string[]
 */
export const cleanupValueExpressionKeys = (valueExpressionKeys: string[]): string[] => {
  // Business logic:
  // Fortschritt value expression is never saved to the user config,
  // instead of that, we use touren value expression.
  const filtered = valueExpressionKeys?.filter((key) => key !== 'fortschritt');

  const indexOfLegacyIstLademenge = filtered.indexOf('lademenge');
  const indexOfLegacyAvgIstLademenge = filtered.indexOf('lademenge/touren');

  if (indexOfLegacyIstLademenge > -1) {
    // implemented at 2023-03-08
    // remove legacy key and replace with new key
    filtered.splice(indexOfLegacyIstLademenge, 1, MetricTypeKey.TARelevantSum);
  }
  if (indexOfLegacyAvgIstLademenge > -1) {
    // implemented at 2023-03-08
    // remove legacy key and replace with new key
    filtered.splice(indexOfLegacyAvgIstLademenge, 1, 'ist_lademenge/touren');
  }
  return Array.from(new Set(filtered));
};

export const valueExpressionSortingFn = <T>(rowA: Row<T>, rowB: Row<T>, columnId: string) => {
  const aValue = rowA.getValue<number>(columnId);
  const bValue = rowB.getValue<number>(columnId);
  if (aValue === bValue) {
    return 0;
  } else if (!aValue) {
    return -1;
  } else if (!bValue) {
    return 1;
  }
  return aValue > bValue ? 1 : -1;
};
export const getTableHeadlineForOrgKey = (orgKey: string): string => {
  if (!orgKey) return 'No orgKey';
  if (CountryUtils.isCountry(orgKey)) return 'Areas';
  if (AreaUtils.isArea(orgKey)) return 'Depotgebiete';
  if (orgKey.startsWith('od')) return 'Standorte';
  if (ZSBUtils.isZSB(orgKey) || orgKey?.startsWith('ov')) return 'Touren';
  return orgKey;
};

export const isTourOrgKey = (orgKey: string): boolean => {
  return TourUtils.isTourKey(orgKey) || ZSBUtils.isZSB(orgKey) || ContractorUtils.isContractor(orgKey);
};
export const isToday = (duration: Duration) => {
  return (
    duration?.key === DurationKey.TODAY ||
    (duration?.key === DurationKey.CUSTOM &&
      duration?.from?.startOf('day')?.diff(moment(new Date()).startOf('day'), 'days') === 0 &&
      duration?.to?.startOf('day')?.diff(moment(new Date()).startOf('day'), 'days') === 0)
  );
};

export const sortTourIdentifierRows = <T>(rowA: Row<T>, rowB: Row<T>): number => {
  const valueA = parseInt((rowA.original as DashboardDataTableDataType)?.tourIdentifier?.tourNumber ?? '0');
  const valueB = parseInt((rowB.original as DashboardDataTableDataType)?.tourIdentifier?.tourNumber ?? '0');
  if (valueA === valueB) return 0;
  return valueA > valueB ? 1 : -1;
};

export const filterTourIdentifierRows = <T>(row: Row<T>, columnId: string, filterValue?: string): boolean => {
  if (!filterValue) return true;
  const tourFilterStringParser = new TourFilterStringParser();
  const trimmedFilterValue = tourFilterStringParser.removeWhitespaceFromString(filterValue);
  if (trimmedFilterValue.length <= 0) return true;
  const value = row.getValue<TourIdentifier>(columnId) ?? null;
  if (!value?.tourNumber) {
    return false;
  }
  const negativeSet = new Set(
    tourFilterStringParser
      .getNegativeNumberGroups(trimmedFilterValue)
      .map((group) => {
        const [lower, upper] = tourFilterStringParser.extractBoundsFromGroup(group);
        return Array.from(Array(upper - lower + 1).keys()).map((key) => lower + key);
      })
      .flat()
  );
  tourFilterStringParser.getNegativeSingleValues(trimmedFilterValue).forEach((value) => negativeSet.add(value));
  const positiveSet = new Set(
    tourFilterStringParser
      .getNumberGroups(trimmedFilterValue)
      .map((group) => {
        const [lower, upper] = tourFilterStringParser.extractBoundsFromGroup(group);
        return Array.from(Array(upper - lower + 1).keys()).map((key) => lower + key);
      })
      .flat()
  );
  tourFilterStringParser.getSingleValues(trimmedFilterValue).forEach((value) => positiveSet.add(value));

  if (positiveSet.size === 0) {
    return !negativeSet.has(Number(value?.tourNumber));
  }
  return positiveSet.has(Number(value?.tourNumber)) && !negativeSet.has(Number(value?.tourNumber));
};

export const getDataTableColumnFilterTitle = (columnId: string) => {
  switch (columnId) {
    case 'orgName':
      return 'Name filtern';
    case 'standort':
      return 'Standort filtern';
    case 'auftraggeber':
      return 'Auftraggeber filtern';
    case 'details':
      return 'Details filtern';
    case 'sendungsId':
      return 'Sendungs-ID filtern';
    default:
      return 'Filtern';
  }
};

export const getHinweisArts = <T, V>(column: Column<T, V>): [string[], string[]] => {
  const rows = column.getFacetedRowModel().rows;
  const activeValues = rows.map((row) => row.getValue<string>(column.id));
  const anomalyReasons = Object.values<string>(AnomalyReason);
  const inactiveValues = anomalyReasons.filter((reason) => !activeValues.includes(reason));
  return [activeValues, inactiveValues];
};

export const getBeanstandungGrunds = <T, V>(column: Column<T, V>): [string[], string[]] => {
  const rows = column.getFacetedRowModel().rows;
  const rowValues = rows.map((row) => row.getValue<string>(column.id));
  const inactiveValues = complaintReasons.filter((reason) => !rowValues.includes(reason));
  return [rowValues, inactiveValues];
};

export const getVerlustrelevantBeanstandungGrunds = (allValue: string[]): [string[], string[]] => {
  const verlustRelevanteValues = allValue.filter((value) =>
    (Object.values(ValuableComplaintReasons) as string[]).includes(value)
  );
  const inactiveVerlustRelevanteValues = (Object.values(ValuableComplaintReasons) as string[]).filter(
    (value) => !verlustRelevanteValues.includes(value)
  );
  return [verlustRelevanteValues, inactiveVerlustRelevanteValues];
};

export const localeCompareStrings = (a: string, b: string): number => {
  return a.localeCompare(b);
};

// Sorts values and inactiveValues independently and then merges them
// Returns a sorted list of values that includes the active values and the inactive values at the end
export const getSortedMultiselectValues = (values: string[], inactiveValues: string[]): string[] => {
  const result = [...values].sort(localeCompareStrings);
  const inactiveResult = [...inactiveValues].sort(localeCompareStrings);
  result.push(...inactiveResult);
  return Array.from(new Set(result).values());
};

export const formatValueExpressionComparisonValue = (valueExpression: ValueExpression, value: number): string => {
  return Number(value) > 0
    ? '+' + formatValueExpressionValue(valueExpression, value)?.toString()
    : formatValueExpressionValue(valueExpression, value)?.toString();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortTableColumns = <T = any, V = unknown>(columns: Column<T, V>[], order: string[]): Column<T, V>[] => {
  return [...columns].sort((columnA, columnB) => {
    const aIsPinned = columnA.getIsPinned();
    const bIsPinned = columnB.getIsPinned();
    if (aIsPinned && bIsPinned) {
      return 0;
    } else if (aIsPinned && !bIsPinned) {
      return -1;
    } else if (!aIsPinned && bIsPinned) {
      return 1;
    } else if (!order.includes(columnA.id) && !order.includes(columnB.id)) {
      return 0;
    } else if (!order.includes(columnA.id) && order.includes(columnB.id)) {
      return 1;
    } else if (order.includes(columnA.id) && !order.includes(columnB.id)) {
      return -1;
    } else {
      return order.indexOf(columnA.id) - order.indexOf(columnB.id);
    }
  });
};
