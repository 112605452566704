import Logger from '../../utils/logging/Logger';
import { ConfigKey, EnvironmentConfigurationService } from '../../common/services/EnvironmentConfigurationService';

const signatureUrl = EnvironmentConfigurationService.get(ConfigKey.HTTP_API_URL);

export default class SignatureFlaggingService {
  _logger: Logger = Logger.getInstance('SignatureFlaggingService');

  getImageFlagUrl(imageId: string, tokenId: string): string {
    return `${signatureUrl}/signatures/${imageId}/mark?token=${tokenId}`;
  }

  async query(url: string, method: string = 'GET'): Promise<boolean> {
    try {
      const result = await fetch(url, {
        method,
      });

      if (result.status === 200) {
        return true;
      }
      if (result.status > 299) {
        this._logger.error(`Image flagging call failed with status: ${result.status}`);
      }
      return false;
    } catch (e) {
      this._logger.error('Image flag request failed', e);
      return false;
    }
  }

  flagImage(imageId: string, tokenId: string): Promise<boolean> {
    if (!imageId || !imageId.length) {
      throw new Error('ImageId must be set');
    }
    if (!tokenId || !tokenId.length) {
      throw new Error('TokenId must be set');
    }

    const url = new URL(this.getImageFlagUrl(imageId, tokenId));
    url.searchParams.append('reason', 'suspicious');

    return this.query(url.toString(), 'POST');
  }

  _replaceTokenIdFromImageUrl(oldImageUrl: string, tokenId: string) {
    const url = new URL(oldImageUrl);
    url.searchParams.delete('token');
    url.searchParams.append('token', tokenId);
    return url.toString();
  }

  static extractImageId(imageString: string) {
    if (!imageString || !imageString.length) {
      throw new Error('No image string provided: ' + imageString);
    }
    const str = 'filename="';
    const filenameIdx = imageString.indexOf('filename="');
    const startIdx = filenameIdx + str.length;
    const endIdx = imageString.indexOf('"', startIdx);
    return imageString.substr(startIdx, endIdx - startIdx);
  }
}
