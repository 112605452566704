import { extendMoment } from 'moment-range';
import moment from 'moment-timezone';
import AbstractCacheDataProvider from '../../datamanager/cache/AbstractCacheDataProvider';
import { QueryPriorities, QueryPriority } from '../../datamanager/models/enumerations/QueryPriority';
import RequestScheduler from '../../datamanager/services/RequestScheduler';
import { DateRange } from '../../utils/dates/DateRange';
import MetricDataConnection from '../models/dataconnection/MetricDataConnection';
import MetricsEntityKey from '../models/entities/MetricsEntityKey';
import { MetricsSingleValue } from '../models/entities/MetricsSingleValue';
import { DateRangeGrouping } from '../models/enumerations/DateRangeGrouping';
import MetricsQuery from '../models/queries/MetricsQuery';
import TypesState from '../models/state/TypesState';
import MetricsQueryResponsePayload from '../models/websocket/metrics/MetricsQueryResponsePayload';
import { MetricsQueryResponsePayloadValue } from '../models/websocket/metrics/MetricsQueryResponsePayloadValue';
import MetricsBaseQuery from '../models/queries/MetricsBaseQuery';
import MetricsOverviewQuery from '../models/queries/MetricsOverviewQuery';
import BasePayload from '../../common/models/websocket/payloads/BasePayload';
import MetricsOverviewQueryResponsePayload from '../models/websocket/metrics/MetricsOverviewQueryResponsePayload';
import { WeekResolution } from '../../dashboard/utils/ChartUtils';
import { CacheKeyInterface } from '../../datamanager/models/entities/CacheKeyInterface';

const { range } = extendMoment(moment as any);

export default class MetricsDataProvider extends AbstractCacheDataProvider<MetricsEntityKey, MetricsSingleValue> {
  static TAG = 'MetricsDataProvider';

  constructor(requestScheduler: RequestScheduler) {
    super(requestScheduler);
  }

  init() {
    super.init();
    this._requestScheduler.websocketApiService.socketEventFormatter.registerEventType(
      'metrics.query.result',
      MetricsQueryResponsePayload
    );
    this._requestScheduler.websocketApiService.socketEventFormatter.registerEventType(
      'metrics.overview.result',
      MetricsOverviewQueryResponsePayload
    );
  }

  // FIXME: Lookup after run
  // @ts-ignore
  requestConnection(query: MetricsBaseQuery, priority: QueryPriority = QueryPriorities.normal): MetricDataConnection {
    // FIXME: Lookup after run
    // @ts-ignore
    return super.requestConnection(query, priority);
  }
  // FIXME: Lookup after run
  // @ts-ignore
  query(
    query: MetricsBaseQuery,
    priority: QueryPriority = QueryPriorities.normal
  ): Promise<Map<MetricsEntityKey, MetricsSingleValue> | null | undefined> {
    // FIXME: Lookup after run
    // @ts-ignore
    return super.query(query, priority);
  }

  zeroValue() {
    return 0.0;
  }

  // FIXME: Lookup after run
  // @ts-ignore
  _newDataConnection(query: MetricsBaseQuery): MetricDataConnection {
    // FIXME: Lookup after run
    // @ts-ignore
    return new MetricDataConnection(query);
  }

  // FIXME: Lookup after run
  // @ts-ignore
  getDirectCacheEntityKeys(query: MetricsBaseQuery): Array<CacheKeyInterface> {
    if (query instanceof MetricsQuery) {
      return this.getDirectCacheEntityKeysMetricsQuery(query);
    } else if (query instanceof MetricsOverviewQuery) {
      return this.getDirectCacheEntityKeysMetricsOverviewQuery(query);
    } else {
      throw new Error('unknown query type');
    }
  }

  static translateGroupingToMomentRange(grouping: DateRangeGrouping) {
    if (grouping === 'week0') {
      return 'week';
    } else if (grouping === 'week1') {
      return 'isoWeek';
    }
    return grouping;
  }

  static translateMomentRangeToGrouping(momentRange: string) {
    if (momentRange === 'week') {
      return 'week0';
    } else if (momentRange === 'isoWeek') {
      return 'week1';
    }
    return momentRange;
  }

  static timeRangeResolutionForRangeGrouping(res: WeekResolution): WeekResolution {
    return res === WeekResolution.isoWeek || res === WeekResolution.weeks ? WeekResolution.weeks : res;
  }

  getDirectCacheEntityKeysMetricsQuery(query: MetricsQuery): Array<CacheKeyInterface> {
    let dateRanges: Array<DateRange> = [];
    if (query.grouping === DateRangeGrouping.none) {
      dateRanges = [
        {
          from: moment(query.dateFrom),
          to: moment(query.dateUntil),
        },
      ];
    } else {
      const groupingResolution = MetricsDataProvider.translateGroupingToMomentRange(
        query.grouping || DateRangeGrouping.day
      );
      dateRanges = Array.from(
        range(
          // FIXME: Lookup after run
          // @ts-ignore
          moment(query.dateFrom).startOf(groupingResolution),
          // FIXME: Lookup after run
          // @ts-ignore
          moment(query.dateUntil).endOf(groupingResolution).startOf('day')
        ).by(
          // FIXME: Lookup after run
          // @ts-ignore
          MetricsDataProvider.timeRangeResolutionForRangeGrouping(
            // FIXME: Lookup after run
            // @ts-ignore
            groupingResolution
          )
        )
      ).map((date: moment.Moment) => {
        return {
          from: moment(date),
          to: moment(date).endOf(groupingResolution as moment.unitOfTime.StartOf),
        };
      });
    }
    let valueKeys = [query.valueKey];
    if (!query.valueKey) {
      valueKeys = TypesState.getMapKeysByType(query.type);
    }
    const cacheEntityKeys = dateRanges
      .map((dr: DateRange) => {
        return valueKeys.map(
          (vk: string | null | undefined) =>
            new MetricsEntityKey(query.type, query.orgKey, dr.from, dr.to, query.grouping, vk, query.weekdayFilter)
        );
      })
      .reduce((a, b) => a.concat(b), []);
    return cacheEntityKeys;
  }

  getDirectCacheEntityKeysMetricsOverviewQuery(query: MetricsOverviewQuery): Array<MetricsEntityKey> {
    let valueKeys = [query.valueKey];
    if (!query.valueKey) {
      valueKeys = TypesState.getMapKeysByType(query.type);
    }
    const cacheEntityKeys = query.orgKeys
      .map((orgKey: string) => {
        return query.types
          .map((type: string) => {
            return valueKeys.map(
              (vk: string | null | undefined) =>
                new MetricsEntityKey(
                  type,
                  orgKey,
                  query.dateFrom,
                  query.dateUntil,
                  DateRangeGrouping.none,
                  vk,
                  query.weekdayFilter
                )
            );
          })
          .reduce((a, b) => a.concat(b), []);
      })
      .reduce((a, b) => a.concat(b), []);
    return cacheEntityKeys;
  }

  _metricsQueryValueToCacheEntityKey(query: MetricsQuery, value: MetricsQueryResponsePayloadValue): CacheKeyInterface {
    let dateFrom = query.dateFrom;
    let dateUntil = query.dateUntil;
    switch (query.grouping) {
      case DateRangeGrouping.week0:
        dateFrom = moment(value.group, 'YYYY-ww').startOf('week');
        dateUntil = dateFrom.clone().endOf('week').startOf('day');
        break;
      case DateRangeGrouping.week1:
        dateFrom = moment(value.group, 'YYYY-WW').startOf('isoWeek');
        dateUntil = dateFrom.clone().endOf('isoWeek').startOf('day');
        break;
      case DateRangeGrouping.year:
        dateFrom = moment(value.group, 'YYYY');
        dateUntil = dateFrom.clone().endOf('year').startOf('day');
        break;
      case DateRangeGrouping.month:
        dateFrom = moment(value.group, 'YYYY-MM');
        dateUntil = dateFrom.clone().endOf('month').startOf('day');
        break;
      case DateRangeGrouping.day:
        dateFrom = moment(value.group);
        dateUntil = dateFrom;
        break;
      case DateRangeGrouping.none:
      default: // noop
    }
    return this.getUniqueCacheEntityKey(
      new MetricsEntityKey(
        query.type,
        query.orgKey,
        dateFrom,
        dateUntil,
        query.grouping,
        value.valueKey,
        query.weekdayFilter
      )
    );
  }

  _metricsOverviewQueryValueToCacheEntityKey(
    query: MetricsOverviewQuery,
    value: MetricsQueryResponsePayloadValue
  ): CacheKeyInterface {
    return this.getUniqueCacheEntityKey(
      new MetricsEntityKey(
        value.type,
        value.group,
        query.dateFrom,
        query.dateUntil,
        DateRangeGrouping.none,
        value.valueKey,
        query.weekdayFilter
      )
    );
  }

  // FIXME: Lookup after run
  // @ts-ignore
  _parseResponse(query: MetricsBaseQuery, response: BasePayload<any>): Map<MetricsEntityKey, MetricsSingleValue> {
    if (query instanceof MetricsQuery) {
      // FIXME: Lookup after run
      // @ts-ignore
      return this._parseResponseMetricsQuery(query, response);
    } else if (query instanceof MetricsOverviewQuery) {
      // FIXME: Lookup after run
      // @ts-ignore
      return this._parseResponseMetricsOverviewQuery(query, response);
    } else {
      throw new Error('unknown query type');
    }
  }

  _parseResponseMetricsQuery(
    query: MetricsQuery,
    response: MetricsQueryResponsePayload
  ): Map<MetricsEntityKey, MetricsSingleValue> {
    const responseEntities = new Map();
    const self = this;
    response.values.forEach((value: MetricsQueryResponsePayloadValue) => {
      const cacheEntityKey = self._metricsQueryValueToCacheEntityKey(query, value);
      responseEntities.set(cacheEntityKey, value.value);
    });
    return responseEntities;
  }

  _parseResponseMetricsOverviewQuery(
    query: MetricsOverviewQuery,
    response: MetricsOverviewQueryResponsePayload
  ): Map<MetricsEntityKey, MetricsSingleValue> {
    const responseEntities = new Map();
    const self = this;
    response.values.forEach((value: MetricsQueryResponsePayloadValue) => {
      const cacheEntityKey = self._metricsOverviewQueryValueToCacheEntityKey(query, value);
      responseEntities.set(cacheEntityKey, value.value);
    });
    return responseEntities;
  }
}
