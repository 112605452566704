import { selectActiveView } from '@redux';
import { ViewMode, appSlice } from '@redux/app.slice';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type UseViewModeOutput = [ViewMode, (viewMode: ViewMode) => void];

export default function useViewMode(): UseViewModeOutput {
  const dispatch = useDispatch();
  const currentViewMode = useSelector(selectActiveView);

  const setViewMode = useCallback(
    (viewMode: ViewMode): void => {
      dispatch(appSlice.actions.setViewMode(viewMode));
    },
    [dispatch]
  );
  return [currentViewMode, setViewMode];
}
