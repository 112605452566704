import moment from 'moment';

import { KpisQuery, WeekdayInput } from '@graphql-client/graphql';
import { DateRangeGrouping } from '@graphql-client/graphql';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import { KpiFilter, KpiMapper } from '@hooks/use-kpi-query-hook/use-kpi-query.hook';
import { Duration, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';
import ChartUtils, { WeekResolution } from '@legacy-modules/dashboard/utils/ChartUtils';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { DurationUtils } from '@utils/duration-utils';
import { DateRange } from '@legacy-modules/utils/dates/DateRange';

const mapWeekResolutionToDateRangeGroupGql = (weekResolutionVal: WeekResolution): DateRangeGrouping => {
  switch (weekResolutionVal) {
    case WeekResolution.isoWeek:
      return DateRangeGrouping.WeekMonday;
    case WeekResolution.month:
      return DateRangeGrouping.Month;
    case WeekResolution.day:
      return DateRangeGrouping.Day;
    case WeekResolution.week1:
      return DateRangeGrouping.WeekMonday;
    default:
      throw new Error(`Unknown type: ${weekResolutionVal}`);
  }
};

export type UseChartKpiQueryProps = {
  orgKey: string;
  duration: Duration;
  valueExpression: ValueExpression;
  isComparisonDate?: boolean;
};

type UseChartKpiQueryOutput = {
  data: { rangeData: { [n: string]: number }; summaryData: number };
  loading: boolean;
};

export const transformData = (dateRange: DateRange) => (data: KpisQuery) => {
  const filteredKpiValues = data.kpis.groups.map((group) => ({
    ...group,
    kpiValues: group.kpiValues.filter(KpiFilter).map(KpiMapper),
  }));
  const filteredSummaryValues = data.kpis.summary.kpiValues.filter(KpiFilter).map(KpiMapper);

  const groupStringSuffix = ChartUtils.getDateResolution(
    dateRange.from,
    dateRange.to,
    WeekResolution.week1,
    ChartUtils.thresholdDataBarsCount
  );
  const mappedData = filteredKpiValues.reduce((obj, currVal) => {
    const mappedGroup = moment(currVal.group).format('YYYY-MM-DD');
    if (mappedGroup && currVal.kpiValues.length > 0) {
      obj[`${mappedGroup}_${groupStringSuffix}`] = currVal.kpiValues[0]?.value;
    }
    return obj;
  }, {});
  return { rangeData: mappedData, summaryData: filteredSummaryValues[0]?.value };
};

export default function useChartKpiQuery({
  orgKey,
  duration,
  valueExpression,
  isComparisonDate = false,
}: UseChartKpiQueryProps): UseChartKpiQueryOutput {
  const dateRange = isComparisonDate
    ? DurationUtils.getComparisonDateRange(duration)
    : DurationUtils.getDateRange(duration);

  const { from, to } = {
    from: dateRange?.from?.format('YYYY-MM-DD'),
    to: dateRange?.to?.format('YYYY-MM-DD'),
  };

  const dateRangeResolution = ChartUtils.getDateResolution(
    dateRange.from,
    dateRange.to,
    WeekResolution.isoWeek,
    ChartUtils.thresholdDataBarsCount
  );

  const { data, isLoading } = useKpiQuery(
    {
      orgKeys: [orgKey],
      dateFilter: {
        range: {
          from: from,
          until: to,
        },
        weekdays: Object.entries(duration.weekdayFilter || defaultWeekdaysFilter).map(([day, active]) => ({
          weekday: day.toUpperCase() as WeekdayInput,
          active,
        })),
      },
      kpiIds: valueExpression?.getRequiredMetricTypes().map((metricType) => ({ id: metricType.type.key })),
      dateRangeGrouping: mapWeekResolutionToDateRangeGroupGql(dateRangeResolution),
    },
    {
      enabled: valueExpression?.apiVersion === 'graphql' && !!orgKey && !!from && !!to,
      select: transformData(dateRange),
    }
  );

  return { data, loading: isLoading };
}
