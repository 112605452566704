import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import benachrichtigungenArt from './benachrichtigungen-art';
import benachrichtigungen from './benachrichtigungen';

const expression = new QuotientValueExpression(
  new MapDetailValueExpression(benachrichtigungenArt.metricType, 'email'),
  benachrichtigungen,
  'Digitale Benachrichtigungsquote',
  true,
  undefined,
  undefined,
  getDefinitionByName('Digitale Benachrichtigungsquote')
);

expression.setIdentifier('dbn_quote');

export default expression;
