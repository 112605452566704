import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import touren from './touren';
import tourfahrzeit from './tourfahrzeit';

const expression = new QuotientValueExpression(
  tourfahrzeit,
  touren,
  'Ø Tourfahrtzeit',
  false,
  '-#.###.##0,0 h',
  undefined,
  getDefinitionByName('Tourfahrtzeit')
);

expression.setIdentifier('tourfahrtzeit/touren'); // TODO: fix typo tourfahrzeit

export default expression;
