import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import beanstandungenQuoteVerlustrelevant from './beanstandungen-quote-verlustrelevant';
import abwicklungsmengeZsb from './abwicklungsmenge-zsb';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const expression = new QuotientValueExpression(
  beanstandungenQuoteVerlustrelevant,
  abwicklungsmengeZsb,
  'vSB-Quote (ZSB/VP+)',
  true,
  null,
  null,
  getDefinitionByName('vSB-Quote (ZSB/VP+)')
);

expression.decimalPlaces = 3;
expression.chartRenderOptions.startYAxisOnZero = false;
expression.valueFormat = '-#.###.##0,##0%';
expression.setIdentifier('complaints.quote.count_lossrelevant/complaints.quote.base');

export default expression;
