import { useApiContext } from '@contexts/api-context';
import { KnownWebsocketEvent } from '@contexts/api-context/request.types';
import OrgUnitTreeResponsePayload from '@legacy-modules/metrics2/models/websocket/org/OrgUnitTreeResponsePayload';
import { useQuery } from 'react-query';

type UseOrgTreeDataOutput = OrgUnitTreeResponsePayload;

const useOrgTreeData = (realm: string, enabled: boolean = true): UseOrgTreeDataOutput => {
  const apiCtx = useApiContext();

  const { data } = useQuery<OrgUnitTreeResponsePayload>({
    queryKey: ['org-tree', realm],
    queryFn: () =>
      apiCtx.wsFetch(KnownWebsocketEvent.ORG_TREE_LOAD_EVENT, {
        realm,
      }),
    enabled,
  });

  return data;
};

export default useOrgTreeData;
