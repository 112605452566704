import { DataTableColumnCell } from '@data-table/components/data-table-column-cell';
import { DataTableColumnHeader } from '@data-table/components/data-table-column-header';
import { BeanstandungenDataTableDataType, DataTableColumnMeta } from '@data-table/data-table.types';
import { filterTourIdentifierRows, sortTourIdentifierRows } from '@data-table/utils';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<BeanstandungenDataTableDataType>();

const useBeanstandungenDataTableColumns = (): ColumnDef<BeanstandungenDataTableDataType, any>[] => {
  return [
    columnHelper.accessor('tourIdentifier', {
      id: 'tourIdentifier',
      header: DataTableColumnHeader.CenterAlignedColumnHeader,
      cell: DataTableColumnCell.TourNumberColumnCell,
      meta: {
        exportValue: 'Tour',
        columnStyle: {
          gridColumn: '120px',
        },
      } as DataTableColumnMeta,
      sortingFn: sortTourIdentifierRows,
      filterFn: filterTourIdentifierRows,
      sortUndefined: 1,
    }),
    columnHelper.accessor('sendungsId', {
      id: 'sendungsId',
      header: DataTableColumnHeader.TruncatedColumnHeader,
      cell: DataTableColumnCell.ShipmentCell,
      meta: {
        exportValue: 'Sendungs-ID',
        columnCellClassName: 'leftAlignedCell',
        bodyCellClassName: 'leftAlignedCell',
        columnStyle: {
          gridColumn: '230px',
        },
      } as DataTableColumnMeta,
      filterFn: 'includesString',
      sortUndefined: 1,
      invertSorting: true,
    }),
    columnHelper.accessor('wert', {
      id: 'wert',
      header: DataTableColumnHeader.TruncatedColumnHeader,
      cell: DataTableColumnCell.WertCell,
      meta: {
        exportValue: 'Wert',
        columnStyle: {
          gridColumn: '120px',
        },
      } as DataTableColumnMeta,
      enableColumnFilter: false,
      sortUndefined: 1,
    }),
    columnHelper.accessor('beanstandungGrund', {
      id: 'beanstandungGrund',
      header: DataTableColumnHeader.TruncatedColumnHeader,
      cell: DataTableColumnCell.TruncatedColumnCell,
      meta: {
        exportValue: 'SB-Grund',
        columnCellClassName: 'leftAlignedCell',
        bodyCellClassName: 'leftAlignedCell',
        columnStyle: {
          gridColumn: 'minmax(150px, 1fr)',
        },
      } as DataTableColumnMeta,
      filterFn: 'arrIncludesSome',
      invertSorting: true,
      sortUndefined: 1,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: DataTableColumnHeader.TruncatedColumnHeader,
      cell: DataTableColumnCell.CenterAlignedCell,
      meta: {
        exportValue: 'Status',
        columnCellClassName: 'leftAlignedCell',
        bodyCellClassName: 'leftAlignedCell',
        columnStyle: {
          gridColumn: '150px',
        },
      } as DataTableColumnMeta,
      enableColumnFilter: false,
      invertSorting: true,
      sortUndefined: 1,
    }),
    columnHelper.accessor('auftraggeber', {
      id: 'auftraggeber',
      header: DataTableColumnHeader.TruncatedColumnHeader,
      cell: DataTableColumnCell.TruncatedColumnCell,
      meta: {
        exportValue: 'Auftraggeber',
        columnCellClassName: 'leftAlignedCell',
        bodyCellClassName: 'leftAlignedCell',
        columnStyle: {
          gridColumn: 'minmax(150px, 1fr)',
        },
      } as DataTableColumnMeta,
      filterFn: 'arrIncludesSome',
      invertSorting: true,
      sortUndefined: 1,
    }),
    columnHelper.accessor('meldezeitpunkt', {
      id: 'meldezeitpunkt',
      header: DataTableColumnHeader.TruncatedColumnHeader,
      cell: DataTableColumnCell.DateCell,
      meta: {
        exportValue: 'Meldezeitpunkt',
        dateCellFormat: 'DD.MM.YY HH:mm',
        columnCellClassName: 'leftAlignedCell',
        bodyCellClassName: 'leftAlignedCell',
        columnStyle: {
          gridColumn: '150px',
        },
      } as DataTableColumnMeta,
      enableColumnFilter: false,
      sortUndefined: 1,
    }),
  ];
};

export default useBeanstandungenDataTableColumns;
