import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.FinishedDeliveriesStopJourneyLocationAccuracy,
  label: 'GPS Genauigkeit',
  aggregation: AggregationType.sum,
  chart: ChartType.line,
  positiveDirection: PositiveDirection.custom,
  category: 'tours',
  description: '<p>GPS Genauigkeit in Metern, gruppiert</p>\n',
});

export default new SingleValueExpression(metric);
