export class AreaUtils {
  static PREFIX = 'oa';

  static isArea(orgKey: string): boolean {
    if (!orgKey) {
      return false;
    }
    return orgKey.startsWith(AreaUtils.PREFIX);
  }
}
