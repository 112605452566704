import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import servicebeanstandungenZustelltour from './servicebeanstandungen-zustelltour';
import istLademenge from './lademenge-ist';

const expression = new QuotientValueExpression(
  servicebeanstandungenZustelltour,
  istLademenge,
  'SB pro Lademenge',
  true
);

expression.setIdentifier('sb/lademenge');

export default expression;
