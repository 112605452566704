import React, { CSSProperties, useCallback, useMemo, useRef, useState } from 'react';
import '../../dashboard/cssModules/kpiContainerStyles.scss';
import { useSelector } from 'react-redux';
import '../assets/signaturesContainer.scss';
import SignaturesTableStop from '../components/SignaturesTableStop';
import CustomerTrackingData from '../models/types/CustomerTrackingData';
import Lottie from 'react-lottie-player';
import CloudSad from '../../../assets/lottie/cloud/cloud_sad.json';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import { sumBy } from 'lodash';
import { SearchInput } from '../../dashboard/components/SearchInput';
import { useOnClickOutside } from '../../common/hooks/useOnClickOutside';
import { Loader } from '../../common/components/Loader';
import { SignatureGalleryElement } from './SignatureGalleryElement';
import { Button, ButtonGroup } from 'reactstrap';
import { BsGrid3X3GapFill, BsViewStacked } from 'react-icons/bs';
import Styles from './SignaturesContainer.module.scss';
import { GalleryImageSizeSelector } from '../components/GalleryImageSizeSelector';
import TextButton from '../../common/components/TextButton';
import NoResult from '../../../assets/lottie/magnifyingglass/no_result.json';
import { MdImage } from 'react-icons/md';
import { buildShipmentIdToCustomerIdMap } from '../utils/ShipmentUtils';
import { getProofOfDeliveryType, ProofOfDelivery } from '../models/enumerations/ProofOfDelivery';
import { SignatureFilterBadgePanel } from './SignatureFilterBadgePanel';
import { TourenReportAction } from '../../../refactoring/common/components/touren-report';
import AuthGuard from '../../auth/components/AuthGuard';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import { selectTokenId } from '@redux/auth.selectors';
import { selectTourDetailsTourIdentifier } from '@redux/tour-details.selectors';
import { useTourDetailsContext } from '@other-components/tour-details-context';

type Props = {};

const SUPPORTED_PROOFS = [ProofOfDelivery.imageSignature, ProofOfDelivery.signature, undefined];

export enum SignatureViewMode {
  Gallery,
  List,
}

const SignaturesContainer: React.FC<Props> = () => {
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const tokenId = useSelector(selectTokenId);
  const tourIdentifier = useSelector(selectTourDetailsTourIdentifier);

  const [galleryImageSize, setGalleryImageSize] = useState(250);
  const [signatureViewMode, setSignatureViewMode] = useState(SignatureViewMode.List);

  const {
    tourDetails,
    tourDetailsLoading,
    finishedDeliveries,
    filteredFinishedDeliveries,
    zustellartFilterState: [, setZustellartFilter],
    signaturesState: [, setSignaturesOpen],
  } = useTourDetailsContext();

  const urls = useMemo(() => {
    const buildURLs = (deliveries: FinishedDelivery[]) => {
      const getUrlForStop = (fd?: FinishedDelivery) => fd?.signatureURL(tourIdentifier, tokenId) || null;

      return deliveries
        .filter((stop) => SUPPORTED_PROOFS.indexOf(getProofOfDeliveryType(stop.proofOfDelivery)) > -1)
        .reduce((collector, stop) => {
          const url = getUrlForStop(stop);
          const id = stop.displayableStopNumber;
          return {
            ...collector,
            [id]: url,
          };
        }, {});
    };
    return buildURLs(finishedDeliveries);
  }, [tokenId, finishedDeliveries, tourIdentifier]);

  const wrapper = useRef<HTMLDivElement | null>(null);

  const _showForFilter = useCallback(
    (stop: FinishedDelivery, searchString: string) => {
      if (!tourDetails.customers) return false;

      const buildIdSearchString = (stop: FinishedDelivery) => {
        const deliverItemIds = stop.deliveryItems.map((i) => i.id).join(' ');
        const returnIds = stop.returnDeliveryItems.map((i) => i.id + ' ');
        return [deliverItemIds, returnIds].join(' ');
      };

      const buildAddressSearchString = (stop: FinishedDelivery) => {
        let customers: Array<CustomerTrackingData> = [];
        const cd = stop && Object.values(stop.customerDeliveries);
        if (cd.length >= 1) {
          customers = cd
            .filter((c) => tourDetails?.customers?.has(c.customerRef))
            .map((c) => tourDetails.customers.get(c.customerRef));
        }
        const searchStrings = [];
        customers.forEach((customer) => {
          const customerInfo = [
            customer.firstName,
            customer.lastName,
            customer.street,
            customer.streetNr,
            customer.city,
            customer.zipcode,
            customer.rsteze,
          ].join(' ');
          return searchStrings.push(customerInfo);
        });
        return searchStrings.join(' ');
      };

      const idSearch = buildIdSearchString(stop);
      const addressSearch = buildAddressSearchString(stop);
      return [addressSearch, idSearch].join(' ').indexOf(searchString) > -1;
    },
    [tourDetails?.customers]
  );

  const _onSearch = useCallback((search: string) => setSearchFieldValue(search), [setSearchFieldValue]);

  const onClickOutside = useCallback(
    (e: MouseEvent) => {
      if (document.querySelector('.subheader-wrapper').contains(e.target as Node)) {
        return;
      }
      setSignaturesOpen(false);
      setZustellartFilter([]);
    },
    [setSignaturesOpen, setZustellartFilter]
  );

  useOnClickOutside(wrapper, onClickOutside);

  const [maxNrOfPackages, nrOfActivePackages] = useMemo(() => {
    if (!filteredFinishedDeliveries) {
      return [0, 0];
    }

    const di = sumBy(filteredFinishedDeliveries, (fd) => fd.deliveryItems.length);
    const retDi = sumBy(filteredFinishedDeliveries, (fd) => fd.returnDeliveryItems.length);
    const diMax = sumBy(finishedDeliveries, (fd) => fd.deliveryItems.length);
    const retDiMax = sumBy(finishedDeliveries, (fd) => fd.returnDeliveryItems.length);
    const maxNrOfPackages = diMax + retDiMax;
    const nrOfActivePackages = di + retDi;

    return [maxNrOfPackages, nrOfActivePackages];
  }, [finishedDeliveries, filteredFinishedDeliveries]);

  const getTableStyle = (width: string): CSSProperties => ({
    width,
    borderBottom: 0,
    fontWeight: 500,
    backgroundColor: 'var(--gray20)',
    textAlign: 'left',
    padding: '0 15px',
  });

  const tableHead = (
    <thead>
      <tr style={{ height: '32px' }}>
        <td style={getTableStyle('4%')}>Stopp</td>
        <td style={getTableStyle('3%')}>Zeit</td>
        <td style={getTableStyle('3%')}>GPS</td>
        <td style={getTableStyle('14%')}>Adresse und Kontakt</td>
        <td style={getTableStyle('14%')}>Unterschrift</td>
        <td style={getTableStyle('10%')}>Berechtigter</td>
        <td style={getTableStyle('30%')}>Sendungen</td>
      </tr>
    </thead>
  );

  const toggleViewMode = useCallback(() => {
    setSignatureViewMode((state) =>
      state === SignatureViewMode.List ? SignatureViewMode.Gallery : SignatureViewMode.List
    );
  }, [setSignatureViewMode]);

  const stopList = finishedDeliveries;
  const activePackages = nrOfActivePackages === 0 ? maxNrOfPackages : nrOfActivePackages;
  const noOfStopsShown = filteredFinishedDeliveries?.length;
  const maxNrOfStops = stopList?.length;
  const equalityCheckStops = maxNrOfStops === noOfStopsShown ? maxNrOfStops : `${noOfStopsShown}/${maxNrOfStops}`;
  const formattedCount = (() => {
    if (activePackages === maxNrOfPackages) return maxNrOfPackages;
    return `${activePackages}/${maxNrOfPackages}`;
  })();

  const list = useMemo(() => {
    if (searchFieldValue.length) {
      return (stopList || []).filter((i) => _showForFilter(i, searchFieldValue.toUpperCase()));
    }

    const listToUse = filteredFinishedDeliveries?.length > 0 ? filteredFinishedDeliveries : finishedDeliveries;

    return listToUse;
  }, [_showForFilter, filteredFinishedDeliveries, searchFieldValue, stopList, finishedDeliveries]);

  const filteredListForGalleryView = useMemo(() => {
    return (list || []).filter((fd) =>
      ['imageSignature', 'signature'].includes(getProofOfDeliveryType(fd.proofOfDelivery))
    );
  }, [list]);

  const resultsExist = (() => {
    if (signatureViewMode !== SignatureViewMode.Gallery) {
      return list?.length || 0;
    }
    return filteredListForGalleryView.length;
  })();

  const removeFiltersCallback = useCallback(() => setZustellartFilter([]), [setZustellartFilter]);

  const signatureFilterAnomalies = useMemo(() => {
    return tourDetails?.anomalies?.filter((a) => a.anomalyLevel !== 'tour');
  }, [tourDetails?.anomalies]);

  const getSignatureTableAnomalies = useCallback(
    (finishedDelivery: FinishedDelivery) => {
      return signatureFilterAnomalies.filter((a) =>
        a.tourStopRefs.map((ref) => ref.stopNumber).includes(finishedDelivery.index)
      );
    },
    [signatureFilterAnomalies]
  );

  const content = (() => {
    if (!tourDetails) {
      return (
        <div className='loading-indicator'>
          <div>Die Daten zu der Tour sind nicht verfügbar, bitte wähle ein anderes Datum.</div>
          <Lottie
            style={{
              height: 350,
              width: 500,
            }}
            loop
            play
            animationData={CloudSad}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </div>
      );
    }

    const tableContent = (
      <div className='table-container-signatures'>
        <table>
          {tableHead}
          <tbody>
            {list.map((finishedDelivery) => (
              <SignaturesTableStop
                key={`key-${finishedDelivery.uuid}-tablestop`}
                finishedDelivery={finishedDelivery}
                customers={tourDetails.customers}
                shipmentIdToCustomerMap={buildShipmentIdToCustomerIdMap(
                  tourDetails.deliveryItems,
                  Object.values(tourDetails.finishedDeliveries)
                )}
                anomalies={getSignatureTableAnomalies(finishedDelivery)}
                url={urls?.[finishedDelivery.displayableStopNumber]}
              />
            ))}
          </tbody>
        </table>
      </div>
    );

    const gallery = (
      <div className={Styles.GalleryWrapper}>
        {filteredListForGalleryView.map((finishedDelivery, id) => (
          <SignatureGalleryElement
            key={id}
            size={galleryImageSize}
            finishedDelivery={finishedDelivery}
            url={urls?.[finishedDelivery.displayableStopNumber]}
          />
        ))}
      </div>
    );

    const filtersActive = finishedDeliveries || searchFieldValue.length;

    const content = (() => {
      if (tourDetailsLoading) {
        return <Loader className={Styles.LoaderWrapper} />;
      }

      if (!resultsExist) {
        return (
          <div className={Styles.NoFilterResultsWrapper}>
            <Lottie
              style={{
                height: 200,
                width: 150,
              }}
              loop
              play
              animationData={NoResult}
              rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            />
            <div className={Styles.Text}>
              {signatureViewMode === SignatureViewMode.List
                ? 'Zu Deiner Auswahl sind keine Stopps vorhanden.'
                : 'Es sind keine Abliefernachweise vorhanden.'}
              {!!filtersActive && (
                <div>
                  Filter{' '}
                  <div style={{ display: 'inline-block' }}>
                    <TextButton text={'zurücksetzen'} onClick={removeFiltersCallback} />
                  </div>
                  ?
                </div>
              )}
            </div>
          </div>
        );
      }

      if (signatureViewMode === SignatureViewMode.Gallery) {
        return gallery;
      } else {
        return tableContent;
      }
    })();

    return (
      <>
        <div className={'top-panel-container-signatures-wrapper '}>
          <div className='top-panel-container-signatures'>
            <div className='container-panel-title'>
              <span className='panel-title-signatures'>Unterschriften</span>
              <span className='number-stops-info'>
                {equalityCheckStops} Stopps - {formattedCount} Scans
              </span>
            </div>
            <div className='right-side'>
              <AuthGuard requiredPermissions={[WellKnownPermission.ShowTourenBerichtExportButton]}>
                <TourenReportAction
                  tourDetails={tourDetails}
                  finishedDeliveries={list}
                  imageUrls={urls}
                  tourIdentifier={tourIdentifier}
                />
              </AuthGuard>
              <div className='inputs-container-signatures'>
                <SearchInput
                  placeholder={'Adresse oder ID suchen'}
                  onResetClick={() => _onSearch('')}
                  value={searchFieldValue}
                  onChange={(e) => _onSearch(e.target.value)}
                />
              </div>
              <div className={'button-group-wrapper'}>
                <ButtonGroup className={Styles.ButtonGroup}>
                  <Button
                    className={Styles.Button}
                    onClick={toggleViewMode}
                    color={signatureViewMode === SignatureViewMode.List ? 'primary' : 'white'}>
                    <BsViewStacked />
                  </Button>
                  <Button
                    className={Styles.Button}
                    onClick={toggleViewMode}
                    color={signatureViewMode === SignatureViewMode.Gallery ? 'primary' : 'white'}>
                    <BsGrid3X3GapFill />
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          {stopList && <SignatureFilterBadgePanel />}
        </div>
        {signatureViewMode === SignatureViewMode.Gallery && (
          <div className={Styles.TopBar}>
            <div className={Styles.Info}>
              {filteredListForGalleryView.length} Abliefernachweise
              {list.length - filteredListForGalleryView.length > 0 && (
                <span>
                  {' '}
                  ({list.length - filteredListForGalleryView.length} Stopps werden nicht angezeigt, weil für diese kein
                  ALN erforderlich war)
                </span>
              )}
            </div>
            <div className={Styles.SizeSelectorWrapper}>
              <span className={Styles.ImageIcon}>
                <MdImage />
              </span>
              <GalleryImageSizeSelector
                galleryImageSize={galleryImageSize}
                onSizeChangeCallback={setGalleryImageSize}
              />
            </div>
          </div>
        )}
        <div className='signatures-main-container'>{content}</div>
      </>
    );
  })();

  return (
    <div ref={wrapper} className='wrapperDashboardSignatures' style={{ marginTop: '0px' }}>
      {content}
    </div>
  );
};

export default SignaturesContainer;
