import { useMemo } from 'react';
import { compareValueExpressionWithBounds } from '../utils/ValueExpressionComparison';
import { CustomLimit, DefaultLimit, LimitClassTypes, TargetArea } from '../types/AbstractLimit';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import { getDirectionFromGoal } from '../utils/DirectionUtils';
import { defaultLimitsMap } from '@utils/limits-utils';
import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';

export enum KpiColorVariation {
  Positive = 'positive',
  Negative = 'negative',
  Warning = 'warning',
  Neutral = 'neutral',
}

export const isSumValueExpression = (valueExpression: ValueExpression): boolean => {
  return valueExpression instanceof SumValueExpression;
};

export const useKpiIndicator = (
  goal: LimitClassTypes,
  valueExpression: ValueExpression,
  isLoading: boolean,
  primaryData: number,
  workdaysInRange: number
) => {
  const defaultLimits = defaultLimitsMap.get(valueExpression.identifier) || null;

  const indicatorColor = useMemo(() => {
    if (!goal && !defaultLimits) {
      return KpiColorVariation.Neutral;
    }

    if (isLoading || primaryData == null || isNaN(primaryData)) {
      return KpiColorVariation.Neutral;
    }

    const divideByWorkingDays = isSumValueExpression(valueExpression);

    if (goal instanceof TargetArea) {
      const sortedAreas = [...goal.targetAreas].sort((a, b) => a - b);
      const dataDividedByWorkingDays =
        divideByWorkingDays && workdaysInRange > 0 ? primaryData / workdaysInRange : primaryData;
      const isWithinArea = sortedAreas[0] <= dataDividedByWorkingDays && dataDividedByWorkingDays <= sortedAreas[1];

      return isWithinArea ? KpiColorVariation.Positive : KpiColorVariation.Negative;
    }

    const bounds = (() => {
      if (goal instanceof DefaultLimit) {
        return goal;
      }
      if (goal instanceof CustomLimit) {
        return goal.limit;
      }
    })();

    if (!bounds && !defaultLimits) {
      return KpiColorVariation.Neutral;
    }

    const direction = getDirectionFromGoal(valueExpression, goal);

    if (direction === 'custom') {
      return KpiColorVariation.Neutral;
    }

    if (bounds) {
      return compareValueExpressionWithBounds(
        direction,
        divideByWorkingDays && workdaysInRange > 0 ? primaryData / workdaysInRange : primaryData,
        bounds
      );
    } else {
      return compareValueExpressionWithBounds(
        direction,
        divideByWorkingDays && workdaysInRange > 0 ? primaryData / workdaysInRange : primaryData,
        defaultLimits
      );
    }
  }, [defaultLimits, goal, isLoading, primaryData, valueExpression, workdaysInRange]);

  return {
    color: indicatorColor,
  };
};
