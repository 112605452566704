import React from 'react';
import { FilterGroup } from '../components/FilterGroup';
import FilterBadgePanel from '../components/FilterBadgePanel';
import { FilterSeparator } from '../components/FilterSeparator';
import ServicesFilterPanel from '../components/ServicesFilterPanel';
import Styles from './SignatureFilterBadgePanel.module.scss';
import { AnomalyFilterGroup } from './AnomalyFilterGroup';

type Props = {};

export const SignatureFilterBadgePanel = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const renderInRow = true;
  return (
    <div ref={ref} className={Styles.Container}>
      <FilterGroup>
        <FilterBadgePanel />
      </FilterGroup>
      {renderInRow && <FilterSeparator />}
      <FilterGroup>
        <ServicesFilterPanel />
      </FilterGroup>
      {renderInRow && <FilterSeparator />}
      <AnomalyFilterGroup />
    </div>
  );
});
