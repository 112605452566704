import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import touren from './touren';
import tourstrecke from './tourstrecke';

const expression = new QuotientValueExpression(
  tourstrecke,
  touren,
  'Ø Tourstrecke',
  false,
  '-#.###.##0,0 km',
  undefined,
  getDefinitionByName('Tourstrecke')
);

expression.setIdentifier('tourstrecke/touren');

export default expression;
