import React, { useCallback } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { TreeType, TreeTypes } from '../../../metrics2/models/enumerations/TreeType';
import OrgUnit from '../../../metrics2/models/entities/organization/OrgUnit';
import classNames from 'classnames';

type Props = {
  items: Array<OrgUnit>;
  onClickItem: (arg0: OrgUnit) => void;
};

const MapBreadcrumb: React.FC<Props> = (props) => {
  const { onClickItem, items } = props;

  const _getTreeTypeLabel = useCallback((treeType: TreeType): string => {
    switch (treeType) {
      case TreeTypes.rsteze:
        return 'RS';
      case TreeTypes.zip:
        return 'PLZ';
      default:
        return '';
    }
  }, []);

  return (
    <div className='map-breadcrumb'>
      <ul>
        {items.map((item, i: number) => {
          const itemProps = (() => {
            if (item.orgKey) {
              return {
                onClick: () => onClickItem(item),
              };
            }
            return {
              disabled: true,
            };
          })();

          const nodes = [
            <li
              {...itemProps}
              key={`breadcrumb-label-${item.orgKey}`}
              className={classNames({
                multi: items[i + 1] && item.orgType === items[i + 1].orgType,
              })}>
              {i === 1 ? _getTreeTypeLabel(item.treeType) : ''} {item.name}
            </li>,
          ];

          if (i !== items.length - 1) {
            nodes.push(
              <li key={`breadcrumb-arrow-${i}`} className='breadcrumb-arrow'>
                {item.orgType !== items[i + 1].orgType && <MdChevronRight size={18} />}
                {/* if multi-select add comma between  */}
                {item.orgType === items[i + 1].orgType && <em>,&nbsp;</em>}
              </li>
            );
          }
          return nodes;
        })}
      </ul>
    </div>
  );
};

export default MapBreadcrumb;
