import FinishedDelivery from '../models/entities/FinishedDelivery';
import moment from 'moment';

export interface IFinishedDeliveryInterruption {
  duration: moment.Duration;
  isNextDay: boolean;
  interruption: {
    after: FinishedDelivery;
    before: FinishedDelivery;
  };
}

export class FinishedDeliveryInterruptionService {
  /**
   * Finds interruptions in a given list of FinishedDeliveries.
   * It checks the duration of every delivery and compares it to
   * the given threshold. If the difference is <b>same</b> or higher
   * it will be returned as the result.
   *
   * @pre: List is sorted with the finishedAt field.
   *
   * @param finishedDeliveries Array of the finished deliveries
   * @param durationThreshold duration in minutes when the difference is considered as an
   * interruption. Value is inclusive.
   */
  findInterruptions(
    finishedDeliveries: FinishedDelivery[],
    durationThreshold: number
  ): IFinishedDeliveryInterruption[] {
    if (!finishedDeliveries || !finishedDeliveries.length) return [];

    let lastFinishedDelivery = finishedDeliveries[0];
    const interruptions: IFinishedDeliveryInterruption[] = [];
    for (let i = 1; i < finishedDeliveries.length; i++) {
      const current = finishedDeliveries[i];
      if (!lastFinishedDelivery.finishedAt || !current.finishedAt) {
        lastFinishedDelivery = finishedDeliveries[i];
        continue;
      }
      const diff = moment.duration(lastFinishedDelivery.finishedAt.diff(current.finishedAt));
      const diffInMinutes = Math.abs(diff.asMinutes());
      if (diffInMinutes >= durationThreshold) {
        interruptions.push({
          duration: diff,
          isNextDay: lastFinishedDelivery.finishedAt.clone().add(1, 'day').isSame(current.finishedAt, 'day'),
          interruption: {
            after: lastFinishedDelivery,
            before: current,
          },
        });
      }
      lastFinishedDelivery = finishedDeliveries[i];
    }

    return interruptions;
  }
}

export const FinishedDeliveryInterruptionServiceInstance = new FinishedDeliveryInterruptionService();
