import React, { ReactElement } from 'react';
import './mapbox-map.module.scss';
import Map, { MapProps } from 'react-map-gl';
import { useMapStyleContext } from './map-style-context';
import { MapboxScaleControl, MapboxScaleControlProps } from './mapbox-scale-control';
import { MapboxNavigationControl, MapboxNavigationControlProps } from './mapbox-navigation-control';
import { MapboxStyleControl, MapboxStyleControlProps } from './mapbox-style-control';
import { MapboxControlProps } from './mapbox-control/mapbox-control';
import { MapboxStopSelect, MapboxStopSelectProps } from './mapbox-stop-select';
import { MapboxBreadcrumbProps, MapboxBreadcrumb } from './mapbox-breadcrumb';
import {
  ConfigKey,
  EnvironmentConfigurationService,
} from '../../../../modules/common/services/EnvironmentConfigurationService';
import { MapboxLegend, MapboxLegendProps } from './mapbox-legend';

const defaultViewState: MapProps['initialViewState'] = {
  bounds: [
    [0, 47.2702482],
    [15.0417476, 55.0586834],
  ],
  fitBoundsOptions: {
    padding: 100,
    linear: true,
  },
};

export type MapboxMapProps = MapProps & {
  showScaleControl?: boolean;
  showNavigationControl?: boolean;
  showStyleControl?: boolean;
  showStopSelectControl?: boolean;
  showBreadcrumbControl?: boolean;
  showLegendControl?: boolean;
  scaleControlProps?: MapboxScaleControlProps;
  navigationControlProps?: MapboxNavigationControlProps;
  styleControlProps?: MapboxControlProps<MapboxStyleControlProps>;
  stopSelectControlProps?: MapboxControlProps<MapboxStopSelectProps>;
  breadcrumbControlProps?: MapboxControlProps<MapboxBreadcrumbProps>;
  legendControlProps?: MapboxControlProps<MapboxLegendProps>;
};

export function MapboxMap({
  onMove,
  children,
  showScaleControl,
  showNavigationControl,
  showStyleControl,
  showStopSelectControl,
  showBreadcrumbControl,
  showLegendControl,
  scaleControlProps,
  navigationControlProps,
  styleControlProps,
  stopSelectControlProps,
  breadcrumbControlProps,
  legendControlProps,
  initialViewState,
  ...rest
}: MapboxMapProps): ReactElement {
  const styleCtx = useMapStyleContext();
  return (
    <Map
      style={{ width: '100%', height: '100%' }}
      mapboxAccessToken={EnvironmentConfigurationService.get(
        EnvironmentConfigurationService.isInDevelopmentEnvironment()
          ? ConfigKey.MAPBOX_DEV_TOKEN
          : ConfigKey.MAPBOX_TOKEN
      )}
      mapStyle={styleCtx?.mapConfig?.mapStyleURL ?? EnvironmentConfigurationService.get(ConfigKey.MAPBOX_STYLE_DEFAULT)}
      onMove={onMove}
      attributionControl={true}
      {...rest}
      initialViewState={{ ...defaultViewState, ...initialViewState }}>
      {showScaleControl && <MapboxScaleControl unit='metric' {...scaleControlProps} />}
      {showNavigationControl && (
        <MapboxNavigationControl showCompass={false} showZoom={true} {...navigationControlProps} />
      )}
      {showBreadcrumbControl && <MapboxBreadcrumb {...breadcrumbControlProps} />}
      {showStopSelectControl && <MapboxStopSelect {...stopSelectControlProps} />}
      {showStyleControl && <MapboxStyleControl {...styleControlProps} />}
      {showLegendControl && <MapboxLegend {...legendControlProps} />}
      {children}
    </Map>
  );
}

export default MapboxMap;
